import { CLabel } from '../CLabel';
import { withTranslation, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import Connect from '../../helpers/Connect';
import DatePicker from 'rc-calendar/lib/Picker';
import Moment from 'moment';
import Utils from '../../helpers/Utils';
import { AccessRestriction } from '../AccessRestriction';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../../redux/Store';
import CInlineDropdown from '../filters/CInlineDropdown';
import { useMemo } from 'react';
import { isContentAdmin } from '../../helpers/UserUtils';
import { checkActionConfirmationForAA, ACTION_TYPES } from '../../helpers/UtilsHelpers';



const CNoteEditPopup = ({ toggleEditNotePopup, data, onChanged, onDeleted, user, isAliasEnabled, hasAccessToAssignableActions, toggleConfirmActionPopup }) => {
    const { t } = useTranslation();
    const initialValue = (() => {
        if (!data.note) {
            return {
                propertyId: data.property?.id,
                companyId: data.company?.id,
                alertByEmail: false,
                type: "NOTE"
            }
        }
        return {
            ...data.note,
            dateTo: data.note.type === "NOTE" ? null : data.note.dateTo
        }
    })();
    const [note, setNote] = useState(initialValue);



    const [textError, setTextError] = useState(null);
    const [dateToError, setDateToError] = useState(null);
    const [usersList, setUsersList] = useState([]);

    const getCreatedByDefault = (user) => [
        { title: t("actions.and.notes.filter.written.by.me"), value: user && user.id }
    ]
    
    const initialAssigneeId = (() => {
        if (data.note?.type === "NOTE") {
            return getCreatedByDefault(user)[0].value;
        }
        return data.note?.assignedTo?.id || getCreatedByDefault(user)[0].value;
    })();

    const [assignedTo, setAssignedTo] = useState(initialAssigneeId);

    useEffect(() => {
        API.notesDictionaryAssignable({ propertyId: data.property?.id, companyId: data.company?.id }, (data) => {
            const others = data
                .filter((it) => it.value !== user.id)
                .map((it) => ({
                    title: it.label,
                    value: it.value
                }))

            const items = [...getCreatedByDefault(user), ...others]
            setUsersList(items);
        })
    }, []);

    const setType = (e) => {
        setDateToError(null);
        if (e.target.value == "NOTE") {
            setNote({
                ...note,
                type: "NOTE"
            });
            return;
        }
        setNote({
            ...note,
            type: "ACTION",
            // dateTo: null
        });
    }

    const setDateTo = (moment) => {
        setNote({
            ...note,
            dateTo: moment.format()
        });
        setDateToError(null);
    }

    const setText = (e) => {
        setNote({
            ...note,
            text: e.target.value
        })
        setTextError(null);
    }

    const validateText = () => {
        if (!note.text) {
            setTextError(t("actions.and.notes.edit.note.popup.can.not.be.empty"));
            return false;
        }
        if (note.text.length > 1000) {
            setTextError(t("actions.and.notes.edit.note.popup.char.limit"));
            return false;
        }
        setTextError(null);
        return true;
    }

    const validateDate = () => {
        if (note.type == "ACTION") {
            if (!note.dateTo) {
                setDateToError(t("actions.and.notes.edit.note.popup.can.not.be.empty"));
                return false;
            }
            const isPast = Moment(note.dateTo) < Moment().startOf('day');
            const dateChanged = data?.note?.dateTo !== note.dateTo;
            if (dateChanged && isPast) {
                setDateToError(t("actions.and.notes.edit.note.popup.can.not.be.in.past"));
                return false;
            }
        }
        setDateToError(null);
        return true;
    }

    const validate = () => {
        const isTextValid = validateText();
        const isDateValid = validateDate();
        return isTextValid && isDateValid;
    }

    const onSaved = (response) => {
        toggleEditNotePopup();
        onChanged(response);
    }


    const changed = useMemo(() => {
        return JSON.stringify({
            ...note,
            dateTo: note.type === "NOTE" ? null : note.dateTo,
        }) !== JSON.stringify(initialValue) || assignedTo !== initialAssigneeId
    }, [note, initialValue, assignedTo, initialAssigneeId])


    const saveNote = useCallback(() => {
        if (!validate() || !changed) {
            return;
        }
        if (note.id) {
            const changingType = note.type  !== data.note.type;
            const editNoteCallback = () => {
                API.editNote({
                    ...note,
                    assignedTo: undefined,
                    dateTo: note.type === "NOTE" ? null : note.dateTo,
                    assignedToId: note.type === "NOTE" ? undefined : assignedTo,
                    active: changingType ? true : note.active
                }, onSaved);
            }
            const isReassignAction = note.assignedTo.id !== assignedTo && note.type === "ACTION";
            if (isReassignAction) {
                checkActionConfirmationForAA(t, note, user, ACTION_TYPES.REASSIGN, toggleConfirmActionPopup, editNoteCallback);
                return;
            }
            // const isChangingToAction = note.type === "NOTE"
            console.log(data, note);

            editNoteCallback();
            return;
        }
        const hasAssignedTo = note.type === "ACTION" && hasAccessToAssignableActions;
        API.createNote({
            ...note,
            dateTo: note.type === "NOTE" ? null : note.dateTo,
            assignedToId: hasAssignedTo ? assignedTo : undefined
        }, onSaved);
    }, [changed, validate, assignedTo, note, onSaved, hasAccessToAssignableActions]);

    const deleteNote = () => {
        const callbackFn = () => {
            API.deleteNote(note.id, null, null, () => {
                toggleEditNotePopup();
                if (onDeleted) {
                    onDeleted(note.id);
                    return;
                }
            });
        }

        checkActionConfirmationForAA(t, note, user, ACTION_TYPES.DELETE, toggleConfirmActionPopup, callbackFn);
    }

    const setAssigned = (assignee) => {
        setAssignedTo(assignee.value);
    }

    const assignedToName = useMemo(() => {
        const assignee = usersList.find((it) => it.value === assignedTo);
        return assignee?.title || '';
    }, [assignedTo, usersList]);

    const calendarPlaceHolder = () => {
        return <span tabIndex="0" className="pointer">
            <i className="fa fa-calendar"></i>
            <span>{note.dateTo ? Moment(note.dateTo).format("[MMM-DD]") : <CLabel k="actions.and.notes.edit.note.popup.click.to.set.done" />}</span>
        </span>
    }

    const canDelete = useMemo(() => {
        if (!note.id) {
            return false;
        }

        const isOwn = user?.id == note?.createdBy?.id;
        return isOwn || isContentAdmin(user, isAliasEnabled);
    }, [note, isAliasEnabled, user]);

    return <div className="modal modal-action-note-dialog open">
        <div className="modal-header-mobile show-mobile">
            <a className="btn btn-back close-modal" onClick={toggleEditNotePopup}><span className="upper"><CLabel k="actions.and.notes.edit.note.popup.close" /></span></a>
            <div className="title-page">
                <CLabel k={note.id ? "actions.and.notes.edit.note.popup.edit.note" : "actions.and.notes.edit.note.popup.new.note"} />
            </div>
        </div>
        <div className="modal-dialog modal-sm">
            <div className="modal-content">
                <div className="modal-header">
                    <div className="modal-title">
                        <div className="name-block small">{data.property?.name || data.company?.name}</div>
                    </div>
                </div>
                <div className="modal-body">
                    <form className="form form-small form-new-note">
                        <div className="box-control">
                            <div className="box-checkbox middle box-inline">
                                <input type="radio" id="radio0" name="date" onChange={setType} value="NOTE" checked={note?.type == "NOTE"} /><label htmlFor="radio0"><CLabel k="actions.and.notes.edit.note.popup.note.no.due.date" /></label>
                            </div>
                            <div className="box-checkbox middle box-inline">
                                <input type="radio" id="radio1" name="date" onChange={setType} value="ACTION" checked={note?.type == "ACTION"} /><label htmlFor="radio1"><CLabel k="actions.and.notes.edit.note.popup.action.with.due.date" /></label>
                            </div>
                            <div className={`middle box-inline datepicker-wrapper ${note?.type == 'ACTION' ? 'open' : ''}`}>
                                <div className="control-datepicker" style={{ marginBottom: hasAccessToAssignableActions ? 0 : 10 }}>
                                    <DatePicker
                                        animation="slide-up"
                                        calendar={Utils.createCalendar(note.dateTo, t("actions.and.notes.edit.note.popup.please.input"), (now) => now < Moment().startOf('day'))}
                                        value={note.dateTo && Moment(note.dateTo)}
                                        onChange={setDateTo}
                                    >
                                        {calendarPlaceHolder}
                                    </DatePicker>
                                    {dateToError &&
                                        <div className="message-error">{dateToError}</div>
                                    }
                                </div>
                            </div>
                            {hasAccessToAssignableActions && <div className={`middle box-inline datepicker-wrapper ${note?.type == 'ACTION' ? 'open' : ''}`}>
                                <div className="control-assign-to">
                                    <Trans i18nKey="actions.and.notes.assigned.to" values={{
                                        name: assignedToName,
                                        icon: user.id === assignedTo ? "👉 " : ""
                                    }}>
                                        <span style={{ fontSize: 16, top: 1, position: "relative", lineHeight: "16px" }}></span>
                                        <CInlineDropdown
                                            as="a"
                                            items={usersList}
                                            onSelect={setAssigned}
                                        />
                                    </Trans>
                                </div>
                            </div>}
                        </div>
                        <div className="box-control">
                            <textarea className="form-control" value={note.text} onChange={setText} />
                            {textError &&
                                <div className="message-error">{textError}</div>
                            }
                        </div>
                    </form>
                </div>
                <div className="modal-footer hide-mobile">
                    <div className="clearfix">
                        <div className="box-btn box-right">
                            <a className="btn btn-grey btn-sm close-modal" onClick={toggleEditNotePopup}><span className="upper"><CLabel k="actions.and.notes.edit.note.popup.cancel" /></span></a>
                            <AccessRestriction>
                                <a className={`btn btn-red btn-sm close-modal ${note.id && !changed && 'disabled'}`} onClick={saveNote}>
                                    <span className="upper">
                                        {note.id ?
                                            <CLabel k="actions.and.notes.edit.note.popup.update" />
                                            : <CLabel k="actions.and.notes.edit.note.popup.create" />}
                                    </span>
                                </a>
                            </AccessRestriction>
                        </div>
                        {canDelete &&
                            <AccessRestriction>
                                <a className="btn btn-border-grey btn-sm close-modal" onClick={deleteNote}>
                                    <span className="upper"><CLabel k="actions.and.notes.edit.note.popup.delete" /></span>
                                </a>
                            </AccessRestriction>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="modal-footer-mobile modal-footer-mobile-edit-note show-mobile">
            <a className="btn btn-grey btn-sm close-modal" onClick={toggleEditNotePopup}><span className="upper"><CLabel k="actions.and.notes.edit.note.popup.cancel" /></span></a>
            <AccessRestriction>
                <a className={`btn btn-red btn-sm close-modal ${note.id && !changed && 'disabled'}`} onClick={saveNote}>
                    <span className="upper">
                        {note.id ?
                            <CLabel k="actions.and.notes.edit.note.popup.update" />
                            : <CLabel k="actions.and.notes.edit.note.popup.create" />}
                    </span>
                </a>
            </AccessRestriction>
        </div>
    </div>
}

export default withTranslation()(Connect(CNoteEditPopup, {
    dispatch: (dispatch) => ({
        toggleEditNotePopup: () => dispatch({ type: 'TOGGLE_POPUP_EDIT_NOTE' }),
        toggleConfirmActionPopup: (data) => dispatch({ type: "TOGGLE_POPUP_CONFIRM_ACTION", data: data }),
    }),
    state: (state) => ({
        user: state.session?.user,
        isAliasEnabled: state.session?.isAliasEnabled,
        hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions"),
    })
}));