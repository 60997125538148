import camelCase from 'lodash.camelcase';

const valueLabelMapper = (t, fieldName) => (option) => {
    return {
        ...option,
        value: option.value,
        label: t(`data.${camelCase(fieldName)}.${camelCase(option.value)}`, { ns: 'data', defaultValue: option.label }) || option.label
    };
};

const valueMapper = (t, fieldName) => (option) => {
    return {
        value: option,
        label: t(`data.${camelCase(fieldName)}.${camelCase(option)}`, { ns: 'data', defaultValue: option }) || option
    };
};

export const translateDropdownValues = (t, fieldName, options) => {
    if (!options) return options;
    const mapperFn = typeof options[0] === 'string' ? valueMapper : valueLabelMapper;
    if (fieldName === 'newChainScale') return options.map(mapperFn(t, 'chainScale'));
    return options.map(mapperFn(t, fieldName));
}

export const translateCategoricalValue = (t, fieldName, value) => {
    if (value === 'Unbranded Hotel') {
        return value;
    }
    if (fieldName === 'keywords') {
        return value.split(', ')
            .map(keyword => t(`data.${camelCase(fieldName)}.${camelCase(keyword)}`, `${keyword}`, { ns: 'data' }))
            .join(', ');
    }
    return t(`data.${camelCase(fieldName)}.${camelCase(value)}`, `${value}`, { ns: 'data' }); 
}

export const translateANPCCounter = (t, values) => {
    const recordsTypes = [];
    const {
        properties,
        companies,
        notes,
        actions
    } = values;
    if (actions > 0) {
        recordsTypes.push("A");
    }
    if (notes > 0) {
        recordsTypes.push("N");
    }
    if (properties > 0) {
        recordsTypes.push("P");
    }
    if (companies > 0) {
        recordsTypes.push("C");
    }
    return t(`actions.and.notes.export.popup.records${recordsTypes.join('')}`, {
        actions,
        notes,
        properties,
        companies
    });
}