import React, {useMemo, useState} from 'react';
import { CLabel } from '../../CLabel';
import * as DatePicker from 'rc-calendar/lib/Picker';
import { createCalendar, isMobile } from '../../../helpers/Utils';
import * as Moment from 'moment';
import './CDateRange.styles.scss';
import Connect from "../../../helpers/Connect";
import { useTranslation } from 'react-i18next';
import { getDateLocale } from "../../../helpers/LocaleHelpers";
import { createPortal } from 'react-dom';

const options = {
    ANYTIME: 'ANYTIME',
    IN_LAST_DAYS: 'IN_LAST_DAYS',
    BETWEEN: 'BETWEEN'
}

const CDateRangeDialog = ({ closeModal, onConfirm, value }) => {
    const [selectedOption, setSelectedOption] = useState(value.selectedOption);
    const [dateFrom, setDateFrom] = useState(value.dateFrom);
    const [dateTo, setDateTo] = useState(value.dateTo);
    const [days, setDays] = useState(value.days);
    const { t, i18n } = useTranslation();

    const onSelect = (event) => {
        setSelectedOption(event.target.value);
    };

    const calendarDate = (value) => {
        return createCalendar(value, t("controls.date.range.calendar.please.input"), undefined, i18n.resolvedLanguage);
    };

    const calendarPlaceholder = (date) => () => {
        return <span tabIndex="0" className="pointer">
          <i className="fa fa-fw fa-calendar"></i>
          {date ?
              <span>{Moment(date).tz('UTC').locale(getDateLocale(i18n.resolvedLanguage)).format("[MMM-DD]")}</span> :
              <span className="text-grey">{t('controls.date.range.calendar.click.to.set.date')}</span>
          }
        </span>;
    };

    const onDateFromChange = (moment) => {
        setDateFrom(moment.tz('UTC').startOf('day').format());
    };

    const onDateToChange = (moment) => {
        setDateTo(moment.tz('UTC').endOf('day').format());
    };

    const isDisabled = useMemo(() => {
        if (!selectedOption) {
            return true;
        }
        if (selectedOption === options.BETWEEN) {
            if (dateFrom && dateTo) {
                if (Moment().tz('UTC').endOf('day').diff(dateTo) < 0) {
                    return true;
                }
                return Moment(dateTo).diff(Moment(dateFrom)) < 0;
            }
            return true;
        }
        return false;
    }, [selectedOption, dateFrom, dateTo]);

    const onDaysChange = (event) => {
        setDays(event.target.value);
    };

    const onConfirmClick = () => {
        if (isDisabled) {
            return;
        }

        onConfirm({
            selectedOption,
            dateFrom,
            dateTo,
            days: Number(days)
        });
    }

    return createPortal(<>
        <div className={"modal modal-date-changed open"}>
            <div className="modal-header-mobile show-mobile">
                <a className="btn btn-back close-modal" onClick={closeModal}>
                    <span className="upper">
                        <CLabel k="settings.custom.popup.close"/>
                    </span>
                </a>
                <div className="title-page"><CLabel k="controls.date.range.show.notes" /></div>
            </div>
            <div className={`modal-dialog ${!isMobile() && 'dialog-desktop'}`}>
                <div className="modal-content">
                    <div className="modal-body">
                        <div><CLabel k="controls.date.range.show.notes" /></div>
                        <div className="radio-group">
                            <input
                                id="radio-anytime"
                                type="radio"
                                name="date"
                                onChange={onSelect}
                                value={options.ANYTIME}
                                checked={selectedOption === options.ANYTIME}/>
                            <label htmlFor="radio-anytime">
                                <CLabel k="controls.date.range.anytime" />
                            </label>
                        </div>
                        <div className="radio-group align-center">
                            <input
                                id="radio-last-days"
                                type="radio"
                                name="date"
                                onChange={onSelect}
                                value={options.IN_LAST_DAYS}
                                checked={selectedOption === options.IN_LAST_DAYS}
                            />
                            <label htmlFor="radio-last-days">
                                <CLabel k="controls.date.range.in.the.last" />
                                <input type="number" value={days} onChange={onDaysChange} />
                                <CLabel k="controls.date.range.days" />
                            </label>
                        </div>
                        <div className="radio-group">
                            <input
                                id="radio-between"
                                type="radio"
                                name="date"
                                onChange={onSelect}
                                value={options.BETWEEN}
                                checked={selectedOption === options.BETWEEN}
                            />
                            <label htmlFor="radio-between">
                                <div className="radio-label-row">
                                    <div className="radio-label">
                                        <CLabel k="controls.date.range.between" />
                                    </div>
                                    <DatePicker
                                        animation="slide-up"
                                        calendar={calendarDate(dateFrom)}
                                        name='dateFrom'
                                        value={dateFrom && Moment(dateFrom).tz('UTC')}
                                        onChange={onDateFromChange}
                                    >
                                        {calendarPlaceholder(dateFrom)}
                                    </DatePicker>
                                </div>
                                <div className="radio-label-row">
                                    <div className="radio-label">
                                        <CLabel k="controls.date.range.and" />
                                    </div>
                                    <DatePicker
                                        animation="slide-up"
                                        calendar={calendarDate(dateTo)}
                                        name='dateTo'
                                        value={dateTo && Moment(dateTo).tz('UTC')}
                                        onChange={onDateToChange}
                                    >
                                        {calendarPlaceholder(dateTo)}
                                    </DatePicker>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer hide-mobile">
                        <div className="clearfix">
                            <div className="box-btn d-flex justify-content-center">
                                <a className="btn btn-grey btn-sm close-modal" onClick={closeModal}><span className="upper"><CLabel k="cancel"/></span></a>
                                <a className={`btn btn-red btn-sm close-modal ${isDisabled ? 'disabled' : ''}`} onClick={onConfirmClick}>
                                    <span className="upper">
                                        <CLabel k="ok"/>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={`modal-footer-mobile show-mobile ${isDisabled ? 'disabled-background' : ''}`}>
                        <a className="btn btn-grey btn-normal close-modal" onClick={closeModal}><span className="upper"><CLabel k="cancel"/></span></a>
                        <a className={`btn btn-red btn-normal close-modal ${isDisabled ? 'disabled' : ''}`} onClick={onConfirmClick}>
                            <span className="upper">
                                <CLabel k="ok"/>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>, document.body);
};

export default Connect(CDateRangeDialog, {
    state: (state) => {
        return {
            translation: state.translation,
        }
    }
});