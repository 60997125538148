LENAV_SESSION = "LENAV_SESSION"



module.exports = (state = { filters: [], shared: [], version: Math.random() }, action) ->
  if action.type == "SET_FILTERS"
    filters: action.filters
    shared: state.shared
    all: action.filters.concat(state.shared)
    version: Math.random()
  else if action.type == "SET_FILTERS_SHARED"
    filters: state.filters
    shared: action.shared
    all: state.filters.concat(action.shared)
    version: Math.random()
  else
    state
