Connect = require '../helpers/Connect'
{ CLabel } = require './CLabel'
{ API } = require '../redux/Store'
Utils = require '../helpers/Utils'

module.exports = Connect createReactClass(
  displayName: 'CSsoFrame'

  componentDidMount: () ->
    window.addEventListener 'message', @onAuthMessage, false

  componentWillUnmount: () ->
    @cancel()

  onAuthMessage: (result) -> 
    if(result.data?.auth && result.data?.hasRole)
      @cancel()
      # window.location.href = result.data.redirect
      window.location.href = @props.url
    else if (result.data?.auth != undefined)
      @cancel()
      if @props.onAuthFail
        @props.onAuthFail(result.data.auth, result.data.hasRole)

  getInitialState: () ->
    {
      loading: true,
      url: @props.url + '&frame=true'
    }

  componentWillReceiveProps: (newProps)->
    @setState
      url: @props.url + '&frame=true'
    
  cancel: () ->
    @setState
      url: 'about:blank'
      loading: false
    window.removeEventListener 'message', @onAuthMessage

  render: ->
    React.createElement("iframe", {"id": "wp-sfc-sso", "sandbox": "allow-top-navigation allow-scripts allow-popups-to-escape-sandbox", "src": (@state.url), "style": (display: 'none')})
), {
  
}