EXPORT_ACTION_DOWNLOAD = 'download'
EXPORT_ACTION_EMAIL = 'email'
DEFAULT_REPORT_NAME = "report"


Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'
{ API } = require '../../redux/Store'
{ createIframeForDownload } = require '../../helpers/Utils'
{ withTranslation } = require 'react-i18next' 

module.exports = withTranslation() Connect createReactClass(
  getInitialState: () ->
    reportName: ''

  onChange: (e) ->
    reportNameError = null
    if (e.target.value.match(/^(con|prn|aux|nul|com[0-9]|lpt[0-9])$|([<>:"\/\\|?*])|(\.)$/ig))
      reportNameError = @props.t('custom.incorrect.filename')
    @setState reportName: e.target.value, reportNameError: reportNameError

  onSubmit: (e) ->
    e.preventDefault()
    @onSend()

  filterHiddenFields: (form) ->
    result = {}
    Object.keys(form)?.forEach (key) ->
      if form.hasOwnProperty(key)
        if form[key]?.hidden
          result[key] = sortingNumber: form[key].sortingNumber, isHeading: form[key].isHeading
        else
          result[key] = form[key]
    result

  onSend: () ->
    cb = (exportInfo) =>
      @props.setExportInProgress(exportInfo)
    ecb = () ->
    fcb = () =>
      @props.toggleExportFileRename()

    receivers = null
    if @props.data?.state?.exportAction == EXPORT_ACTION_EMAIL
      receivers = @props.data?.state?.selectedUsers
    exportForm = {
      fileName: (@state.reportName || DEFAULT_REPORT_NAME).trim()
      format: @props.data.state.dataFormat.NAME
      filterForm: @filterHiddenFields(@props.data.state.searchForm)
      filterNotes: @props.data.state.filterNotes
      receivers: receivers
      title: @props.data?.state?.title
      subtitle: @props.data?.state?.subtitle
      textBody: @props.data?.state?.textBody
      includeNotes: @props.data?.state?.includeNotes
      includeCompanyIds: @props.data?.state?.includeCompanyIds
      dateFormat: @props.data?.state?.dateFormat
    }
    
    if @props.data?.state?.timelineExport
        exportForm.timelinePriorDate = @props.data?.state?.timelinePriorDate

    API.startExport exportForm, cb, ecb, fcb

  render: () ->
    React.createElement("div", {"className": "modal open"},
      React.createElement("div", {"className": "modal-header-mobile show-mobile"},
        React.createElement("a", {"className": "btn btn-back close-modal", "onClick": (@props.toggleExportFileRename)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "close"}))),
        React.createElement("div", {"className": "title-page"}, React.createElement(CLabel, {"k": "custom.rename.file.name"}))
      ),
      React.createElement("div", {"className": "modal-dialog modal-sm"},
        React.createElement("div", {"className": "modal-content"},
          React.createElement("div", {"className": "modal-header"},
            React.createElement("div", {"className": "modal-title"},
              React.createElement("div", {"className": "name-block small"}, React.createElement(CLabel, {"k": "custom.rename.file.name"}))
            )
          ),
          React.createElement("div", {"className": "modal-body"},
            React.createElement("p", {"style": (paddingBottom: '10px')}, React.createElement(CLabel, {"k": "custom.rename.file.info"})),
            React.createElement("form", {"className": "form form-small", "onSubmit": (@onSubmit)},
              React.createElement("div", {"className": "box-control"},
                React.createElement("input", {"style": (height: '34px', lineHeight: '34px'), "className": "form-control", "type": 'text', "maxLength": "30", "value": (@state.reportName), "onChange": (@onChange)}),
                (if @state.reportNameError
                  React.createElement("div", {"className": "message-error under-control"}, (@state.reportNameError))
                )
              )
            )
          ),
          React.createElement("div", {"className": "modal-footer hide-mobile"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "box-btn box-right"},
                React.createElement("a", {"className": "btn btn-normal btn-grey close-modal", "onClick": (@props.toggleExportFileRename)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "custom.cancel"}))),
                React.createElement("a", {"className": "btn btn-red btn-normal close-modal #{if @state.reportNameError then 'disabled'}", "onClick": (@onSend)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "custom.proceed"})))
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": "modal-footer-mobile show-mobile"},
        React.createElement("a", {"className": "btn btn-normal btn-grey close-modal", "onClick": (@props.toggleExportFileRename)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "custom.cancel"}))),
        React.createElement("a", {"className": "btn btn-red btn-normal close-modal #{if @state.reportNameError then 'disabled'}", "onClick": (@onSend)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "custom.proceed"})))
      )
    )

), {
  state: (state) ->
    searchForm: state.searchForm.form
    translation: state.translation
  dispatch: (dispatch) ->
    setExportInProgress: (exportInfo) -> dispatch(type: 'SET_EXPORT_IN_PROGRESS', payload: exportInfo)
    toggleExportFileRename: () -> dispatch(type: 'TOGGLE_POPUP_FILE_RENAME')
}