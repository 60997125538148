import { useTranslation } from "react-i18next";
import logo from "../../../assets/img/LE_Corporate_Logo_Circle.svg";
import Connect from "../../helpers/Connect";
import { getDateLocale } from "../../helpers/LocaleHelpers";
import Moment from 'moment';

const PrintHeader = Connect(({ session }) => {
    const { t, i18n } = useTranslation();
    const locale = getDateLocale(i18n.resolvedLanguage)
    return <div className="show-print print-header">
        <img src={logo} alt=""/>
        <span>
            {t('print.property.header.printed', {
                date: Moment().tz('UTC').locale(locale).format("ll"),
                author: `${session?.user?.firstName} ${session?.user?.lastName}`
            })}
        </span>
    </div>;
}, {
    state: (state) => {
        return {
            session: state.session
        };
    }
});

export default PrintHeader;