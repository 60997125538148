Connect = require '../../helpers/Connect'
{API} = require '../../redux/Store'
CInput = require '../CInput'
{ CLabel } = require '../CLabel'
{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
{ debounce } = require "throttle-debounce"



module.exports = Connect createReactClass(
  displayName: "CPagination"

  getInitialState: ()->
    changeCallback: @props.changeCallback
    paginationFunc: @props.paginationFunc
    nextAvailable: true
    prevAvailable: false
    currentPage: 1
    needToChange: true

  componentWillReceiveProps: (newProps)->
    newNextAvailable
    newPrevAvailable
    if (newProps.currentPage >= newProps.totalPages)
      newNextAvailable = false
    else
      newNextAvailable = true
    if (newProps.currentPage > 1)
      newPrevAvailable = true
    else
      newPrevAvailable = false
    @setState({nextAvailable: newNextAvailable, prevAvailable: newPrevAvailable, currentPage: newProps.currentPage})

  goNext: () ->
    if (@state.nextAvailable)
      @props.paginationFunc(@state.currentPage + 1)

  goLast: () ->
    if (@state.nextAvailable)
      @props.paginationFunc(@props.totalPages)

  goFirst: () ->
    if (@state.prevAvailable)
      @props.paginationFunc(1)

  goPrev: () ->
    if (@state.prevAvailable)
      @props.paginationFunc(@state.currentPage - 1)

  onChangePage: (e) ->
    newPage = parseInt(e.target.value)
    if (newPage && newPage > 0 && newPage <= @props.totalPages)
      @setState({currentPage: newPage, needToChange: true})
      @debouncedChange(newPage)
    else if e.target.value == ''
      @setState({currentPage: '', needToChange: false})

  debouncedChange: debounce(300, (newPage) -> if @state.needToChange then @props.paginationFunc(newPage))

  render: ()->
    styleVar = {margin: '10px', minWidth: '100px', width: '100px'}
    nextDisabled = if @state.nextAvailable then '' else 'disabled'
    prevDisabled = if @state.prevAvailable then '' else 'disabled'
    React.createElement("div", {"className": "clearfix form-small admin-pagination"},
      React.createElement("div", {"className": "control-wrapper"},
      React.createElement("a", {"className": "btn btn-red btn-normal upper element #{prevDisabled}", "onClick": (@goFirst)}, "First"),
      React.createElement("a", {"className": "btn btn-red btn-normal upper element #{prevDisabled}", "onClick": (@goPrev)}, "Prev"),
      React.createElement("input", {"value": (@state.currentPage), "type": "text", "className": "form-control element", "onChange": (@onChangePage)}),
      React.createElement("span", {"style": ({margin: '15px'})}, " of ", (@props.totalPages), " "),
      React.createElement("a", {"className": "btn btn-red btn-normal upper element #{nextDisabled}", "onClick": (@goNext)}, "Next"),
      React.createElement("a", {"className": "btn btn-red btn-normal upper element #{nextDisabled}", "onClick": (@goLast)}, "Last")
      )
    )
)