
Autolinker = require 'autolinker'
Moment = require "moment"
Calendar = require('rc-calendar').default
{ CLabel } = require '../cmp/CLabel'
{ getDateLocale, getCalendarLocale } = require "../helpers/LocaleHelpers";

class Utils
  DATA_EXPORT:
    DATA_FORMAT_CSV:
      NAME:'csv'
    DATA_FORMAT_XLS:
      NAME:'xlsx'
      DOWNLOAD_LIMIT: 5000
      EMAIL_LIMIT: 10000
    DATA_FORMAT_PDF:
      NAME:'pdf'
      DOWNLOAD_LIMIT: 2500
      EMAIL_LIMIT: 5000
    DATA_FORMAT_NOTES_CSV:
      NAME:'notes_csv'
    DATA_FORMAT_NOTES_XLS:
      NAME:'notes_xlsx'
      DOWNLOAD_LIMIT: 5000
      EMAIL_LIMIT: 10000


  EXPORT_PERIOD:
    WEEKLY: 'WEEKLY'
    MONTHLY: 'MONTHLY'

  # Roles by priority to display
  ROLES_SHORT:
    "Developer/Owner": "Dev/Own"
    "Management":"Mgmt"
    "Architect":"Arch"
    "Designer":"Desgn"
    "Contractor":"Contr"
    "Purchaser":"Purch"
    "Lender":"Lender"
    "Buyer":"Buyer"
    "Seller":"Seller"
    "Servicer":"Servcr"
    "Receiver":"Rcvr"
    
  getSingleRoleByPriority: (roles)->
    roleLong = Object.keys(@ROLES_SHORT).find((role) => roles.includes(role))
    return @ROLES_SHORT[roleLong]

  fireEvent: (fn)->
    setTimeout fn, 0

  isEmpty: (value)->
    value in ['', null, undefined, 0]

  isEmptyObject: (o)->
    @keyLength(o) == 0

  keyLength: (o)->
    Object.keys(o).length

  prettifyFieldName: (s)->
    if s
      s = s.replace(/([A-Z1-9])/g, ' $1').trim()
      s = s.charAt(0).toUpperCase() + s.slice(1)
      s

  arraysEqual: (a, b)->
    if a == b
      return true
    if a == null or b == null
      return false
    if a?.length != b?.length
      return false

    i = 0
    while i < a?.length
      if a[i] != b[i]
        return false
      ++i
    true

  trimQuotes: (s)->
    if s?.startsWith("'") && s.endsWith("'")
      s.substring(1, s.length - 1)
    else
      s

  getColorByChanges: (object, fieldName, additionalClass = "")=>
    suppressedFields = [] #["notes", "recordStatus", "status", "yearBuilt", "brandYear", "lastRenovationYear", "lastSold"]
    if fieldName in suppressedFields
      return ""
    date = object?[fieldName]
    color = @getColorByDate(date)
    if additionalClass && color
      return additionalClass + ' ' + color
    else
      return color

  #Deprecated to remove
  getLegacyHistoryColor: (object, fieldName, additionalClass = "")=>
    suppressedFields = [] #["notes", "recordStatus", "status", "yearBuilt", "brandYear", "lastRenovationYear", "lastSold"]
    if fieldName in suppressedFields
      return ""
    date = object?.history?[fieldName]
    color = @getColorByDate(date)
    if additionalClass && color
      return additionalClass + ' ' + color
    else
      return color

  getColorByDate: (date, defaultColor = "")->
    if !date
      return defaultColor
    if Moment().diff(date, 'days') <= 7
      return "lite-red"
    if Moment().diff(date, 'days') <= 30
      return "orange"
    return defaultColor

  getHistoryColor: (object, fieldName, additionalClass = "")=>
    suppressedFields = [] #["notes", "recordStatus", "status", "yearBuilt", "brandYear", "lastRenovationYear", "lastSold"]
    if fieldName in suppressedFields
      return ""
    updatedAt = object?.history?[fieldName + 'UpdatedAt']
    color = @getColorByDate(updatedAt)
    if additionalClass && color
      return additionalClass + ' ' + color
    else
      return color

  isMobile:()->
    window.innerWidth < 769

  formatNumber: (input)->
    input?.toLocaleString(Moment.locale())

  formatBelonging: (input, currentLanguage)->
    if (currentLanguage == "en")
      return input?.concat("'s")
    if (currentLanguage == "fr")
      if input.match(/^[aeiou]/i)
        return "d’#{input}";
      return "de #{input}";
    return input

  getProjectIdChange: (project, name)->
    idKey = name + "IdBit"
    return project?.history?[idKey]

  getProjectContactIdChange: (project, name)->
    idKey = name + "ContactIdBit"
    return project?.history?[idKey]

  getCompanies: (property)=>
    result = [[]]
    if property["developer"]
      property["developer"].fieldName = "developer"
      property["developer"].idNew = property.history?["devOwnCompanyIdBit"]
      property["developer"].idUpdatedAt = property.devOwnCompanyIdUpdatedAt
      property["developer"].contactIdUpdatedAt = property.devOwnContactIdUpdatedAt
      property["developer"].contactIdNew = property.history?["devOwnContactIdBit"]
      property["developer"].contact = property["developerContact"] || property["developer"].contact
      result[0].push property["developer"]
    if property["manager"]
      property["manager"].idNew = property.history?["managementCompanyIdBit"]
      property["manager"].idUpdatedAt = property.managementCompanyIdUpdatedAt
      property["manager"].contactIdUpdatedAt = property.managementContactIdUpdatedAt
      property["manager"].contactIdNew = property.history?["managementContactIdBit"]
      property["manager"].fieldName = "manager"
      property["manager"].contact = property["managementContact"] || property["manager"].contact
      result[0].push property["manager"]
    property.projects?.map (project) =>
      ["contractor", "purchaser", "architect", "designer","receiver","servicer","lender","buyer","seller"].filter (name, i)=>
        if project[name]
          project[name].fieldName = name
          project[name].idNew = @getProjectIdChange(project, name)
          project[name].idUpdatedAt = project[name + "IdUpdatedAt"] || project.createdAt
          project[name].contactIdNew = @getProjectContactIdChange(project, name)
          project[name].contact = project[name + "Contact"] || project[name].contact
          last = result[result.length-1]
          if !last || last.length == 2
            last = [project[name]]
            result.push(last)
          else
            last.push(project[name])
    result

  getCompaniesLegacy: (property)->
    result = [[]]
    if property["developer"]
      property["developer"].fieldName = "developer"
      result[0].push property["developer"]
    if property["manager"]
      property["manager"].fieldName = "manager"
      result[0].push property["manager"]
    project = property.projects[0]
    if project
      ["contractor", "purchaser", "architect", "designer","receiver","servicer","lender","seller", "buyer"].filter (name, i)=>
        if project[name]
          project[name].fieldName = name
          last = result[result.length-1]
          if !last || last.length == 2
            last = [project[name]]
            result.push(last)
          else
            last.push(project[name])
    result

  autolink: (s)->
    Autolinker.link(s, {
      newWindow: true
      replaceFn: (match)->
        tag = match.buildTag()
        tag.setAttr('target', '_top')

        tag
    })

  truncateTextWithDots: (text, length)->
    if text.length > length
      @autolink(text.substring(0, length - 3).concat('...'))
    else
      @autolink(text)

  formatPhoneNumber: (number)->
    number?.replace(/\s/g, '')

  isProximityFilterEmpty: (value)->
    !value?.location?.latitude && !value?.location?.longitude && !value?.address && !value?.radius

  getUserAgent: ()->
    ua = navigator.userAgent.toLowerCase()
    if /ipad|iphone|ipod/.test(ua) && !window.MSStream
      'IOS'
    else if /android/.test(ua)
      'ANDROID'
    else
      'BROWSER'

  isMobileAgent: ()=>
    @getUserAgent() in ['IOS', 'ANDROID']

  setBadgeCountOnMobileDevice: (count = 0)->
    if window.deviceName in ["IOS", "ANDROID"]
      location.href = "lenavigator://badge?value=#{count}"

  createIframeForDownload: (src, id)->
    console.log(src)
    id = id || 'iframeForDownload'
    iframe = document.getElementById(id)
    if !iframe
      iframe = document.createElement('iframe')
      iframe.id = id
      iframe.style.display = 'none';
      document.body.appendChild(iframe)
    iframe.src = src

  generateMailTo: (recipient, subject, message)->
    if typeof recipient != 'string' && recipient.isArray()
      recipient = recipient.join(';')
    res = 'mailto:' + recipient + '?subject=' + encodeURIComponent(subject)
    if message
      res += '&body=' + encodeURIComponent(message)
    res

  getProjectHistoryColor: (project)=>
    return @getColorByDate(project.createdAt)

  filterActivityFieldsByClient: (client)->
    activityFields = ["showNewConstruction", "showConversions", "showRenovations", "showSales", "showOpenAndOperating", "showDistressedAssets"]
    if client?.showNewConstruction || client?.showRenovations || client?.showSales || client?.showConversions || client?.showDistressedAssets
      activityFields.filter (a)-> a == "showOpenAndOperating" && client.showNewConstruction || a != "showOpenAndOperating" && client[a]
    else
      []

  filterActivityContacts: (client)->
    activityContacts = []
    activityContacts = if client?.showActivityContacts then ["isDeveloper", "isManager", "isContractor", "isPurchaser", "isArchitect", "isDesigner"] else ["isDeveloper", "isManager"]
    if client?.showSales
      activityContacts = activityContacts.concat ["isSeller", "isBuyer"]
    if client?.showDistressedAssets
      activityContacts = activityContacts.concat ["isReceiver", "isServicer", "isLender"]
    activityContacts

  filterProjectTypes: (projectTypes, props)->
    client = props?.client
    results = props?.mapResult?.results
    hasPipelineProperties = results?.some (it)-> it.propertyType == "Pipeline"
    projectTypes = projectTypes.filter (it)-> it != 'Sale' && it != 'Distressed Asset'
    if !client?.showNewConstruction && !hasPipelineProperties
      projectTypes = projectTypes.filter (it)-> it != 'New Construction'
    if !client?.showConversions
      projectTypes = projectTypes.filter (it)-> it != 'Conversion'
    if !client?.showRenovations
      projectTypes = projectTypes.filter (it)-> it != 'Renovation'
    projectTypes

  getUrlByPathname: (pathname)->
    if !pathname
      return {
        key: 'home'
        url: '/home'
      }
    switch true
      when pathname == '/results'
        key: 'search.results'
        url: '/results'
      when pathname == '/watches'
        key: 'watch.list'
        url: '/watches'
      when pathname == '/notes'
        key: 'actions.and.notes'
        url: '/notes'
      when /\/results\/[0-9]+/.test(pathname)
        key: 'saved.search'
        url: pathname
      when pathname == '/charts'
        key: 'chart.gallery'
        url: pathname
      when pathname == '/settings'
        key: 'settings'
        url: '/settings'
      when pathname.startsWith('/property')
        key: 'property'
        url: pathname
      when pathname.startsWith('/company')
        key: 'company'
        url: pathname
      when pathname == '/search'
        key: 'searchform'
        url: pathname
      else
        key: 'home'
        url: '/home'

  previousPath: (locationHistory)->
    pathname = locationHistory[locationHistory.length - 2]?[0]?.pathname
    return pathname

  getDaysOfWeek: () ->
    [
      {value: 1, label:"Monday"}
      {value: 2, label:"Tuesday"}
      {value: 3, label:"Wednesday"}
      {value: 4, label:"Thursday"}
      {value: 5, label: "Friday"}
      {value: 6, label: "Saturday"}
      {value: 7, label: "Sunday"}
    ]

  getWeekOfMonth: () ->
    [
      {value: 1, label:"First"}
      {value: 2, label:"Second"}
      {value: 3, label:"Third"}
      {value: 4, label:"Fourth"}
    ]

  timelineDatesFilter: (moment) ->
    !Moment(moment).startOf('day').isSameOrAfter('2020-02-01T00:00:00')

  timelineDatePlaceholder: (value)->
    React.createElement("span", {"className": "pointer"},
      React.createElement("a", {"className": "black"}, React.createElement("span", {"className": "fa fa-calendar"}), """
         
""", (if value then Moment(value).format("[MMM-DD]") else React.createElement(CLabel, {"k": 'timeline.export.date'}))
      )
    )

  createCalendar: (value, placeholder, disabledDate, resolvedLanguage)->
    React.createElement(Calendar, { \
      "locale": (getCalendarLocale(Moment.locale())),  \
      "dateInputPlaceholder": (placeholder),  \
      "formatter": 'YYYY-MM-DD',  \
      "showDateInput": (true),  \
      "disabledDate": (disabledDate),  \
      "defaultValue": (value && Moment(value))})

  getNextNoteAlertColor: (notes, user, isCompany)=>
    note = @getNoteForPopup(notes, user)
    if (!note)
      return 'gray-text'
    defaultColor = if isCompany then 'green-text' else 'yellow-text'
    if !note.active || !note.dateTo || note.type == "NOTE"
      return defaultColor
    return @getNoteStatusColor(note.dateTo, defaultColor)

  getUrgentNotesCount: (notes)=>
    if (!notes || !notes.length)
      return 0
    date = notes?.length && notes?.filter((note) => !!note.dateTo && note.active).sort((a, b) => a.dateTo - b.dateTo)

    if !date && notes?.length && notes?.filter((note) => !!note.dateTo && !note.active).length
      return 0
    if !date && notes?.length > 0
      return 0

    dates = date.map((note) => @getNoteStatusColor(note.dateTo))
    return dates.filter((color) => color == "red-text").length

  getNoteForPopup: (notes, user)->
    if (!user)
      return null
    filterRedOrangeDueDates = (note) => 
      return Moment().diff(Moment(note.dateTo)) > 0 || Moment(note.dateTo).isSame(Moment(), 'day') || Moment(note.dateTo).isSame(Moment().add(1, 'days'), 'day')
    filterActionsDue = (note) =>
      if !note.dateTo || !note.active || note.type == "NOTE"
        return false
      return true

    userId = user.id
    dueActions = notes?.length && notes?.filter(filterActionsDue).sort((a, b) => a.dateTo - b.dateTo)
    urgentActionsForUser = dueActions?.length && dueActions?.filter(filterRedOrangeDueDates).filter((action) => action.assignedTo?.id == userId)
    if (urgentActionsForUser?.length > 0)
      return urgentActionsForUser[0]
    if (dueActions?.length > 0)
      return dueActions[0]
    return notes?.length && notes?.sort((a, b) => b.createdAt - a.createdAt)[0]

  getNoteStatusColor: (dateTo, defaultStyle, optionalStyle)  ->
    if !dateTo
      return defaultStyle
    if Moment().diff(Moment(dateTo)) > 0 || Moment(dateTo).isSame(Moment(), 'day')
      return 'red-text'
    if Moment(dateTo).isSame(Moment().add(1, 'days'), 'day')
      return 'orange-text'
    if Moment(dateTo).isSame(Moment().add(2, 'days'), 'day')
      return optionalStyle || defaultStyle
    if Moment(dateTo).diff(Moment(), 'days') > 2
      return optionalStyle || defaultStyle
    return defaultStyle

  getNoteStatusDue: (dateTo, t, i18n)  ->
    locale = getDateLocale(i18n.resolvedLanguage)

    if Moment(dateTo).isSame(Moment(), 'day')
      return t('actions.and.notes.today')
    else if Moment(dateTo).isSame(Moment().add(1, 'days'), 'day')
      return t('actions.and.notes.tomorrow')
    else
      return Moment(dateTo).locale(locale).format("ll")

#TODO: Deprecated please use UserUtils.hasFeature
  hasFeature: (client, feature) ->
    client?.keywords?.split(",")?.map((x) => x.trim())?.indexOf(feature) >= 0

  uniqueItem: (value, index, self) ->
    return self.indexOf(value) == index;

  # /**
  # * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  # * 
  # * @param {String} text The text to be rendered.
  # * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  # * 
  # * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  # */
  getTextWidth: (text, font) ->
    canvas = document.createElement("canvas");
    context = canvas.getContext("2d");
    context.font = font;
    metrics = context.measureText(text);
    size = 
      width: Math.ceil(metrics.width)
      height: 12;

  ellipsis: (text, maxLength) ->
    if text.length > maxLength then text.substring(0, maxLength) + '...' else text

  copyLink: (onsuccess)->
    @copyText(window.location.href, onsuccess)

  copyText: (text, onsuccess)->
    console.log text
    textField = document.createElement('textarea')
    document.body.appendChild(textField)
    textField.readOnly = false;
    textField.innerText = text
    if @getUserAgent() != 'IOS'
      textField.select()
    else
      range = document.createRange()
      range.selectNodeContents(textField)
      selection = window.getSelection()
      selection.removeAllRanges();
      selection.addRange(range);
      textField.setSelectionRange(0, 999999);
    document.execCommand('copy')
    textField.remove()
    if onsuccess
      onsuccess()

  joinStrings: (values, separator = ', ')->
    return values.filter((item)-> Boolean(item)).join(separator);

  getCurrentKeywords: (keywordsAdded, keywordsRemoved, defaultKeywords)->
    defaultKeywords = defaultKeywords?.split(',')?.map((x) => x.trim()) || []
    keywordsAdded = keywordsAdded?.split(',')?.map((x) => x.trim()) || []
    keywordsRemoved = keywordsRemoved?.split(',')?.map((x) => x.trim()) || []
    return [...defaultKeywords, ...keywordsAdded].filter((keyword) =>
      return keywordsRemoved.indexOf(keyword) == -1
    ).filter(@uniqueItem).join(', ')

  getEnvStyle: () ->
    window.SETTINGS.realms[config.LE_ENV].STYLE

module.exports = new Utils
