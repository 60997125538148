import LocaleEnGB from 'rc-calendar/lib/locale/en_GB';
import LocaleEnUS from 'rc-calendar/lib/locale/en_US';
import LocaleFrFR from 'rc-calendar/lib/locale/fr_FR';
import LocaleRuRU from 'rc-calendar/lib/locale/ru_RU';
import LocaleZhCN from 'rc-calendar/lib/locale/zh_CN';
import LocaleEsES from 'rc-calendar/lib/locale/es_ES';
import LocalePtBR from 'rc-calendar/lib/locale/pt_BR';
import LocaleItIT from 'rc-calendar/lib/locale/it_IT';
import LocaleHiIN from 'rc-calendar/lib/locale/hi_IN';
import LocaleDeDE from 'rc-calendar/lib/locale/de_DE';

const calendarLocales = {
    'en-GB': LocaleEnGB,
    'en-US': LocaleEnUS,
    'fr': LocaleFrFR,
    'ru': LocaleRuRU,
    'zh-CN': LocaleZhCN,
    'es': LocaleEsES,
    'pt-BR': LocalePtBR,
    'it': LocaleItIT,
    'hi': LocaleHiIN,
    'de': LocaleDeDE
}

const momentLocalesAvailable = [
    "en", "af", "ar-dz", "ar-kw", "ar-ly", "ar-ma", "ar-sa", "ar-tn", "ar",
    "az", "be", "bg", "bm", "bn-bd", "bn", "bo", "br", "bs", "ca", "cs", "cv",
    "cy", "da", "de-at", "de-ch", "de", "dv", "el", "en-au", "en-ca", "en-gb",
    "en-ie", "en-il", "en-in", "en-nz", "en-sg", "eo", "es-do", "es-mx", "es-us",
    "es", "et", "eu", "fa", "fi", "fil", "fo", "fr-ca", "fr-ch", "fr", "fy", "ga",
    "gd", "gl", "gom-deva", "gom-latn", "gu", "he", "hi", "hr", "hu", "hy-am",
    "id", "is", "it-ch", "it", "ja", "jv", "ka", "kk", "km", "kn", "ko", "ku", "ky",
    "lb", "lo", "lt", "lv", "me", "mi", "mk", "ml", "mn", "mr", "ms-my", "ms", "mt",
    "my", "nb", "ne", "nl-be", "nl", "nn", "oc-lnc", "pa-in", "pl", "pt-br", "pt",
    "ro", "ru", "sd", "se", "si", "sk", "sl", "sq", "sr-cyrl", "sr", "ss", "sv", "sw",
    "ta", "te", "tet", "tg", "th", "tk", "tl-ph", "tlh", "tr", "tzl", "tzm-latn",
    "tzm", "ug-cn", "uk", "ur", "uz-latn", "uz", "vi", "x-pseudo", "yo", "zh-cn",
    "zh-hk", "zh-mo", "zh-tw"
];

export const getBrowserLanguages = () => {
    const languages = navigator.languages;
    return languages || [navigator.language];
}

export const getDateLocale = (resolvedLanguage = 'en') => {
    const languages = getBrowserLanguages();
    const language = languages.find(lang => lang.startsWith(resolvedLanguage) && momentLocalesAvailable.includes(lang));
    const dateLocale = language || resolvedLanguage;
    return dateLocale.toLocaleLowerCase();
}

export const shouldUseMiles = (resolvedLanguage) => {
    const locale = getDateLocale(resolvedLanguage);
    return locale.startsWith('en');
}

export const getCalendarLocale = (momentLocale) => {
    const availableCalendarLocales = Object.keys(calendarLocales);
    const availableLocale = availableCalendarLocales.find((locale) => {
        if (locale.toLocaleLowerCase() === momentLocale.toLocaleLowerCase()) {
            return true;
        }
        if (momentLocale.startsWith(locale) || locale.startsWith(momentLocale)) {
            return true;
        }
        return locale.startsWith(momentLocale.substring(0, 2));
    });
    const calendarLocale = calendarLocales[availableLocale];
    return calendarLocale || LocaleEnUS;
}
