Utils = require './Utils'
{ extend } = require './UtilsHelpers'

SMALLEST_COLOR = '#8D3040'

CHART_COLORS = [
  '#F0CC88',
  '#679DB3',
  '#72A699',
  '#DDCFBD',
  '#695541',
  '#939598',
  '#004F74',
  '#83A75F',
  '#926A80',
  '#6A715A',
  '#AE673E',
  '#D1D3D4',
  '#DBA84E',
  '#D29D91',
  '#DEC531',
  '#c85b6e',
  '#f8e6c4',
  '#89b3c4',
  '#91bab0',
  '#e3d7c8',
  '#a4876a',
  '#8f9093',
  '#37b7ff',
  '#9cb980',
  '#a88799',
  '#8d957a',
  '#c98b67',
  '#dadbdc',
  '#e1b66a',
  '#dcb2a9',
  '#e5d15d',
  '#d98e9b',
  '#fcf3e2'
  '#a0c2cf',
  '#a6c7bf',
  '#eee7df',
  '#c5b19f',
  '#a9aaac',
  '#6ac9ff',
  '#adc596',
  '#bda5b2',
  '#a5ac96',
  '#d6a88d',
  '#e3e3e4',
  '#eacb94',
  '#e6c8c1',
  '#ecdd89',
  '#d98afb',
  '#c2d8e0',
  '#c5dbd5',
  '#f4efea',
  '#d1c3b4',
  '#c3c4c5',
  '#9ddbff',
  '#c7d7b7',
  '#daccd4',
  '#c6cabc',
  '#e8cebf',
  '#f4f4f5',
  '#f2e0bf',
  '#f0ddd9',
  '#f5eec4'
]

generateColors = (data, isPieChart) ->
  moveIndex = false
  if isPieChart
    data = extend [], data.slice(1)
    sortedByMeasure = extend([], data).sort (a,b)-> a[1] < b[1]
    minValue = sortedByMeasure.pop()?[1]
    if data.length == 1
      [CHART_COLORS[0]]
    else
      data.map (it, idx)->
        if it[1] == minValue && !moveIndex
          moveIndex = true
          SMALLEST_COLOR
        else
          CHART_COLORS[if moveIndex then idx - 1 else idx]
  else
    data = data.data
    measures = data.slice(1).map (it)-> it.slice(1, -1)
    indexOfSmallest = (arr)->
      arrLength = arr.length
      lowest = 0
      for i in [0...arrLength]
        if isNaN(arr[i]) || arr[i] < arr[lowest] then lowest = i
      lowest

    indexesOfLowest = measures.map (it)-> indexOfSmallest(it)
    dominantLowest = indexesOfLowest.reduce (acc, value) ->
      acc[value] = (acc[value] || 0) + 1
      acc
    , {}
    minIndex = parseInt(Object.keys(dominantLowest).reduce (a, b) -> if dominantLowest[a] > dominantLowest[b] then a else b)

    if data[1].length == 3
      [CHART_COLORS[0]]
    else
      for i in [0...data[1].length - 2]
        if i == minIndex && !moveIndex
          moveIndex = true
          SMALLEST_COLOR
        else
          CHART_COLORS[if moveIndex then i - 1 else i]

getColors = (data)->
  sortedByMeasure = extend([], data).sort (a,b)->
    if a.measure1 < b.measure1
      1
    else if a.measure1 == b.measure1
      0
    else
      -1
  moveIndex = false
  min = sortedByMeasure.pop()?.measure1
  if data.length == 1
    [CHART_COLORS[0]]
  else
    data.map (it, idx)->
      if it.measure1 == min && !moveIndex
        moveIndex = true
        SMALLEST_COLOR
      else
        CHART_COLORS[if moveIndex then idx-1 else idx]

sortDimensionLabels = (array, dim1, dim2, dimensions, crossTable = false)->
  array = extend [], array
  sort = (sortOrder)->
    array = extend [], array
    array?.sort (a, b)->
      if (dim1 || dim1 == 0) && (dim2 || dim2 == 0)
        if sortOrder.indexOf(a[dim1][dim2]) > sortOrder.indexOf(b[dim1][dim2])
          1
        else if sortOrder.indexOf(a[dim1][dim2]) == sortOrder.indexOf(b[dim1][dim2])
          0
        else
          -1
      else if dim1 || dim1 == 0
        if sortOrder.indexOf(a[dim1]) > sortOrder.indexOf(b[dim1])
          1
        else if sortOrder.indexOf(a[dim1]) == sortOrder.indexOf(b[dim1])
          0
        else
          -1
      else
        if sortOrder.indexOf(a) > sortOrder.indexOf(b)
          1
        else if sortOrder.indexOf(a) == sortOrder.indexOf(b)
          0
        else
          -1

  handleError = (e)->
    console.error('Something went wrong...\n', e)
    array

  try
    hasOther = 'Other' in array.map (it) -> (it?.label || it?.dimension1?.value || it?[0])
    hasUnbranded = 'Unbranded' in (it) -> (it?.label || it?.dimension1?.value || it?[0])
    if (dimensions && 'brandedchainscale' in dimensions) && !crossTable
      sort(['Unbranded', 'Casino'])
    else if hasOther || hasUnbranded
      sort(['Other', 'Unbranded'])
    else
      array
  catch e then handleError e

module.exports =
  getParsedData: (chart, id, t, i18n)->
    createCustomTooltip = (label, value, countLabel, isStacked, withPercents) ->
      percent = if chart.options.includes('percent') then '%' else ''
      html = '<div><b>' + label  + '</b><br>'
      if isStacked
        html += '<span>' + countLabel + ':</span><b> ' + (if percent then value + percent else Utils.formatNumber(value)) + '</b></div>'
      else if withPercents
        total = chart.data.reduce (acc, next)->
          acc += next[withPercents]
        , 0
        html += '<span><b>' + Utils.formatNumber(value) + ' (' + (Number(value/total * 100).toFixed(2)) + '%)' + '</b></span>'
      else
        html += '<span>' + (if percent then value + percent else Utils.formatNumber(value)) + '</span> ' + countLabel + '</div>'

    if !chart
      return null

    titleTextStyle = italic: false, bold: true, fontSize: 11
    textStyle = italic: false, bold: false, fontSize: 12
    if (i18n.resolvedLanguage == "hi")
      titleTextStyle = italic: false, bold: false, fontSize: 14
      textStyle = italic: false, bold: false, fontSize: 11
    result = null

    prepareData = (chart)->
      output = {}
      isStacked = false

      chart.data.forEach (it, i)->
        if it.dimension1?.label && !output[it.dimension1?.value]
          if typeof it.dimension1.value == 'string'
            output[it.dimension1.value] = [it]
        else if it.dimension1?.value
          output[it.dimension1.value]?.push(it)

      isStacked = (Object.keys(output).filter (it)-> output[it].length > 1).length > 0
      if isStacked
        columns = chart.data.map (it)-> it.dimension1?.label
        columns = columns.filter (value, index, self)-> self.indexOf(value) == index
        rows = chart.data.map (it)-> it.dimension2?.label
        rows = rows.filter (value, index, self)-> self.indexOf(value) == index

        data = columns.map (c)->
          [c].concat(rows.map (r)->
            el = (chart.data.filter (it)->
              it.dimension1?.label == c && it.dimension2?.label == r )[0]
            el?.measure1 || NaN)

        data = sortDimensionLabels(data, 0, null, [chart.dimensions?[0]])
        if data.length > 0

          legend = (chart.data.map (it)-> it.dimension2?.label).filter((value, index, self)-> self.indexOf(value) == index)
          sortedLegend = sortDimensionLabels(legend, null, null, [chart.dimensions?[1]])
          sortOrder = sortedLegend.map (it) -> legend.indexOf(it)
          if typeof data?[0]?[0] == 'string'
            slicedData = data.map (it) -> it.slice(1)
            sortedMeasures = slicedData.map (it, idx)->
              sortOrder.reduce (acc, value, idx)->
                acc.push(it[sortOrder[idx]])
                acc
              , []

            data = sortedMeasures.map (it, idx)-> [data[idx][0]].concat(it)

          data = data.map (it)-> [it[0]].concat((it.slice(1).map (i, idx)->
            el = (chart.data.filter (it)->
              it.dimension2?.label == sortedLegend[idx] && it.measure1 == i)[0]
            [i].concat([createCustomTooltip(el?.dimension1?.label || el?.dimension1?.value, i, el?.dimension2?.label || el?.dimension2?.value, true)])).reduce (a,b)-> a.concat(b))
          data.unshift([(chart.dimensions[1] || chart.dimensions[0])].concat((sortedLegend.map (it)-> [it].concat([{ role: 'tooltip', type: 'string', p: html: true}])).reduce (a, b)-> a.concat(b)))
          if sortedLegend.length < 4
            chartArea = top: 20, bottom: 40
          else
            chartArea = top: 40, bottom: 40
      else
        data = [
          ['Element', chart.dimensions[0], { role: 'style' }, { role: 'tooltip', type: 'string', p: html: true}]
        ]
        chart.data = sortDimensionLabels(chart.data, 'dimension1', 'label', chart.dimensions)

        colors = getColors(chart.data)
        data = data.concat(chart.data.map (it, idx)->
          [it.dimension1?.label, it.measure1, colors[idx], createCustomTooltip(it?.dimension1?.label || it?.dimension1?.value, it.measure1, if "rooms" in chart.options then t('charts.rooms') else t('charts.properties'))])
        chartArea = height: '80%', bottom: 50, top: 10

        if data.length == 1
          data = data.concat([new Array(data[0].length).fill(0)])

      chartArea: chartArea
      data: data,
      isStacked: isStacked

    commonForVBarAndHBar = (()->
      data = prepareData(chart)

      className: if !data.isStacked then 'chart-not-stacked'
      data: data.data
      options:
        isStacked: data.isStacked
        colors: generateColors(data)
        legend: if !data.isStacked then 'none'
        tooltip: isHtml: true
        vAxis: titleTextStyle: titleTextStyle, textStyle: textStyle
        explorer:
          actions: ['dragToZoom', 'rightClickToReset']
          keepInBounds: true
          maxZoomIn: 100
        chartArea: extend({right: 10, width: '100%', height: '70%'}, data.chartArea)
    )()

    switch chart.type
      when 'Pie'
        data = chart.data.map (it)-> [it.dimension1?.label || 'empty', it.measure1, createCustomTooltip(it?.dimension1?.label || it?.dimension1?.value, it.measure1, (if "rooms" in chart.options then t('charts.rooms') else t('charts.properties')), false, 'measure1')]
        data.unshift(['text', 'text', {type: 'string', role: 'tooltip', p: html: true}])
        result =
          key:id
          graph_id:"PieChart"+id
          chartType:"PieChart"
          data:data
          options:
            colors: generateColors(data, true)
            tooltip: isHtml: true
            pieSliceText: 'value'
            chartArea: {width: '90%', height: '70%'}
        if chart.data?[0]?.measure2
          chartArea = left: 10, right: 10, width: '100%', height: '70%'
          data = chart.data.map (it)-> [it.dimension1?.label || 'empty', it.measure2, createCustomTooltip(it.dimension1?.label || el?.dimension1?.value, it.measure2, (if "rooms" in chart.options then t('charts.rooms') else t('charts.properties')), false, 'measure2')]
          data.unshift(['text', 'text', {type: 'string', role: 'tooltip', p: html: true}])
          result.options.legend = position: 'none'
          result.options.chartArea = chartArea
          result = [
            result,
            {
              key: id + 1,
              graph_id:"PieChart" + id + 1,
              chartType:"PieChart",
              options:
                colors: generateColors(data, true)
                legend: 'none'
                tooltip: isHtml: true
                pieSliceText: 'value'
                chartArea: chartArea
              data:data
            }
          ]
        if chart.options.includes('percent')
          if result instanceof Array
            result.forEach (it, idx)->
              it.options.pieSliceText = ''
          else
            result.options.pieSliceText = ''
        result
      when 'HBar'
        rowsSums = commonForVBarAndHBar.data.slice(1).map (i)->
          i.slice(1, -1).reduce((initial, value) ->
            if !isNaN(value) then initial += value else initial
          , 0)
        max = Math.max.apply(null, rowsSums)

        result = extend true, commonForVBarAndHBar,
          graph_id: "BarChart" + id
          chartType: "BarChart"
          options:
            hAxis:
              viewWindow: max: max
            vAxis:
              title: chart.dimensions[0]
            explorer:
              axis: 'horizontal'
            chartArea:
              left: 120
              right: 20
      when 'VBar'
        result = extend true, commonForVBarAndHBar,
          graph_id: "ColumnChart" + id
          chartType: "ColumnChart"
          options:
            vAxis:
              title: chart.legend?[0]?.title
              titleTextStyle: titleTextStyle
            explorer:
              axis: 'vertical'
            chartArea:
              left: 70
              bottom: 30
      when 'CrossTable'
        columns = chart.data.map (it)-> {type: 'number', label: it.dimension2?.label}
        columns = columns.filter (value, index, self)-> (self.map (it)-> it.label).indexOf(value.label) == index
        columns = sortDimensionLabels(columns, 'label', null, [chart.dimensions?[1]], true)
        rows = chart.data.map (it)-> it.dimension1?.label
        rows = rows.filter (value, index, self)-> self.indexOf(value) == index
        rows = rows.map (r)-> [r].concat(columns.map (c)->
          (chart.data.filter (it)-> it.dimension2?.label == c.label && it.dimension1?.label == r )[0]?.measure1 || 0)
        rows = sortDimensionLabels(rows, 0, null, [chart.dimensions?[0]], true)
        columns.unshift(type: 'string', label: '')
        getSum = (rows, column)->
          total = 0
          for i in [0...rows.length]
            total += rows[i][column]
          total

        rows.push([t('charts.total')].concat(columns.slice(1).map (c, index)-> getSum(rows, index + 1)))
        rows = rows.map (r) -> r.concat([r.slice(1).reduce ((acc, next)-> acc += next), 0])
        columns.push(type: 'number', label: t('charts.total'))

        cssClassNames =
          headerRow: 'header-row'
          oddTableRow: 'odd-table-row'
          tableRow: 'table-row'

        result =
          graph_id:"CrossTable"+id
          chartType:"Table"
          chartPackages: ['table']
          columns: columns
          options: width: '97%', height: '97%', cssClassNames: cssClassNames, sort: 'disable', allowHtml: true
          rows: rows

    result || null
