import Connect from '../../helpers/Connect';
import { CLabel } from '../CLabel';
import { withTranslation } from 'react-i18next';
import { useTranslation, Trans } from 'react-i18next';
import { getDateLocale } from '../../helpers/LocaleHelpers';
import { API } from '../../redux/Store';
import { useEffect, useState } from 'react';
import Moment from 'moment'

const CNoteHistoryPopup = ({ togglePopupNoteHistory, noteHistoryPopup, i18n }) => {
  const { t } = useTranslation();

  const { data: { note, shouldFetchHistory } } = noteHistoryPopup;
  const [history, setHistory] = useState(note.history || []);
  const [loading, setLoading] = useState(shouldFetchHistory);


  useEffect(() => {
    if (!shouldFetchHistory) {
      return;
    }
    API.notesHistory(note.id, (res) => {
      setHistory(res.history || []);
      setLoading(false);
    });
  }, [setHistory, setLoading, shouldFetchHistory]);

  const parseData = (historyItem) => {
    const { data } = historyItem;
    const info = [];
    if (data.type) {
      info.push(<div key={`type-${historyItem.createdAt}`}>{t(data.type.newValue == "NOTE" ? 'actions.and.notes.history.changedToNote' : 'actions.and.notes.history.changedToAction')}</div>)
    }
    if (data.text) {
      info.push(<div key={`text-${historyItem.createdAt}`}>{data.text.oldValue ? "Edited" : "Created"}</div>)
    }
    if (data.assignedTo && data.assignedTo.newValue) {
      const { firstName, lastName } = data.assignedTo.newValue;
      info.push(<div key={`name-${historyItem.createdAt}`}>{t('actions.and.notes.history.assignedTo', { name: `${firstName} ${lastName}` })}</div>)
    }
    const newDateTo = data.dateTo?.newValue !== data.dateTo?.oldValue || data.type?.newValue === "ACTION";
    if (data.dateTo && data.dateTo.newValue && newDateTo) {
      const dateTo = Moment(data.dateTo.newValue).locale(getDateLocale(i18n.resolvedLanguage)).format("ll");
      info.push(<div key={`date-${historyItem.createdAt}`}>{t('actions.and.notes.history.dueDate', { dateTo })}</div>);
    }
    if (data.active) {
      info.push(<div key={`active-${historyItem.createdAt}`}>{t(data.active.newValue ? 'actions.and.notes.history.markedAsNotDone' : 'actions.and.notes.history.markedAsDone')}</div>)
    }
    return info;
  }

  const generateRow = (historyItem) => {
    const firstItem = historyItem[0];
    return <div className="note-history-table-row" key={`test-${firstItem.itemId}-${firstItem.createdAt}`}>
      <div className="note-history-table-column column-date">
        <div>{Moment(firstItem.createdAt).locale(getDateLocale(i18n.resolvedLanguage)).format("ll")}</div>
        <div className="show-mobile" style={{ marginTop: 10 }}>{firstItem.createdBy?.firstName} {firstItem.createdBy?.lastName}</div>
      </div>
      <div className="note-history-table-column column-name hide-mobile">
        {firstItem.createdBy?.firstName} {firstItem.createdBy?.lastName}
      </div>
      <div className="note-history-table-column column-data">
        {historyItem.map((item) => parseData(item))}
      </div>
    </div>;
  }

  const getOriginalValue = (key, history, note) => {
    const firstHistoryItem = history.find((item) => {
      if (!item.data[key]) {
        return false;
      }
      return true;
    });
    if (!firstHistoryItem) {
      return note[key];
    }
    return firstHistoryItem.data[key].oldValue;
  };

  const fillHistoryWithCreated = (history) => {
    const originalType = getOriginalValue("type", history, note);
    const created = {
      itemId: note.id,
      createdAt: note.createdAt,
      createdBy: note.createdBy,
      data: {
        text: {
          newValue: getOriginalValue("text", history, note)
        },
        dateTo: {
          newValue: getOriginalValue("dateTo", history, note)
        }
      }
    }

    if (originalType === "ACTION") {
      created.data.assignedTo = {
        newValue: getOriginalValue("assignedTo", history, note)
      }
    }

    return [
      created,
      ...history
    ];
  }

  const getLastValue = (key, history, maxIndex) => {
    const lastItemWithValue = history.filter((item, index) => {
      if (index >= maxIndex) {
        return false;
      }
      if (!item.data[key]) {
        return false;
      }
      return true;
    }).pop();
    if (!lastItemWithValue) {
      return null;
    }
    return lastItemWithValue.data[key].newValue;
  }

  const fixHistoryForTypeChange = (history) => {
    return history.map((item, index) => {
      if (item.data.type && item.data.type.newValue === "ACTION") {
        return { 
          ...item,
          data: {
            ...item.data,
            assignedTo: {
              newValue: item.data.assignedTo?.newValue || getLastValue("assignedTo", history, index) || getOriginalValue("assignedTo", history, note)
            }
          }
        }
      }
      return item;
    });
  }

  const mergeSameDayChanges = (history) => {
    let currentRow = null;
    let row = [];
    const mergedHistory = [];
    history.forEach(item => {
      const day = Moment(item.createdAt).format("YYYY-MM-DD");
      const id = item.createdBy.id;
      if (!currentRow) {
        row.push(item);
        currentRow = {};
        currentRow.day = day;
        currentRow.id = id;
        return;
      }
      if (currentRow.day == day && currentRow.id == id) {
        row.push(item);
        return;
      }
      mergedHistory.push(row);
      row = [];
      currentRow.day = day;
      currentRow.id = id;
      row.push(item);
    });

    mergedHistory.push(row);

    return mergedHistory;
  }

  const processedHistory = mergeSameDayChanges(fixHistoryForTypeChange(fillHistoryWithCreated(history?.sort((a, b) => a.createdAt - b.createdAt) || [])));

  return <div className="modal modal-note-history open">
    <div className="modal-header-mobile show-mobile">
      <a className="btn btn-back close-modal" onClick={togglePopupNoteHistory}>
        <span className="upper">
          <CLabel k="actions.and.notes.edit.note.popup.close" />
        </span>
      </a>
      <div className="title-page"><CLabel k="actions.and.notes.history.title" /></div>
    </div>
    <div className="modal-dialog modal-sm">
      <div className="modal-content">
        <div className="modal-header hide-mobile">
          <div className="modal-title">
            <div className="name-block small"><CLabel k="actions.and.notes.history.title" /></div>
          </div>
        </div>
        <div className="modal-body">
          {!loading && <div className="note-history-table">
            {processedHistory.map(generateRow)}
          </div>}
          {loading && <div className="loader loader-white-bg" />}
        </div>
        <div className="modal-footer hide-mobile">
          <div className="clearfix">
            <div className="box-btn box-right">
              <a className="btn btn-red btn-sm close-modal" onClick={togglePopupNoteHistory}>
                <span className="upper"><CLabel k="ok" /></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-footer-mobile show-mobile">
      <a className="btn btn-red btn-normal close-modal" onClick={togglePopupNoteHistory}>
        <span className="upper"><CLabel k="ok" /></span>
      </a>
    </div>
  </div>
}

export default withTranslation()(Connect(CNoteHistoryPopup, {
  dispatch: (dispatch) => ({
    togglePopupNoteHistory: () => {
      dispatch({ type: 'TOGGLE_POPUP_NOTE_HISTORY' });
    }
  }),
  state: (state) => ({
    noteHistoryPopup: state.popup?.TOGGLE_POPUP_NOTE_HISTORY,
  })
}));