{ CLabel } = require '../../cmp/CLabel'
CItemNote = require './CItemNote'

#props.notes
#props.maxNotes
#props.onNoteChanged
#props.disableShowMore
#props.showMore
module.exports = createReactClass(
  displayName: "CItemNotesList"

  getInitialState: () ->
    showMore: @props.showMore || false
    disableShowMore: @props.disableShowMore || false

  toggleShowMore: ->
    @setState showMore: !@state.showMore

  getNotesToShow: (showMore) ->
    if !showMore then @props.notes?.filter((e, i) => i < (@props.maxNotes || 1)) else @props.notes

  render: ()->
    React.createElement(React.Fragment, null,
      React.createElement("div", {"className": "tile-group", "style": (paddingBottom: '0')},
        (@getNotesToShow(@state.showMore)?.map (note, i)=>
          React.createElement(CItemNote, { \
            "note": (note),  \
            "truncated": (!@state.showMore && @props.notes?.length > (@props.maxNotes || 1)),   \
            "onDeleted": (@props.onNoteDeleted),  \
            "onChanged": (@props.onNoteChanged),  \
            "key": ("note-" + note.id + note.updatedAt)
          })
        )
      ),
      (if !@state.disableShowMore && @props.notes?.length > (@props.maxNotes || 1)
        React.createElement("div", {"className": "tile-bottom"},
          (if @state.showMore
              React.createElement("div", {"className": "box-btn text-center"}, React.createElement("a", {"onClick": (@toggleShowMore), "className": "btn btn-small"}, React.createElement(CLabel, {"k": "property.show.less"})))
            else
              React.createElement("div", {"className": "box-btn text-center"}, React.createElement("a", {"onClick": (@toggleShowMore), "className": "btn btn-small"}, React.createElement(CLabel, {"k": "property.show.more"})))
          )
        )
      )
    )
)
