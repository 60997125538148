import { CLabel } from '../CLabel';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { API } from '../../redux/Store';
import { useTranslation } from 'react-i18next';

const RoleToContactField = {
    'Developer': 'DevOwn',
    'Manager': "Management",
    "Purchaser": "Purchasing",
    "Designer": "InteriorDesign",
}

const CCustomFieldSet = ({ clientId, structure, onChange, selectedColumns, clientName, structureName }) => {
    const [open, setOpen] = useState(false);
    const [contactOpen, setContactOpen] = useState(false);
    const [selectedFields, setSelectedFields] = useState(selectedColumns || []);
    const [selectedFieldsModal, setSelectedFieldsModal] = useState(selectedColumns || []);
    const [columns, setColumns] = useState([]);
    const [contactColumns, setContactColumns] = useState([]);
    const { t } = useTranslation();

    const setDefaultFields = useCallback((newFields) => {
        if (columns.length === 0) return;
        const isEqual = columns && columns.length === newFields.length && columns.every((v, i) => v.column === newFields[i].column);
        if (!isEqual) {
            const newSelectedFields = newFields.map(f => f.column);
            setSelectedFields(newSelectedFields);
            onChange(newSelectedFields);
        }
    }, [columns, onChange]);

    useEffect(() => {
        if (!clientId || !structure) return;
        API.getCustomFieldSet(clientId, (res) => {
            const fields = res[structure] || [];
            const contactFields = fields.filter(f => f.isContact);
            setDefaultFields(fields);
            setContactColumns(contactFields);
            setColumns(fields);
        });
    }, [clientId, structure]);

    const toggleModal = () => {
        if (!clientId || !structure) return;
        setOpen(!open);
    };

    const save = () => {
        onChange(selectedFields);
        setOpen(false);
    }
    
    const cancel = () => {
        setSelectedFields(selectedColumns || []);
        setOpen(false);
    }

    const saveContacts = () => {
        const filteredSelectedFields = selectedFields.filter(f => !contactColumns.map(c => c.column).includes(f));
        setSelectedFields([...new Set([...filteredSelectedFields, ...selectedFieldsModal])]);
        setContactOpen(false);
    }

    const closeContactModal = () => {
        setContactOpen(false);
    };

    const openContactModal = () => {
        setSelectedFieldsModal(selectedFields);
        setContactOpen(true);
    }

    const selectField = (field) => () => {
        if (selectedFields.includes(field)) {
            setSelectedFields(selectedFields.filter(f => f !== field));
            return;
        }
        setSelectedFields([...selectedFields, field]);
    };

    const fieldLabel = useMemo(() => {
        if (columns.length === 0) {
            return t('admin.custom.field.set.none', { ns: 'internal_ui' });
        }
        if (selectedFields.length === 0) {
            return t('admin.custom.field.set.count', { count: selectedFields.length, total: columns.length, ns: 'internal_ui' });
        }
        if (selectedFields.length === columns.length) {
            return t('admin.custom.field.set.all', { ns: 'internal_ui' });
        }
        if (columns.length - selectedFields.length < 3) {
            const nonSelectedFields = columns.filter(c => !selectedFields.includes(c.column));
            return t('admin.custom.field.set.allExcept', { fields: nonSelectedFields.map(f => f.name).join(' and '), ns: 'internal_ui' });
        }
        return t('admin.custom.field.set.count', { count: selectedFields.length, total: columns.length, ns: 'internal_ui' });
    }, [selectedFields, columns]);

    const selectAll = () => {
        if (selectedFields.length === columns.length) {
            setSelectedFields([]);
            return;
        }
        setSelectedFields(columns.map(c => c.column));
    }

    const contactRoles = useMemo(() => {
        return [...new Set(contactColumns.map(c => c.role))];
    }, [contactColumns]);

    const contactFields = useMemo(() => {
        return [... new Set(contactColumns.map(c => {
            return c.column.replace(RoleToContactField[c.role] || c.role, '');
        }))];
    }, [contactColumns]);

    const getContactFieldColumn = (field, role) => {
        const contactRole = RoleToContactField[role] || role;
        const contactField = contactRole + field
        return contactField;
    }

    const selectContactColumn = (field, role) => () => {
        const contactField = getContactFieldColumn(field, role);
        if (selectedFieldsModal.includes(contactField)) {
            setSelectedFieldsModal(selectedFieldsModal.filter(f => f !== contactField));
            return;
        }
        setSelectedFieldsModal([...selectedFieldsModal, contactField]);
    }

    const isChecked = (field, role) => {
        const contactField = getContactFieldColumn(field, role)
        return selectedFieldsModal.includes(contactField);
    }

    const onRoleClick = (role) => () => {
        const fields = contactColumns.filter(c => c.role === role).map(c => c.column);
        const allSelected = fields.every(f => selectedFieldsModal.includes(f));
        if (allSelected) {
            setSelectedFieldsModal(selectedFieldsModal.filter(f => !fields.includes(f)));
            return;
        }
        const notSelectedFields = contactFields.filter(f => !selectedFieldsModal.includes(getContactFieldColumn(f, role)));
        const occupiedFields = notSelectedFields.filter(f => {
            return contactRoles.some(r => r !== role && selectedFieldsModal.includes(getContactFieldColumn(f, r)));
        })
        if (occupiedFields.length > 0) {
            setSelectedFieldsModal([...new Set([...selectedFieldsModal, ...occupiedFields.map(f => getContactFieldColumn(f, role))])]);
            return;
        }
        setSelectedFieldsModal([...new Set([...selectedFieldsModal, ...fields])]);
    }

    const onContactColumnClick = (column) => () => {
        const contactFields = contactRoles.map(role => getContactFieldColumn(column, role));
        const allSelected = contactFields.every(f => selectedFieldsModal.includes(f));
        if (allSelected) {
            setSelectedFieldsModal(selectedFieldsModal.filter(f => !selectedFieldsModal.includes(f)));
            return;
        }
        const notSelectedRoles = contactRoles.filter(r => !selectedFieldsModal.includes(getContactFieldColumn(column, r)));
        const occupiedRoles = notSelectedRoles.filter(r => {
            return contactColumns.some(c => c.role === r && selectedFieldsModal.includes(c.column));
        })
        if (occupiedRoles.length > 0) {
            setSelectedFieldsModal([...new Set([...selectedFieldsModal, ...occupiedRoles.map(r => getContactFieldColumn(column, r))])]);
            return;
        }
        setSelectedFieldsModal([...new Set([...selectedFieldsModal, ...contactFields])]);
    }

    const selectAllRoles = () => {
        // const roles = contactRoles.map(r => contactColumns.find(c => c.role === r).column);
        console.log(selectedFields, contactColumns);
        setSelectedFieldsModal([...new Set([...selectedFieldsModal, ...contactColumns.map(c => c.column)])]);
    }

    const clearAllRoles = () => {
        setSelectedFieldsModal([]);
    }

    const isDisabled = useMemo(() => {
        return !clientId || !structure || columns.length === 0;
    }, [clientId, structure, columns]);

    return <>
        <div className={`form-control auto-export-custom-field-set ${isDisabled ? 'disabled' : ''}`} style={{ height: "auto" }} onClick={toggleModal}>
            {fieldLabel}
        </div>
        <div className={`modal ${open ? 'open' : ''}`}>
            <div className="modal-dialog modal-sm auto-export-custom-field-set-modal" style={{ paddingLeft: 16, paddingRight: 16 }}>
                <div className="modal-content" style={{ height: 'auto', marginTop: 16 }}>
                    <div className="modal-body" style={{ lineHeight: "18px" }}>
                        <div className="modal-title">
                            <div className="name-block small">{t('admin.custom.field.set.title', { ns: 'internal_ui' })}</div>
                        </div>
                        <div>
                            <i>{t('admin.custom.field.set.client', { clientName, ns: 'internal_ui' })}</i>
                        </div>
                        <div>
                            <i>{t('admin.custom.field.set.structure', { structureName, ns: 'internal_ui' })}</i>
                        </div>
                        <div className='custom-field-set-wrapper'>
                            <div className='custom-field-set-info'>
                                <div>
                                    <i>{t('admin.custom.field.set.description', { ns: 'internal_ui' })}</i>
                                </div>
                                <div className='custom-field-set-buttons'>
                                    {contactColumns.length > 0 && <a class="btn" onClick={openContactModal}>{t('admin.custom.field.set.use.roles', { ns: 'internal_ui' })}</a>}
                                    <a class="btn" onClick={selectAll}>
                                        {selectedFields.length === columns.length ? t('admin.custom.field.set.excludeAll', { ns: 'internal_ui' }) : t('admin.custom.field.set.includeAll', { ns: 'internal_ui' })}
                                    </a>
                                </div>
                            </div>
                            <div className='custom-field-set-list'>
                                {columns.map((column) => <div key={column.column} className={`custom-field-set-list-item ${selectedFields.includes(column.column) ? 'active' : ''}`} onClick={selectField(column.column)}>
                                    {column.name}
                                </div>)}
                            </div>
                            <div className='custom-field-set-including'>
                                <i>
                                    {t('admin.custom.field.set.including', { ns: 'internal_ui', count: selectedFields.length, total: columns.length })}
                                </i>
                            </div>
                            <div className='custom-field-set-footer'>
                                <a class="btn bold" onClick={save}>
                                    {t('admin.custom.field.set.save', { ns: 'internal_ui' })}
                                </a>
                                <a class="btn bold" onClick={cancel}>
                                    {t('admin.custom.field.set.cancel', { ns: 'internal_ui' })}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={`modal ${contactOpen ? 'open' : ''}`}>
            <div className="modal-dialog modal-lg auto-export-custom-field-set-modal" style={{ paddingLeft: 16, paddingRight: 16 }}>
                <div className="modal-content" style={{ height: 'auto', marginTop: 16 }}>
                    <div className="modal-body" style={{ lineHeight: "18px" }}>
                        <div className="modal-title">
                            <div className="name-block small">
                                {t('admin.custom.field.set.contactColumns', { ns: 'internal_ui' })}
                            </div>
                            <div className="modal-title-buttons">
                                <a class="btn bold" onClick={selectAllRoles}>
                                    {t('admin.custom.field.set.includeAllRoles', { ns: 'internal_ui' })}
                                </a>
                                <a class="btn bold" onClick={clearAllRoles}>
                                    {t('admin.custom.field.set.excludeAllRoles', { ns: 'internal_ui' })}
                                </a>
                            </div>
                        </div>
                        <div className='contact-field-set-table'>
                            <div className='contact-field-set-table-column'>
                                <div className='contact-field-set-table-cell'></div>
                                {contactFields.map(field => <div key={field} className='contact-field-set-table-cell' onClick={onContactColumnClick(field)}>
                                    {field}
                                </div>)}
                            </div>
                            {contactRoles.map(role => <div key={role} className='contact-field-set-table-column'>
                                <div className='contact-field-set-table-cell' onClick={onRoleClick(role)}>{role}</div>
                                {contactFields.map(field => <div key={role + field} className='contact-field-set-table-cell text-center' onClick={selectContactColumn(field, role)}>
                                    <i class={`fa fa-check ${isChecked(field, role) ? '' : 'hidden'}`}></i>
                                </div>)}
                            </div>)}
                        </div>
                        <div className='contact-field-set-description'>
                            {t('admin.custom.field.set.contactColumnsDescription', { ns: 'internal_ui' })}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="clearfix">
                            <div className="box-btn box-right">
                                <a className="btn bold btn-sm close-modal" onClick={closeContactModal}>
                                    <span className="upper"><CLabel k="cancel" /></span>
                                </a>
                                <a className="btn btn-red btn-sm close-modal" onClick={saveContacts}>
                                    <span className="upper"><CLabel k="ok" /></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default CCustomFieldSet;