{ extend } = require '../../helpers/UtilsHelpers'

clone = (o) ->
  extend({}, o)

module.exports = (getStore, getAPI, Analytics) ->
  loadWatches = () ->
    getAPI().getWatchList (watches) ->
      getStore().dispatch(type: "SET_WATCHES", watches: watches)

  deleteWatch = (propertyId, cb, stateNotSync) ->
    getAPI().deleteWatch propertyId, null, null, () ->
      Analytics().navigate.watchRemove()
      getStore().dispatch(type: "TOGGLE_WATCHING_PROPERTY", id: propertyId, watching: false)
      if stateNotSync
        watches = getStore().getState().watches.list
        watches.find((el) -> el.property.id == propertyId)?.property?.watching = false
      else
        watches = getStore().getState().watches.list.filter (item) -> item.property.id != propertyId

      getStore().dispatch(type: "SET_WATCHES", watches: watches)
      cb?()

  updateWatch = (propertyId, cb, stateNotSync) ->
    getAPI().updateWatchList propertyId, null, null, () ->
      getStore().dispatch(type: "TOGGLE_WATCHING_PROPERTY", id: propertyId, watching: true)
      Analytics().navigate.watchAdd()
      if stateNotSync
        watches = getStore().getState().watches.list
        watches.find((el) -> el.property.id == propertyId)?.property?.watching = true
        getStore().dispatch(type: "SET_WATCHES", watches: watches)
      else
        getStore().dispatch(type: 'LOAD_WATCHES')

      cb?()

  (state = list: [], action) ->
    if action.type == "SET_WATCHES"
      state.list = action.watches
      state.version = Math.random()
      clone state
    else  if action.type == "LOAD_WATCHES"
      loadWatches()
      state
    else if action.type == "DELETE_WATCH"
      deleteWatch(action.propertyId, action.cb, action.stateNotSync)
      state
    else if action.type == "UPDATE_WATCH"
      updateWatch(action.propertyId, action.cb, action.stateNotSync)
      state
    else if action.type == "CHANGE_PERIOD_FILTER"
      state.period = action.period
      clone state
    else
      state
