import React, { useState } from "react";
import { AccessRestriction } from "../cmp/AccessRestriction"
import CWatchPopupNew from "../cmp/popups/CWatchPopupNew"
import CTimeline from '../cmp/CTimeline'
import { CLabel } from '../cmp/CLabel'
import Connect from '../helpers/Connect'
import Moment from 'moment'
import Utils from '../helpers/Utils'
import { SALE_FIELDS, getProjectSubheading, interestingFieldsOrder } from "./WatchList.constants"
import { getDateLocale } from "../helpers/LocaleHelpers"
import { isMobile, getUrgentNotesCount } from "../helpers/Utils"
import CItemNote from '../cmp/note/CItemNote'

import {
  DATE_FIELDS,
  getCompany,
  getContact,
  getTimeRange,
  INTERESTING_FIELDS_PROJECTS,
  INTERESTING_FIELDS_PROPERTY,
  FORCED_PROJECT_FIELDS,
  OLD_VALUE_FIELDS,
  PRICE_FIELDS,
} from "./WatchList.constants";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import { translateCategoricalValue } from '../cmp/filters/CTranslateUtils'

const getLastModifiedFields = (item, properties, labelPrefix, meta) => {
  return properties
    .filter(field => {
      if (item.projectType === "Sale" && field == "projectStage") {
        return false;
      }
      if (item.projectType === "Sale" && item[field + "New"] && SALE_FIELDS.includes(field)) {
        return true;
      }
      return item[field + "New"];
    })
    .map(field => {
      const fieldNew = (field + "New")
      const fieldUpdatedAt = (field + "UpdatedAt")
      return {
        parentCreatedAt: item.createdAt,
        _labelPrefix: labelPrefix,
        _fieldName: field,
        _meta: meta,
        company: getCompany(item, field),
        contact: getContact(item, field),
        [field]: item[field],
        [fieldNew]: item[fieldNew],
        [fieldUpdatedAt]: item[fieldUpdatedAt]
      }
    });
}

const getSaleField = (fields, t, i18n) => {
  const saleFields = fields.filter(field => SALE_FIELDS.includes(field._fieldName));
  if (saleFields.length === 0) {
    return null;
  }
  let {
    sellingPrice,
    sellingPriceNew,
    sellingPriceUpdatedAt,
    dateOfSale,
    dateOfSaleNew,
    dateOfSaleUpdatedAt,
    parentCreatedAt
  } = Object.assign(...saleFields);
  const sale = Boolean(dateOfSale || sellingPrice);
  const saleNew = t('watch.list.sold', { dateOfSale: Moment(dateOfSaleNew).tz('UTC').locale(getDateLocale(i18n.resolvedLanguage)).format("[month-year]"), sellingPrice: Utils.formatNumber(sellingPriceNew), context: sellingPriceNew ? 'withPrice' : 'withoutPrice' });
  const saleUpdatedAt = Math.max(...[sellingPriceUpdatedAt, dateOfSaleUpdatedAt, parentCreatedAt].filter(v => v));
  return {
    _labelPrefix: 'watch.list.project.',
    _fieldName: 'sale',
    sale,
    saleNew,
    saleUpdatedAt
  };
}

const sortInterestingFields = (a, b) => {
  const aOrder = interestingFieldsOrder.indexOf(a._fieldName);
  const bOrder = interestingFieldsOrder.indexOf(b._fieldName);
  return aOrder - bOrder;
}

const interestingModifiedFields = (item, t, i18n) => {
  let lastModified = [...getLastModifiedFields(item.property, INTERESTING_FIELDS_PROPERTY, 'watch.list.property.')];
  if (item.property.projects) {
    const projectsModified = item.property.projects.flatMap((project) => getLastModifiedFields(project, INTERESTING_FIELDS_PROJECTS, 'watch.list.project.', project.projectType));
    if (projectsModified.length > 0) {
      lastModified = [...lastModified, ...projectsModified]
    }
  }
  const saleField = getSaleField(lastModified, t, i18n);
  if (saleField) {
    lastModified.push(saleField);
  }
  return lastModified.filter(field => !SALE_FIELDS.includes(field._fieldName))
}

const CellLabel = ({ history, t }) => {
  const isNewFieldRecord = history[history._fieldName + "UpdatedAt"] <= history.parentCreatedAt;
  const fieldName = t(history._labelPrefix + history._fieldName);
  const field = (() => {
    if (history[history._fieldName + "UpdatedAt"]) {
      return (!isNewFieldRecord && history._fieldName !== "sale" && (history[history._fieldName] ? <CLabel k="watch.list.property.is.now" param={{ fieldName }} /> : <CLabel k="watch.list.property.added" param={{ fieldName }} />));
    }
    if (history._fieldName === "sale" && history[history._fieldName]) {
      return <CLabel k="watch.list.project.updated" param={{ fieldName }} />;
    }
    if (history[history._fieldName + "UpdatedAt"]) {
      return <CLabel k="watch.list.property.deleted" param={{ fieldName }} />;
    }
    return <span style={{ textTransform: "capitalize" }} > {fieldName}</span>;
  })();
  return <div>
    {field}
  </div>;
};

const CellValue = ({ history, t, i18n }) => {
  return <div>
    {
      DATE_FIELDS.indexOf(history._fieldName) > -1 &&
      <div className="value">
          {Moment(history[history._fieldName + "New"]).tz('UTC').locale(getDateLocale(i18n.resolvedLanguage)).format('[month-year]')}
        {history[history._fieldName]
            && <span className="prev-value"> {t('watch.list.changed.value', { value: Moment(history[history._fieldName]).tz('UTC').locale(getDateLocale(i18n.resolvedLanguage)).format('[month-year]') })}</span>}
      </div>
      ||
      PRICE_FIELDS.indexOf(history._fieldName) > -1 &&
      <div className="value">{Utils.formatNumber(history[history._fieldName + "New"])}</div>
      ||
      OLD_VALUE_FIELDS.indexOf(history._fieldName) > -1 &&
      <div className="value">
          {translateCategoricalValue(t, history._fieldName, history[history._fieldName + "New"])}
          {history[history._fieldName] && <span className="prev-value"> {t('watch.list.changed.value', { value: translateCategoricalValue(t, history._fieldName, history[history._fieldName]) })}</span>}
      </div>
      ||
      history?.company?.name &&
      <Link to={"/company/" + history[history._fieldName + "New"]}>{history?.company?.name}</Link>
      ||
      history?.contact?.fullName &&
      <div className="value">{history?.contact?.fullName}</div>
      ||
      <div className="value">{translateCategoricalValue(t, history._fieldName, history[history._fieldName + "New"])}</div>
    }
  </div>
}

const filterChangeList = (timeRange, forced = []) => (field) => {
  if (timeRange.value === Infinity) {
    return false;
  }
  const lastModified = field[field._fieldName + "UpdatedAt"] || field.parentCreatedAt
  const fieldTimeRange = getTimeRange(lastModified);
  if (fieldTimeRange.value <= 30 || forced.includes(field._fieldName)) {
    return true;
  }
  return timeRange.value === fieldTimeRange.value;
};

const getChangeList = (item, t, i18n) => {
  const timeRange = getTimeRange(item.interestingUpdatedAt);
  const fields = interestingModifiedFields(item, t, i18n);
  const fieldsFiltered = fields.filter(filterChangeList(timeRange))
  const forced = fieldsFiltered.map(x => x._meta).filter(x => x).flatMap(x => FORCED_PROJECT_FIELDS[x])
  return interestingModifiedFields(item, t, i18n).filter(filterChangeList(timeRange, forced))
    .sort(sortInterestingFields);
};

const WatchListItem = ({
  updateWatch,
  deleteWatch,
  router,
  item,
  loadData,
  hasAccessToAssignableActions,
  user
}) => {

  const [showAll, setShowAll] = useState(false)
  const [watching, setWatching] = useState(item?.property?.isWatching);
  const [open, setOpen] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [notes, setNotes] = useState(item?.notes || []);
  const { t, i18n } = useTranslation();
  const watch = item

  const changesList = getChangeList(item, t, i18n);
  const maxChanges = showAll || changesList.length === 3 ? changesList.length : 2
  const hasMoreChanges = changesList.length > maxChanges

  const toggleWatch = () => {
    setWatching(watching !== false ? false : true);
    if (watching !== false) {
      deleteWatch(item.watch?.propertyId)
    } else {
      updateWatch(watch.watch?.propertyId)
    }
  }

  const getNoteIconColor = () => {
    const lastNote = item.notes?.filter((note) => !!note.dateTo).sort((a, b) => a.dateTo - b.dateTo)[0]
    return Utils.getNoteStatusColor(lastNote?.active && lastNote?.dateTo, 'green-text');
  };

  const close = () => {
    setOpen(false);
  }

  const openModal = () => {
    if (isMobile()) {
      router.push(`/property/${item.watch?.propertyId}`)
      return;
    }
    setOpen(true);
  }

  const subheading = ((item) => {

    const values = []

    const propertyTimeRange = getTimeRange(item.property.createdAt).value;
    const isNewPropertyRecord = propertyTimeRange <= 60
      && (!item.property.projects || item.property.projects.filter(project => project.projectType === "New Construction").length == 0);

    if (isNewPropertyRecord) {
      values.push(t("watch.list.property.new.record"))
    }

    if (!item.property.projects) {
      return null;
    }
    values.push(...item.property.projects
      .map(getProjectSubheading(item.interestingUpdatedAt, t))
      .filter(v => v));

    if (values.length > 0) {
      return values.join(", ");
    }
    return null;
  })(item);

  const onMouseEnter = () => {
    setNoteOpen(true);
  }

  const onMouseLeave = () => {
    setNoteOpen(false);
  }

  const onNoteChanged = (response) => {
    setNotes(notes.map((note) => {
      if (note.id === response.id) {
        return response;
      }
      return note;
    }));
  }

  const onNoteDeleted = (response) => {
    setNotes(notes.filter((note) => {
      return note.id !== response.id;
    }));
  }

  return (<>
    {open && <CWatchPopupNew watch={item} toggleWatch={toggleWatch} watching={watching} close={close} loadData={loadData} />}
    <div className="block-tile wl-item new-wl-item" key={item.id}>
      <div className="box-tile">
        <div className="tile-watch-list" style={{ minHeight: 'initial' }}>
          <div className={"tile-group" + (hasMoreChanges ? " has-more-changes" : "")}>
            <div className="tile-title">
              <div className="box-btn box-right">
                {!hasAccessToAssignableActions && item.notes && item.notes.length > 0 && <Link to={`/property/${item.watch?.propertyId}`} className={`btn tooltip save ${getNoteIconColor()}`}>
                  <span className="tooltiptext"><CLabel k="results.result.tooltip.show.notes" /></span>
                  <i className="fa fa-file-text-o"></i>
                </Link>}
                {notes && notes.length > 0 && hasAccessToAssignableActions &&
                  <div className={`tooltip save ${Utils.getNextNoteAlertColor(notes, user)}`} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onClick={onMouseEnter} onTouchStart={onMouseEnter}>
                    {noteOpen && <div className="note-popover">
                      <div className="block-tile">
                        <div className="box-tile selected">
                          <div className="tile-action notes-list">
                            <div className="tile-group" style={{ paddingBottom: 0 }}>
                              <CItemNote
                                note={{
                                  ...Utils.getNoteForPopup(notes, user),
                                  property: {
                                    id: item.watch.propertyId,
                                    name: item?.property.nameNew || item?.property.name
                                  }
                                }}
                                truncated
                                hideMenu
                                onChanged={onNoteChanged}
                                onDeleted={onNoteDeleted}
                                shouldFetchHistory
                              />
                            </div>
                            <div className="tile-group tile-group-more">
                              <Link
                                to={{
                                  pathname: `/property/${item.watch?.propertyId}`,
                                  state: { openMore: getUrgentNotesCount(notes) > 1 }
                                }}
                                className="btn tooltip save"
                              >
                                {getUrgentNotesCount(notes) > 1 ? <CLabel k="menu.moreDueSoon" /> : <CLabel k="menu.more" />}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                    <i className="fa fa-file-text-o"></i>
                  </div>
                }
                {item.timeline && item.timeline.length > 1 &&
                  <a className="black timeline tooltip">
                    <span className="tooltiptext" style={{
                      right: "0%", bottom: "initial", top: "3em",
                    }}>
                      <CTimeline timeline={item.timeline} />
                    </span>
                    <span className="timeline-label">
                      <i className={Utils.getColorByDate(item.timeline[item.timeline.length - 1].asOf, "black") + " fa fa-calendar"} />
                    </span>
                  </a>
                }
                <AccessRestriction>
                  <a onClick={toggleWatch}
                    className={"btn link-grey watch btn-watch-list tooltip " + (watching !== false && ' active ') + (Utils.isMobile() && ' nohover')}>
                    <i className="fa fa-eye"></i>
                    <span className="tooltiptext"><CLabel k={watching !== false ? "watch.list.tooltip.remove.from.watch.list" : "watch.list.tooltip.add.to.watch.list"} /></span>
                  </a>
                </AccessRestriction>
              </div>
              <div className="name-block small">
                <a className="open-modal" title={item.property.nameNew} onClick={openModal}>{item.property.nameNew}, <span className="normal">{item.property.cityNew}, {item.property.stateProvinceNew && item.property.stateProvinceNew + ", "}{item.property.countryNew}</span></a>
              </div>
              {subheading && <div className="date">{subheading}</div>}
            </div>
            <div className="box-details">
              <table className={hasMoreChanges ? 'details-main' : ''}>
                <tbody>
                  {changesList.length > 0 && changesList.slice(0, maxChanges).map((item) =>
                    <tr key={item._fieldName}>
                      <td className="cell-label">
                        <CellLabel history={item} t={t} />
                      </td>
                      <td className="cell-details">
                        <CellValue history={item} t={t} i18n={i18n} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {hasMoreChanges && !showAll &&
                <div className="box-btn">
                  <a className="btn btn-small btn-slide-form show btn-more-changes" onClick={() => setShowAll(!showAll)}>
                    <span className="bold"><CLabel k="watch.list.property.more.changes" param={{ count: changesList.length - maxChanges }}/></span>
                  </a>
                </div>
              }
            </div >
          </div >
        </div >
      </div >
    </div >
  </>
  )
}

export default Connect(WatchListItem, {
  dispatch: (dispatch) => {
    return {
      deleteWatch: (propertyId) => dispatch({ type: 'DELETE_WATCH', propertyId: propertyId, stateNotSync: true }),
      updateWatch: (propertyId) => dispatch({ type: 'UPDATE_WATCH', propertyId: propertyId, stateNotSync: true })
    }
  },

  state: (state) => {
    return {
      translation: state.translation,
      user: state.session && state.session.user,
      hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions")
    }
  }
})