import React from 'react';
import { CLabel } from '../CLabel';
import { isMobile } from '../../helpers/Utils';

const MobileAppRedirectModal = ({ closeModal, onConfirm, url }) => {
    return <>
        <div className={"modal modal-date-changed open"}>
            <div className="modal-header-mobile show-mobile">
                <a className="btn btn-back close-modal" onClick={closeModal}>
                    <span className="upper">
                        <CLabel k="settings.custom.popup.close"/>
                    </span>
                </a>
                <div className="title-page"><CLabel k="twiddler.mobile.app.modal.title" /></div>
            </div>
            <div className={`modal-dialog ${!isMobile() && 'dialog-desktop'}`}>
                <div className="modal-content">
                    <div className="modal-body">
                        <p style={{ lineHeight: '18px' }}><CLabel k="twiddler.mobile.app.modal.redirect" /> <span style={{ fontSize: 16 }}>{url}</span></p>
                        <br />
                        <p><CLabel k="twiddler.mobile.app.modal.warning" /></p>
                    </div>
                    <div className="modal-footer hide-mobile">
                        <div className="clearfix">
                            <div className="box-btn d-flex justify-content-center">
                                <a className="btn btn-grey btn-sm close-modal" onClick={closeModal}><span className="upper"><CLabel k="cancel"/></span></a>
                                <a className={`btn btn-red btn-sm close-modal`} onClick={onConfirm}>
                                    <span className="upper">
                                        <CLabel k="proceed"/>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={`modal-footer-mobile show-mobile`}>
                        <a className="btn btn-grey btn-normal close-modal" onClick={closeModal}><span className="upper"><CLabel k="cancel"/></span></a>
                        <a className={`btn btn-red btn-normal close-modal`} onClick={onConfirm}>
                            <span className="upper">
                                <CLabel k="proceed"/>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default MobileAppRedirectModal; 