
import * as atmosphere from 'atmosphere.js';

class WebSocketAdapter {

    constructor(webSocketUrl, callbacks = {}) {
        this.subscription = null;
        this.prevSubscription = null;
        this.callbacks = callbacks;

        this.socketRequest = new atmosphere.AtmosphereRequest();
        this.socketRequest.webSocketUrl = webSocketUrl;
        this.socketRequest.transport = 'websocket';
        this.socketRequest.timeout = 70 * 1000;
        this.socketRequest.fallbackTransport = 'websocket';

        this.socketRequest.onOpen = this.onOpen.bind(this);
        this.socketRequest.onReopen = this.onReopen.bind(this);
        this.socketRequest.onClose = this.onClose.bind(this);
        this.socketRequest.onClientTimeout = this.onClientTimeout.bind(this);
        this.socketRequest.onError = this.onError.bind(this);
        this.socketRequest.onMessage = this.onMessage.bind(this);
    }

    onOpen() {
        console.log("[ws] Connection was opened");
        this.callbacks.onOpen?.();
    }

    onReopen() {
        console.log("[ws] Connection was reopened"); 
        if (this.prevSubscription) {
            this.subscription = this.prevSubscription;
            this.prevSubscription = null;
        }
        this.callbacks.onReopen?.();
    }

    onClose() {
        console.log("[ws] Connection was closed");
        this.prevSubscription = this.subscription;
        this.subscription = null;
        this.callbacks.onClose?.();
    }

    onClientTimeout() {
        console.log("[ws] Connection timed out");
        this.connect();
        this.callbacks.onClientTimeout?.();
    }

    onError(e) {
        console.log("[ws] Connection was dropped", e);
        this.prevSubscription = this.subscription;
        this.subscription = null;
        this.callbacks.onError?.();
    }

    onMessage(data) {
        const message = JSON.parse(data.responseBody);
        this.callbacks.onMessage?.(message);
    }

    connect() {
        console.log("[ws] Connecting");
        this.subscription = atmosphere.subscribe(this.socketRequest);
    }

    disconnect() {
        console.log("[ws] Disconnected")
        atmosphere.unsubscribe();
        this.subscription = null;
    }

    isOpen() {
        return Boolean(this.subscription);
    }

    pushMessages(event, data) {
        if (!this.subscription) return;
        const payload = {
            e: event,
            ...data
        };
        this.subscription.push(JSON.stringify(payload));
    }
}

export default WebSocketAdapter;