import { initializeApp } from "firebase/app";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDNEwhvuCfmfmo_KKcgZgTPs6w7sF4DKEc",
  authDomain: "le-business-development.firebaseapp.com",
  databaseURL: "https://le-business-development.firebaseio.com",
  projectId: "le-business-development",
  storageBucket: "le-business-development.appspot.com",
  messagingSenderId: "466839102359",
  appId: "1:466839102359:web:9b741b4410f70df7665ad2",
};

const VAPID_KEY =
  "BIawntBwVTHFEm3Q5r6-Jf-gYH3_t3i3dOLIcfIaRhgK8__3_OL1jO99-wCTJd5nK7lANZOgQe8g9XBoCHseVWU";

const listenToMessages = () => {
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    new Notification(payload.notification.title, {
      body: payload.notification.body,
      icon: "/img/LE-logo.svg",
    });
  });
};

const initializeFirebase = () => {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  const requestPermission = () => {
    return new Promise((resolve, reject) => {
      Notification.requestPermission().then((permission) => {
        console.log("permission", permission);
        if (permission === "granted") {
          getToken(messaging, { vapidKey: VAPID_KEY }).then((currentToken) => {
            if (currentToken) {
              resolve(currentToken);
              return;
            }
            resolve(null);
          });
          return;
        }
        reject(null);
      });
    });
  };
  requestPermission().then((token) => {
    if (token) {
      window.deviceToken = token;
      window.deviceName = "WEB";
      window.updateFcmToken("WEB", token);
      listenToMessages();
    }
  });
};

export { initializeFirebase };
