import { store as storage } from '../../redux/Store'
import i18next, { t } from 'i18next'
import { extend } from '../../helpers/UtilsHelpers';

const translate = (key, props) => {
  return i18next.t(key, props);
}
const searchFieldsInfo = storage.getState().app.searchFieldsInfo
const searchCompanyFieldsInfo = storage.getState().app.searchCompanyFieldsInfo

const setBlocksOnSearchForm = (blocks) => {
  storage.dispatch({ type: 'SET_BLOCKS_ON_SEARCH_FORM', blocks: blocks })
  // store.dispatch({ type: 'NEW_SEARCH' })
}

const setExcludedProperties = (ids) => storage.dispatch({ type: 'SET_EXCLUDED_PROPERTIES', ids: ids })

const clearExcludedProperties = () => storage.dispatch({ type: 'REMOVE_ALL_EXCLUDED_PROPERTIES' })


function createVirtualDropdown({ searchForm, client, onSearch }) {
  const activityFields = Utils.filterActivityFieldsByClient(client)
  const activityFieldValues = activityFields.filter((name) => searchForm[name] !== undefined)
  const rolesFields = Utils.filterActivityContacts(client)
  const rolesFieldValues = rolesFields.filter((name) => searchForm[name] !== undefined)
  return {
    Activity: {
      name: "Activity",
      priority: 10,
      virtualDropdown: true,
      value: activityFieldValues.length > 0 ? activityFieldValues.filter((name) => searchForm[name]).join(";") : undefined,
      options: activityFields.map((name) => ({
        value: name,
        label: Utils.prettifyFieldName(name).substring(5) == "Sales"
          ? "Sales Transactions"
          : Utils.prettifyFieldName(name).substring(5),
      })),
      onChange: (unused, value) => {
        setBlocksOnSearchForm(activityFields.map((f) => ({ name: f, value: value.indexOf(f) != -1 })));
        if (onSearch) {
          onSearch();
        }
      },
      onClear: () => {
        setBlocksOnSearchForm(activityFields.map((f) => ({ name: f, value: false })))
      },
      onRemove: () => {
        setBlocksOnSearchForm(activityFields.map((f) => ({ name: f, value: undefined })))
      }
    },
    CompanyRole: {
      name: "CompanyRole",
      priority: 100,
      virtualDropdown: true,
      value: rolesFieldValues.length > 0 ? rolesFieldValues.filter((name) => searchForm[name]).join(";") : undefined,
      options: rolesFields.map((name) => ({
        value: name,
        label: translate("search.company." + name)
      })),
      onChange: (unused, value) => {
        setBlocksOnSearchForm(rolesFields.map((f) => ({ name: f, value: value.indexOf(f) != -1 })))
        if (onSearch) {
          onSearch();
        }
      },
      onClear: () => setBlocksOnSearchForm(rolesFields.map((f) => ({ name: f, value: false }))),
      onRemove: () => setBlocksOnSearchForm(rolesFields.map((f) => ({ name: f })))
    },
    Excluded: {
      priority: 1000,
      name: "Excluded",
      virtualDropdown: true,
      value: (searchForm.excludedProperties || []).map((option) => option.id).join(";"),
      options: (searchForm.excludedProperties || []).map((option) => ({ value: option.id, label: option.name })),
      onChange: (unused, value, isTrue) => setExcludedProperties(value),
      valuePrinter: (values, options) => {
        return translate('results.fast.filter.property', { count: values.length });
      },
      onClear: () => clearExcludedProperties()
    }
  }
}

function createOpaqueFilters({ searchForm }) {

  const filters = {}
  const keywords = searchForm.keywords && searchForm.keywords.value && searchForm.keywords.value.split(';')
  if (searchForm.isOpaqueFilter && keywords && keywords.length > 0) {
    labels = searchForm.keywords.options.filter((option) => keywords.indexOf("'" + option.value + "'") != -1).map((option) => option.label)
    opaqueFilterLabel = labels.length == 1 ? labels[0] : translate("reports.selected.target.accounts")
    extend(filters, {
      Custom: {
        name: translate("results.fast.filter.opaque.filter.custom"),
        priority: searchFieldsInfo.keywords && searchFieldsInfo.keywords?.priority || 1,
        value: opaqueFilterLabel,
        opaqueFilter: true
      }
    })
  }


  if (searchForm.isOpaqueFilter && searchForm.isCompanyPortfolio) {
    //TODO: to restore Company Portfolio mode
    // company = props.result?.params?.company || props.activeCompany
    // if (company) {
    //   if (!props.activeCompany) setActiveCompany(company)
    //   extend(filters, {
    //     Portfolio: {
    //       name: translate("results.fast.filter.opaque.filter.portfolio"),
    //       priority: searchFieldsInfo.portfolio || 1,
    //       value: company?.name + ', ' + company?.city,
    //       opaqueFilter: true
    //     }
    //   })
    // }
  }

  if (searchForm.isOpaqueFilter && searchForm.managementOpportunities) {
    extend(filters, {
      ManagementOpportunities: {
        name: translate("reports.sales.management.opportunities"),
        priority: searchFieldsInfo.managementOpportunities || 1,
        value: translate("reports.sales.management.opportunities"),
        opaqueFilter: true
      }
    })
  }

  return filters
}

export function cleanupFilter(searchForm) {
  const form = JSON.parse(JSON.stringify(searchForm));
  for (const key in form) {
    if (Object.hasOwnProperty.call(form, key) && form[key]
      && (Object.hasOwnProperty.call(form[key], "value") && !form[key].value)) {
      form[key].value = undefined
    }
  }
  return form
}

export function getSearchForm({ searchForm, fullReload, client, excludedFields, onSearch }) {
  const fields = Object.entries(searchForm).map(([key, field]) => {
    if (key === "Activity" || key === "CompanyRole") {
      return null;
    }
    return (field && field.value !== undefined && !field.hidden && ['isCompanyPortfolio'].indexOf(key) === -1)
      ? {
        value: field.value,
        options: field.options,
        name: key,
        priority: searchForm.searchType == Const.SEARCH_TYPE_PROPERTIES
          ? searchFieldsInfo[key] && searchFieldsInfo[key].priority || 1000
          : searchCompanyFieldsInfo[key] && searchCompanyFieldsInfo[key].priority || 1000,
        formatFn: field.formatFn
      }
      : null
  }).filter((it) => it != null)

  //TODO virtualDropDowns, opaqueFilters refactoring is required! all fields have to managed on backend (or frontend, but not both!)
  const virtualDropDowns = createVirtualDropdown({ searchForm, client, setBlocksOnSearchForm, clearExcludedProperties, setExcludedProperties, onSearch })

  if (virtualDropDowns.Activity && virtualDropDowns.Activity.value !== undefined) {
    fields.push(virtualDropDowns.Activity)
  }

  if (virtualDropDowns.CompanyRole && virtualDropDowns.CompanyRole.value !== undefined) {
    fields.push(virtualDropDowns.CompanyRole)
  }


  if (searchForm.excludedProperties && searchForm.excludedProperties.length > 0) {
    fields.push(virtualDropDowns.Excluded)
  }

  const opaqueFilters = createOpaqueFilters({ searchForm, searchFieldsInfo })

  if (opaqueFilters.Custom && opaqueFilters.Custom.value) {
    fields.push(opaqueFilters.Custom)
  }

  if (opaqueFilters.Portfolio && opaqueFilters.Portfolio.value) {
    fields.push(opaqueFilters.Portfolio)
  }

  if (opaqueFilters.ManagementOpportunities && opaqueFilters.ManagementOpportunities.value) {
    fields.push(opaqueFilters.ManagementOpportunities)
  }

  if (!fullReload) {

    Object.entries(searchForm).forEach(([key, field]) => {
      if (field && field.name && (fields.filter((field) => field.name == key).length == 0 && !searchForm[key].hidden)) {
        if (virtualDropDowns[key]) {
          fields.push(virtualDropDowns[key])
        } else if (opaqueFilters[key]) {
          fields.push(opaqueFilters[key])
        } else {
          fields.push({
            value: field.value,
            name: key,
            priority: searchForm.searchType == Const.SEARCH_TYPE_PROPERTIES
              ? searchFieldsInfo[key] && searchFieldsInfo[key].priority || 1000
              : searchCompanyFieldsInfo[key] && searchCompanyFieldsInfo[key].priority || 1000
          });
        }
      }
    });
  }

  const filterVisibleFields = (field) => {
    const isNotesFilter = field && (field.name === 'propertyNotesFilter' || field.name === 'companyNotesFilter');
    return field && field.name && field.value !== undefined && !isNotesFilter || isNotesFilter && field.value && field.value.enabled;
  };

  const filterExcluded = (field) => {
    if (excludedFields) {
      return !excludedFields.includes(field.name);
    }
    return true;
  }

  return fields.filter(filterExcluded).filter(filterVisibleFields).sort((a, b) => (a.priority - b.priority)).reduce((x, item) => { x.set(item.name, item); return x }, new Map())
}