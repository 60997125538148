{ CLabel } = require '../cmp/CLabel'

module.exports = createReactClass
  render: ->
    React.createElement("div", {"className": "page-maintenance"},
      React.createElement("div", {"className": "container"},
        React.createElement("div", {"className": "vertical-middle"},
          React.createElement("div", {"className": "content-vertical"},
            React.createElement("div", {"className": "box-form"},
              React.createElement("div", {"className": "box-logo"},
                React.createElement("a", {"href": (window.location.origin)}, React.createElement("img", {"src": (require "../../assets/img/LE-logo.png"), "alt": "LE Navigator logotype"}))
              ),
              React.createElement("h2", {"style": (padding: '20px')},
                React.createElement(CLabel, {"k": "maintenance.message"})
              ),
              React.createElement("a", {"href": (window.location.origin)}, React.createElement(CLabel, {"k": "maintenance.try.again"}))
            )
          )
        )
      )
    )