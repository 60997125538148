import camelCase from 'lodash.camelcase'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { CLabel } from '../../cmp/CLabel'
import CFilterElement from '../../cmp/filters/CFilterElement'
import CGeneralChangesFastFilterElement from '../../cmp/filters/CGeneralChangesFilter/CGeneralChangesFastFilterElement'
import CHasNotesFilterElement from '../../cmp/filters/CHasNotesFilterElement'
import CHasTimelineFastFilterElement from '../../cmp/filters/CHasTimelineFastFilterElement'
import CHasWatchListFilterElement from '../../cmp/filters/CHasWatchListFilterElement'
import CMultipleDropdown from '../../cmp/filters/CMultipleDropdown'
import CProximityFilterElement from '../../cmp/filters/CProximityFilterElement'
import Comparators from '../../helpers/Comparators'
import Connect from '../../helpers/Connect'
import Utils from '../../helpers/Utils'
import { translateCategoricalValue } from '../filters/CTranslateUtils'

const FIELD_LABELS = {
  "propertyChanges": "search.general.changes.fast.filter.title",
  "propertyWatchFilter": "results.fast.filter.watch.list.title",
}

export default withTranslation()(Connect(({ putValue, putValueFromDropDown, remove, clear, name, client, displayName, disabled, isPrintedLayout, field, fieldInfo, searchFieldsInfo, t, translation }) => {

  const [isDirty, setDirty] = useState(false)

  let timeoutId;

  useEffect(() => (() => timeoutId && clearTimeout(timeoutId)), [])

  const apply = () => {
    if (!fieldInfo?.dropdown && !field.virtualDropdown && isDirty) {
      putValue(field.name, field.value)
      setDirty(false)
    }
  }

  const getTitle = () => { 
    if (name == "fkey") return <CLabel k="search.hotel.information.fkey" param={{ lng: 'en' }} />
    if (FIELD_LABELS[name]) return <CLabel k={FIELD_LABELS[name]} />
    if (name == "branch")
      return searchFieldsInfo.branch?.label ? translateCategoricalValue(t, "customPropertySet", searchFieldsInfo.branch?.label ) : t('data.fast.filter.label.branch', { ns: "data" })
    return t("data.fast.filter.label." + camelCase(name), Utils.prettifyFieldName(displayName), { ns: "data" })
  }

  const getPlaceholder = () => {
    if (name == "saleDate") return t("search.activity.date")
    return getTitle();
  }

  const onBlurApply = () => (timeoutId = setTimeout(apply, 300))

  const changeHandler = (name, value) => {
    putValue(name, value, false, true)
    if (!value.invalid) {
      setDirty(true)
    }
  }

  const onClear = () => {
    setDirty(false)
    field.onClear && field.onClear(false) || clear()
  }

  const onRemove = () => {
    if (field.onRemove) {
      field.onRemove()
    }
    remove()
  }

  const removeOnly = (name == 'hasNotesFilter' || name == 'hasCompanyNotesFilter' || field.opaqueFilter)
  const hasValue = field.value && !removeOnly

  const actionLabel = isDirty && "results.fast.filter.apply"
    || hasValue && "results.fast.filter.clear"
    || "results.fast.filter.remove"

  const actionFunc = isDirty && apply
    || hasValue && onClear
    || onRemove

  return (
    <div data-cy-field-name={name} className={(disabled && "disabled unselectable " || "") + (fieldInfo?.proximityFilter && "proximity-filter-box " || "") + "box-control box-inline"}>
      <div className="label-control">
        {!isPrintedLayout && (<div className="box-right"><a onClick={actionFunc} className="btn btn-small"><CLabel k={actionLabel} /></a></div>)}
        {getTitle()}
      </div>
      {(!field.opaqueFilter) &&
        ((fieldInfo?.dropdown || field.virtualDropdown) &&
        (<CMultipleDropdown isFastFilter={true} onChange={field.onChange || putValueFromDropDown} valuePrinter={field.valuePrinter} comparator={Comparators[name]} values={field.value} options={field.options} name={name} placeholder={getPlaceholder()} />)
          || (fieldInfo?.proximityFilter) &&
      (<CProximityFilterElement description={(name == 'proximityFilter') && "search.additional.property.details.proximity.header"} onBlur={onBlurApply} onChange={changeHandler} name={name} value={field.value} changeOnBlur={false} placeholder={getPlaceholder()} />)
          || (name == 'propertyNotesFilter' || name == 'companyNotesFilter') &&
      (<CHasNotesFilterElement onChange={changeHandler} notesSharingAllowed={client?.noteSharing} isFastFilter={true} name={name} value={field?.value} changeOnBlur={false} placeholder={getPlaceholder()} />)
          || (name == 'propertyWatchFilter') &&
      (<CHasWatchListFilterElement onChange={changeHandler} isFastFilter={true} name={name} value={field?.value} changeOnBlur={false} placeholder={getPlaceholder()} />)
          || (name == 'hasTimelineFilter') &&
      (<CHasTimelineFastFilterElement onChange={changeHandler} translation={translation} isFastFilter={true} name={name} value={field?.value} changeOnBlur={false} placeholder={getPlaceholder()} />)
          || (name == 'propertyChanges') &&
      (<CGeneralChangesFastFilterElement onChange={changeHandler} translation={translation} isFastFilter={true} name={name} value={field?.value} changeOnBlur={false} placeholder={getPlaceholder()} />)
          ||
          (<CFilterElement onBlur={onBlurApply} onChange={changeHandler} changeOnBlur={false} name={name} value={field.formatFn ? field.formatFn(field.value) : field.value} type="text" className="form-control" placeholder={getPlaceholder()} />)
        )
        ||
        (<CFilterElement style={{ background: "lightgray" }} className="form-control" value={field.value} disabled="disabled" placeholder={getPlaceholder()} />)
      }
    </div>
  )
}, {
  state: (state) => ({
    client: state.session?.user?.client,
    searchFieldsInfo: state.app.searchFieldsInfo,
    translation: state.translation
  })
}
))
