import { useCallback, useEffect, useMemo } from 'react';
import { API } from '../../redux/Store'
import { useState } from 'react';
import CFilterDropdown from '../filters/CFilterDropdown';
import { CLabel } from '../CLabel';
import CIconLabel from '../CIconLabel';
import { Parser } from '@json2csv/plainjs';

const PARAM_TYPES = {
    STRING: 'STRING',
    BOOLEAN: 'BOOLEAN',
    DATE: 'DATE'
}

const CAdminQueries = () => {
    const [queries, setQueries] = useState([]);
    const [selectedQuery, setSelectedQuery] = useState(null);
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [params, setParams] = useState({});
    
    const query = useMemo(() => {
        return queries.find(q => q.key === selectedQuery);
    }, [queries, selectedQuery]);

    useEffect(() => {
        API.getQueries(res => {
            setQueries(res.map(q => (
                {
                    ...q,
                    value: q.key,
                    label: q.name
                }
            )));
        });
    }, []);

    const fetchQuery = useCallback((query) => {
        API.getQuery(query.api.replace('/api/', ''), query.apiMethod, params, res => {
            setLoading(false);
            if (Array.isArray(res)) {
                setResult(res);
                return;
            }
            try {
                setResult(JSON.stringify(res, null, 2));
            } catch (e) {
                setResult(res);
            }

        }, err => {
            setLoading(false);
            setHasError(true);
            try {
                setResult(JSON.stringify(err, null, 2));
            } catch (e) {
                setResult(err);
            }
        });
    }, [params]);

    const setDefaultParams = useCallback((nextSelectedQuery) => {
        const newParams = {}
        const nextQuery = queries.find(q => q.key === nextSelectedQuery);
        nextQuery?.params.forEach(param => {
            if (param.type === PARAM_TYPES.BOOLEAN) {
                newParams[param.key] = param.defaultValue || false;
            }
            if (param.type === PARAM_TYPES.STRING) {
                newParams[param.key] = param.defaultValue || "";
            }
            if (param.type === PARAM_TYPES.DATE) {
                newParams[param.key] = param.defaultValue || null;
            }
        });
        setParams(newParams);
    }, [queries]);

    const onSelectQuery = useCallback((_, value) => {
        setDefaultParams(value);
        setSelectedQuery(value);
    }, [setDefaultParams])

    const copyToClipboard = useCallback(() => {
        document.getElementById('copy-tooltip').style.opacity = 1;
        setTimeout(() => {
            document.getElementById('copy-tooltip').style.opacity = 0;
        }, 2000);
        if (Array.isArray(result)) {
            const parser = new Parser({});
            const csv = parser.parse(result);
            navigator.clipboard.writeText(csv);
            return;
        }
        navigator.clipboard.writeText(result || "");
    }, [result]);

    const downloadCSV = useCallback(() => {
        if (!Array.isArray(result)) {
            return;
        } 
        document.getElementById('download-tooltip').style.opacity = 1;
        setTimeout(() => {
            document.getElementById('download-tooltip').style.opacity = 0;
        }, 2000);
        const parser = new Parser({});
        const csv = parser.parse(result);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url);
        a.setAttribute('download', 'download.csv');
        a.click()
    }, [result]);

    const submit = useCallback(() => {
        if (!query) {
            return;
        }
        setLoading(true);
        setHasError(false);
        fetchQuery(query);
    }, [query, fetchQuery]);

    const onParamUpdate = useCallback((key, value) => {
        setParams({
            ...params,
            [key]: value
        });
    }, [params]);


    const renderParamInput = useCallback((param) => {
        if (param.type === PARAM_TYPES.STRING) {
            return <div className="box-control" key={param.key}>
                <label htmlFor={param.key}>{param.label}</label>
                <input
                    type="text"
                    name={param.key}
                    id={param.key}
                    className="form-control"
                    placeholder={param.label}
                    value={params[param.key] || param.defaultValue || ""}
                    onChange={(e) => {
                        onParamUpdate(param.key, e.target.value);
                    }}
                />
            </div>
        }

        if (param.type === PARAM_TYPES.BOOLEAN) {
            return <div className="box-control box-checkbox middle" key={param.key}>
                <input
                    type="checkbox"
                    checked={params[param.key]}
                    name={param.key}
                    id={param.key}
                    className="form-control"
                    onChange={(e) => {
                        onParamUpdate(param.key, e.target.checked);
                    }}
                />
                <label htmlFor={param.key}>{param.label}</label>
            </div>
        }

        return null;
    }, [params]);

    const onSubmit = useCallback((e) => {
        e.preventDefault();
        submit();
    }, [submit]);

    return (
        <div className="admin-page">
            <div className="block-tile">
                <div className="box-tile">
                    <div className="tile-group">
                        <form className="form form-small admin-queries-form">
                            <div className="query-label">
                                <CLabel k="admin.queries.label" />
                            </div>
                            <div className="box-control">
                                <div className="box-checkbox">
                                    <CFilterDropdown
                                        name='query'
                                        id="query"
                                        className="text-left auto-export-dropdown"
                                        onChange={onSelectQuery}
                                        value={selectedQuery}
                                        nullable={false}
                                        options={queries}
                                        placeholder="Select query"
                                    />
                                </div>
                            </div>
                            <a className={`btn btn-red btn-normal upper ${loading ? 'disabled' : ''}`} onClick={submit}>
                                <CLabel k="admin.queries.submit" />
                            </a>
                        </form>
                        {query?.params.length > 0 && <form className="form form-small admin-queries-params-form" onSubmit={onSubmit}>
                            <div className="query-label">
                                <CLabel k="admin.queries.params" />
                            </div>
                            <div className="query-params-fields">
                                {query.params.map(renderParamInput)}
                            </div>
                        </form>}
                        <div className='query-results'>
                            <div className='query-results-buttons'>
                                {Array.isArray(result) && <span className='tooltip-bottom'>
                                    <CIconLabel className="btn link-grey" onClick={downloadCSV} icon="fa-file" k="admin.queries.download" />
                                        <span id="download-tooltip" style={{ opacity: 0 }} className='tooltiptext'>
                                        <CLabel k="admin.queries.downloadTooltip" />
                                        </span>
                                    </span>}
                                <span className='tooltip-bottom'>
                                    <CIconLabel className="btn link-grey" onClick={copyToClipboard} icon="fa-clipboard" k="admin.queries.copy" />
                                    <span id="copy-tooltip" style={{ opacity: 0 }} className='tooltiptext'>
                                        <CLabel k="admin.queries.copyTooltip" />
                                    </span>
                                </span>
                            </div>
                            {(!Array.isArray(result) || result.length == 0) && <textarea className={`query-results ${hasError ? 'has-error' : ''}`} readOnly={true} value={result} disabled={loading} />}
                            {Array.isArray(result) && result.length > 0 && <div className='query-results-table-wrapper'>
                                <table className='query-results-table'>
                                <thead>
                                    <tr>
                                        {Object.keys(result[0]).map(key => <th key={key}>{key}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {result.map((row, i) => <tr key={i}>
                                        {Object.keys(result[0]).map(key => <td key={key}>{row[key]}</td>)}
                                    </tr>)}
                                </tbody>
                            </table>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CAdminQueries;