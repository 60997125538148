
const getDimensions = (dimensions, t) => {
    return dimensions.map(dimension => t(`data.charts.shortname.${dimension}`, { ns: 'data' }));
}

const getContext = (info, dimensions) => {
    if (info) {
        if (dimensions.length === 1) {
            return 'withInfoByOne';
        }
        if (dimensions.length === 2) {
            return 'withInfoByTwo';
        }
        return 'withInfo';
    }
    if (dimensions.length === 1) {
        return 'withByOne';
    }
    if (dimensions.length === 2) {
        return 'withByTwo';
    }
    return null;
};

const getInfo = (options, t) => {
    if (options.includes("big3brandcompanies")) {
        return t('data.charts.info.big3brandcompanies', { ns: 'data' });
    }
    if (options.includes("big6brandcompanies")) {
        return t('data.charts.info.big6brandcompanies', { ns: 'data' });
    }
    if (options.includes("top25USmarkets")) {
        return t('data.charts.info.top25USmarkets', { ns: 'data' });
    }
    if (options.includes("rooms") && options.includes("conversions") && options.includes("renocon")) {
        return t('data.charts.info.rooms', { ns: 'data' });
    }
    return null;
}

const getTitleTranslationKey = (options = []) => {
    if (options.includes("conversions")) {
        if (options.includes("activestages")) {
            return 'data.charts.title.active.conversions';
        }
        return 'data.charts.title.conversions';
    }

    if (options.includes("renocon")) {
        if (options.includes("activestages")) {
            return 'data.charts.title.active.renovations.conversions';
        }
        return 'data.charts.title.renovations.conversions';
    }

    if (options.includes("renovations")) {
        return 'data.charts.title.renovations';
    }

    if (options.includes("ncrenocon")) {
        return 'data.charts.title.ncrenocon';
    }

    if (options.includes("pipeline")) {
        return 'data.charts.title.pipeline';
    }

    if (options.includes("activestages")) {
        return 'data.charts.title.activestages';
    }

    if (options.includes("rooms")) {
        return 'data.charts.title.rooms';
    }

    return 'data.charts.title.properties';
}

export const getChartTitle = (chart, t) => {
    if (!chart) return "";
    const info = getInfo(chart.options, t);
    const dimensions = getDimensions(chart.dimensions, t);
    const context = getContext(info, dimensions);
    const title = t(getTitleTranslationKey(chart.options), {
        context: context,
        info,
        dimension1: dimensions[0],
        dimension2: dimensions[1],
        ns: 'data'
    });

    return title;
}