import Moment from "moment"
import Connect from "../../helpers/Connect"
import { CLabel } from "../../cmp/CLabel"
import { withTranslation, Trans } from 'react-i18next'
import { getDateLocale } from "../../helpers/LocaleHelpers";
import { useEffect, useState } from "react"

const LAST_WEEK = "lastWeek"
const LAST_MONTH = "last30days"
const LAST_LOGIN = "lastLogin"

export {LAST_WEEK, LAST_MONTH, LAST_LOGIN}

export default withTranslation()(Connect(({ changePeriodFilter, i18n, lastLoginDate, t, period = LAST_WEEK }) => {

  const [showDropdown, setShowDropdown] = useState(false);

  const onBodyClick = (e) => {
    if (!e.target.closest('.menu-dropdown')) {
      setShowDropdown(false)
      document.body.classList.remove("dropdown-open")
    }
  }

  useEffect(() => {
    document.body.addEventListener("click", onBodyClick)
    return () => {
      document.body.removeEventListener("click", onBodyClick)
    }
  })

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
    document.body.classList.toggle("dropdown-open")
  }

  const changePeriod = (period) => {
    changePeriodFilter(period)
    toggleDropdown();
  }

  const isActivePeriodClass = (test) => (test == period ? "active" : '')
  return (
    <div className="box-dropdown box-inline">
      <a className="btn btn-filter href-dropdown" onClick={toggleDropdown}>
        <CLabel k={`home.${period}`} />
        <span> </span>
        <i className="fa fa-angle-down"></i>
      </a>
      {showDropdown &&
        (<div className="menu-dropdown">
          <a onClick={() => changePeriod(LAST_WEEK)} className={isActivePeriodClass(LAST_WEEK)}><span><CLabel k="home.lastWeek" /></span></a>
          <a onClick={() => changePeriod(LAST_MONTH)} className={isActivePeriodClass(LAST_MONTH)}><span><CLabel k="home.last30days" /></span></a>
          <a onClick={() => changePeriod(LAST_LOGIN)} className={isActivePeriodClass(LAST_LOGIN) + (lastLoginDate ? '' : ' disabled')}>
          <span><CLabel k={lastLoginDate ? "home.lastLoginWithDate" : "home.lastLogin"} param={{
              date: Moment(lastLoginDate).locale(getDateLocale(i18n.language)).format('[MMM-D]')
            }}/> </span>
          </a>
        </div>)
      }
    </div>
  )

}, {
  state: (state) => ({
    lastLoginDate: state.app?.properties?.lastLoginDate
  }),

  dispatch: (dispatch) => ({
    changePeriodFilter: (period) => dispatch({ type: 'CHANGE_PERIOD_FILTER', period: period })
  })
}))
