import { CLabel } from '../CLabel'
import Utils from '../../helpers/Utils.cjsx'
import { Link } from 'react-router'
import { CSSProperties } from 'react'
import { Trans } from 'react-i18next'
import './role_short.scss'

/**
 * @type {{[key: string]: CSSProperties}}
 */
const styles = {

  item: {
    lineHeight: "18px",
    display: "flex",
    marginBottom: "10px",
    marginTop: "5px",
    alignItems: "baseline",
    flexFlow: "wrap"
  },

  role: {
    lineHeight: "22px",
    fontSize: "18px",
    fontWeight: "bold"
  },

  name: {
    width: "100%"
  },

  phone: {
    marginTop: "3px"
  }
}

export default ({ role, company, contact, history, roles }) => {


  const id = company && company.id || contact && contact.companyId
  const phone = contact && contact.phone || company && company.phone
  const name = []
  if (contact && contact.fullName && contact.fullName.trim().length > 0) {
    name.push(<span key={"contact.name"} className={Utils.getColorByChanges(history, roles[role].contactId)}>{contact.fullName.trim()}</span>)
  }

  if (company && company.name && company.name.trim().length > 0) {
    name.push(<span key={"company.name"} className={Utils.getColorByChanges(history, roles[role].companyId)}>{company.name.trim()}</span>)
  }


  return <div>
    <CLabel k={"property.contact.type.short." + role} style={styles.role} />
    <div key={id + role} style={styles.item} className="results-roles">
      <Link to={"/company/" + id} style={styles.name} className={"dark-grey bold text-overflow"} data-cy-company-id={id}>
        {name.reduce((result, item) => result.length > 0 ? [...result, ', ', item] : [item], [])}
        {company && company.notes && company.notes.length > 0 && <span to={"/company/" + id} style={styles.name} className={"dark-grey bold text-overflow"} data-cy-company-id={id}>
          <i style={{ marginLeft: "4px" }} className={"fa fa-file-text-o " + Utils.getNoteStatusColor(company.notes[company.notes.length - 1].dateTo, "green-text")}></i>
        </span>
          // || <i style={{ marginLeft: "4px" }} className={"fa fa-file-text-o"}></i> //NOTE: height hack, for debug
        }
      </Link>
      {phone && <span className="phone text-overflow" style={styles.phone}>
        <Trans i18nKey="property.contact.phoneNumber" values={{
          phoneNumber: phone
        }}>
          <span>P: </span>
          <a target="_top" href={"tel:" + Utils.formatPhoneNumber(phone)} className="link link-phone">{{ phone }}</a>
        </Trans>
      </span>}
    </div>
  </div>
}