import Connect from '../../helpers/Connect';
import { CLabel } from '../CLabel';
import { withTranslation } from 'react-i18next';
import { useTranslation, Trans } from 'react-i18next';

const CLanguageAccessPopup = ({ togglePopupLanguageAccess, languageAccessPopup, client }) => {
  const { t } = useTranslation();
  const language = languageAccessPopup?.data?.language.replace('-', '').toLowerCase();

  return <div className="modal open">
      <div className="modal-dialog modal-sm" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <div className="modal-content" style={{ height: 'auto', marginTop: 16 }}>
          <div className="modal-body" style={{ lineHeight: "18px" }}>
            <CLabel k="language.access.popup" param={{
              language: t('settings.languages.' + language),
              client: client?.shortName,
              lng: language
            }} />
          </div>
          <div className="modal-footer">
            <div className="clearfix">
              <div className="box-btn box-right">
                <a className="btn btn-red btn-sm close-modal" onClick={togglePopupLanguageAccess}>
                  <span className="upper"><CLabel k="ok" /></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
}

export default withTranslation()(Connect(CLanguageAccessPopup, {
  dispatch: (dispatch) => ({
    togglePopupLanguageAccess: () => {
      dispatch({ type: 'TOGGLE_POPUP_LANGUAGE_ACCESS' });
    }
  }),
  state: (state) => ({
    translation: state.translation,
    languageAccessPopup: state.popup?.TOGGLE_POPUP_LANGUAGE_ACCESS,
    client: state.session?.user?.client
  })
}));