Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'
CFilterElement = require './CFilterElement'
Utils = require '../../helpers/Utils'
{ API } = require '../../redux/Store'
CDateRange = require('./CDateRange/CDateRange').default
{ withTranslation, Trans } = require 'react-i18next'
CInlineDropdown = require('./CInlineDropdown').default


module.exports = withTranslation() Connect createReactClass(
  displayName: "CHasNotesFilterElement"

  getInitialState: ()->
    isToggleable: true
    type: @props.value?.type
    isNotDone: @props.value?.isNotDone
    createdById: if @props.value?.createdById == null then null else if @props.hasAccessToAssignableActions then [] else [@props.user?.id]
    assignedToId: if @props.hasAccessToAssignableActions then @props.value?.assignedToId || [@props.user?.id] else undefined
    updatedAfter: @props.value?.updatedAfter || null
    updatedBefore: @props.value?.updatedBefore || null
    controlValue: @props.value?.controlValue || null
    enabled: @props.value?.enabled || false
    text: @props.value?.text
    createdByList: []
    assignedToList: []

  componentDidMount: ()->
    @loadAuthors()
    @loadAssignees()

  componentWillReceiveProps: (newProps) -> 
    state = @state
    if(newProps.value?.type != @props.value?.type)
      state.type = newProps.value?.type

    if(newProps.value?.isNotDone != @props.value?.isNotDone)
      state.isNotDone = newProps.value?.isNotDone

    if (newProps.value == undefined)
      state.createdById = [@props.user?.id]
    else if(newProps.value?.createdById != @props.value?.createdById)
      state.createdById = newProps.value?.createdById

    if(newProps.value?.enabled != @props.value?.enabled)
      state.enabled = newProps.value?.enabled

    if(newProps.value?.text != @props.value?.text)
      state.text = newProps.value?.text

    @setState(state)

  selectNotesType: (e)->
    e.preventDefault()
    e.stopPropagation()
    nextActionsOnly = !@isActions() 
    value = 
      type: if nextActionsOnly then ["ACTION"] else null
      isNotDone: nextActionsOnly
      assignedToId: if !nextActionsOnly || !@props.hasAccessToAssignableActions then undefined else [@props.user?.id]

    @setState(value, @handleChange)

  getCreatedByDefault: () ->
    return [
        { titleKey: "actions.and.notes.filter.written.by.me", value: [@props.user && @props.user.id] },
        { titleKey: "actions.and.notes.filter.written.by.anyone", value: [] }
    ]

  isActions: ()->
    @state.type?.length == 1 && @state.type?.indexOf("ACTION") != -1
  
  selectNotesAuthor: (v)->
    @setState(createdById: v.value, @handleChange)

  selectAssignee: (v)->
    @setState(assignedToId: v.value, @handleChange)
  
  isByMe: ()->
    @state.createdById && @state.createdById?.indexOf(@props.user.id) != -1

  isAssignedToMe: ()->
    @state.assignedToId && @state.assignedToId?.indexOf(@props.user.id) != -1

  includeNotes: (e)->
    if(!@props.isFastFilter)
      @setState(enabled: !@state.enabled, @handleChange)

  onTextChange: (name, value)->
      @setState(text: value, @handleChange)

  handleChange: ()->
    value = @state
    @props.onChange?(@props.name, value, false)

  onDateChange: (value)->
    @setState(value, @handleChange)

  loadAuthors: () ->
    API.notesDictionaryAuthors((data) =>
        otherAuthors = data
            .filter((it) => it.value != @props.user.id)
            .map((it) => ({
                title: it.label,
                value: [it.value]
            }))

        createdByList = [...@getCreatedByDefault(), ...otherAuthors]
        @setState({ createdByList: createdByList })
    )

  loadAssignees: () ->
    API.notesDictionaryAssignees((data) =>
        others = data
            .filter((it) => it.value != @props.user.id)
            .map((it) => ({
                title: it.label,
                value: [it.value]
            }))

        items = [...@getCreatedByDefault(), ...others]
        @setState({ assignedToList: items })
    )

  author: ()->
    if (!@state.createdById)
      return null
    author = @state.createdByList?.find((user) =>
      return @state.createdById[0] == user.value[0]
    )
    return if author?.titleKey then @props.t(author.titleKey) else author?.title


  assignee: ()->
    if (!@state.assignedToId)
      return null
    assignee = @state.assignedToList?.find((user) =>
      return @state.assignedToId[0] == user.value[0]
    )
    return if assignee?.titleKey then @props.t(assignee.titleKey) else assignee?.title

  selectNoteTypeAction: (selected)->
    value = 
      type: selected.value.type
      isNotDone: selected.value.isNotDone
      assignedToId: if selected.value.type == null then undefined else [@props.user?.id]

    @setState(value, @handleChange)

  getActionStatuses: () -> [
      {
          value: {
            isNotDone: false,
            type: null,
          }
          title: this.props.t("search.additional.property.notes.notesOrAction")
      },
      { 
          value: {
            isNotDone: true,
            type: ["ACTION"],
          }
          title: this.props.t("search.additional.property.notes.actionsDue")
      },
      {
          value: {  
            isNotDone: false,
            type: ["ACTION"],
          }
          title: this.props.t("search.additional.property.notes.actionsDone")
      },
      {
          value: {
            isNotDone: null,
            type: ["ACTION"],
          }
          title: this.props.t("search.additional.property.notes.actionsDueOrDone")
      }
  ]

  getActionStatusValue: ()->
    statuses = @getActionStatuses()
    status = statuses.find((item) =>
      return item.value.isNotDone == @state.isNotDone && item.value.type?.length == @state.type?.length
    )
    return status?.title

  render: ()->
    React.createElement("div", {"style": (marginTop: "15px"), "className": (if !@props.isFastFilter then "box-checkbox middle line-normal has-notes dofl" else "line-normal dofl")},
      React.createElement("input", {"type": "checkbox", "className": (if @props.isFastFilter then "hide-all" else ""), "id": ("hasNotes" + @props.name), "checked": (@state?.enabled || false), "onChange": (@includeNotes)}),
      React.createElement("label", {"htmlFor": ("hasNotes" + @props.name)},
        (if (!@props.notesSharingAllowed) then React.createElement(Trans, {"i18nKey": "search.additional.property.notes.filter", "values": ({
           notesType: if @isActions() then @props.t("search.additional.property.notes.actionsDue") else @props.t("search.additional.property.notes.notesOrAction")
         })},
          React.createElement("a", {"className": "btn", "onClick": (@selectNotesType)})
        )),
         (if (@props.notesSharingAllowed && !@props.hasAccessToAssignableActions) then React.createElement(Trans, {"i18nKey": (if @props.name == "propertyNotesFilter" then "search.additional.property.notes.filter.sharing.allowed" else "search.additional.company.notes.filter.sharing.allowed"), "values": ({
           notesType: if @isActions() then @props.t("search.additional.property.notes.actionsDue") else @props.t("search.additional.property.notes.notesOrAction"),
           author: @author()
         })},
            React.createElement("a", {"className": "btn", "onClick": (@selectNotesType)}),
            React.createElement(CInlineDropdown, { \
                "as": "a",  \
                "onSelect": (@selectNotesAuthor),  \
                "items": (@state.createdByList.map((item) => ({ ...item, title: if item.titleKey then @props.t(item.titleKey) else item.title })))}, """
                anyone
"""),
            React.createElement(CDateRange, {"onChange": (@onDateChange), "controlValue": (@props.value?.controlValue)})  
          )
         ),
         (if (@props.notesSharingAllowed && @props.hasAccessToAssignableActions && !@isActions()) then React.createElement(Trans, {"i18nKey": (if @props.name == "propertyNotesFilter" then "search.additional.property.notes.filter.sharing.notes" else "search.additional.company.notes.filter.sharing.notes"), "values": ({
           author: @author()
         })},
            React.createElement(CInlineDropdown, { \
                "as": "a",  \
                "onSelect": (@selectNoteTypeAction),  \
                "items": (@getActionStatuses())}, """
                notes or actions
"""),
            React.createElement(CInlineDropdown, { \
                "as": "a",  \
                "onSelect": (@selectNotesAuthor),  \
                "items": (@state.createdByList.map((item) => ({ ...item, title: if item.titleKey then @props.t(item.titleKey) else item.title })))}, """
                anyone
"""),
            React.createElement(CDateRange, {"onChange": (@onDateChange), "controlValue": (@props.value?.controlValue)})  
          )
         ),
         (if (@props.notesSharingAllowed && @props.hasAccessToAssignableActions && @isActions()) then React.createElement(Trans, {"i18nKey": (if @props.name == "propertyNotesFilter" then "search.additional.property.notes.filter.sharing.actions" else "search.additional.company.notes.filter.sharing.actions"), "values": ({
           author: @author()
           assignee: @assignee()
           status: @getActionStatusValue()
         })},
            React.createElement(CInlineDropdown, { \
                "as": "a",  \
                "onSelect": (@selectNoteTypeAction),  \
                "items": (@getActionStatuses())}, """
                actions due
"""),
            React.createElement(CInlineDropdown, { \
                "as": "a",  \
                "onSelect": (@selectNotesAuthor),  \
                "items": (@state.createdByList.map((item) => ({ ...item, title: if item.titleKey then @props.t(item.titleKey) else item.title })))}, """
                anyone
"""),
            React.createElement(CInlineDropdown, { \
                "as": "a",  \
                "onSelect": (@selectAssignee),  \
                "items": (@state.assignedToList.map((item) => ({ ...item, title: if item.titleKey then @props.t(item.titleKey) else item.title })))}, """
                me
"""),
            React.createElement(CDateRange, {"onChange": (@onDateChange), "controlValue": (@props.value?.controlValue)})  
          )
         )
      ),
      React.createElement("div", {"className": ("d-flex mt-1 flex-row justify-content-between align-items-center" + if !@props.isFastFilter then " has-notes-controls" else "")},
        React.createElement(CLabel, {"k": ("search.additional.property.notes.label"), "className": "mr-2"}),
        React.createElement("div", {"style": ({ flex: 1 })},
          React.createElement(CFilterElement, {"onChange": (@onTextChange), "changeOnBlur": (false), "name": (@props.name + ".text"), "value": (@props.value?.text), "type": "text", "className": "form-control", "placeholder": (@props.t("actions.and.notes.filter.contains.placeholder"))})
        )
      )
    )
) , {
  state: (state) ->
    translation: state.translation
    user: state.session?.user
    hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions")
}