{ AccessRestriction } = require "../AccessRestriction"
Utils = require '../../helpers/Utils'
Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'
CNoteText = require '../../cmp/note/CNoteText'
CNoteDates = require '../../cmp/note/CNoteDates'
CThreeDots = (require '../../cmp/CThreeDots').default
{ API } = require '../../redux/Store'
{ isMobile } = require '../../helpers/Utils'
{ getSearchForm, cleanupFilter } = require '../../cmp/results/CFilterUtils'
{ Link } = require 'react-router'
{ isContentAdmin } = require '../../helpers/UserUtils'
{ checkActionConfirmationForAA, ACTION_TYPES } = require '../../helpers/UtilsHelpers'
{ withTranslation } = require 'react-i18next'

module.exports = withTranslation() Connect createReactClass(
  displayName: "CNoteBlock"

  getInitialState: ()->
    showDropdown: false
    note: @props.note
    isLoading: false

  componentWillReceiveProps: (newProps)->
    if(@state.note != newProps.note)
      @setState note: newProps.note

  makeActive: (note, active)->
    @toggleDropdown()
    callbackFn = () => 
      @setState isLoading: true
      if @props.onMarkingAction
        @props.onMarkingAction(note.id)
      API.editNote {id: note.id, active: active}, (response)=>
          @setState isLoading: false
          @setState note: response
          @props.onNoteChanged(response)
    if (active)
      checkActionConfirmationForAA(@props.t, note, @props.user, ACTION_TYPES.MARK_AS_NOT_DONE, @props.toggleConfirmActionPopup, callbackFn)
      return
    checkActionConfirmationForAA(@props.t, note, @props.user, ACTION_TYPES.MARK_AS_DONE, @props.toggleConfirmActionPopup, callbackFn)

  editNote: (note)->
    @props.toggleEditNotePopup(note.property, note.company, note, (response) =>
      @setState note: response
      @props.onNoteChanged(response)
    , (response) =>
      @props.onNoteDeleted(response.id)
    , (response) =>
      @props.onNoteDeleted(response.id)
    )
    @toggleDropdown()

  addNewNote: (note)->
    @props.toggleEditNotePopup(note.property, note.company, null, (response) =>
      @props.onNoteAdded(response)
    )
    @toggleDropdown()

  deleteNote: (note)->
    @toggleDropdown()
    callbackFn = () =>
      @setState isLoading: true
      API.deleteNote note.id, null, null, (response)=>
        @setState isLoading: false
        if (@props.onNoteDeleted)
            @props.onNoteDeleted(note.id)
    checkActionConfirmationForAA(@props.t, note, @props.user, ACTION_TYPES.DELETE, @props.toggleConfirmActionPopup, callbackFn)

  toggleDropdown: (value)->
    if value
      if @props.disableMenu
        return
      document.body.classList.add('dropdown-open')
      document.querySelector('.block-nav').classList.add('hide-mobile-menu')
      @props.setIsOpenNoteDropdown(true)
    else
      document.body.classList.remove('dropdown-open')
      document.querySelector('.block-nav').classList.remove('hide-mobile-menu')
    @setState showDropdown: value

  onClick: (e)->
    if e.target.classList.contains('menu-dropdown')
      @toggleDropdown(false)
      e.preventDefault()
      e.stopPropagation()
      @props.setIsOpenNoteDropdown(false)

  onTouch: (e)->
    if e.target.closest('.menu-dropdown') == null
      @toggleDropdown(false)

  onKeyUp: (e)->
    if e.which == 27
      @toggleDropdown(false)

  onBodyClick: (e)->
    if e.target.closest('.menu-dropdown') == null
      @toggleDropdown(false)

  componentDidMount: ()->
    if isMobile()
      document.getElementById("note-#{@props.note.id}").addEventListener('click', @onClick, true)
      document.getElementById("note-#{@props.note.id}").addEventListener('touchstart', @onTouch, true)
      document.getElementById("note-#{@props.note.id}").addEventListener('touchmove', @onTouch, true)
      document.getElementById("note-#{@props.note.id}").addEventListener('keyup', @onKeyUp, true)
      return
    document.body.addEventListener('click', @onBodyClick);

  componentWillUnmount: ()->
    if isMobile()
      document.getElementById("note-#{@props.note.id}").removeEventListener('click', @onClick, true)
      document.getElementById("note-#{@props.note.id}").removeEventListener('touchstart', @onTouch, true)
      document.getElementById("note-#{@props.note.id}").removeEventListener('touchmove', @onTouch, true)
      document.getElementById("note-#{@props.note.id}").removeEventListener('keyup', @onKeyUp, true)
      return
    document.body.removeEventListener('click', @onBodyClick);

  getNoteTypeTitleKey: (note) -> 
    if note.property
      if note.type == 'ACTION'
        "actions.for.property"
      else
        "notes.for.property"
    else 
      if note.type == 'ACTION'
        "actions.for.company"
      else
        "notes.for.company"

  showRelatedPropertyIcon: ()->
    fields = getSearchForm({
      searchForm: cleanupFilter(@props.searchForm),
      client: @props.client,
      fullReload: true,
      excludedFields: ["propertyNotesFilter", "companyNotesFilter"],
      onSearch: () -> {}
    })
    fieldNames = [...fields.keys()]
    companyFields = fieldNames.filter((field) => field && field.startsWith("company"))
    hasPropertyFields = fieldNames.length > 0 && companyFields.length != fieldNames.length

    navigateToSearch = () =>
      companyFields.forEach((field) =>
        @props.addValueToForm(field, undefined);
      )
      @props.savePreviousForm(@props.searchForm);
      @props.addValueToForm("companyId", @state.note.companyId);
      @props.router.push('/results')

    if @state.note?.company && hasPropertyFields
      return React.createElement("span", {"className": "btn link-grey tooltip tooltip-bottom-middle", "onClick": (navigateToSearch)},
        React.createElement("i", {"className": "icon icon-property-filled small icon-middle"}),
        React.createElement("span", {"className": "tooltiptext"},
          React.createElement("span", {"className": "light-grey"},
            React.createElement(CLabel, {"k": "actions.and.notes.company.involved.in.properties", "param": ({ companyName: @state.note.company.name })})
          )
        )
      )
    return null

  assignToMe: (note) ->
    @toggleDropdown(false)
    @toggleDropdown(false)
    callbackFn = () =>
      API.editNote {id: note.id, assignedToId: @props.user.id}, (response) =>
          @props.onNoteChanged(response)
    checkActionConfirmationForAA(@props.t, note, @props.user, ACTION_TYPES.REASSIGN, @props.toggleConfirmActionPopup, callbackFn)

  showHistory: (note)-> 
    @toggleDropdown(false)
    @props.toggleNoteHistoryPopup(note)

  render: ()->
    note = @state.note
    parentItem = if note.property then note.property else note.company
    link = if note.property then "/property/#{note.property.id}" else "/company/#{note.company.id}"
    assignedToMe = note.assignedTo?.id == @props.user?.id
    isOwn = note.createdBy?.id == @props.user?.id
    allowEdit = !note.deletedAt && (isOwn || isContentAdmin(@props.user, @props.isAliasEnabled) || @props.hasAccessToAssignableActions)
    allowDelete = !note.deletedAt && (isOwn || isContentAdmin(@props.user, @props.isAliasEnabled))

    React.createElement("div", {"id": "note-#{note.id}", "data-key": (note.id), "className": ("note-block tile-action#{if note.type == "ACTION" && !note.active then " done" else ""} #{if note.deletedAt then " deleted" else ""} #{if note.property then "" else "company-notes" } #{if @props.isAnimating then "note-highlighted" else "" }"), "style": ( opacity: @props.opacity )},
      React.createElement("div", {"className": "tile-group"},
        React.createElement("div", {"className": ("tile-title note-header #{if @props.hasAccessToAssignableActions then "access-to-aa" else ""}")},
          React.createElement("div", {"className": "name-block small"},
            React.createElement(Link, {"className": "bold dark-grey", "to": (link), "title": (parentItem.name)},
              (if note?.deletedAt then React.createElement(CLabel, {"k": "actions.and.notes.deleted", "className": "deleted-note"}) else ""),
              (parentItem.name)
            ),  
            (@showRelatedPropertyIcon()),
            React.createElement("div", {"className": "text-overflow note-address"}, (if parentItem?.city then parentItem?.city + ', '), (if parentItem?.stateProvince then parentItem?.stateProvince + ', '), (parentItem?.country))
          ),
          React.createElement("div", {"className": "box-btn"},
            (!@props.hasAccessToAssignableActions && React.createElement(React.Fragment, null,
              React.createElement("div", {"className": "hide-mobile text-right", "style": (display: "flex", alignItems: "right", justifyContent: "space-between")},
                React.createElement("span", {"className": "btn nowrap link-grey #{if allowEdit || allowDelete then 'hide-on-hover'} italic", "style": (paddingRight: "8px")}, React.createElement(CLabel, {"k": (@getNoteTypeTitleKey(note))})),
                (if allowEdit
                  React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn nowrap link-grey open-modal show-on-hover", "style": (border: "none"), "onClick": (@editNote.bind @, note)}, React.createElement("i", {"className": "fa fa-pencil icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.edit"})))
                ),
                (if allowDelete
                  React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn nowrap link-grey open-modal show-on-hover", "onClick": (@deleteNote.bind @, note)}, React.createElement("i", {"className": "fa fa-close icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.delete"})))
                ),
                (if note.type == "ACTION"
                  if allowEdit
                    React.createElement(React.Fragment, null,
                      (if note.active
                        React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn nowrap link-grey", "onClick": (@makeActive.bind @, note, false)}, React.createElement("i", {"className": "fa fa-check icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.mark.as.done"})))
                      else
                        React.createElement(AccessRestriction, null,
                          React.createElement("a", {"className": "btn nowrap link-grey hide-on-hover"}, React.createElement("i", {"className": "fa fa-check icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.done"})),
                          React.createElement("a", {"className": "btn nowrap link-grey show-on-hover", "onClick": (@makeActive.bind @, note, true)}, React.createElement("i", {"className": "fa fa-check icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.mark.as.not.done"}))
                        )
                      )
                    )
                  else
                    React.createElement("a", {"className": "btn nowrap link-grey"}, React.createElement("i", {"className": "fa fa-check icon-left"}), React.createElement(CLabel, {"k": "#{if note.active then 'actions.and.notes.not.done' else 'actions.and.notes.done'}"}))
                )
              ),
              React.createElement("div", {"className": "hide-mobile text-right"},
                React.createElement("a", {"className": "btn nowrap link-grey", "onClick": (@addNewNote.bind @, note)}, React.createElement("i", {"className": "fa fa-plus icon-left"}), React.createElement(CLabel, {"k": (if note.property then "actions.and.notes.add.new.note.to.property" else "actions.and.notes.add.new.note.to.company")}))
              )
            )),
            React.createElement("div", {"className": ("box-dropdown nav-action text-right #{if @props.hasAccessToAssignableActions then "" else "show-mobile"}")},
              React.createElement(CThreeDots, {"onClick": (@toggleDropdown.bind @, true), "disabled": (@props.disableMenu && @props.hasAccessToAssignableActions), "loading": (@props.hasAccessToAssignableActions && (this.state.isLoading || @props.isAnimating))}),
              (if @state.showDropdown
                React.createElement("div", {"className": "menu-dropdown"},
                  React.createElement("div", {"className": "show-mobile item-action small", "style": ({ marginBottom: 0 })},
                    React.createElement("div", {"className": "name-block name-block-note-title"},
                      React.createElement(Link, {"className": "bold", "onClick": (@toggleDropdown.bind @, false), "to": (link)},
                        (if note?.deletedAt then React.createElement(CLabel, {"k": "actions.and.notes.deleted", "className": "deleted-note"}) else ""),
                        (parentItem.name)
                      ),  
                      (@showRelatedPropertyIcon())
                    ),
                    React.createElement("div", {"className": "note-address"}, (if parentItem?.city then parentItem?.city + ', '), (if parentItem?.stateProvince then parentItem?.stateProvince + ', '), (parentItem?.country)),
                    React.createElement(CNoteDates, {"note": (note)}),
                    React.createElement("div", {"className": "text"},
                      React.createElement(CNoteText, {"note": (note)})
                    )
                  ),
                  React.createElement("a", {"className": "btn", "onClick": (@addNewNote.bind @, note)}, React.createElement("i", {"className": "fa fa-plus icon-left"}), React.createElement(CLabel, {"k": (if note.property then "actions.and.notes.add.new.note.to.property" else "actions.and.notes.add.new.note.to.company")})),
                  (allowEdit && !assignedToMe && note.type == "ACTION" && @props.hasAccessToAssignableActions && React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn", "onClick": (@assignToMe.bind @, note, false)}, React.createElement("i", {"className": "icon-hand-right-black"}), React.createElement(CLabel, {"k": "actions.and.notes.assign.to.me"})))),
                  (if note.type == "ACTION" && allowEdit
                    if note.active
                      React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn", "onClick": (@makeActive.bind @, note, false)}, React.createElement("i", {"className": "fa fa-check icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.mark.as.done"})))
                    else
                      React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn", "onClick": (@makeActive.bind @, note, true)}, React.createElement("i", {"className": "fa fa-check icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.mark.as.not.done"})))
                  ),
                  (if allowEdit 
                    React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn", "onClick": (@editNote.bind @, note)}, React.createElement("i", {"className": "fa fa-pencil icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.edit"})))
                  ),
                  (if allowDelete
                    React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn", "onClick": (@deleteNote.bind @, note)}, React.createElement("i", {"className": "fa fa-close icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.delete"})))
                  ),
                  (@props.hasAccessToAssignableActions && React.createElement("a", {"className": "btn", "onClick": (@showHistory.bind @, note)},
                    React.createElement("i", {"className": "icon icon-history icon-left"}),
                    React.createElement(CLabel, {"k": "actions.and.notes.showHistory"})
                  ))
                )
              )
            ),
            (if false && note.companyId && note.involvedCount
              React.createElement("div", {"className": "bold text-right", "style": (fontSize: "13px", paddingRight: "8px")}, "Involved at ", (note.involvedCount), " matching propert", (if note.involvedCount == 1 then "y" else "ies"))
            )
          )
        ),
        React.createElement("div", {"className": "item-action"},
          React.createElement(CNoteDates, {"note": (note)}),
          React.createElement("div", {"className": "text"},
            React.createElement(CNoteText, {"note": (note)})
          )
        )
      )
    )
),{
 dispatch: (dispatch) ->
   addValueToForm: (name, value) => dispatch({ type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value }),
   toggleEditNotePopup: (property, company, note, onChanged, onDeleted) -> dispatch(type: 'TOGGLE_POPUP_EDIT_NOTE', data: {property:property, company: company, note: note, onChanged: onChanged, onDeleted: onDeleted })
   toggleNoteHistoryPopup: (note) -> dispatch(type: 'TOGGLE_POPUP_NOTE_HISTORY', data: { note: note })
   savePreviousForm: (payload) => dispatch({ type: 'SAVE_PREVIOUS_FORM', payload }),
   toggleConfirmActionPopup: (data) => dispatch({ type: "TOGGLE_POPUP_CONFIRM_ACTION", data: data }),
 state: (state) ->
   searchForm: state.searchForm.form
   user: state.session?.user
   isAliasEnabled: state.session?.isAliasEnabled
   hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions")
}
