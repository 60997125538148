import * as moment from 'moment';
import { getBrowserLanguages } from './helpers/LocaleHelpers';

moment.defineLocale('zh', {
    parentLocale: 'zh-cn',
});

const updateLocales = (locales, config) => {
    locales.forEach(locale => {
        if (locale.toLowerCase().includes('zh')) {
            const zhConfig = { ... config, longDateFormat: { ...config.longDateFormat } };
            Object.keys(zhConfig.longDateFormat).forEach((key) => {
                zhConfig.longDateFormat[key] = zhConfig.longDateFormat[key].split(' ').join('');
            });
            moment.updateLocale(locale.toLowerCase(), zhConfig);
            return;
        }
        moment.updateLocale(locale.toLowerCase(), config);
    });
};

export const configMomentLocales = (languages) => {
    const availableLocales = [...getBrowserLanguages(), ...languages].filter(locale => locale.toLowerCase() !== 'en-us');
    updateLocales(availableLocales, {
        longDateFormat: {
            '[month-year]': 'MM/YYYY',
            '[MMM-D]': 'MMM D',
            '[MMM-DD]': 'MMM DD'
        }
    });
    moment.updateLocale('de', {
        longDateFormat: {
            '[month-year]': 'MM/YY',
            '[MMM-D]': 'D. MMM',
            '[MMM-DD]': 'DD. MMM'
        }
    });
    moment.updateLocale('it', {
        longDateFormat: {
            '[month-year]': 'MM/YY',
            '[MMM-D]': 'D MMM',
            '[MMM-DD]': 'DD MMM'
        }
    });
    moment.updateLocale('fr', {
        longDateFormat: {
            '[month-year]': 'MM/YY',
            '[MMM-D]': 'D MMM',
            '[MMM-DD]': 'DD MMM'
        }
    });
    moment.updateLocale('en-us', {
        longDateFormat: {
            'L': 'MM/DD/YY',
            '[month-year]': 'MM/YY',
            '[MMM-D]': 'MMM D',
            '[MMM-DD]': 'MMM DD'
        }
    });

    moment.updateLocale('hi', {
        longDateFormat: {
            '[month-year]': 'MM/YYYY',
            '[MMM-D]': 'MMM D',
            '[MMM-DD]': 'MMM DD'
        },
        preparse: function (string) {
            return string;
        },
        postformat: function (string) {
            return string;
        },
    });
};
