
Connect = require '../../../helpers/Connect'
{ Link } = require 'react-router'
{ API } = require '../../../redux/Store'
{ getParsedData } = require '../../../helpers/Charts'
CChart = require './CChart'
{ CLabel } = require '../../CLabel'
An = require '../../../helpers/Analytics'
{ isMobile } = require "../../../helpers/Utils"
{ getChartTitle } = require "../../../helpers/ChartHelpers"
{ withTranslation } = require 'react-i18next'
camelCase = require 'lodash.camelcase';
{ onScrollTooltipPosition } = require '../../../helpers/UtilsHelpers'

module.exports = withTranslation() Connect createReactClass(

  displayName: 'CCharts'

  getInitialState: ->
    activeTab: 0

  componentWillMount: ->
    @props.loadCharts()

  componentWillReceiveProps: (newProps) ->
    if newProps.chartsData.length == 0 || window.addedScrollListener || (newProps.chartsData[0] == '' && newProps.chartsData.length == 1)
      return;
    window.addedScrollListener = true
    newProps.chartsData.forEach((chart, index)=>
      onScroll = onScrollTooltipPosition("charts-tooltip-#{index}", "charts-tooltip-anchor-#{index}")
      document.querySelector('.main-content').addEventListener('scroll', onScroll)
    )
  
  componentWillUnmount: ->
    window.addedScrollListener = false
    (@props.chartsData || []).forEach((chart, index)=>
      onScroll = onScrollTooltipPosition("charts-tooltip-#{index}", "charts-tooltip-anchor-#{index}")
      document.querySelector('.main-content').removeEventListener 'scroll', onScroll
    )

  changeTab: (tabIndex)->
    @setState activeTab: tabIndex
    An.charts.chart()

  translateLabels: (chartData) ->
    data = chartData.data?.map (item) =>
      chartData.dimensions?.forEach (dimension, index) =>
        if dimension.toLowerCase().includes("chainscale")
          value = item["dimension" + (index + 1)].value
          if value == '~'
            value = 'Unbranded'
          item["dimension" + (index + 1)].label = @props.t("data.chainScale." + camelCase(value), { ns: 'data' });
          return;
        if dimension.toLowerCase().includes("projectstage")
          item["dimension" + (index + 1)].label = @props.t("data.projectStage." + camelCase(item["dimension" + (index + 1)].value), { ns: 'data' });
          return;
      item
    return {
      ...chartData,
      data: data
    }

  render: ->
    React.createElement("div", {"className": "block-tile row-half"},
      React.createElement("div", {"className": "box-tile", "style": (height: '100%', position: 'relative')},
        (if @props.chartsData?.length > 0 && !@props.isLoading
          React.createElement("div", null,
            React.createElement("div", {"className": "box-nav-tabs"},
              React.createElement("ul", {"className": "nav-tabs"},
                (@props.chartsLegend?.slice(0, 4).map((it, index)=>
                  if it.dimensions?.length == 1
                    title = if "custpropset" in it.dimensions then it.title || @props.t("data.charts.shortname.#{it.dimensions[0]}", { ns: 'data' }) else @props.t("data.charts.shortname.#{it.dimensions[0]}", { ns: 'data' })
                  else if it.dimensions?.length == 2
                    dimension1 = if "custpropset" in it.dimensions then it.title else @props.t("data.charts.shortname.#{it.dimensions[0]}", { ns: 'data' })
                    title = @props.t("data.charts.shortname2Dimensions", {
                      dimension1: dimension1,
                      dimension2: @props.t("data.charts.shortname.#{it.dimensions[1]}", { ns: 'data' }),
                      ns: 'data'
                    })
                  React.createElement("li", {"style": (height: '100%'), "key": (index), "className": (if @state.activeTab == index then 'active')}, React.createElement("a", {"style": (height: '100%'), "onClick": (@changeTab.bind @, index)}, React.createElement("span", null, (it.shortname || title || it.title))))
                ))
              )
            ),
            React.createElement("div", {"className": "block-tabs"},
              ((@props.chartsData || []).map((chart, index)=>
                chartData = if chart then getParsedData(@translateLabels(chart), index, @props.t, @props.i18n) else {}
                React.createElement("div", {"className": "box-tab #{if @state.activeTab == index then 'active' else ''}", "key": (index)},
                  React.createElement("div", {"className": "box-tab-content"},
                    React.createElement("div", {"className": "tile-title grey", "style": (paddingBottom: '8px')},
                      React.createElement("div", {"className": "box-btn box-right", "onClick": (if isMobile() then @props.onTooltip else undefined)},
                        React.createElement("a", {"id": "charts-tooltip-anchor-#{index}", "className": "btn btn-info tooltip-bottom"},
                          React.createElement("span", {"id": "charts-tooltip-#{index}", "className": "tooltiptext"}, React.createElement(CLabel, {"k": "home.charts.info"})),
                          React.createElement("i", {"className": "fa fa-info"})
                        )
                      ),
                      React.createElement("div", {"className": "name-block chart-block small"}, React.createElement("span", {"className": "upper two-lines-ellipsis"}, (getChartTitle(chart, @props.t))))
                    ),
                    (if chartData
                      React.createElement("div", {"className": "box-charts"},
                        (if chartData instanceof Array
                          React.createElement("div", {"className": "double-chart"},
                            React.createElement("div", {"className": "chart-info"},
                              (chartData[0].data.slice(1).map((data, idx)->
                                React.createElement("div", {"className": "item-info", "key": (idx)}, React.createElement("i", {"className": "fa fa-circle", "style": (fontSize: '1em', color: chartData[0].options.colors[idx])}), " ", (data[0]))
                              ))
                            ),
                            React.createElement("div", {"className": "clearfix"},
                              (chartData.map((it, id)->
                                React.createElement(CChart, Object.assign({},  it, {"isDoubleChart": (true), "key": (id), "index": (id), "chart": (chart)}))
                              ))
                            )
                          )
                        else if Object.keys(chartData).length == 0
                          React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
                        else
                          React.createElement(CChart, Object.assign({},  chartData, {"chart": (chart)}))
                        ),
                        React.createElement("div", {"style": (position: 'absolute', bottom: '50px', right: '0'), "className": "chart-info text-right"},
                          React.createElement(Link, {"className": "btn", "to": "/charts"}, React.createElement(CLabel, {"k": "menu.chart.gallery"}), " ", React.createElement("i", {"className": "fa fa-chevron-right small"}))
                        )
                      )
                    else
                      React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
                    )
                  )
                )
              ))
            )
          )
        else if @props.isLoading && @props.chartsLegend?.length > 0
          React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
        )
      )
    )

),
  state: (state)->
    chartsLegend: state.app?.charts
    chartsData: state.charts.data
    isLoading: state.charts.isLoading
    translation: state.translation
  dispatch: (dispatch)->
    loadCharts: ()-> dispatch(type: "LOAD_FIRST_CHART")
