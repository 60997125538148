Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'


module.exports = Connect createReactClass(
  displayName: "CCustomPopup"

  render: ()->
    React.createElement("div", {"className": "modal open"},
      React.createElement("div", {"className": "modal-header-mobile show-mobile"},
        (if !@props.data?.hideCloseOnMobile
          React.createElement("a", {"className": "btn btn-back close-modal", "onClick": (@props.togglePopupCustom)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.custom.popup.close"})))
        ),
        React.createElement("div", {"className": "title-page"}, React.createElement(CLabel, {"k": (@props.data?.title)}))
      ),
      React.createElement("div", {"className": "modal-dialog #{if @props.data?.modalSize then @props.data.modalSize else 'modal-sm'}"},
        React.createElement("div", {"className": "modal-content"},
          React.createElement("div", {"className": "modal-header"},
            React.createElement("div", {"className": "modal-title"},
              React.createElement("div", {"className": "name-block small"}, React.createElement(CLabel, {"k": (@props.data?.title)}))
            )
          ),
          (if !@props.data?.hideBody
            React.createElement("div", {"className": "modal-body"},
              (@props.data?.content)
            )
          ),
          React.createElement("div", {"className": "modal-footer hide-mobile"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "box-btn box-right"},
                (@props.data?.button),
                (if !@props.data?.hideDefaultButton
                  React.createElement("a", {"className": "btn btn-grey btn-sm close-modal", "onClick": (@props.togglePopupCustom)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": (@props.data?.buttonText || "settings.custom.popup.close")})))
                )
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": "modal-footer-mobile show-mobile"},
        (@props.data?.button),
        (if !@props.data?.hideDefaultButton
          React.createElement("a", {"className": "btn #{if @props.data?.buttonTextClass then @props.data.buttonTextClass else 'btn-red'} btn-normal close-modal", "onClick": (@props.togglePopupCustom)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": (@props.data?.buttonText || "settings.custom.popup.close")})))
        )
      )
    )
),
dispatch: (dispatch)->
  togglePopupCustom: ()-> dispatch(type: 'TOGGLE_POPUP_CUSTOM')
state: (state)->
  translation: state.translation
