LENAV_NOTES_FILTER = "LENAV_NOTES_FILTER"
{ extend } = require '../../helpers/UtilsHelpers'

clone = (o) ->
  extend(true, {}, o)

DEFAULT_TABS =
  activeTab: "ACTION_DUE"
  filter:
    isOwn: true

module.exports = (getStore, getAPI) ->
  #window.loadNotes needs for refreshing list on devices after update by push notification
  window.loadNotes = loadNotes = () ->
    console.log("Update notes list by Push Disabled") #TODO should be moved into Notes module

  loadActionsToDo = () ->
    getAPI().getActionsToDo (data) ->
      getStore().dispatch(type: "SET_ACTIONS_TODO", actions: data)

  loadTabsState = () ->
    if sessionStorage.getItem(LENAV_NOTES_FILTER)
      JSON.parse(sessionStorage.getItem(LENAV_NOTES_FILTER))
    else
      DEFAULT_TABS

  (state = list: [], tabs: loadTabsState(), action) ->
    if (action.type == "SET_ACTIONS_TODO")
      state.actionsToDo = action.actions
      clone state
    else if (action.type == "LOAD_ACTIONS_TODO")
      loadActionsToDo()
      state
    else if (action.type == "RESET_NOTE_TABS")
      state.tabs = DEFAULT_TABS
      clone state
    else if (action.type == "SET_ACTIVE_TAB")
      state.tabs.activeTab = action.name
      clone state
    else if (action.type == "SET_TAB_FILTER")
      state.tabs.filter = action.filter
      sessionStorage.setItem(LENAV_NOTES_FILTER, JSON.stringify(state.tabs))
      clone state
    else
      state
