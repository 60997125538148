import '../assets/style/bootstrap.scss'
{ render } = require 'react-dom';
{ Provider } = require 'react-redux'
{ Suspense } = require 'react'

{ Router, Route, IndexRoute } = require 'react-router'
{ store, history, API } = require './redux/Store'

import ActionsAndNotes2 from './notes/ActionsAndNotes2'
import WatchList2 from './watchList/WatchList2'
import ShadowUser from './shadowUser/ShadowUser'
import Home from './pages/Home.js'
import './i18n';
UseMobileApp = require './pages/UseMobileApp'
Maintenance = require './pages/Maintenance'
Sfc = require './pages/Sfc'
Login = require './pages/Login'
Utils = require './helpers/Utils'
{ getDateLocale } = require "./helpers/LocaleHelpers"
Moment = require "moment"
import i18next from 'i18next'
import { initializeFirebase } from './firebase/firebaseConfig'


renderMainApp = ()->
  store.dispatch type: "LOAD_ACTIONS_TODO"

  document.title = i18next.t(Utils.getEnvStyle().title)

  API.settings (settings)=>
    store.dispatch type:"SET_SETTINGS", payload:settings
    store.dispatch type:"SET_TRANSLATIONS", EN: settings.translations
    if window.deviceName == 'INIT'
      window.deviceName = 'BROWSER'

    if store.getState().session
      API.currentSession (session)=>
        if (session?.user?.language)
          i18next.changeLanguage session.user.language
          document.title = i18next.t(Utils.getEnvStyle().title)
          Moment.locale(getDateLocale(session.user.language))
        store.dispatch type:"LOGIN", payload: session, currentLocation: history.getCurrentLocation()
      #if Utils.isMobileAgent()
      #  render(<Provider store={store}><UseMobileApp /></Provider>, document.querySelector(".wrapper"))

  API.getImportJob (job) =>
    store.dispatch type:"SET_IMPORT_JOB", payload: job
    setInterval () =>
      API.getImportJob (job) =>
        store.dispatch type:"SET_IMPORT_JOB", payload: job
    , 10000


  state = store.getState()

  history.listen (location)->
    licenceAccepted = store.getState().session?.user?.licenceAccepted
    isAliasEnabled = store.getState().session?.isAliasEnabled
    aliasLogout = sessionStorage.getItem("ALIAS_LOGOUT")
    if !licenceAccepted && location.pathname not in ['/login/first', '/login'] && location.action == "POP" && !isAliasEnabled
      history.push('/login')
    store.dispatch(type: "CHANGE_LOCATION", location: location, action: location.action)

  securedPages = (router)->
    token = store.getState().session?.token
    licenceAccepted = store.getState().session?.user?.licenceAccepted
    isAliasEnabled = store.getState().session?.isAliasEnabled
    path = router.location?.pathname?.trim()
    if !token
      if path && path !="/" && path != "/home" && !sessionStorage.getItem("PREVIOUS_URL")
        sessionStorage.setItem("PREVIOUS_URL", path)
      history.push('/login')
    else if !licenceAccepted && path != "/login/first" && !isAliasEnabled
      history.push("/login/first")
    else
      if sessionStorage.getItem("PREVIOUS_URL")
        url = sessionStorage.getItem("PREVIOUS_URL")
        sessionStorage.removeItem("PREVIOUS_URL")
        history.push(url)

  window.updateFcmToken = (app, deviceToken) =>
    token = store.getState().session?.token
    if token
      API.addDevice(app, deviceToken)
  if window.deviceToken
    window.updateFcmToken(window.deviceName, window.deviceToken)

  if !Utils.isMobileAgent()
    client = store.getState().session?.user?.client
    hasKeyword = Utils.hasFeature(client, "WebPushNotification")
    if hasKeyword
      initializeFirebase();

  publicPages = ()->
    token = store.getState().session?.token
    licenceAccepted = store.getState().session?.user?.licenceAccepted
    if token && licenceAccepted
      history.push('/home')

  setFontFamilyByLanguage = (lng) =>
    localStorage.setItem("i18nextPrevLng", lng)
    document.body.className += " lng-" + lng
    if (lng == 'fr' || lng == 'es' || lng == 'pt-BR' || lng == 'it' || lng == 'de')
      document.body.style = 'font-family: Arial, Helvetica, sans-serif;'
      return;
    document.body.style = 'font-family: "HelveticaNeue", Arial, Helvetica, sans-serif;'

  setBrowserClass = () =>
    if navigator.vendor?.indexOf("Apple") > -1
      document.body.className += " safari"
    else
      document.body.className = "not-safari"

  renderLoader = ()->
      React.createElement("div", {"className": "lds-spinner", "style": ({ margin: "30px auto" })},
        (new Array(12).fill(0).map((_, i) => React.createElement("div", null)))
      )

  onKeyPress = (event)->
    if (config.LE_ENV == 'production' && !store.getState().session?.user.isTester)
      return
    if (event.keyCode == 76 && event.altKey)
        nextLang = localStorage.getItem("i18nPrevLng")
        Moment.locale(getDateLocale(nextLang))
        i18next.changeLanguage(nextLang)
        user = {
          ...store.getState().session?.user,
          language: nextLang
        }
        API.updateUser user, (user)=>
          store.dispatch type: "SET_USER", user: user

  window.addEventListener("keydown", onKeyPress, true)
  i18next.on('languageChanged', setFontFamilyByLanguage)
  setFontFamilyByLanguage(i18next.resolvedLanguage)
  setBrowserClass()

  render(
    React.createElement(Provider, {"store": (store)},
      React.createElement(Suspense, {"fallback": (renderLoader())},
        React.createElement(Router, {"history": (history), "onUpdate": (() => window.scrollTo(0, 0))},
          React.createElement(Route, {"path": 'login', "onEnter": (publicPages), "component": (require './pages/Login')}),
          React.createElement(Route, {"path": 'demosession/:shadowToken', "component": (ShadowUser)}),
          React.createElement(Route, {"path": '/', "onEnter": (securedPages)},
            React.createElement(Route, {"path": 'sfc', "onEnter": (securedPages), "component": (require './pages/Sfc')}),
            React.createElement(Route, {"path": 'login/first', "component": (require './pages/License')}),
            React.createElement(IndexRoute, {"component": (Home), "title": ("title.home")}),
            React.createElement(Route, {"path": 'home', "component": (Home), "title": ("title.home")}),
            React.createElement(Route, {"path": 'notes', "component": ((props) -> React.createElement(ActionsAndNotes2, Object.assign({},  props ))), "title": ("title.actions.notes"), "backLabel": ("menu.actions.and.notes")}),
            React.createElement(Route, {"path": 'watches', "component": ((props)-> React.createElement(WatchList2, Object.assign({},  props ))), "title": ("title.watches"), "backLabel": (i18next.t("back"))}),
            React.createElement(Route, {"path": 'search', "component": (require('./pages/Search').default), "title": ("title.search")}),
            React.createElement(Route, {"path": 'charts', "component": (require './pages/ChartGallery'), "title": ("title.charts"), "backLabel": (i18next.t("back"))}),
            React.createElement(Route, {"path": 'results(/:filterId(/:filterValue))', "backLabel": (i18next.t("back")), "component": (require './pages/Results')}),
            React.createElement(Route, {"path": 'report/results(/:filterId)', "component": (require './pages/ReportResults')}),
            React.createElement(Route, {"path": 'twiddler', "component": (require './pages/Twiddler'), "title": ("title.twiddler"), "backLabel": (i18next.t("back"))}),
            React.createElement(Route, {"path": 'admin', "component": (require './pages/Admin'), "title": ("title.admin")}),
            React.createElement(Route, {"path": 'property/:propertyId', "component": (require './pages/Property'), "backLabel": (i18next.t("close"))}),
            React.createElement(Route, {"path": 'company/:companyId', "component": (require './pages/Company'), "backLabel": (i18next.t("close"))}),
            React.createElement(Route, {"path": 'settings', "component": (require './pages/Settings'), "title": ("title.settings"), "backLabel": (i18next.t("back"))}),
            React.createElement(Route, {"path": 'sUseMobileApps', "component": (require './pages/Settings'), "title": ("title.settings"), "backLabel": (i18next.t("back"))})
          ),
          React.createElement(Route, {"path": '*', "component": (require './pages/NotFound')})
        )
      )
    )
    document.querySelector(".wrapper")
  )

renderMainApp()

start = (attempt)->
  API.ping ()=>
    #everything is ok, do nothing
    true
  , ()=>
    if attempt > 3
      render(React.createElement(Provider, {"store": (store)}, React.createElement(Maintenance, null)), document.querySelector(".wrapper"))
    else
      setTimeout ()->
        start(attempt+1)
      , 100

start(0)

