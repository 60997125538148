{ extend } = require '../../helpers/UtilsHelpers'

initialState = {
  mainMenuOpened: true
  closedByUser: false
  sharedSearchesIsOpened: true
  savedSearchesIsOpened: true
  recentlyViewedIsOpened: false
}

module.exports = (state = initialState, action) ->
  if action.type == "TOGGLE_SAVED_SEARCHES_MENU"
    state.savedSearchesIsOpened = !state.savedSearchesIsOpened
  if action.type == "TOGGLE_SHARED_SEARCHES_MENU"
    state.sharedSearchesIsOpened = !state.sharedSearchesIsOpened
  if action.type == "TOGGLE_RECENTLY_VIEWED_MENU"
    state.recentlyViewedIsOpened = !state.recentlyViewedIsOpened
  if action.type == "TOGGLE_MAIN_MENU"
    state.mainMenuOpened = !state.mainMenuOpened
    state.closedByUser = !state.mainMenuOpened && action.byUser
  else if action.type == "HIDE_MAIN_MENU"
    state.mainMenuOpened = false
  else if action.type == "SHOW_MAIN_MENU"
    if !state.closedByUser
      state.mainMenuOpened = true

  extend({}, state)
