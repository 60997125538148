Utils = require '../helpers/Utils'

module.exports = createReactClass(
  displayName: "UseMobileApp"

  render: ->
    React.createElement("div", {"className": "page-login"},
      React.createElement("div", {"className": "container"},
        React.createElement("div", {"className": "vertical-middle"},
          React.createElement("div", {"className": "box-img"},
            React.createElement("img", {"src": (require "../../assets/img/login.jpg"), "alt": ""})
          ),
          React.createElement("div", {"className": "content-vertical"},
            React.createElement("div", {"className": "box-form"},
              React.createElement("div", {"className": "box-logo"},
                React.createElement("img", {"src": (require "../../assets/img/LE_Corporate_Logo_Circle.svg"), "alt": ""})
              ),
              React.createElement("div", {"style": (textAlign:"center", fontSize:"14px", padding:"0 20px 0 20px")}, """
                To access your online database from your mobile device, use our custom """, React.createElement("a", {"href": (if Utils.getUserAgent() == 'ANDROID' then "https://play.google.com/store/apps/details?id=com.lodgingeconometrics.businessdev" else "https://itunes.apple.com/app/le-business-development/id1305931851")}, "mobile app"), """.
""")
            )
          )
        )
      )
    )
)