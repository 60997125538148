const isContentAdmin = (user, isAlias) => {
    return isAlias || hasFeature(user?.client, "AllContentAdmins") || hasUserFeature(user, "ContentAdmin");
}

const hasFeature = (client, feature) => {
    return client?.keywords?.split(",")?.map((x) => x.trim())?.indexOf(feature) >= 0
}

const hasUserFeature = (user, feature) => {
    return user?.keywords?.split(",")?.map((x) => x.trim())?.indexOf(feature) >= 0
}

export {
    isContentAdmin,
    hasFeature,
    hasUserFeature
}
