import { useTranslation } from "react-i18next";

const PrintFooter = () => {
    const { t } = useTranslation();

    return <div className="show-print print-footer">
        <p className="footer-copyright">
            {t('print.footer.copyright')}
        </p>
        <ul>
            <li>{t('print.footer.le')}</li>
            <li>|</li>
            <li>{t('print.footer.address1')}</li>
            <li>|</li>
            <li>{t('print.footer.address2')}</li>
            <li>|</li>
            <li>{t('print.footer.phone')}</li>
            <li>|</li>
            <li>{t('print.footer.email')}</li>
            <li>|</li>
            <li>{t('print.footer.web')}</li>
        </ul>
    </div>;
}

export default PrintFooter;