import { CLabel } from '../CLabel'
import Utils from '../../helpers/Utils.cjsx'
import { Link } from 'react-router'
import { CSSProperties, useState } from 'react'
import { Trans } from 'react-i18next'
import Connect from '../../helpers/Connect'
import CItemNote from '../../cmp/note/CItemNote'
import './role_short.scss'

/**
 * @type {{[key: string]: CSSProperties}}
 */
const styles = {

  item: {
    width: "calc(50% - 5px)", //We want space 10px total between two elements
    lineHeight: "18px",
    display: "flex",
    marginBottom: "3px",
    alignItems: "baseline"
  },

  role: {
    width: "70px",
    minWidth: "70px",
  },

  name: {
    marginLeft: "4px",
    marginRight: "4px",
    width: "calc(100% - 180px)" //NOTE: Ellipsis bug hack
  },

  icon: {
    marginLeft: "4px",
    marginRight: "4px",
    width: 18
  },

  phone: {
    marginLeft: "4px",
    width: "140px",
    textAlign: "left"
  }
}

export default Connect(({ role, company, contact, history, roles, user, hasAccessToAssignableActions }) => {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState(company?.notes || []);

  const id = company && company.id || contact && contact.companyId
  const phone = contact && contact.phone || company && company.phone
  const name = []
  if (contact && contact.fullName && contact.fullName.trim().length > 0) {
    name.push(<span key={"contact.name"} className={Utils.getColorByChanges(history, roles[role].contactId)}>{contact.fullName.trim()}</span>)
  }

  if (company && company.name && company.name.trim().length > 0) {
    name.push(<span key={"company.name"} className={Utils.getColorByChanges(history, roles[role].companyId)}>{company.name.trim()}</span>)
  }

  const onMouseLeave = () => {
    setOpen(false);
  };

  const onMouseEnter = () => {
    setOpen(true);
  }
  const companyHasNotes = Boolean((notes.length > 0));

  const noteForPopup = companyHasNotes ? {
    ...Utils.getNoteForPopup(notes, user),
    company
  } : null;

  const urgentNotesCount = Utils.getUrgentNotesCount(notes);

  const onChanged = (note) => {
    setNotes(notes.map((item) => {
      if (item.id === note.id) {
        return note;
      }
      return item;
    }));
  }

  const onDeleted = (note) => {
    setNotes(notes.filter((item) => {
      return item.id !== note.id;
    }))
  }

  const renderNoteIcon = () => {
    const companyHasNotes = Boolean((notes.length > 0));
    if (!companyHasNotes) {
      return <div style={styles.icon}></div>
    }
    
    if (!hasAccessToAssignableActions) {
      return <Link to={"/company/" + id} style={styles.icon} className={"dark-grey bold"} data-cy-company-id={id}>
        <i className={"fa fa-file-text-o " + Utils.getNoteStatusColor(notes[notes.length - 1]?.dateTo, "green-text")}></i>
      </Link>;
    }

    if (noteForPopup) {
      return <div className="tooltip save" onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} style={styles.icon} onClick={onMouseEnter} onTouchStart={onMouseEnter}>
        {open && <div className="note-popover note-popover-company">
          <div className="block-tile">
            <div className="box-tile selected">
              <div className="tile-action notes-list">
                <div className="tile-group" style={{ paddingBottom: 0 }}>
                  <CItemNote
                    note={noteForPopup}
                    truncated
                    hideMenu
                    onChanged={onChanged}
                    onDeleted={onDeleted}
                    shouldFetchHistory
                  />
                </div>
                <div className="tile-group tile-group-more">
                  <Link to={{
                    pathname: `/company/${id}`,
                    state: {
                      openMore: urgentNotesCount > 1
                    }
                  }}
                    className="btn tooltip save"
                  >
                    {urgentNotesCount > 1 ? <CLabel k="menu.moreDueSoon" /> : <CLabel k="menu.more" />}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>}
        <i className={"fa fa-file-text-o " + Utils.getNextNoteAlertColor(company.notes, user, true)}></i>
      </div>
    }

    return <div style={styles.icon}></div>;
  }


  return <div key={id + role} style={styles.item} className="results-role">
    <CLabel k={"property.contact.type.short." + role} style={styles.role} />
    <Link to={"/company/" + id} style={styles.name} className={"dark-grey bold text-overflow"} data-cy-company-id={id}>
      {name.reduce((result, item) => result.length > 0 ? [...result, ', ', item] : [item], [])}
    </Link>
    {renderNoteIcon()}
    <span style={{ flexGrow: 1 }} />
    {phone && <span className="phone text-overflow" style={styles.phone}>
     <Trans i18nKey="property.contact.phoneNumber" values={{
        phoneNumber: phone
      }}>
        <span>P: </span>
        <a target="_top" href={"tel:" + Utils.formatPhoneNumber(phone)} className="link link-phone">{{ phone }}</a>
      </Trans>
    </span>}
  </div>
}, {
  state: (state) => ({
    user: state.session?.user,
    hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions")
  })
})