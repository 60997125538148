Utils = require '../../helpers/Utils'
RWebSocket = (require '../../helpers/RWebSocket').default;


module.exports = (getStore, getAPI) ->
  socket = new RWebSocket(getStore);

  initialState =
    exportInfo: null

  (state = initialState, action) ->
    if action.type == "SUBSCRIBE_USER"
      socket.connect(action.token)
      state
    else if action.type == "CHECK_WS_CONNECTION"
      socket.checkConnection()
      state
    else if action.type == "DISCONNECT_WS"
      socket.disconnect()
      state
    else
      state