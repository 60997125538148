Connect = require '../../helpers/Connect'
{ CLabel } = require "../CLabel"
Utils = require '../../helpers/Utils'
Moment = require 'moment'
CAdminAnalyticsActionsTable = require './CAdminAnalyticsActionsTable'

module.exports = Connect createReactClass(
  displayName: "CAdminAnalyticsActionsGroupedTable"

  stateDirty: []

  getInitialState: () ->
    {}

  componentDidMount: () ->

  componentWillReceiveProps: (newProps) ->
    {}

  render: ->
    React.createElement("div", {"className": "flex-table"},
      React.createElement("div", {"className": "flex-tr flex-th"},
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.date.time"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.user"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.client"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.platform"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.action"})
        ),
        React.createElement("div", {"className": "flex-td flex-td-15"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.description"})
        )
      ),
      (if @props.groupBySession
        @props.tableData.map (data, sid) ->
          sessionStart = data[data.length - 1]
          sessionEnd = data[0]
          title = """#{Moment(sessionStart.createdAt).tz('UTC').format('YYYY-MM-DD HH:mm')} - \
          #{Moment(sessionEnd.createdAt).tz('UTC').format('HH:mm')}, \
          #{sessionStart.userName}, \ 
          #{sessionStart.clientName}: records - #{data.length}
          """
          React.createElement(CAdminAnalyticsActionsTable, {"title": (title), "key": (sid), "tableData": (data)})
      else
        React.createElement(CAdminAnalyticsActionsTable, {"tableData": (@props.tableData)})
      )
      
    )
), {
  state: (state) ->
    {}
  dispatch: (dispatch) ->
    {}
}