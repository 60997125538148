import { useEffect, useState } from 'react'
import Connect from '../helpers/Connect'
import { API } from '../redux/Store'
import { CLabel } from './CLabel'
import CLoading from './CLoading'

export default Connect(({ data, closePreview }) => {

  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState([])

  useEffect(() => {
    API.getWeeklyDigest(data, (res) => {
      setResults(res)
      setLoading(false)
    })
  }, []);

  useEffect(() => {
    const onClose = (e) => {
      return (e && e.which == 27 && closePreview())
    }
    document.querySelector('body').addEventListener('keyup', onClose)
    document.querySelector('body').addEventListener('orientationchange', closePreview)
    return () => {
      document.querySelector('body').removeEventListener('keyup', onClose)
      document.querySelector('body').removeEventListener('orientationchange', closePreview)
    }
  }, []);

  return (
    <div className="modal modal-digest open">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            {loading && <div className="no-info"><CLoading /></div>}
            {!loading && results.map((x) => x.formData).map((item) => (
              <div key={item['to'][0]} style={{marginBottom: "20px"}}>
                <p><b>To:</b> {item['to'][0]}</p>
                <p><b>Subject:</b> {item['subject'][0]}</p>
                <p><b>Sender:</b> {item['h:sender'][0]}</p>
                <iframe style={{width: "100%", minHeight: "350px"}} sandbox="allow-top-navigation allow-scripts allow-popups-to-escape-sandbox" srcDoc={item['html'][0]} />
              </div>
            ))}
          </div>
          <div className="modal-footer hide-mobile">
            <div className="clearfix">
              <div className="box-btn box-right">
                <a className="btn btn-grey btn-sm close-modal" onClick={closePreview}><span className="upper"><CLabel k="settings.custom.popup.close" /></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
},
  {
    state: (state) => ({
      translation: state.translation
    }),

    dispatch: (dispatch) => ({
      closePreview: () => dispatch({ type: 'TOGGLE_POPUP_DIGEST_VIEW' })
    })
  }
)
