const FEATURE_ENUM = {
    PRINT: 'PRINT',
}

const FEATURE_TABLE = {
    PRINT: {
        name: 'print',
        minVersion: '1.5.1',
    }
}

const isVersionNewerOrEqual = (oldVer, newVer) => {
    const oldParts = oldVer.split('.')
    const newParts = newVer.split('.')

    for (let i = 0; i < newParts.length; i++) {
        const newPart = Number(newParts[i]);
        const oldPart = Number(oldParts[i]);
        if (newPart > oldPart) {
            return true;
        }
        if (newPart < oldPart) {
            return false;
        }
        if (i === newParts.length - 1 && newPart === oldPart) {
            return true;
        }
    }

    return false;
}

const checkIfFeatureIsAvailable = (featureName) => {
    const feature = FEATURE_TABLE[featureName];
    const mobileDeviceNames = ["ANDROID", "IOS"];

    const version = window.deviceNativeVersion;
    
    if (!mobileDeviceNames.includes(window.deviceName)) {
        return true;
    }

    if (!feature || !version) {
        return false;
    }

    return isVersionNewerOrEqual(feature.minVersion, version);;
}

export { checkIfFeatureIsAvailable, FEATURE_ENUM, FEATURE_TABLE }