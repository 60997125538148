Connect = require '../../../helpers/Connect'
{ CLabel } = require '../../CLabel'
Utils = require '../../../helpers/Utils'
{ API } = require '../../../redux/Store'
DatePicker = require 'rc-calendar/lib/Picker'
Moment = require 'moment'
React = require 'react'
CMultipleDropdown = require '../CMultipleDropdown'
{ mapSelectedFieldsToChangeTypes, getSelectedFields, optionsFields, filterOptionFields, validateSelectedChanges, getError, disabledDate } = require './CGeneralChangesHelpers'
{ withTranslation, Trans } = require 'react-i18next' 


module.exports = withTranslation() Connect createReactClass(

  displayName: "CGeneralChangesFilterElement"

  getInitialState: ()->
    values:
      isToggleable: true,
      enabled: @props.value?.enabled || false
      mode: @props.value?.mode || 'DAYS'
      sinceDate: @props.value?.sinceDate
      daysAgo: @props.value?.daysAgo || 7,
      values: @props.value?.values || null,
      invalid: false

  componentWillReceiveProps: (newProps)->
    if (!newProps.value)
      initialValues = {
        isToggleable: true,
        enabled: false,
        mode: 'DAYS',
        daysAgo: 7,
        values: null,
        invalid: false
      }
      @setState values: initialValues
    else
      @setState values: newProps.value
      

  optionsFields: ()->
    optionsFields.filter(filterOptionFields(@props.client)).map((field) =>
      if field.translationKey
        field.label = @props.t(field.translationKey);
      return field
    )

  handleCheckbox: (e)->
    @state.values[e.target.name] = e.target.checked
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  handleText: (e)->
    @state.values[e.target.name] = e.target.value
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  handleRadio: (e)->
    if e.target.checked
      @state.values[e.target.name] = e.target.value
      @state.values.invalid = Boolean(getError(@state.values))
      @setState(@state, @handleChange)

  setMode: (value) -> () =>
    @state.values?.mode = value
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  putDate: (moment) ->
    @state.values.sinceDate = moment?.startOf('day').format()
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  handleChange: ()->
    if !@state.values.invalid
      @props.onChange?(@props.name, @state.values, false)

  datePlaceholder: (value) ->
    Utils.timelineDatePlaceholder(value.value)

  onChange: (name, value)->
    prevValue = getSelectedFields(@state.values.values)?.split(';');
    @state.values[name] = mapSelectedFieldsToChangeTypes(validateSelectedChanges(prevValue, value))
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  componentWillUnmount: () ->
    if @state.values.invalid
      @props.onChange?(@props.name, undefined)

  render: ()->
    error = getError(@state.values)
    priorDateCalendar = Utils.createCalendar(@state.values?.sinceDate && Moment(@state.values?.sinceDate), @props.t("timeline.export.date"), disabledDate)
    React.createElement(React.Fragment, null,
      React.createElement("div", {"className": "name-block small", "style": (marginTop: "20px")}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.general.changes.title"}))),
      React.createElement("div", {"className": "box-control"},
        React.createElement("div", {"className": "clearfix", "style": (display: "flex", justifyContent: "space-around")},
          React.createElement("div", {"className": "box-checkbox middle", "style": (flexGrow: "1")},
            React.createElement("input", {"type": "checkbox", "id": "check_general_changes", "name": "enabled", "checked": (@state.values.enabled), "onChange": (@handleCheckbox)}), React.createElement("label", {"htmlFor": "check_general_changes"}, React.createElement(CLabel, {"k": "search.general.changes.changed.since"}))
          ),
          React.createElement("div", {"className": "box-control middle box-checkbox #{if !@state.values?.enabled then 'disabled unselectable' else ''}"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("input", {"type": "radio", "id": "modeDate", "name": "mode", "checked": (@state.values?.mode == 'DATE'), "value": "DATE", "onChange": (@handleRadio)}),
              React.createElement("label", {"htmlFor": "modeDate"},
                React.createElement(DatePicker, { \
                  "animation": "slide-up",  \
                  "calendar": (priorDateCalendar),  \
                  "name": 'sinceDate',  \
                  "value": (@state.values?.sinceDate && Moment(@state.values?.sinceDate).tz('UTC')),  \
                  "onChange": (@putDate),  \
                  "onOpenChange": (@setMode('DATE'))
                },
                  (@datePlaceholder)
                )
              )
            ),
            React.createElement("div", {"className": "box-control"},
              React.createElement("input", {"type": "radio", "id": "modeDays", "name": "mode", "checked": (@state.values?.mode == 'DAYS'), "value": "DAYS", "onChange": (@handleRadio)}),
              React.createElement("label", {"className": "label-before-middle", "htmlFor": "modeDays"}, """ 
""", React.createElement(Trans, {"i18nKey": ("search.general.changes.days.ago")},
                  React.createElement("input", { \
                    "onBlur": (@props.onBlur),  \
                    "name": "daysAgo",  \
                    "maxLength": "2",  \
                    "min": "1",  \
                    "max": "700",  \
                    "value": (@state.values?.daysAgo),  \
                    "onClick": (@setMode('DAYS')),  \
                    "onChange": (@handleText), "type": "number", "className": "form-control micro"
                  })
                )
              )
            )
          )
        )
      ),
      React.createElement(CMultipleDropdown, { \
        "className": "general-changes-dropdown ml-2 mt-2 #{if !@state.values?.enabled then 'disabled unselectable' else ''}",  \
        "onChange": (@onChange),  \
        "name": "values",  \
        "values": (getSelectedFields(@state.values?.values)),  \
        "options": (@optionsFields()),  \
        "placeholder": (@props.t("search.general.changes.type"))
      }),
      (error && React.createElement("div", {"className": "general-changes-error"},
        React.createElement(CLabel, {"k": (error.translationKey), "param": (error.params)})
      ))
    )
), {
  state: (state) ->
    client: state.session?.user?.client
    value: state.searchForm?.form?.propertyChanges?.value
}