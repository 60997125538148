Utils = require '../../helpers/Utils'
Const = require '../../helpers/Const'
{ extend } = require '../../helpers/UtilsHelpers'

LENAV_SEARCH_FORM = "LENAV_SEARCH_FORM"
LENAV_PREVIOUS_SEARCH_FORM = "LENAV_PREVIOUS_SEARCH_FORM"
LENAV_SAVED_SORTING = "LENAV_SAVED_SORTING"
DEFAULT_TAB = "PROPERTIES"
DEFAULT_VIEW = "LIST"

Moment = require "moment"
savedSorting = {}

{ debounce } = require "throttle-debounce"

headingDependencies = [
  ["worldRegion1", "worldRegion2", "country", "stateProvince", "market", "tract"],
  ["stateProvince", "city"]
]

clone = (o) ->
  r = extend(true, { version: Math.random() }, o)
  r.form?.version = Math.random()
  r

window.xhrForSearch = null
window.xhrForCount = null

module.exports = (getStore, getAPI, Analytics) ->

  startLoading = () ->
    setTimeout () ->
      getStore().dispatch(type: 'START_LOADING')
      getStore().dispatch type: "SET_COUNT_LOADING", loading: true
    , 0

  stopLoading = () ->
    setTimeout () ->
      getStore().dispatch(type: 'STOP_LOADING')
      getStore().dispatch type: "SET_COUNT_LOADING", loading: false
    , 0

  addSorting = (form, oldName, newName, number) ->
    searchFieldsInfo = getStore().getState().app.searchFieldsInfo || []
    if getStore().getState().searchForm.form.searchType == Const.SEARCH_TYPE_COMPANIES
      searchFieldsInfo = getStore().getState().app.searchCompanyFieldsInfo || []

    if oldName && form[oldName]
      if form[oldName].value in [null, undefined, ""]
        delete form[oldName]
      else
        delete form[oldName].isHeading
        delete form[oldName].orderDirection
        delete form[oldName].orderType
        delete form[oldName].sortingNumber

    if newName
      val = form[newName]?.value
      if val not in [null, undefined, ""]
        form[newName] =
          value: val

      if searchFieldsInfo?[newName]?.defaultSorting
        if !form[newName]
          form[newName] = {}
          form[newName]?.orderType = searchFieldsInfo?[newName]?.defaultSorting

      form[newName] = extend form[newName],
        sortingNumber: number
        isHeading: searchFieldsInfo?[newName]?.isGroupedByDefault

  setDefaultSorting = (state) ->
    searchFieldsInfo = getStore().getState().app.searchFieldsInfo || []
    if state.form.searchType == Const.SEARCH_TYPE_COMPANIES
      searchFieldsInfo = getStore().getState().app.searchCompanyFieldsInfo || []

    Object.keys(searchFieldsInfo).forEach (name) ->
      field = searchFieldsInfo[name] 
      if field.isSortedByDefault > -1 && field.sortable
        if !state.form[name]
          state.form[name] = { isHeading: field.isGroupedByDefault }
        if field.defaultSorting
          state.form[name].orderType = field.defaultSorting
        if field.defaultOrderDirection
          state.form[name].orderDirection = field.defaultOrderDirection
        state.form[name].sortingNumber = field.isSortedByDefault

  applyHeadingRules = (state) ->
    lastHeadingField = { position: -1 }
    Object.keys(state.form).forEach (name) ->
      field = state.form[name]
      if field && field.isHeading && field.sortingNumber > -1 && lastHeadingField.position < field.sortingNumber
        lastHeadingField = position: field.sortingNumber, name: name

    Object.keys(state.form).forEach (name) ->
      field = state.form[name]
      if field && field.sortingNumber <= lastHeadingField.position
        rules = headingDependencies.filter (r) -> name in r

        rules.forEach (rule, i) ->
          lastName = null
          rule.forEach (rname, j) ->
            if state.form[rname]?.sortingNumber > -1 && state.form[rname].sortingNumber <= lastHeadingField.position
              lastName = rname
          state.form[lastName].isHeading = true

  clearSorting = (state) ->
    Object.keys(state.form).forEach (name, field) ->
      field = state.form[name]
      if field == undefined
        return
      delete state.form[name].isHeading
      delete state.form[name].orderDirection
      delete state.form[name].orderType
      delete state.form[name].sortingNumber
      if typeof(state.form[name]) == "object" && Utils.isEmptyObject(state.form[name])
        delete state.form[name]

  getInitialState = (state, searchType = state?.form?.searchType || Const.SEARCH_TYPE_PROPERTIES) ->
    extend {}, {
      form:
        _createdAt: new Date().getTime()
        includeUnbranded: true
        excludedProperties: []
        searchType: searchType
        activeView: DEFAULT_VIEW

      info: {}
      result: {}
      mapResult: {}
      previousForm: null
      tab: DEFAULT_TAB
      isSubSearch: false
    }

  addSearchValue = (form, name, value, options, formatFn) ->
    if value == undefined
      if form[name]
        if Utils.keyLength(form[name]) == 1
          delete form[name]
        else
          delete form[name].value
          delete form[name].options
    else
      if !form[name]
        form[name] =
          value: value
      else
        form[name].value = value

      if options
        form[name].options = options

      if formatFn
        form[name].formatFn = formatFn

  count = (state)->
    invalid = Object.keys(state.form).find((key) =>
      if (state.form[key]?.value?.invalid )
        return true;
      return false;
    );
    if (invalid)
      state.info.count = 0
      state.info.roomsCount = 0
      return
    state.info.isCountLoading = true;
    countDebounce(state)

  countDebounce = debounce 250, (state) ->
    delete state.info.count
    if Utils.keyLength(state.form) > 0
      if window.xhrForCount
        window.xhrForCount.abort()
      window.xhrForCount = getAPI().count filterHiddenFields(state.form), (result) ->
        getStore().dispatch type: "SET_COUNT_TO_SEARCH_FORM", count: result.count, roomsCount: result.roomsCount
        getStore().dispatch type: "SET_COUNT_LOADING", loading: false
      , null, () ->
        window.xhrForCount = null

  filterHiddenFields = (form) ->
    result = {}
    Object.keys(form)?.forEach (key) ->
      if form.hasOwnProperty(key)
        if form[key]?.hidden
          result[key] = sortingNumber: form[key].sortingNumber, isHeading: form[key].isHeading
        else
          result[key] = form[key]
    result

  searchMap = (state) ->
    if Utils.keyLength(state.form || {}) > 0
      startLoading()
      if window.xhrForSearchMap
        window.xhrForSearchMap.abort()

      form = clone(state.form)
      window.xhrForSearchMap = getAPI().mapSearch filterHiddenFields(form), (result) ->
        getStore().dispatch(type: 'SET_MAP_RESULT', result: result)
      , (error, status) ->
        if (status != "abort")
          console.warn(arguments)
          getStore().dispatch(type: 'SET_MAP_RESULT', result: { totalCount: 0, error: error, status: status })
      , () ->
        window.xhrForSearchMap = null

    else
      getStore().dispatch(type: 'SET_MAP_RESULT', result: [])

  search = (state, page, allResults, flush, callback) ->
    if state.form.activeView == 'MAP'
      searchMap(state)


    invalid = Object.keys(state.form).find((key) =>
      if (state.form[key]?.value?.invalid )
        return true;
      return false;
    );

    if (invalid)
      state.info.count = 0
      state.info.roomsCount = 0;
      state.result.results = []
      state.info.page = 0
      state.result.version = Math.random()
      return

    if Utils.keyLength(state.form || {}) > 0
      startLoading()
      if window.xhrForSearch
        window.xhrForSearch.abort()

      if allResults
        length = 100
      else
        length = Const.RESULTS_PER_PAGE

      form = clone(state.form)
      if page == 0 
        if state.form && state.form.searchType == Const.SEARCH_TYPE_PROPERTIES or !state.form.searchType
          Analytics().search.propertySearch()
        else
          Analytics().search.companySearch()

      window.xhrForSearch = getAPI().search filterHiddenFields(form), page * length || 0, length, (result) ->
        getStore().dispatch(type: 'SET_RESULT', result: result, page: page, flush: flush)
        if (callback)
          callback(result)
      , (error, status) ->
        if (status != "abort")
          console.warn(arguments)
          getStore().dispatch(type: 'SET_RESULT', result: { totalCount: 0, error: error }, page: page)
      , () ->
        window.xhrForSearch = null
    else
      getStore().dispatch(type: 'SET_RESULT', result: [], page: page)

  save = (state, isOriginal, isInitial) ->
    state.form.pristine = !!isInitial

    if !state.form.filterId
      savedState = extend {}, state
      savedState.info = {}
      savedState.result = {}
      savedState.mapResult = {}
      delete state.form.weeklyDigest
      delete state.form.weeklyDigestShared
      sessionStorage.setItem(LENAV_SEARCH_FORM, JSON.stringify(savedState))
    else if !isOriginal
      state.form.changed = true
      state.form.useSavedSearch = false

      if state.form.isLink
        delete state.form.isLink
        delete state.form.linkId
        delete state.form.filterId

      if state.form.isShared && state.form.createdById != getStore().getState().session?.user?.id
        delete state.form.name
        delete state.form.createdById
        delete state.form.createdAt
        delete state.form.useSavedSearch
        delete state.form.isShared
        delete state.form.weeklyDigest
        delete state.form.weeklyDigestShared

  savePreviousSearch = (state, version) ->
    if version
      state.version = version
    if state.version
      savedState = extend {}, state
      savedState.info = {}
      savedState.result = {}
      savedState.mapResult = {}
      sessionStorage.setItem(savedState?.form?.storageKey || LENAV_PREVIOUS_SEARCH_FORM, JSON.stringify(savedState))

  setConstraints = (state) ->
    f = state.form
    f.projectStage?.hidden = !(f.showConversions || f.showRenovations || f.showNewConstruction)
    f.startDate?.hidden = !(f.showConversions || f.showRenovations || f.showNewConstruction)
    f.openingDate?.hidden = !(f.showConversions || f.showRenovations || f.showNewConstruction)
    f.saleDate?.hidden = !f.showSales
    f.saleAmount?.hidden = !f.showSales
    f.salePerRoom?.hidden = !f.showSales
    f.assetStatus?.hidden = !f.showDistressedAssets
    f.newFranchiseName?.hidden = !f.showConversions
    f.newFranchiseCompany?.hidden = !f.showConversions
    f.newChainScale?.hidden = !f.showConversions

    client = getStore().getState().session?.user?.client
    hasAccessToConversionInitial = Utils.hasFeature(client, "ConversionInitial")

    if f.projectStage
      if ((f.showConversions || f.showRenovations) && f.showNewConstruction) || (!f.showConversions && !f.showRenovations && !f.showNewConstruction)
        f.projectStage.value = f.projectStage.value?.replace(/'Under Construction'/, "'Underway / Under Construction'")?.replace(/'Underway'/, "'Underway / Under Construction'")
        if hasAccessToConversionInitial
          f.projectStage.value = f.projectStage.value?.replace(/'Recently Completed'/, "'Recently Opened / Completed'")?.replace(/'Recently Opened'/, "'Recently Opened / Completed'")
        f.projectStage.value = f.projectStage.value?.replace(/'Start Next 12 Months'/, "'Start Next 6 or 12 Months'")?.replace(/'Start Next 6 Months'/, "'Start Next 6 or 12 Months'")
      else if f.showConversions || f.showRenovations
        f.projectStage.value = f.projectStage.value?.replace(/'Underway \/ Under Construction'/, "'Underway'")?.replace(/'Under Construction'/, "'Underway'")
        if hasAccessToConversionInitial
          f.projectStage.value = f.projectStage.value?.replace(/'Recently Opened \/ Completed'/, "'Recently Completed'")?.replace(/'Recently Opened'/, "'Recently Completed'")
        else
          f.projectStage.value = f.projectStage.value?.replace(/'Recently Opened'/, "")
        f.projectStage.value = f.projectStage.value?.replace(/'Start Next 12 Months'/, "'Start Next 6 Months'")?.replace(/'Start Next 6 or 12 Months'/, "'Start Next 6 Months'")
      else if f.showNewConstruction
        f.projectStage.value = f.projectStage.value?.replace(/'Underway \/ Under Construction'/, "'Under Construction'")?.replace(/'Underway'/, "'Under Construction'")
        if hasAccessToConversionInitial
          f.projectStage.value = f.projectStage.value?.replace(/'Recently Opened \/ Completed'/, "'Recently Opened'")?.replace(/'Recently Completed'/, "'Recently Opened'")
        f.projectStage.value = f.projectStage.value?.replace(/'Start Next 6 or 12 Months'/, "'Start Next 12 Months'")?.replace(/'Start Next 6 Months'/, "'Start Next 12 Months'")
      f.projectStage.value = f.projectStage.value?.split(';').filter((v) -> v != "").join(';')

  load = () ->
    if sessionStorage.getItem(LENAV_SEARCH_FORM)
      JSON.parse(sessionStorage.getItem(LENAV_SEARCH_FORM))
    else
      getInitialState()

  saveSorting = (sorting, searchType) ->
    savedSorting[searchType] = sorting;

  restoreSorting = (state, searchType) ->
    if savedSorting[searchType]? && !Utils.isEmptyObject(savedSorting[searchType])
      Object.keys(savedSorting[searchType]).forEach (name) ->
        field = savedSorting[searchType][name]
        state.form[name] = field
    else
      setDefaultSorting(state)

  getSorting = (state) ->
    result = {}
    Object.keys(state.form).forEach (name) ->
      field = state.form[name]
      if field?.sortingNumber
        result[name] = field;
    return deepObjectClone(result);

  deepObjectClone = (obj) ->
    if not obj? or typeof obj isnt 'object'
      return obj
    if obj instanceof Date
      return new Date(obj.getTime())
    newInstance = new obj.constructor()
    for key of obj
      newInstance[key] = deepObjectClone obj[key]
    return newInstance

  (state = load(), action) ->
    #console.log(action)
    if action.type == "SET_FILTER_FORM"
      state = getInitialState()
      state.form = extend state.form, action.form
      save(state, true)
      if (action.getCount)
        count(state)
      clone(state)

    if action.type == "UPDATE_FILTER_FORM"
      state.form = extend state.form, action.form
      save(state, true)
      clone(state)

    else if action.type == "SET_CHANGED"
      state.form.changed = !!action.changed
      clone(state)

    else if action.type == "ADD_SORTING_TO_SEARCH_FORM"
      addSorting(state.form, action.oldName, action.newName, action.number)
      applyHeadingRules(state)
      save(state)
      clone(state)

    else if action.type == "UPDATE_SORTING_TO_SEARCH_FORM"
      if !state.form[action.name]
        state.form[action.name] = action.info
      else
        if action.info.isHeading
          state.form[action.name].isHeading = action.info.isHeading
        if action.info.orderDirection
          state.form[action.name].orderDirection = action.info.orderDirection
        if action.info.orderType
          state.form[action.name].orderType = action.info.orderType
        if action.info.sortingNumber > -1
          state.form[action.name].sortingNumber = action.info.sortingNumber

      applyHeadingRules(state)
      save(state)
      clone(state)

    else if action.type == "CLEAR_SORTING"
      state = clone(state)
      clearSorting(state)
      save(state)
      state

    else if action.type == "DELETE_FILTER_ID"
      delete state.form.filterId
      delete state.form.name
      save(state)
      clone(state)

    else if action.type == "RESET_SORTING"
      clearSorting(state)
      setDefaultSorting(state)
      save(state)
      clone(state)

    else if action.type == "ADD_VALUE_TO_SEARCH_FORM"
      addSearchValue(state.form, action.name, action.value, action.options, action.formatFn)
      applyHeadingRules(state)
      if !action.noCountChange
        count(state)
      save(state)
      clone(state)

    else if action.type == "ADD_VALUE_TO_SEARCH_FORM_AND_SEARCH"
      addSearchValue(state.form, action.name, action.value, action.options)
      applyHeadingRules(state)
      save(state)
      search(state, 0)
      clone(state)

    else if action.type == "SET_DELTA_SINCE"
      delete state.form.deltaSearch
      state.form.deltaSearch = 
        value: action.value
        mode: "NEW"
      applyHeadingRules(state)
      count(state)
      save(state, true)
      search(state, 0)
      clone(state)

    else if action.type == "SET_UPDATED_SINCE"
      delete state.form.deltaSearch
      state.form.deltaSearch = 
        value: action.value
        mode: "UPDATED"
      applyHeadingRules(state)
      count(state)
      save(state, true)
      search(state, 0)
      clone(state)

    else if action.type == "REMOVE_DELTA_FILTERS"
      delete state.form.deltaSearch
      state.form.deltaSearch = 
        value: action.value
        mode: "CURRENT"
      applyHeadingRules(state)
      count(state)
      save(state, true)
      search(state, 0)
      clone(state)

    else if action.type == "TOGGLE_BLOCK_ON_SEARCH_FORM"
      if action.setExplicitly
        if action.value
          state.form[action.name] = action.value
        else
          if state.form[action.name]
            delete state.form[action.name]
      else
        if state.form[action.name]
          delete state.form[action.name]
        else
          state.form[action.name] = true

      console.log("TOGGLE")
      setConstraints(state)
      save(state)
      if !action.withoutSearch
        count(state)

      if action.withoutSearch
        state
      else
        clone(state)

    else if action.type == "SET_BLOCKS_ON_SEARCH_FORM"
      action.blocks.forEach (b, i) ->
        if b.value != undefined
          state.form[b.name] = b.value
        else
          delete state.form[b.name]
        1

      setConstraints(state)
      save(state)
      count(state)
      clone(state)

    else if action.type == "SET_COUNT_TO_SEARCH_FORM"
      state.info.count = action.count
      state.info.roomsCount = action.roomsCount
      clone(state)

    else if action.type == "SET_COUNT_LOADING"
      state.info.isCountLoading = action.loading
      clone(state)

    else if action.type == "SET_NAME_TO_SEARCH_FORM"
      state.form.name = action.name
      state.form.isShared = action.isShared
      state.form.weeklyDigest = action.weeklyDigest
      state.form.weeklyDigestShared = action.weeklyDigestShared
      clone(state)

    else if action.type == "INIT_SEARCH_FORM"
      if !action.withoutPreviousSearchSave
        savePreviousSearch(state, action.version)
      state = getInitialState(state, action.searchType)
      console.log(action)
      if (action.fullReload)
        state.form.fullReload = true
      if action.storageKey
        state.form.storageKey = action.storageKey
      if (action.defaultValues)
        Object.keys(action.defaultValues).forEach (key) ->
          state.form[key] = action.defaultValues[key]
      if action.isSubSearch
        state.isSubSearch = true
      if action.previousForm
        state.previousForm = action.previousForm

      setDefaultSorting(state)

      save(state, false, true)
      console.log(state)
      if !action.withoutSearch
        count(state)
      if action.withoutSearch
        state
      else
        clone(state)

    else if action.type == "DELETE_NOTE_IN_SEARCH_RESULT"
      noteId = action.noteId
      id = action.id
      results = state.result.results.map((result) => 
        if (result.id == id)
          return {
            ...result,
            notes: result.notes.filter((item) =>
              if (item.id == noteId)
                return false
              return true
            )
          }
        return result
      )
      state.result.results = results
      clone(state)

    else if action.type == "EDIT_NOTE_IN_SEARCH_RESULT"
      note = action.note
      id = action.id
      results = state.result.results.map((result) => 
        if (result.id == id)
          return {
            ...result,
            notes: result.notes.map((item) =>
              if (item.id == note.id)
                return {
                  ...note
                }
              return item
            )
          }
        return result
      )
      state.result.results = results
      clone(state)

    else if action.type == "SET_RESULT"
      if action.result.error
        stopLoading()
        return state
      state.info.count = action.result.totalCount
      state.info.roomsCount = action.result.roomsCount;
      if state.result.results && action.page > 0 && !action.flush
        action.result.results = state.result.results.concat(action.result.results)
      if state.result.results && action.page > 0 && action.flush && state.result.limit && state.info.page
        action.result.results = state.result.results.slice(0, (state.info.page - 1) * state.result.limit).concat(action.result.results)
      #console.log(action.result)
      if action.result.filterForm?.filterId in ["pipeline", "census", "updated"]
        action.result.filterForm.searchType = state.form.searchType
        state.form = action.result.filterForm
      
      if !state.form.linkId && action.result.filterForm?.linkId
        state.form.linkId = action.result.filterForm.linkId

      state.result = action.result
      state.info.page = action.page + 1
      state.result.version = Math.random()
      stopLoading()
      clone(state)

    else if action.type == "SET_MAP_RESULT"
      state.mapResult = extend(state.mapResult, action.result)
      state.mapResult.version = Math.random()
      if !state.form.linkId && action.result.filterForm?.linkId
        state.form.linkId = action.result.filterForm.linkId

      stopLoading()
      clone(state)

    else if action.type == "SET_MAP_OPTIONS"
      state.mapResult.options = action.options
      state.mapResult.options.version = Math.random()
      state.form.propertyMapUseClustering = action.options.propertiesLegend.useClustering
      state.form.propertyMapShowLabels = action.options.propertiesLegend.showLabels
      state.form.activeColorPin = action.options.propertiesLegend.activeColorPin.value
      state.form.companyMapUseClustering = action.options.companiesLegend.useClustering
      state.form.companyMapShowLabels = action.options.companiesLegend.showLabels
      state.form.changed = true

      if state.form.isLink
        delete state.form.isLink
        delete state.form.linkId
        delete state.form.filterId
        searchMap(state)

      save(state)
      clone(state)

    else if action.type == "CLEAR_RESULTS"
      state.result = {}
      state.mapResult = {}
      clone(state)

    else if action.type == "SEARCH"
      search(state, action.page, false, action.flush, action.callback)
      state

    else if action.type == "SEARCH_MAP"
      state.form.locationMin = action.locationMin
      state.form.locationMax = action.locationMax
      searchMap(state)
      count(state)
      state

    else if action.type == "SEARCH_ALL"
      search(state, action.page, true)
      state

    else if action.type == "NEW_SEARCH"
      state.result = {}
      search(state, 0)
      clone(state)

    else if action.type == "EXCLUDE_PROPERTY"
      state.result.results = state.result.results.filter (p) -> p.id != action.option.id
      if (!state.excludedPropertiesCache)
        state.excludedPropertiesCache = {}
      if !state.form.excludedProperties
        state.form.excludedProperties = []
      if (state.form.excludedProperties.filter (opt) -> opt.id == action.option.id).length == 0
        state.excludedPropertiesCache[action.option.id] = action.option.name
        state.form.excludedProperties.push action.option
      if state.form.isLink
        delete state.form.isLink
        delete state.form.linkId
        delete state.form.filterId
        search(state, 0)
      save(state)
      count(state)
      if(state.result.results.length <= 10 && state.result.totalCount > 20)
        search(state, 0)
      state

    else if action.type == "SET_EXCLUDED_PROPERTIES"
      if (!state.excludedPropertiesCache)
        state.excludedPropertiesCache = {}
      state.form.excludedProperties = action.ids.map (id) -> id: id, name: state.excludedPropertiesCache[id]
      save(state)
      search(state, 0)
      state

    else if action.type == "REMOVE_ALL_EXCLUDED_PROPERTIES"
      state.form.excludedProperties = undefined
      save(state)
      search(state, 0)
      state

    else if action.type == "TOGGLE_WATCHING_PROPERTY"
      if state.result?.results
        (state.result.results.filter (opt) -> opt.id == action.id)[0]?.watching = action.watching
      clone(state)

    else if action.type == "SET_VIEW"
      state.form.activeView = action.view
      state.form.changed = true
      save(state)
      clone state

    else if action.type == "SET_SEARCH_TYPE"
      currentSearchType = state.form.searchType
      state.form.searchType = action.searchType
      state.result.results = []
      state.result.mapResult = []
      save(state, null, state.form.pristine)
      count(state)
      currentSorting = getSorting(state)
      clearSorting(state)
      restoreSorting(state, action.searchType)
      saveSorting(currentSorting, currentSearchType)
      save(state, null, state.form.pristine)
      if action.needSearch
        search(state, 0)
      if action.needMapSearch
        searchMap(state)
      clone state

    else if action.type == "SET_STORAGE_KEY"
      state.form.storageKey = action.storageKey
      save(state)
      clone state

    else if action.type == "RESTORE_PREVIOUS_SEARCH"
      state = extend {}, action.previousSearchForm
      save(state)
      search(state, 0)
      clone state

    else if action.type == "SET_ACTIVE_COMPANY"
      state.activeCompany = action.activeCompany
      clone state

    else if action.type == "SET_SEARCH_FORM_COLUMNS"
      state.searchFormColumns = extend state.searchFormColumns, action.searchFormColumns
      clone state

    else if action.type == "SET_LAST_SCROLL_POSITION"
      state.lastScrollPosition = action.payload
      clone state

    else if action.type == "SAVE_PREVIOUS_FORM"
      state.previousForm = action.payload
      clone state

    else if action.type == "SET_SUBSEARCH"
      state.isSubSearch = action.isSubSearch
      clone state

    else
      state
