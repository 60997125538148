Connect = require '../helpers/Connect'
{ CLabel } = require './CLabel'
Moment = require 'moment'
{ withTranslation } = require 'react-i18next' 
{ translateCategoricalValue } = require '../cmp/filters/CTranslateUtils'
{ getDateLocale } = require "../helpers/LocaleHelpers";

module.exports = withTranslation() Connect createReactClass(
  displayName: 'CTimeline'

  render: ->
    locale = getDateLocale(@props.i18n.resolvedLanguage)
    React.createElement("table", null,
      React.createElement("thead", null,
        React.createElement("tr", {"className": "items-info-hotel", "style": (marginBottom: "10px")},
          React.createElement("th", {"className": "hide-mobile"}),
          React.createElement("th", null, React.createElement(CLabel, {"k": "timeline.th.asof"})),
          React.createElement("th", null, React.createElement(CLabel, {"k": "timeline.th.stage"})),
          React.createElement("th", null, React.createElement(CLabel, {"k": "timeline.th.start"})),
          React.createElement("th", null, React.createElement(CLabel, {"k": "timeline.th.open"}))
        )
      ),
      React.createElement("tbody", null,
        (@props.timeline.map (timeline, i) =>
          React.createElement("tr", {"key": (i)},
            React.createElement("td", {"className": "hide-mobile"}, (if i == 0 then '(' + @props.t("timeline.baseline") + ')')),
            React.createElement("td", {"className": "hide-mobile"}, (Moment(timeline.asOf).tz('UTC').locale(locale).format("ll"))),
            React.createElement("td", {"className": "show-mobile"}, (Moment(timeline.asOf).tz('UTC').locale(locale).format("L"))),
            React.createElement("td", {"className": "show-mobile", "style": ({ whiteSpace: "normal" })}, (translateCategoricalValue(@props.t, 'projectStage', timeline.projectStage))),
            React.createElement("td", {"className": "hide-mobile"}, (translateCategoricalValue(@props.t, 'projectStage', timeline.projectStage))),
            React.createElement("td", null, (timeline.startedAt && Moment(timeline.startedAt).tz('UTC').locale(locale).format("L"))),
            React.createElement("td", null, (timeline.openAt && Moment(timeline.openAt).tz('UTC').locale(getDateLocale(locale)).format("L")))
          )
        )
      )
    )
)
, {
  state: (state) ->
   translation: state.translation
}