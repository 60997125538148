import React from "react";
import WatchListItem from "./WatchListItem2";
import { CLabel } from "../cmp/CLabel";
import {
  getTimeRange
} from "./WatchList.constants";

const WatchListItems = ({
  watchList,
  loadData,
}) => {

  const renderHeader = (item, i) => {
    if (i === 0) {
      return <div className="name-block block-tile small grey" key={"label-" + item.property.id}>
        <span className="upper">
          <CLabel k={getTimeRange(item.interestingUpdatedAt)?.label} />
        </span>
      </div>
    }
    if (getTimeRange(item.interestingUpdatedAt).value > getTimeRange(watchList[i - 1].interestingUpdatedAt).value) {
      return <div className="name-block block-tile small grey" key={"property-" + item.property.id}>
        <span className="upper">
          <CLabel k={getTimeRange(item.interestingUpdatedAt).label} />
        </span>
      </div>;
    }
    return null;
  }

  if (watchList === null) {
    return null;
  }

  return (
    <div className="group-tiles">
      <div className="wl-list">
        {watchList.map((item, i) => {
          return <React.Fragment key={item.watch?.propertyId}>
            {renderHeader(item, i)}
            <WatchListItem
              loadData={loadData}
              item={item}
            />
          </React.Fragment>
        })}
      </div>
    </div>
  );
};

export default WatchListItems;
