Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'
CFilterElement = require './CFilterElement'
Utils = require '../../helpers/Utils'
{ API } = require '../../redux/Store'
CInlineDropdown = require('./CInlineDropdown').default
{ withTranslation, Trans } = require 'react-i18next' 


module.exports = withTranslation() Connect createReactClass(
  displayName: "CHasWatchListFilterElement"

  getInitialState: ()->
    isToggleable: true
    createdBy: @props.value?.createdBy || [@props.user?.id]
    enabled: @props.value?.enabled || false
    users: null

  componentDidMount: ()->
    API.getWatchUsers((data) =>
      users = data
        .filter((it) => it.value != @props.user?.id)
        .map((it) => ({
          title: it.label,
          value: it.value,
        }));
      @setState(users: users)
    )

  componentWillReceiveProps: (newProps) -> 
    state = @state
    if(newProps.value?.createdBy)
      if (newProps.value?.createdBy != @props.value?.createdBy)
        state.createdBy = newProps.value?.createdBy
    else state.createdBy = [@props.user?.id]

    if(newProps.value?.enabled != undefined)
      if (newProps.value?.enabled != @props.value?.enabled)
        state.enabled = newProps.value?.enabled
    else state.enabled = false

    @setState(state)
  
  isByMe: ()->
    @state.createdBy && @state.createdBy[0] == @props.user?.id

  getSelectedUserName: ()->
    if (@state.createdBy)
      selectedUser = @state.users?.find((item) => item.value == @state.createdBy[0]);
      if (selectedUser)
        return Utils.formatBelonging(selectedUser.title, @props.i18n.resolvedLanguage);
    return Utils.formatBelonging("other", @props.i18n.resolvedLanguage)


  includeWatchList: (e)->
    if(!@props.isFastFilter)
      @setState(enabled: !@state.enabled, @handleChange)

  selectAuthor: (e)->
    e.preventDefault()
    e.stopPropagation()
    createdBy = if !@isByMe() then [@props.user?.id] else null
    @setState(createdBy: createdBy, @handleChange)

  handleChange: ()->
    value = @state
    @props.onChange?(@props.name, value, false)

  onDateChange: (value)->
    @setState(value, @handleChange)

  onResetSelectedUser: (e)->
    e.preventDefault();
    e.stopPropagation();
    @setState(createdBy: [@props.user?.id], @handleChange)

  onUserSelect: (selectedUser)->
    @setState(createdBy: [selectedUser.value], @handleChange)

  renderWatchListSelect: ()->
    if (!@isByMe())
      return React.createElement(Trans, {"i18nKey": ("search.additional.property.notes.hotel.on.watch.list"), "values": ({ belonging: @getSelectedUserName() })},
        React.createElement("a", {"onClick": (@onResetSelectedUser)})
      )
    return React.createElement(Trans, {"i18nKey": ("search.additional.property.notes.hotel.on.my.watch.list")},
      React.createElement(CInlineDropdown, { \
          "placeholder": (@props.t("watch.list.search.placeholder")),  \
          "as": "a",  \
          "onSelect": (@onUserSelect),  \
          "items": (@state.users || [])
      })
    )


  render: ()->
    React.createElement("div", {"style": (marginTop: "15px"), "className": (if !@props.isFastFilter then "box-checkbox middle line-normal has-notes dofl" else "line-normal dofl")},
      (if !@props.isFastFilter then React.createElement("input", {"type": "checkbox", "id": ("hasWatchList" + @props.name), "checked": (@state?.enabled || false), "onChange": (@includeWatchList)})),
      React.createElement("label", {"htmlFor": ("hasWatchList" + @props.name)},
        (@renderWatchListSelect())
      )
    )
) , {
  state: (state) ->
    translation: state.translation
    user: state.session?.user
}