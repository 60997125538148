{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'

Utils = require '../../helpers/Utils'
{ withTranslation } = require 'react-i18next' 


module.exports = withTranslation() Connect createReactClass(
  displayName: "CCount"

  getInitialState: ()->
    oldValue: @props.count

  componentWillReceiveProps: (props)->
    if props.count > -1
      @setState oldValue: props.count

  render: ()->
    if @props.isCountLoading
      return React.createElement("span", {"className": "count", "style": (opacity:0.5)}, (Utils.formatNumber(@props.count)));
    if @props.count > -1
      React.createElement("span", {"className": "count"}, (Utils.formatNumber(@props.count)))
    else
      React.createElement("span", {"className": "count", "style": (opacity:0.3)}, (Utils.formatNumber(@state.oldValue)))

), {
 state: (state) ->
   count: state.searchForm.info?.count,
   isCountLoading: state.searchForm.info?.isCountLoading
}
