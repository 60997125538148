import React, { useCallback, useEffect, useRef, useState } from 'react';
import Connect from "../helpers/Connect.js";
import { CLabel } from "../cmp/CLabel";
import { usePopper } from "react-popper";

export const AccessRestriction = Connect(({ children, user }) => {
    const [show, setShow] = useState(false);
    const timer = useRef(null);
    const popupRef = useRef(null);
    const popperRef = useRef(null);
    const { styles, update } = usePopper(popupRef.current, popperRef.current, {
        placement: "top-end",
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
            {
                name: 'preventOverflow',
                options: {
                    padding: 10
                },
            },
        ]
    });

    const openPopup = (e) => {
        setShow(!show);
        if (update) {
            update();
        }

        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (!show) {
            timer.current = setTimeout(() => {
                setShow(false);
            }, 4000);
        }
    }

    const handleClickOutside = useCallback((event) => {
        if (
            popupRef.current &&
            !popupRef.current.contains(event.target) &&
            show
        ) {
            if (timer.current) {
                clearTimeout(timer.current);
            }
            setShow(false);
        }
    }, [show]);

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside])

    const stopPropagation = (e) => e.stopPropagation();

    if (user && user.shadowType === "DEMO") {
        return <div className="access-restriction" onClick={openPopup} ref={popupRef}>
            {children}
            <div onClick={stopPropagation}
                ref={popperRef}
                style={styles.popper}
                className={"access-restriction-popup " + (show && "access-restriction-popup-show")}>
                <CLabel k="access.restriction.first" /> <br />
                <CLabel k="access.restriction.second" />
            </div>
        </div>
    }

    return children;
}, {
    state: (state) => {
        return {
            user: state.session && state.session.user
        };
    },
});
