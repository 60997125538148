import Utils from '../../helpers/Utils.cjsx'
import { CSSProperties } from 'react'
import './role_short.scss'
import { Trans } from 'react-i18next'

/**
 * @type {{[key: string]: CSSProperties}}
 */
const styles = {
  phone: {
    lineHeight: "18px"
  }
}

export default ({ company, contact }) => {
  const phone = contact && contact.phone || company && company.phone

  return <span className="phone text-overflow" style={styles.phone}>
    {phone ? <Trans i18nKey="property.contact.phoneNumber" values={{
      phoneNumber: phone
    }}>
      <b>P: </b>
      <a target="_top" href={"tel:" + Utils.formatPhoneNumber(phone)} className="link link-phone">{{ phone }}</a>
    </Trans> : " "}
  </span>
}