Connect = require '../../helpers/Connect'
{ CLabel } = require "../CLabel"
Utils = require '../../helpers/Utils'
Moment = require 'moment'

module.exports = Connect createReactClass(
  displayName: "CAdminAnalyticsActionsTable"

  stateDirty: []

  getInitialState: () ->
    {}

  componentDidMount: () ->

  componentWillReceiveProps: (newProps) ->
    {}

  render: ->
    React.createElement("div", null,
      (if @props.title
        React.createElement("div", {"className": "flex-tr", "style": ('backgroundColor': '#ccc')},
          React.createElement("div", {"className": "flex-td"}, React.createElement("span", {"style": ('fontWeight': '450')}, (@props.title)))
        )
      ),
      (@props.tableData.map (data, idx)->
        React.createElement("div", {"className": "flex-tr", "key": (idx)},
          React.createElement("div", {"className": "flex-td"},
            (data.createdAt && Moment(data.createdAt).tz('UTC').format('YYYY-MM-DD HH:mm'))
          ),
          React.createElement("div", {"className": "flex-td"},
            (data.userName)
          ),
          React.createElement("div", {"className": "flex-td"},
            (data.clientName)
          ),
          React.createElement("div", {"className": "flex-td"},
            (if data?.isAlias then data.platform + ', Alias' else data.platform)
          ),
          React.createElement("div", {"className": "flex-td"},
            (data.action)
          ),
          React.createElement("div", {"className": "flex-td flex-td-15"},
            (if data.description
              data.description
            else
              React.createElement(CLabel, {"k": "admin.tabs.users.action.desc.#{data.action.toLowerCase()}"})
            )
          )
        )
      )
    )
), {
  state: (state) ->
    {}
  dispatch: (dispatch) ->
    {}
}