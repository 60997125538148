Connect = require '../../helpers/Connect'
Utils = require '../../helpers/Utils'
{ CLabel } = require '../CLabel'
{ API } = require '../../redux/Store'
TextareaAutosize = (require 'react-textarea-autosize').default

dontShowAutocomleteFor = ["-","=",">","<","\"","#","*"]

module.exports = Connect createReactClass(
  displayName: "CFilterElement"

  timeoutId: 0
  handleBlurTimeoutId: 0

  getInitialState: ()->
    value: @props.value
    label: @props.label
    className: @props.className || ""
    name: @props.name
    type: @props.type || "text"
    placeholder: @props.placeholder
    description: @props.placeholder || ""
    display: "none"
    options: []
    loading: false
    withLabels: @props.withLabels || false
    changeOnBlur: @props.changeOnBlur || false
    forceSingleAutocomplete: @props.forceSingleAutocomplete || false

  checkIfAutocompleteIsNeeded: (value)->
    common = @props.searchFieldsInfo[@props.name]?.autocomplete || @props.searchCompanyFieldsInfo[@props.name]?.autocomplete && value?.length > 2
    hasNoSpecialSymbols = (dontShowAutocomleteFor.filter (symbol)-> value.indexOf(symbol) != -1).length == 0 && value.trim()[value.trim().length-1] != ";"
    lastSymbolChanged = @state.value?.indexOf(value) > -1 || value?.indexOf(@state.value) > -1

    common && lastSymbolChanged && hasNoSpecialSymbols

  handleChange: (e)->
    value = e.target.value
    if e.which == 13
      e.preventDefault()
      return false
    else if @checkIfAutocompleteIsNeeded(value) || @props.autocompleteXhr
      @setState loading: true
      clearTimeout(@timeoutId)
      if @xhr
        @xhr.abort()
        @setState loading: false
      @timeoutId = setTimeout ()=>
        form = @props.form
        if @state.forceSingleAutocomplete
          form = "#{@state.name}":
            includeUnbranded: true
            value: value

        cb = (list)=>
          selected = @state.value?.split(";")?.slice(0, -1)
          list = list.filter (el)-> !selected?.includes(el)
          @setState loading: false, options: list || []
          if @state.options.length > 0 && value?.length > 2
            @open()

        fieldName = @props.dictionaryKey || @props.name
        @xhr = if @props.autocompleteXhr then @props.autocompleteXhr(cb, value) else API.autocomplete fieldName, form, cb
      , 200
    else
      @close()

    if value == @state.value
      return
      
    if @props.withLabels
      @setState value: value, label: value, () =>
        @props.onChange?(@props.name, value, value, false)
    else
      @setState value: value, () =>
        @props.onChange?(@props.name, value, false)

  handleFocus: (e)->
    @props.onFocus?(e)

  handlePaste: (e)->
    @props.onPaste?(e)


  handleCopy: (e)->
    @props.onCopy?(e)

  close: ()->
    clearTimeout(@timeoutId)
    @setState display: "none"

  open: ()->
    document.querySelector('.box-dropdown').scrollTop = 0
    document.querySelector('.menu-dropdown').scrollTop = 0
    box = @refs.autocompleteBox
    active = box.querySelector('.active')
    box.querySelector('.form-control:first-child').classList.add('active')
    @setState display: "block"

  handlerOnKeyPress: (e)->
    box = @refs.autocompleteBox
    active = box.querySelector('.active')
    if e.which == 13
      e.preventDefault()
      active?.click()
      active?.classList?.remove("active")
      @close()
    else if @state.value?.length < 3
      @setState loading: false
      @close()
    else if e.which == 38 || e.which == 40
      controls = box.querySelectorAll('.form-control')
      activeIndex = Array.from(controls).findIndex (el)-> el.classList.contains("active")
      if active.classList.contains("active")
        active.classList.remove("active")
      if e.which == 38
        if activeIndex > 0
          controls[activeIndex - 1].classList.add("active")
        else
          controls[controls.length - 1].classList.add('active')
      else if e.which == 40
        if activeIndex < controls.length - 1
          controls[activeIndex + 1].classList.add("active")
        else
          controls[0].classList.add('active')
      active = box.querySelector('.active')
      if active
        box.scrollTop = active.parentElement.offsetTop - box.offsetTop
      return false
    else if @state.display == "block" && e.which == 27
      @close()

  handleBlur: (e)->
    @props.onBlur?(e)
    if @state.changeOnBlur
      if @state.withLabels
        @props.onChange?(@props.name, @state.value, @state.label, true)
      else
        @props.onChange?(@props.name, @state.value, true)
    @handleBlurTimeoutId = setTimeout @close, 300

  selectWithLabel: (value, label)->
    value += ';'
    if @state.value?.lastIndexOf(";") > -1 && @state.value.indexOf(value) == -1
      value = @state.value.substring(0, @state.value.lastIndexOf(";"))+ ";" + value
    @setState value: value, label: label
    @props.onChange?(@props.name, value, label, true)

  select: (value)->
    value += ';'
    if @state.value?.lastIndexOf(";") > -1 && @state.value.indexOf(value) == -1
      value = @state.value.substring(0, @state.value.lastIndexOf(";"))+ ";" + value
    @setState value: value
    @props.onChange?(@props.name, value, true)

  componentWillReceiveProps: (newProps)->
    if newProps.placeholder != @state.placeholder
      @setState placeholder: newProps.placeholder
    if newProps.value != @state.value && !@state.withLabels
      @setState value: newProps.value

  componentWillUnmount: ()->
    clearTimeout(@timeoutId)
    clearTimeout(@handleBlurTimeoutId)

  render: ->
    React.createElement("span", null,
      React.createElement("div", {"className": "box-control-dropdown"},
        React.createElement("div", {"className": "box-dropdown usual"},
          React.createElement(TextareaAutosize, { \
            "rows": "1",  \
            "wrap": "soft",  \
            "className": ("form-control textarea-control"),  \
            "data-cy-field-name": (@props.name),  \
            "placeholder": (@state.placeholder),  \
            "disabled": (@props.disabled),  \
            "style": (@props.style),  \
            "value": (@state.label || (@state.value || "")),  \
            "onChange": (@handleChange),  \
            "onFocus": (@handleFocus),  \
            "onPaste": (@handlePaste),  \
            "onCopy": (@handleCopy),  \
            "onBlur": (@handleBlur),  \
            "onKeyDown": (@handlerOnKeyPress),  \
            "autoCorrect": "off",  \
            "autoCapitalize": "none"
          }),
          (if @state.loading
            React.createElement("div", {"className": "box-icon-control right"},
              React.createElement("div", {"className": "loader"})
            )
          ),
          React.createElement("div", {"className": "menu-dropdown menu-usual", "ref": "autocompleteBox", "style": (display: @state.display)},
            (if @state.options.length == 0
              React.createElement("div", {"className": "form-control"}, React.createElement(CLabel, {"k": "no.results"}))
            else
              @state.options.map (value, i)=>
                if @state.withLabels
                  React.createElement("a", {"key": (i), "onClick": (@selectWithLabel.bind @, value?.value, value?.label)}, React.createElement("div", {"className": "form-control"}, (value?.label)))
                else
                  React.createElement("a", {"key": (i), "onClick": (@select.bind @, value)}, React.createElement("div", {"className": "form-control"}, (value)))
            )
          )
        )
      ),
      (if @state.error
        React.createElement("div", {"className": "message-error"}, (@state.error))
      )
    )
), state: (state)->
     searchFieldsInfo: state.app.searchFieldsInfo
     searchCompanyFieldsInfo: state.app.searchCompanyFieldsInfo
     form: state.searchForm.form
