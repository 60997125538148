{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
CFilterElement = require '../../cmp/filters/CFilterElement'
CMultipleDropdown = require '../../cmp/filters/CMultipleDropdown'
{ CLabel } = require '../../cmp/CLabel'
Utils = require '../../helpers/Utils'
Comparators = require '../../helpers/Comparators'

CCount = require '../../cmp/results/CCount'
CFastFilterElement = require('../../cmp/results/CFastFilterElement').default
CMobileDropdownMenu = require '../../cmp/CMobileDropdownMenu'
{ API } = require '../../redux/Store'
{ withTranslation } = require 'react-i18next'

activityFields = ["showConversions","showDistressedAssets","showNewConstruction","showOpenAndOperating","showRenovations","showSales"]


module.exports = withTranslation() Connect createReactClass(
  displayName: "CReportFastFilter"
  needFullReload: false

  createVirtualDropdown: (searchForm)->
    Activity:
      name: "Activity"
      priority: 10
      virtualDropdown: true
      value: (activityFields.filter (name)=>  searchForm[name]).join(";")
      options: activityFields.map (name)->
        value: name
        label: Utils.prettifyFieldName(name).substring(5)
      onChange: (unused, value)=>
        @props.setBlocksOnSearchForm(activityFields.map (f)-> name:f, value: f in value)
      onClear: ()=>
        @props.setBlocksOnSearchForm(activityFields.map (f)-> name:f, value: false)
    Excluded:
      priority: 1000
      name: "Excluded"
      virtualDropdown: true
      value: ((searchForm.excludedProperties || []).map (option)-> option.id).join(";")
      options: (searchForm.excludedProperties || []).map (option)->
        value: option.id
        label: option.name
      onChange: (unused, value, isTrue)=>
        @props.setExcludedProperties(value)
      valuePrinter: (values, options)=>
        return @props.t('results.fast.filter.property', { count: values.length });
      onClear: ()=>
        @props.clearExcludedProperties()

  getInitForm: (searchForm, fullReload)->
    if @needFullReload && fullReload
      @needFullReload = false

    fields = []
    Object.keys(searchForm).forEach (key)=>
      field = searchForm[key]
      if field.value
        fields.push
          value: field.value
          name: key
          priority: @props.searchFieldsInfo[key]?.priority || 1000

    virtualDropDowns = @createVirtualDropdown(searchForm)
    if virtualDropDowns.Activity.value
      fields.push virtualDropDowns.Activity

    if searchForm.excludedProperties?.length > 0
      fields.push virtualDropDowns.Excluded

    if !fullReload
      @state.searchForm.forEach (key)=>
        field = @state.searchForm[key]
        if field.name && (fields.filter (field)-> field.name == key).length == 0
          if virtualDropDowns[key]
            fields.push virtualDropDowns[key]
          else
            fields.push
              value: field.value
              name: key
              priority: @props.searchFieldsInfo[key]?.priority || 1000

    result = {}
    (fields.sort (a, b)-> a.priority - b.priority).forEach (i, field)=>
      result[field.name] = field
    result

  getInitialState: ()->
    searchForm: []
    filterError: null
    version: @props.searchForm.version
    dropDownDisplay: "none"

  componentWillReceiveProps: (newProps)->
    if newProps.searchForm.filterId != @props.params.filterId * 1 || newProps.searchForm.version != @state.version
      fullReload = @needFullReload || @props.params.filterId && newProps.searchForm.filterId != @props.params.filterId * 1
      @setState
        searchForm: @getInitForm(newProps.searchForm, fullReload)
        version: newProps.searchForm.version

  getSortingLabel: ()->
    result = []
    Object.keys(@props.searchForm).forEach (name)=>
      field = @props.searchForm[name]
      if field.sortingNumber
        result.push(name: Utils.prettifyFieldName(name), sortingNumber: field.sortingNumber)
    (result.sort((a,b)-> a.sortingNumber - b.sortingNumber).map (f)-> f.name).join(", ")


  onFilterChanged: ()->
    @needFullReload = true




  render: ()->
    filterNameInput =
      React.createElement("div", {"className": "box-control"}, " ", (@props.searchForm?.name || ""))


    React.createElement("div", {"className": "", "key": (@props.params.filterId)},
      React.createElement("div", {"className": "slide-form"},
        React.createElement("div", {"className": "search-nav hide-mobile"},
          React.createElement("div", {"className": "box-input box-inline"},
            (filterNameInput)
          )
        ),
        React.createElement("div", {"className": "block-tile"},
          React.createElement("div", {"className": "box-tile"},
            React.createElement("div", {"className": "tile-group"},
              React.createElement("form", {"className": "form form-small"},
                (Object.keys(@state.searchForm).map (name)=>
                  field = @state.searchForm[name]
                  if field.name
                    React.createElement(CFastFilterElement, { \
                      "key": (name),  \
                      "name": (name),  \
                      "field": (field),  \
                      "fieldInfo": (@props.searchFieldsInfo[name]),  \
                      "isPrintedLayout": (true)
                    })
                )
              ),
              React.createElement("div", {"className": "tile-sorted"},
                React.createElement("div", {"className": "box-right box-btn"},
                  React.createElement("div", {"className": "box-nav"},
                    React.createElement("a", {"className": "btn link-grey overflow open-modal"},
                      React.createElement("i", {"className": "icon icon-middle icon-asc"}),
                      React.createElement("span", {"className": "hide-mobile"}, React.createElement(CLabel, {"k": "results.fast.filter.sorted.by", "param": ({ sortingLabel: @getSortingLabel() })})),
                      React.createElement("span", {"className": "show-mobile"}, React.createElement(CLabel, {"k": "results.fast.filter.sorting"}))
                    )
                  )
                ),
                React.createElement("div", {"className": "info-count lite"}, React.createElement("span", {"className": "count"}, React.createElement(CCount, null)), React.createElement(CLabel, {"k": "results.fast.filter.records.found"}))
              )
            )
          )
        )
      )
    )
), {
  state: (state) ->
    searchFieldsInfo: state.app.searchFieldsInfo
    searchForm: state.searchForm.form
    result: state.searchForm?.result
    info: state.searchForm?.info
    translation: state.translation
}