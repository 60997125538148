export const extend = (deep, target = {}, ...sources) => {
    if (typeof deep !== 'boolean') {
        sources.unshift(target);
        target = deep;
        deep = false;
    }
    if (!target) {
        target = Array.isArray(sources[0]) ? [] : {};
    }
    sources.forEach(source => {
        if (source) {
            Object.keys(source).forEach(key => {
                if (deep && typeof source[key] === 'object' && source[key]) {
                    if (!target[key]) {
                        target[key] = Array.isArray(source[key]) ? [] : {};
                    }
                    extend(deep, target[key], source[key]);
                } else {
                    target[key] = source[key];
                }
            });
        }
    });
    return target;
};

export const param = (obj) => {
    return Object.keys(obj).map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
    }).join('&');
};

export const onScrollTooltipPosition = (tooltipElementId, tooltipAnchorId) => (e) => {
    const tooltipElement = document.getElementById(tooltipElementId);
    const tooltipAnchor = document.getElementById(tooltipAnchorId);
    const { top, height } = tooltipElement?.getBoundingClientRect() || {};
    const isTop = tooltipAnchor?.classList.contains('tooltip-top');
    const isBottom = tooltipAnchor?.classList.contains('tooltip-bottom');
    const anchorRect = tooltipAnchor?.getBoundingClientRect();
    if (top < 52 && isTop) {
        tooltipAnchor.classList.add('tooltip-bottom');
        tooltipAnchor.classList.remove('tooltip-top');
        return
    }
    if ((anchorRect?.bottom + height) > (e.target.clientHeight + 48) && isBottom && anchorRect.top >= (height + 60)) {
        tooltipAnchor.classList.add('tooltip-top');
        tooltipAnchor.classList.remove('tooltip-bottom');
        return;
    }
}

export const ACTION_TYPES = {
    DELETE: "DELETE",
    REASSIGN: "REASSIGN",
    MARK_AS_DONE: "MARK_AS_DONE",
    MARK_AS_NOT_DONE: "MARK_AS_NOT_DONE"
}

const deleteConfirmationAction = (note, user, t) => {
    const isAction = note.type === "ACTION";
    const isNote = note.type === "NOTE";
    const isWrittenByUser = note.createdBy.id === user.id;
    const defaultMessage = {
        title: t('actions.and.notes.confirmationModal.deleteTitle'),
        description: t('actions.and.notes.confirmationModal.confirmDeleteText'),
    };

    if (isNote) {
        if (isWrittenByUser) {
            return defaultMessage;
        }
        return {
            title: t('actions.and.notes.confirmationModal.deleteTitle'),
            description: [
                t('actions.and.notes.confirmationModal.noteWrittenBy', { fullName: `${note.createdBy.firstName} ${note.createdBy.lastName}` }),
                t('actions.and.notes.confirmationModal.confirmDeleteText')
            ].join(' ')
        }
    }

    const isCompleted = !note.active;
    const isCompletedByUser = isCompleted && note.doneBy?.id === user.id;

    if (isAction) {
        if (isWrittenByUser) {
            if (!isCompleted) {
                return {
                    title: t('actions.and.notes.confirmationModal.deleteTitle'),
                    description: [
                        t('actions.and.notes.confirmationModal.actionIsNotDone'),
                        t('actions.and.notes.confirmationModal.confirmDeleteText')
                    ].join(' ')
                }
            }
            return defaultMessage;
        }

        if (!isWrittenByUser) {
            if (isCompletedByUser) {
                return defaultMessage;
            }
            return {
                title: t('actions.and.notes.confirmationModal.deleteTitle'),
                description: [
                    t('actions.and.notes.confirmationModal.actionWrittenBy', { fullName: `${note.createdBy.firstName} ${note.createdBy.lastName}` }),
                    t('actions.and.notes.confirmationModal.confirmDeleteText')
                ].join(' ')
            }
        }
    }

    return null;
}

const reassignConfirmationAction = (note, user, t) => {
    const isWrittenByUser = note.createdBy.id === user.id;
    if (isWrittenByUser) {
        return null;
    }
    return {
        title: t('actions.and.notes.confirmationModal.reassignTitle'),
        description: [
            t('actions.and.notes.confirmationModal.actionWrittenBy', { fullName: `${note.createdBy.firstName} ${note.createdBy.lastName}` }),
            t('actions.and.notes.confirmationModal.confirmReassignText')
        ].join(' ')
    }
}

const markAsDoneConfirmationAction = (note, user, t) => {
    const isAssignToMe = note.assignedTo.id === user.id;
    if (isAssignToMe) {
        return null;
    }
    return {
        title: t('actions.and.notes.confirmationModal.markAsDoneTitle'),
        description: [
            t('actions.and.notes.confirmationModal.actionAssignedTo', { fullName: `${note.assignedTo.firstName} ${note.assignedTo.lastName}` }),
            t('actions.and.notes.confirmationModal.confirmMarkAsDoneText')
        ].join(' ')
    }
}

const markAsNotDoneConfirmationAction = (note, user, t) => {
    const isWrittenByUser = note.createdBy.id === user.id;
    const isAssignToMe = note.assignedTo.id === user.id;
    const isCompletedByUser = note.doneBy?.id === user.id;
    if (isAssignToMe || isWrittenByUser || isCompletedByUser) {
        return null;
    }
    return {
        title: t('actions.and.notes.confirmationModal.markAsDoneTitle'),
        description: [
            t('actions.and.notes.confirmationModal.actionWrittenBy', { fullName: `${note.createdBy.firstName} ${note.createdBy.lastName}` }),
            t('actions.and.notes.confirmationModal.confirmMarkAsNotDoneText')
        ].join(' ')
    }
}

const getDescriptionForAAConfirmationModal = (note, user, actionType, t) => {
    if (actionType === ACTION_TYPES.DELETE) {
        return deleteConfirmationAction(note, user, t);
    }
    if (actionType === ACTION_TYPES.REASSIGN) {
        return reassignConfirmationAction(note, user, t);
    }
    if (actionType === ACTION_TYPES.MARK_AS_DONE) {
        return markAsDoneConfirmationAction(note, user, t);
    }
    if (actionType === ACTION_TYPES.MARK_AS_NOT_DONE) {
        return markAsNotDoneConfirmationAction(note, user, t);
    }
    return null;
}

export const checkActionConfirmationForAA = (t, note, user, actionType, toggleConfirmActionPopup, callbackFn) => {
    const confirmationNeeded = getDescriptionForAAConfirmationModal(note, user, actionType, t);
    if (confirmationNeeded) {
        const { title, description } = confirmationNeeded;
        toggleConfirmActionPopup({
            title,
            description,
            onCancel: () => toggleConfirmActionPopup(),
            onConfirm: () => {
                toggleConfirmActionPopup();
                callbackFn();
            }
        });
        return
    }
    callbackFn()
}