
Connect = require '../../helpers/Connect'
{API} = require '../../redux/Store'
{ CInput } = require '../CInput'
{ CLabel } = require '../CLabel'

{ withTranslation } = require 'react-i18next' 


module.exports = withTranslation() Connect createReactClass(

  getInitialState: ->
    tags: []
    newTag:
      keyword: ""
      label: ""

  componentWillMount: ()->
    if !@props.user?.isAdmin
      @props.router.push('/home')

  componentDidMount: ()->
    API.getTags (tags)=>
      @setState tags: tags

  onBlur: (keyword, e)->
    label = e.target.value

    tag = @state.tags.find((tag)-> keyword == tag.keyword)
    if !label && !tag?.label
      return
    if tag?.changed
      API.saveTag keyword: keyword, label: label, null, null, ()=>
        @refs[keyword].style.display = "inline-block"
        setTimeout ()=>
          @refs[keyword].style.display = "none"
          tags = @state.tags.map((tag)->
            if tag.keyword == keyword
              tag.changed = false
            tag
          )
          @setState tags: tags
        , 2000

  onChange: (keyword, e)->
    tags = @state.tags.map((tag)->
      if tag.keyword == keyword
        tag.label = e.target.value
        tag.changed = true
      tag
    )
    @setState tags: tags

  filterTags: (withLabel)->
    @state.tags.filter (tag)-> if withLabel then tag.label?.length > 0 else !tag.label

  tagsGroup: (tag, i)->
    if tag.keyword
      React.createElement("div", {"className": "box-control clearfix", "style": (padding: '0 10px 20px 10px'), "key": (i)},
        React.createElement("div", {"style": (width: '35%', display: 'inline-block', paddingRight: '10px')},
          React.createElement("label", {"style": (display: 'block', lineHeight: '1.5em'), "className": 'bold'}, (tag.keyword))
        ),
        React.createElement("div", {"style": (width: '65%', display: 'inline-block')},
          React.createElement(CInput, {"key": (tag.keyword), "style": (width: "90%"), "placeholder": (@props.t("admin.placeholder.enter.label", { ns: "internal_ui" })), "className": "form-control", "onBlur": (@onBlur.bind @, tag.keyword), "onChange": (@onChange.bind @, tag.keyword), "type": 'text', "value": (tag.label)}),
          (if tag.label
            React.createElement("span", {"style": (paddingLeft: '15px', cursor: 'pointer'), "onClick": (@deleteLabel.bind @, tag.keyword)}, React.createElement("i", {"className": "fa fa-remove"}))
          ),
          React.createElement("span", {"className": "red-text", "ref": (tag.keyword), "style": (display: 'none', float: 'right', fontSize: '12px')}, React.createElement(CLabel, {"k": "admin.saved"}))
        )
      )
    else
      null

  setForm: (e)->
    form = @state.newTag
    form[e.target.name] = e.target.value
    @setState newTag: form

  isValidKeyword: (keyword)->
    !!keyword && (@state.tags.filter (tag)-> tag.keyword == keyword).length == 0

  deleteLabel: (keyword)->
    API.saveTag keyword: keyword, label: "", null, null, ()=>
      @state.tags[(@state.tags.map (tag)-> tag.keyword).indexOf(keyword)].label = ""
      @setState @state


  addTag: ->
    if @isValidKeyword(@state.newTag.keyword)
      API.addTag @state.newTag, null, null, ()=>
        @state.tags.unshift(@state.newTag)
        @state.newTag =
          keyword: ""
          label: ""
        @setState @state

  render: ->
    React.createElement("div", {"className": "block-tile admin-page"},
      React.createElement("div", {"className": "box-tile"},
        React.createElement("div", {"className": "tile-group"},
          React.createElement("div", {"className": "name-block upper"},
            React.createElement(CLabel, {"k": "admin.add.new.tag"})
          ),
          React.createElement("form", {"className": "form form-small"},
            React.createElement("div", {"className": "box-control clearfix", "style": (padding: '20px 10px')},
              React.createElement("div", {"className": "row-half", "style": (paddingBottom: '20px')},
                React.createElement("input", {"style": (width: "90%"), "placeholder": (@props.t("admin.placeholder.enter.keyword", { ns: "internal_ui" })), "className": "form-control", "type": 'text', "onChange": (@setForm), "name": "keyword", "value": (@state.newTag.keyword)})
              ),
              React.createElement("div", {"className": "row-half"},
                React.createElement("input", {"style": (width: "90%"), "placeholder": (@props.t("admin.placeholder.enter.label", { ns: "internal_ui" })), "className": "form-control", "onChange": (@setForm), "name": "label", "value": (@state.newTag.label)})
              ),
              React.createElement("div", {"className": "box-btn text-right hide-mobile", "style": (width: "95%", paddingTop: '20px')},
                React.createElement("a", {"onClick": (@addTag), "className": "btn btn-red btn-normal upper #{if !@isValidKeyword(@state.newTag.keyword) then 'disabled'}"}, React.createElement(CLabel, {"k": "admin.add"}))
              ),
              React.createElement("div", {"className": "box-btn text-right show-mobile", "style": (width: "90%")},
                React.createElement("a", {"onClick": (@addTag), "className": "btn btn-red btn-normal upper #{if !@isValidKeyword(@state.newTag.keyword) then 'disabled'}"}, React.createElement(CLabel, {"k": "admin.add"}))
              )
            )
          )
        ),
        React.createElement("div", {"className": "tile-group"},
          React.createElement("div", {"className": "tile-title hide-mobile", "style": (marginBottom: '20px')},
            React.createElement("div", {"style": (width: "35%", display: 'inline-block', textAlign: 'center')},
              React.createElement("h3", null, React.createElement(CLabel, {"k": "admin.keyword"}))
            ),

            React.createElement("div", {"style": (width: "65%", display: 'inline-block', textAlign: 'center')},
              React.createElement("h3", null, React.createElement(CLabel, {"k": "admin.label"}))
            )
          ),
          React.createElement("form", {"className": "form form-small", "style": (height: '100%')},
            React.createElement("div", {"className": 'box-tile'},
              React.createElement("div", {"className": "tile-title"},
                React.createElement("div", {"className": "name-block"},
                  React.createElement(CLabel, {"k": "admin.tag.definitions"})
                )
              ),
              (@filterTags(true).map (tag, i)=>
                @tagsGroup(tag, i)
              )
            ),
            React.createElement("div", {"className": 'box-tile', "style": (marginTop: '20px')},
              React.createElement("div", {"className": "tile-title"},
                React.createElement("div", {"className": "name-block"},
                  React.createElement(CLabel, {"k": "admin.undefined.tags.found.in.data"})
                )
              ),
              (@filterTags(false).map (tag, i)=>
                @tagsGroup(tag, i)
              )
            )
          )
        )
      )
    )

), {

  state: (state)->
    user: state.session?.user
    translation: state.translation

}