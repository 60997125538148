{ extend } = require '../../helpers/UtilsHelpers'

module.exports = (state = {}, action) ->
  if (action.type?.indexOf("TOGGLE_POPUP_") == 0)
    if state[action.type]
      delete state[action.type]
    else
      state[action.type] =
        onSuccessCallback: action.onSuccessCallback
        data: action.data

    extend true, {}, state
  else if action.type == "CLOSE_ACTIVE_POPUP"
    extend true, {}, {}
  else
    state
