Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'
{ CInputWithLabel } = require '../../cmp/CInputWithLabel'
Utils = require '../../helpers/Utils'
CMultipleDropdown = require './CMultipleDropdown'
{ API } = require '../../redux/Store'
DatePicker = require 'rc-calendar/lib/Picker'
Moment = require 'moment'
React = require 'react'
{ withTranslation, Trans } = require 'react-i18next' 
{ DatePickerSince } = require './DatePickerSince'
{ ChangesDelaysInWrapper, DaysAgoWrapper } = require './TimelineFilterHelpers'


module.exports = withTranslation() Connect createReactClass(
  displayName: "CHasTimelineFastFilterElement"

  optionsFields: () -> [
    {label: @props.t('data.fast.filter.label.startDate', { ns: "data" }),  value: 'fieldStart'}
    {label: @props.t('data.fast.filter.label.openingDate', { ns: "data" }),  value: 'fieldOpen'}
    {label: @props.t('data.fast.filter.label.projectStage', { ns: "data" }),  value: 'fieldProjectStage'}
  ]
    

  getInitialState: ()->
    values:
      isToggleable: true
      enabled: @props.value?.enabled || false
      timelineMode: @props.value?.timelineMode || 'DATE'
      sinceDate: @props.value?.sinceDate
      daysAgo: @props.value?.daysAgo || '60'
      fieldStart: @props.value?.fieldStart || false
      fieldOpen: @props.value?.fieldOpen || false
      fieldProjectStage: @props.value?.fieldProjectStage || false
      delaysOnly: @props.value?.delaysOnly || false
    fieldsValues: ''

  getClearState: ()->
    values:
      isToggleable: true
      enabled: true
      timelineMode: @props.value?.timelineMode
      sinceDate: undefined
      daysAgo: '60'
      fieldStart: false
      fieldOpen: false
      fieldProjectStage: false
      delaysOnly: false
    fieldsValues: ''

  componentWillReceiveProps: (newProps) -> 
    if newProps.value
      fieldsValues = @optionsFields().filter((v) -> 
        newProps.value[v.value] 
      ).map((v) -> v.value).join(';')
      @setState(
        values: 
          newProps.value
        fieldsValues: fieldsValues
      )
    else
      @setState(@getClearState())


  handleText: (e)->
    if e.target.name == 'daysAgo'
      @state.values.timelineMode = 'DAYS'
      @state.values.enabled = true
    @state.values[e.target.name] = e.target.value
    @setState(@state, @handleChange)

  handleRadio: (e)->
    @state.values[e.target.name] = 'true' == e.target.value
    @setState(@state, @handleChange)

  putTimelineDate: (moment) ->
    @state.values.enabled = true
    @state.values.timelineMode = 'DATE'
    @state.values.sinceDate = moment?.startOf('day').format()
    @setState(@state, @handleChange)

  handleChange: ()->
    @props.onChange?(@props.name, @state.values, false)

  putValueFromDropDown: (name, values, options)->
    @optionsFields().forEach((v) => 
      @state.values[v.value] = false
    )
    values.forEach((v) => 
      @state.values[v] = true
    )
    @setState(@state, @handleChange)

  valuePrinter: (values = [], options = []) ->
    texts = options
      .filter((o) -> values.indexOf(o.value) != -1)
      .map((o) -> o.label)
    text = ''
    for i in [0.. texts.length - 1]
      if i > 0 && i == texts.length - 1
        text += ' ' + @props.t('timeline.fast.filter.or') + ' '
      else if i > 0
        text += ', '
      text += texts[i];
    text
      

  timelineDatePlaceholder: (value)->
    React.createElement("div", {"className": "pointer form-control"},
      React.createElement("a", {"className": "black box-left"},
        (if value?.value then Moment(value?.value).format("[MMM-DD]") else React.createElement(CLabel, {"k": 'timeline.export.date'}))
      ),
      React.createElement("span", {"className": "fa fa-calendar box-right"})
    )

  render: ()->
    timelinePriorDateCalendar = Utils.createCalendar(@state.values?.sinceDate && Moment(@state.values?.sinceDate), @props.t("timeline.export.date"), Utils.timelineDatesFilter)
    timePicker = if @state.values.timelineMode != 'DAYS' then React.createElement(DatePickerSince, { \
        "animation": "slide-up",  \
        "calendar": (timelinePriorDateCalendar),  \
        "name": 'sinceDate',  \
        "value": (@state.values?.sinceDate && Moment(@state.values?.sinceDate).tz('UTC')),  \
        "onChange": (@putTimelineDate),  \
        "placeholder": (@timelineDatePlaceholder)
      }) else React.createElement(DaysAgoWrapper, { \
        "daysAgo": (@state.values.daysAgo),  \
        "onChange": (@handleText),  \
        "onBlur": (@props.onBlur)})
    React.createElement(React.Fragment, null,  
      React.createElement("div", {"className": "box-control middle box-checkbox"},
        React.createElement(Trans, {"i18nKey": "timeline.fast.filter.wrapper", "components": ({
          changesDelaysIn: React.createElement(ChangesDelaysInWrapper, { \
            "onChange": (@handleRadio),  \
            "delaysOnly": (@state.values.delaysOnly)}
          ),
          dropdown: React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "fields", "valuePrinter": (@valuePrinter), "values": (@state.fieldsValues), "options": (@optionsFields()), "placeholder": (@props.t("search.timeline.changes.to.fast"))}),
          timePicker: timePicker
          })}
        )
        
      )
    )
)