Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'
Utils = require '../../helpers/Utils'
{ API } = require '../../redux/Store'
DatePicker = require 'rc-calendar/lib/Picker'
Moment = require 'moment'
React = require 'react'
{ withTranslation, Trans } = require 'react-i18next' 


module.exports = withTranslation() Connect createReactClass(

  displayName: "CHasTimelineFilterElement"

  getInitialState: ()->
    values:
      isToggleable: true
      enabled: @props.value?.enabled || false
      timelineMode: @props.value?.timelineMode || 'DATE'
      sinceDate: @props.value?.sinceDate
      daysAgo: @props.value?.daysAgo || 60
      fieldStart: @props.value?.fieldStart || false
      fieldOpen: @props.value?.fieldOpen || false
      fieldProjectStage: @props.value?.fieldProjectStage || false
      delaysOnly: @props.value?.delaysOnly || false

  componentWillReceiveProps: (newProps) ->
    if (newProps.value)
      @setState(values: newProps.value)

  handleCheckbox: (e)->
    @state.values[e.target.name] = e.target.checked
    @setState(@state, @handleChange)

  handleText: (e)->
    @state.values[e.target.name] = e.target.value
    @setState(@state, @handleChange)

  handleRadio: (e)->
    if e.target.checked
      @state.values[e.target.name] = e.target.value
      @setState(@state, @handleChange)

  setMode: (value) -> () =>
    @state.values?.timelineMode = value
    @setState(@state, @handleChange)

  putTimelineDate: (moment) ->
    @state.values.sinceDate = moment?.startOf('day').format()
    @setState(@state, @handleChange)

  handleChange: ()->
    @props.onChange?(@props.name, @state.values, false)

  timelineDatePlaceholder: (value) ->
    Utils.timelineDatePlaceholder(value.value)

  render: ()->
    timelinePriorDateCalendar = Utils.createCalendar(@state.values?.sinceDate && Moment(@state.values?.sinceDate), @props.t("timeline.export.date"), Utils.timelineDatesFilter)
    React.createElement(React.Fragment, null,
      React.createElement("div", {"className": "name-block small", "style": (marginTop: "20px")}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.timeline.title"}))),
      React.createElement("div", {"className": "box-control"},
        React.createElement("div", {"className": "clearfix", "style": (display: "flex", justifyContent: "space-around")},
          React.createElement("div", {"className": "box-checkbox middle aligned", "style": (flexGrow: "1")},
            React.createElement("input", {"type": "checkbox", "id": "check_timeline", "name": "enabled", "checked": (@state.values?.enabled), "onChange": (@handleCheckbox)}), React.createElement("label", {"htmlFor": "check_timeline"}, React.createElement(CLabel, {"k": "search.timeline.check_timeline"}))
          ),
          React.createElement("div", {"className": "box-control middle box-checkbox #{if !@state.values?.enabled then 'disabled unselectable' else ''}"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("input", {"type": "radio", "id": "timelineModeDate", "name": "timelineMode", "checked": (@state.values?.timelineMode == 'DATE'), "value": "DATE", "onChange": (@handleRadio)}),
              React.createElement("label", {"htmlFor": "timelineModeDate"},
                React.createElement(DatePicker, { \
                  "animation": "slide-up",  \
                  "calendar": (timelinePriorDateCalendar),  \
                  "name": 'sinceDate',  \
                  "value": (@state.values?.sinceDate && Moment(@state.values?.sinceDate).tz('UTC')),  \
                  "onChange": (@putTimelineDate),  \
                  "onOpenChange": (@setMode('DATE'))
                },
                  (@timelineDatePlaceholder)
                )
              )
            ),
            React.createElement("div", {"className": "box-control"},
              React.createElement("input", {"type": "radio", "id": "timelineModeDays", "name": "timelineMode", "checked": (@state.values?.timelineMode == 'DAYS'), "value": "DAYS", "onChange": (@handleRadio)}),
              React.createElement("label", {"className": "label-before-middle", "htmlFor": "timelineModeDays"}, """ 
""", React.createElement(Trans, {"i18nKey": ("search.timeline.days_ago")},
                  React.createElement("input", { \
                    "onBlur": (@props.onBlur),  \
                    "name": "daysAgo",  \
                    "onClick": (@setMode('DAYS')),  \
                    "defaultValue": (@state.values?.daysAgo), "maxLength": "2", "min": "1", "max": "700",  \
                    "onChange": (@handleText), "type": "number", "className": "form-control micro"
                  })
                )
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": "name-box", "style": (marginTop: '18px')}, React.createElement("span", {"className": "italic"}, React.createElement(CLabel, {"k": "search.timeline.type_of_change"}))),
      React.createElement("div", {"className": "name-box clearfix", "style": (marginTop: '18px')}, React.createElement(CLabel, {"k": "search.timeline.changes.to.label"})),
      React.createElement("div", {"className": "timeline-search-fields", "style": (marginTop: '10px')},
          React.createElement("div", {"className": "box-checkbox middle"},
            React.createElement("input", {"type": "checkbox", "id": "timelineStart", "name": "fieldStart", "checked": (@state.values?.fieldStart), "onChange": (@handleCheckbox)}), React.createElement("label", {"htmlFor": "timelineStart"}, React.createElement(CLabel, {"k": "search.timeline.changes.to.start"}))
          ),
          React.createElement("div", {"className": "box-checkbox middle"},
            React.createElement("input", {"type": "checkbox", "id": "timelineOpen", "name": "fieldOpen", "checked": (@state.values?.fieldOpen), "onChange": (@handleCheckbox)}), React.createElement("label", {"htmlFor": "timelineOpen"}, React.createElement(CLabel, {"k": "search.timeline.changes.to.open"}))
          )
      ),
      React.createElement("div", {"className": "box-checkbox middle"},
        React.createElement("input", {"type": "checkbox", "id": "timelineProjectStage", "name": "fieldProjectStage", "checked": (@state.values?.fieldProjectStage), "onChange": (@handleCheckbox)}), React.createElement("label", {"htmlFor": "timelineProjectStage"}, React.createElement(CLabel, {"k": "search.timeline.changes.to.project_stage"}))
      ),
      React.createElement("div", {"className": "box-checkbox middle", "style": (marginTop: '18px')},
        React.createElement("input", {"type": "checkbox", "id": "timelineDelays", "name": "delaysOnly", "checked": (@state.values?.delaysOnly), "onChange": (@handleCheckbox)}), React.createElement("label", {"htmlFor": "timelineDelays"}, React.createElement(CLabel, {"k": "search.timeline.delays"}))
      )
    )
), {
  state: (state) ->
    value: state.searchForm?.form?.hasTimelineFilter?.value
    translation: state.translation
}