var connect = require('react-redux').connect;
var withRouter = require('react-router').withRouter;

var empty = function () { return {} };

module.exports = function (reactClass, f) {
  return withRouter(connect(
    (f != null ? f.state : void 0) || empty,
    (f != null ? f.dispatch : void 0) || empty
  )(reactClass));
};