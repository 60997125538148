export const getMobileAppSettings = () => {
  const envs = [
    {
      name: "Staging",
      url: "https://lenav.s1.extech.by" 
    },
    {
      name: "Production",
      url: "https://clients.lodgingeconometrics.com"
    }
  ];
  return envs;
};