CSearchFormColumnActivity = require './CSearchFormColumnActivity'
CSearchFormColumnProperty = require './CSearchFormColumnProperty'
CSearchFormColumnCompany = require './CSearchFormColumnCompany'
{ CLabel } = require '../CLabel'

module.exports = createReactClass(
  displayName: "CPropertySearchForm"

  getInitialState: () ->
    companiesCollapsed: true

  toggle: () ->
    @setState companiesCollapsed: !@state.companiesCollapsed

  componentWillReceiveProps: (newProps) ->
    for fieldName in ['isManager', 'isPurchaser', 'isDesigner', 'isArchitect', 'isContractor', 'isDeveloper', 'isLender', 'isServicer', 'isReceiver']
      if @props.form[fieldName] && @state.companiesCollapsed
        @setState companiesCollapsed: false

    for fieldName in ['companyProximityFilter', 'companyContactName', 'companyName', 'companyAddress', 'companyCity', 'companyId', 'companyStateProvince', 'companyPostalCode', 'companyCountry', 'portfolioSize']
      if @props.form[fieldName]?.value  && @state.companiesCollapsed
        @setState companiesCollapsed: false

  render: () ->
    React.createElement("div", {"className": "box-table"},
      React.createElement(CSearchFormColumnProperty, null,
        React.createElement("div", {"className": "tile-group"},
          React.createElement("div", {"className": "tile-title"},
            React.createElement("div", {"className": "name-block"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.hotel.information.title"})))
          )
        )
      ),

      React.createElement(CSearchFormColumnActivity, {"className": "search-form-activity-column"},
        React.createElement("div", {"className": "tile-title"},
          React.createElement("div", {"className": "name-block"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.activity.label"})))
        ),
        React.createElement("div", {"className": "tile-text hide-mobile"},
          React.createElement(CLabel, {"k": "search.hotel.activity.description"})
        )
      ),

      React.createElement(CSearchFormColumnCompany, {"collapsed": (@state.companiesCollapsed)},
        React.createElement("div", {"className": "tile-group"},
          React.createElement("div", {"className": "tile-title"},
            React.createElement("div", {"className": "box-btn box-right"},
              React.createElement("a", {"onClick": (@toggle), "className": "btn btn-small btn-slide-form"},
                React.createElement("div", {"className": "box-arrow"},
                 (if @state.companiesCollapsed
                   React.createElement("i", {"className": "fa fa-angle-down"})
                 else
                   React.createElement("i", {"className": "fa fa-angle-up"})
                 )
                )
              )
            ),
            React.createElement("div", {"className": "name-block box-inline"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.companies"})))
          ),
          React.createElement("div", {"className": "tile-text"},
            React.createElement(CLabel, {"k": "search.hotel.companies.description"})
          )
        )
      )
    )
)