{ Link, browserHistory } = require 'react-router'
{ API } = require '../../redux/Store'
Connect = require '../../helpers/Connect'
CPopups = require '../../cmp/popups/CPopups'
CQuickFind = require '../../cmp/home/quick-find/CQuickFind'
{ CLabel } = require '../../cmp/CLabel'
Menu = require('./Menu').default
Moment = require 'moment'
{ createIframeForDownload, getEnvStyle } = require '../../helpers/Utils'
{ withTranslation } = require 'react-i18next'
LanguageSelectorHeader = require('./LanguageSelectorHeader').default

PERIOD_TO_SHOW_SLOW_MAP_WARNING =  24 * 60 * 60 * 1000 #1 day
MAX_RESULT_FOR_SLOW_WARNING = 5000

module.exports = withTranslation() Connect createReactClass(
  displayName: "Header"

  importInterval: null

  getInitialState: () ->
    menu: false
    bannerMessage: null
    bannerMessageSecond: null

  getImportMessage: (job) -> 
    return switch
      when job.stage in ['STARTING', 'DATA'] then 'header.update.message.stage.data'
      when job.stage in ['POSTPROCESSING', 'WEBHOOKS'] then 'header.update.message.stage.postprocessing'
      when job.stage in ['INDEXING', 'UPDATING_INDEXES'] then 'header.update.message.stage.indexing'
      when job.stage in ['DONE'] then 'header.update.message.stage.done'
      else undefined #aborting

  onResize: () ->
    if (@props.importJob?.jobInProgress && !@props.importJob?.isSilent) || @props.exportInfo?.isExportInProgress
      @recalculateHeaderHeight()

  onBodyClick: (e) ->
    if @state.menu && e.target.closest('.box-user') == null
      @setState menu: false

  componentDidMount: () ->
    @recalculateHeaderHeight()
    window.addEventListener("resize", @onResize)
    document.querySelector('body').addEventListener('click', @onBodyClick)
    API.getBannerMessageSettings (message)=>
      @setState bannerMessage: message
    API.getBannerMessage (message)=>
      if(message.show)
        @setState bannerMessageSecond: message.message


  componentWillUnmount: () ->
    clearTimeout(@importInterval)
    window.removeEventListener("resize", @onResize)
    document.querySelector('body').removeEventListener('click', @onBodyClick)

  componentDidUpdate: (newProps) ->
    @recalculateHeaderHeight()

  recalculateHeaderHeight: () ->
    header = document.querySelector('header')
    document.querySelector('.wrapper .wrapper-content').style.paddingTop = header.offsetHeight + 'px'
    document.querySelector('.container .block-nav').style.paddingTop = header.offsetHeight + 'px'


  getElementStatus: (exportElement) ->
    if exportElement.status == "FINISHED"
      if exportElement.receivers?.length
        @props.t('header.export.email.sent')
      else if exportElement.method == 'FTP'
        @props.t('header.export.ftp.sent')
      else
        @props.t('header.export.ready')
    else if exportElement.status == "WAITING"
      @props.t('header.export.queued')
    else if exportElement.status == "IN_PROGRESS"
      if exportElement.finishingPercent == 0
        return @props.t('header.export.starting')
      @props.t('header.export.generating')
    else if exportElement.status == "SENDING_TO_EMAILS"
      @props.t('header.export.sending')
    else if exportElement.status == "FAILED"
      @props.t('header.export.failed')
    else
      @props.t('header.export.unknown')

  getElementAction: (exportElement) ->
    if exportElement.status == "FINISHED" || exportElement.status == "FAILED"
      if exportElement.receivers?.length
        action = @props.t('header.export.ok')
      else if exportElement.method == 'FTP'
        action = @props.t('header.export.ok')
      else
        action = @props.t('header.export.download')
    else
      action = @props.t('header.export.cancel')
    action

  getActionButtonState: (exportElement) ->
    if exportElement.status == "FINISHED" || exportElement.status == "FAILED"
      if exportElement.receivers?.length
        action = 'ok'
      else if exportElement.method == 'FTP'
        action = 'ok'
      else
        action = 'download'
    else
      action = 'cancel'
    action

  accept: (e) ->
    @props.checkWsConnection(@props.session.token)
    if e.target.name == @props.t('header.export.download')
      createIframeForDownload(API.downloadExport("&exportId=#{e.target.id}"))
    else
      API.acceptExport e.target.id

  doNotShowSlowMapWarning: () ->
    localStorage.setItem('DO_NOT_SHOW_SLOW_MAP_UNTIL', (Date.now() + PERIOD_TO_SHOW_SLOW_MAP_WARNING));
    @forceUpdate()

  getAliasDemoLabel: ()->
    if @props.session?.isAliasEnabled && @props.session?.user?.shadowType == "DEMO"
      return React.createElement(CLabel, {"k": "header.alias.demo.on", "param": ({ lng: 'en' })})
    if @props.session?.isAliasEnabled
      return React.createElement(CLabel, {"k": "header.alias.on", "param": ({ lng: 'en' })})
    return React.createElement(CLabel, {"k": "header.demo.on", "param": ({ lng: 'en' })});

  getPercentage: (exportInfo)->
    if exportInfo.finishingPercent == 0
      return '';
    return '(' + exportInfo.finishingPercent + '%) ';

  onLogout: (session)-> (e) =>
    e.stopPropagation()
    @props.logout(session)

  onUserClick: (e) ->
    e.stopPropagation()
    @setState menu: !@state.menu

  render: () ->
    timeToShowSlowMapWarning = localStorage.getItem('DO_NOT_SHOW_SLOW_MAP_UNTIL')
    timeToShowSlowMapWarning = if timeToShowSlowMapWarning? then timeToShowSlowMapWarning else 0
    React.createElement("div", {"className": "top-content"},
      React.createElement(CPopups, null),
      React.createElement("div", {"className": "bg-background"}),
      React.createElement(Menu, {"className": "show-mobile"}),
      React.createElement("header", {"className": (getEnvStyle().headerclass)},
        React.createElement("div", {"className": "container"},
          React.createElement("div", {"className": "box-right hide-mobile"},
            React.createElement("div", {"className": "box-user", "onClick": (@onUserClick)},
              React.createElement("div", {"className": "box-dropdown"},
                React.createElement("a", {"className": "btn btn-lang href-dropdown", "style": (whiteSpace:"nowrap")},
                  (@props.session?.user?.firstName), " ", (@props.session?.user?.lastName),
                  (if @props.session?.isTwiddlerEnabled
                    React.createElement("span", null, " (", React.createElement(CLabel, {"k": "header.twiddler.on"}), ")")
                  ),
                  (if @props.session?.isAliasEnabled || @props.session?.user?.shadowType == "DEMO"
                    React.createElement("span", null, " (", (@getAliasDemoLabel()), ")")
                  ),
                  React.createElement("i", {"className": "fa fa-angle-down icon-right"})
                ),
                (if @state.menu
                  React.createElement("div", {"className": "menu-dropdown user-nav"},
                    React.createElement("ul", null,
                      React.createElement("li", null, React.createElement(Link, {"to": "/settings", "className": "btn"}, React.createElement(CLabel, {"k": "header.profile"}), " ")),
                      React.createElement("li", null,
                        React.createElement("a", {"className": "btn", "onClick": (@onLogout(@props.session))},
                          (if @props.session?.isAliasEnabled
                            React.createElement(CLabel, {"k": "header.back.to.admin.user"})
                          else
                            React.createElement(CLabel, {"k": "header.log.out"})
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          ),
          React.createElement(LanguageSelectorHeader, null),
          React.createElement("div", {"className": "box-inline hide-mobile"},
            (if @props.backLabel && !@props.quickSearchIsActive && @props.locationHistory.length > 0
              React.createElement(Link, {"onClick": (@props.onBackClick || browserHistory.goBack), "className": "hide-mobile"}, React.createElement("span", {"className": "logo"}))
            else
              React.createElement(Link, {"to": "/", "className": "hide-mobile"}, React.createElement("span", {"className": "logo"}))
            ),
            React.createElement("a", {"className": "btn btn-menu", "onClick": (@props.toggleMainMenu)},
              React.createElement("span", {"className": "icon-bar"}),
              React.createElement("span", {"className": "icon-bar"}),
              React.createElement("span", {"className": "icon-bar"})
            ),
            (if !@props.mainMenuOpened
              React.createElement(Link, {"to": "/home", "style": (fontWeight: 'normal', fontSize: '15px'), "className": "link-home"}, React.createElement(CLabel, {"k": "header.home"}), " ")
            )
          ),
          React.createElement(CQuickFind, null),
          React.createElement("div", {"className": "title-page show-mobile #{if @props.searchFormName then 'search-name-title'}"},
            React.createElement("span", {"style": (display: if @props.quickSearchIsActive then 'none')}, (@props.searchFormName || @props.t(@props.title))),
            (if @props.session?.isAliasEnabled
              React.createElement("span", {"className": "show-mobile alias upper"}, React.createElement(CLabel, {"k": "header.alias.label"}))
            )
          ),
          (if @props.backLabel && !@props.quickSearchIsActive && @props.locationHistory.length > 0
            if @props.router.location.pathname.match(/\/property\/[0-9]*/) || @props.router.location.pathname.match(/\/company\/[0-9A-Z]*/) || (@props.locationHistory?.length > 2 && @props.locationHistory[@props.locationHistory?.length - 2][0]?.pathname?.match(/\/company\/[0-9A-Z]*/))
              React.createElement(Link, {"onClick": (@props.onBackClick || browserHistory.goBack), "className": "btn btn-back show-mobile"}, React.createElement(CLabel, {"className": "upper", "k": 'close'}))
            else if @props.router.location.pathname.match(/\/results/)
              React.createElement(Link, {"onClick": (@props.onBackClick || browserHistory.goBack), "className": "btn btn-back show-mobile"}, React.createElement(CLabel, {"className": "upper", "k": 'back'}))
            else
              React.createElement(Link, {"onClick": (@props.onBackClick || browserHistory.goBack), "className": "btn btn-back show-mobile"}, React.createElement("span", {"className": "logo"}))
          else if !@props.quickSearchIsActive
            if @props.router.location.pathname.match(/\/property\/[0-9]*/)
              React.createElement(Link, {"to": '/', "className": "btn btn-back show-mobile"}, React.createElement(CLabel, {"className": "upper", "k": 'close'}))
            else
              React.createElement(Link, {"to": "/", "className": "show-mobile btn btn-back"}, React.createElement("span", {"className": "logo"}))
          )
        ),
        (if @state.bannerMessage?.message
          React.createElement("div", {"className": "admin-message-banner banner-common", "dangerouslySetInnerHTML": ( __html: @state.bannerMessage?.message )})
        ),
        (if @state.bannerMessageSecond
          React.createElement("div", {"className": "admin-message-banner banner-common", "dangerouslySetInnerHTML": ( __html: @state.bannerMessageSecond )})
        ),

        (if @props.importJob?.jobInProgress && !@props.importJob?.isSilent
          React.createElement("div", {"className": "update-message-banner", "style": (paddingLeft: '20px')},
            React.createElement(CLabel, {"k": (@getImportMessage(@props.importJob))})
          )
        ),

        (if @props.showWarningMessage
          React.createElement("div", {"className": "update-message-banner"}, (@props.showWarningMessage))
        ),

        (if @props.slowMapWarning && timeToShowSlowMapWarning < Date.now()
          React.createElement("div", {"className": "update-message-banner"},
            React.createElement(CLabel, {"k": "header.warning.slow.map"}), " ", React.createElement("a", {"onClick": (@doNotShowSlowMapWarning)},
              React.createElement(CLabel, {"k": "header.doNotShowAgain"})
            )
          )
        ),
        (if @props.exportInfo?.isExportInProgress
          elements = @props.exportInfo.elements
          React.createElement("div", {"className": "export-message-banner"},
            React.createElement("div", {"id": "center", "className": "center"},
            (elements.map (exportInfo, i)=>
              status = @getElementStatus(exportInfo)
              action = @getElementAction(exportInfo)
              state = @getActionButtonState(exportInfo)
              React.createElement("div", {"key": (exportInfo.id), "className": ("export-message-banner-element export-element")},
                ( @props.t('header.export.message', { recordsCount: exportInfo.recordsCount, status: status }) ),
                (if exportInfo.numberInQueue
                  React.createElement("span", null, "(", ('#' + exportInfo.numberInQueue), ") ")
                ),
                (if exportInfo.inProgress
                  React.createElement("span", {"className": 'export-percent', "id": (exportInfo.id)}, (@getPercentage(exportInfo)))
                ), """
                (""", React.createElement("a", {"className": "cancel-export-button", "data-cy-export-btn-state": (state), "onClick": (@accept), "id": (exportInfo.id), "name": (action)}, (action)), """)
""")
            )
            )
          )
        )
      )
    )

),{
dispatch: (dispatch) ->
  logout: (session) -> dispatch(type: 'LOGOUT', payload: session, terminateSessionByUser: true)
  toggleMainMenu: (session) -> dispatch(type: 'TOGGLE_MAIN_MENU', byUser: true)
  checkWsConnection: (token) -> dispatch(type: "CHECK_WS_CONNECTION", token: token)

state: (state) ->
  session: state.session
  importJob: state.app.importJob
  slowMapWarning: state.searchForm.form?.activeView == 'MAP' && state.searchForm.info?.count > MAX_RESULT_FOR_SLOW_WARNING
  showWarningMessage: state.app.showWarningMessage
  exportInfo: state.app.exportInfo
  translation: state.translation
  mainMenuOpened: state.menu.mainMenuOpened
  quickSearchIsActive: state.quickSearchForm.active
  locationHistory: state.app.locationHistory
}
