{ Link } = require 'react-router'
Connect = require '../helpers/Connect'
{ CInput } = require '../cmp/CInput'
CPopups = require '../cmp/popups/CPopups'
{ CLabel } = require '../cmp/CLabel'
CSsoFrame = require '../cmp/CSsoFrame'
{ API } = require '../redux/Store'
Utils = require '../helpers/Utils'
An = require '../helpers/Analytics'
{ getLanguageOptions } = require '../helpers/LanguageOptions'
{ withTranslation } = require 'react-i18next' 
{ getDateLocale } = require "../helpers/LocaleHelpers"
Moment = require "moment"
{ initializeFirebase } = require '../firebase/firebaseConfig'

module.exports = withTranslation() Connect createReactClass(
  displayName: "Login"
  getInitialState: ->
    {bannerMessageSecond: null, changedLang: null, email: "", password: "" }

  login: ()->
    state =
      emailError: null
      passwordError: null
      loginSfcOnly: false
      loginSfcToken: null
      loginSfcFail: false

    if !@state.email
      state.emailError = React.createElement(CLabel, {"k": "login.validation.email.cannot.be.empty"})
    if !@state.password
      state.passwordError = React.createElement(CLabel, {"k": "login.validation.password.cannot.be.empty"})

    @setState state

    if @state.email && @state.password
      token = if Utils.isMobileAgent() then window.deviceToken else undefined
      API.login @state.email, @state.password, token, (session)=>
        session.checkLangAccess = true
        if (session?.user?.language && !@state.changedLang)
          @props.i18n.changeLanguage session.user.language
          document.title = @props.i18n.t(Utils.getEnvStyle().title)
          Moment.locale(getDateLocale(session.user.language))
        if session.token
          if (@state.changedLang)
            session.user.language = @state.changedLang
            Moment.locale(getDateLocale(@state.changedLang))
          @props.login(session)
          if !Utils.isMobileAgent() && Utils.hasFeature(session?.user?.client, "WebPushNotification")
            initializeFirebase();
          if @props.passwordRecoveryPopup
            @props.togglePasswordRecovery()
        else if session.jwtToken
          @setState loginSfcOnly: true, loginSfcToken: session.jwtToken
        else
          @setState emailError:@props.t("login.unexpected.error")
      , (data)=>
        @setState emailError:@props.t("server.errors.#{data.error}"), passwordError:null
      
  onKeyUp: (e) ->
    if e.which == 13
      @login()

  componentDidMount: ()->
    document.addEventListener("keyup", @onKeyUp, false)

    API.getBannerMessage (message)=>
      if(message.show)
        @setState bannerMessageSecond: message.message

  componentWillMount: ()->
    Utils.setBadgeCountOnMobileDevice(0)

  componentWillUnmount: ()->
    document.removeEventListener("keyup", @onKeyUp, false)

  componentWillReceiveProps: (newProps)->
    if newProps.session?.token
      if !newProps.session?.user?.licenceAccepted
        @props.router.push('/login/first')
      else
        @props.router.push('/home')

  languageOptions: ()->
    languageOptions = getLanguageOptions(@props.t, null, true)
    chunkSize = Math.round(languageOptions.length / 2);
    chunks = [];
    for i in [0..languageOptions.length - 1] by chunkSize
      chunks.push(languageOptions.slice(i, i + chunkSize));
    return chunks

  
  moreThan1Lang: ()->
    return @languageOptions().length > 1

  selectLanguage: (value)->()=>
    @props.i18n.changeLanguage(value)
    document.title = @props.t(Utils.getEnvStyle().title)
    @setState({ changedLang: value })

  handleOnEmailChange: (e) ->
    @setState email: e.target.value

  handleOnPasswordChange: (e) ->
    @setState password: e.target.value

  render: ->
    React.createElement("div", {"className": "page-login"},
      React.createElement("div", {"className": "container"},
        React.createElement("div", {"className": "vertical-middle"},
          React.createElement("div", {"className": "box-img"},
            React.createElement("img", {"src": (require "../../assets/img/login.jpg"), "alt": ""})
          ),
          React.createElement("div", {"className": "content-vertical"},
            React.createElement("div", {"className": "box-form"},
              (if @state.bannerMessageSecond
                React.createElement("div", {"className": "admin-message-banner banner-common", "style": (width: "100%", fontSize: "20px", backgroundColor: "#820020", marginBottom: "20px" ), "dangerouslySetInnerHTML": ( __html: @state.bannerMessageSecond )})
              ),
              React.createElement("div", {"className": "box-logo"},
                React.createElement("img", {"src": (require "../../assets/img/LE_Corporate_Logo_Circle.svg"), "alt": ""})
              ),
              React.createElement("form", {"className": "form"},
                React.createElement("div", {"className": "name-form"}, " ", React.createElement(CLabel, {"k": "login.title"}), " "),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CInput, {"name": "email", "onChange": (@handleOnEmailChange), "className": "form-control", "autocomplete": "username", "placeholder": (@props.t("login.your.id"))}),
                  (if @state.emailError
                    React.createElement("div", {"className": "message-error"}, (@state.emailError))
                  )
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CInput, {"name": "password", "onChange": (@handleOnPasswordChange), "type": "password", "autocomplete": "current-password", "className": "form-control", "placeholder": (@props.t("login.password"))}),
                  (if @state.passwordError
                    React.createElement("div", {"className": "message-error"}, (@state.passwordError))
                  )
                ),
                React.createElement("div", {"className": "btn-control forgot-password"},
                  React.createElement("a", {"onClick": (@props.togglePasswordRecovery)}, React.createElement(CLabel, {"k": "login.forgot.password.button"})),
                  React.createElement(CPopups, null)
                ),
                React.createElement("div", {"className": "box-btn"},
                  React.createElement("a", {"onClick": (@login), "className": "btn btn-red btn-full btn-lg btn-login"}, React.createElement(CLabel, {"k": "login.button"}), " ")
                ),
                (if @state.loginSfcOnly
                  React.createElement("div", {"style": (marginTop: "10px", height: "70px")},
                    (if @state.loginSfcFail
                      React.createElement(CLabel, {"style": (color: "#c96259"), "k": "login.sfc.no_account"})
                    else 
                      React.createElement("div", null,
                        React.createElement(CLabel, {"style": (display: "block", width: "100%", textAlign: "center"), "k": "login.sfc.opening"}),
                        React.createElement(CSsoFrame, {"url": (@props.sfcUrl + @state.loginSfcToken), "onAuthFail": (() => @setState loginSfcFail: true)}) 
                      )
                    )
                  )
                )
              )
            )
          )
        ),
        (if @moreThan1Lang() then React.createElement("div", {"className": "language-selector"},
          (@languageOptions().map((chunk) => React.createElement("ul", {"className": "languages"},
            (chunk.map((lang) =>
              React.createElement("li", {"className": (if lang.value == @props.i18n.resolvedLanguage then 'active' else ''), "key": (lang.value), "onClick": (@selectLanguage(lang.value))},
                (lang.label)
              )
            ))
          )))
        ))
      )
    )
),
state: (state)->
  translation: state.translation
  session: state.session
  sfcUrl: state.app?.applicationSettings?.librarySiteSso
  passwordRecoveryPopup: state.popup?.TOGGLE_POPUP_PASSWORD_RECOVERY
dispatch: (dispatch)->
  login: (session, cb)->
    dispatch(type: 'LOGIN', payload: session)
  togglePasswordRecovery: ()->
    dispatch(type: 'TOGGLE_POPUP_PASSWORD_RECOVERY')