{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'


module.exports = Connect createReactClass(
  displayName: "HeaderSimple"

  componentDidMount: () ->
    @recalculateHeaderHeight()

  componentDidUpdate: (newProps) ->
    @recalculateHeaderHeight()

  recalculateHeaderHeight: () ->
    header = document.querySelector('header')
    document.querySelector('.wrapper .wrapper-content')?.style.paddingTop = header.offsetHeight + 'px'
    document.querySelector('.container .block-nav')?.style.paddingTop = header.offsetHeight + 'px'

  getAliasDemoLabel: ()->
    if @props.session?.isAliasEnabled && @props.session?.user?.shadowType == "DEMO"
      return React.createElement(CLabel, {"k": "header.alias.demo.on", "param": ({ lng: 'en' })})
    if @props.session?.isAliasEnabled
      return React.createElement(CLabel, {"k": "header.alias.on", "param": ({ lng: 'en' })})
    return React.createElement(CLabel, {"k": "header.demo.on", "param": ({ lng: 'en' })});

  render: () ->
    React.createElement("div", {"className": "top-content"},
      React.createElement("header", null,
        React.createElement("div", {"className": "container"},
          React.createElement("div", {"className": "box-right"},
            React.createElement("div", {"className": "box-user"},
              React.createElement("div", {"className": "box-dropdown"},
                React.createElement("a", {"className": "btn btn-lang href-dropdown", "style": (whiteSpace:"nowrap")},
                  (@props.session?.user?.firstName), " ", (@props.session?.user?.lastName),
                  (if @props.session?.isAliasEnabled || @props.session?.user?.shadowType == "DEMO"
                    React.createElement("span", null, " (", (@getAliasDemoLabel()), ")")
                  )
                )
              )
            )
          ),
          React.createElement("div", {"className": "box-inline hide-mobile"},
            React.createElement(Link, {"to": "/", "className": "hide-mobile"}, React.createElement("span", {"className": "logo"}))
          ),
          React.createElement(Link, {"to": "/", "className": "show-mobile btn btn-back"}, React.createElement("span", {"className": "logo"})),
          (@props.title && React.createElement("span", {"className": "title"}, (@props.title)))
        )
      )
    )

),{

state: (state) ->
  session: state.session
}
