CSearchFormColumnActivity = require './CSearchFormColumnActivity'
CSearchFormColumnProperty = require './CSearchFormColumnProperty'
CSearchFormColumnCompany = require './CSearchFormColumnCompany'
{ CLabel } = require '../CLabel'

module.exports = createReactClass(
  displayName: "CCompanySearchForm"

  getInitialState: () ->
    portfolioCollapsed: true
    activitiesCollapsed: false

  toggle: (blockName) ->
    if blockName == 'portfolio'
      @setState portfolioCollapsed: !@state.portfolioCollapsed
    else if blockName == 'activity'
      @setState activitiesCollapsed: !@state.activitiesCollapsed

  componentWillReceiveProps: (newProps) ->
    for fieldName in ['showOpenAndOperating', 'showSales', 'showDisstressedAssets', 'showConversions', 'showRenovations', 'showNewConstruction']
      if @props.form[fieldName] && @state.activitiesCollapsed
        @setState activityCollapsed: false

    portfolioColumnFields = [
      'id', 'hotelName', 'address', 'city', 'stateProvince', 'postalCode', 'rooms',
      'market', 'brand', 'tract', 'brandCompany', 'chainScale', 'territory', 'branch',
      'proximityFilter', 'county', 'worldRegion1', 'worldRegion2', 'yearBuilt', 'brandYear',
      'lastRenovationYear', 'lastSold', 'meetingSpace', 'ownerRole', 'brandRole', 'locationType'
    ]
    for fieldName in portfolioColumnFields
      if @props.form[fieldName]?.value && @state.portfolioCollapsed
        @setState portfolioCollapsed: false

  render: () ->
    React.createElement("div", {"className": "box-table"},
      React.createElement(CSearchFormColumnCompany, null,
        React.createElement("div", {"className": "tile-title"},
          React.createElement("div", {"className": "name-block"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.company.company.information"})))
        )
      ),
      React.createElement(CSearchFormColumnActivity, {"className": "search-form-activity-column", "collapsed": (@state.activitiesCollapsed)},
        React.createElement("div", {"className": "tile-title"},
          React.createElement("div", {"className": "box-btn box-right"},
            React.createElement("a", {"onClick": (@toggle.bind @, 'activity'), "className": "btn btn-small btn-slide-form"},
              React.createElement("div", {"className": "box-arrow"},
               (if @state.activitiesCollapsed
                 React.createElement("i", {"className": "fa fa-angle-down"})
               else
                 React.createElement("i", {"className": "fa fa-angle-up"})
               )
              )
            )
          ),
          React.createElement("div", {"className": "name-block"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.activity.label"})))
        ),
        React.createElement("div", {"className": "tile-text hide-mobile"},
          React.createElement(CLabel, {"k": "search.hotel.activity.description"})
        )
      ),

      React.createElement(CSearchFormColumnProperty, {"collapsed": (@state.portfolioCollapsed)},
        React.createElement("div", {"className": "tile-group"},
          React.createElement("div", {"className": "tile-title"},
            React.createElement("div", {"className": "box-btn box-right"},
              React.createElement("a", {"onClick": (@toggle.bind @, 'portfolio'), "className": "btn btn-small btn-slide-form"},
                React.createElement("div", {"className": "box-arrow"},
                  (if @state.portfolioCollapsed
                    React.createElement("i", {"className": "fa fa-angle-down"})
                  else
                    React.createElement("i", {"className": "fa fa-angle-up"})
                  )
                )
              )
            ),
            React.createElement("div", {"className": "name-block"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.portfolio"})))
          )
        )
      )
    )
)