Connect = require '../../helpers/Connect'
{ CLabel } = require "../CLabel"
{ CInput } = require '../CInput'
Moment = require 'moment'
DatePicker = require 'rc-calendar/lib/Picker'
TimePicker = require 'rc-time-picker/lib/Panel'
{ API } = require '../../redux/Store'
{ createIframeForDownload, createCalendar } = require '../../helpers/Utils'
CFilterElement = require '../filters/CFilterElement'
CFilterDropdown = require '../filters/CFilterDropdown'
CAdminAnalyticsActionsGroupedTable = require './CAdminAnalyticsActionsGroupedTable'
CAdminAnalyticsSessionsTable = require './CAdminAnalyticsSessionsTable'
{ debounce } = require "throttle-debounce"
{ withTranslation } = require 'react-i18next' 
{ param } = require '../../helpers/UtilsHelpers'


module.exports = withTranslation() Connect createReactClass(
  displayName: "CAdminAnalytics"

  getInitialState: ->
    clientName: ''
    clientId: ''
    userEmail: ''
    userFirstname: ''
    userLastname: ''
    platform: ''
    activity: ''
    dateFrom: Moment().subtract(1, 'days').tz('UTC').startOf('days').format()
    dateTo: Moment().tz('UTC').format()
    dateLast: Moment().tz('UTC').format()
    page: 0
    pagesTotal: 1
    hasNextPage: false
    groupMode: 'sessions'
    showAlias: true
    showUsers: true
    showApi: true
    tableData: []

  componentDidMount: ->
    @updateActivity(@getFormPage(@state.page))
    document.querySelector('.main-content').addEventListener('scroll', @onScroll)

  componentWillUnmount: ()->
    # @props.setLastScrollPosition(@scrollPosition)
    document.querySelector('.main-content').removeEventListener('scroll', @onScroll)

  onScroll: (event) ->
    scrollPosition = document.querySelector('.main-content').scrollTop
    scrollHeight = document.querySelector('.dashboard').clientHeight - document.querySelector('.main').clientHeight
    
    searchPoint = scrollHeight - scrollHeight * 0.2
    if @state.page < @state.pagesTotal && !@state.lock &&  scrollPosition > searchPoint
      @setState page: @state.page + 1, height: scrollHeight, () -> 
        @updateActivity(@getFormPage(@state.page))

  updateActivity: (form) ->
    @setState lock: true, () =>
      switch @state.groupMode
        when 'sessions' then @props.getAnalyticsSessionsData(form)
        when 'activity' then @props.getAnalyticsData(form)
        when 'group' then @props.getAnalyticsGroupSessionsData(form)

  getForm: ->
    form = {
      clientId: @state.clientId
      userEmail: @state.userEmail
      userFirstname: @state.userFirstname
      userLastname: @state.userLastname
      platform: @state.platform
      activity: @state.activity
      dateFrom: @state.dateFrom
      dateTo: @state.dateTo
      dateFirst: @state.dateFrom
      alias: @state.showAlias
      user: @state.showUsers
      api: @state.showApi
    }

  getFormPage: (page) ->
    @state.page = page
    @state.pagesTotal = Moment(@state.dateTo).diff(Moment(@state.dateFrom), 'days')
    dateFrom = Moment(@state.dateTo).subtract(page + 1, 'days').tz('UTC').startOf('days').format()
    if(Moment(dateFrom).isBefore(Moment(@state.dateFrom)))
      dateFrom = Moment(@state.dateFrom).tz('UTC').startOf('days').format()
    dateTo = Moment(@state.dateTo).subtract(page, 'days').tz('UTC').endOf('days').format()
    form = {
      clientId: @state.clientId
      userEmail: @state.userEmail
      userFirstname: @state.userFirstname
      userLastname: @state.userLastname
      platform: @state.platform
      activity: @state.activity
      dateFrom: dateFrom
      dateTo: dateTo
      dateFirst: @state.dateFrom
      alias: @state.showAlias
      user: @state.showUsers
      api: @state.showApi
    }


  onChangeFilter: (e)->
    @state[e.target.name] = e.target.value
    @setState(@state, () -> @updateActivity(@getFormPage(0)))

  onChangeAlias: (e)->
    @state[e.target.name] = e.target.checked
    @setState(@state,  ()->
      @updateActivity(@getFormPage(0))
    )

  componentWillReceiveProps: (newProps)->
    if !@state.lock
      return

    @setState(lock: false)
    tableData = switch @state?.groupMode || 'sessions'
                  when 'sessions' then newProps.analyticsSessionsData
                  when 'activity' then newProps.analyticsData
                  when 'group'    then newProps.analyticsGroupSessionsData
                  else newProps.analyticsSessionsData

    if tableData && tableData != @state.tableData
      if tableData.length && (tableData[tableData.length - 1].startedAt || tableData[tableData.length - 1].createdAt || tableData[tableData.length - 1][0].createdAt)
        lastDate = tableData[tableData.length - 1].startedAt || tableData[tableData.length - 1].createdAt || tableData[tableData.length - 1][0].createdAt
        page = Moment(@state.dateTo)
          .tz('UTC').startOf('days')
          .diff(Moment(lastDate)
            .tz('UTC').startOf('days'), 'days')
      else 
        page = @state.pagesTotal

      if @state.page > 0
        tableData = @state.tableData.concat(tableData)
      @setState page: page, tableData: tableData, @onScroll

  setGroupSessions: (e)->
    if(e.target.checked) 
      @setState(groupMode: e.target.value, tableData: [],  ()->
        @updateActivity(@getFormPage(0))
      )

  setClientName: (field, _, id, value)->
    @setState(clientName: value, clientId: id?.replace(';', ''), () ->
      if id != value
        @updateActivity(@getFormPage(0))
    )

  setDateFrom: (moment)->
    @setState(dateFrom: moment?.tz('UTC').format(), () ->
      @updateActivity(@getFormPage(0))
    )

  setDateTo: (moment)->
    @setState(dateTo: moment?.tz('UTC').format(), ()->
      @updateActivity(@getFormPage(0))
    )

  calendarPlaceHolder: (name, value)->
    React.createElement("span", {"tabIndex": "0", "className": "pointer"},
      React.createElement(CLabel, {"k": ("admin.tabs.users.analytics.date." + name.substring(4,).toLowerCase())}), " ", React.createElement("i", {"className": "fa fa-fw fa-calendar"}),
      React.createElement("span", null, (if @state[name] then Moment(@state[name]).tz('UTC').format("[MMM-DD]") else React.createElement(CLabel, {"k": ("admin.tabs.users.recent.activity.filter.date." + name.substring(4,).toLowerCase())})))
    )

  calendarPlaceHolderFrom: (value)->
    @calendarPlaceHolder('dateFrom', value)

  calendarPlaceHolderTo: (value)->
    @calendarPlaceHolder('dateTo', value)

  exportActivity: (e)->
    createIframeForDownload(API.exportAnalyticsActivity(param(@getForm())))

  exportSessions: (e)->
    createIframeForDownload(API.exportAnalyticsSessions(param(@getForm())))


  autocompleteClientXhr: (field, cb) ->
    if @state[field]
      API.clientAutocomplete(@state[field], cb)

  autocompleteUserXhr: (field, cb) ->
    if @state[field]
      API.emailAutocomplete(@state[field], cb)

  render: ->
    calendarDateFrom = createCalendar(@state.dateFrom && Moment(@state.dateFrom), @props.t("admin.tabs.users.recent.activity.filter.please.input", { ns: 'internal_ui' }))
    calendarDateTo = createCalendar(@state.dateTo && Moment(@state.dateTo), @props.t("admin.tabs.users.recent.activity.filter.please.input", { ns: 'internal_ui' }))
    React.createElement("div", {"className": "block-tile"},
      React.createElement("div", {"className": "box-tile"},
        React.createElement("div", {"className": "tile-group"},
          React.createElement("form", {"className": "form form-small"},
            React.createElement("div", {"className": "box-control clearfix"},
              React.createElement("div", {"className": "row-half name-block upper", "style": (fontSize: '1em')}, React.createElement(CLabel, {"k": "admin.tabs.users.recent.sessions"})),
              (if @state.groupMode in ['sessions', 'activity']
                React.createElement("div", {"className": "box-right"},
                  React.createElement("a", {"className": "btn link-grey", "onClick": (if @state.groupMode == 'sessions' then @exportSessions else @exportActivity)}, React.createElement("i", {"className": "fa fa-clipboard icon-left"}), React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.export"}))
                )
              )
            ),
            React.createElement("div", {"className": "box-control clearfix"},
              React.createElement("div", {"className": "row-20"},
                React.createElement(DatePicker, { \
                  "animation": "slide-up",  \
                  "calendar": (calendarDateFrom),  \
                  "name": 'dateFrom',  \
                  "value": (@state.dateFrom && Moment(@state.dateFrom).tz('UTC')),  \
                  "onChange": (@setDateFrom)
                },
                  (@calendarPlaceHolderFrom)
                )
              ),
              React.createElement("div", {"className": "row-20", "style": (marginRight: "10px")},
                React.createElement(CFilterElement, { \
                    "type": "text",  \
                    "withLabels": true,  \
                    "autocompleteXhr": (@autocompleteClientXhr.bind @, 'clientName'),  \
                    "onChange": (@setClientName.bind @, 'clientName'),  \
                    "placeholder": (@props.t("admin.tabs.users.analytics.client", { ns: 'internal_ui' })),  \
                    "value": (@state.clientId),  \
                    "label": (@state.clientName),  \
                    "className": "form-control"
                  })
              ),
              React.createElement("div", {"className": "row-20", "style": (marginRight: "3px")}, React.createElement(CInput, {"ref": "filterUserActivity", "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.first.name", { ns: 'internal_ui' })), "name": 'userFirstname', "className": "form-control", "type": 'text', "onChange": (@onChangeFilter), "value": (@state.userFirstname)})),
              React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.last.name", { ns: 'internal_ui' })), "name": 'userLastname', "className": "form-control", "type": 'text', "onChange": (@onChangeFilter), "value": (@state.userLastname)})),
              React.createElement("div", {"className": "clear-left"},
              React.createElement("div", {"className": "row-20"},
                  React.createElement(DatePicker, { \
                    "animation": "slide-up",  \
                    "calendar": (calendarDateTo),  \
                    "name": 'dateTo',  \
                    "value": (@state.dateTo && Moment(@state.dateTo).tz('UTC')),  \
                    "onChange": (@setDateTo)
                  },
                    (@calendarPlaceHolderTo)
                  )
              ),
              React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.platform", { ns: 'internal_ui' })), "name": 'platform', "className": "form-control", "type": 'text', "onChange": (@onChangeFilter), "value": (@state.platform)})),
                React.createElement("div", {"className": "clear-left"},
                  React.createElement("div", {"style": ({marginLeft: '20%'})},
                    React.createElement("div", {"className": "box-checkbox row-40"},
                      React.createElement(CLabel, {"k": "admin.tabs.users.analytics.type.label", "style": (marginRight: "10px")}),
                      React.createElement("input", {"type": "checkbox", "id": "typeUsers", "name": "showUsers", "checked": (@state.showUsers || false), "onChange": (@onChangeAlias)}), React.createElement("label", {"htmlFor": "typeUsers", "style": (marginRight: "10px", paddingLeft: "25px")}, React.createElement(CLabel, {"k": "admin.tabs.users.analytics.type.user"})),
                      React.createElement("input", {"type": "checkbox", "id": "typeAlias", "name": "showAlias", "checked": (@state.showAlias || false), "onChange": (@onChangeAlias)}), React.createElement("label", {"htmlFor": "typeAlias", "style": (marginRight: "10px", paddingLeft: "25px")}, React.createElement(CLabel, {"k": "admin.tabs.users.analytics.type.alias"})),
                      React.createElement("input", {"type": "checkbox", "id": "typeApi", "name": "showApi", "checked": (@state.showApi || false), "onChange": (@onChangeAlias)}), React.createElement("label", {"htmlFor": "typeApi", "style": (marginRight: "10px", paddingLeft: "25px")}, React.createElement(CLabel, {"k": "admin.tabs.users.analytics.type.api"}))
                    ),
                    React.createElement("input", {"type": "radio", "value": "sessions", "checked": (@state.groupMode == "sessions"), "id": "analyticsSessions", "name": "groupBySession", "onChange": (@setGroupSessions)}), React.createElement("label", {"style": (marginRight: "10px"), "htmlFor": "analyticsSessions"}, React.createElement(CLabel, {"k": "admin.tabs.users.analytics.group.sessions"})),
                    React.createElement("input", {"type": "radio", "value": "activity", "checked": (@state.groupMode == "activity"), "id": "analyticsActivity", "name": "groupBySession", "onChange": (@setGroupSessions)}), React.createElement("label", {"style": (marginRight: "10px"), "htmlFor": "analyticsActivity"}, React.createElement(CLabel, {"k": "admin.tabs.users.analytics.group.actions.label"})),
                    React.createElement("input", {"type": "radio", "value": "group", "checked": (@state.groupMode == "group"), "id": "analyticsGrouped", "name": "groupBySession", "onChange": (@setGroupSessions)}), React.createElement("label", {"style": (marginRight: "10px"), "htmlFor": "analyticsGrouped"}, React.createElement(CLabel, {"k": "admin.tabs.users.analytics.group.actions.grouped"}))
                  )
                )
              )
            )
          ),
          (switch @state.groupMode
            when 'sessions' then React.createElement(CAdminAnalyticsSessionsTable, {"tableData": (@state.tableData)})
            when 'activity' then React.createElement(CAdminAnalyticsActionsGroupedTable, {"groupBySession": (false), "tableData": (@state.tableData)})
            when 'group' then React.createElement(CAdminAnalyticsActionsGroupedTable, {"groupBySession": (true), "tableData": (@state.tableData)})
          )
        )
      )
    )
), {
  state: (state)->
    analyticsSessionsData: state.admin.analyticsSessionsData
    analyticsData: state.admin.analyticsData
    analyticsGroupSessionsData: state.admin.analyticsGroupSessionsData
    translation: state.translation

  dispatch: (dispatch)->
    getAnalyticsData: debounce(300, (form)-> dispatch(type: "GET_ANALYTICS", form: form))
    getAnalyticsGroupSessionsData: debounce(300, (form)-> dispatch(type: "GET_ANALYTICS_GROUP_SESSIONS", form: form))
    getAnalyticsSessionsData: debounce(300, (form)-> dispatch(type: "GET_ANALYTICS_SESSIONS", form: form))
}