
{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
CSearchForm = require('../../cmp/search/CSearchForm').default
Utils = require '../../helpers/Utils'
CCount = require '../../cmp/results/CCount'
{ CLabel } = require '../../cmp/CLabel'
{ withTranslation, Trans } = require 'react-i18next' 


module.exports = withTranslation() Connect createReactClass(
  displayName: "CFilterPopup"

  search: ()->
    if(!@props.filterPopup.data.onSearch)
      @props.filterPopup.onSuccessCallback?()
      @props.search()
    else 
      @props.filterPopup.data.onSearch(@props.form)

  onClose: ()->
    @props.setFilterForm(@props.filterPopup.data.originalForm)
    if(!@props.filterPopup.data.onCancel)
      @search()
    else
      @props.filterPopup.data.onCancel()

  onClick: (e) ->
    if e.target.classList.contains('open')
      @onClose()

  componentDidMount: ->
    calendarModalSelector = '.rc-calendar-picker'
    document.querySelector('.modal-content').addEventListener('scroll', (e) =>
      if Utils.getUserAgent() == 'IOS'
        document.querySelector("textarea:focus").blur()
        document.querySelector("input:focus").blur()
    )

    document.querySelector('.modal.open').addEventListener('click', @onClick)

  componentWillUnmount: ->
    document.querySelector('.modal.open').removeEventListener('click', @onClick)

  render: ()->
    React.createElement("div", {"className": "modal open"},
      React.createElement("div", {"className": "modal-header-mobile show-mobile"},
        React.createElement("a", {"className": "btn btn-back close-modal", "onClick": (@onClose)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.cancel"}))),
        React.createElement("div", {"className": "title-page"}, React.createElement(CLabel, {"k": "search.filter.popup.more.filters"}))
      ),
      React.createElement("div", {"className": "modal-dialog modal-lg dofl modal-new"},
        React.createElement("div", {"className": "modal-content"},
          React.createElement("div", {"className": "modal-header hide-mobile"},
            React.createElement("div", {"className": "modal-title with-btn hide-mobile"},
              React.createElement("div", {"className": "box-btn box-right"},
                React.createElement("a", {"className": "btn btn-grey btn-normal close-modal", "onClick": (@onClose)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.cancel"}))), """
                 
""", React.createElement("a", {"className": "btn btn-red btn-normal close-modal", "onClick": (@search)},
                  (if @props.filterPopup.data.successBtnText
                    React.createElement("span", {"className": "upper"}, (@props.filterPopup.data.successBtnText))
                  else React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.show.results"})))
                )
              ),
              React.createElement("div", {"className": "name-block middle box-inline"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.heading.label"})), " ", (if @props.filterPopup.data.count then @props.filterPopup.data.count else React.createElement("span", {"className": "count"}, "(", React.createElement(CCount, null), " ", React.createElement(CLabel, {"k": "search.count.records.found"}), ")")))
            )
          ),
          React.createElement("div", {"className": "modal-text-info show-mobile"},
            React.createElement(CLabel, {"k": "search.heading.description"})
          ),
          React.createElement("div", {"className": "modal-body"},
            React.createElement("div", {"className": "border-rows"},
              React.createElement("div", {"className": "box-table"},
                React.createElement(CSearchForm, {"excludeTab": (@props.filterPopup.data.excludeTab), "hideRestorePreviousForm": (@props.filterPopup.data.hideRestorePreviousForm), "previousSearchKey": (@props.filterPopup.data.previousSearchKey), "defaultValues": (@props.filterPopup.data.defaultValues)})
              )
            )
          ),     
          React.createElement("div", {"className": "modal-footer dark hide-mobile d-flex flex-row flex-wrap justify-content-between align-items-center"},
            React.createElement("div", {"className": "box-result d-flex"},
              (if @props.filterPopup.data.count
                React.createElement("div", {"className": "count"}, (@props.filterPopup.data.count))
              else
                React.createElement(React.Fragment, null,
                  React.createElement("div", {"className": "count"}, React.createElement(CCount, null)),
                  React.createElement("div", null,
                    (if @props.roomsCount then React.createElement(Trans, {"i18nKey": "search.count.records.found.two.line", "values": ({ roomsCount: Utils.formatNumber(@props.roomsCount) })},
                      React.createElement("span", null),
                      React.createElement("span", {"id": "rooms-count-info"})
                    ) else React.createElement(CLabel, {"k": "search.count.records.found"}))
                  )
                )
              )
            ),
            React.createElement("div", {"className": "box-btn"},
              React.createElement("a", {"className": "btn btn-grey btn-normal close-modal", "onClick": (@onClose)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.cancel"}))),
              React.createElement("a", {"className": "btn btn-red btn-normal close-modal", "onClick": (@search)},
                (if @props.filterPopup.data.successBtnText
                  React.createElement("span", {"className": "upper"}, (@props.filterPopup.data.successBtnText))
                else React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.show.results"})))
              )
            )
          ),
          React.createElement("div", {"className": "modal-footer-mobile show-mobile position-relative"},
            React.createElement("a", {"className": "btn btn-red btn-normal close-modal", "onClick": (@search)},
              (if @props.filterPopup.data.successBtnText
                  React.createElement("span", {"className": "upper"}, (@props.filterPopup.data.successBtnText))
              else React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.show.results"}))),
              (if @props.filterPopup.data.count
                  @props.filterPopup.data.count
              else
                React.createElement("div", {"className": "count-result show-mobile"}, React.createElement(CCount, {"className": "count"}), " ", React.createElement(CLabel, {"k": "search.count.records.found"}))
              )
            )
          )
        )
      )
    )
), {
 dispatch: (dispatch) ->
   toggleFilterPopup: () -> dispatch(type: 'TOGGLE_POPUP_FILTER')
   setFilterForm: (form) -> dispatch(type: 'SET_FILTER_FORM', form: form)
   search: () ->
     dispatch(type: 'TOGGLE_POPUP_FILTER')
     dispatch(type: 'NEW_SEARCH')


 state: (state) ->
   form: state.searchForm.form
   count: state.searchForm.info?.count,
   filterPopup: state.popup?.TOGGLE_POPUP_FILTER,
   roomsCount: state.searchForm?.info?.roomsCount && Number.parseInt(state.searchForm.info.roomsCount)
}