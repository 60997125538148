EXPORT_ACTION_DOWNLOAD = 'download'
EXPORT_ACTION_EMAIL = 'email'

Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'
{ isMobile } = require '../../helpers/Utils'
{ API } = require '../../redux/Store'

Utils = require '../../helpers/Utils'
UserUtils = require '../../helpers/UserUtils'
DatePicker = require 'rc-calendar/lib/Picker'
React = require 'react'
Moment = require 'moment'
{ AccessRestriction } = require "../AccessRestriction"
{ withTranslation } = require 'react-i18next' 

module.exports = withTranslation() Connect createReactClass(

  displayName: 'CResultsExportPopup'

  getInitialState: () ->
    dataFormat: Utils.DATA_EXPORT.DATA_FORMAT_CSV
    exportAction: if isMobile() then EXPORT_ACTION_EMAIL else EXPORT_ACTION_DOWNLOAD
    isMobile: isMobile()
    title: ''
    subtitle: ''
    textBody: ''
    showDropdown: false
    includeNotes: false
    includeCompanyIds: false
    dateFormat: 'US'
    timelineExport: false
    timelinePriorDate: undefined
    selectedUsers: []
    approximateFilesize: @props.count && @round((@props.count * @props.exportSizes?.csv) / 1024)
    count: @props.count
    searchForm: @props.searchForm

  componentDidMount: () ->
    @props.checkWsConnection(@props.token)

    API.getClientUsers (data)=>
      @setState clientUsers: data, selectedUsers: (data.filter (el)=> el.id == @props.user.id).map (el)-> el.id

  round: (num)->
    Number(Math.round(num + 'e2')+'e-2')

  setIncludeNotesState: () ->
    @setState includeNotes: !@state.includeNotes

  setIncludeCompanyIds: () ->
    @setState includeCompanyIds: !@state.includeCompanyIds

  setIncludeTimeline: () ->
    @setState timelineExport: !@state.timelineExport

  setTimelinePriorDate: (moment) ->
    @setState timelinePriorDate: moment?.startOf('day').format('YYYY-MM-DD')

  timelineDatePlaceHolder: (value)->
    React.createElement("span", {"className": "pointer"},
      React.createElement("a", {"className": "black timeline-placeholder"}, React.createElement("span", {"className": "fa fa-calendar"}), """
         
""", (if @state.timelinePriorDate then Moment(@state.timelinePriorDate).format("[MMM-DD]") else React.createElement(CLabel, {"k": 'timeline.export.date'}))
      )
    )

  timelineDatesFilter: (moment) ->
    x = !moment?.startOf('day').isSameOrAfter(Moment('2020-02-01T00:00:00'))

  setValue: (field, value) ->
    form = @state
    if field == 'dataFormat'
      form.approximateFilesize = @props.count && @round((@props.count * @props.exportSizes?[value.NAME]) / 1024)
      if value == Utils.DATA_EXPORT.DATA_FORMAT_PDF
        form['includeNotes'] = false
        form['includeCompanyIds'] = false
        form['timelineExport'] = false
    form[field] = value
    @setState form

  toggleDropdown: (e) ->
    !@state.showDropdown && @setState({ searchUsers: null })
    @setState showDropdown: !@state.showDropdown

  searchUsers: (e) ->
    searchValue = e.target.value
    contains = (testString, searchValue)->
      testString?.toLowerCase().indexOf(searchValue?.toLowerCase()) != -1
    @setState searchUsers: @state.clientUsers.filter (user)-> contains(user.firstName, searchValue) || contains(user.lastName, searchValue)

  selectUser: (id) ->
    index = @state.selectedUsers.indexOf(id)
    if index != -1
      @state.selectedUsers.splice(index, 1)
    else
      @state.selectedUsers.push(id)
    @setState selectedUsers: @state.selectedUsers

  clearSelectedUsers: () ->
    @setState selectedUsers: []

  getSelectedUsers: () ->
    @state.selectedUsers.map (userId)=> @state.clientUsers.find (u)-> u.id == userId

  beforeSend: () ->
    @props.toggleResultsExportPopup()
    @props.toggleRenameExportFilename(@state)

  render: () ->
    timelinePriorDateCalendar = Utils.createCalendar(@state.timelinePriorDate && Moment(@state.timelinePriorDate), @props.t("timeline.export.date"), @timelineDatesFilter)
    isPdfDesabled = Utils.DATA_EXPORT.DATA_FORMAT_PDF[if @state.exportAction == EXPORT_ACTION_DOWNLOAD then "DOWNLOAD_LIMIT" else "EMAIL_LIMIT"] < @props.count
    isXlsDesabled = Utils.DATA_EXPORT.DATA_FORMAT_XLS[if @state.exportAction == EXPORT_ACTION_DOWNLOAD then "DOWNLOAD_LIMIT" else "EMAIL_LIMIT"] < @props.count
    exportDisabledByLimits = false
    if @props.user.exportLimit && !@props.session.isAliasEnabled
      exportedCount = 0
      if @props.user.finishedExports
        exportedCount = @props.user.finishedExports
      exportDisabledByLimits = (@props.user.exportLimit - exportedCount) < @props.count
    React.createElement(React.Fragment, null,
      React.createElement("div", {"className": "modal open", "id": "export"},
        React.createElement("div", {"className": "modal-header-mobile show-mobile"},
          React.createElement("a", {"className": "btn btn-back close-modal", "onClick": (@props.toggleResultsExportPopup)}, React.createElement("i", {"className": "fa fa-times"})),
          React.createElement("div", {"className": "title-page"}, React.createElement(CLabel, {"k": 'results.fast.filter.export.search.results'}))
        ),
        React.createElement("div", {"className": "modal-bg"}),
        React.createElement("div", {"className": "modal-dialog"},
          React.createElement("div", {"className": "modal-content"},
            React.createElement("div", {"className": "modal-header hide-mobile"},
              React.createElement("div", {"className": "modal-title"},
                React.createElement("div", {"className": "text-center"}, React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": 'results.fast.filter.export.search.results'}))))
              )
            ),
            React.createElement("div", {"className": "modal-body"},
              React.createElement("form", {"className": "form form-small"},
                React.createElement("div", {"className": "body-group"},
                  React.createElement("div", {"className": "text-center"}, React.createElement("span", {"className": "grey"}, (@props.count), " ", React.createElement(CLabel, {"k": 'results.fast.filter.properties'})))
                ),
                React.createElement("div", {"className": "body-group"},
                  React.createElement("div", {"className": "label-control-upper"}, React.createElement(CLabel, {"k": 'results.fast.filter.data.format'})),
                  React.createElement("div", {"className": "box-control box-double-control"},
                    React.createElement("div", {"className": "clearfix"},
                      React.createElement("div", {"className": "box-control box-checkbox middle"},
                        React.createElement("input", {"type": "radio", "id": "data1", "disabled": (isPdfDesabled), "onChange": (@setValue.bind @, 'dataFormat', Utils.DATA_EXPORT.DATA_FORMAT_PDF), "checked": (@state.dataFormat == Utils.DATA_EXPORT.DATA_FORMAT_PDF), "name": "dataFormat"}), React.createElement("label", {"htmlFor": "data1", "className": (if isPdfDesabled then "disabled" else "")}, React.createElement(CLabel, {"k": 'results.fast.filter.listing.pdf'})),
                        React.createElement("span", {"className": "label-control-small", "style": (color:"#9f9f9f")}, " (", React.createElement(CLabel, {"k": 'results.fast.filter.data.limit', "param": ({ count: Utils.DATA_EXPORT.DATA_FORMAT_PDF[if @state.exportAction == EXPORT_ACTION_DOWNLOAD then "DOWNLOAD_LIMIT" else "EMAIL_LIMIT"] })}), ")"),
                        React.createElement("br", null),
                        React.createElement("input", {"type": "radio", "id": "data2", "disabled": (isXlsDesabled), "name": "dataFormat", "onChange": (@setValue.bind @, 'dataFormat', Utils.DATA_EXPORT.DATA_FORMAT_XLS), "checked": (@state.dataFormat == Utils.DATA_EXPORT.DATA_FORMAT_XLS)}), React.createElement("label", {"htmlFor": "data2", "className": (if isXlsDesabled then "disabled" else "")}, React.createElement(CLabel, {"k": 'results.fast.filter.table.excel'})),
                        React.createElement("span", {"className": "label-control-small", "style": (color:"#9f9f9f")}, "  (", React.createElement(CLabel, {"k": 'results.fast.filter.data.limit', "param": ({ count: Utils.DATA_EXPORT.DATA_FORMAT_XLS[if @state.exportAction == EXPORT_ACTION_DOWNLOAD then "DOWNLOAD_LIMIT" else "EMAIL_LIMIT"] })}), ")"),
                        React.createElement("br", null),
                        React.createElement("input", {"type": "radio", "id": "data3", "name": "dataFormat", "onChange": (@setValue.bind @, 'dataFormat', Utils.DATA_EXPORT.DATA_FORMAT_CSV), "checked": (@state.dataFormat == Utils.DATA_EXPORT.DATA_FORMAT_CSV)}), React.createElement("label", {"htmlFor": "data3"}, React.createElement(CLabel, {"k": 'results.fast.filter.table.csv'})),
                        React.createElement("a", {"className": "btn btn-info tooltip-bottom", "style": (margin: "5px 30px")},
                          React.createElement("span", {"className": "tooltiptext #{if @state.isMobile then 'tooltip-mobile'}"}, React.createElement(CLabel, {"k": 'export.popup.tooltip.description'})), React.createElement("i", {"className": "fa fa-info"})
                        )
                      )
                    )
                  ),
                  (if @state.dataFormat == Utils.DATA_EXPORT.DATA_FORMAT_PDF
                    React.createElement("div", {"className": "box-control box-double-control box-control-top"},
                      React.createElement("div", {"className": "clearfix"},
                        React.createElement("div", {"className": "box-control row-half"}, React.createElement("input", {"style": (lineHeight: '34px'), "type": "text", "onChange": ((e)=> @setValue('title', e.target.value)), "value": (@state.title), "className": "form-control", "placeholder": (@props.t("results.fast.filter.placeholder.title"))})),
                        React.createElement("div", {"className": "box-control row-half"}, React.createElement("input", {"style": (lineHeight: '34px'), "type": "text", "onChange": ((e)=> @setValue('subtitle', e.target.value)), "value": (@state.subtitle), "className": "form-control", "placeholder": (@props.t("results.fast.filter.placeholder.subtitle"))}))
                      )
                    )
                  ),
                  React.createElement("div", {"className": "label-control-upper"}, React.createElement(CLabel, {"k": 'results.fast.filter.options'})),
                  React.createElement("div", {"className": "box-control box-double-control"},
                    React.createElement("div", {"className": "clearfix"},
                      React.createElement("div", {"className": "box-checkbox middle row-half #{if @state.dataFormat == Utils.DATA_EXPORT.DATA_FORMAT_PDF then 'disabled'}", "style": (float: 'none', display: 'inline-block')},
                        React.createElement("input", {"type": "checkbox", "id": "includeNotesAction", "checked": (@state.includeNotes), "onChange": (@setIncludeNotesState)}), React.createElement("label", {"htmlFor": "includeNotesAction", "style": ({ whiteSpace: 'nowrap' })}, React.createElement(CLabel, {"k": "results.fast.filter.options.include.notes"}))
                      )
                    ),
                    React.createElement("div", {"className": "clearfix"},
                      React.createElement("div", {"className": "box-checkbox middle row-half #{if @state.dataFormat == Utils.DATA_EXPORT.DATA_FORMAT_PDF then 'disabled'}", "style": (float: 'none', display: 'inline-block')},
                        React.createElement("input", {"type": "checkbox", "id": "includeCompanyIds", "checked": (@state.includeCompanyIds), "onChange": (@setIncludeCompanyIds)}), React.createElement("label", {"htmlFor": "includeCompanyIds"}, React.createElement(CLabel, {"k": "results.fast.filter.options.include.companyIds"}))
                      )
                    ),
                    React.createElement("div", {"className": "box-control box-checkbox #{if @state.dataFormat == Utils.DATA_EXPORT.DATA_FORMAT_PDF then 'disabled'}"},
                      React.createElement("span", null, React.createElement(CLabel, {"k": 'results.fast.filter.table.dateformat.label'})),
                      React.createElement("input", {"type": "radio", "id": "date_us", "name": "dateFormat", "onChange": (@setValue.bind @, 'dateFormat', 'US'), "checked": (@state.dateFormat == 'US')}), React.createElement("label", {"htmlFor": "date_us", "style": ({margin: '0px 15px 0px'})}, React.createElement(CLabel, {"k": 'results.fast.filter.table.dateformat.us'})),
                      React.createElement("input", {"type": "radio", "id": "date_int", "name": "dateFormat", "onChange": (@setValue.bind @, 'dateFormat', 'International'), "checked": (@state.dateFormat == 'International')}), React.createElement("label", {"htmlFor": "date_int"}, React.createElement(CLabel, {"k": 'results.fast.filter.table.dateformat.international'}))
                    )
                  ),
                  React.createElement("div", {"className": "box-control box-double-control middle box-checkbox #{if @state.dataFormat == Utils.DATA_EXPORT.DATA_FORMAT_PDF then 'disabled'}"},
                    React.createElement("input", {"type": "checkbox", "id": "addTimeline", "onChange": (@setIncludeTimeline)}), React.createElement("label", {"htmlFor": "addTimeline"}, React.createElement(CLabel, {"k": "timeline.export.add"})), """
                     
""", (if @state.timelineExport
                      React.createElement(DatePicker, { \
                        "animation": "slide-up",  \
                        "calendar": (timelinePriorDateCalendar),  \
                        "name": 'timelinePriorDate',  \
                        "value": (@state.timelinePriorDate && Moment(@state.timelinePriorDate)),  \
                        "onChange": (@setTimelinePriorDate)},
                        (@timelineDatePlaceHolder)
                      )
                    )
                  ),
                  React.createElement("div", {"className": "label-control-upper"}, React.createElement(CLabel, {"k": 'results.fast.filter.data.delivery'})),
                  React.createElement("div", {"className": "box-control box-control-top"},
                    React.createElement("div", {"className": "box-checkbox middle"},
                      React.createElement("input", {"type": "radio", "id": "value1", "checked": (@state.exportAction == EXPORT_ACTION_DOWNLOAD), "onChange": (@setValue.bind @, 'exportAction', EXPORT_ACTION_DOWNLOAD), "name": "exportAction"}),
                      React.createElement("label", {"htmlFor": "value1"},
                        React.createElement(CLabel, {"k": 'results.fast.filter.download.properties'})
                      )
                    ),
                    React.createElement("div", {"className": "box-checkbox middle"},
                      React.createElement("input", {"type": "radio", "id": "value3", "checked": (@state.exportAction == EXPORT_ACTION_EMAIL), "onChange": (@setValue.bind @, 'exportAction', EXPORT_ACTION_EMAIL), "name": "exportAction"}), React.createElement("label", {"htmlFor": "value3"}, React.createElement(CLabel, {"k": 'results.fast.filter.email.them.to'}))
                    )
                  ),

                  (if @state.exportAction == EXPORT_ACTION_EMAIL
                    React.createElement("div", null,
                      React.createElement("div", {"className": "box-control box-control-top"},
                        React.createElement("div", {"className": "box-control-dropdown"},
                          React.createElement("div", {"className": "box-dropdown"},
                            React.createElement("a", {"className": "href-dropdown", "onClick": (@toggleDropdown)},
                              React.createElement("div", {"className": "arrow-dropdown"}, React.createElement("div", {"className": "box-icon"}, React.createElement("i", {"className": "fa fa-angle-down"}))),

                              (if @state.selectedUsers.length > 0
                                React.createElement("div", {"className": "form-control active"},
                                  (@getSelectedUsers().map((user, i)->
                                    React.createElement("span", {"key": (i)}, (user.firstName), " ", (user.lastName), ";")
                                  ))
                                )
                              else
                                React.createElement("div", {"className": "form-control not-active"}, React.createElement(CLabel, {"k": 'results.fast.filter.users.all'}))
                              )
                            ),
                            (if @state.showDropdown
                              React.createElement("div", {"className": "menu-dropdown export-popup"},
                                React.createElement("div", {"className": "box-form-search"},
                                  (if @state.isMobile
                                    React.createElement(React.Fragment, null,
                                      (if @state.selectedUsers.length > 0
                                        React.createElement("div", {"className": "form-control mobile-export-recipients"},
                                          (@getSelectedUsers().map((user, i)->
                                            React.createElement("span", {"key": (i)}, (user.firstName), " ", (user.lastName), ";")
                                          ))
                                        )
                                      else
                                        React.createElement("div", {"className": "form-control mobile-export-recipients not-active"}, React.createElement(CLabel, {"k": 'results.fast.filter.users.all'}))
                                      )
                                    )
                                  ),
                                  React.createElement("div", {"className": "box-control"},
                                    React.createElement("div", {"className": "box-icon-control right"},
                                      React.createElement("i", {"className": "fa fa-search"})
                                    ),
                                    React.createElement("input", {"type": "text", "onChange": (@searchUsers), "className": "form-control", "placeholder": (@props.t("results.fast.filter.placeholder.search"))})
                                  ),
                                  React.createElement("div", {"className": "box-btn"},
                                    React.createElement("a", {"className": "btn btn-small", "onClick": (@clearSelectedUsers)}, React.createElement(CLabel, {"k": 'results.fast.filter.clear.selected.items'}))
                                  )
                                ),

                                React.createElement("div", {"className": "scrollable-menu-items"},
                                  ((@state.searchUsers || @state.clientUsers || []).map((user, i)=>
                                    React.createElement("div", {"className": "box-control", "key": (i)},
                                      React.createElement("div", {"className": "box-checkbox middle"},
                                        React.createElement("input", {"type": "checkbox", "onChange": (@selectUser.bind @, user.id), "checked": (user.id in @state.selectedUsers), "id": "email#{i}"}), React.createElement("label", {"htmlFor": "email#{i}"}, (user.firstName), " ", (user.lastName))
                                      )
                                    )
                                  ))
                                ),
                                React.createElement("div", {"className": "box-btn hide-mobile", "style": (bottom: '0', width: '100%')},
                                  React.createElement("a", {"className": "btn btn-red btn-normal btn-full close-dropdown", "onClick": (@toggleDropdown)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": 'results.fast.filter.ok'})))
                                )
                              )
                            )
                          )
                        )
                      ),
                      React.createElement("div", {"className": "box-control box-control-top"},
                        React.createElement("div", {"className": "label-control-upper"}, React.createElement(CLabel, {"k": 'results.fast.filter.text.for.email'})),
                        React.createElement("textarea", {"onChange": ((e)=> @setValue('textBody', e.target.value)), "value": (@state.textBody), "className": "form-control"})
                      )
                    )
                  ),
                  React.createElement("div", {"className": "name-box #{if @state.approximateFilesize > 3 then 'red-text'}"},
                    React.createElement(CLabel, {"k": "results.fast.filter.approximate.file.size"}), " ", (@state.approximateFilesize?.toFixed(1)), """ MB
"""),
                  (if exportDisabledByLimits
                    React.createElement("div", {"className": "name-box red-text"}, """
                      This export would exceed your cumulative export limit. Please contact Lodging Econometrics for assistance.
""")
                  )
                )
              )
            ),
            React.createElement("div", {"className": "modal-footer hide-mobile"},
              React.createElement("div", {"className": "box-btn text-right"},
                React.createElement("a", {"className": "btn btn-grey btn-normal close-modal", "onClick": (@props.toggleResultsExportPopup)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": 'cancel'}))),
                React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn btn-red btn-normal close-modal #{if exportDisabledByLimits then 'disabled unselectable'} #{if !exportDisabledByLimits && @state.exportAction == EXPORT_ACTION_EMAIL && (@state.selectedUsers.length == 0) then 'disabled unselectable'}", "onClick": (@beforeSend)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": (if @state.exportAction == EXPORT_ACTION_DOWNLOAD then 'results.fast.filter.download.label' else 'results.fast.filter.send')}))))
              )
            )
          )
        ),
        React.createElement("div", {"className": "modal-footer-mobile show-mobile"},
          (if @state.showDropdown
            React.createElement("div", {"className": "box-btn"},
              React.createElement("a", {"className": "btn btn-red btn-normal btn-full close-dropdown", "onClick": (@toggleDropdown)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": 'results.fast.filter.ok'})))
            )
          else
            React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn btn-red btn-normal close-modal #{if exportDisabledByLimits then 'disabled unselectable'}", "onClick": (@beforeSend)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": (if @state.exportAction == EXPORT_ACTION_DOWNLOAD then 'results.fast.filter.download.label' else 'results.fast.filter.send')}))))
          )
        )
      )
    )
), {
  dispatch: (dispatch) ->
    toggleResultsExportPopup: () -> dispatch(type: "TOGGLE_POPUP_RESULTS_EXPORT")
    toggleRenameExportFilename: (state) -> dispatch(type: "TOGGLE_POPUP_FILE_RENAME", data: state: state)
    checkWsConnection: (token) -> dispatch(type: "CHECK_WS_CONNECTION", token: token)
  state: (state) ->
    translation: state.translation
    count: state.searchForm.info?.count
    exportLimits: state.app?.exportLimits
    exportSizes: state.app?.exportSizes
    user: state.session?.user
    token: state.session?.token
    session: state.session
    searchForm: state.searchForm.form
}
