Connect = require '../../helpers/Connect'

module.exports = Connect createReactClass(
  displayName: "CSearchContentTab"

  render: ()->
    React.createElement("div", {"className": "box-tab #{if @props.tab == @props.tabName then 'active'}"},
      React.createElement("div", {"className": "border-rows"},
        (@props.children)
      )
    )

), {

  state: (state) ->
    tab: state.searchForm.form.searchType

}