LENAV_QUICK_SEARCH_FORM = "LENAV_QUICK_SEARCH_FORM"

{ extend } = require '../../helpers/UtilsHelpers'

{ debounce } = require "throttle-debounce"

clone = (o) ->
  extend(true, { version: Math.random() }, o)

window.xhrForQuickSearch = null


module.exports = (getStore, getAPI) ->


  startLoading = () ->
    setTimeout () ->
      getStore().dispatch(type: 'START_LOADING')
    , 0

  stopLoading = () ->
    setTimeout () ->
      getStore().dispatch(type: 'STOP_LOADING')
    , 0

  getInitialState = () ->
    extend {}, {
      form:
        term: ''
        properties: true
        companies: false
        contacts: false
        proximity: ''

      result: []
      active: false
    }

  quickSearch = debounce 300, (state) ->
    if (state.form?.term) && (state.form?.companies || state.form?.properties || state.form?.contacts)
      if window.xhrForQuickSearch
        window.xhrForQuickSearch.abort()

      startLoading()
      window.xhrForQuickSearch = getAPI().quickSearch state.form, (result) ->
        getStore().dispatch(type: 'QUICK_SEARCH_SET_RESULT', result: result)
      , (error, status) ->
        if (status != "abort")
          console.warn(arguments)
          getStore().dispatch(type: 'QUICK_SEARCH_SET_RESULT', result: [], totalCount: 0, error: error )
      , () ->
        window.xhrForQuickSearch = null
        stopLoading()
    else
      state.result = []
      getStore().dispatch(type: 'QUICK_SEARCH_SET_RESULT', result: state.result)

  save = (state) ->
    savedState = extend {}, state
    sessionStorage.setItem(LENAV_QUICK_SEARCH_FORM, JSON.stringify(savedState))

  load = () ->
    if sessionStorage.getItem(LENAV_QUICK_SEARCH_FORM)
      state = JSON.parse(sessionStorage.getItem(LENAV_QUICK_SEARCH_FORM))
      state.active = false
      state
    else
      getInitialState()

  (state = load(), action) ->
    if action.type == "QUICK_SEARCH"
      quickSearch(state)
      state

    else if action.type == "QUICK_SEARCH_SET_RESULT"
      state.result = action.result
      save(state)
      clone state

    else if action.type == "SET_QUICK_SEARCH_FORM"
      state.form[action.name] = action.value
      quickSearch(state)
      save(state)
      clone state

    else if action.type == "TOGGLE_QUICK_SEARCH_FORM"
      state.form.properties = false
      state.form.companies = false
      state.form.contacts = false
      state.form[action.name] = !state.form[action.name]
      quickSearch(state)
      save(state)
      clone state

    else if action.type == "RESET_QUICK_SEARCH_FORM"
      state = getInitialState()
      save(state)
      clone state

    else if action.type == "SET_IS_ACTIVE_QUICK_SEARCH"
      state.active = action.active
      clone state

    else
      state
