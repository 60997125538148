Connect = require '../../helpers/Connect'
{ CLabel } = require "../CLabel"
CInput = require '../CInput'
CAdminAutoExportItem = require './CAdminAutoExportItem'
{ API } = require '../../redux/Store'

module.exports = Connect createReactClass(
  displayName: "CAdminAutoExports"

  getInitialState: ->
    autoExports: @props.autoExports

  componentDidMount: ->
    @props.getAutoExports()

  componentWillReceiveProps: (newProps)->
    if newProps.autoExports != @state.autoExports
      @setState autoExports: newProps.autoExports

  addAutoExport: ()->
    @props.saveAutoExport({})

  render: ->
    React.createElement("div", {"className": "block-tile"},
      React.createElement("div", {"className": "box-tile"},
        React.createElement("div", {"className": "tile-group"},
          React.createElement("form", {"className": "form form-small"},
            React.createElement("div", {"className": "box-control clearfix", "key": "add-new"},
              React.createElement("div", {"className": "row"},
                React.createElement("a", {"onClick": (@addAutoExport), "className": "btn upper add-auto-export"})
              )
            ),
            (@state.autoExports?.map (autoExport, idx)->
              React.createElement(CAdminAutoExportItem, Object.assign({},  autoExport, {"key": (autoExport.id)}))
            )
          )
        )
      )
    )
), {
  state: (state)->
    autoExports: state.admin.autoExports
    translation: state.translation
  dispatch: (dispatch)->
    getAutoExports: ()-> dispatch(type: "GET_AUTO_EXPORTS")
    saveAutoExport: (form)-> dispatch(type: "SAVE_AUTO_EXPORT", data: form)
}