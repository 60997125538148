import { useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import Connect from "../../helpers/Connect";
import Const from "../../helpers/Const.coffee";
import { isMobile } from "../../helpers/Utils.cjsx";
import { API } from "../../redux/Store.cjsx";
import { CLabel } from "../CLabel";
import CLoading from "../CLoading";
import { onScrollTooltipPosition } from "../../helpers/UtilsHelpers";
require("./cstats_action.scss")

const EmptyItem = ({ k }) => (<div className="info-count large row-half">
  <div className="no-info"><CLabel k={k} /></div>
</div>)


const Actions = ({ actions, gotoActionsAndNotes }) => {

  const count = actions.thisWeek + actions.overdue

  return (<div className="info-double-count">
    <div className="clearfix">
      {(actions.thisWeek || actions.overdue) &&
        <div className="info-count large row-half" style={{ cursor: "pointer", display: "flex", lineHeight: "inherit" }} onClick={gotoActionsAndNotes}>
          <span data-cy-id="an-stats-counter-due" className={"count" + (count > 99 && " highvalue" || "") }>{count}</span>
          <div className="info">
            <span className="upper"><CLabel k="home.actions.due.this.week" param={{ count }} /></span>
            {actions.overdue &&
              <div data-cy-id="an-stats-counter-overdue" className="include"><CLabel k="home.actions.including.overdue" param={{ count: actions.overdue  }}/></div> || ""}
          </div>
        </div> || <EmptyItem k="home.actions.no.actions.due.this.week" />}
      {actions.nextWeek &&
        <div className="info-count large row-half" style={{ cursor: "pointer", display: "flex", lineHeight: "inherit" }} onClick={gotoActionsAndNotes}>
          <span  data-cy-id="an-stats-counter-next-week"  className="count">{actions.nextWeek}</span>
          <div className="info">
            <span className="upper"><CLabel k="home.actions.due.next.week" param={{ count: actions.nextWeek }} /></span>
          </div>
        </div> || <EmptyItem k="home.actions.no.actions.due.next.week" />}
    </div>
  </div>)
}

export default Connect(({ router, resetNoteTabPosition, initSearchForm, setActionsToDo, onTooltip, showTooltip }) => {

  const [actions, setActions] = useState({});
  const [loading, setLoading] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const setShowLoadingDebounce = debounce(300, setShowLoading);

  useEffect(() => {
    setShowLoadingDebounce(true);
    const onScrollFn = onScrollTooltipPosition("action-tooltip", "action-tooltip-anchor");
    document.querySelector('.main-content').addEventListener('scroll', onScrollFn);
    API.getActionsToDo((data) => {
      setLoading(false)
      setShowLoading(false)
      setShowLoadingDebounce(false)
      setActions(data)
      setActionsToDo(data);
    });
    return () => {
      document.querySelector('.main-content').removeEventListener('scroll', onScrollFn);
    }
  }, []);

  const gotoActionsAndNotes = () => {
    sessionStorage.removeItem(Const.ACTIONS_AND_NOTES_FILTER_STATE)
    resetNoteTabPosition()
    initSearchForm()
    router.push('/notes')
  }

  return (<div className="tile-group">
    <div className="tile-title">
      <div className="box-btn box-right" onClick={isMobile() ? onTooltip : undefined}>
        <a id="action-tooltip-anchor" className="btn btn-info tooltip-top">
          <span id="action-tooltip" className="tooltiptext"><CLabel k="home.actions.info" /></span>
          <i className="fa fa-info"></i>
        </a>
      </div>
      <div className="name-block"><span className="upper bold"><CLabel k="home.actions.title" /></span></div>
    </div>
    {showLoading && <div className="no-info"><CLoading /></div>}
    {!loading && (!actions.nextWeek && !actions.overdue && !actions.thisWeek
      && <div className="no-info"><CLabel k="home.actions.no.actions" /></div>
      || <Actions actions={actions} gotoActionsAndNotes={gotoActionsAndNotes} />)}
  </div>)
}, {
  dispatch: (dispatch) => ({
    initSearchForm: () => dispatch({
      type: 'INIT_SEARCH_FORM',
      searchType: Const.SEARCH_TYPE_PROPERTIES,
      storageKey: "LENAV_PREVIOUS_SEARCH_FORM_AN"
    }),
    resetNoteTabPosition: () => dispatch({ type: 'RESET_NOTE_TABS' }),
    setActionsToDo: (data) => dispatch({ type: 'SET_ACTIONS_TODO', actions: data }) 
  })
})
