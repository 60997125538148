{ CLabel } = require '../../cmp/CLabel'
{ Link } = require 'react-router'
CItemNote = require './CItemNote'
Utils = require '../../helpers/Utils'

#props.notes
#props.maxNotes
#props.onNoteChanged
#props.disableShowMore
#props.showMore
module.exports = createReactClass(
  displayName: "CSectionNotesList"

  getInitialState: () ->
    showMore: @props.showMore || false
    disableShowMore: @props.disableShowMore || false

  toggleShowMore: ->
    @setState showMore: !@state.showMore

  getNotesToShow: (showMore) ->
    sortedNotes = (@props.notes?.sort (a,b)-> b.createdAt - a.createdAt)
    if !showMore then sortedNotes?.filter((e, i) => i < (@props.maxNotes || 1)) else sortedNotes

  render: ()->
    React.createElement(React.Fragment, null,
      React.createElement("div", {"className": "tile-group", "style": (paddingBottom: '0')},
        (currentPropertyId = undefined
        @getNotesToShow(@state.showMore)?.map (note, i)=>
          React.createElement(React.Fragment, {"key": (note.id + note.createdAt + note.updatedAt)},
            (if currentPropertyId != note.propertyId
              currentPropertyId = note.propertyId
              property = note.property
              roles = note.involvedAs
              React.createElement("div", {"className": "notes-hotel-name"},
                React.createElement("div", {"className": ""}, React.createElement(Link, {"className": "bold", "to": ("/property/#{currentPropertyId}")}, (property.name))),
                (if roles?.length
                  React.createElement("div", {"className": "company-role"}, (Utils.getSingleRoleByPriority(roles)), (if roles.length > 1 then "+"))
                )
              )
            ),
            React.createElement(CItemNote, { \
              "note": (note),  \
              "truncated": (!@state.showMore && @props.notes?.length > (@props.maxNotes || 1)),   \
              "onDeleted": (@props.onNoteChanged),   \
              "onChanged": (@props.onNoteChanged)
              })
          )
        )
      ),
      (if !@state.disableShowMore && @props.notes?.length > (@props.maxNotes || 1)
        React.createElement("div", {"className": "tile-bottom"},
          (if @state.showMore
              React.createElement("div", {"className": "box-btn text-center"}, React.createElement("a", {"onClick": (@toggleShowMore), "className": "btn btn-small"}, React.createElement(CLabel, {"k": "property.show.less"})))
            else
              React.createElement("div", {"className": "box-btn text-center"}, React.createElement("a", {"onClick": (@toggleShowMore), "className": "btn btn-small"}, React.createElement(CLabel, {"k": "property.show.more"})))
          )
        )
      )
    )
)
