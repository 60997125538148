Connect = require '../helpers/Connect'
{ CLabel } = require '../cmp/CLabel'
{ Link } = require 'react-router'
{ API } = require '../redux/Store'
{ getLanguageOptions } = require '../helpers/LanguageOptions'
{ withTranslation } = require 'react-i18next'
CFilterDropdown = require '../cmp/filters/CFilterDropdown'
{ getDateLocale } = require "../helpers/LocaleHelpers"
{ extend } = require '../helpers/UtilsHelpers'
Utils = require '../helpers/Utils'
Moment = require "moment"

module.exports = withTranslation() Connect createReactClass(
  displayName: "License"

  getInitialState: ->
    {
      language: @props.i18n.resolvedLanguage
    }

  login: ()->
    isChecked = @refs.cb.checked
    @setState dontAccept:!isChecked
    if isChecked
      API.acceptLicense null, null, ()=>
        @props.acceptLicence()
        @props.router.push('/home')

  logout: ()->
    @props.logout()
    API.logout (session)=>
      @props.router.push('/login')

  onScroll: (e)->
    if e.target.closest('.box-license') == null
      document.querySelector('.box-scroll').scrollTop += e.deltaY || 5


  saveSettings: ()->
    user = extend(@props.user, { language: @state.language })
    API.updateUser user, (user)=>
      @props.setUser(user)


  selectLanguage: (_, value)->
    @props.i18n.changeLanguage(value)
    document.title = @props.t(Utils.getEnvStyle().title)
    Moment.locale(getDateLocale(value))
    @setState({
      language: value
    }, ()=>
      @saveSettings()
    )

  languageOptions: ()->
    return getLanguageOptions(@props.t, @props.client).map((item)=>
      {
        ...item,
        labelSelected: React.createElement("span", null,
          React.createElement("i", {"className": "fa fa-globe"}),
          (item.label) 
        )
      }
    )

  render: ->
    React.createElement("div", {"className": "page-login page-license", "onTouchMove": (@onScroll), "onWheel": (@onScroll)},
      React.createElement("div", {"className": "container"},
        React.createElement("div", {"className": "vertical-middle"},
          React.createElement("div", {"className": "content-vertical"},
            React.createElement("div", {"className": "box-form central"},
              React.createElement("div", {"className": "box-logo"},
                React.createElement("img", {"src": (require "../../assets/img/LE_Corporate_Logo_Circle.svg"), "alt": ""})
              ),
              (@languageOptions().length > 1 && React.createElement("div", {"className": "tile-group"},
                React.createElement("form", {"className": "form form-small"},
                  React.createElement("div", {"className": "box-control"},
                    React.createElement("div", {"className": "box-checkbox settings-language-select"},
                      React.createElement(CFilterDropdown, { \
                        "name": "language",  \
                        "id": "language",  \
                        "className": "text-left auto-export-dropdown",  \
                        "onChange": (@selectLanguage),  \
                        "value": (@props.i18n.resolvedLanguage),  \
                        "nullable": (false),  \
                        "options": (@languageOptions())
                      })
                    )
                  )
                )
              )
              ),
              React.createElement("div", {"className": "name-form small"},
                React.createElement("p", null, " ", React.createElement(CLabel, {"k": "license.welcome"})),
                React.createElement("p", null, " ", React.createElement(CLabel, {"k": "license.use.of.license"}))
              ),
              React.createElement("div", {"className": "box-license"},
                React.createElement("div", {"className": "box-scroll"},
                  React.createElement("div", {"className": "title"},
                    React.createElement("div", {"className": "name-block small text-center"}, React.createElement("span", {"className": "upper"}, " ", React.createElement(CLabel, {"k": "license.end.user.license.agreement"}))),
                    React.createElement("div", {"className": "update"}, " ", React.createElement(CLabel, {"k": "license.last.updated"}), ": October 23, 2017")
                  ),
                  React.createElement("div", {"className": "text"},
                    React.createElement("p", null, """
                      You have been designated as an authorized user (“User”) with permission to access and use the Licensed Database (defined in Section 1 below) under a certain License Agreement entered into by and between Lodging Econometrics (“LE”) and your employer (“Licensee”) (the “License Agreement”).  UNDER THE TERMS OF THE LICENSE AGREEMENT, YOUR ACCESS TO AND USE OF THE LICENSED DATABASE IS SUBJECT TO YOUR AGREEMENT TO ABIDE BY THE TERMS AND CONDITIONS OF THIS END USER LICENSE AGREEMENT.  IF YOU DO NOT AGREE WITH ANY PART OF THIS END USER LICENSE AGREEMENT, DO NOT USE THE LICENSED DATABASE.  RETURN THE LICENSED DATABASE AND ALL RELATED MATERIALS AND DOCUMENTATION TO LE IMMEDIATELY.
"""),

                    React.createElement("div", {"className": "title"},
                      React.createElement("div", {"className": "name-block small text-center"}, React.createElement("span", {"className": "upper"}, " ", React.createElement(CLabel, {"k": "license.end.user.license.terms.and.conditions"})))
                    ),

                    React.createElement("p", null, "1.\t", React.createElement(CLabel, {"k": "license.licensed.database"})),
                    React.createElement("p", null, "2.\t", React.createElement(CLabel, {"k": "license.limited.license"})),
                    React.createElement("p", null, "3.\t", React.createElement(CLabel, {"k": "license.confidentiality"})),
                    React.createElement("p", null, "4.\t", React.createElement(CLabel, {"k": "license.ownership.proprietary.rights"})),
                    React.createElement("p", null, "5.\t", React.createElement(CLabel, {"k": "license.restrictions"})),
                    React.createElement("p", null, "6.\t", React.createElement(CLabel, {"k": "license.responsibilities"})),
                    React.createElement("p", null, "7.\t", React.createElement(CLabel, {"k": "license.disclaimer"})),
                    React.createElement("p", null, "8.\t", React.createElement(CLabel, {"k": "license.limit.of.liability"})),
                    React.createElement("p", null, "9.\t", React.createElement(CLabel, {"k": "license.term.termination"})),
                    React.createElement("p", null, "10. ", React.createElement(CLabel, {"k": "license.password.protection"})),
                    React.createElement("p", null, "11. ", React.createElement(CLabel, {"k": "license.subject.to.license.agreement"}))

                  )
                )
              ),
              React.createElement("form", {"className": "form text-center"},
                React.createElement("div", {"className": "box-control"},
                  React.createElement("div", {"className": "box-checkbox box-inline"},
                    React.createElement("input", {"ref": "cb", "type": "checkbox", "id": "agree"}), React.createElement("label", {"htmlFor": "agree"}, " ", React.createElement(CLabel, {"k": "license.accept.agreement"})),
                    (if @state.dontAccept
                      React.createElement("div", {"className": "message-error"}, " ", React.createElement(CLabel, {"k": "license.validation"}))
                    )
                  )
                ),
                React.createElement("div", {"className": "box-btn"},
                  React.createElement("div", {"className": "clearfix"},
                    React.createElement("div", {"className": "row-half item-btn"},
                      React.createElement("a", {"onClick": (@logout), "className": "btn btn-grey btn-full btn-lg", "type": "submit"}, " ", React.createElement(CLabel, {"k": "license.cancel"}))
                    ),
                    React.createElement("div", {"className": "row-half item-btn"},
                      React.createElement("a", {"onClick": (@login), "to": "/home", "className": "btn btn-red btn-full btn-lg", "type": "submit"}, " ", React.createElement(CLabel, {"k": "license.login"}))
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
),
state: (state)->
  client: state.session?.user?.client
  user: state.session?.user
dispatch: (dispatch)->
  logout: ()-> dispatch(type: 'LOGOUT')
  setUser: (user)-> dispatch(type: 'SET_USER', user: user)
  acceptLicence: ()-> dispatch(type: 'ACCEPT_LICENCE')
