import { Link } from 'react-router'
import Utils from '../../helpers/Utils.cjsx'
import { AccessRestriction } from '../AccessRestriction'
import { CLabel } from '../CLabel'
import CResultAddress from '../results/CResultAddress.cjsx'
import { Trans } from 'react-i18next'
import { useMemo, useState } from 'react'


const ContactPhone = ({ contact, company }) => {
  const phone = contact && contact.phone || company && company.phone
  const fax = contact && contact.fax || company && company.fax

  const data = []
  if (phone && phone.length > 0) {
    data.push(<span key="contact.phone">
      <Trans i18nKey="property.contact.phoneNumber" values={{
        phoneNumber: phone
      }}>
        <span>P: </span>
        <a target="_top" href={"tel:" + Utils.formatPhoneNumber(phone)} className="link link-phone">{{ phone }}</a>
      </Trans>
    </span>)
  }

  if (fax && fax.length > 0) {
    data.push(<span key="contact.fax" className="cell-fax">
      <CLabel k="property.contact.fax" param={{
        faxNumber: contact && contact.fax || company && company.fax
      }} />
    </span>)
  }

  return utilJoin(data, (i) => <br key={"d" + i} />)
}

const ContactName = ({ contact, className }) => {
  const data = []
  if (contact && contact.fullName && contact.fullName.trim()) {
    data.push(contact.fullName)
  }

  if (contact && contact.title && contact.title.trim()) {
    data.push(contact.title)
  }

  return data.length > 0 ? data.map((item, i) => <div key={"d" + i} className={className}>{item}</div>) : <></>;
}

const utilJoin = (arr, delimiter) => {
  return arr.reduce((result, item) => {
    if (delimiter instanceof Function) delimiter = delimiter(result.length)
    return result.length > 0 ? [...result, delimiter, item] : [item]
  }, [])
}

export default ({ role, company, contact, history, roles }) => {
  const [open, setOpen] = useState(false);
  const id = company && company.id || contact && contact.companyId


  const contactsBlockToggle = () => {
    setOpen(!open)
  }

  const address = useMemo(() => {
    const stateProvinceCountries = ['United States', 'India', 'Indonesia', 'China'];
    const parts = [];
    if (company?.city) {
      parts.push(company.city);
    }
    if (company?.stateProvince && stateProvinceCountries.includes(company.country)) {
      parts.push(company.stateProvince);
    }
    if (company?.country) {
      parts.push(company.country);
    }
    return parts.join(', '); 
  }, [company])


  return <div className="box-tile" style={{ minHeight: '100px' }}>
    <div className="tile-group">
      <div className="box-slide-form">
        <div className="tile-title">
          <div className="box-btn box-right">
            {!open && <a className="btn btn-small btn-slide-form" onClick={contactsBlockToggle}><CLabel k="property.show.more" /></a>}
          </div>
          <div className="name-block middle hide-print"><span className="bold"><CLabel k={"property.contact.type.full." + role} /></span></div>
          <div className="name-block middle print-contact-name show-print">
            <span className={`bold ${Utils.getColorByChanges(history, roles[role].companyId) || 'dark-grey'}`}>{company.name && company.name.trim() && company.name}</span>
            <span className="grey-text bold"><CLabel k={"property.contact.type.full." + role} /></span>
          </div>
          <span className="grey-text show-print">{address}</span>
        </div>
        <div className="table table-contact">
          {(contact && (contact.phone || contact.fax) || company && (company.phone || company.fax)) ?
            <>
              <div className='phone-number' style={{ float: "right", lineHeight: "18px", maxWidth: '120px' }}>
                <AccessRestriction>
                  <span className="text-overflow">
                    <ContactPhone contact={contact} company={company} />
                  </span>
                </AccessRestriction>
              </div>
              {/* <div className="clear-left" /> */}
            </> : <div></div>
          }
          <div style={{ lineHeight: "18px", flex: 1 }}>
            <ContactName contact={contact} className={Utils.getColorByChanges(history, roles[role].contactId)} />
            {company && (company.name.trim() || company.notes && company.notes.length > 0) &&
              <div>
                <Link to={"/company/" + id} className={`${Utils.getColorByChanges(history, roles[role].companyId) || 'dark-grey'} font-medium-screen hide-print`}>
                  {company.notes && company.notes.length > 0 &&
                    <i className={"fa fa-file-text-o " + Utils.getNoteStatusColor(company.notes[company.notes.length - 1].dateTo, "green-text")}>&nbsp;</i>
                  }
                  {company.name && company.name.trim() && <span>{company.name}</span>}
                </Link>
              </div>
            }
            {open && <span className="slide-form hide-mobile open">
              <span style={{ verticalAlign: 'top' }}>
                <CResultAddress property={company} />
              </span>
            </span>}
          </div>
        </div>
      </div>
    </div>
  </div >

}