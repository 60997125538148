
{ CLabel } = require '../../CLabel'
Connect = require '../../../helpers/Connect'
Const = require '../../../helpers/Const'
CMultipleDropdown = require '../../filters/CMultipleDropdown'
An = require '../../../helpers/Analytics'
{ isMobile } = require "../../../helpers/Utils"
{ onScrollTooltipPosition } = require '../../../helpers/UtilsHelpers'

managementOpportunitiesForm =
  reportType: "MGTOPPS"
  isOpaqueFilter: true
  managementOpportunities: true

changesReport = (type, isDevOwn, isMgmt) =>
  reportType: type
  propertyChanges: 
    value:
      isToggleable: true
      enabled: true
      daysAgo: 7
      mode: "DAYS"
      values:
        developer: isDevOwn 
        manager: isMgmt

potentialLandsitesForm =
  reportType: "LAND_SITES"
  isOpaqueFilter: true
  keywords:
    value: "'landSite'"
    options: [value: "landSite", label: "Potential Landsite"]


module.exports = Connect createReactClass(
  displayName: "CReports"

  openReport: (activity, form, analytics)->
    @props.setForm(form || includeUnbranded: true, searchType: Const.SEARCH_TYPE_PROPERTIES)
    @props.resetSorting()
    if activity
      @props.toggleBlockOnSearchForm(activity)
    analytics?.call()
    @props.router.push('/results')

  componentDidMount: ->
    onScroll = onScrollTooltipPosition('custom-report-tooltip', 'custom-report-tooltip-anchor')
    document.querySelector('.main-content').addEventListener 'scroll', onScroll

  componentWillUnmount: ->
    onScroll = onScrollTooltipPosition('custom-report-tooltip', 'custom-report-tooltip-anchor')
    document.querySelector('.main-content').removeEventListener 'scroll', onScroll

  render: ->
    if @props.app.mapWizardAvailable || @props.app.reports && Object.keys(@props.app.reports).length > 0
      React.createElement("div", {"className": "block-tile row-half"},
        React.createElement("div", {"className": "box-tile"},
          React.createElement("div", {"className": "tile-title"},
            React.createElement("div", {"className": "box-btn box-right", "onClick": (if isMobile() then @props.onTooltip else undefined)},
              React.createElement("a", {"id": "custom-report-tooltip-anchor", "className": "btn btn-info tooltip-bottom"},
                (if @props.clientType == 'Franchise'
                  React.createElement("span", {"id": "custom-report-tooltip", "className": "tooltiptext"}, React.createElement(CLabel, {"k": "home.reports.franchise.info"}))
                else if @props.clientType == 'OM' || @props.clientType == 'LE'
                  React.createElement("span", {"id": "custom-report-tooltip", "className": "tooltiptext"}, React.createElement(CLabel, {"k": "home.reports.om.info"}))
                else if @props.clientType == 'Vendor'
                  React.createElement("span", {"id": "custom-report-tooltip", "className": "tooltiptext"}, React.createElement(CLabel, {"k": "home.reports.vendor.info"}))
                ),
                React.createElement("i", {"className": "fa fa-info"})
              )
            ),
            React.createElement("div", {"className": "name-block"}, React.createElement("span", {"className": "upper bold"}, " ", React.createElement(CLabel, {"k": "home.custom.reports"})))
          ),
          React.createElement("div", {"className": "tile-table"},
            React.createElement("table", {"className": "table"},
              React.createElement("tbody", null,
                (if @props.app.reports?.hasOwnProperty("NEW_DEVOWN")
                  React.createElement("tr", {"style": (cursor: "pointer"), "onClick": (@openReport.bind @, null, changesReport("NEW_DEVOWN", true), An.reports.opportunities)},
                    React.createElement("td", null, React.createElement(CLabel, {"k": "reports.new.devown"})),
                    React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                  )
                ),
                (if @props.app.reports?.hasOwnProperty("NEW_MGMT")
                  React.createElement("tr", {"style": (cursor: "pointer"), "onClick": (@openReport.bind @, null, changesReport("NEW_MGMT", undefined, true), An.reports.opportunities)},
                    React.createElement("td", null, React.createElement(CLabel, {"k": "reports.new.mgmt"})),
                    React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                  )
                ),
                (if @props.app.reports?.hasOwnProperty("NEW_DEVOWNMGMT")
                  React.createElement("tr", {"style": (cursor: "pointer"), "onClick": (@openReport.bind @, null, changesReport("NEW_DEVOWNMGMT", true, true), An.reports.opportunities)},
                    React.createElement("td", null, React.createElement(CLabel, {"k": "reports.new.devownmgmt"})),
                    React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                  )
                ),
                (if @props.app.reports?.hasOwnProperty("MGTOPPS")
                  React.createElement("tr", {"style": (cursor: "pointer"), "onClick": (@openReport.bind @, null, managementOpportunitiesForm, An.reports.opportunities)},
                    React.createElement("td", null, React.createElement(CLabel, {"k": "reports.sales.management.opportunities"})),
                    React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                  )
                ),
                (if @props.app.reports?.hasOwnProperty("SALES")
                  React.createElement("tr", {"style": (cursor: "pointer"), "onClick": (@openReport.bind @, 'showSales', null, An.reports.sales)},
                    React.createElement("td", null, React.createElement(CLabel, {"k": "reports.sales.transactions"})),
                    React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                  )
                ),
                (if @props.app.reports?.hasOwnProperty("DISTRESSED")
                  React.createElement("tr", {"style": (cursor: "pointer"), "onClick": (@openReport.bind @, 'showDistressedAssets', null, An.reports.distressed)},
                    React.createElement("td", null, React.createElement(CLabel, {"k": "reports.distressed.assets"})),
                    React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                  )
                ),
                (if @props.app.reports?.hasOwnProperty("TARGET")
                  React.createElement("tr", {"style": (cursor: "pointer"), "onClick": (@props.toggleTargetAccountsPopup)},
                    React.createElement("td", null, React.createElement(CLabel, {"k": "reports.target.accounts"})),
                    React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                  )
                ),
                (if @props.app.reports?.hasOwnProperty("LAND_SITES")
                  React.createElement("tr", {"style": (cursor: "pointer"), "onClick": (@openReport.bind @, null, potentialLandsitesForm, An.reports.landsites)},
                    React.createElement("td", null, React.createElement(CLabel, {"k": "reports.potential.landsites"})),
                    React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                  )
                ),
                (if @props.app.mapWizardAvailable
                  React.createElement("tr", {"style": (cursor: "pointer"), "onClick": (@props.toggleMapCompaniesPopup)},
                    React.createElement("td", null, React.createElement(CLabel, {"k": "reports.map.companies"})),
                    React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                  )
                )
              )
            )
          )
        )
      )
    else null
),
dispatch: (dispatch)->
  toggleTargetAccountsPopup: ()-> dispatch(type: 'TOGGLE_POPUP_TARGET_ACCOUNTS')
  toggleMapCompaniesPopup: ()-> dispatch(type: 'TOGGLE_POPUP_MAP_COMPANIES')
  toggleBlockOnSearchForm: (name) -> dispatch(type: 'TOGGLE_BLOCK_ON_SEARCH_FORM', name: name)
  setForm: (form) -> dispatch(type: 'SET_FILTER_FORM', form: form)
  resetSorting: () -> dispatch(type: 'RESET_SORTING')
state: (state)->
  clientType: state.session?.user?.client?.clientType
  app: state.app