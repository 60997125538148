{ Link } = require 'react-router'

Connect = require '../../helpers/Connect'
Utils = require '../../helpers/Utils'


module.exports = Connect createReactClass(
  displayName: "CBreadcrumb"

  render: ()->
    if @props.path
      React.createElement("div", {"className": "nav-breadcrumbs"},
        (@props.path?.split(";;;").map (crumb, i)=>
          React.createElement("span", {"key": (i)},
            (if i > 0
              React.createElement("div", {"className": "sep"}, React.createElement("i", {"className": "fa fa-chevron-right"}))
            ),
            React.createElement("div", {"className": "item-breadcrumb"}, (crumb))
          )
        )
      )
    else
      null
),
state: (state)->
  form: state.searchForm.form