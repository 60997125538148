{ AccessRestriction } = require "../cmp/AccessRestriction"

{ Link } = require 'react-router'
Header = require './parts/Header'
Menu = require('./parts/Menu').default
{ CLabel } = require '../cmp/CLabel'
{API} = require '../redux/Store'
Connect = require '../helpers/Connect'
{ hasFeature } = require '../helpers/UserUtils'
Const = require '../helpers/Const'
An = require '../helpers/Analytics'
{ formatPhoneNumber, generateMailTo, previousUrl } = require '../helpers/Utils'
CPropertyNotes = require '../cmp/results/CPropertyNotes'
CRelatedNotes = require '../cmp/results/CRelatedNotes'
CBackButton = require('../cmp/CBackButton').default
{ withTranslation, Trans } = require 'react-i18next' 
camelCase = require 'lodash.camelcase'

module.exports = withTranslation() Connect createReactClass(
  displayName: 'Company'

  getInitialState: ->
    company: null
    contacts: []
    notesInvolved: null
    notes: []

  componentDidMount: ()->
    @load(@props.params.companyId)
    An.navigate.companyCard()

  load: (companyId)->
    if !companyId
      @props.router.push("/search")
      return

    API.getCompany companyId, (company)=>
      contacts = (company.contacts || []).sort (a, b)->
        a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)
      @setState company: company, contacts: contacts

    @loadNotes(companyId)

    API.getCompanyInvolvedNotes companyId, (notes)=>
      @setState notesInvolved: notes

  loadNotes: (ownerId) -> 
    API.notesCompany ownerId, (notes) => 
      @setState notes: notes


  UNSAFE_componentWillReceiveProps: (newProps)->
    if @props.params.companyId != newProps.params.companyId
      @setState company: null
      @load(newProps.params.companyId)

  onInvolvedNoteChanged: (note)->
    API.getCompanyInvolvedNotes @props.params.companyId, (notes)=>
      @setState notesInvolved: notes

  gotoProperties: ->
    @props.initSearchForm()
    @props.setSearchType(Const.SEARCH_TYPE_PROPERTIES)
    @props.addValueToSearchForm('companyId', @state.company.id)
    @props.router.push("/results")

  goBackTo: ()->
    if @props.result
      @props.router.goBack()
    else
      @props.router.push('/search')

  generatePortfolioListing: ()->
    @props.initSearchForm()
    @props.setForm(isCompanyPortfolio: true, isOpaqueFilter: true)
    @props.addValueToSearchForm("companyId", @state.company.id)
    @props.setActiveCompany(@state.company)
    @props.router.push("/results")

  mobileBlockToggle:(e)->
    e.target.closest('.box-slide-block').querySelector('.slide-block').classList.toggle('open')

  generateMailTo: ()->
    message = @props.t("company.info.inquiry.message", {
      salesFirstName: @props.client?.salesFirstName,
      companyName: @state.company.name,
      companyCity: @state.company.city,
      userName: @props.user.firstName + ' ' + @props.user.lastName,
      clientName: @props.client?.name
    })
    generateMailTo(@props.client?.salesEmail, @props.t("company.info.inquiry.title"), message)

  locateProperty: (e)->
    e?.preventDefault()
    company = @state.company
    if company?.longitude && company?.latitude && company?.longitude != 0 && company?.latitude != 0
      address = [company.latitude, company.longitude].join(',')
    else if company.name && company.country && company.city && company.address
      address = [company.country, company.city, company.address].filter((i)-> i).join(',')
    if address && address!=''
      if window.deviceName == "IOS" || (window.deviceName == "INIT" && getUserAgent() == "IOS")
        location.href = 'maps://?q=' + address
      else if window.deviceName == "ANDROID" || (window.deviceName == "INIT" && getUserAgent() == "ANDROID")
        window.open('geo://?q=' + address)
      else if window.deviceName == "BROWSER" || (window.deviceName == "INIT" && getUserAgent() == "BROWSER")
        window.open('https://www.google.com/maps/?q=' + address)
      else
        console.warn('Cannot detect user agent, opening new link with map')
        window.open('https://www.google.com/maps/?q=' + address)

  mapThisLocation: (className)->
      React.createElement("div", {"className": "line-address #{className}"},
      (if ((@state.company.name && @state.company.address && @state.company.country && @state.company.city)||
          (@state.company.latitude && @state.company.longitude && @state.company.latitude!=0 && @state.company.longitude!=0)) && @props.mapsAvailable
        React.createElement("div", {"className": "box-btn"}, React.createElement("a", {"className": "btn btn-small", "onClick": (@locateProperty)}, React.createElement(CLabel, {"k": "property.map.this.location"})))
      )
      )

  sortNotes: (a, b)->
    if (a.type != b.type)
      return if a.type == "ACTION" then -1 else 1
    if (a.active != b.active)
      return if a.active then -1 else 1
    if (a.type == "NOTE")
      return a.updatedAt - b.updatedAt
    if (!a.active)
      return a.doneAt - b.doneAt
    return a.dateTo - b.dateTo

  onNoteChanged: (note)->
    isNewNote = !Boolean(@state.notes.find((item) => note.id == item.id))
    if (isNewNote)
      @loadNotes(@state.company.id)
      return

    notes = @state.notes.map((item) => 
      if item.id != note.id
        return item
      return note
    )
    @setState({ notes: notes.sort(@sortNotes) })

  onNoteDeleted: (note)->
    notes = @state.notes.filter((item) => 
      if item.id != note.id
        return true
      return false
    )
    @setState({ notes: notes.sort(@sortNotes) })


  render: ->
    React.createElement("div", {"className": "wrapper-content"},
      React.createElement(Header, {"title": "", "backLabel": (@props.route.backLabel), "onBackClick": (@goBackTo)}),
      React.createElement("div", {"className": "main"},
        React.createElement("div", {"className": "main-content"},
          React.createElement("div", {"className": "container"},
            React.createElement(Menu, {"className": "hide-mobile"}),
            React.createElement("div", {"className": "dashboard"},
              React.createElement("div", {"className": "nav-page hide-mobile"},
                React.createElement(CBackButton, null)
              ),
              (if !@state.company
                React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, "\x3CCLabel k=\"loading\"\x3E"))
              else
               React.createElement("div", {"className": "dashboard-group clearfix"},
                  React.createElement("div", {"className": "row-double-three"},
                    React.createElement("div", {"className": "company-card"},
                      React.createElement("div", {"className": "block-tile"},
                        React.createElement("div", {"className": "box-tile"},
                          React.createElement("div", {"className": "tile-group"},
                            React.createElement("div", {"className": "property-details-title"},
                              React.createElement("div", {"className": "property-details-left"},
                                React.createElement("div", {"className": "property-details-name"},
                                  React.createElement("div", {"className": "property-details-name-value"}, (@state.company?.name))
                                )
                              ),
                              React.createElement("div", {"className": "property-details-title-right"},
                                React.createElement("div", {"className": "property-details-id"},
                                  React.createElement("div", {"className": ""}, "ID: ", (@state.company?.id)),
                                  (@state.company?.sourceIdLe && React.createElement("div", {"className": "property-details-id"}, "LE.Internal: ", (@state.company?.sourceIdLe)))
                                )
                              )
                            )
                          ),
                          React.createElement("div", {"className": "tile-group"},
                            React.createElement("table", {"className": "table table-border"},
                              React.createElement("tbody", null,
                              React.createElement("tr", null,
                                React.createElement("td", null,
                                  React.createElement("div", {"className": "label hide-mobile"}, React.createElement(CLabel, {"k": 'company.address'})),
                                  React.createElement("p", null, (@state.company?.address)),
                                  React.createElement("p", null, ([@state.company?.city?.trim(), @state.company?.stateProvince].filter((i)->i).join(', ')), " ", (@state.company?.postalCode)),
                                  React.createElement("p", null, (@state.company?.country)),
                                  (@mapThisLocation()),
                                  (if @state.company?.phone
                                    React.createElement(AccessRestriction, null,
                                      React.createElement("p", null,
                                        React.createElement(Trans, {"i18nKey": "property.contact.phoneNumber", "values": ({
                                          phoneNumber: @state.company?.phone
                                        })},
                                          React.createElement("span", null, "P: "),
                                          React.createElement("a", {"className": "link link-phone", "target": "_top", "href": "tel:#{formatPhoneNumber(@state.company?.phone)}"}, (@state.company?.phone))
                                        )
                                      )
                                    )
                                  )
                                ),
                                React.createElement("td", {"className": "cell-roles hide-mobile"},
                                  React.createElement("div", {"className": "box-inline"},
                                    React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "company.roles"})),
                                    ((@state.company?.roles || []).map (role, i)=> 
                                      React.createElement("p", {"key": (i)}, (@props.t("data.company.roles.#{camelCase(role)}", { ns: "data" })))
                                    )
                                  )
                                ),
                                React.createElement("td", {"className": "cell-size hide-mobile"},
                                  React.createElement("div", {"className": "box-inline"},
                                    React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "company.portfolio.size"})),
                                    React.createElement("p", null, (@props.t("company.property", { count: @state.company?.portfolioSize }))),
                                    React.createElement("p", null, React.createElement("a", {"onClick": (@gotoProperties)}, React.createElement(CLabel, {"k": "company.in.my.territory", "param": ({ count: @state.company?.territoryPortfolioSize })}))),
                                    React.createElement("a", {"className": "link link-grey-small", "onClick": (@generatePortfolioListing)}, React.createElement(CLabel, {"k": "company.generate.portfolio.listing"}))
                                  )
                                )
                              )
                              )
                            )
                          )
                        )
                      ),
                      React.createElement("div", {"className": "block-tile show-mobile"},
                        React.createElement("div", {"className": "box-tile"},
                          React.createElement("div", {"className": "tile-group"},
                            React.createElement("table", {"className": "table table-border no-transform"},
                              React.createElement("tbody", null,
                              React.createElement("tr", null,
                                React.createElement("td", {"className": "cell-roles"},
                                  React.createElement("div", {"className": "box-inline"},
                                    React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "company.roles"})),
                                    ((@state.company?.roles || []).map (role, i)=>
                                      React.createElement("p", {"key": (i)}, (@props.t("data.company.roles.#{camelCase(role)}", { ns: "data" })))
                                    )
                                  )
                                ),
                                React.createElement("td", {"className": "cell-size"},
                                  React.createElement("div", {"className": "box-inline"},
                                    React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "company.portfolio.size"})),
                                    React.createElement("p", null, (@props.t("company.property", { count: @state.company?.portfolioSize }))),
                                    React.createElement("p", null, React.createElement("a", {"onClick": (@gotoProperties)}, React.createElement(CLabel, {"k": "company.in.my.territory", "param": ({ count: @state.company?.territoryPortfolioSize })}))),
                                    React.createElement("a", {"className": "link link-grey-small", "onClick": (@generatePortfolioListing)}, React.createElement(CLabel, {"k": "company.generate.portfolio.listing"}))
                                  )
                                )
                              )
                              )
                            )
                          )
                        )
                      ),
                      (if @state.contacts?.length > 0
                        React.createElement("div", {"className": "block-tile"},
                          React.createElement("div", {"className": "box-tile"},
                            React.createElement("div", {"className": "box-slide-block open"},
                              React.createElement("div", {"className": "tile-group"},
                                React.createElement("div", {"className": "tile-title"},
                                  React.createElement("div", {"className": "box-btn box-right show-mobile"},
                                    React.createElement("a", {"className": "btn btn-small btn-slide-block", "onClick": (@mobileBlockToggle)}, React.createElement("div", {"className": "box-arrow"}, React.createElement("i", {"className": "fa fa-angle-up"})))
                                  ),
                                  (if @props.client?.salesEmail && @props.client?.salesFirstName
                                    React.createElement("div", {"className": "box-btn box-right hide-mobile"},
                                      React.createElement("a", {"href": (@generateMailTo())}, React.createElement("div", {"style": (fontWeight: 'bold')}, React.createElement(CLabel, {"k": "company.request.more.information"})))
                                    )
                                  ),
                                  React.createElement("div", {"className": "name-block middle"},
                                    React.createElement(CLabel, {"k": "company.contacts"})
                                  )
                                )
                              ),
                              React.createElement("div", {"className": "slide-block open"},
                                (@state.company?.contacts.map (it, idx)->
                                  React.createElement("div", {"className": "item-contact", "key": (idx)},
                                    React.createElement("div", {"className": "box-name"}, ([it?.fullName?.trim(), it?.title].filter((i)->i).join(', '))),
                                    React.createElement("div", {"className": "box-phone"},
                                      (if it?.phone then React.createElement(AccessRestriction, null,
                                        React.createElement(Trans, {"i18nKey": "property.contact.phoneNumber", "values": ({
                                          phoneNumber: it?.phone
                                        })},
                                          React.createElement("span", null, "P: "),
                                          React.createElement("a", {"className": "link link-phone", "target": "_top", "href": "tel:#{formatPhoneNumber(it?.phone)}"}, (it?.phone))
                                        )
                                      ))
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    )
                  ),
                  React.createElement("div", {"className": "row-three"},
                      React.createElement("div", {"className": "block-tile"},
                        React.createElement("div", {"className": "box-tile company-notes"},
                          React.createElement("div", {"className": "box-slide-block open"},
                            React.createElement(CPropertyNotes, { \
                              "company": (@state.company),  \
                              "subtitle": (@props.t("company.actions.and.notes.subtitle")),   \
                              "notes": (@state.notes),  \
                              "onNoteChanged": (@onNoteChanged),  \
                              "onNoteDeleted": (@onNoteDeleted),  \
                              "maxNotes": (2)})
                          )
                        )
                      ),
                      React.createElement("div", {"className": "block-tile"},
                        React.createElement("div", {"className": "box-tile company-notes-related"},
                          React.createElement("div", {"className": "box-slide-block open"},
                            React.createElement(CRelatedNotes, {  \
                              "subtitle": (@props.t("company.actions.and.notes.related.subtitle")),   \
                              "notes": (@state.notesInvolved && Object.values(@state.notesInvolved)),   \
                              "maxNotes": (2),   \
                              "onNoteChanged": (@onInvolvedNoteChanged)})
                          )
                        )
                      )
                  )
                )
              )
            )
          )
        )
      )
    )
), {

  state: (state)->
    result: state.searchForm?.result
    client: state.session?.user?.client
    user: state.session?.user
    translation: state.translation
    mapsAvailable: state.session?.user?.client?.maps
    locationHistory: state.app.locationHistory
  dispatch: (dispatch)->
    addValueToSearchForm: (name, value) ->
      dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value)
    setSearchType: (searchType) -> dispatch(type: 'SET_SEARCH_TYPE', searchType: searchType)
    initSearchForm: (form) -> dispatch(type: 'INIT_SEARCH_FORM')
    setForm: (form) -> dispatch(type: 'SET_FILTER_FORM', form: form)
    setActiveCompany: (activeCompany) -> dispatch(type: 'SET_ACTIVE_COMPANY', activeCompany: activeCompany)
}
