Connect = require '../../helpers/Connect'
Const = require '../../helpers/Const'
{ CLabel } = require '../CLabel'
CProximityFilterElement = require '../filters/CProximityFilterElement'

module.exports = Connect createReactClass(

  componentDidMount: ->
    @props.initSearchForm()

  showBlock: (name)->
    @props.toggleBlockOnSearchForm(name)

  putValue: (name, value)->
    @props.addValueToSearchForm(name, value)

  generate: ->
    @props.setSearchType(Const.SEARCH_TYPE_COMPANIES)
    @props.setView('MAP')
    @props.searchMap({}, {})
    @props.togglePopupMapCompanies()
    @props.router.push('/results')

  render: ->
    React.createElement("div", {"className": "modal open"},
      React.createElement("div", {"className": "modal-header-mobile show-mobile"},
        React.createElement("a", {"className": "btn btn-back close-modal", "onClick": (@props.togglePopupMapCompanies)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.custom.popup.close"}))),
        React.createElement("div", {"className": "title-page"}, React.createElement(CLabel, {"k": "reports.map.companies"}))
      ),
      React.createElement("div", {"className": "modal-dialog modal-sm"},
        React.createElement("div", {"className": "modal-content"},
          React.createElement("div", {"className": "modal-header"},
            React.createElement("div", {"className": "modal-title", "style": (textAlign: 'center')},
              React.createElement("div", {"className": "name-block upper bold center"}, React.createElement(CLabel, {"k": "reports.map.companies"}))
            )
          ),
          React.createElement("div", {"className": "modal-body"},
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "label-control-upper"}, React.createElement(CLabel, {"k": "reports.map.companies.select.company.roles"})),
              React.createElement("div", {"className": "box-form-group", "style": (padding: '0 20px')},
                React.createElement("div", {"className": "box-control"},
                  React.createElement("div", {"className": "clearfix"},
                    React.createElement("div", {"className": "box-checkbox middle row-half"},
                      React.createElement("input", {"type": "checkbox", "id": "check21", "checked": (@props.form.isDeveloper || false), "onChange": (@showBlock.bind @, "isDeveloper")}), React.createElement("label", {"htmlFor": "check21"}, React.createElement(CLabel, {"k": "search.company.isDeveloper"}))
                    ),
                    React.createElement("div", {"className": "box-checkbox middle row-half"},
                      React.createElement("input", {"type": "checkbox", "id": "check22", "checked": (@props.form.isManager || false), "onChange": (@showBlock.bind @, "isManager")}), React.createElement("label", {"htmlFor": "check22"}, React.createElement(CLabel, {"k": "search.company.isManager"}))
                    ),
                    React.createElement("div", {"className": "box-checkbox middle row-half"},
                      React.createElement("input", {"type": "checkbox", "id": "check23", "checked": (@props.form.isContractor || false), "onChange": (@showBlock.bind @, "isContractor")}), React.createElement("label", {"htmlFor": "check23"}, React.createElement(CLabel, {"k": "search.company.isContractor"}))
                    ),
                    React.createElement("div", {"className": "box-checkbox middle row-half"},
                      React.createElement("input", {"type": "checkbox", "id": "check24", "checked": (@props.form.isPurchaser || false), "onChange": (@showBlock.bind @, "isPurchaser")}), React.createElement("label", {"htmlFor": "check24"}, React.createElement(CLabel, {"k": "search.company.isPurchaser"}))
                    ),
                    React.createElement("div", {"className": "box-checkbox middle row-half"},
                      React.createElement("input", {"type": "checkbox", "id": "check25", "checked": (@props.form.isArchitect || false), "onChange": (@showBlock.bind @, "isArchitect")}), React.createElement("label", {"htmlFor": "check25"}, React.createElement(CLabel, {"k": "search.company.isArchitect"}))
                    ),
                    React.createElement("div", {"className": "box-checkbox middle row-half"},
                      React.createElement("input", {"type": "checkbox", "id": "check26", "checked": (@props.form.isDesigner || false), "onChange": (@showBlock.bind @, "isDesigner")}), React.createElement("label", {"htmlFor": "check26"}, React.createElement(CLabel, {"k": "search.company.isDesigner"}))
                    )
                  )
                )
              ),
              React.createElement("div", {"className": "label-control-upper"}, React.createElement(CLabel, {"k": "reports.map.companies.specify.office.location"})),
              React.createElement("div", {"className": "box-control", "style": (padding: '0 140px 0 40px')},
                React.createElement(CProximityFilterElement, {"onBlur": (@putValue), "onChange": (@putValue), "name": "companyProximityFilter", "value": (@props.form?.proximityFilter?.value)})
              )
            )
          ),
          React.createElement("div", {"className": "modal-footer hide-mobile"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "box-btn box-right"},
                React.createElement("a", {"className": "btn btn-grey btn-sm close-modal", "onClick": (@props.togglePopupMapCompanies)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "cancel"}))),
                React.createElement("a", {"className": "btn btn-red btn-sm close-modal", "onClick": (@generate)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "reports.generate"})))
              )
            )
          ),
          React.createElement("div", {"className": "modal-footer-mobile show-mobile", "style": (background: "white")},
            React.createElement("a", {"className": "btn btn-red btn-normal close-modal", "onClick": (@generate)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "reports.generate"})))
          )
        )
      )
    )
), {

  dispatch: (dispatch)->
    initSearchForm: ()-> dispatch(type: 'INIT_SEARCH_FORM')
    addValueToSearchForm: (name, value, options) -> dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value, options: options)
    togglePopupMapCompanies: ()-> dispatch(type: 'TOGGLE_POPUP_MAP_COMPANIES')
    toggleBlockOnSearchForm: (name) -> dispatch(type: 'TOGGLE_BLOCK_ON_SEARCH_FORM', name: name)
    setSearchType: (searchType) -> dispatch(type: 'SET_SEARCH_TYPE', searchType: searchType)
    setView: (view) -> dispatch(type: 'SET_VIEW', view: view)
    searchMap: (locationMin, locationMax)-> dispatch(type: 'SEARCH_MAP', locationMin: locationMin, locationMax: locationMax)

  state: (state)->
    form: state.searchForm.form

}