
{ Link } = require 'react-router'
Connect = require '../../../helpers/Connect'
An = require '../../../helpers/Analytics'
{ CLabel } = require '../../CLabel';
{ formatPhoneNumber, isMobile, getNoteStatusColor, getEnvStyle } = require '../../../helpers/Utils'
{ AccessRestriction } = require "../../AccessRestriction"
{ withTranslation, Trans } = require 'react-i18next' 

module.exports = withTranslation() Connect createReactClass(

  displayName: "CQuickFind"

  getInitialState: ->
    showResults: false
    contacts: []
    otherResults: []

  setQuickFindResults: (props)->
    contacts = props.result?.filter (it)-> it.type == 'Contact'
    otherResults = props.result?.filter (it)-> it.type != 'Contact'
    @setState contacts: contacts, otherResults: otherResults

  onKeyUp: (e)->
    if e.which == 27
      document.querySelector('.block-quick-search .box-control .form-control').blur()
      @setState showResults: false
      @props.setIsActive(false)
  

  componentDidMount: ->
    @setQuickFindResults(@props)

    document.querySelector('.block-quick-search .box-control .form-control').addEventListener 'focus', (e)=>
      @props.setIsActive(true)
      @setState showResults: true

    document.body.addEventListener 'keyup', @onKeyUp

  componentWillReceiveProps: (newProps)->
    @setQuickFindResults(newProps)

  componentWillUnmount: ->
    document.body.removeEventListener 'keyup', @onKeyUp

  toggleQuickSearchForm: (name)->
    @props.toggleQuickSearchForm(name)

  toggleShowPopup: (ref)->
    showPopup = if ['none', ''].includes(@refs[ref].querySelector('.box-details').style.display) then true else false
    @refs[ref].querySelector('.box-details').style.display = 'none'
    if showPopup
      An.other.quickFind()
      @refs[ref].querySelector('.box-details').style.display = 'block'


  putValue: (e)->
    @props.setQuickSearchForm(e.target.name, e.target.value)

    type = switch
      when e.target.value.match(/^\d{7}$/) then 'properties'
      when e.target.value.match(/^PR[a-zA-Z]?\d{5,7}$/) then 'properties'
      when e.target.value.match(/^C[a-zA-Z]?\d{5,7}$/) then 'companies'
      when e.target.value.match(/^T[a-zA-Z]?\d{5,7}$/) then 'contacts'

    if type
      @toggleQuickSearchForm(type)
    

  closeQuickSearch: ->
    @props.setIsActive(false)
    @setState showResults: false

  onClear: ->
    @setState showResults: false
    @props.resetForm()

  goToItem: (item)->
    if item.type == 'Property'
      @props.router.push('/property/' + item.id)
    else if item.type == 'Company'
      @props.router.push('/company/' + item.id)
    else if item.type == 'Contact'
      @props.router.push('/company/' + item.companyId)
    @props.setIsActive(false)
    @setState showResults: false

  render: ->
    React.createElement("div", {"className": "block-quick-search box-right #{if @state.showResults then 'open' else ''}"},
      React.createElement("div", {"className": "search-form"},
        React.createElement("form", {"className": "form form-small", "onSubmit": ((e) => e.preventDefault())},
          React.createElement("div", {"className": "box-control"},
            React.createElement("div", {"style": (zIndex: '0'), "className": "box-icon-control left toggle-open"}, React.createElement("i", {"className": "fa fa-search"})),
            React.createElement("input", {"type": "text", "className": ("form-control #{getEnvStyle().headerclass}"), "autoComplete": 'off', "name": 'term', "onChange": (@putValue), "value": (@props.form?.term || ''), "placeholder": (@props.t("quick.find.placeholder"))}),
            (if @props.form?.term && @props.isActive
              React.createElement("i", {"className": "fa close-icon", "onClick": (@onClear)})
            )
          ),
          React.createElement("div", {"className": "box-btn show-mobile"}, React.createElement("a", {"onClick": (@closeQuickSearch), "className": "btn toggle-open"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "cancel"}))))
        )
      ),
      React.createElement("div", {"className": "quick-search-results", "style": (display: if @state.showResults then 'block' else 'none')},
        React.createElement("form", {"className": "form form-small", "onSubmit": ((e) => e.preventDefault())},
          React.createElement("div", {"className": "form-group"},
            React.createElement("div", {"className": "box-control"},
              React.createElement("div", {"className": "clearfix"},
                React.createElement("div", {"className": "row-quarter"},
                  React.createElement("div", {"className": "label-control inline"}, React.createElement(CLabel, {"k": "quick.find.look.for"}))
                ),
                React.createElement("div", {"className": "row-three-quarter"},
                  React.createElement("div", {"className": "box-checkbox middle box-inline"},
                    React.createElement("input", {"type": "radio", "name": "lookFor", "onChange": (@toggleQuickSearchForm.bind @, 'properties'), "id": "check-form1", "checked": (@props.form?.properties)}), React.createElement("label", {"htmlFor": "check-form1"}, React.createElement(CLabel, {"k": "quick.find.properties"}))
                  ),
                  React.createElement("div", {"className": "box-checkbox middle box-inline"},
                    React.createElement("input", {"type": "radio", "name": "lookFor", "onChange": (@toggleQuickSearchForm.bind @, 'companies'), "id": "check-form2", "checked": (@props.form?.companies)}), React.createElement("label", {"htmlFor": "check-form2"}, React.createElement(CLabel, {"k": "quick.find.companies"}))
                  ),
                  React.createElement("div", {"className": "box-checkbox middle box-inline"},
                    React.createElement("input", {"type": "radio", "name": "lookFor", "onChange": (@toggleQuickSearchForm.bind @, 'contacts'), "id": "check-form3", "checked": (@props.form?.contacts)}), React.createElement("label", {"htmlFor": "check-form3"}, React.createElement(CLabel, {"k": "quick.find.contacts"}))
                  )
                )
              )
            ),
            React.createElement("div", {"className": "box-control"},
              React.createElement("div", {"className": "clearfix"},
                React.createElement("div", {"className": "row-quarter"},
                  React.createElement("div", {"className": "label-control inline"}, React.createElement(CLabel, {"k": "quick.find.near"}))
                ),
                React.createElement("div", {"className": "row-three-quarter"},
                  React.createElement("input", {"type": "search", "className": "form-control", "name": 'proximity', "onChange": (@putValue), "value": (@props.form?.proximity), "placeholder": (@props.t("quick.find.address.or.location"))})
                )
              )
            )
          ),
          React.createElement("div", {"className": "form-group"},
            React.createElement("div", {"className": "list-results"},
              React.createElement("div", {"className": "box-scroll"},

                (if @props.loading
                  React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
                else
                  React.createElement("div", null,
                    (if @state.contacts?.length || @state.otherResults?.length
                      [
                        (@state.contacts || []).map (contact, i)=>
                          React.createElement("div", {"className": "result-with-details", "key": (i), "onClick": (@toggleShowPopup.bind @, 'resultWithDetails'+i), "ref": ('resultWithDetails'+i)},
                            React.createElement("a", {"className": "item-result"},
                              React.createElement("div", {"className": "text-overflow"},
                                React.createElement("div", {"className": "box-icon box-inline"}, React.createElement("i", {"className": "fa fa-phone"})),
                                (contact.name?.trim()), (if contact.companyName then ', ' + contact.companyName)
                              )
                            ),
                            React.createElement("div", {"className": "box-details"},
                              React.createElement("div", {"className": "modal-header-mobile show-mobile"},
                                React.createElement("a", {"className": "btn btn-back close-details"}, React.createElement("i", {"className": "fa fa-times"})),
                                React.createElement("div", {"className": "title-page"}, (contact.name))
                              ),
                              React.createElement("div", {"className": "box-info"},
                                React.createElement("div", {"style": (float:"right", lineHeight: "18px")},
                                  (if contact.phone || contact.fax
                                    React.createElement(AccessRestriction, null,
                                        React.createElement("span", {"style": (whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '120px'), "className": "cell-phone vendorContactPhone"},
                                          (if contact.phone
                                            React.createElement(Trans, {"i18nKey": "property.contact.phoneNumber", "values": ({
                                              phoneNumber: contact.phone
                                            })},
                                              React.createElement("span", null, "P: "),
                                              React.createElement("a", {"target": "_top", "href": "tel:#{formatPhoneNumber(contact.phone)}", "className": "link link-phone"}, (contact.phone))
                                              
                                            )
                                          ),
                                          (if contact.phone
                                            React.createElement("br", null)
                                          ),
                                          (if contact.fax
                                            React.createElement("span", {"className": "cell-fax vendorContactFax"},
                                              React.createElement(CLabel, {"k": "property.contact.fax", "param": ({
                                                faxNumber: contact.fax
                                              })})
                                            )
                                          )
                                        )
                                    )
                                  )
                                ),
                                React.createElement("div", {"className": "clear-left"}),  
                                React.createElement("p", {"style": (lineHeight: "18px")},
                                  React.createElement(React.Fragment, null, React.createElement("b", null, (contact.name)), React.createElement("br", null)),
                                  (if contact.contactTitle?.trim()
                                    React.createElement(React.Fragment, null, (contact.contactTitle), React.createElement("br", null))
                                  ),
                                  (if contact.companyName?.trim() || contact.lastAction
                                    React.createElement(React.Fragment, null,
                                      React.createElement("span", {"className": "", "style": (display: 'inline-block', paddingTop: "2px")},
                                        React.createElement(Link, {"style": (color: '#931D3D'), "to": ("/company/#{contact.companyId}")},
                                            (if contact.hasNotes
                                              React.createElement("i", {"className": ("fa fa-file-text-o #{getNoteStatusColor(contact.lastAction, "green-text")}")}, " ")
                                            ),
                                            (if contact.companyName?.trim()
                                              React.createElement("span", null, (contact.companyName))
                                            )
                                          )
                                      ), React.createElement("br", null)
                                    )
                                  )
                                )
                              )
                            )
                          )
                        (@state.otherResults || []).map (item, i)=>
                          React.createElement("div", {"className": "item-result", "key": (i), "onClick": (@goToItem.bind @, item), "style": (cursor: 'pointer')},
                            React.createElement("div", {"className": "text-overflow"},
                              React.createElement("div", {"className": "box-icon box-inline"}, React.createElement("i", {"className": "icon #{if item.type == 'Property' then 'icon-property' else 'icon-related'} small icon-middle"})),
                              ([item.name?.trim(), item.city?.trim(), (if item.country in ['United States', 'Canada'] then item.state else null), item.country].filter((i)->i).join(', '))
                            )
                          )
                      ]
                    else
                      React.createElement("div", {"className": "text-center"}, React.createElement(CLabel, {"k": "no.results"}))
                    )
                  )
                )
              )
            )
          ),
          React.createElement("div", {"className": "form-group quick-find-controls"},
            React.createElement("div", {"className": "box-btn text-right"},
              React.createElement("a", {"style": (width: '100%'), "className": "show-mobile btn btn-red btn-normal", "onClick": (@closeQuickSearch)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "quick.find.done"}))),
              React.createElement("a", {"className": "hide-mobile btn btn-red btn-normal", "onClick": (@closeQuickSearch)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "quick.find.done"})))
            )
          )
        )
      )
    )
), {

  state: (state)->
    form: state.quickSearchForm?.form
    result: state.quickSearchForm?.result
    version: state.quickSearchForm?.version
    isActive: state.quickSearchForm?.active
    translation: state.translation
    loading: state.loader.loading

  dispatch: (dispatch)->
    setQuickSearchForm: (name, value)-> dispatch(type: 'SET_QUICK_SEARCH_FORM', name: name, value: value)
    toggleQuickSearchForm: (name) -> dispatch(type: 'TOGGLE_QUICK_SEARCH_FORM', name: name)
    resetForm: ()-> dispatch(type: 'RESET_QUICK_SEARCH_FORM')
    setIsActive: (active) -> dispatch(type: 'SET_IS_ACTIVE_QUICK_SEARCH', active: active)
}
