import React from 'react';
import { CLabel } from './../CLabel';
import { isMobile } from '../../helpers/Utils';

const CConfirmActionModal = ({ data }) => {
    const { onCancel, onConfirm, title, description } = data;
    return <>
        <div className={"modal modal-action-note-dialog open"}>
            <div className="modal-header-mobile show-mobile">
                <a className="btn btn-back close-modal" onClick={onCancel}>
                    <span className="upper">
                        <CLabel k="settings.custom.popup.close"/>
                    </span>
                </a>
                <div className="title-page">
                    {title}
                </div>
            </div>
            <div className={`modal-dialog ${!isMobile() && 'dialog-desktop'}`}>
                <div className="modal-content">
                    <div className="modal-body">
                        <p>{description}</p>
                    </div>
                    <div className="modal-footer hide-mobile">
                        <div className="clearfix">
                            <div className="box-btn d-flex justify-content-center">
                                <a className="btn btn-grey btn-sm close-modal" onClick={onCancel}><span className="upper"><CLabel k="cancel"/></span></a>
                                <a className={`btn btn-red btn-sm close-modal`} onClick={onConfirm}>
                                    <span className="upper">
                                        <CLabel k="proceed"/>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={`modal-footer-mobile show-mobile`}>
                        <a className="btn btn-grey btn-normal close-modal" onClick={onCancel}><span className="upper"><CLabel k="cancel"/></span></a>
                        <a className={`btn btn-red btn-normal close-modal`} onClick={onConfirm}>
                            <span className="upper">
                                <CLabel k="proceed"/>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default CConfirmActionModal; 