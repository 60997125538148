{ getHistoryColor, getTextWidth } = require '../helpers/Utils'

module.exports = 

    itemInfoTooltip: (object, fieldName, label, minWidth)->
        if object[fieldName]
            values = object[fieldName].split(', ')
            React.createElement("span", {"className": "territory tooltip-bottom", "style": ("display": "block", "cursor": "text")},
                React.createElement("div", {"className": "item-info text-overflow", "style": ("pointerEvents": "none")},
                    React.createElement("div", {"className": "label"}, (label)),
                    React.createElement("span", {"className": "#{getHistoryColor(object, fieldName, 'bold')}"},
                        (if getTextWidth(object[fieldName], "13px normal Helvetica,sans-serif").width > minWidth
                            React.createElement("span", {"className": "tooltiptext #{getHistoryColor(object, fieldName, 'bold')}", "style": ("whiteSpace":"normal")},
                                (values.map (value, idx) -> React.createElement("div", {"key": (idx)}, (value)))
                            )
                        ),
                        (object[fieldName])
                    )
                )
            )