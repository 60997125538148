Connect = require '../helpers/Connect'
{ CLabel } = require './CLabel'
{ API } = require '../redux/Store'
Utils = require '../helpers/Utils'

module.exports = Connect createReactClass(
  displayName: 'CWpSso'

  componentDidMount: () ->
    window.addEventListener 'message', @onAuthMessage, false

  componentWillUnmount: () ->
    @cancel()

  onAuthMessage: (result) -> 
      if(result.data?.auth && result.data?.hasRole)
        @cancel()
        # window.location.href = result.data.redirect
        if(@props.isPopup)
          @props.toggleWpSso()

        window.location.href = @props.url

      else if (result.data?.auth != undefined)
        @cancel()
        message = if (result.data.auth && !result.data.hasRole) then "sfc.sso.popup.failed.landing" else "sfc.sso.popup.failed.account.not.found"
        @setState(message: message, loading: false)

  getInitialState: () ->
    {
      message: "sfc.sso.popup.loading",
      loading: true,
      url: @props.url + '&frame=true'
    }

  componentWillReceiveProps: (newProps)->
    @setState
      url: @props.url + '&frame=true'
    
  cancel: () ->
    @setState
      url: 'about:blank'
    window.removeEventListener 'message', @onAuthMessage

  render: ->
    React.createElement("div", {"className": "modal-dialog modal-sm", "style": (if !@props.isPopup then {"marginTop": "80px"})},
      React.createElement("div", {"className": "modal-content"},
        React.createElement("div", {"className": "modal-body"},
          React.createElement("div", {"className": "box-icon icon-lib-align text-center clearfix"},
            React.createElement("i", {"className": "icon icon-library-big icon-middle margin"})
          ),
          React.createElement("div", {"className": "message-block center clearfix"}, React.createElement(CLabel, {"k": (@state.message)})),
          React.createElement("iframe", {"id": "wp-sfc-sso", "sandbox": "allow-top-navigation allow-scripts allow-popups-to-escape-sandbox", "src": (@state.url), "onLoad": (@onLoad), "style": (display: 'none')})
        ),
        (if @props.isPopup
          React.createElement("div", {"className": "modal-footer hide-mobile"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "box-btn box-right"},
                React.createElement("a", {"className": "btn btn-grey btn-sm close-modal", "onClick": (@props.toggleWpSso)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.custom.popup.close"})))
              )
            )
          )
        )
      )
    )
), {
  state: (state) ->
   translation: state.translation
  dispatch: (dispatch) ->
   toggleWpSso: () -> dispatch(type: 'TOGGLE_POPUP_WP_SSO')
}