import Utils from './Utils'

const languageOptions = (t) => {
  const realm = window.SETTINGS.realms[config.LE_ENV];
  const releasedLanguages = realm.RELEASED_LANGUAGES;
  const languageOptions = [
    {
      label: t('settings.languages.en'),
      value: 'en',
      isTestLanguage: false,
      isDefaultLanguage: true,
      keyword: "Lang_EN"
    },
    {
      label: t('settings.languages.ru'),
      value: 'ru',
      isTestLanguage: releasedLanguages.indexOf('ru') === -1,
      isDefaultLanguage: false,
      keyword: "Lang_RU"
    },
    {
      label: t('settings.languages.fr'),
      value: 'fr',
      isTestLanguage: releasedLanguages.indexOf('fr') === -1,
      isDefaultLanguage: false,
      keyword: "Lang_FR"
    },
    {
      label: t('settings.languages.zh'),
      value: 'zh',
      isTestLanguage: releasedLanguages.indexOf('zh') === -1,
      isDefaultLanguage: false,
      keyword: "Lang_ZH"
    },
    {
      label: t('settings.languages.es'),
      value: 'es',
      isTestLanguage: releasedLanguages.indexOf('es') === -1,
      isDefaultLanguage: false,
      keyword: "Lang_ES"
    },
    {
      label: t('settings.languages.ptbr'),
      value: 'pt-BR',
      isTestLanguage: releasedLanguages.indexOf('pt-BR') === -1,
      isDefaultLanguage: false,
      keyword: "Lang_PTBR"
    },
    {
      label: t('settings.languages.it'),
      value: 'it',
      isTestLanguage: releasedLanguages.indexOf('it') === -1,
      isDefaultLanguage: false,
      keyword: "Lang_IT"
    },
    {
      label: t('settings.languages.hi'),
      value: 'hi',
      isTestLanguage: releasedLanguages.indexOf('hi') === -1,
      isDefaultLanguage: false,
      keyword: "Lang_IT"
    },
    {
      label: t('settings.languages.de'),
      value: 'de',
      isTestLanguage: releasedLanguages.indexOf('de') === -1,
      isDefaultLanguage: false,
      keyword: "Lang_DE"
    },
  ];
  return languageOptions;
}

export const hasAccessToLanguage = (t, lang, client) => {
  let language = lang
  if (typeof lang === 'string') {
    const languages = languageOptions(t)
    language = languages.find((language) => language.value === lang)
  }
  if (language.isTestLanguage) {
    return Utils.hasFeature(client, "TestLanguages") || Utils.hasFeature(client, language.keyword);
  }

  if (language.isDefaultLanguage) {
    return true;
  }

  return Utils.hasFeature(client, "Localization") || Utils.hasFeature(client, language.keyword);
};

export const getLanguageOptions = (t, client, showReleased) => {
    return languageOptions(t).map((language) => {
      return {
        ...language,
        hasAccess: hasAccessToLanguage(t, language, client)
      };
    }).filter((language) => language.hasAccess || (showReleased && !language.isTestLanguage))
}