Utils = require '../../helpers/Utils'
Connect = require '../../helpers/Connect'
{ API } = require '../../redux/Store'
{ CLabel } = require '../../cmp/CLabel'
{ translateDropdownValues } = require './CTranslateUtils.js'
{ withTranslation } = require 'react-i18next' 
camelCase = require 'lodash.camelcase';


module.exports = withTranslation() Connect createReactClass(
  displayName: "CMultipleDropdown"
  xhrForDictionary: null

  getInitialState: ->
    id: "Dropbox_" + Math.random()
    values: @parseValues(@props.values)
    originalValue: @props.values
    placeholder: @props.placeholder
    display: "none"
    valuePrinter: @props.valuePrinter
    options: if @props.name == 'keywords' then @props.options || [] else translateDropdownValues(@props.t, @props.name, @props.options) || []
    loading: @props.loading || false
    filterId: @props.filterId || null
    lng: @props.i18n.resolvedLanguage

  parseValues: (v) ->
    if typeof v == 'string' && v.length > 0 then (v.split(';').map (e) -> Utils.trimQuotes e) else []

  onFocus: (e) ->
    @props.onFocus?(e)

  onPaste: (e) ->
    @props.onPaste?(e)

  onCopy: (e) ->
    @props.onCopy?(e)

  onBlur: (e) ->
    @props.onBlur?(e)

  close: () ->
    document.body.classList.remove("dropdown-open")
    document.querySelector(".search-filter")?.classList.add("filter-fixed-on-mobile")
    @setState display: "none"

  open: () ->
    document.body.classList.add("dropdown-open")
    document.querySelector(".search-filter")?.classList.remove("filter-fixed-on-mobile")
    @setState display: "block"

  updateProjectStage: (newProps) ->
    @xhrForDictionary = API.dictionary @props.dictionaryKey || @props.name, newProps.form, (options) =>
      @setState loading: true
      options = translateDropdownValues(@props.t, @props.name, options)
      if options.length > 0
        options = options.map (o) -> if !o.value then value: o, label: o else o

        values = @parseValues(newProps.form?.projectStage?.value)?.filter((value)->
          found = options.find((option) => option.value == value);
          return Boolean(found)
        ) || []
        @setState {
          loading: false,
          options: if @props.comparator then options.sort (a, b) => @props.comparator?(a.value, b.value) else options,
          values: values
        }
      else
        @setState options: [], loading: false,

  populateOptions: (reloadOptions) ->
    if ((@state.loading || @state.options.length > 0) && !reloadOptions)
      return
    if (@props.name == "keywords")
      @xhrForDictionary = API.getTagsNew (keywords) =>
        @setState loading: true
        options = keywords.map((keyword) => 
          return {
            value: keyword,
            label: keyword
          }
        )
        if options.length > 0
          options = options.map (o) -> if !o.value then value: o, label: o else o
          values = @state.values?.filter((value)->
            found = options.find((option) => option.value == value);
            return Boolean(found)
          ) || []
          @setState {
            loading: false,
            options: if @props.comparator then options.sort (a, b) => @props.comparator?(a.value, b.value) else options,
            values: values
          }
        else
          @setState options: [], loading: false,
      return;
    @xhrForDictionary = API.dictionary @props.dictionaryKey || @props.name, @props.form, (options) =>
      @setState loading: true
      options = translateDropdownValues(@props.t, @props.name, options)
      if options.length > 0
        options = options.map (o) -> if !o.value then value: o, label: o else o
        values = @state.values?.filter((value)->
          found = options.find((option) => option.value == value);
          return Boolean(found)
        ) || []
        @setState {
          loading: false,
          options: if @props.comparator then options.sort (a, b) => @props.comparator?(a.value, b.value) else options,
          values: values
        }
      else
        @setState options: [], loading: false,

  toggle: (e) ->
    e.preventDefault()
    e.stopPropagation()

    open = @state.display == "none"
    if open && @state.options.length == 0 && @props.name || @props.name == 'projectStage'
      @populateOptions()

    if open
      @open()
    else
      @close()

  handleClick: (e) ->
      if @state.display == "block" && (e.target?.className?.includes('mobile-dropdown') || !@refs[@state.id]?.contains(e.target))
        @close()
  
  handleKeyUp: (e) ->
      if @state.display == "block"
        if e.which == 13 || e.which == 27
          @close()

  componentDidMount: () ->
    document.addEventListener("click", @handleClick)
    document.addEventListener("keyup", @handleKeyUp)
      
  componentWillUnmount: () ->
    if @state.display == "block"
      document.body.classList.remove("dropdown-open")
      document.querySelector(".search-filter")?.classList.add("filter-fixed-on-mobile")
    if @xhrForDictionary
      @xhrForDictionary.abort()
    document.removeEventListener("click", @handleClick)
    document.removeEventListener("keyup", @handleKeyUp)

  componentWillReceiveProps: (newProps) ->
    state = {}
    if newProps.i18n.resolvedLanguage != @state.lng && @props.name != 'keywords'
      state.lng = newProps.i18n.resolvedLanguage
      state.options = translateDropdownValues(newProps.t, newProps.name, newProps.options) || []
      @setState state
    if newProps.values != @state.originalValue
      state.values = @parseValues(newProps.values)
      state.originalValue = newProps.values
    if newProps.name == "Excluded" && newProps.params.filterId != @state.filterId
      state.options = newProps.options
      state.filterId = newProps.params.filterId
      @setState state
      return
    if newProps.options != @state.options
      newProps.options?.forEach (opt) =>
        if (@state.options.filter (o) -> o.value == opt.value).length == 0
          @state.options.push(opt)
      state.options = if @props.name == 'keywords' then @state.options || [] else translateDropdownValues(@props.t, @props.name, @state.options) || []
    @setState state
    if newProps.name == 'territory'
      @populateOptions()
    if newProps.name == 'branch'
      @populateOptions()
    if newProps.name == 'chainscale'
      state.values = @parseValues(newProps.form?.projectStage?.value)
      state.originalValue = newProps.form?.projectStage?.value
      @setState state
      @populateOptions()
    if (newProps.name == 'projectStage' and (
      newProps.form?.showConversions or
      newProps.form?.showDistressedAssets or
      newProps.form?.showNewConstruction or
      newProps.form?.showOpenAndOperating or
      newProps.form?.showRenovations or
      newProps.form?.showSales
    ))
      @updateProjectStage(newProps)

  select: (option) ->
    v = @state.values
    if v && option.value in v
      v.splice(v.indexOf(option.value), 1)
    else
      v.push(option.value)
    @setState values: v
    @props.onChange?(@props.name, v, @state.options)

  showValue: () ->
    if @props.name == 'keywords' && @state.values.length == 1 && @props.i18n.resolvedLanguage != 'en'
      labelTranslation = @props.t("data.keywords." + camelCase(@state.values[0]), "", { ns: "data" })
      return React.createElement("span", {"className": "grey bold"},
        (@state.values[0]), " ", (if labelTranslation then React.createElement("span", {"className": "menu-dropdown-label-description"}, "- ", (labelTranslation)))
      )
    if @state.valuePrinter
      @state.valuePrinter(@state.values, @state.options)
    else if @state.options && (@state.options.filter (o) -> o.value).length > 0
      (@state.values.map((v) =>
        value = (@state.options.filter (o) -> o.value == v || "-'" + o.value + "'" == v)[0]?.label || v
        if v.indexOf('-') > -1
          if (@state.options.filter (o) -> "-'" + o.value + "'" == v)[0]?.label
            '-'+value
          else
            value
        else
          value
      )).join("; ")
    else
      @state.values.join("; ")

  modifyOptionLabel: (option, name) ->
    if name == 'territory' && option.allOverlapping
      React.createElement("span", {"className": "gray-text"}, (option.label))
    else
      option.label

  render: ->
    showTranslation = (() =>
      if @props.i18n.resolvedLanguage == 'en'
        return false;
      return @props.name == 'keywords';
    )()
    React.createElement("div", {"className": ("box-control-dropdown #{if @props.className then @props.className else ''}"), "ref": (@state.id)},
      React.createElement("div", {"className": "box-dropdown"},
        React.createElement("a", {"className": "href-dropdown", "id": (@state.id), "onClick": (@toggle), "name": (@props.name)},
          React.createElement("div", {"className": "arrow-dropdown"}, React.createElement("div", {"className": "box-icon"}, React.createElement("i", {"className": "fa fa-angle-down"}))),
          React.createElement("div", {"className": "change-value"},
            (if @state.values.length == 0
              React.createElement("div", {"className": "form-control not-active"}, (@props.placeholder))
            else if @state.loading
              React.createElement("div", {"className": "form-control not-active"},
                React.createElement("div", {"className": "loader loader-white-bg loader-small"})
              )
            else
              React.createElement("div", {"className": "form-control"}, (@showValue()))
            )
          )
        ),
        React.createElement("div", {"className": "menu-dropdown #{if @state.display == 'block' then 'mobile-dropdown' else ''}", "style": (display: @state.display, overflowY: "none")},
          React.createElement("div", {"className": "menu-dropdown-list"},
            ((@state.options || []).map (option, i) =>
              if option
                labelTranslation = @props.t("data.keywords." + camelCase(option.label), "", { ns: "data" })
                React.createElement("div", {"className": "box-control", "key": (i)},
                  React.createElement("div", {"className": "box-checkbox middle aligned xl"},
                    React.createElement("input", {"onChange": (@select.bind @, option), "checked": (@state.values && option.value in @state.values), "type": "checkbox", "id": ("dropdown_#{@props.name}_#{i}")}),
                    React.createElement("label", {"style": (width: '100%'), "htmlFor": ("dropdown_#{@props.name}_#{i}")},
                      (@modifyOptionLabel(option, @props.name)),
                      (if showTranslation && labelTranslation then React.createElement("span", {"className": "menu-dropdown-label-description"}, " (", (labelTranslation), ")"))
                    )
                  )
                )
            )
          ),
          React.createElement("div", {"className": "box-btn", "onClick": (@toggle)},
            React.createElement("a", {"className": "btn btn-red btn-normal btn-full close-dropdown"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "done"})))
          )
        )
      )
    )
), {
  state: (state) ->
    form: state.searchForm.form
    translation: state.translation
}
