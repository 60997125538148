
const roleInfo = (companyId, contact, contactId) =>
    ({ companyId: companyId, contact: contact, contactId: contactId })

const companyInfo = (role, company, contact, history) =>
    ({ role: role, company: company, contact: contact, history: history })

export const roles = {
    developer: roleInfo('devOwnCompanyIdUpdatedAt', 'developerContact', 'devOwnContactIdUpdatedAt'),
    manager: roleInfo('managementCompanyIdUpdatedAt', 'managementContact', 'managementContactIdUpdatedAt'),
    architect: roleInfo('architectIdUpdatedAt', 'architectContact', 'architectContactIdUpdatedAt'),
    designer: roleInfo('designerIdUpdatedAt', 'designerContact', 'designerContactIdUpdatedAt'),
    contractor: roleInfo('contractorIdUpdatedAt', 'contractorContact', 'contractorContactIdUpdatedAt'),
    purchaser: roleInfo('purchaserIdUpdatedAt', 'purchaserContact', 'purchaserContactIdUpdatedAt'),
    buyer: roleInfo('buyerIdUpdatedAt', 'buyerContact', 'buyerContactIdUpdatedAt'),
    seller: roleInfo('sellerIdUpdatedAt', 'sellerContact', 'sellerContactIdUpdatedAt'),
    lender: roleInfo('lenderIdUpdatedAt', 'lenderContact', 'lenderContactIdUpdatedAt'),
    servicer: roleInfo('servicerIdUpdatedAt', 'servicerContact', 'servicerContactIdUpdatedAt'),
    receiver: roleInfo('receiverIdUpdatedAt', 'receiverContact', 'receiverContactIdUpdatedAt'),
}

export const getRolesInfo = (property, isHistory) => {
    const searchItems = [property]
    if (property.projects && property.projects.length) {
        searchItems.push(...property.projects)
    }

    const rolesInfo = Object.keys(roles)
        .flatMap(role => searchItems.filter(item => item[role] || item[roles[role].contact])
            .map(item => (companyInfo(role, item[role], item[roles[role].contact], isHistory ? item : item.history))))
        .filter(x => x)

    return rolesInfo;
}
