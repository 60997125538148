import CFilters from "../cmp/results/CFilters";
import { CLabel } from "../cmp/CLabel";

export default function CSearchFilter({
  fields,
  searchType,
  onSearch,
  onShowFilters,
  onClearFilters,
  isMobile = false,
}) {
  return <div className="dofl">
    <div className={`d-flex justify-content-between ${isMobile ? 'align-items-space-between flex-wrap' : 'align-items-center'}`}>
      <div className="filters-wrapper">
        <CFilters {... { fields, searchType, onSearch }} />
      </div>
      <div style={{ lineHeight: "20px", whiteSpace: "nowrap", textAlign: "right", paddingLeft: 15, ...(isMobile && { marginLeft: 'auto'}) }} >
        <a onClick={onShowFilters}><CLabel k="actions.and.notes.filter.search.filters.more" /></a><br />
        <a onClick={onClearFilters}><CLabel k="actions.and.notes.filter.search.filters.remove" /></a><br />
      </div>
    </div >
  </div >
}