{ CLabel } = require '../../CLabel'
Moment = require 'moment'

WINDOW_LIMIT = 365

CHANGE_TYPES = {
    PROPERTY_PROJECT_UPDATES: {
        dropdownValue: "PROPERTY_PROJECT_UPDATES",
        isProjectAndPropertyType: true,
        propertyNames: ["updated", "interestingOnly"],
    },
    PROPERTY_DETAILS: {
        dropdownValue: "PROPERTY_DETAILS",
        isProjectType: false,
        propertyName: "details"
    },
    NEW_PIPELINE: {
        dropdownValue: "NEW_PIPELINE",
        propertyName: "propertyType",
        value: ["PIPELINE"],
        checkAccess: (client) ->
            return client.showNewConstruction;
    },
    NEW_RENO_CONV: {
        dropdownValue: "NEW_RENO_CONV",
        isProjectType: true,
        isNewProjectType: true,
        projectTypes: ["RENOVATION", "CONVERSION"],
        checkAccess: (client) ->
            return client.showRenovations || client.showConversions;
    },
    NEW_SALE: {
        dropdownValue: "NEW_SALE",
        isProjectType: true,
        isNewProjectType: true,
        projectTypes: ["SALE"],
        checkAccess: (client) ->
            return client.showSales;
    },
    DISTRESS_STATUS: {
        dropdownValue: "DISTRESS_STATUS",
        isProjectType: true,
        isNewProjectType: true,
        propertyName: "assetStatus"
        projectTypes: ["DISTRESSED_ASSET"],
        checkAccess: (client) ->
            return client.showDistressedAssets;
    },
    DEVELOPER_OWNER: {
        dropdownValue: "DEVELOPER_OWNER",
        isProjectType: false,
        propertyName: "developer"
    },
    MANAGEMENT: {
        dropdownValue: "MANAGEMENT",
        isProjectType: false,
        propertyName: "manager"
    },
    ARCHITECT: {
        dropdownValue: "ARCHITECT",
        isProjectType: true,
        propertyName: "architect",
        checkAccess: (client) ->
            if (!client.showActivityContacts)
                return false;
            return client.showNewConstruction || client.showRenovations || client.showConversions;
    },
    INTERIOR_DESIGN: {
        dropdownValue: "INTERIOR_DESIGN",
        isProjectType: true,
        propertyName: "designer"
        checkAccess: (client) ->
            if (!client.showActivityContacts)
                return false;
            return client.showNewConstruction || client.showRenovations || client.showConversions;
    },
    CONTRACTOR: {
        dropdownValue: "CONTRACTOR",
        isProjectType: true,
        propertyName: "contractor"
        checkAccess: (client) ->
            if (!client.showActivityContacts)
                return false;
            return client.showNewConstruction || client.showRenovations || client.showConversions;
    },
    PURCHASING: {
        dropdownValue: "PURCHASING",
        isProjectType: true,
        propertyName: "purchaser"
        checkAccess: (client) ->
            if (!client.showActivityContacts)
                return false;
            return client.showNewConstruction || client.showRenovations || client.showConversions;
    }
    UPDATED_RENO_CONVERSION: {
        dropdownValue: "UPDATED_RENO_CONVERSION",
        isProjectType: true,
        isUpdatedProjectType: true,
        projectTypes: ["RENOVATION", "CONVERSION"],
        checkAccess: (client) ->
            return client.showRenovations || client.showConversions;
    }
    UPDATED_PIPELINE_PROJECT: {
        dropdownValue: "UPDATED_PIPELINE_PROJECT",
        isProjectType: true,
        isUpdatedProjectType: true,
        projectTypes: ["NEW_CONSTRUCTION"],
        checkAccess: (client) ->
            return client.showNewConstruction;
    },
    ANY_UPDATE: {
        dropdownValue: "ANY_UPDATE",
        isProjectAndPropertyType: true,
        isAnyUpdate: true,
        propertyNames: ["updated"],
    },
}


mapSelectedFieldsToChangeTypes = (types)->
    values = {}
    types.forEach((type)->
        changeType = CHANGE_TYPES[type]
        if changeType.isProjectAndPropertyType
            if (!values.project)
                values.project = {}
            changeType.propertyNames.forEach((propertyName)->
                values[propertyName] = true
                values.project[propertyName] = true
            )
            return
        if changeType.isProjectType
            if (!values.project)
                values.project = {}
            if (changeType.isNewProjectType)
                if (!values.project.newProjectType)
                    values.project.newProjectType = []
                values.project.newProjectType = values.project.newProjectType.concat(changeType.projectTypes)
            if (changeType.isUpdatedProjectType)
                if (!values.project.updatedProjectType)
                    values.project.updatedProjectType = []
                values.project.updatedProjectType = values.project.updatedProjectType.concat(changeType.projectTypes)
            if (changeType.propertyName)
                values.project[changeType.propertyName] = true
            return
        values[changeType.propertyName] = if(changeType.value) then changeType.value else true
    )
    return values

getSelectedFields = (values)->
    if (!values)
        return values
    selectedFields = []
    Object.keys(CHANGE_TYPES).forEach((type)->
        changeType = CHANGE_TYPES[type]
        if changeType.isProjectAndPropertyType
            if (!values.project)
                return
            if changeType.propertyNames.every((propertyName)-> values[propertyName] && values.project[propertyName])
                if changeType.isAnyUpdate && (Object.keys(values.project).length != 1 || Object.keys(values).length != 2)
                    return
                selectedFields.push(changeType.dropdownValue)
            return
        if changeType.isProjectType
            if (!values.project)
                return
            if (changeType.isNewProjectType)
                if (!values.project.newProjectType)
                    return
                if changeType.projectTypes.every((projectType)-> values.project.newProjectType.includes(projectType))
                    selectedFields.push(changeType.dropdownValue)
                return
            if (changeType.isUpdatedProjectType)
                if (!values.project.updatedProjectType)
                    return
                if changeType.projectTypes.every((projectType)-> values.project.updatedProjectType.includes(projectType))
                    selectedFields.push(changeType.dropdownValue)
                return
            if (values.project[changeType.propertyName])
                selectedFields.push(changeType.dropdownValue)
            return
        if (values[changeType.propertyName])
            selectedFields.push(changeType.dropdownValue)
    )
    return selectedFields.join(';')

filterOptionFields = (client) -> (field) ->
    changeType = CHANGE_TYPES[field.value]
    if (!client)
        return false;
    if (changeType.checkAccess)
        return changeType.checkAccess(client);
    return true;

validateSelectedChanges = (prevValue, nextValue)->
    selectedValue = nextValue.filter((x) -> !prevValue?.includes(x)).join('');
    if (selectedValue == CHANGE_TYPES.ANY_UPDATE.dropdownValue)
        return [CHANGE_TYPES.ANY_UPDATE.dropdownValue]
    if prevValue?.includes(CHANGE_TYPES.ANY_UPDATE.dropdownValue)
        return nextValue.filter((value) -> value != CHANGE_TYPES.ANY_UPDATE.dropdownValue)
    return nextValue

optionsFields = [
    { translationKey: 'search.general.changes.types.property.project.updates',  value: CHANGE_TYPES.PROPERTY_PROJECT_UPDATES.dropdownValue },
    { translationKey: 'search.general.changes.types.new.renovation.conversion',  value: CHANGE_TYPES.NEW_RENO_CONV.dropdownValue },
    { translationKey: 'search.general.changes.types.new.pipeline',  value: CHANGE_TYPES.NEW_PIPELINE.dropdownValue },
    { translationKey: 'search.general.changes.types.developer.owner',  value: CHANGE_TYPES.DEVELOPER_OWNER.dropdownValue },
    { translationKey: 'search.general.changes.types.management',  value: CHANGE_TYPES.MANAGEMENT.dropdownValue },
    { translationKey: 'search.general.changes.types.architect',  value: CHANGE_TYPES.ARCHITECT.dropdownValue },
    { translationKey: 'search.general.changes.types.contractor',  value: CHANGE_TYPES.CONTRACTOR.dropdownValue },
    { translationKey: 'search.general.changes.types.interior.design',  value: CHANGE_TYPES.INTERIOR_DESIGN.dropdownValue },
    { translationKey: 'search.general.changes.types.purchasing',  value: CHANGE_TYPES.PURCHASING.dropdownValue },
    { translationKey: 'search.general.changes.types.new.sale',  value: CHANGE_TYPES.NEW_SALE.dropdownValue },
    { translationKey: 'search.general.changes.types.distress.status',  value: CHANGE_TYPES.DISTRESS_STATUS.dropdownValue },
    { translationKey: 'search.general.changes.types.any.update',  value: CHANGE_TYPES.ANY_UPDATE.dropdownValue },
]

getError = (values)->
    if (!values || !values.enabled)
        return null;
    if (values.mode == 'DATE')
        if (!values.sinceDate)
            return { translationKey: "search.general.changes.missing.date" }
        daysAgo = Math.floor(Moment.duration(Moment().diff(Moment(values.sinceDate))).asDays())
        if (Number(daysAgo) > WINDOW_LIMIT || Number(daysAgo) < 1)
            return { translationKey: "search.general.changes.wrong.date", params: { maxDays: WINDOW_LIMIT } }
    if (values.mode == 'DAYS')
        if (!values.daysAgo)
            return { translationKey: "search.general.changes.missing.days" }
        if (Number(values.daysAgo) > WINDOW_LIMIT || Number(values.daysAgo) < 1)
            return { translationKey: "search.general.changes.wrong.days", params: { minDays: 1, maxDays: WINDOW_LIMIT } }
    if (!values.values || Object.keys(values.values).length == 0)
        return { translationKey: "search.general.changes.no.types" }
    return null;

disabledDate = (moment) ->
    daysAgo = Math.floor(Moment.duration(Moment().diff(Moment(moment))).asDays())
    return Number(daysAgo) > WINDOW_LIMIT || Number(daysAgo) < 1

module.exports = {
    filterOptionFields: filterOptionFields,
    getSelectedFields: getSelectedFields,
    mapSelectedFieldsToChangeTypes: mapSelectedFieldsToChangeTypes,
    optionsFields: optionsFields,
    getError: getError,
    disabledDate: disabledDate,
    validateSelectedChanges: validateSelectedChanges
}