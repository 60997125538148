import { useState, useCallback, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const KEYBOARD_KEYCODES = {
    ENTER: 13,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
};

const CInlineDropdown = ({ title, className = '', as, items, onSelect, placeholder, loading, noResultsText, style, children }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const searchable = items.filter(item => !item.inactive).length > 5;
    let keyCounter = 0;
    const { t } = useTranslation();

    const onSearchQueryChange = useCallback((event) => {
        setActiveSuggestion(0);
        setSearchQuery(event.target.value);
    }, [setActiveSuggestion, setSearchQuery]);

    const filteredItems = useMemo(() => {
        if (!searchable) {
            return items;
        }
        return items.filter((item) => {
            if (item.inactive) {
                return true;
            }
            return item.title.toLowerCase().includes(searchQuery.toLowerCase());
        });
    }, [searchable, searchQuery, items]);

    const onMouseEnter = useCallback((index) => () => {
        setActiveSuggestion(index);
    }, [setActiveSuggestion]);

    const onMouseLeave = () => {
        setActiveSuggestion(null);
    };

    const getActiveSuggestionIndexArrowUp = (currentIndex) => {
        if (currentIndex - 1 < 0) {
            return null;
        }
        if (filteredItems[currentIndex - 1].inactive) {
            return getActiveSuggestionIndexArrowUp(currentIndex - 1);
        }
        return currentIndex - 1;
    };

    const getActiveSuggestionIndexArrowDown = (currentIndex) => {
        if (currentIndex + 1 >= filteredItems.length) {
            return null;
        }
        if (filteredItems[currentIndex + 1].inactive) {
            return getActiveSuggestionIndexArrowDown(currentIndex + 1);
        }
        return currentIndex + 1;
    };

    const onKeyDown = useCallback(
        (event) => {
            if (event.keyCode === KEYBOARD_KEYCODES.ENTER) {
                event.preventDefault();
                if (activeSuggestion !== null && filteredItems[activeSuggestion]) {
                    setShowDropdown(false);
                    onSelect(filteredItems[activeSuggestion]);
                }
            }
            if (event.keyCode === KEYBOARD_KEYCODES.ARROW_UP) {
                if (activeSuggestion === 0) {
                    return;
                }
                const nextActiveSuggestionIndex = getActiveSuggestionIndexArrowUp(activeSuggestion);
                if (nextActiveSuggestionIndex !== null) {
                    setActiveSuggestion(nextActiveSuggestionIndex);
                }
            }
            if (event.keyCode === KEYBOARD_KEYCODES.ARROW_DOWN) {
                if (activeSuggestion === filteredItems.length - 1) {
                    return;
                }
                const nextActiveSuggestionIndex = getActiveSuggestionIndexArrowDown(activeSuggestion);
                if (nextActiveSuggestionIndex !== null) {
                    setActiveSuggestion(nextActiveSuggestionIndex);
                }
            }
        },
        [activeSuggestion, setActiveSuggestion, filteredItems]
    );

    const setFocus = (element) => {
        if (element) {
            element.focus();
        }
        return element;
    }

    const getItemClassName = (item, index) => {
        if (item.disabled) {
            return "disabled";
        }
        if (item.inactive) {
            return "dropdown-item-inactive";
        }
        if (index === activeSuggestion) {
            return "dropdown-item-selected";
        }
        return null;
    }

    const onToggle = (isOpen) => {
        setShowDropdown(isOpen);
    }

    const preventDefault = (e) => {
        e.preventDefault();
    }

    return <Dropdown onClick={preventDefault} onToggle={onToggle} show={showDropdown} focusFirstItemOnShow='keyboard' as='span' className={"dobo dropdown-inline"}>
        <Dropdown.Toggle bsPrefix={"noclass " + className} as={as} variant="normal" style={style}>
            {title || children}
        </Dropdown.Toggle>
        <Dropdown.Menu onKeyDown={onKeyDown}>
            {loading && <Dropdown.Item disabled className="dropdown-item-loader">
                <div className="loader loader-small loader-white-bg"></div>
            </Dropdown.Item>}
            {searchable && <div className="form">
                <div className="box-form-search">
                    <div className="box-control">
                        <div className="box-icon-control right">
                            <i className="fa fa-search"></i>
                        </div>
                        <input
                            className="form-control dropdown-search"
                            onChange={onSearchQueryChange}
                            value={searchQuery}
                            placeholder={placeholder || t("dropdown.search.default.search.placeholder")}
                            ref={setFocus}
                        />
                    </div>
                </div>
            </div>}
            <div className="dropdown-list">
                {filteredItems.map((item, index) => (
                    <React.Fragment key={"d" + (keyCounter++)}>
                        <Dropdown.Item
                            className={getItemClassName(item, index)}
                            onMouseEnter={onMouseEnter(index)}
                            onMouseLeave={onMouseLeave}
                            onClick={() => onSelect(item)}
                        >
                            {item.title}
                        </Dropdown.Item>
                        {item.divider && <div className="dropdown-item-divider"></div>}
                    </React.Fragment>
                ))}
                {!loading && filteredItems.length === 0 && <Dropdown.Item disabled className="dropdown-item-empty">
                    {noResultsText || t("dropdown.search.default.no.results.placeholder")}
                </Dropdown.Item>}
            </div>
        </Dropdown.Menu>
    </Dropdown>
}


export default CInlineDropdown;