Connect = require '../../helpers/Connect'
Utils = require '../../helpers/Utils'
{ CLabel } = require '../CLabel'
CMultipleDropdown = require '../filters/CMultipleDropdown'
CFilterElement = require '../filters/CFilterElement'
CHasTimelineFilterElement = require '../filters/CHasTimelineFilterElement'
CGeneralChangesFilterElement = require '../filters/CGeneralChangesFilter/CGeneralChangesFilterElement'
{ withTranslation } = require 'react-i18next' 

module.exports = withTranslation() Connect createReactClass(

  displayName: 'CSearchFormColumnActivity'

  getInitialState: ->
    collapsed: true

  putValue: (name, value)->
    val = if typeof value == 'string' && value.length == 0 then undefined else value
    if name == "proximityFilter" && value?.address?.length == 0 && !value?.radius
      val = undefined
    @props.addValueToSearchForm(name, val)

  putValueFromDropDown: (name, values, options)->
    values = if values.length == 0 then undefined else (values.map (e)=> "'#{e}'").join(";")
    @props.addValueToSearchForm(name, values, options)

  showBlock: (name)->
    @props.toggleBlockOnSearchForm(name)

  renderCheckboxes: ()->
    checkboxes = []

    if @props.client?.showNewConstruction
      checkboxes.push React.createElement("div", {"key": "construction", "className": "box-checkbox middle aligned row-half"},
        React.createElement("input", {"type": "checkbox", "id": "check2", "checked": (@props.form.showNewConstruction || false), "onChange": (@showBlock.bind @, "showNewConstruction")}), React.createElement("label", {"htmlFor": "check2"}, React.createElement(CLabel, {"k": "data.activity.showNewConstruction", "param": ({ ns: "data" })}))
      )
    if @props.client?.showConversions
      checkboxes.push React.createElement("div", {"key": "conversions", "className": "box-checkbox middle aligned row-half"},
        React.createElement("input", {"type": "checkbox", "id": "check4", "checked": (@props.form.showConversions || false), "onChange": (@showBlock.bind @, "showConversions")}), React.createElement("label", {"htmlFor": "check4"}, React.createElement(CLabel, {"k": "data.activity.showConversions", "param": ({ ns: "data" })}))
      )
    if @props.client?.showRenovations
      checkboxes.push React.createElement("div", {"key": "renovations", "className": "box-checkbox middle aligned row-half"},
        React.createElement("input", {"type": "checkbox", "id": "check3", "checked": (@props.form.showRenovations || false), "onChange": (@showBlock.bind @, "showRenovations")}), React.createElement("label", {"htmlFor": "check3"}, React.createElement(CLabel, {"k": "data.activity.showRenovations", "param": ({ ns: "data" })}))
      )
    if @props.client?.showSales
      checkboxes.push React.createElement("div", {"key": "sales", "className": "box-checkbox middle aligned row-half"},
        React.createElement("input", {"type": "checkbox", "id": "check5", "checked": (@props.form.showSales || false), "onChange": (@showBlock.bind @, "showSales")}), React.createElement("label", {"htmlFor": "check5"}, React.createElement(CLabel, {"k": "data.activity.showSales", "param": ({ ns: "data" })}))
      )

    if @props.client?.showNewConstruction
      checkboxes.push React.createElement("div", {"key": "open", "className": "box-checkbox middle aligned row-half"},
        React.createElement("input", {"type": "checkbox", "id": "check1", "checked": (@props.form.showOpenAndOperating || false), "onChange": (@showBlock.bind @, "showOpenAndOperating")}), React.createElement("label", {"htmlFor": "check1"}, React.createElement(CLabel, {"k": "data.activity.showOpenAndOperating", "param": ({ ns: "data" })}))
      )
  
    if @props.client?.showDistressedAssets
      checkboxes.push React.createElement("div", {"key": "distressed", "className": "box-checkbox middle aligned row-half"},
        React.createElement("input", {"type": "checkbox", "id": "check6", "checked": (@props.form.showDistressedAssets || false), "onChange": (@showBlock.bind @, "showDistressedAssets")}), React.createElement("label", {"htmlFor": "check6"}, React.createElement(CLabel, {"k": "data.activity.showDistressedAssets", "param": ({ ns: "data" })}))
      )

    rows = checkboxes.reduce((result, value, index, array) =>
      if (index % 2 == 0)
        result.push(array.slice(index, index + 2));
      return result;
    , []);

    return rows.map((row, i) => React.createElement("div", {"key": "key_#{i}", "className": "checkboxes-row"},
      (row)
    ))
    

  render: ->
    if @props.client?.showNewConstruction || @props.client?.showRenovations || @props.client?.showSales || @props.client?.showConversions || @props.client?.showDistressedAssets
      React.createElement("div", {"className": "row-three #{@props.className}"},
        React.createElement("div", {"className": "box-slide-form open"},
          React.createElement("div", {"className": "top-form"},
            React.createElement("div", {"className": "tile-group"},
              (@props.children)
            )
          ),
          React.createElement("div", {"className": "slide-form #{if @props.collapsed then '' else 'open'}"},
            React.createElement("div", {"className": "tile-group"},
              React.createElement("form", {"className": "form form-small"},
                React.createElement("div", {"className": "box-form-group"},
                  React.createElement("div", {"className": "box-control"},
                    React.createElement("div", {"className": "clearfix"},
                      (@renderCheckboxes())
                    )
                  )
                )
              )
            ),
            React.createElement("div", {"className": "tile-group"},
              React.createElement("form", {"className": "form form-small"},
                React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "search.activity.details"}))),
                React.createElement("div", {"style": (display: if @props.form["showNewConstruction"] || @props.form["showRenovations"] || @props.form["showConversions"] then 'block' else 'none')},
                  React.createElement("div", {"className": "box-form-group"},
                    React.createElement("div", {"className": "box-control"},
                      React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "projectStage", "values": (@props.form?.projectStage?.value), "options": (@props.form?.projectStage?.options), "placeholder": (@props.t("search.activity.details.project.stage"))})
                    ),
                    React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "startDate", "value": (@props.form?.startDate?.value), "type": "date", "className": "form-control", "placeholder": (@props.t("search.activity.details.start.date"))})),
                    React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "openingDate", "value": (@props.form?.openingDate?.value), "type": "date", "className": "form-control", "placeholder": (@props.t("search.activity.details.opening.date"))}))
                  )
                ),

                (if Utils.hasFeature(@props.client, "ConversionInitial") then React.createElement("div", {"style": (display: if @props.form["showConversions"] then 'block' else 'none')},
                  React.createElement("div", {"className": "name-box"}, React.createElement("span", {"className": "italic"}, React.createElement(CLabel, {"k": "search.activity.details.if.convertingFrom"}))),
                  React.createElement("div", {"className": "box-form-group"},
                    React.createElement("div", {"className": "box-control"},
                      React.createElement(CFilterElement, {"onChange": (@putValue), "name": "initialFranchiseName", "value": (@props.form?.initialFranchiseName?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.activity.details.prior.brand"))})
                    ),
                    React.createElement("div", {"className": "box-control"},
                      React.createElement(CFilterElement, {"onChange": (@putValue), "name": "initialFranchiseCompany", "value": (@props.form?.initialFranchiseCompany?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.activity.details.prior.franchise.company"))})
                    ),
                    React.createElement("div", {"className": "box-control"},
                      React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "initialChainScale", "dictionaryKey": "newChainScale", "values": (@props.form?.initialChainScale?.value), "options": (@props.form?.initialChainScale?.options), "placeholder": (@props.t("search.activity.details.prior.chain.scale"))})
                    )
                  )
                )),

                React.createElement("div", {"style": (display: if @props.form["showConversions"] then 'block' else 'none')},
                  React.createElement("div", {"className": "name-box"}, React.createElement("span", {"className": "italic"}, React.createElement(CLabel, {"k": (if Utils.hasFeature(@props.client, "ConversionInitial") then "search.activity.details.if.convertingTo" else "search.activity.details.if.converting")}))),
                  React.createElement("div", {"className": "box-form-group"},
                    React.createElement("div", {"className": "box-control"},
                      React.createElement(CFilterElement, {"onChange": (@putValue), "name": "newFranchiseName", "value": (@props.form?.newFranchiseName?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.activity.details.new.brand"))})
                    ),
                    React.createElement("div", {"className": "box-control"},
                      React.createElement(CFilterElement, {"onChange": (@putValue), "name": "newFranchiseCompany", "value": (@props.form?.newFranchiseCompany?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.activity.details.new.franchise.company"))})
                    ),
                    React.createElement("div", {"className": "box-control"},
                      React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "newChainScale", "values": (@props.form?.newChainScale?.value), "options": (@props.form?.newChainScale?.options), "placeholder": (@props.t("search.activity.details.new.chain.scale"))})
                    )
                  )
                ),
                React.createElement("div", {"style": (display: if @props.form["showSales"] then 'block' else 'none')},
                  React.createElement("div", {"className": "name-box"}, React.createElement("span", {"className": "italic"}, React.createElement(CLabel, {"k": "search.activity.details.sales.transaction"}))),
                  React.createElement("div", {"className": "box-form-group"},
                    React.createElement("div", {"className": "box-control"},
                      React.createElement(CFilterElement, {"onChange": (@putValue), "name": "saleDate", "value": (@props.form?.saleDate?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.activity.date"))})
                    ),
                    React.createElement("div", {"className": "box-multi-control"},
                      React.createElement("div", {"className": "box-table"},
                        React.createElement("div", {"className": "box-control row-half"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "saleAmount", "value": (@props.form?.saleAmount?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.activity.details.sale.amount"))})),
                        React.createElement("div", {"className": "box-control row-half"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "salePerRoom", "value": (@props.form?.salePerRoom?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.activity.details.price.per.room"))}))
                      )
                    )
                  )
                ),
                React.createElement("div", {"style": (display: if @props.form["showDistressedAssets"] then 'block' else 'none')},
                  React.createElement("div", {"className": "name-box"}, React.createElement("span", {"className": "italic"}, React.createElement(CLabel, {"k": "search.activity.details.distressed.assets"}))),
                  React.createElement("div", {"className": "box-form-group"},
                    React.createElement("div", {"className": "box-control"},
                      React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "assetStatus", "values": (@props.form?.assetStatus?.value), "options": (@props.form?.assetStatus?.options), "placeholder": (@props.t("search.activity.details.asset.status"))})
                    )
                  )
                ),
                (if @props.form["showNewConstruction"] || @props.form["showRenovations"] || @props.form["showConversions"]
                  React.createElement(CHasTimelineFilterElement, {"onChange": (@putValue), "name": "hasTimelineFilter", "value": (@props.form?.hasTimelineFilter?.value)})
                )
              )
            ),
            React.createElement("div", {"className": "tile-group"},
              React.createElement("form", {"className": "form form-small"},
                React.createElement(CGeneralChangesFilterElement, {"onChange": (@putValue), "name": "propertyChanges", "value": (@props.form?.propertyChanges?.value)})
              )
            )
          )
        )
      )
    else
      null
), {
  state: (state)->
    translation: state.translation
    form: state.searchForm.form
    client: state.session?.user?.client

  dispatch: (dispatch)->
    toggleBlockOnSearchForm: (name) -> dispatch(type: 'TOGGLE_BLOCK_ON_SEARCH_FORM', name: name)
    addValueToSearchForm: (name, value, options) -> dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value, options: options)
}
