import { useState } from 'react'
import { hasFeature } from '../helpers/UserUtils'
import Utils from '../helpers/Utils'
import { CLabel } from './CLabel'

// #title
// #filters
// #onFilterSelected
// #onMenuToggle

export default ({ user, title, filters, savedSearches, isOpen, onFilterSelected, onMenuToggle }) => {

  const [isOpenMenu, setOpen] = useState(isOpen)

  const toggleMenu = () => {
    setOpen(!isOpenMenu)
    onMenuToggle(isOpen)
  }

  const isActive = (path) => (window.location.href.endsWith(path) ? "active" : "")
  const isInDigest = (filter) => {
    if (!hasFeature(user?.client, "CustomDigest")) {
      return false;
    }
    return (filter.createdById == user.id && filter.weeklyDigest) || filter.weeklyDigestShared && filter.isShared;
  }

  return (
    <div className="box-nav">
      <div className="name-nav" onClick={toggleMenu}><CLabel k={title} /> ({filters?.length}) <i className={"fa " + (isOpenMenu && "fa-angle-up" || "fa-angle-down")}></i></div>
      <ul className="menu-main menu-secondary" style={{ display: (isOpenMenu ? "block" : "none") }}>
        {(filters || []).sort((a, b) => (a.filterId - b.filterId)).map((filter, i) => (
          <li className={isActive("/results/" + filter.filterId) + (savedSearches ? " saved-search" : "")} key={i}>
            <a className="menu-item" onClick={() => onFilterSelected(filter)}>
              <i className={"icon icon-middle " + (isInDigest(filter) ? "icon-savedsearch-digest" : "icon-savedsearch")}></i>
              <span className="show-mobile">{Utils.ellipsis(filter.name, 45)}</span>
              <span className="hide-mobile">{Utils.ellipsis(filter.name, 32)}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )

}
