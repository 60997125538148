import { CSSProperties } from 'react'
import CRolePhoneNumber from './CRolePhoneNumber'
import { getRolesInfo, roles } from './RoleUtils'

/**
 * @type {CSSProperties}
 */
const styles = {

  container: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
  },
}


export default ({ property, isHistory, RenderItemAs, style, separateRolesContacts, fillEmpty, rolesData }) => {
  const rolesInfo = rolesData || getRolesInfo(property, isHistory)

  return <div style={style || styles.container} className="roles-list">
    <div className="results-roles">
      {rolesInfo.map(info => {
        const { role, company, contact } = info
        const id = company && company.id || contact && contact.companyId
        return <RenderItemAs key={role + id} {...info} roles={roles} />
      })}
      {fillEmpty && rolesInfo.length % 2 === 1 && <div className="box-tile empty-role-item" />}
    </div>
    {separateRolesContacts && <div className="roles-contacts hide-mobile">
      {rolesInfo.map(info => {
        const { role, company, contact } = info
        const id = company && company.id || contact && contact.companyId
        return <CRolePhoneNumber key={role + id} {...info} roles={roles} />
      })}
    </div>}
  </div>
}