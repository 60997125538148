
Connect = require '../../helpers/Connect'
{API} = require '../../redux/Store'
CInput = require '../CInput'
CFilterElement = require '../filters/CFilterElement'
{ CLabel } = require '../CLabel'
CAdminAnalytics = require './CAdminAnalytics'
CAdminRecentActivity = require './CAdminRecentActivity'

{ withTranslation } = require 'react-i18next' 


module.exports = withTranslation() Connect createReactClass(
  getInitialState: ()->
    newUser: ""
    singleEmail: ""
    multipleEmails: ""
    analyticsTab: "sessions"

  tryNewUser: ()->
    newUser = @state.newUser.replace(';', '')
    @initAliasSession(newUser)

  initAliasSession: (userIdOrEmail)->
    API.startAliasSession userIdOrEmail: userIdOrEmail, (session)=>
      @props.login(session)
      sessionStorage.clear()
      location.href = "/home";
    , ()=>
      @setState aliasUserError: 'Incorrect alias user, check your user id or email'

  initShadowSession: ()->
    token = @state.newUser.replace(';', '')
    API.createShadowSession(token, (session) =>
      @initAliasSession(session?.user?.id)
    , ()=>
      @setState aliasUserError: 'Incorrect shadow token')

  autocompleteXhr: (field, cb)->
    if @state[field]
      API.emailAutocomplete(@state[field], cb)

  setForm: (name, e, value)->
    if e?.target?.value
      value = e.target.value
    @state[name] = value
    @state.emailError = ""
    @state.aliasUserError = ""
    @state.emailSuccess = ""
    @setState @state

  changeAnalyticsTab: (tab)->
    @setState analyticsTab: tab

  _getEmails: ()->
    emails: (@state.singleEmail?.split(';') || []).concat(@state.multipleEmails?.split(/\r?\n/)).filter (i) -> i

  _errorCallback: (type)->
    (error)=>

      length = @_getEmails().emails.length
      if error
        @setState emailError: 'Invalid email: ' + error.info + '. No emails were sent.'
      else
        if type == 'WELCOME'
          emailSuccess = if length > 1 then @props.t('admin.tabs.users.welcome.emails.success', { length, ns: 'internal_ui' }) else @props.t('admin.tabs.users.welcome.email.success', { length, ns: 'internal_ui' })
        else if type == 'WEEKLY_DIGEST'
          emailSuccess = if length > 1 then @props.t('admin.tabs.users.passwords.reset.success', { length, ns: 'internal_ui' }) else @props.t('admin.tabs.users.password.reset.success', { length, ns: 'internal_ui' })
        else
          emailSuccess = if length > 1 then @props.t('admin.tabs.users.weekly.digests.success', { length, ns: 'internal_ui' }) else @props.t('admin.tabs.users.weekly.digest.success', { length, ns: 'internal_ui' })
        @setState emailSuccess: emailSuccess, singleEmail: "", multipleEmails: ""

  sendWelcomeEmails: ()->
    API.sendWelcomeEmail @_getEmails(), (res)=>
      length = @_getEmails().emails.length
      emailSuccess = if length > 1 then @props.t('admin.tabs.users.welcome.emails.success', { length, ns: 'internal_ui' }) else @props.t('admin.tabs.users.welcome.email.success', { length, ns: 'internal_ui' })
      @setState singleEmail: "", multipleEmails: "", emailSuccess: emailSuccess
    , @_errorCallback('WELCOME')

  sendNewPasswords: ()->
    API.passwordReset @_getEmails(), (res)=>
      @setState singleEmail: "", multipleEmails: "", emailSuccess: (res.map (it)-> 'Password for user ' + it.email + ' has been reset to ' + it.password).join('<br/>')
    , @_errorCallback('RESET')

  isWelcomeEmailAndPasswordResetDisabled: ->
    !@state.singleEmail && !@state.multipleEmails || @state.singleEmail && @state.multipleEmails

  isWeeklyDigestDisabled: ->
    !@state.singleEmailWeeklyDigest && !@state.multipleEmailsWeeklyDigest || @state.singleEmailWeeklyDigest && @state.multipleEmailsWeeklyDigest

  sendWeeklyDigest: ()->
    API.sendWeeklyDigest @_getEmails(), (res)=>
      length = @_getEmails().emails.length
      emailSuccess = if length > 1 then @props.t('admin.tabs.users.weekly.digests.success', { length, ns: 'internal_ui' }) else @props.t('admin.tabs.users.weekly.digest.success', { length, ns: 'internal_ui' })
      @setState singleEmail: "", multipleEmails: "", emailSuccess: emailSuccess
    , @_errorCallback('WEEKLY_DIGEST')

  showWeeklyDigest: ()->
    @props.digestPreview(@_getEmails())

  render: ->
    React.createElement("div", {"className": "admin-page"},
      React.createElement("div", {"className": "block-tile"},
        React.createElement("div", {"className": "box-tile"},
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "name-block upper", "style": (fontSize: '1em')},
              React.createElement(CLabel, {"k": "admin.tabs.users.user.alias.session"})
            ),
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "box-control clearfix"},
                React.createElement("div", {"className": 'row-half', "style": (paddingRight: '10px', marginBottom: '10px')},
                  React.createElement(CFilterElement, {"type": "text", "autocompleteXhr": (@autocompleteXhr.bind @, 'newUser'), "onChange": (@setForm.bind @, 'newUser'), "value": (@state.newUser || ''), "className": "form-control", "placeholder": (@props.t("admin.tabs.users.enter.single.user.email", { ns: 'internal_ui' }))}),
                  (if @state.aliasUserError
                    React.createElement("div", {"className": "message-error under-control"}, (@state.aliasUserError))
                  )
                ),
                React.createElement("div", {"className": 'row-half'},
                  React.createElement("a", {"onClick": (@tryNewUser), "className": "btn btn-red btn-normal upper #{if !@state.newUser || @state.aliasUserError then 'disabled'}"}, React.createElement(CLabel, {"k": "admin.tabs.users.alias"})),
                  React.createElement("a", {"onClick": (@initShadowSession), "className": "btn btn-red btn-normal upper ml-2 #{if !@state.newUser || @state.aliasUserError then 'disabled'}"}, React.createElement(CLabel, {"k": "admin.tabs.users.shadow"}))
                )
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": "block-tile"},
        React.createElement("div", {"className": "box-tile"},
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "name-block upper", "style": (fontSize: '1em')},
              React.createElement(CLabel, {"k": "admin.tabs.users.welcome.email.or.password.reset"})
            ),
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "box-control clearfix"},
                React.createElement("div", {"className": 'row-half', "style": (paddingRight: '10px', marginBottom: '10px')},
                  React.createElement(CFilterElement, {"type": "text", "autocompleteXhr": (@autocompleteXhr.bind @, 'singleEmail'), "onChange": (@setForm.bind @, 'singleEmail'), "value": (@state.singleEmail), "className": "form-control", "placeholder": (@props.t("admin.tabs.users.enter.single.user.email", { ns: 'internal_ui' }))}),
                  React.createElement("textarea", {"style": (marginTop: '10px'), "onChange": (@setForm.bind @, 'multipleEmails'), "value": (@state.multipleEmails), "className": "form-control", "placeholder": (@props.t("admin.tabs.users.or.enter.multiple.emails.one.per.line", { ns: 'internal_ui' }))})
                ),
                React.createElement("div", {"className": 'row-half'},
                  React.createElement("a", {"onClick": (@sendWelcomeEmails), "className": "btn btn-red btn-normal upper #{if @isWelcomeEmailAndPasswordResetDisabled() then 'disabled'}"}, React.createElement(CLabel, {"k": "admin.tabs.users.send.welcome.email"})),
                  React.createElement("div", null, "or"),
                  React.createElement("a", {"onClick": (@sendNewPasswords), "className": "btn btn-red btn-normal upper #{if @isWelcomeEmailAndPasswordResetDisabled() then 'disabled'}"}, React.createElement(CLabel, {"k": "admin.tabs.users.send.new.password"})),
                  React.createElement("div", null, "or"),
                  React.createElement("a", {"onClick": (@sendWeeklyDigest), "className": "btn btn-red btn-normal upper #{if @isWelcomeEmailAndPasswordResetDisabled() then 'disabled'}"}, React.createElement(CLabel, {"k": "admin.tabs.users.send.weekly.digest"})),
                  React.createElement("span", null, " or "), React.createElement("a", {"onClick": (@showWeeklyDigest), "className": "btn btn-red btn-normal upper #{if @isWelcomeEmailAndPasswordResetDisabled() then 'disabled'}"}, React.createElement(CLabel, {"k": "admin.tabs.users.show.weekly.digest"})),
                  React.createElement("div", {"style": (marginTop: '10px', lineHeight: '1.5em')},
                    (@state.emailError),
                    (@state.emailSuccess)
                  )
                )
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": "box-nav-tabs"},
        React.createElement("ul", {"className": "nav-tabs"},
          React.createElement("li", {"style": (height: '100%'), "key": "admin-user-sessions", "className": (if @state.analyticsTab == "sessions" then "active")}, React.createElement("a", {"style": (height: '100%'), "onClick": (@changeAnalyticsTab.bind @, "sessions")}, React.createElement("span", null, React.createElement(CLabel, {"k": "admin.tabs.users.recent.sessions"})))),
          React.createElement("li", {"style": (height: '100%'), "key": "admin-user-activity", "className": (if @state.analyticsTab == "activity" then "active")}, React.createElement("a", {"style": (height: '100%'), "onClick": (@changeAnalyticsTab.bind @, "activity")}, React.createElement("span", null, React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.logins"}))))
        )
      ),
      React.createElement("div", {"className": "block-tabs"},
        React.createElement("div", {"className": "box-tab #{if @state.analyticsTab == 'sessions' then 'active' else ''}"},
          React.createElement("div", {"className": "box-tab-content"},
            React.createElement(CAdminAnalytics, null)
          )
        ),
        React.createElement("div", {"className": "box-tab #{if @state.analyticsTab == 'activity' then 'active' else ''}"},
          React.createElement("div", {"className": "box-tab-content"},
            React.createElement(CAdminRecentActivity, null)
          )
        )
      )
    )
), {
  dispatch: (dispatch)->
    login: (session)->
      dispatch(type: 'LOGIN', payload: session)
    digestPreview: (emails)->
      dispatch(type: 'TOGGLE_POPUP_DIGEST_VIEW', data: emails)

  state: (state)->
    user: state.session?.user
    translation: state.translation
}
