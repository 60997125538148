{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'

{ API } = require '../../redux/Store'
{ CLabel } = require '../../cmp/CLabel'
CItemNotesList = require '../../cmp/note/CItemNotesList'
CNoteText = require '../../cmp/note/CNoteText'
CNoteDates = require '../../cmp/note/CNoteDates'

#props.subtitle
#props.notes
#props.maxNotes
module.exports = Connect createReactClass(
  displayName: "CPropertyNotes"

  UNSAFE_componentWillReceiveProps: (props)->
    if props.count > -1
      @setState oldValue: props.count

  mobileBlockToggle:(e)->
    e.target.closest('.box-slide-block').querySelector('.slide-block').classList.toggle('open')

  render: ()->
    shouldShowMore = @props.locationHistory[@props.locationHistory.length - 1]?[0]?.state?.openMore

    React.createElement("div", {"className": "tile-action notes-list"},
      React.createElement("div", {"className": "tile-group"},
        React.createElement("div", {"className": "tile-title"},
          React.createElement("div", {"className": "box-btn box-right"},
            React.createElement("div", {"className": "hide-mobile"},
              React.createElement("a", {"className": "btn", "onClick": (@props.toggleEditNotePopup.bind @, @props.property, @props.company, null, @props.onNoteChanged)}, "+ ", React.createElement(CLabel, {"k": "property.add.new.note"}))
            ),
            React.createElement("div", {"className": "show-mobile"},
              React.createElement("a", {"className": "btn btn-small", "onClick": (@props.toggleEditNotePopup.bind @, @props.property, @props.company, null, @props.onNoteChanged)}, React.createElement("div", {"className": "box-arrow"}, React.createElement("i", {"className": "fa fa-plus"}))),
              React.createElement("a", {"className": "btn btn-small btn-slide-block", "onClick": (@mobileBlockToggle)}, React.createElement("div", {"className": "box-arrow"}, React.createElement("i", {"className": "fa fa-angle-up"})))
            )
          ),
          React.createElement("div", {"className": "name-block middle"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "property.actions.and.notes"}))),
          (if @props.subtitle
            React.createElement("div", {"className": "small bold", "style": (fontSize: "12px", lineHeight: "14px", overflow: "visible")}, (@props.subtitle))
          )
        )
      ),
      React.createElement("div", {"className": "slide-block open"},
        React.createElement(CItemNotesList, { \
          "showMore": (shouldShowMore),  \
          "notes": (@props.notes),  \
          "onNoteChanged": (@props.onNoteChanged),   \
          "onNoteDeleted": (@props.onNoteDeleted),  \
          "maxNotes": (@props.maxNotes)
        })
      )
    )

), {
  state: (state) ->
    locationHistory: state.app.locationHistory
  dispatch: (dispatch) ->
    toggleEditNotePopup: (property, company, note, onNoteChanged) -> dispatch(type: 'TOGGLE_POPUP_EDIT_NOTE', data: {property: property, company: company, note: note, onChanged: onNoteChanged})
}
