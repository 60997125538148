import { Trans } from 'react-i18next'
import { CInputWithLabel } from '../CInputWithLabel'

export const ChangesDelaysInWrapper = ({ delaysOnly, onChange }) => {
    return <div style={{ lineHeight: "34px" }}>
      <Trans i18nKey="timeline.fast.filter.changes.delays.in">
        <CInputWithLabel type="radio" id="modeChanges" name="delaysOnly" checked={!delaysOnly} value="false" onChange={onChange} />
        <CInputWithLabel type="radio" id="modeDelays" name="delaysOnly" checked={delaysOnly} value="true" onChange={onChange} />
      </Trans>
    </div>
}

export const DaysAgoWrapper = ({ daysAgo, onChange, onBlur }) => {
    return <div className="box-control general-changes-days-ago-wrapper">
        <Trans i18nKey="timeline.fast.filter.days.ago">
            <input
                onBlur={onBlur}
                name="daysAgo"
                value={daysAgo} maxLength="2" min="1" max="700"
                onChange={onChange} type="number" className="form-control micro"
                style={{ lineHeight: "34px", paddingRight: "1px" }}
              />
        </Trans>
    </div>
}