import WebSocketAdapter from "./WebSocketAdapter";

const realm = window.SETTINGS.realms[config.LE_ENV];
const webSocketReduxEventsMap = {
    "export-info": "SET_EXPORT_IN_PROGRESS",
    "queue-number": "SET_EXPORT_QUEUE_NUMBER",
    "user-data": "UPDATE_USER_DATA"
};

class RWebSocket {
    constructor(getStore) {
        this.getStore = getStore;
        this.token = null;

        this.wsAdapter = new WebSocketAdapter(realm.WS, {
            onOpen: this.onOpen.bind(this),
            onReopen: this.onOpen.bind(this),
            onMessage: this.onMessage.bind(this)
        });
        this.dispatcher = getStore?.()?.dispatch;
    }

    onOpen() {
        this.login(this.token);
    }

    dispatchMessage(type, payload) {
        if (!this.dispatcher) {
            this.dispatcher = this.getStore()?.dispatch;
        }
        this.dispatcher?.({ type, payload });
    }

    onMessage(message) {
        const reduxEvent = webSocketReduxEventsMap[message.type];
        if (reduxEvent) {
            this.dispatchMessage(reduxEvent, message.data);
        }
    }

    login(token) {
        this.wsAdapter.pushMessages("login", { token });
    }

    checkConnection() {
        if (!this.wsAdapter.isOpen()) {
            this.wsAdapter.connect();
        }
    }

    disconnect() {
        this.token = null;
        this.wsAdapter.disconnect();
    }

    connect(token) {
        if (!token) return;
        this.token = token;
        this.wsAdapter.connect();
    }
}

export default RWebSocket;