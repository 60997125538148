
require('./cresult.scss')
CRolesList = require('../property/CRolesList.js').default
CRoleShort = require('../property/CRoleShort.js').default
{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
CResultAddress = require '../../cmp/results/CResultAddress'
{ CLabel } = require '../../cmp/CLabel'
CTimeline = require '../../cmp/CTimeline'
{ prettifyFieldName, getHistoryColor, getNoteStatusColor, getProjectHistoryColor, getColorByChanges, isMobile, getUrgentNotesCount } = require '../../helpers/Utils'
{ itemInfoTooltip } = require '../../helpers/Blocks'
{ API } = require '../../redux/Store'

Moment = require 'moment'
Utils = require '../../helpers/Utils'
{ AccessRestriction } = require "../AccessRestriction"
{ translateCategoricalValue } = require '../../cmp/filters/CTranslateUtils'
{ withTranslation } = require 'react-i18next' 
#Perf = require 'react-addons-perf';
{ withTranslation, Trans } = require 'react-i18next'
{ getDateLocale } = require "../../helpers/LocaleHelpers";
{ onScrollTooltipPosition } = require '../../helpers/UtilsHelpers'
CItemNote = require '../../cmp/note/CItemNote'

module.exports = withTranslation() Connect createReactClass(
  displayName: "CResult"

  getInitialState: ->
    watching: @props.property?.watching
    lng: @props.i18n.resolvedLanguage
    open: false

  componentWillReceiveProps: (newProps)->
    if @state.lng != newProps.i18n.resolvedLanguage
      @setState lng: newProps.i18n.resolvedLanguage
    if @props.property?.watching != newProps.property?.watching
      @setState watching: newProps.property.watching

  componentDidMount: ->
    onScroll = onScrollTooltipPosition("property-tooltip-#{@props.property.id}", "property-tooltip-anchor-#{@props.property.id}")
    document.querySelector('.main-content').addEventListener 'scroll', onScroll

  componentWillUnmount: ->
    onScroll = onScrollTooltipPosition("property-tooltip-#{@props.property.id}", "property-tooltip-anchor-#{@props.property.id}")
    document.querySelector('.main-content').removeEventListener 'scroll', onScroll

  generateProjectInfo: (project)->
    result = []
    result.push React.createElement("div", {"key": "projectType", "className": "show-mobile item-info title"}, React.createElement("span", {"className": "bold project-label-color-#{project.projectType?.replace(' ', '').toLowerCase()}"}, (translateCategoricalValue(@props.t, "projectType", project.projectType))))
    if project.projectType in ["New Construction", "Renovation", "Conversion"] && (['showNewConstruction', 'showRenovations', 'showConversions'].some (it)=> @props.client?[it])
      result.push React.createElement("div", {"key": "projectStage", "style": (display: 'block'), "className": ("item-info #{getHistoryColor(project, "projectStage", "bold")}-in-frame")}, (translateCategoricalValue(@props.t, "projectStage", project.projectStage)))
      if project.startDate && project.scheduledOpen
        result.push React.createElement("div", {"key": "startDateScheduledOpen", "className": "grouped-item-info"},
          React.createElement("div", {"className": "item-info"}, React.createElement(CLabel, {"k": "results.result.start"}), " ", React.createElement("span", {"className": ("#{getHistoryColor(project, "startDate", "bold")}-in-frame")}, (Moment(project.startDate).tz('UTC').locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]")))),
          React.createElement("div", {"className": "item-info"}, React.createElement(CLabel, {"k": "results.result.open"}), " ", React.createElement("span", {"className": ("#{getHistoryColor(project, "scheduledOpen", "bold")}-in-frame")}, (Moment(project.scheduledOpen).tz('UTC').locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]"))))
        )
      else if project.startDate
        result.push React.createElement("div", {"key": "startDate", "className": "grouped-item-info"},
          React.createElement("div", {"className": "item-info"}, React.createElement(CLabel, {"k": "results.result.start"}), " ", React.createElement("span", {"className": ("#{getHistoryColor(project, "startDate", "bold")}-in-frame")}, (Moment(project.startDate).tz('UTC').locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]"))))
        )
      else if project.scheduledOpen
        result.push React.createElement("div", {"key": "scheduledOpen", "className": "grouped-item-info"}, React.createElement("div", {"className": "item-info"}, React.createElement(CLabel, {"k": "results.result.open"}), " ", React.createElement("span", {"className": ("#{getHistoryColor(project, "scheduledOpen", "bold")}-in-frame")}, (Moment(project.scheduledOpen).tz('UTC').locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]")))))
    if project.projectType == "Conversion" && @props.client?.showConversions
      if project.projectStage == "Recently Completed"
        result.push(React.createElement("div", {"key": "initialBrand", "className": "grouped-item-info"}, React.createElement("div", {"className": "item-info"}, React.createElement(CLabel, {"k": "results.result.initial.brand"}), " ", React.createElement("span", {"className": ("#{getHistoryColor(project, "initialBrand", "bold")}-in-frame")}, (project.initialBrand?.brandName)))))
      if project.newBrand && project.projectStage != "Recently Completed"
        result.push React.createElement("div", {"key": "newBrand", "className": "grouped-item-info"}, React.createElement("div", {"className": "item-info"}, React.createElement(CLabel, {"k": "results.result.new.brand"}), " ", React.createElement("span", {"className": ("#{getHistoryColor(project, "newBrand", "bold")}-in-frame")}, (project.newBrand?.brandName))))
      else if project.projectStage != "Recently Completed"
        result.push React.createElement("div", {"key": "newBrand", "className": "item-info"})
    if project.projectType == "Distressed Asset" && @props.client?.showDistressedAssets
      if project.assetStatus
        result.push React.createElement("div", {"key": "assetStatus", "className": "grouped-item-info"}, React.createElement("div", {"className": "item-info"}, React.createElement("span", {"className": ("#{getHistoryColor(project, "assetStatus", "bold")}-in-frame")}, (translateCategoricalValue(@props.t, "assetStatus", project.assetStatus)))))
      else
        result.push React.createElement("div", {"key": "assetStatus", "className": "item-info"})
    if project.projectType == "Sale" && @props.client?.showSales
      if project.dateOfSale
        result.push React.createElement("div", {"key": "dateOfSale", "className": "grouped-item-info"}, React.createElement("div", null, React.createElement("span", {"className": ("item-info #{getHistoryColor(project, "dateOfSale", "bold")}-in-frame")}, (Moment(project.dateOfSale).locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]"))), " ", React.createElement("span", {"className": ("item-info #{getHistoryColor(project, "sellingPricePerRoom", "bold")}-in-frame")}, (if project.sellingPrice then '$' + Utils.formatNumber(project.sellingPrice) else null))))
    if project?.timeline?.length > 1
      result.push React.createElement("div", {"key": "timeline", "className": "item-info title"}, React.createElement("a", {"id": "property-tooltip-anchor-#{@props.property.id}", "className": "black timeline tooltip tooltip-top"}, React.createElement("span", {"id": "property-tooltip-#{@props.property.id}", "className": "tooltiptext"}, React.createElement(CTimeline, {"timeline": (project.timeline)})), React.createElement("span", {"className": "timeline-label"}, React.createElement(CLabel, {"k": "timeline.results.title"}), " ", React.createElement("i", {"className": (Utils.getColorByDate(project.timeline[project.timeline.length - 1].asOf, "black") + " fa fa-calendar")})))) 
    return result

  generateItem: (object, fieldName, label, historyClass)->
    if object?[fieldName]
      React.createElement("div", {"className": "item-info item-info-#{fieldName} text-overflow"},
        React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": (label)})),
        React.createElement("span", {"className": (historyClass)}, (translateCategoricalValue(@props.t, fieldName, object[fieldName])))
      )

  shouldComponentUpdate: (nextProps, nextState)->
    if (nextProps.property.notes.length != @props.property.notes.length)
      return true
    areNotesEqual = nextProps.property.notes.every((note, index) =>
      oldNote = @props.property.notes[index]
      if (!oldNote)
        return false
      return oldNote.id == note.id && note.updatedAt == oldNote.updatedAt
    )
    if (!areNotesEqual)
      return true
    if (nextState.open != @state.open)
      return true
    if (Object.keys(@props.searchFieldsInfo)?.length != Object.keys(nextProps.searchFieldsInfo)?.length)
      return true
    if (nextState.lng != @state.lng)
      return true
    nextState.watching != @state.watching or nextProps.property?.nextNoteAlertDate != @props.property?.nextNoteAlertDate or @props.property?.notes?.length != nextProps.property?.notes?.length

  exclude: (property)->
    #Perf.start()
    @props.excludeProperty(id: property.id, name: property.name)

  toggleWatch: (propertyId)->
    if @state.watching
      @props.deleteWatch(propertyId, ()=> @setState watching: false)
    else
      @props.updateWatch(propertyId, ()=> @setState watching: true)

  onNoteChanged: (note)->
    @props.editNote(@props.property.id, note)

  onDeleted: (note)->
    @props.deleteNote(@props.property.id, note.id)

  onMouseEnter: ()->
    @setState open: true

  onMouseLeave: ()->
    @setState open: false

  render: ()->
    propertyColor = Utils.getColorByDate(@props.property.createdAt)
    customPropertySetLabel = if @props.searchFieldsInfo?.branch?.label then translateCategoricalValue(@props.t, "customPropertySet", @props.searchFieldsInfo.branch.label) else React.createElement(CLabel, {"k": "results.result.branch"})
    note = {
      ...Utils.getNoteForPopup(@props.property.notes, @props.user),
      property: @props.property
    }
    noteColor = Utils.getNextNoteAlertColor(@props.property.notes, @props.user)
    fkeys = [@props.property.sourceIdLe, ...@props.property?.fkeys?.map((i, idx) -> i.id)].filter (x) -> x

    React.createElement("div", {"className": "block-tile"},
      React.createElement("div", {"className": "box-tile"},
        React.createElement("div", {"className": "tile-group"},
          React.createElement("div", {"className": "tile-title"},
            React.createElement("div", {"className": "box-nav box-right"},
              React.createElement("div", {"className": "hide-mobile-small"},
                (if @state.watching
                  React.createElement(AccessRestriction, null,
                    React.createElement("a", {"className": "btn link-grey watch active tooltip", "onClick": (@toggleWatch.bind @, @props.property?.id)},
                      React.createElement("span", {"className": "tooltiptext"}, React.createElement(CLabel, {"k": "results.result.tooltip.remove.watch"})),
                      React.createElement("i", {"className": "fa fa-eye"})
                    )
                  )
                else
                  React.createElement(AccessRestriction, null,
                    React.createElement("a", {"className": "btn link-grey watch tooltip #{if isMobile() then 'nohover'}", "onClick": (@toggleWatch.bind @, @props.property?.id)},
                      React.createElement("span", {"className": "tooltiptext"}, React.createElement(CLabel, {"k": "results.result.tooltip.add.watch"})),
                      React.createElement("i", {"className": "fa fa-eye"})
                    )
                  )
                ),
                (if @props.property?.notes?.length == 0
                  React.createElement(Link, {"to": ("/property/#{@props.property?.id}"), "className": ("btn tooltip save #{noteColor}")},
                    React.createElement("span", {"className": "tooltiptext"}, React.createElement(CLabel, {"k": "results.result.tooltip.no.notes"})),
                    React.createElement("i", {"className": "fa fa-file-text-o"})
                  )
                ),
                (if @props.property?.notes?.length > 0 && !@props.hasAccessToAssignableActions
                  React.createElement(Link, {"to": ("/property/#{@props.property?.id}"), "className": ("btn tooltip save #{noteColor}")},
                    React.createElement("span", {"className": "tooltiptext"}, React.createElement(CLabel, {"k": "results.result.tooltip.show.notes"})),
                    React.createElement("i", {"className": "fa fa-file-text-o"})
                  )
                ),
                (if @props.property?.notes?.length > 0 && @props.hasAccessToAssignableActions
                  React.createElement("div", {"className": "tooltip save #{noteColor}", "onMouseLeave": (@onMouseLeave), "onMouseEnter": (@onMouseEnter), "onClick": (@onMouseEnter), "onTouchStart": (@onMouseEnter)},
                    (if @state.open then React.createElement("div", {"className": "note-popover"},
                      React.createElement("div", {"className": "block-tile"},
                        React.createElement("div", {"className": "box-tile selected"},
                          React.createElement("div", {"className": "tile-action notes-list"},
                            React.createElement("div", {"className": "tile-group", "style": ({ paddingBottom: 0 })},
                                React.createElement(CItemNote, { \
                                  "note": (note),  \
                                  "truncated": true,  \
                                  "hideMenu": true,  \
                                  "onChanged": (@onNoteChanged),  \
                                  "onDeleted": (@onDeleted),  \
                                  "shouldFetchHistory": true
                                })
                            ),
                            React.createElement("div", {"className": "tile-group tile-group-more"},
                              React.createElement(Link, { \
                                "to": ({
                                  pathname: "/property/#{@props.property?.id}",
                                  state: { openMore: getUrgentNotesCount(@props.property?.notes) > 1 }
                                }),  \
                                "className": "btn tooltip save"
                              },
                                (if getUrgentNotesCount(@props.property?.notes) > 1 then  React.createElement(CLabel, {"k": "menu.moreDueSoon"}) else React.createElement(CLabel, {"k": "menu.more"}))
                              )
                            )
                          )
                        )
                      )
                    )),
                    React.createElement("i", {"className": "fa fa-file-text-o"})
                  )
                ),
                React.createElement("a", {"className": "btn link-grey tooltip", "onClick": (@exclude.bind @, @props.property)}, React.createElement("span", {"className": "tooltiptext"}, React.createElement(CLabel, {"k": "results.result.tooltip.remove.from.results"})), React.createElement("i", {"className": "fa fa-close"}))
              )
            ),
            
            React.createElement(Link, {"className": ("results-property-name dark-grey bold tooltip #{getHistoryColor(@props.property, "name")}"), "to": ("/property/#{@props.property?.id}"), "title": (@props.property?.name)},
              React.createElement("span", {"className": "name-block medium box-inline"},
                (@props.property?.name)
              )
            ),
            (if propertyColor
              React.createElement("div", {"style": (if propertyColor == "orange" then background: "orange"), "className": "label-status"}, React.createElement(CLabel, {"k": "results.result.new.label"}))
            )
          ),
          React.createElement("table", {"className": "table table-border"},
            React.createElement("tbody", null,
              React.createElement("tr", null,
                React.createElement("td", {"className": "cell-address"},
                  React.createElement("span", {"style": ({ verticalAlign: 'top' })},
                    React.createElement(CResultAddress, {"property": (@props.property)})
                  ),
                  (if @props.property?.phone then React.createElement("div", {"style": (display: 'block'), "className": ("phone #{getHistoryColor(@props.property, "phone", "bold")}")},
                    React.createElement(Trans, {"i18nKey": "property.contact.phoneNumber", "values": ({
                      phoneNumber: @props.property?.phone
                    })},
                      React.createElement("span", null, "P: "),
                      React.createElement("a", {"className": "link link-phone", "target": "_top", "href": "tel:#{Utils.formatPhoneNumber(@props.property?.phone)}"}, (@props.property?.phone))
                    )
                  ))
                ),
                React.createElement("td", {"className": "cell-details"},
                  (@generateItem(@props.property, "rooms", "results.result.rooms", getHistoryColor(@props.property, "rooms", "bold"))),
                  (@generateItem(@props.property, "chainScale", "results.result.chain.scale", getHistoryColor(@props.property, "chainScale", "bold"))),
                  (@generateItem(@props.property.brand, "brandName", "results.result.brand.label", getHistoryColor(@props.property, "brandId", "bold"))),
                  (@generateItem(@props.property.brand, "brandCompany", "results.result.brand.company", getHistoryColor(@props.property, "brandCompany", "bold")))
                ),
                React.createElement("td", {"className": "cell-details"},
                  (@generateItem(@props.property, "market", "results.result.market", getHistoryColor(@props.property, "market", "bold"))),
                  (@generateItem(@props.property, "tract", "results.result.tract", getHistoryColor(@props.property, "tract", "bold"))),
                  (@generateItem(@props.property, "county", "results.result.county", getHistoryColor(@props.property, "county", "bold"))),

                  (if @props.property.usersWithAccess
                    React.createElement("span", {"className": "territory tooltip-bottom #{getHistoryColor(@props.property, 'usersWithAccess', 'bold')}"},
                      React.createElement("div", {"className": "item-info text-overflow"},
                        React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "results.result.territory"})),
                        (if @props.property.usersWithAccessFull
                          React.createElement("span", null,
                            React.createElement("span", {"className": "tooltiptext"},
                              (@props.property.usersWithAccessFull.map (i, idx)->
                                React.createElement("div", {"key": (idx)},
                                  (i)
                                )
                              )
                            ),
                            (if @props.property.usersWithAccess == "None" then React.createElement(CLabel, {"k": "results.result.none"}) else @props.property.usersWithAccess)
                          )
                        else
                          React.createElement("span", {"className": "#{getHistoryColor(@props.property, 'userWithAccess', 'bold')}"},
                            (if @props.property.usersWithAccess == "None" then React.createElement(CLabel, {"k": "results.result.none"}) else @props.property.usersWithAccess)
                          )
                        )
                      )
                    )
                  ),
                  (@props.searchFieldsInfo.branch && itemInfoTooltip(@props.property, "propertySets", customPropertySetLabel, 148)),
                  (@generateItem(@props.property, "keywords", "results.result.keywords", getHistoryColor(@props.property, "keywords", "bold")))
                ),
                React.createElement("td", {"className": "cell-info projects #{if @props.property?.projects?.length == 0 then 'search-result-property-projects'}"},
                  (if @props.property?.projects?.length > 0
                    React.createElement("div", null,
                      ((@props.property?.projects || []).map (project, i)=>
                        React.createElement("div", {"className": "info-hotel project-color project-color-#{project.projectType?.replace(' ', '').toLowerCase()}", "key": (project.id)},
                          React.createElement("div", {"key": "projectType", "className": "item-info title hide-mobile"}, React.createElement("span", {"className": ("bold #{getProjectHistoryColor(project)}-in-frame")}, (translateCategoricalValue(@props.t, "projectType", project.projectType)))),
                          (@generateProjectInfo(project))
                        )
                      ),
                      React.createElement("div", {"className": "search-result-property-ids"},
                        React.createElement("div", {"style": (color: '#9f9f9f', marginRight: '15px')}, (fkeys.join(', '))),
                        React.createElement("div", {"className": "search-result-property-ids-id", "style": (color: '#9f9f9f', alignItems: 'flex-start')}, React.createElement("span", null, (@props.property?.id)))
                      )
                    )
                  else
                    React.createElement("div", {"className": "search-result-property-ids"},
                      React.createElement("div", {"style": (color: '#9f9f9f')},
                        (fkeys.join(', '))
                      ),
                      React.createElement("div", {"className": "search-result-property-ids-id", "style": (color: '#9f9f9f')}, React.createElement("span", null, (@props.property?.id)))
                    )
                  )
                )
              )
            )
          )
        ),
        React.createElement("div", {"className": "tile-bottom tile-bottom-contacts hide-mobile"},
          React.createElement(CRolesList, {"property": (@props.property), "RenderItemAs": (CRoleShort)})
        )
      )
    )
), {
  state: (state)->
    searchFieldsInfo: state.app.searchFieldsInfo
    form: state.searchForm.form
    client: state.session?.user?.client
    user: state.session?.user
    searchFieldsInfo: state.app.searchFieldsInfo
    hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions")

  dispatch: (dispatch)->
    excludeProperty: (option)-> dispatch(type: 'EXCLUDE_PROPERTY', option: option)
    deleteWatch: (propertyId, cb)-> dispatch(type: 'DELETE_WATCH', propertyId: propertyId, cb: cb)
    updateWatch: (propertyId, cb)-> dispatch(type: 'UPDATE_WATCH', propertyId: propertyId, cb: cb)
    editNote: (id, note)-> dispatch(type: 'EDIT_NOTE_IN_SEARCH_RESULT', id: id, note: note)
    deleteNote: (id, noteId)-> dispatch(type: 'DELETE_NOTE_IN_SEARCH_RESULT', id: id, noteId: noteId)
}
