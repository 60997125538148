Connect = require '../../helpers/Connect'
{ CLabel } = require '../CLabel'
Const = require '../../helpers/Const'
{ formatPhoneNumber, getNoteStatusColor, joinStrings } = require '../../helpers/Utils'
{API} = require '../../redux/Store'
{ Link } = require 'react-router'
{ AccessRestriction } = require '../AccessRestriction'
{ withTranslation, Trans } = require 'react-i18next' 
camelCase = require 'lodash.camelcase'

module.exports = withTranslation() Connect createReactClass(

  getInitialState: ->
      company: null, contacts: []

  componentDidMount: ()->
    @load(@props.companyId)

  load: (companyId)->
    if !companyId
      @props.router.push("/search")
      return

    API.getCompany companyId, (company)=>
      contacts = (company.contacts || []).sort (a, b)->
        a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)
      @setState company: company, contacts: contacts

  gotoProperties: ->
    @props.toggleCompanyCardPopup()
    @props.initSearchForm()
    @props.setSearchType(Const.SEARCH_TYPE_PROPERTIES)
    @props.addValueToSearchForm('companyId', @state.company.id)
    @props.router.push("/results")

  generatePortfolioListing: ->
    @props.toggleCompanyCardPopup()
    @props.initSearchForm()
    @props.setForm(isCompanyPortfolio: true, isOpaqueFilter: true)
    @props.addValueToSearchForm("companyId", @state.company.id)
    @props.setActiveCompany(@state.company)
    @props.router.push("/results")

  render: ->
    React.createElement("div", {"className": "modal open"},
      React.createElement("div", {"className": "modal-header-mobile show-mobile"},
        React.createElement("a", {"className": "btn btn-back close-modal", "onClick": (@props.toggleCompanyCardPopup)},
          React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.custom.popup.close"}))
        ),
        React.createElement("div", {"className": "title-page"})
      ),
      React.createElement("div", {"className": "modal-dialog modal-sm"},
        React.createElement("div", {"className": "modal-content"},
          React.createElement("div", {"className": "modal-body"},
            (if !@state.company
              React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, "Loading..."))
            else
              React.createElement("div", {"className": "company-card"},
                React.createElement("div", {"className": "block-tile"},
                  React.createElement("div", {"className": "box-tile"},
                    React.createElement("div", {"className": "tile-group hide-mobile"},
                      React.createElement("div", {"className": "tile-title"},
                        (if @state.company.note
                          React.createElement("div", {"className": "contact-note-popup-icon"},
                              React.createElement(Link, {"to": ("/company/#{@state.company.id}"), "className": ("btn save #{getNoteStatusColor(@state.company.note?.dateTo, "green-text")}")},
                                React.createElement("i", {"className": "fa fa-file-text-o"})
                              )
                          )
                        ),
                        React.createElement("a", {"className": "small tooltip black", "style": ({ display: "block" }), "title": (@state.company?.name)},
                          React.createElement("div", {"className": "name-block"}, (@state.company?.name))
                        )
                      )
                    ),
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("div", {"className": "name-block show-mobile"}, (@state.company?.name)),
                      React.createElement("table", {"className": "table table-border"},
                        React.createElement("tbody", null,
                        React.createElement("tr", null,
                          React.createElement("td", null,
                            React.createElement("div", {"className": "label hide-mobile"}, React.createElement(CLabel, {"k": 'company.address'})),
                            React.createElement("p", null, (@state.company?.address)),
                            React.createElement("p", null, (@state.company?.city), (if @state.company?.stateProvince then ', ' + @state.company?.stateProvince), " ", (@state.company?.postalCode)),
                            React.createElement("p", null, (@state.company?.country)),
                            (if @state.company?.phone
                              React.createElement(AccessRestriction, null,
                                React.createElement(Trans, {"i18nKey": "property.contact.phoneNumber", "values": ({
                                  phoneNumber: @state.company?.phone
                                })},
                                  React.createElement("span", null, "P: "),
                                  React.createElement("a", {"className": "link link-phone", "target": "_top", "href": "tel:#{formatPhoneNumber(@state.company?.phone)}"},
                                    (@state.company?.phone)
                                  )
                                )
                              )
                            )
                          ),
                          React.createElement("td", {"className": "cell-roles hide-mobile"},
                            React.createElement("div", {"className": "box-inline"},
                              React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "company.roles"})),
                              ((@state.company?.roles || []).map((role, i)=>
                                React.createElement("p", {"key": (i)}, (@props.t("data.company.roles.#{camelCase(role)}", { ns: "data" })))
                              ))
                            )
                          ),
                          React.createElement("td", {"className": "cell-size hide-mobile"},
                            React.createElement("div", {"className": "box-inline"},
                              React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "company.portfolio.size"})),
                              React.createElement("p", null, (@props.t("company.property", { count: @state.company?.portfolioSize }))),
                              React.createElement("p", null, React.createElement("a", {"onClick": (@gotoProperties)}, React.createElement(CLabel, {"k": "company.in.my.territory", "param": ({ count: @state.company?.territoryPortfolioSize })}))),
                              React.createElement("a", {"className": "link link-grey-small", "onClick": (@generatePortfolioListing)}, React.createElement(CLabel, {"k": "company.generate.portfolio.listing"}))
                            )
                          )
                        )
                        )
                      )
                    )
                  )
                ),
                React.createElement("div", {"className": "block-tile show-mobile"},
                  React.createElement("div", {"className": "box-tile"},
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("table", {"className": "table table-border no-transform"},
                        React.createElement("tbody", null,
                        React.createElement("tr", null,
                          React.createElement("td", {"className": "cell-roles"},
                            React.createElement("div", {"className": "box-inline"},
                              React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "company.roles"})),
                              ((@state.company?.roles || []).map((role, i)=>
                                React.createElement("p", {"key": (i)}, (@props.t("data.company.roles.#{camelCase(role)}", { ns: "data" })))
                              ))
                            )
                          ),
                          React.createElement("td", {"className": "cell-size"},
                            React.createElement("div", {"className": "box-inline"},
                              React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "company.portfolio.size"})),
                              React.createElement("p", null, (@props.t("company.property", { count: @state.company?.portfolioSize }))),
                              React.createElement("p", null, React.createElement("a", {"onClick": (@gotoProperties)}, React.createElement(CLabel, {"k": "company.in.my.territory", "param": ({ count: @state.company?.territoryPortfolioSize })}))),
                              React.createElement("a", {"className": "link link-grey-small", "onClick": (@generatePortfolioListing)}, React.createElement(CLabel, {"k": "company.generate.portfolio.listing"}))
                            )
                          )
                        )
                        )
                      )
                    )
                  )
                ),
                React.createElement("div", {"className": "block-tile"},
                  React.createElement("div", {"className": "box-tile"},
                    React.createElement("div", {"className": "box-slide-block open"},
                      React.createElement("div", {"className": "tile-group"},
                        React.createElement("div", {"className": "tile-title"},
                          React.createElement("div", {"className": "box-btn box-right show-mobile"},
                            React.createElement("a", {"className": "btn btn-small btn-slide-block"}, React.createElement("div", {"className": "box-arrow"}, React.createElement("i", {"className": "fa fa-angle-up"})))
                          ),
                          React.createElement("div", {"className": "name-block middle"},
                            React.createElement(CLabel, {"k": "company.contacts"})
                          )
                        )
                      ),
                      React.createElement("div", {"className": "slide-block open"},
                        (@state.contacts?.map((it, idx)->
                          React.createElement("div", {"className": "item-contact", "key": (idx)},
                            React.createElement("div", {"style": (height: '14px'), "className": "box-name"}, (joinStrings([it?.fullName, it?.title]))),
                            (if it?.phone then React.createElement("div", {"className": "box-phone box-right"},
                            React.createElement(AccessRestriction, null,
                                React.createElement(Trans, {"i18nKey": "property.contact.phoneNumber", "values": ({
                                  phoneNumber: it?.phone
                                })},
                                  React.createElement("span", null, "P: "),
                                  React.createElement("a", {"className": "link link-phone", "target": "_top", "href": "tel:#{formatPhoneNumber(it?.phone)}"}, (it?.phone))
                                )  
                              )
                            ))
                          )
                        ))
                      )
                    )
                  )
                )
              )
            )
          ),
          React.createElement("div", {"className": "modal-footer hide-mobile"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "box-btn box-right"},
                React.createElement("a", {"className": "btn btn-grey btn-sm close-modal", "onClick": (@props.toggleCompanyCardPopup)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.custom.popup.close"})))
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": "modal-footer-mobile show-mobile"},
        React.createElement("a", {"className": "btn btn-red btn-normal close-modal", "onClick": (@props.toggleCompanyCardPopup)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.custom.popup.close"})))
      )
    )
), {

  dispatch: (dispatch)->
    toggleCompanyCardPopup: ()-> dispatch(type: 'TOGGLE_POPUP_COMPANY_CARD')
    addValueToSearchForm: (name, value) ->
          dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM_AND_SEARCH', name: name, value: value)
    setSearchType: (searchType) -> dispatch(type: 'SET_SEARCH_TYPE', searchType: searchType)
    initSearchForm: (form) -> dispatch(type: 'INIT_SEARCH_FORM')
    setForm: (form) -> dispatch(type: 'SET_FILTER_FORM', form: form)
    setActiveCompany: (activeCompany) -> dispatch(type: 'SET_ACTIVE_COMPANY', activeCompany: activeCompany)
}