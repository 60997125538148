import React from "react";
import { useEffect } from "react";
import { API } from "../../redux/Store";
import { CLabel } from "../CLabel";
import CPeriodFilter from "./CPeriodFilter";
import Connect from "../../helpers/Connect";
import { isMobile } from "../../helpers/Utils.cjsx"
import CLoading from "../CLoading";
import { debounce } from "throttle-debounce";
import Moment from "moment";
import { onScrollTooltipPosition } from "../../helpers/UtilsHelpers";
const LAST_WEEK = "lastWeek"
const LAST_MONTH = "last30days"
const LAST_LOGIN = "lastLogin"
const TOTAL = "total"

const CWatchList2 = ({ period, router, lastLoginDate, addValueToForm, onTooltip, showTooltip }) => {
  const [watchCount, setWatchCount] = React.useState(null);
  const [watchEmpty, setWatchEmpty] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [showLoading, setShowLoading] = React.useState(false);
  const setShowLoadingDebounce = debounce(300, setShowLoading);

  useEffect(() => {
    setShowLoadingDebounce(true);
    const onScrollFn = onScrollTooltipPosition("watch-tooltip", "watch-tooltip-anchor");
    document.querySelector('.main-content').addEventListener('scroll', onScrollFn);
    API.getWatchCount((data) => {
      setLoading(false)
      setShowLoading(false)
      setShowLoadingDebounce(false)
      setWatchCount({
        [LAST_WEEK]: data.days7,
        [LAST_MONTH]: data.days30,
        [LAST_LOGIN]: data.lastLogin,
      });

      setWatchEmpty(data.total == 0)
    });
    return () => {
      document.querySelector('.main-content').removeEventListener('scroll', onScrollFn);
    }
  }, []);

  const goToWatchList = () => {
    const daysAgo = (period === LAST_MONTH && 30) || (period === LAST_LOGIN && Math.floor(Moment.duration(Moment().diff(Moment(lastLoginDate))).asDays())) || 7

    addValueToForm('propertyChanges',
      { mode: 'DAYS', daysAgo, invalid: false, enabled: true, values: { updated: true, interestingOnly: true, project: { updated: true, interestingOnly: true } }}
    );
    router.push("/watches");
  };

  return <div className="tile-group home-watchlist" style={{ height: '160px' }}>
    <div className="tile-title">
    <div className="box-btn box-right" onClick={isMobile() ? onTooltip : undefined}>
        <a id="watch-tooltip-anchor" className="btn btn-info tooltip-bottom">
          <span id="watch-tooltip" className="tooltiptext"><CLabel k="home.watchlist.info" /></span>
          <i className="fa fa-info"></i>
        </a>
      </div>
      <div className="name-block"><span className="upper bold"><CLabel k="home.watch.list" /></span></div>
    </div>
    {showLoading && <div className="no-info"><CLoading /></div>}
    {!loading && watchEmpty && <div className="no-info"><CLabel k="home.watch.list.not.watching" /></div>}
    {!loading && watchCount && !watchEmpty && <div>
      <CPeriodFilter period={period || LAST_WEEK} />
      <div className="info-count large info-count-middle" style={{ cursor: "pointer" }} onClick={goToWatchList}>
        <span><span className="count">{watchCount[period || LAST_WEEK]}</span>
          {watchCount[period || LAST_WEEK] == 1 ?
            <CLabel k="home.watch.list.updated.record" />
            :
            <CLabel k="home.watch.list.updated.records" />
          }
        </span>
      </div>
    </div>}
  </div>;
};

export default Connect(CWatchList2, {
  state: (state) => {
    return {
      lastLoginDate: state.app?.properties?.lastLoginDate,
      period: state.watches?.period
    }
  },
  dispatch: (dispatch) =>({
      addValueToForm: (name, value) => dispatch({ type: 'ADD_VALUE_TO_SEARCH_FORM', name, value})
    })
});