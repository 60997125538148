Connect = require '../helpers/Connect'
Header = require '../pages/parts/Header'
Menu = require('../pages/parts/Menu').default
CChart = require '../cmp/home/charts/CChart'
{ CLabel } = require '../cmp/CLabel'
{ API } = require '../redux/Store'
{ getParsedData } = require '../helpers/Charts'
Utils = require '../helpers/Utils'
An = require '../helpers/Analytics'
camelCase = require 'lodash.camelcase';
{ getChartTitle } = require "../helpers/ChartHelpers"



{ withTranslation } = require 'react-i18next' 

module.exports = withTranslation() Connect createReactClass(
  displayName: "ChartGallery"

  componentDidMount: ->
    An.main.charts()
    @props.loadCharts()

  translateLabels: (chartData) ->
    data = chartData.data?.map (item) =>
      chartData.dimensions?.forEach (dimension, index) =>
        if dimension.toLowerCase().includes("chainscale")
          value = item["dimension" + (index + 1)].value
          if value == '~'
            value = 'Unbranded'
          item["dimension" + (index + 1)].label = @props.t("data.chainScale." + camelCase(value), { ns: "data" });
          return;
        if dimension.toLowerCase().includes("projectstage")
          item["dimension" + (index + 1)].label = @props.t("data.projectStage." + camelCase(item["dimension" + (index + 1)].value), { ns: "data" });
          return;
      item
    return {
      ...chartData,
      data: data
    }

  render: ->
    React.createElement("div", {"className": "wrapper-content chart-gallery-page"},
      React.createElement(Header, {"title": (@props.route.title), "backLabel": (@props.route.backLabel)}),
      React.createElement("div", {"className": "main"},
        React.createElement("div", {"className": "main-content"},
          React.createElement("div", {"className": "container"},
            React.createElement(Menu, {"className": "hide-mobile"}),
            React.createElement("div", {"className": "dashboard"},
              React.createElement("div", {"className": "nav-page"},
                React.createElement("div", {"className": "name"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "chart.gallery.title", "param": ({ name: Utils.formatBelonging(@props.firstName) })})))
              ),
              React.createElement("div", {"className": "nav-page subtitle"}, React.createElement(CLabel, {"k": "chart.gallery.description"})),
              React.createElement("div", {"className": "chart-gallery dashboard-group"},
                (if @props.status
                  React.createElement("span", {"className": "fa fa-exclamation-circle error-message"}, React.createElement(CLabel, {"k": "error.loading.charts"}))
                ),
                (if @props.charts?.length > 0
                  @props.charts.map (chart, id)=>
                    chartData = getParsedData(@translateLabels(chart), id, @props.t, @props.i18n)
                    if chartData
                      React.createElement("div", {"className": "block-tile row-half", "style": (marginBottom: 0), "key": (id)},
                        React.createElement("div", {"className": "box-tile"},
                          React.createElement("div", {"className": "tile-title grey", "style": (paddingBottom: '8px')},
                            React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "upper"}, (getChartTitle(chart, @props.t))))
                          ),
                          React.createElement("div", {"style": (marginBottom: '15px'), "className": "box-charts  #{if chartData instanceof Array then 'double-chart'}"},
                            (if chartData instanceof Array
                              React.createElement("div", null,
                                React.createElement("div", {"className": "chart-info"},
                                  (chartData[0].data.slice(1).map (data, idx)->
                                    React.createElement("div", {"className": "item-info", "style": (fontSize: '12px'), "key": (idx)}, React.createElement("i", {"className": "fa fa-circle", "style": (fontSize: '1em', color: chartData[0].options.colors[idx])}), " ", (data[0]))
                                  )
                                ),
                                React.createElement("div", {"className": "clearfix"},
                                  (chartData.map (it, id)->
                                    React.createElement(CChart, Object.assign({},  it, {"isDoubleChart": (true), "key": (id), "index": (id), "chart": (chart)}))
                                  )
                                )
                              )
                            else
                              React.createElement(CChart, Object.assign({},  chartData, {"chart": (chart)}))
                            )
                          )
                        )
                      )
                else if @props.isLoading
                  React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
                )
              )
            )
          )
        )
      )
    )
),
state: (state)->
  charts: state.charts.data
  isLoading: state.charts.isLoading
  status: false
  firstName: state.session?.user?.firstName
  translation: state.translation
dispatch: (dispatch)->
  loadCharts: ()-> dispatch(type: 'LOAD_CHARTS', isLoading: true)
