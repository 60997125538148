Connect = require '../../helpers/Connect'
{ CLabel } = require '../CLabel'
{ API } = require '../../redux/Store'
Utils = require '../../helpers/Utils'

{ extend } = require '../../helpers/UtilsHelpers'

module.exports = Connect createReactClass(
  displayName: 'CTargetAccountsPopup'
  xhrForKeywords: null

  getInitialState: ->
    options: []
    values: @parseValues(@props.form?.keywords?.value)

  componentDidMount: ->
    @props.initSearchForm()

    @xhrForKeywords = API.dictionary 'keywords', @props.form, (list)=>
      if list.length > 0
        if list[0].substring
          @setState options: list.map (e)-> label: e, value: e
        else
          @setState options: list

  componentWillUnmount: ->
    if @xhrForKeywords
      @xhrForKeywords.abort()

  parseValues: (v)->
    if v then (v.split(";").map (e)=> Utils.trimQuotes e) else []

  select: (option)->
    v = @state.values
    if option.value in v
      v?.splice(v?.indexOf(option.value), 1)
    else
      v.push(option.value)
    @setState values: v
    @putValueFromDropDown('keywords', v, @state.options)

  putValueFromDropDown: (name, values, options)->
    values = if values.length == 0 then null else (values.map (e)=> "'#{e}'").join(";")
    @props.addValueToSearchForm(name, values, options)

  generate: ->
    @props.setForm(extend @props.form, isOpaqueFilter: true)
    @props.togglePopup()
    @props.router.push('/results')

  render: ->
    React.createElement("div", {"className": "modal open"},
      React.createElement("div", {"className": "modal-header-mobile show-mobile"},
        React.createElement("a", {"className": "btn btn-back close-modal", "onClick": (@props.togglePopup)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.custom.popup.close"}))),
        React.createElement("div", {"className": "title-page"}, React.createElement(CLabel, {"k": "reports.target.accounts"}))
      ),
      React.createElement("div", {"className": "modal-dialog modal-sm"},
        React.createElement("div", {"className": "modal-content"},
          React.createElement("div", {"className": "modal-header"},
            React.createElement("div", {"className": "modal-title", "style": (textAlign: 'center')},
              React.createElement("div", {"className": "name-block upper bold center"}, React.createElement(CLabel, {"k": "reports.target.accounts"}))
            )
          ),
          React.createElement("div", {"className": "modal-body"},
            ((@state.options || []).map (option, i)=>
              if option
                React.createElement("form", {"className": "form", "key": (i)},
                  React.createElement("div", {"className": "box-control"},
                    React.createElement("div", {"className": "box-checkbox middle"},
                      React.createElement("input", {"className": "form-control", "onChange": (@select.bind @, option), "checked": (option.value in @state.values), "type": "checkbox", "id": ("dropdown_keywords_#{i}")}),
                      React.createElement("label", {"style": (fontSize: '16px'), "htmlFor": ("dropdown_keywords_#{i}")}, (option.label || option.keyword))
                    )
                  )
                )
            )
          ),
          React.createElement("div", {"className": "modal-footer hide-mobile"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "box-btn box-right"},
                React.createElement("a", {"className": "btn btn-grey btn-sm close-modal", "onClick": (@props.togglePopup)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.custom.popup.cancel"}))),
                React.createElement("a", {"className": "btn btn-red btn-sm close-modal #{if @state.values.length == 0 then 'disabled'}", "onClick": (@generate)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "reports.generate"})))
              )
            )
          ),
          React.createElement("div", {"className": "modal-footer-mobile show-mobile", "style": (background: "white")},
            React.createElement("a", {"className": "btn btn-red btn-normal close-modal #{if @state.values.length == 0 then 'disabled'}", "onClick": (@generate)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "reports.generate"})))
          )
        )
      )
    )
), {

  dispatch: (dispatch) ->
    initSearchForm: ()-> dispatch(type: 'INIT_SEARCH_FORM')
    setForm: (form) -> dispatch(type: 'SET_FILTER_FORM', form: form)
    togglePopup: () -> dispatch(type: 'TOGGLE_POPUP_TARGET_ACCOUNTS')
    addValueToSearchForm: (name, value, options) -> dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value, options: options)

  state: (state) ->
    form: state.searchForm?.form
    targetAccountsPopup: state.popup?.TOGGLE_POPUP_TARGET_ACCOUNTS
}