import React, { useEffect, useState, useCallback, useRef } from "react";
import Header from "../pages/parts/Header";
import Menu from "../pages/parts/Menu";
import { CLabel } from "../cmp/CLabel";
import Connect from "../helpers/Connect";
import WatchListItems from "./WatchListItems";
import An from "../helpers/Analytics";
import CInlineDropdown from "../cmp/filters/CInlineDropdown";
import { API } from "../redux/Store";
import "./watch_list.scss";
import CSearchFilter from "../notes/CSearchFilter";
import { getSearchForm, cleanupFilter } from "../cmp/results/CFilterUtils";
import * as Moment from "moment";
import { debounce } from "throttle-debounce";
import VisibilitySensor from "./VisibilitySensor";
import { Trans, useTranslation } from 'react-i18next';


const WatchList2 = ({
  addValueToForm,
  route,
  user,
  translation,
  toggleFilterPopup,
  searchForm,
  initSearchForm,
  savedFilters,
  sharedFilters,
  setForm,
  router,
  setSearchType,
  setStorageKey
}) => {
  const savedState = JSON.parse(sessionStorage.getItem(Const.WATCH_LIST_FILTER_STATE));
  const savedSelectedUserId = savedState && savedState.selectedUserId;
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(savedSelectedUserId ? savedSelectedUserId : user?.id);
  const [useSavedSearch, setUseSavedSearch] = useState(savedState ? savedState.useSavedSearch : null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [watchList, setWatchList] = useState(null);
  const [page, setPage] = useState(0);
  const { t, i18n } = useTranslation();
  const searchFormRef = useRef();
  searchFormRef.current = searchForm;

  const loadData = useCallback(() => {
    setLoading(true);
    const watchFilter = {
      isToggleable: true,
      createdBy: [selectedUserId],
      enabled: true,
      updatedAtFrom: Moment().subtract(9999, "days").toISOString(),
      updatedAtTo: Moment().toISOString(),
    };
    API.watchList(watchFilter, searchFormRef.current, 0, (data) => {
      data.results = data.results.filter((item) => {
        if (!item.property) {
          console.log(item.watch?.propertyId)
        }
        return item.property != null
      });
      setPage(0);
      setWatchList(data);
      setLoading(false);
    });
  }, [selectedUserId, searchForm]);

  useEffect(() => {
    An.main.watch();
  }, []);

  useEffect(() => {
    loadData();
  }, [selectedUserId]);

  const loadMore = () => {
    const nextPage = page + 1;
    setLoadingMore(true);
    const watchFilter = {
      isToggleable: true,
      createdBy: [selectedUserId],
      enabled: true,
      updatedAtFrom: Moment().subtract(9999, "days").toISOString(),
      updatedAtTo: Moment().toISOString(),
    };
    API.watchList(watchFilter, searchForm, nextPage, (res) => {
      const items = res.results.filter((item) => {
        if (!item.property) {
          console.log(item.watch?.propertyId)
        }
        return item.property != null
      })

      const data = {
        count: watchList.count + items.length,
        results: [...watchList.results, ...items],
        totalCount: watchList.totalCount
      };
      setPage(nextPage)
      setWatchList(data);
      setLoadingMore(false);
    });
  };

  useEffect(() => {
    setUsersLoading(true);
    API.getWatchUsers((data) => {
      const usersData = data
        .map((it) => ({
          title: it.label,
          value: it.value,
        }));

      setUsersLoading(false);
      setUsers(usersData);
    }, () => {
      setUsersLoading(false);
    });
  }, []);

  useEffect(() => {
    const state = {
      selectedUserId,
      useSavedSearch
    };
    if (selectedUserId || useSavedSearch) {
      sessionStorage.setItem(Const.WATCH_LIST_FILTER_STATE, JSON.stringify(state));
    }
  }, [selectedUserId, useSavedSearch])

  const onUserSelect = (selectedUser) => {
    setSelectedUserId(selectedUser.value);
    addValueToForm("propertyWatchFilter", {
      isToggleable: true,
      createdBy: [selectedUser.value],
      enabled: true,
    });
  };

  const onResetSelectedUser = () => {
    setSelectedUserId(user.id);
    addValueToForm("propertyWatchFilter", {
      isToggleable: true,
      createdBy: [user.id],
      enabled: true,
    });
  };

  const renderWatchListSelect = (() => {
    if (selectedUserId !== user?.id) {
      return (
        <Trans i18nKey="watch.list.back.to.mine">
          <a onClick={onResetSelectedUser}></a>
        </Trans>
      );
    }
    return (
      <Trans i18nKey="watch.list.other.watch.lists">
        <CInlineDropdown
          placeholder={t("watch.list.search.placeholder")}
          as="a"
          onSelect={onUserSelect}
          items={users.filter((it) => it.value !== user?.id)}
          loading={usersLoading}
          noResultsText={t("watch.list.no.watch.lists.found")}
        />
      </Trans>
    );
  })();

  const name = (() => {
    if (selectedUserId !== user?.id) {
      const selectedUser = users.find((item) => item.value === selectedUserId);
      if (selectedUser) {
        return Utils.formatBelonging(selectedUser.title, i18n.resolvedLanguage);
      }
    }
    return Utils.formatBelonging(user?.firstName, i18n.resolvedLanguage);
  })();

  const loadDataThrottle = debounce(200, loadData);

  const reloadData = (useSavedSearch) => {
    setUseSavedSearch(useSavedSearch);
    setLoading(true);
    loadDataThrottle();
  };

  const onFilterPopupSearch = (form) => {
    setForm(cleanupFilter(form))
    if (form?.propertyWatchFilter?.value?.enabled && form.propertyWatchFilter.value.createdBy[0] && form.propertyWatchFilter.value.createdBy[0] !== selectedUserId) {
      setSelectedUserId(form.propertyWatchFilter.value.createdBy[0]);
    } else {
      reloadData();
    }
  };

  const showFilterPopup = () => {
    toggleFilterPopup(searchForm, onFilterPopupSearch);
  };

  const clearFilters = () => {
    initSearchForm();
    reloadData();
  };

  const loadFilter = (item) => {
    if (item.value) {
      setForm(cleanupFilter(item.value));
      reloadData(item.value);
    }
  };

  const getSavedFilters = () => {
    const sharedTitle = t(
      "actions.and.notes.filter.search.saved.shared"
    );
    const ownTitle = t(
      "actions.and.notes.filter.search.saved.own"
    );
    const noneTitle = t(
      "actions.and.notes.filter.search.saved.none"
    );

    const sharedList = [
      {
        title:
          sharedTitle +
          (sharedFilters.length === 0 ? " \u2013 " + noneTitle : ""),
        inactive: true,
      },
      ...sharedFilters.map((f) => ({
        title: f.name,
        value: f,
      })),
    ];

    sharedList[sharedList.length - 1].divider = true;

    return [
      ...sharedList,
      {
        title:
          ownTitle + (sharedFilters.length === 0 ? " \u2013 " + noneTitle : ""),
        inactive: true,
      },
      ...savedFilters.map((f) => ({
        title: f.name,
        value: f,
      })),
    ];
  };

  const goToPropertyList = () => {
    setSearchType(Const.SEARCH_TYPE_PROPERTIES)
    addValueToForm("propertyWatchFilter", {
      isToggleable: true,
      createdBy: [selectedUserId],
      enabled: true,
    });

    setStorageKey("LENAV_PREVIOUS_SEARCH_FORM");
    router.push('/results');
  }

  const fields = getSearchForm({
    searchForm: searchForm,
    client: user?.client,
    fullReload: true,
    excludedFields: ["propertyWatchFilter"],
    onSearch: reloadData,
  });

  const showLoadMore = !loadingMore && !loading && watchList.count < watchList.totalCount;

  return (
    <div className="wrapper-content">
      <Header title={route.title} backLabel={route.backLabel} />
      <div className="main">
        <div className="main-content show-main-scroll">
          <div className="container">
            <Menu className="hide-mobile" />
            <div className="dashboard watch-list search-filter">
              <div className="wl-page dofl">
                <div className="d-flex justify-content-between d-block-mobile">
                  <div style={{ display: "flex", width: "100%", flexWrap: "wrap", alignItems: "center" }}>
                    <div className="wl-name">
                      <span className="upper">
                        <CLabel k="watch.list.title" param={{ name }} />
                      </span>
                    </div>
                    <div className="wl-controls">
                      ({renderWatchListSelect})
                    </div>
                  </div> 
                  <div className="wl-filters">
                    {fields.size === 0 && (
                      <a className="mr-4" onClick={showFilterPopup}>
                        <CLabel k="watch.list.filter.search.filters.add" />
                      </a>
                    )}
                    {useSavedSearch && (
                      <a className="mr-4" onClick={showFilterPopup}>
                        <CLabel k="watch.list.filter.search.filters.more" />
                      </a>
                    )}
                    <CInlineDropdown
                      title={t(
                        "actions.and.notes.filter.search.use.saved"
                      )}
                      as="a"
                      onSelect={loadFilter}
                      items={getSavedFilters()}
                    />
                  </div>
                </div>
              </div>
              <form className="form form-small wl-form border-between">
                {fields.size > 0 && !useSavedSearch && (
                  <div className="search-filter row">
                    <CSearchFilter
                      fields={fields}
                      searchType={null}
                      onSearch={reloadData}
                      onShowFilters={showFilterPopup}
                      onClearFilters={clearFilters}
                    />
                  </div>
                )}
                {useSavedSearch && (
                  <div className="dofl search-filter row">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <div>
                        <i>
                          <CLabel k="actions.and.notes.filter.search.saved.apply" />{" "}
                          <span className="bold-shadow">{useSavedSearch.name}</span>
                        </i>
                      </div>
                      <div>
                        <a onClick={clearFilters}>
                          <CLabel k="quick.find.clear" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                <div className="wl-results-count search-filter row">
                  <span className="grey">
                    {watchList && watchList.totalCount !== null && !loading &&
                      t("watch.list.propertyCount", { count: watchList.totalCount })
                    }
                  </span>
                  <a className="grey" onClick={goToPropertyList}>
                    <i className="fa fa-arrow-right" style={{ "marginRight": "3px", "fontSize": "13px", "paddingTop": "1px" }}></i>
                    <CLabel k="watch.list.propertyList" />
                  </a>
                </div>
              </form>
              {loading && (
                <div className="loader">
                  <CLabel k="loading" />
                </div>
              )}
              {!loading && <WatchListItems
                watchList={watchList.results}
                loadData={loadData}
              />}
              {loadingMore && !loading && (
                <div className="wl-period-item-loader">
                  <div className="loader">
                    <CLabel k="loading" />
                  </div>
                </div>
              )}
              {showLoadMore && (
                <VisibilitySensor offset={400} onVisible={loadMore} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connect(WatchList2, {
  dispatch: (dispatch) => {
    return {
      addValueToForm: (name, value) => dispatch({ type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value }),
      toggleFilterPopup: (form, onSearch) => {
        const toggle = () => dispatch({ type: "TOGGLE_POPUP_FILTER" });
        const search = (form) => {
          toggle();
          onSearch(form);
        };
        dispatch({
          type: "TOGGLE_POPUP_FILTER",
          data: {
            originalForm: form,
            onSearch: search,
            onCancel: toggle,
            count: " ",
            previousSearchKey: "LENAV_PREVIOUS_SEARCH_FORM_WL",
            excludeTab: Const.SEARCH_TYPE_COMPANIES,
            defaultValues: {storageKey: "LENAV_PREVIOUS_SEARCH_FORM_WL"}
          },
        });
      },
      initSearchForm: (type) =>
        dispatch({ type: "INIT_SEARCH_FORM", searchType: type, storageKey: "LENAV_PREVIOUS_SEARCH_FORM_WL", version: Math.random() }),
      setForm: (form) => dispatch({ type: "SET_FILTER_FORM", form: form }),
      setStorageKey: (storageKey) => dispatch({ type: 'SET_STORAGE_KEY', storageKey: storageKey }),
      setSearchType: (searchType) => dispatch({ type: 'SET_SEARCH_TYPE', searchType: searchType }),
    };
  },

  state: (state) => {
    return {
      user: state.session && state.session.user,
      translation: state.translation,
      searchForm: state.searchForm.form,
      savedFilters: state.savedFilters.filters,
      sharedFilters: state.savedFilters.shared,
    };
  },
});
