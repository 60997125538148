import { useEffect, useState } from "react";
import { getLanguageOptions } from "../../helpers/LanguageOptions";
import { useTranslation } from "react-i18next";
import Connect from '../../helpers/Connect'
import Utils from "../../helpers/Utils";
import Moment from "moment";
import { getDateLocale } from "../../helpers/LocaleHelpers";
import { API } from "../../redux/Store"

const LanguageSelectorHeader = ({ client, user, setUser }) => {
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();

    const toggleDropdown = (e) => {
        e.preventDefault();
        setOpen(!open);
    }

    const onBodyClick = (e) => {
        if (e.target.closest('.box-dropdown-lang') !== null || !open) return;
        setOpen(false);
    }

    useEffect(() => {
        document.body.addEventListener('click', onBodyClick);
        return () => {
            document.body.removeEventListener('click', onBodyClick);
        }
    }, [onBodyClick])

    const selectLanguage = (lang) => () => {
        i18n.changeLanguage(lang);
        document.title = t(Utils.getEnvStyle().title)
        Moment.locale(getDateLocale(lang))
        setOpen(false);
        API.updateUser({
            ...user,
            language: lang
        }, (res) => {
            setUser(res);
        })
        
    }

    const languages = getLanguageOptions(t, client, true);
    const language = languages.find(lang => lang.value === i18n.resolvedLanguage);

    return <div className="box-right hide-mobile">
        <div className="box-lang-switcher">
            <div className="box-dropdown box-dropdown-lang">
                <a className="btn btn-lang href-dropdown" style={{ whiteSpace: "nowrap" }} onClick={toggleDropdown}>
                    <span className="uppercase">{language.value}</span>
                    <i className="fa fa-angle-down icon-right"></i>
                </a>
                
                {open && <div className="menu-dropdown lang-nav">
                    <ul>
                        {languages.map(lang => <li key={lang.value} className={`${lang.hasAccess ? '' : 'disabled'}`} onClick={selectLanguage(lang.value)}>
                            {lang.label} (<span className="uppercase">{lang.value}</span>)
                        </li>)}
                    </ul>
                </div>}
            </div>
        </div>
    </div>
}



export default Connect(LanguageSelectorHeader, {
    state: (state) => {
        return {
            user: state.session?.user,
            client: state.session && state.session.user && state.session.user.client,
        }
    },
    dispatch: (dispatch) => {
        return {
            setUser: (user) => dispatch({ type: 'SET_USER', user: user })
        }
    }
});