module.exports =
  meetingSpace: (left, right)->
    left = left?.replace(/[, ]+/g, '').toLowerCase()
    right = right?.replace(/[, ]+/g, '').toLowerCase()

    if left?.charAt(0) == '<' or left?.startsWith 'under'
      if right?.charAt(0) == '>' or left?.startsWith 'over'
        return -1
      return -1
    else if right?.charAt(0) == '<' or right?.startsWith 'under'
      if left?.charAt(0) == '>' or left?.startsWith 'over'
        return 1
      return 1
    if left > right then 1
    else if left == right then 0
    else -1
