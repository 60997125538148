import Utils from "../../helpers/Utils"
import { hasAccessToLanguage } from "../../helpers/LanguageOptions"
import { extend } from "../../helpers/UtilsHelpers"
const LENAV_SESSION = "LENAV_SESSION"
import i18next from 'i18next'

const clone = (o) => extend(true, { version: Math.random() }, o)

export default (getStore, getAPI, getHistory) => {
  const loadDataAfterLogin = (currentLocation, token, checkLangAccess, hasLanguageAccess, language) => {
    if (checkLangAccess && !hasLanguageAccess) {
      Utils.fireEvent(() => getStore().dispatch({ type: "TOGGLE_POPUP_LANGUAGE_ACCESS", data: {
        language
      } }))
    }

    getAPI().getRecents((recents) => getStore().dispatch({ type: "SET_RECENT", recents: recents }))

    getAPI().getFilters((filters) => getStore().dispatch({ type: "SET_FILTERS", filters: filters }))

    getAPI().getFiltersShared((filters) => getStore().dispatch({ type: "SET_FILTERS_SHARED", shared: filters }))

    getAPI().settings((settings) => {
      getStore().dispatch({ type: "SET_SETTINGS", payload: settings })
      getStore().dispatch({ type: "CHANGE_LOCATION", location: currentLocation, action: "PUSH" })
    })

    Utils.fireEvent(() => getStore().dispatch({ type: "SUBSCRIBE_USER", token: token }))
  }

  const redirectTo = (to, loadCharts) => {
    setTimeout(() => {
      if (loadCharts) {
        getStore().dispatch({ type: "LOAD_CHART_DIMENSIONS" })
        getStore().dispatch({ type: "LOAD_FIRST_CHART" })
      }
      getHistory().push(to)
    }, 0)
  }

  const logout = (() => {
    getAPI().logout((session) => {
      getStore().dispatch({ type: "CLOSE_ACTIVE_POPUP" })
      getStore().dispatch({ type: "DISCONNECT_WS" })
      if (session?.token) {
        getStore().dispatch({ type: "LOGIN", payload: session })
        location.href = '/'
      }
    })
  })

  return (state = JSON.parse(localStorage.getItem(LENAV_SESSION) || null), action) => {
    if (action.type == "LOGIN") {
      Utils.setBadgeCountOnMobileDevice(0)
      state = action.payload
      localStorage.setItem(LENAV_SESSION, JSON.stringify(state))
      const userLanguage = state.user.language || 'en'
      const hasLanguageAccess = hasAccessToLanguage(i18next.t, userLanguage, state.user.client)
      const nextLanguage = hasLanguageAccess ? userLanguage : 'en';
      i18next.changeLanguage(nextLanguage)
      document.title = i18next.t(Utils.getEnvStyle().title)
      getAPI().updateUser({ ...state.user, language: nextLanguage });
      if (action.payload.token) loadDataAfterLogin(action.currentLocation, action.payload.token, state.checkLangAccess, hasLanguageAccess, state.user.language)
      return clone(state)
    } else if (action.type == "LOGOUT") {
      if (localStorage.getItem(LENAV_SESSION)) {
        localStorage.removeItem("LENAV_SHADOW_SESSION")
        logout()
        if (action.payload?.isAliasEnabled) {
          state.isAliasEnabled = false
          sessionStorage.clear()
          localStorage.removeItem(LENAV_SESSION)
          localStorage.setItem(LENAV_SESSION, JSON.stringify(state))
          return clone(state)
        }

        localStorage.removeItem(LENAV_SESSION)
        Utils.setBadgeCountOnMobileDevice(0)
        state = null
        if (action.terminateSessionByUser) sessionStorage.clear()
        if (!action.keepRoute) redirectTo("/login")
        return null
      }
      return clone(state)
    } else if (action.type == "SET_USER") {
      state.user = action.user
      localStorage.setItem(LENAV_SESSION, JSON.stringify(state))
      return clone(state)
    } else if (action.type == "ACCEPT_LICENCE" && state.user) {
      state.user.licenceAccepted = true
      localStorage.setItem(LENAV_SESSION, JSON.stringify(state))
      return clone(state)
    }
    else if (action.type == "UPDATE_USER_DATA" && state.user) {
      state.user.finishedExports = action.payload?.finishedExports
      localStorage.setItem(LENAV_SESSION, JSON.stringify(state))
      return clone(state)
    } else return state
  }
}
