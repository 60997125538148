Connect = require '../../helpers/Connect'
CFilterElement = require '../filters/CFilterElement'
CMultipleDropdown = require '../filters/CMultipleDropdown'
CProximityFilterElement = require '../filters/CProximityFilterElement'
CHasNotesFilterElement = require '../filters/CHasNotesFilterElement'
CHasWatchListFilterElement = require '../filters/CHasWatchListFilterElement'
{ CLabel } = require '../CLabel'
{ isMobile } = require '../../helpers/Utils'
UserUtils = require '../../helpers/UserUtils'
Comparators = require '../../helpers/Comparators'
{ withTranslation } = require 'react-i18next'
{ translateCategoricalValue } = require '../filters/CTranslateUtils'

module.exports = withTranslation() Connect createReactClass(
  displayName: 'CSearchFormColumnProperty'

  putValue: (name, value)->
    val = if typeof value == 'string' && value.length == 0 then undefined else value
    if name == "proximityFilter" && value?.address?.length == 0 && !value?.radius
      val = undefined
    @props.addValueToSearchForm(name, val)

  putValueFromDropDown: (name, values, options)->
    values = if values.length == 0 then undefined else (values.map (e)=> "'#{e}'").join(";")
    @props.addValueToSearchForm(name, values, options)

  showBlock: (name)->
    @props.toggleBlockOnSearchForm(name)

  render: ->
    customPropertySetLabel = if @props.searchFieldsInfo?.branch?.label then translateCategoricalValue(@props.t, "customPropertySet", @props.searchFieldsInfo.branch.label) else @props.t("search.hotel.information.branch")
    React.createElement("div", {"className": "row-three #{@props.className}"},
      React.createElement("div", {"className": "box-slide-form open"},
        React.createElement("div", {"className": "top-form"},
          (@props.children)
        ),
        React.createElement("div", {"className": "slide-form #{if @props.collapsed then '' else 'open'}"},
          React.createElement("div", {"className": "tile-group"},
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "box-form-group"},
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CFilterElement, {"onChange": (@putValue), "name": "id", "value": (@props.form?.id?.value), "type": "text", "className": "form-control small", "placeholder": (@props.t("search.hotel.information.property.id"))})
                ),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "hotelName", "value": (@props.form?.hotelName?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.hotel.name"))})),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "address", "value": (@props.form?.address?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.street.address"))})),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "city", "value": (@props.form?.city?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.city"))})),
                React.createElement("div", {"className": "box-multi-control"},
                  React.createElement("div", {"className": "box-table"},
                    React.createElement("div", {"className": "box-control row-half item-cell"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "stateProvince", "value": (@props.form?.stateProvince?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.state.province"))})),
                    React.createElement("div", {"className": "box-control row-half item-cell"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "postalCode", "value": (@props.form?.postalCode?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.post.code"))}))
                  )
                ),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "country", "value": (@props.form?.country?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.country"))})),
                React.createElement("div", {"className": "box-control hide-mobile"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "rooms", "value": (@props.form?.rooms?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.rooms"))}))
              )
            )
          ),
          React.createElement("div", {"className": "tile-group"},
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "box-form-group"},
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CFilterElement, {"onChange": (@putValue), "name": "market", "value": (@props.form?.market?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.market"))})
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CFilterElement, {"onChange": (@putValue), "name": "tract", "value": (@props.form?.tract?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.tract"))})
                ),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "brand", "value": (@props.form?.brand?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.brand.label"))})),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "franchiseCompany", "value": (@props.form?.franchiseCompany?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.brand.company"))})),
                (if UserUtils.hasFeature(@props.client, "FKey")
                  React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "fkey", "value": (@props.form?.fkey?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.fkey", { lng: 'en' }))}))
                ),
                React.createElement("div", {"className": "box-multi-control"},
                  React.createElement("div", {"className": "box-table"},
                    React.createElement("div", {"className": "box-control row-double-three"},
                      React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "chainScale", "values": (@props.form?.chainScale?.value), "options": (@props.form?.chainScale?.options), "placeholder": (@props.t("search.hotel.information.chain.scale"))})
                    ),
                    React.createElement("div", {"className": "box-control row-three show-mobile"},
                      React.createElement(CFilterElement, {"onChange": (@putValue), "name": "rooms", "value": (@props.form?.rooms?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.rooms"))})
                    )
                  )
                )
              ),
              React.createElement("div", {"className": "box-form-group"},
                React.createElement("div", {"className": "box-control text-right"},
                  React.createElement("div", {"className": "box-checkbox middle aligned #{if isMobile() then 'text-left' else ''}"},
                    React.createElement("input", {"type": "checkbox", "id": "like", "checked": (@props.form.includeUnbranded || false), "onChange": (@showBlock.bind @, "includeUnbranded")}), React.createElement("label", {"htmlFor": "like"}, React.createElement(CLabel, {"k": "search.hotel.information.include.like.kind.unbranded"}))
                  )
                )
              ),
              React.createElement("div", {"className": "box-form-group"},
                (if !(@props.filtersToHide && 'territory' in @props.filtersToHide)
                  React.createElement("div", {"className": "box-control"},
                    React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "territory", "values": (@props.form?.territory?.value), "options": (@props.form?.territory?.options), "placeholder": (@props.t("search.hotel.information.territory"))})
                  )
                ),
                (if !(@props.filtersToHide && 'branch' in @props.filtersToHide)
                  React.createElement("div", {"className": "box-control"},
                    React.createElement(CMultipleDropdown, { \
                      "onChange": (@putValueFromDropDown),  \
                      "name": "branch",  \
                      "values": (@props.form?.branch?.value),  \
                      "options": (@props.form?.branch?.options),  \
                      "placeholder": (@props.form?.propertySetLabel || customPropertySetLabel)
                    })
                  )
                )
              )
            )
          ),
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "tile-title"},
              React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "search.location"})))
            ),
            React.createElement("form", {"className": "form form-small"},
              React.createElement(CProximityFilterElement, {"description": "search.additional.property.details.proximity.header", "onBlur": (@putValue), "onChange": (@putValue), "name": "proximityFilter", "value": (@props.form?.proximityFilter?.value)})
            )
          ),
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "tile-title"},
              React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "search.additional.property.details.title"})))
            ),
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "box-form-group"},
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CFilterElement, {"onChange": (@putValue), "name": "county", "value": (@props.form?.county?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.additional.property.details.county"))})
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "worldRegion1", "values": (@props.form?.worldRegion1?.value), "options": (@props.form?.worldRegion1?.options), "placeholder": (@props.t("search.additional.property.details.world.region1"))})
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "worldRegion2", "values": (@props.form?.worldRegion2?.value), "options": (@props.form?.worldRegion2?.options), "placeholder": (@props.t("search.additional.property.details.world.region2"))})
                ),
                React.createElement("div", {"className": "box-multi-control"},
                  React.createElement("div", {"className": "box-table"},
                    React.createElement("div", {"className": "box-control row-half item-cell"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "yearBuilt", "value": (@props.form?.yearBuilt?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.additional.property.details.year.built"))})),
                    React.createElement("div", {"className": "box-control row-half item-cell"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "brandYear", "value": (@props.form?.brandYear?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.additional.property.details.brand.year"))}))
                  )
                ),
                React.createElement("div", {"className": "box-multi-control"},
                  React.createElement("div", {"className": "box-table"},
                    React.createElement("div", {"className": "box-control row-half item-cell"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "lastRenovationYear", "value": (@props.form?.lastRenovationYear?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.additional.property.details.last.reno"))})),
                    React.createElement("div", {"className": "box-control row-half item-cell"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "lastSold", "value": (@props.form?.lastSold?.value), "type": "date", "className": "form-control", "placeholder": (@props.t("search.additional.property.details.last.sold"))}))
                  )
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "locationType", "values": (@props.form?.locationType?.value), "options": (@props.form?.locationType?.options), "placeholder": (@props.t("search.additional.property.details.locationType"))})
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "comparator": (Comparators["meetingSpace"]), "name": "meetingSpace", "values": (@props.form?.meetingSpace?.value), "options": (@props.form?.meetingSpace?.options), "placeholder": (@props.t("search.additional.property.details.meeting.space"))})
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "ownerRole", "values": (@props.form?.ownerRole?.value), "options": (@props.form?.ownerRole?.options), "placeholder": (@props.t("search.additional.property.details.owner.role"))})
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "brandRole", "values": (@props.form?.brandRole?.value), "options": (@props.form?.brandRole?.options), "placeholder": (@props.t("search.additional.property.details.brand.role"))})
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CMultipleDropdown, {"onChange": (@putValueFromDropDown), "name": "keywords", "values": (@props.form?.keywords?.value), "options": (@props.form?.keywords?.options), "placeholder": (@props.t("search.additional.property.details.tags"))})
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement(CFilterElement, {"onChange": (@putValue), "name": "commentary", "value": (@props.form?.commentary?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.hotel.information.commentary"))})
                )
              )
            )
          ),
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "tile-title", "style": ({ paddingBottom: 0 })},
              React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "search.additional.data", "param": ({ shortName: @props.client?.shortName || "" })})))
            ),
            React.createElement("form", {"className": "form form-small"},
              (if @props.form?.storageKey != "LENAV_PREVIOUS_SEARCH_FORM_AN" 
                React.createElement("div", {"className": "box-form-group"},
                  React.createElement("div", {"className": "box-control"},
                    React.createElement(CHasNotesFilterElement, {"notesSharingAllowed": (@props.client?.noteSharing), "showCheckbox": (true), "onChange": (@putValue), "name": "propertyNotesFilter", "value": (@props.form?.propertyNotesFilter?.value)})
                  )
                )
              ),
              (if @props.form?.storageKey != "LENAV_PREVIOUS_SEARCH_FORM_WL" 
                React.createElement("div", {"className": "box-form-group"},
                  React.createElement("div", {"className": "box-control"},
                    React.createElement(CHasWatchListFilterElement, {"showCheckbox": (true), "onChange": (@putValue), "name": "propertyWatchFilter", "value": (@props.form?.propertyWatchFilter?.value)})
                  )
                )
              )
            )
          )
        )
      )
    )

), {

  state: (state) ->
    translation: state.translation
    form: state.searchForm.form
    client: state.session?.user?.client
    filtersToHide: state.app.filtersToHide
    searchFieldsInfo: state.app.searchFieldsInfo
  dispatch: (dispatch) ->
    addValueToSearchForm: (name, value, options) -> dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value, options: options)
    toggleBlockOnSearchForm: (name) -> dispatch(type: 'TOGGLE_BLOCK_ON_SEARCH_FORM', name: name)
}
