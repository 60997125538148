{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
CSortPopup = require('../../cmp/popups/CSortPopup').default
CEditNotePopup = (require '../../cmp/popups/CEditNotePopup').default
CFilterPopup = require '../../cmp/popups/CFilterPopup'
CPasswordRecoveryPopup = require '../../cmp/popups/CPasswordRecoveryPopup'
CCustomPopup = require '../../cmp/popups/CCustomPopup'
CResultsExportPopup = require '../../cmp/popups/CResultsExportPopup'
CTargetAccountsPopup = require '../../cmp/popups/CTargetAccountsPopup'
CMapCompaniesPopup = require '../../cmp/popups/CMapCompaniesPopup'
CCompanyCardPopup = require '../../cmp/popups/CCompanyCardPopup'
CExportFileRenamePopup = require '../../cmp/popups/CExportFileRenamePopup'
CPropertyExport = require '../../cmp/popups/CPropertyExportPopup'
CLanguageAccessPopup = (require '../../cmp/popups/CLanguageAccessPopup').default
CNoteHistoryPopup = (require '../../cmp/popups/CNoteHistoryPopup').default
CMobileFeaturePopup = (require '../../cmp/popups/CMobileFeaturePopup').default
CConfirmActionModal = (require '../../cmp/popups/CConfirmActionModal').default
CWpSso = require '../../cmp/CWpSso'
CDigestView = require('../../cmp/CDigestView').default
{ CLabel } = require '../../cmp/CLabel'


Utils = require '../../helpers/Utils'

module.exports = Connect createReactClass(
  displayName: "CPopups"

  componentDidMount: ->
    document.body.addEventListener 'keyup', @onKeyUp, false

  onKeyUp: (e) ->
    if e.which == 27 && e.ctrlKey
      Object.keys(@props.popup).forEach (key) =>
        @props.togglePopup(key)

  componentWillUnmount: ->
    document.body.removeEventListener 'keyup', @onKeyUp, false

  render: () ->
    React.createElement("div", null,
      (if @props.sortPopup
        React.createElement(CSortPopup, {"data": (@props.sortPopup.data)})
      ),
      (if @props.filterPopup
        React.createElement(CFilterPopup, {"data": (@props.filterPopup.data)})
      ),
      (if @props.passwordRecoveryPopup
        React.createElement(CPasswordRecoveryPopup, null)
      ),
      (if @props.languageAccessPopup
        React.createElement(CLanguageAccessPopup, null)
      ),
      (if @props.mobileFeaturePopup
        React.createElement(CMobileFeaturePopup, { \
          "onClose": (() =>
            @props.togglePopup('TOGGLE_POPUP_MOBILE_FEATURE')
          ),  \
          "featureName": (@props.mobileFeaturePopup?.data?.featureName)
        })
      ),
      (if @props.editNotePopup
        React.createElement(CEditNotePopup, {"data": (@props.editNotePopup.data), "onChanged": (@props.editNotePopup.data.onChanged), "loadNotes": (@props.editNotePopup.data.loadNotes), "onDeleteNote": (@props.editNotePopup.data.onDeleteNote)})
      ),
      (if @props.customPopup
        React.createElement(CCustomPopup, {"data": (@props.customPopup.data)})
      ),
      (if @props.propertyExportPopup
        React.createElement(CPropertyExport, {"data": (@props.propertyExportPopup.data)})
      ),
      (if @props.resultsExportPopup
        React.createElement(CResultsExportPopup, {"data": (@props.resultsExportPopup.data)})
      ),
      (if @props.targetAccountsPopup
        React.createElement(CTargetAccountsPopup, null)
      ),
      (if @props.mapCompaniesPopup
        React.createElement(CMapCompaniesPopup, null)
      ),
      (if @props.companyCardPopup
        React.createElement(CCompanyCardPopup, {"companyId": (@props.companyCardPopup.data)})
      ),
      (if @props.exportFileRenamePopup
        React.createElement(CExportFileRenamePopup, {"data": (@props.exportFileRenamePopup.data)})
      ),
      (if @props.digestViewPopup
        React.createElement(CDigestView, {"data": (@props.digestViewPopup.data)})
      ),
      (if @props.confirmActionPopup
        React.createElement(CConfirmActionModal, {"data": (@props.confirmActionPopup.data)})
      ),
      (if @props.noteHistoryPopup
        React.createElement(CNoteHistoryPopup, {"data": (@props.noteHistoryPopup.data)})
      ),
      (if @props.wpSsoPopup
        React.createElement("div", {"className": "modal unselectable open"},
          React.createElement(CWpSso, {"url": (@props.wpSsoPopup.data), "isPopup": (true)}),
          React.createElement("div", {"className": "modal-footer-mobile show-mobile"},
            React.createElement("a", {"className": "btn btn-red btn-normal close-modal", "onClick": (()=> @props.togglePopup('TOGGLE_POPUP_WP_SSO'))}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "settings.custom.popup.close"})))
          )
        )
      )
    )
), {
  state: (state) ->
    body = document.body
    if !body.classList.contains("modal-open") && Utils.keyLength(state.popup) > 0
      body.classList.add("modal-open")
    else if body.classList.contains("modal-open") && Utils.keyLength(state.popup) == 0
      body.classList.remove("modal-open")

    popup: state.popup
    editNotePopup: state.popup?.TOGGLE_POPUP_EDIT_NOTE
    filterPopup: state.popup?.TOGGLE_POPUP_FILTER
    sortPopup: state.popup?.TOGGLE_POPUP_SORT
    passwordRecoveryPopup: state.popup?.TOGGLE_POPUP_PASSWORD_RECOVERY
    watchPopup: state.popup?.TOGGLE_POPUP_WATCH
    customPopup: state.popup?.TOGGLE_POPUP_CUSTOM
    resultsExportPopup: state.popup?.TOGGLE_POPUP_RESULTS_EXPORT
    targetAccountsPopup: state.popup?.TOGGLE_POPUP_TARGET_ACCOUNTS
    mapCompaniesPopup: state.popup?.TOGGLE_POPUP_MAP_COMPANIES
    companyCardPopup: state.popup?.TOGGLE_POPUP_COMPANY_CARD
    exportFileRenamePopup: state.popup?.TOGGLE_POPUP_FILE_RENAME
    propertyExportPopup: state.popup?.TOGGLE_POPUP_PROPERTY_EXPORT
    wpSsoPopup: state.popup?.TOGGLE_POPUP_WP_SSO
    digestViewPopup: state.popup?.TOGGLE_POPUP_DIGEST_VIEW
    languageAccessPopup: state.popup?.TOGGLE_POPUP_LANGUAGE_ACCESS
    mobileFeaturePopup: state.popup?.TOGGLE_POPUP_MOBILE_FEATURE
    confirmActionPopup: state.popup?.TOGGLE_POPUP_CONFIRM_ACTION
    noteHistoryPopup: state.popup?.TOGGLE_POPUP_NOTE_HISTORY
  dispatch: (dispatch) ->
    togglePopup: (popup) -> dispatch(type: popup)
}
