{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
CFilterPopup = require '../../cmp/popups/CFilterPopup'
CInput = require '../../cmp/CInput'
{ CLabel } = require '../../cmp/CLabel'
Utils = require '../../helpers/Utils'
{API} = require '../../redux/Store'

{ withTranslation } = require 'react-i18next' 

module.exports = withTranslation() Connect createReactClass(
  displayName: "CPasswordRecoveryPopup"

  getInitialState: ->
    email: ''

  handleChange: (e)->
    @setState email: e.target.value, emailError: null

  recoverPassword: ->
    if !@state.email
      @setState emailError: React.createElement(CLabel, {"k": "login.forgot.password.email.cannot.be.empty"})
    else if !/\S+@\S+\.\S+/.test @state.email
      @setState emailError: React.createElement(CLabel, {"k": "login.forgot.password.invalid.email"})
    else
      API.recoverPassword @state.email, () =>
        @setState emailError: null, emailReset: true
      , (data)=>
        if data?.error
          @setState emailError: data.error
        else
          @setState emailError: null, emailReset: true

  
  onBodyClick: (e) ->
    if e.target.closest('.password-recovery-popup') == null
      @props.togglePopupPasswordRecovery()

  componentDidMount: () ->
    document.querySelector('body').addEventListener('click', @onBodyClick)

  componentWillUnmount: () ->
    document.querySelector('body').removeEventListener('click', @onBodyClick)

  render: ()->
    React.createElement("div", {"className": "modal-form password-recovery-popup", "style": (display: "block")},
      React.createElement("div", {"className": "modal-header-mobile show-mobile", "style": (position: "initial")},
        React.createElement("a", {"className": "btn btn-back close-modal", "onClick": (@props.togglePopupPasswordRecovery)}, React.createElement("i", {"className": "fa fa-close"})),
        React.createElement("div", {"className": "title-page"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "login.forgot.password.reset.password"})))
      ),
      React.createElement("div", {"className": "modal-form-header hide-mobile"},
        React.createElement("a", {"className": "btn btn-close-modal-form btn-close", "onClick": (@props.togglePopupPasswordRecovery)}, React.createElement("i", {"className": "fa fa-close"})),
        React.createElement("div", {"className": "name-block middle text-center"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "login.forgot.password.reset.password"})))
      ),
      (if !@state.emailReset
        React.createElement("div", {"className": "modal-form-content"},
          React.createElement("div", {"className": "box-text"},
            React.createElement(CLabel, {"k": "login.forgot.password.text"})
          ),
          React.createElement("div", {"className": "form-small"},
            React.createElement("div", {"className": "box-control"},
              React.createElement("input", {"ref": "email", "type": "text", "value": (@state.email), "onChange": (@handleChange), "className": "form-control", "placeholder": (@props.t("login.forgot.password.email.address"))}),
              (if @state.emailError
                React.createElement("div", {"className": "message-error"}, (@state.emailError))
              )
            ),
            React.createElement("div", {"className": "box-btn"},
              React.createElement("a", {"onClick": (@recoverPassword), "className": "btn btn-red btn-normal btn-full"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "login.forgot.password.send.reset.link"})))
            )
          )
        )
      ),
      (if @state.emailReset
        React.createElement("div", {"className": "modal-form-message"},
          React.createElement("div", {"className": "box-icon"}, React.createElement("i", {"className": "fa fa-check-circle"})),
          React.createElement("div", {"className": "title"}, React.createElement(CLabel, {"k": "login.forgot.password.email.sent", "param": ({ email: @state.email })})),
          React.createElement("div", {"className": "text"},
            React.createElement(CLabel, {"k": "login.forgot.password.follow.directions"})
          )
        )
      )
    )
),
dispatch: (dispatch)->
  togglePopupPasswordRecovery: ()-> dispatch(type: 'TOGGLE_POPUP_PASSWORD_RECOVERY')
state: (state)->
  translation: state.translation
