{ AccessRestriction } = require "./AccessRestriction"

module.exports = createReactClass
  displayName: "CMobileDropdownMenu"

  getInitialState: ()->
    dropDownDisplay: "none"

  handleKeyUp: (e)->
    e.preventDefault()
    e.stopPropagation()
    if e.which == 27
      @close()

  componentDidMount: ->
    document.querySelector('body').addEventListener('keyup', @handleKeyUp)

  componentWillUnmount: ->
    document.querySelector('body').removeEventListener('keyup', @handleKeyUp)

  toggleDropdown: (e)->
    e.preventDefault()
    e.stopPropagation()
    if @state.dropDownDisplay == "none"
      document.querySelector('body').classList.add('menu-dropdown-open')
      @setState dropDownDisplay: "block"
    else
      @close()

  close: ()->
    document.querySelector('body').classList.remove('menu-dropdown-open')
    @setState dropDownDisplay: "none"

  onClick: (o) -> () =>
    if o.onClick
      o.onClick?(@props.onClose)
    @close()


  render: ()->
    React.createElement("div", {"className": "box-sub-nav show-mobile"},
      React.createElement("div", {"className": "bg-dropdown", "onClick": (@close)}),
      React.createElement("div", {"className": ("box-dropdown #{if @state.dropDownDisplay then "open" else ""}")},
        React.createElement("a", {"className": "btn btn-dots href-dropdown", "onClick": (@toggleDropdown)},
          React.createElement("i", {"className": "fa fa-circle"}), React.createElement("i", {"className": "fa fa-circle"}), React.createElement("i", {"className": "fa fa-circle"})
        ),
        React.createElement("div", {"className": "menu-dropdown", "style": (display: @state.dropDownDisplay)},
          (@props.options.map((o, i)=>
            if o.checkAccess
              return React.createElement(AccessRestriction, {"key": (i)}, React.createElement("a", {"className": "btn", "onClick": (@onClick(o))}, React.createElement("i", {"className": "fa #{o.icon} icon-left"}), (o.label)))
            if !o.test || o.test()
              return React.createElement("a", {"key": (i), "className": "btn", "onClick": (@onClick(o))}, React.createElement("i", {"className": "fa #{o.icon} icon-left"}), (o.label))
          )),
          (if @props.input
            @props.input
          )
        )
      )
    )
