module.exports = (API) ->
    login:
      login: () ->
        API.trackAction('LoginHDN')
      sfcOnly: () ->
        API.trackAction('LoginSFC')
    main:
      home: () ->
        API.trackAction('Home')
      search: () ->
        API.trackAction('Search')
      actions: () ->
        API.trackAction('Actions')
      watch: () ->
        API.trackAction('WatchList')
      charts: () ->
        API.trackAction('ChartGallery')
      concierge: () ->
        API.trackAction('Concierge')
      library: () ->
        API.trackAction('Library')
    other:
      quickFind: () ->
        API.trackAction('QuickFind')
      # quickFindResults: () ->
      #   API.trackAction('QuickFindResults')
      profile: () ->
        API.trackAction('Profile')
      # other: () ->
      #   API.trackAction('Unspecified') #Unspecified action	Unspecified

    reports:
      opportunities: () -> 
        API.trackAction('RMgtOpps')
      landsites: () ->
        API.trackAction('RLandsites')
      distressed: () ->
        API.trackAction('RDistressed')
      sales: () ->
        API.trackAction('RSales')
    charts:
      chart: () ->
        API.trackAction('ChartTab') #Click any charts tab on home page

    search:
      savedSearch: () ->
        API.trackAction('SSearch') #Perform a Saved Search
      sharedSearch: () ->
        API.trackAction('ShSearch') #Perform a Shared Saved Search
      saveNewSearch: () ->
        API.trackAction('SSNew') #Save a new saved Search
      updateSearch: () ->
        API.trackAction('SSUpdate') #Update a saved search
      deleteSearch: () ->
        API.trackAction('SSDelete') #Delete Saved Search
      companySearch: () ->
        API.trackAction('SrchCompany') #Perform a company search (saved or new)
      propertySearch: () ->
        API.trackAction('SrchProperty') #Perform a property search (saved or new)
    navigate:
      relatedCompanies: () ->
        API.trackAction('SrchRelC') #Show Related Companies	SrchRelC
      relatedProperties: () ->
        API.trackAction('SrchRelP') #Show Related Properties	SrchRelP
      sortResults: () ->
        API.trackAction('SrchSort') #Sort Results	SrchSort
      propertyCard: () ->
        API.trackAction('PCard') #Open Property Card	PCard
      companyCard: () ->
        API.trackAction('CCard') #Open Company Card	CCard
      map: () ->
        API.trackAction('Map') #Go to Map View	Map
      watchAdd: () ->
        API.trackAction('WatchAdd') #Add To WatchList	WatchAdd
      watchRemove: () ->
        API.trackAction('WatchRemove') #Remove from Watch List	WatchRemove