{ Link } = require 'react-router'
Connect = require '../helpers/Connect'
Header = require '../pages/parts/Header'
Menu = require('../pages/parts/Menu').default
{ CLabel } = require '../cmp/CLabel'
{ API } = require '../redux/Store'
Utils = require '../helpers/Utils'
{ withTranslation } = require 'react-i18next' 
{ getMobileAppSettings } = require '../../assets/mobileAppSettings'
CFilterDropdown = require '../cmp/filters/CFilterDropdown'
MobileAppRedirectModal = require('../cmp/twiddler/MobileAppRedirectModal').default
{ extend } = require '../helpers/UtilsHelpers'
{ translateANPCCounter } = require '../cmp/filters/CTranslateUtils'
CMobileFeaturePopup = (require '../cmp/popups/CMobileFeaturePopup').default
{ FEATURE_ENUM, FEATURE_TABLE } = require('../helpers/MobileFeature')

module.exports = withTranslation() Connect createReactClass(
  displayName: "Twiddler"
  getInitialState: ()->
    twiddler:{}
    keywordsDefault: []
    keywords: ""
    notesCount: 1
    actionsCount: 1
    propertiesCount: 1
    companiesCount: 1
    openModal: false
    openMobileFeaturePopup: false

  reset: ()->
    @setState {
      twiddler:
        showActivityContacts: @props.user?.client?.showActivityContacts
        clientType: @props.user?.client?.clientType
        maps: @props.user?.client?.maps
        chartsSpec: @props.user?.client?.chartsSpec
        reportWizardsSpec: @props.user?.client?.reportWizardsSpec
        noteSharing: @props.user?.client?.noteSharing
        showConversions: @props.user?.client?.showConversions
        showDistressedAssets: @props.user?.client?.showDistressedAssets
        showNewConstruction: @props.user?.client?.showNewConstruction
        showRenovations: @props.user?.client?.showRenovations
        showSales: @props.user?.client?.showSales
        exportLimit: @props.user?.exportLimit
        userTags: @props.user?.userTags
        customPropertySetLabel: @props.user?.client?.customPropertySetLabel
        hasLibrarySite: @props.user?.client?.hasLibrarySite
        salesEmail: @props.user?.client?.salesEmail
        salesFirstName: @props.user?.client?.salesFirstName
        conciergeEmail: @props.user?.client?.conciergeEmail
        keywordsAdded: ""
        keywordsRemoved: ""
      userTags: ""
      openModal: false
      mobileAppUrl: ""
      notesCount: 1
      actionsCount: 1
      propertiesCount: 1
      companiesCount: 1
      openModal: false
      openMobileFeaturePopup: false
    }

  componentWillMount: ()->
    if 0 && !@props.user?.isTester
      @props.router.push('/home')
      return

    API.getDefaultKeywords (result)=>
        @setState keywordsDefault: result[0], keywords: result[0]
      API.getTwiddler (twiddler)=>
        keywords = Utils.getCurrentKeywords(twiddler?.keywordsAdded, twiddler?.keywordsRemoved, result[0])
        userTags = Utils.getCurrentKeywords(twiddler?.userTagsAdded, twiddler?.userTagsRemoved, twiddler?.user?.userTags)
        userKeywords = Utils.getCurrentKeywords(twiddler?.userKeywordsAdded, twiddler?.userKeywordsRemoved, twiddler?.user?.keywords)
        @setState twiddler:twiddler, keywordsDefault: result[0], keywords: keywords, userTags: userTags, userKeywords: userKeywords
      , ()=>
        @reset()


    @props.loadChartDimensions()
    API.getTwiddler (twiddler)=>
      API.getDefaultKeywords (result)=>
        keywords = Utils.getCurrentKeywords(twiddler?.keywordsAdded, twiddler?.keywordsRemoved, result[0])
        userTags = Utils.getCurrentKeywords(twiddler?.userTagsAdded, twiddler?.userTagsRemoved, twiddler?.user?.userTags)
        userKeywords = Utils.getCurrentKeywords(twiddler?.userKeywordsAdded, twiddler?.userKeywordsRemoved, twiddler?.user?.keywords)
        @setState twiddler:twiddler, keywordsDefault: result[0], keywords: keywords, userTags: userTags, userKeywords: userKeywords

    , ()=>
      @reset()

  toggleFlag: (field)->
    twiddler = @state.twiddler
    twiddler[field] = !twiddler[field]
    keywords = Utils.getCurrentKeywords(twiddler?.keywordsAdded, twiddler?.keywordsRemoved, @state.keywordsDefault)
    @setState twiddler:twiddler, keywords: keywords

  setValue: (field, e)->
    twiddler = @state.twiddler
    twiddler[field] = e.target.value
    @setState twiddler:twiddler

  setKeywords: (value)->
    @setState keywords: value

  setAddedKeywords: (e)->
    twiddler = @state.twiddler
    twiddler?.keywordsAdded = e.target.value
    currentKeywords = Utils.getCurrentKeywords(e.target.value, twiddler?.keywordsRemoved, @state.keywordsDefault)
    @setKeywords currentKeywords

  setAddedUserTags: (e)->
    twiddler = @state.twiddler
    twiddler?.userTagsAdded = e.target.value
    userTags = Utils.getCurrentKeywords(e.target.value, twiddler?.userTagsRemoved, @state.twiddler?.user?.userTags)
    @setState twiddler:twiddler, userTags: userTags

  setRemovedUserTags: (e)->
    twiddler = @state.twiddler
    twiddler?.userTagsRemoved = e.target.value
    userTags = Utils.getCurrentKeywords(twiddler?.userTagsAdded, e.target.value, @state.twiddler?.user?.userTags)
    @setState twiddler:twiddler, userTags: userTags

  setRemovedKeywords: (e)->
    twiddler = @state.twiddler
    twiddler?.keywordsRemoved = e.target.value
    currentKeywords = Utils.getCurrentKeywords(twiddler?.keywordsAdded, e.target.value, @state.keywordsDefault)
    @setKeywords currentKeywords

  setRemovedUserKeywords: (e)->
    twiddler = @state.twiddler
    twiddler?.userKeywordsRemoved = e.target.value
    userKeywords = Utils.getCurrentKeywords(twiddler?.userKeywordsAdded, twiddler?.userKeywordsRemoved, @state.twiddler?.user?.keywords)
    @setState twiddler:twiddler, userKeywords: userKeywords

  setAddedUserKeywords: (e)->
    twiddler = @state.twiddler
    twiddler?.userKeywordsAdded = e.target.value
    userKeywords = Utils.getCurrentKeywords(twiddler?.userKeywordsAdded, twiddler?.userKeywordsRemoved, @state.twiddler?.user?.keywords)
    @setState twiddler:twiddler, userKeywords: userKeywords

  start: ()->
    twiddler = extend {}
      , @state.twiddler
      , chartsSpec: @state.twiddler?.chartsSpec?.split(/\r?\n/).map((i)-> i.replace(/\s{2,}/, ' ')).filter((i)-> i).join('\n')
    API.startTwiddler twiddler, (twiddler)=>
      @setState twiddler:twiddler
      API.currentSession (session)=>
        @props.login(session)

  stop: ()->
    API.stopTwiddler ()=>
      @setState twiddler: {}
      API.currentSession (session)=>
        @props.login(session)
        @reset()

  selectEnvironment: (_, value)->
    @setState({ mobileAppUrl: value })

  getEnvironmentOptions: ()->
    return getMobileAppSettings().map((env) => ({ value: env.url, label: "#{env.name} (#{env.url})" }));

  closeModal: ()->
    @setState({ 
      openModal: false
    })

  openModal: ()->
    @setState({ 
      openModal: true
    })

  redirectToEnvironment: ()->
    if window.deviceName in ["IOS", "ANDROID"]
      location.href = "lenavigator://updateApplicationUrl?value=#{@state.mobileAppUrl}"
    @closeModal()
      
  showMobileAccessPointSection: ()->
    return window.deviceName in ["IOS", "ANDROID"]

  renderANPCCounter: ()->
    renderTypes = ["ANPC", "ANP", "ANC", "APC", "NPC", "AP", "AC", "NC", "NP"]
    return renderTypes.map((type) =>
        keyword = "actions.and.notes.export.popup.records#{type}"
        return React.createElement("div", {"key": (type)},
            (translateANPCCounter(@props.t, {
              properties: if type.includes("P") then 3 + 1 else 0,
              companies: if type.includes("C") then 1 else 0,
              notes: if type.includes("N") then 1 else 0,
              actions: if type.includes("A") then 8 else 0,
            }))
          )
      )

  setNotesCount: (e)->
    minimum = if @state.actionsCount == 0 then 1 else 0
    value = Math.max(Number(e.target.value), minimum)
    @setState notesCount: value
  
  setActionsCount: (e)->
    minimum = if @state.notesCount == 0 then 1 else 0
    value = Math.max(Number(e.target.value), minimum)
    @setState actionsCount: value

  setPropertiesCount: (e)->
    minimum = if @state.companiesCount == 0 then 1 else 0
    value = Math.max(Number(e.target.value), minimum)
    @setState propertiesCount: value

  setCompaniesCount: (e)->
    minimum = if @state.propertiesCount == 0 then 1 else 0
    value = Math.max(Number(e.target.value), minimum)
    @setState companiesCount: value

  openMobileFeaturePopup: (e)->
    e.preventDefault()
    @setState({ openMobileFeaturePopup: true })

  render: ()->
    chartDimensions = React.createElement("div", {"className": "flex-row"},
      React.createElement("div", {"className": "row-half"},
        React.createElement("h3", null, React.createElement(CLabel, {"k": "twiddler.chart.dimensions.label"})),
        React.createElement("ul", {"className": "chart-options-info"},
          (@props.chartsDimensions?.dimensions.map (dimension, i)->
            React.createElement("li", {"key": (i)}, React.createElement("span", null, (dimension.name)), " - ", React.createElement("span", null, (dimension.label)))
          )
        )
      ),
      React.createElement("div", {"className": "row-half"},
        React.createElement("h3", null, React.createElement(CLabel, {"k": "twiddler.chart.options"})),
        React.createElement("ul", {"className": "chart-options-info"},
          (@props.chartsDimensions?.options.map (option, i)->
            React.createElement("li", {"key": (i)}, (option.name))
          )
        )
      )
    )

    React.createElement("div", {"className": "wrapper-content"},
      React.createElement(Header, {"title": (@props.route.title), "backLabel": (@props.route.backLabel)}),
      React.createElement("div", {"className": "main"},
        (if @state.openModal && @showMobileAccessPointSection() then React.createElement(MobileAppRedirectModal, {"closeModal": (@closeModal), "onConfirm": (@redirectToEnvironment), "url": (@state.mobileAppUrl)})),
        React.createElement("div", {"className": "main-content"},
          React.createElement("div", {"className": "container"},
            React.createElement(Menu, {"className": "hide-mobile"}),
            React.createElement("div", {"className": "search-line-results"},
              React.createElement("div", {"className": "container"},
                React.createElement("div", {"className": "container-center"},
                  React.createElement("div", {"className": "clearfix"},
                    React.createElement("div", {"className": "box-left row-half"},
                      React.createElement("a", {"className": "btn btn-grey btn-normal", "onClick": (@stop)},
                        React.createElement("span", {"className": "upper hide-mobile"}, React.createElement(CLabel, {"k": "twiddler.restore.defaults"})),
                        React.createElement("span", {"className": "upper show-mobile"}, React.createElement(CLabel, {"k": "twiddler.reset"}))
                      )
                    ),
                    React.createElement("div", {"className": "box-left row-half text-right"},
                      React.createElement("a", {"id": "twiddler-apply", "className": "btn btn-red btn-normal", "onClick": (@start)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "twiddler.apply"})))
                    )
                  )
                )
              )
            ),
            React.createElement("div", {"className": "dashboard"},
              React.createElement("div", {"className": "nav-page hide-mobile"},
                React.createElement("div", {"className": "name"}, React.createElement(CLabel, {"k": "twiddler.title"}))
              ),
              (if @showMobileAccessPointSection() then React.createElement("div", {"className": "block-tile"},
                React.createElement("div", {"className": "box-tile"},
                  React.createElement("form", {"className": "form form-small"},
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("div", {"className": "clearfix"},
                        React.createElement("div", {"className": "item-group"},
                          React.createElement("div", {"className": "name-block small"},
                            React.createElement("span", {"className": "bold"},
                            React.createElement(CLabel, {"k": "twiddler.mobile.app.access"}))
                          ),
                          React.createElement("div", {"className": "mobile-app-access"},
                            React.createElement("div", {"className": "box-control"},
                              React.createElement(CFilterDropdown, { \
                                "name": 'mobileEnvironment',  \
                                "id": "mobileEnvironment",  \
                                "className": "text-left auto-export-dropdown",  \
                                "onChange": (@selectEnvironment),  \
                                "value": (@state.mobileAppUrl),  \
                                "placeholder": "Server url",  \
                                "options": (@getEnvironmentOptions())
                              })
                            ),
                            React.createElement("a", { \
                              "className": "btn btn-red btn-normal box-right #{if !@state.mobileAppUrl then 'disabled' }",  \
                              "onClick": (@openModal)
                              },
                              React.createElement("span", {"className": "upper"},
                                React.createElement(CLabel, {"k": "twiddler.redirect"})
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )),
              React.createElement("div", {"className": "block-tile", "style": ({ paddingBottom: 80 })},
                React.createElement("div", {"className": "box-tile"},
                  React.createElement("form", {"className": "form form-small"},
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("div", {"className": "clearfix"},
                        React.createElement("div", {"className": "row-quarter"},
                          React.createElement("div", {"className": "item-group"},
                            React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "twiddler.client.type.label"}))),
                            React.createElement("div", {"className": "box-form-group"},
                              React.createElement("div", {"className": "box-control"},
                                React.createElement("div", {"className": "box-checkbox middle"},
                                  React.createElement("input", {"type": "radio", "checked": (@state.twiddler?.clientType == "Vendor"), "onChange": (@setValue.bind @, "clientType"), "value": "Vendor", "id": "radio1", "name": "type"}), React.createElement("label", {"htmlFor": "radio1"}, React.createElement(CLabel, {"k": "twiddler.client.type.vendor"}))
                                ),
                                React.createElement("div", {"className": "box-checkbox middle"},
                                  React.createElement("input", {"type": "radio", "checked": (@state.twiddler?.clientType == "Franchise"), "onChange": (@setValue.bind @, "clientType"), "value": "Franchise", "id": "radio2", "name": "type"}), React.createElement("label", {"htmlFor": "radio2"}, React.createElement(CLabel, {"k": "twiddler.client.type.franchise"}))
                                ),
                                React.createElement("div", {"className": "box-checkbox middle"},
                                  React.createElement("input", {"type": "radio", "checked": (@state.twiddler?.clientType == "OM"), "onChange": (@setValue.bind @, "clientType"), "value": "OM", "id": "radio3", "name": "type"}), React.createElement("label", {"htmlFor": "radio3"}, React.createElement(CLabel, {"k": "twiddler.client.type.ownership.and.management"}))
                                ),
                                React.createElement("div", {"className": "box-checkbox middle"},
                                  React.createElement("input", {"type": "radio", "checked": (@state.twiddler?.clientType == "LE"), "onChange": (@setValue.bind @, "clientType"), "value": "LE", "id": "radio44", "name": "type"}), React.createElement("label", {"htmlFor": "radio44"}, React.createElement(CLabel, {"k": "twiddler.client.type.le"}))
                                )
                              )
                            )
                          )
                        ),
                        React.createElement("div", {"className": "row-half"},
                          React.createElement("div", {"className": "item-group"},
                            React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "twiddler.project.and.team.access.title"}))),
                            React.createElement("div", {"className": "box-form-group"},
                              React.createElement("div", {"className": "box-control"},
                                React.createElement("div", {"className": "clearfix"},
                                  React.createElement("div", {"className": "box-checkbox middle row-half"},
                                    React.createElement("input", {"type": "checkbox", "id": "check1", "checked": (!!@state.twiddler?.showNewConstruction), "onChange": (@toggleFlag.bind @, "showNewConstruction")}), React.createElement("label", {"htmlFor": "check1"}, React.createElement(CLabel, {"k": "twiddler.project.and.team.access.new.construction"}))
                                  ),
                                  React.createElement("div", {"className": "box-checkbox middle row-half"},
                                    React.createElement("input", {"type": "checkbox", "id": "check2", "checked": (!!@state.twiddler?.showConversions), "onChange": (@toggleFlag.bind @, "showConversions")}), React.createElement("label", {"htmlFor": "check2"}, React.createElement(CLabel, {"k": "twiddler.project.and.team.access.conversions"}))
                                  ),
                                  React.createElement("div", {"className": "box-checkbox middle row-half"},
                                    React.createElement("input", {"type": "checkbox", "id": "check3", "checked": (!!@state.twiddler?.showRenovations), "onChange": (@toggleFlag.bind @, "showRenovations")}), React.createElement("label", {"htmlFor": "check3"}, React.createElement(CLabel, {"k": "twiddler.project.and.team.access.renovation"}))
                                  ),
                                  React.createElement("div", {"className": "box-checkbox middle row-half"},
                                    React.createElement("input", {"type": "checkbox", "id": "check4", "checked": (!!@state.twiddler?.showSales), "onChange": (@toggleFlag.bind @, "showSales")}), React.createElement("label", {"htmlFor": "check4"}, React.createElement(CLabel, {"k": "twiddler.project.and.team.access.sales"}))
                                  ),
                                  React.createElement("div", {"className": "box-checkbox middle row-half"},
                                    React.createElement("input", {"type": "checkbox", "id": "check5", "checked": (!!@state.twiddler?.showDistressedAssets), "onChange": (@toggleFlag.bind @, "showDistressedAssets")}), React.createElement("label", {"htmlFor": "check5"}, React.createElement(CLabel, {"k": "twiddler.project.and.team.access.distressed.assets"}))
                                  ),
                                  React.createElement("div", {"className": "box-checkbox middle row-half"},
                                    React.createElement("input", {"type": "checkbox", "id": "check6", "checked": (!!@state.twiddler?.showActivityContacts), "onChange": (@toggleFlag.bind @, "showActivityContacts")}), React.createElement("label", {"htmlFor": "check6"}, React.createElement(CLabel, {"k": "twiddler.project.and.team.access.project.contact.info"}))
                                  )
                                )
                              )
                            )
                          )
                        )
                      ),
                      React.createElement("div", {"className": "clearfix"},
                        React.createElement("div", {"className": "row-half"},
                          React.createElement("div", {"className": "item-group"},
                            React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "twiddler.services"}))),
                            React.createElement("div", {"className": "box-form-group"},
                              React.createElement("div", {"className": "box-control"},
                                React.createElement("div", {"className": "box-checkbox middle"},
                                  React.createElement("input", {"type": "checkbox", "id": "hasLibrarySite", "checked": (!!@state.twiddler?.hasLibrarySite), "onChange": (@toggleFlag.bind @, "hasLibrarySite")}), React.createElement("label", {"htmlFor": "hasLibrarySite"}, React.createElement(CLabel, {"k": "twiddler.services.has.library.site"}))
                                )
                              )
                            )
                          ),
                          React.createElement("div", {"className": "item-group"},
                            React.createElement("div", {"className": "box-form-group"},
                              React.createElement("div", {"className": "box-control"},
                                React.createElement("div", {"className": "name-block small"},
                                  React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "twiddler.other.title"}))
                                ),
                                React.createElement("div", {"className": "box-checkbox middle"},
                                  React.createElement("input", {"type": "checkbox", "id": "check7", "checked": (!!@state.twiddler?.maps), "onChange": (@toggleFlag.bind @, "maps")}), React.createElement("label", {"htmlFor": "check7"}, React.createElement(CLabel, {"k": "twiddler.other.map.access"}))
                                ),
                                React.createElement("div", {"className": "box-checkbox middle"},
                                  React.createElement("input", {"type": "checkbox", "id": "check8", "checked": (!!@state.twiddler?.noteSharing), "onChange": (@toggleFlag.bind @, "noteSharing")}), React.createElement("label", {"htmlFor": "check8"}, React.createElement(CLabel, {"k": "twiddler.other.note.sharing"}))
                                ),
                                React.createElement("div", {"className": "box-control middle"},
                                  React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.client.custom.property.set.label"})),
                                  React.createElement("input", {"type": "text", "placeholder": (@props.t('twiddler.client.custom.property.set.label', { ns: "internal_ui" })), "className": "form-control middle", "value": (@state.twiddler?.customPropertySetLabel || ""), "onChange": (@setValue.bind @, "customPropertySetLabel")})
                                ),
                                React.createElement("div", {"className": "box-control middle"},
                                  React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.services.sales.email"})),
                                  React.createElement("input", {"type": "text", "placeholder": (@props.t('twiddler.services.sales.email', { ns: "internal_ui" })), "className": "form-control middle", "value": (@state.twiddler?.salesEmail || ""), "onChange": (@setValue.bind @, "salesEmail")})
                                ),
                                React.createElement("div", {"className": "box-control middle"},
                                  React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.services.sales.first.name"})),
                                  React.createElement("input", {"type": "text", "placeholder": (@props.t('twiddler.services.sales.first.name', { ns: "internal_ui" })), "className": "form-control middle", "id": "salesFirstName", "value": (@state.twiddler?.salesFirstName || ""), "onChange": (@setValue.bind @, "salesFirstName")})
                                ),
                                React.createElement("div", {"className": "box-control middle"},
                                  React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.services.concierge.email"})),
                                  React.createElement("input", {"type": "text", "placeholder": (@props.t('twiddler.services.concierge.email', { ns: "internal_ui" })), "className": "form-control middle", "id": "conciergeEmail", "value": (@state.twiddler?.conciergeEmail || ""), "onChange": (@setValue.bind @, "conciergeEmail")})
                                )
                              )
                            )
                          )
                        ),
                        React.createElement("div", {"className": "row-half"},
                          React.createElement("div", {"className": "item-group"},
                            React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "twiddler.other.title"}))),
                            React.createElement("div", {"className": "box-form-group"},
                              React.createElement("div", {"className": "box-control"},
                                React.createElement("div", {"className": "box-control middle"},
                                  React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.other.keywords"})),
                                  React.createElement("p", {"className": "twiddler-keywords"},
                                    React.createElement("i", null,
                                      React.createElement(CLabel, {"k": "twiddler.other.default.keywords"})
                                    ),
                                    (@state.keywordsDefault)
                                  ),
                                  React.createElement("div", {"className": "box-control row-half"},
                                    React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.other.add.keywords"})),
                                    React.createElement("textarea", { \
                                      "placeholder": (@props.t('twiddler.other.keywords', { ns: "internal_ui" })),  \
                                      "className": "form-control middle no-resize",  \
                                      "id": "keywords",  \
                                      "value": (@state.twiddler?.keywordsAdded || ""),  \
                                      "onChange": (@setAddedKeywords)
                                    })
                                  ),
                                  React.createElement("div", {"className": "box-control row-half"},
                                    React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.other.remove.keywords"})),
                                    React.createElement("textarea", { \
                                      "placeholder": (@props.t('twiddler.other.keywords', ns: "internal_ui" )),  \
                                      "className": "form-control middle no-resize",  \
                                      "id": "keywords",  \
                                      "value": (@state.twiddler?.keywordsRemoved || ""),  \
                                      "onChange": (@setRemovedKeywords)
                                    })
                                  ),
                                  React.createElement("div", {"className": "clearfix"}),
                                  React.createElement("p", {"className": "twiddler-keywords mt-1"},
                                    React.createElement("i", null, React.createElement(CLabel, {"k": "twiddler.other.result.keywords"})),
                                    (@state.keywords || "")
                                  )
                                )
                              )
                            )
                          ),
                          React.createElement("div", {"className": "item-group"},
                            React.createElement("div", {"className": "box-form-group"},
                              React.createElement("div", {"className": "box-control"},
                                React.createElement("div", {"className": "box-control middle"},
                                  React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.user.tags"})),
                                  React.createElement("p", {"className": "twiddler-keywords"},
                                    React.createElement("i", null,
                                      React.createElement(CLabel, {"k": "twiddler.other.default.user.keywords"})
                                    ),
                                    (@state.twiddler?.user?.userTags || "")
                                  ),
                                  React.createElement("div", {"className": "box-control row-half"},
                                    React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.other.add.tags"})),
                                    React.createElement("textarea", { \
                                      "placeholder": (@props.t('twiddler.other.tags', { ns: "internal_ui" })),  \
                                      "className": "form-control middle no-resize",  \
                                      "id": "keywords",  \
                                      "value": (@state.twiddler?.userTagsAdded || ""),  \
                                      "onChange": (@setAddedUserTags)
                                    })
                                  ),
                                  React.createElement("div", {"className": "box-control row-half"},
                                    React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.other.remove.tags"})),
                                    React.createElement("textarea", { \
                                      "placeholder": (@props.t('twiddler.other.tags', ns: "internal_ui" )),  \
                                      "className": "form-control middle no-resize",  \
                                      "id": "keywords",  \
                                      "value": (@state.twiddler?.userTagsRemoved || ""),  \
                                      "onChange": (@setRemovedUserTags)
                                    })
                                  ),
                                  React.createElement("div", {"className": "clearfix"}),
                                  React.createElement("p", {"className": "twiddler-keywords mt-1"},
                                    React.createElement("i", null, React.createElement(CLabel, {"k": "twiddler.other.result.keywords"})),
                                    (@state.userTags || "")
                                  )
                                )
                              )
                            )
                          ),
                          React.createElement("div", {"className": "item-group"},
                            React.createElement("div", {"className": "box-form-group"},
                              React.createElement("div", {"className": "box-control"},
                                React.createElement("div", {"className": "box-control middle"},
                                  React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.user.keywords"})),
                                  React.createElement("p", {"className": "twiddler-keywords"},
                                    React.createElement("i", null,
                                      React.createElement(CLabel, {"k": "twiddler.other.default.user.keywords"})
                                    ),
                                    (@state.twiddler?.user?.keywords || "")
                                  ),
                                  React.createElement("div", {"className": "box-control row-half"},
                                    React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.other.add.keywords"})),
                                    React.createElement("textarea", { \
                                      "placeholder": (@props.t('twiddler.other.keywords', { ns: "internal_ui" })),  \
                                      "className": "form-control middle no-resize",  \
                                      "id": "keywords",  \
                                      "value": (@state.twiddler?.userKeywordsAdded || ""),  \
                                      "onChange": (@setAddedUserKeywords)
                                    })
                                  ),
                                  React.createElement("div", {"className": "box-control row-half"},
                                    React.createElement("label", {"className": "label"}, React.createElement(CLabel, {"k": "twiddler.other.remove.keywords"})),
                                    React.createElement("textarea", { \
                                      "placeholder": (@props.t('twiddler.other.keywords', ns: "internal_ui" )),  \
                                      "className": "form-control middle no-resize",  \
                                      "id": "keywords",  \
                                      "value": (@state.twiddler?.userKeywordsRemoved || ""),  \
                                      "onChange": (@setRemovedUserKeywords)
                                    })
                                  ),
                                  React.createElement("div", {"className": "clearfix"}),
                                  React.createElement("p", {"className": "twiddler-keywords mt-1"},
                                    React.createElement("i", null, React.createElement(CLabel, {"k": "twiddler.other.result.keywords"})),
                                    (@state.userKeywords || "")
                                  )
                                )
                              )
                            )
                          )
                        )
                      )
                    ),
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("div", {"className": "tile-title"},
                        React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "twiddler.report.wizards"})))
                      ),
                      React.createElement("div", {"className": "box-control"},
                        React.createElement("textarea", {"className": "form-control", "value": (@state.twiddler?.reportWizardsSpec || ""), "onChange": (@setValue.bind @, "reportWizardsSpec")})
                      )
                    ),
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("div", {"className": "tile-title"},
                        React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "twiddler.charts"})))
                      ),
                      React.createElement("div", {"className": "box-control"},
                        React.createElement("textarea", {"className": "form-control", "value": (@state.twiddler?.chartsSpec || ""), "onChange": (@setValue.bind @, "chartsSpec")})
                      ),
                      React.createElement("div", {"className": "box-btn text-left"}, React.createElement("a", {"className": "btn btn-small btn-slide-block", "onClick": (@props.toggleCustomPopup.bind @, chartDimensions)}, React.createElement(CLabel, {"k": "twiddler.show.chart.dimensions.options"})))
                    ),
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("div", {"className": "tile-title"},
                        React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "twiddler.simulate.export.limit"})))
                      ),
                      React.createElement("div", {"className": "box-control"},
                        React.createElement("input", {"type": "text", "className": "form-control middle", "value": (@state.twiddler?.exportLimit || ''), "onChange": (@setValue.bind @, "exportLimit")}),
                        React.createElement("div", {"className": "control-info"}, React.createElement(CLabel, {"k": "twiddler.current.cum.exports"}), ": ", (if @props.user?.finishedExports then @props.user?.finishedExports else 0))
                      )
                    ),
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("div", {"className": "tile-title"},
                        React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "twiddler.an.test.cases"})))
                      ),
                      React.createElement("div", {"className": "box-control"},
                        (@renderANPCCounter())
                      ),
                      React.createElement("div", {"className": "tile-title", "style": ({ marginTop: 20 })},
                        React.createElement("div", {"className": "name-block small"},
                          React.createElement("span", {"className": "bold"},
                            React.createElement(CLabel, {"k": "twiddler.an.building.segments"})
                          )
                        )
                      ),
                      React.createElement("div", {"className": "box-control"},
                        React.createElement("div", null,
                          React.createElement(CLabel, {"k": "an.actions", "param": ({ count: 1 })}), " (", React.createElement("i", null, "an.actions"), """)
"""),
                        React.createElement("div", null,
                          React.createElement(CLabel, {"k": "an.notes", "param": ({ count: 1 })}), " (", React.createElement("i", null, "an.notes"), """)
"""),
                        React.createElement("div", null,
                          React.createElement(CLabel, {"k": "an.properties", "param": ({ count: 1 })}), " (", React.createElement("i", null, "an.properties"), """)
"""),
                        React.createElement("div", null,
                          React.createElement(CLabel, {"k": "an.companies", "param": ({ count: 1 })}), " (", React.createElement("i", null, "an.companies"), """)
""")
                      ),
                      React.createElement("div", {"className": "tile-title", "style": ({ marginTop: 20 })},
                        React.createElement("div", {"className": "name-block small"},
                          React.createElement("span", {"className": "bold"},
                            React.createElement(CLabel, {"k": "twiddler.an.playground"})
                          )
                        )
                      ),
                      React.createElement("ul", null,
                        React.createElement("li", null, React.createElement(CLabel, {"k": "twiddler.an.cantBeZeroAN"})),
                        React.createElement("li", null, React.createElement(CLabel, {"k": "twiddler.an.cantBeZeroPC"}))
                      ),
                      React.createElement("div", {"className": "twiddler-anpc-playground"},
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("label", {"class": "label", "for": "actionsCount"},
                            React.createElement(CLabel, {"k": "twiddler.an.actionsCount"})
                          ),
                          React.createElement("input", {"id": "actionsCount", "className": "form-control middle", "type": "number", "value": (@state.actionsCount), "onChange": (@setActionsCount)})
                        ),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("label", {"class": "label", "for": "notesCount"},
                            React.createElement(CLabel, {"k": "twiddler.an.notesCount"})
                          ),
                          React.createElement("input", {"id": "notesCount", "className": "form-control middle", "type": "number", "value": (@state.notesCount), "onChange": (@setNotesCount)})
                        ),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("label", {"class": "label", "for": "propertiesCount"},
                            React.createElement(CLabel, {"k": "twiddler.an.propertiesCount"})
                          ),
                          React.createElement("input", {"id": "propertiesCount", "className": "form-control middle", "type": "number", "value": (@state.propertiesCount), "onChange": (@setPropertiesCount)})
                        ),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("label", {"class": "label", "for": "companiesCount"},
                            React.createElement(CLabel, {"k": "twiddler.an.companiesCount"})
                          ),
                          React.createElement("input", {"id": "companiesCount", "className": "form-control middle", "type": "number", "value": (@state.companiesCount), "onChange": (@setCompaniesCount)})
                        )
                      ),
                      (translateANPCCounter(@props.t, {
                        properties: @state.propertiesCount,
                        companies: @state.companiesCount,
                        notes: @state.notesCount,
                        actions: @state.actionsCount
                      }))
                    ),
                    React.createElement("div", {"className": "tile-group"},
                      React.createElement("div", {"className": "tile-title"},
                        React.createElement("button", {"className": "btn btn-red btn-normal", "onClick": (@openMobileFeaturePopup)},
                          React.createElement(CLabel, {"k": "twiddler.show.mobile.feature.popup"})
                        ),
                        (@state.openMobileFeaturePopup && React.createElement(CMobileFeaturePopup, { \
                          "showDownloadLinkAlways": (true),  \
                          "onClose": (()=> @setState({ openMobileFeaturePopup: false })),  \
                          "featureName": (FEATURE_TABLE[FEATURE_ENUM.PRINT].name)
                        }))
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
) ,state: (state)->
     user: state.session?.user,
     chartsDimensions: state.charts.dimensions
     translation: state.translation
  ,dispatch: (dispatch)->
     login: (session)-> dispatch(type: 'LOGIN', payload: session)
     loadChartDimensions: ()-> dispatch(type: 'LOAD_CHART_DIMENSIONS')
     toggleCustomPopup: (content) -> dispatch(type: "TOGGLE_POPUP_CUSTOM", data: title: 'twiddler.chart.dimensions.options', content: content, modalSize: 'modal-md')


