import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CFastFilterElement from '../../cmp/results/CFastFilterElement';
import Connect from '../../helpers/Connect';

function getFilterName(searchType, name) {
  if (Const.SEARCH_TYPE_COMPANIES === searchType) {
    if (!/[cC]ompany/.test(name) && ['hotelName', 'propertyId', 'Activity', 'portfolioSize', 'branch'].indexOf(name) == -1) {
      name = 'hotel' + name[0].toUpperCase() + name?.slice(1)
    } else if (['CompanyRole'].indexOf(name) == -1) {
      name = name.replace(/company/, '')
    }
  }

  return name
}

//Main
function CFilters({
  fields,
  searchType,
  searchFieldsInfo,
  searchCompanyFieldsInfo,
  addValueToSearchForm,
  onSearch
}) {
  const { t } = useTranslation();

  const putValue = useCallback((name, value, restartSearch = true, noCountChange = false) => {
    if (value == "keywords") {
      name = "Custom"
    }
    addValueToSearchForm(name, value)
    if (restartSearch && onSearch) {
      onSearch()
    }
  }, [fields])

  const putValueFromDropDown = useCallback((name, values, options) => {
    const value = values.length == 0 ? null : values.map((v) => "'" + v + "'").join(";")
    putValue(name, value, true, false, options)
  })

  const removeFilter = useCallback((name) => {
    if (name == t("results.fast.filter.opaque.filter.custom")) {
      addValueToSearchForm("keywords", "", true)
    } else if (name == t("results.fast.filter.opaque.filter.portfolio")) {
      props.toggleBlockOnSearchForm("isCompanyPortfolio")
      onSearch()
    } else if (name == t("reports.sales.management.opportunities")) {
      initSearchForm()
      onSearch()
    }

    putValue(name, undefined, false, false)
  }, [fields])


  const activityProjectSearch = fields.get("showNewConstruction") && fields.get("showRenovations") && fields.get("showConversions")
  return Array.from(fields.values(), (field) => {
    const name = field.name
    return <CFastFilterElement
      key={name}
      name={name}
      displayName={getFilterName(searchType, name)}
      field={field}
      disabled={activityProjectSearch && field.name == "hasTimelineFilter"}
      remove={() => removeFilter(name)}
      clear={() => putValue(name, null)}
      putValue={putValue}
      putValueFromDropDown={putValueFromDropDown}
      fieldInfo={searchFieldsInfo[name] || searchCompanyFieldsInfo[name]}
    />
  })
}

export default Connect(CFilters, {
  dispatch: (dispatch) => {
    return {
      addValueToSearchForm: (name, value, options) => {
        dispatch({ type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value, noCountChange: true, options: options })
      }
    }
  },

  state: (state) => {
    return {
      user: state.session && state.session.user,
      searchFieldsInfo: state.app.searchFieldsInfo,
      searchCompanyFieldsInfo: state.app.searchCompanyFieldsInfo
    }
  }
});