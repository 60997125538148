import { CLabel } from '../CLabel';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const CMobileFeaturePopup = ({ onClose, featureName, showDownloadLinkAlways = false }) => {
  const { t } = useTranslation();

  const goToAppStore = () => {
    const deviceName = window.deviceName;
    if (deviceName === "ANDROID") {
      window.open("market://details?id=com.lodgingeconometrics.businessdev");
      return;
    }
    location.href = "itms-apps://itunes.apple.com/app/1305931851";
  };

  const showDownloadLink = showDownloadLinkAlways || window.deviceName === "IOS";

  return <div className="modal open">
      <div className="modal-dialog modal-sm" style={{ paddingLeft: 16, paddingRight: 16 }}>
        <div className="modal-content" style={{ height: 'auto', marginTop: 16 }}>
          <div className="modal-header">
            <div className="modal-title">
            <div className="name-block small">{t(`mobile.unsupported.feature.title.${featureName}`)}</div>
            </div>
          </div>
          <div className="modal-body" style={{ lineHeight: "18px" }}>
          <span>{t('mobile.unsupported.feature.description')}</span>
          </div>
          <div className="modal-footer modal-mobile-feature-footer">
          <a className="btn btn-red btn-sm close-modal" onClick={onClose}>
                <span className="upper"><CLabel k="ok" /></span>
              </a>
              {showDownloadLink && <a className="btn btn-red btn-sm close-modal" onClick={goToAppStore}>
                <span className="upper"><CLabel k="mobile.unsupported.feature.download" /></span>
              </a>}
          </div>
        </div>
      </div>
    </div>
}

export default withTranslation()(CMobileFeaturePopup);