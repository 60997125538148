import { CLabel } from '../CLabel'
import Utils from '../../helpers/Utils.cjsx'
import { Link } from 'react-router'
import { CSSProperties, useState } from 'react'
import './role_short.scss'
import Connect from '../../helpers/Connect'
import CItemNote from '../note/CItemNote'
import { Trans } from 'react-i18next'

/**
 * @type {{[key: string]: CSSProperties}}
 */
const styles = {

  item: {
    lineHeight: "18px",
    display: "flex",
    alignItems: "baseline",
    fontSize: "14px",
    position: "relative",
  },

  role: {
    width: "80px",
    minWidth: "80px",
    fontWeight: "bold",
  },

  name: {
    marginRight: "4px",
    marginLeft: "4px",
    width: "100%", //NOTE: Ellipsis bug hack
    position: "unset"
  },

  icon: {
    marginLeft: "4px",
    marginRight: "4px",
    width: 15 
  }
}

const CRoleLong = ({ role, company, contact, history, roles, hasAccessToAssignableActions, user }) => {
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState(company?.notes);

  const id = company && company.id || contact && contact.companyId
  const phone = contact && contact.phone || company && company.phone
  const name = []
  if (contact && contact.fullName && contact.fullName.trim().length > 0) {
    name.push(<span key={"contact.name"} className={Utils.getColorByChanges(history, roles[role].contactId)}>{contact.fullName.trim()}</span>)
  }

  if (company && company.name && company.name.trim().length > 0) {
    name.push(<><span key={"company.name"} className={Utils.getColorByChanges(history, roles[role].companyId)}>{company.name.trim()}</span></>)
  }

  const onMouseLeave = () => {
    setOpen(false);
  }

  const onMouseEnter = () => {
    setOpen(true);
  }

  const onNoteChanged = (response) => {
    setNotes(notes.map((note) => {
      if (note.id === response.id) {
        return response;
      }
      return note;
    }));
  }

  const onNoteDeleted = (response) => {
    setNotes(notes.filter((note) => {
      return note.id !== response.id;
    }));
  }

  const renderNoteIcon = () => {
    if (hasAccessToAssignableActions && notes?.length > 0) {
      return <div className={`tooltip save ${Utils.getNextNoteAlertColor(notes, user, true)}`} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter} onClick={onMouseEnter} onTouchStart={onMouseEnter}>
        {open && <div className="note-popover note-popover-company">
          <div className="block-tile">
            <div className="box-tile selected">
              <div className="tile-action notes-list">
                <div className="tile-group" style={{ paddingBottom: 0 }}>
                    <CItemNote
                      note={{
                        ...Utils.getNoteForPopup(notes, user),
                        company: {
                          id,
                          name: company.name
                        }
                      }}
                      truncated
                      onChanged={onNoteChanged}
                      onDeleted={onNoteDeleted}
                      shouldFetchHistory
                    />
                </div>
                <div className="tile-group tile-group-more">
                  <Link
                    to={{
                      pathname: `/company/${id}`,
                      state: { openMore: Utils.getUrgentNotesCount(notes) > 1 }
                    }}
                    className="btn tooltip save"
                  >
                    {Utils.getUrgentNotesCount(notes) > 1 ? <CLabel k="menu.moreDueSoon" /> : <CLabel k="menu.more" />}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>}
        <i className="fa fa-file-text-o"></i>
      </div>
    }

    if (company?.notes?.length > 0) {
      return <Link to={"/company/" + id} style={styles.icon} className={"dark-grey bold"} data-cy-company-id={id}>
        <i style={{ marginLeft: "4px" }} className={"fa fa-file-text-o " + Utils.getNoteStatusColor(company.notes[company.notes.length - 1].dateTo, "green-text")}></i>
      </Link>
    }

    return <div style={styles.icon}></div>;
  }

  return <div key={id + role} style={styles.item} className="results-role">
    <CLabel k={"property.contact.type.short." + role} style={styles.role} />
    <Link to={"/company/" + id} style={styles.name} className={"dark-grey bold text-overflow tooltip tooltip-bottom-middle"} data-cy-company-id={id}>
      {name.reduce((result, item) => result.length > 0 ? [...result, ', ', item] : [item], [])}
    </Link>
    {renderNoteIcon()}
  </div>
}

export default Connect(CRoleLong, {
  state: (state) => {
    return {
      user: state.session && state.session.user,
      hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions")
    }
  }
})