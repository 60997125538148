import { useEffect } from 'react'
import { CLabel } from '../../cmp/CLabel'
import Connect from '../../helpers/Connect'
import Const from '../../helpers/Const'
import CCompanySearchForm from './CCompanySearchForm'
import CPropertySearchForm from './CPropertySearchForm'
import CSearchContentTab from './CSearchContentTab'


const LENAV_PREVIOUS_SEARCH_FORM = "LENAV_PREVIOUS_SEARCH_FORM"

const CSearchForm = ({ defaultValues, excludeTab, hideRestorePreviousForm, searchFormColumns, form, setSearchType, restorePreviousSearch, previousSearchKey, initSearchForm }) => {

  const onScroll = (e) => {
    e.preventDefault()
  }

  useEffect(() => {
    document.querySelector('.main').addEventListener('scroll', onScroll)

    return () => {
      document.querySelector('.main').removeEventListener('scroll', onScroll)
    }
  }, [])

  const changeTab = (name) => {
    setSearchType(name)
  }

  const restorePreviousSearchForm = () => {
    restorePreviousSearch(JSON.parse(sessionStorage.getItem(previousSearchKey || LENAV_PREVIOUS_SEARCH_FORM)))
  }

  const showRestoreLastSearch = !hideRestorePreviousForm && sessionStorage.getItem(previousSearchKey || LENAV_PREVIOUS_SEARCH_FORM)

  const tabs = [
    { name: 'search.search.properties', tab: Const.SEARCH_TYPE_PROPERTIES },
    { name: 'search.search.companies', tab: Const.SEARCH_TYPE_COMPANIES },
  ].filter(x => x.tab != excludeTab)

  return (
    <div className="search-filter-form">
      <div className="search-nav">
        <div className="box-nav box-right search-box">
          {showRestoreLastSearch &&
            <a className="btn link-grey pl-0" onClick={restorePreviousSearchForm}><i className="fa fa-long-arrow-left icon-left"></i><CLabel className="search-form-control-button-text" k="search.restore.previous.search" /></a>
          }
          <a className={"btn link-grey" + (form.pristine ? ' disabled' : '')} onClick={() => initSearchForm(true, defaultValues)}><i style={{ marginRight: '5px', position: 'relative', top: '1px' }} className="icon-close icon-left"></i><CLabel className="search-form-control-button-text" k="search.clear.all.filters" /></a>
        </div>

        <div className="box-nav-tabs">
          <ul className="nav-tabs tabs-fixed">
            {tabs.map(x => (
              <li key={x.tab} className={form.searchType == x.tab ? 'active' : ''}>
                <a onClick={() => changeTab(x.tab)}>
                  <CLabel k={x.name} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="block-tile">
        <div className="box-tile">
          <div className="block-tabs">
            <CSearchContentTab tabName={form.searchType}>
              {form.searchType == Const.SEARCH_TYPE_PROPERTIES && <CPropertySearchForm form={form} />
                || form.searchType == Const.SEARCH_TYPE_COMPANIES && <CCompanySearchForm form={form} />}
            </CSearchContentTab>
          </div>
        </div>
      </div>
    </div>
  )
}




export default Connect(CSearchForm,
  {
    dispatch: (dispatch) => ({
      setSearchType: (searchType) => dispatch({ type: 'SET_SEARCH_TYPE', searchType: searchType }),
      restorePreviousSearch: (previousSearchForm) => dispatch({ type: 'RESTORE_PREVIOUS_SEARCH', previousSearchForm: previousSearchForm }),
      initSearchForm: (withoutPreviousSearchSave, defaultValues) => dispatch({ type: 'INIT_SEARCH_FORM', withoutPreviousSearchSave: withoutPreviousSearchSave, defaultValues: defaultValues }),
    }),

    state: (state) => ({
      form: state.searchForm.form,
      searchFormColumns: state.searchForm.searchFormColumns
    })
  })
