Connect = require '../../helpers/Connect'

{ API } = require '../../redux/Store'
{ CLabel } = require '../../cmp/CLabel'
CSectionNotesList = require '../../cmp/note/CSectionNotesList'
CNoteText = require '../../cmp/note/CNoteText'
CNoteDates = require '../../cmp/note/CNoteDates'

#props.subtitle
#props.notes
#props.maxNotes
#props.onNoteChanged
module.exports = createReactClass(
  displayName: "CRelatedNotes"

  getInitialState: ()->
    showMore: false

  toggleShowMore: ()->
    @setState showMore: !@state.showMore

  mobileBlockToggle:(e)->
    e.target.closest('.box-slide-block').querySelector('.slide-block').classList.toggle('open')

  render: ()->
    React.createElement("div", {"className": "tile-action notes-list"},
      React.createElement("div", {"className": "tile-group"},
        React.createElement("div", {"className": "tile-title"},
          React.createElement("div", {"className": "box-btn box-right"},
            React.createElement("div", {"className": "show-mobile"},
              React.createElement("a", {"className": "btn btn-small btn-slide-block", "onClick": (@mobileBlockToggle)}, React.createElement("div", {"className": "box-arrow"}, React.createElement("i", {"className": "fa fa-angle-up"})))
            )
          ),
          React.createElement("div", {"className": "name-block middle"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "property.actions.and.notes"}))),
          (if @props.subtitle
            React.createElement("div", {"className": "small bold", "style": (fontSize: "12px", lineHeight: "14px", overflow: "visible")}, (@props.subtitle))
          )
        )
      ),
      React.createElement("div", {"className": "slide-block open"},
        React.createElement(CSectionNotesList, {"notes": (@props.notes),  \
                "onNoteChanged": (@props.onNoteChanged),  \
                "maxNotes": (@props.maxNotes)})
      )
    )
)
