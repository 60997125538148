Connect = require '../../helpers/Connect'
{ CLabel } = require '../CLabel'
{Link} = require 'react-router'
CBreadcrumb = require './CBreadcrumb'

{ getNoteStatusColor, getNoteForPopup, getNextNoteAlertColor, getUrgentNotesCount } = require '../../helpers/Utils'
{ withTranslation } = require 'react-i18next'
camelCase = require 'lodash.camelcase'
CItemNote = require '../note/CItemNote'

module.exports = withTranslation() Connect createReactClass(
  displayName: 'CResultCompanies'


  getInitialState: ->
    open: null

  printRoles: (roles)->
    return roles.map((role) => @props.t("data.company.roles.#{camelCase(role)}", { ns: "data" })).join(', ') 

  gotoProperties: (id) -> () =>
    @props.initSearchForm()
    @props.setSearchType(Const.SEARCH_TYPE_PROPERTIES)
    @props.addValueToSearchForm('companyId', id)
    @props.router.push("/results")

  generateBreadcrumb: (company)->
    if @props.result.headings?.length > 0
      result = []
      @props.result.headings.filter (name, i)=>
        if name.indexOf(".") == -1
          b = company[name]
        else
          b = company[name.split(".")[0]]?[name.split(".")[1]]

        if b
          if name == "externalModifiedAt"
            b = Moment(b).format("YYYY/MM/DD hh:mm")
          result.push (@props.searchCompanyFieldsInfo[name]?.headingFormat || "{{value}}").replace("{{value}}", b)

      result.join(";;;")

  onMouseEnter: (id) -> () =>
    @setState open: id

  onMouseLeave: ()->
    @setState open: null

  onNoteChanged: (companyId) -> (note) =>
    @props.editNote(companyId, note)

  onDeleted: (companyId) -> (note)=>
    @props.deleteNote(companyId, note.id)

  render: ->
    React.createElement("div", {"className": "block-tile"},
      React.createElement("div", {"className": "box-tile"},
        React.createElement("div", {"className": "table table-results"},
          React.createElement("div", {"className": "table-header"},
            React.createElement("div", {"className": "table-row header hide-mobile"},
              React.createElement("div", {"className": "cell-name", "style": (paddingLeft: "4px")},
                React.createElement("i", {"className": "fa fa-file-text-o invisible", "style": (marginRight: "4px")}),
                React.createElement(CLabel, {"k": "results.companies.name"})
              ),
              React.createElement("div", {"className": "cell-location"},
                React.createElement(CLabel, {"k": "results.companies.location"})
              ),
              React.createElement("div", {"className": "cell-role"},
                React.createElement(CLabel, {"k": "results.companies.role"})
              ),
              React.createElement("div", {"className": "cell-size-heading"},
                React.createElement(CLabel, {"k": "results.companies.portfolio.size.label"})
              )
            ),
            React.createElement("div", {"className": "table-row header hide-mobile"},
              React.createElement("div", {"className": "cell-name"}),
              React.createElement("div", {"className": "cell-location"}),
              React.createElement("div", {"className": "cell-role"}),
              React.createElement("div", {"className": "cell-size-heading"},
                React.createElement("div", {"className": "cell-size-subheading"},
                  React.createElement(CLabel, {"k": "results.companies.portfolio.size.overall"})
                ),
                React.createElement("div", {"className": "cell-size-subheading"},
                  React.createElement(CLabel, {"k": "results.companies.portfolio.size.mine"})
                )
              )
            )
          ),
          React.createElement("div", {"className": 'table-results-body'},
            (lastBc = null),
            (@props.results.map (result, i)=>
              [
                React.createElement("div", {"key": (result.id)},
                  (bc = @generateBreadcrumb(result)
                  if lastBc != bc
                    lastBc = bc
                    React.createElement(CBreadcrumb, {"path": (bc)})
                  )
                )
                React.createElement("div", {"key": (i), "className": "table-row"},
                  React.createElement("div", {"className": "cell-name column", "style": (display: "flex", paddingLeft: "6px")},
                    (!@props.hasAccessToAssignableActions && result.notes?.length > 0 && React.createElement("div", {"style": (marginRight: "4px")}, React.createElement("i", {"className": ("fa fa-file-text-o #{getNextNoteAlertColor(result.notes, @props.user, true)}")}))),
                    (@props.hasAccessToAssignableActions && React.createElement("div", {"style": (marginRight: "4px")},
                      (if getNoteForPopup(result.notes, @props.user)
                        React.createElement("div", {"className": "tooltip save", "onMouseLeave": (@onMouseLeave), "onMouseEnter": (@onMouseEnter(result.id)), "onClick": (@onMouseEnter(result.id)), "onTouchStart": (@onMouseEnter(result.id))},
                          (if @state.open == result.id then React.createElement("div", {"className": "note-popover note-popover-companies"},
                            React.createElement("div", {"className": "block-tile"},
                              React.createElement("div", {"className": "box-tile selected"},
                                React.createElement("div", {"className": "tile-action notes-list"},
                                  React.createElement("div", {"className": "tile-group", "style": ({ paddingBottom: 0 })},
                                    React.createElement(CItemNote, { \
                                      "note": ({
                                      ...getNoteForPopup(result.notes, @props.user),
                                      company: result
                                    }),   \
                                      "truncated": true,  \
                                      "hideMenu": true,  \
                                      "onChanged": (@onNoteChanged(result.id)),  \
                                      "onDeleted": (@onDeleted(result.id)),  \
                                      "shouldFetchHistory": true
                                    })
                                  ),
                                  React.createElement("div", {"className": "tile-group tile-group-more"},
                                    React.createElement(Link, {"to": ({
                                        pathname: "/company/#{result.id}"
                                        state: {
                                          openMore: getUrgentNotesCount(result.notes) > 1
                                        }
                                      }),  \
                                      "className": "btn tooltip save"
                                    },
                                      (if getUrgentNotesCount(result.notes) > 1 then  React.createElement(CLabel, {"k": "menu.moreDueSoon"}) else React.createElement(CLabel, {"k": "menu.more"}))
                                    )
                                  )
                                )
                              )
                            )
                          )),
                          React.createElement("i", {"className": ("fa fa-file-text-o #{getNextNoteAlertColor(result.notes, @props.user, true)}")})
                        )
                      )
                    )),
                    React.createElement(Link, {"to": ("/company/#{result.id}"), "className": "link"}, (result.name || "(No Name)")),
                    React.createElement("div", {"className": "show-mobile cell-location"},
                      (if result.country == 'United States'
                        React.createElement("span", null, (result.city), (if result.stateProvince && result.city then ', ' + result.stateProvince else result.city))
                      else
                        React.createElement("span", null, (result.city), (if result.country && result.city then ', ' + result.country else result.country))
                      )
                    )
                  ),
                  (if result.country == 'United States'
                    React.createElement("div", {"className": "cell-location hide-mobile column"}, (result.city), (if result.stateProvince && result.city then ', ' + result.stateProvince else result.stateProvince))
                  else
                    React.createElement("div", {"className": "cell-location hide-mobile column"}, (result.city), (if result.country && result.city then ', ' + result.country else result.country))
                  ),
                  React.createElement("div", {"key": (i), "className": "cell-role column"},
                    React.createElement("div", {"className": "roles"},
                    ( if result.roles
                      React.createElement("span", {"className": "show-mobile"}, React.createElement(CLabel, {"k": "results.companies.role"}), ": ")
                    ),
                    (@printRoles(result.roles)),
                    React.createElement("div", {"className": "info-size show-mobile"},
                      React.createElement(CLabel, {"k": "results.companies.portfolio.size.label"}), ": ", (result.portfolioSize)
                    ),
                    React.createElement("div", {"className": "info-size show-mobile"},
                      React.createElement(CLabel, {"k": "results.companies.user.portfolio.size"}), ": ", React.createElement("a", {"onClick": (@gotoProperties(result.id))}, (result.territoryPortfolioSize))
                    )
                    )
                  ),
                  React.createElement("div", {"className": "column cell-size-half hide-mobile"}, (result.portfolioSize)),
                  React.createElement("div", {"className": "column cell-size-half hide-mobile"},
                    React.createElement("a", {"onClick": (@gotoProperties(result.id))}, (result.territoryPortfolioSize))
                  )
                )
              ]
            )
          )
        )
      )
    )

), {

  state: (state)->
    user: state.session?.user
    result: state.searchForm?.result
    searchForm: state.searchForm.form
    searchCompanyFieldsInfo: state.app.searchCompanyFieldsInfo
    hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions")
  dispatch: (dispatch)->
    addValueToSearchForm: (name, value) ->
          dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM_AND_SEARCH', name: name, value: value)
    setSearchType: (searchType) -> dispatch(type: 'SET_SEARCH_TYPE', searchType: searchType)
    initSearchForm: (form) -> dispatch(type: 'INIT_SEARCH_FORM')
    search: (page, flush) -> dispatch(type: 'SEARCH', page: page, target:"Results", flush: flush)
    editNote: (id, note)-> dispatch(type: 'EDIT_NOTE_IN_SEARCH_RESULT', id: id, note: note)
    deleteNote: (id, noteId)-> dispatch(type: 'DELETE_NOTE_IN_SEARCH_RESULT', id: id, noteId: noteId)
}