Connect = require '../../helpers/Connect'
{ CLabel } = require "../CLabel"
Utils = require '../../helpers/Utils'
Moment = require 'moment'


module.exports = Connect createReactClass(
  displayName: "CAdminAnalyticsSessionsTable"

  stateDirty: []

  getInitialState: () ->
    {}

  componentDidMount: () ->

  componentWillReceiveProps: (newProps) ->
    {}

  render: ->
    React.createElement("div", {"className": "flex-table"},
      React.createElement("div", {"className": "flex-tr flex-th"},
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.date.start.short"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.date.end.short"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.user"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.client"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.platform"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.session.duration"})
        ),
        React.createElement("div", {"className": "flex-td"},
          React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.actions"})
        )
      ),
      (@props.tableData.map (data, idx)->
        React.createElement("div", {"className": "flex-tr", "key": (idx)},
          React.createElement("div", {"className": "flex-td"},
            (data.startedAt && Moment(data.startedAt).tz('UTC').format('YYYY-MM-DD HH:mm'))
          ),
          React.createElement("div", {"className": "flex-td"},
            (data.finishedAt && Moment(data.finishedAt).tz('UTC').format('YYYY-MM-DD HH:mm'))
          ),
          React.createElement("div", {"className": "flex-td"},
            (data.userName)
          ),
          React.createElement("div", {"className": "flex-td"},
            (data.clientName)
          ),
          React.createElement("div", {"className": "flex-td"},
            (if data?.isAlias then data.platform + ', Alias' else data.platform)
          ),
          React.createElement("div", {"className": "flex-td"},
            (Math.ceil(Moment(data.finishedAt).diff(Moment(data.startedAt)) / 1000 / 60 + 1))
          ),
          React.createElement("div", {"className": "flex-td"},
            (data.count)
          )
        )
      )
    )
), {
  state: (state) ->
    {}
  dispatch: (dispatch) ->
    {}
}