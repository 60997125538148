{ Link } = require "react-router"
Connect = require "../../helpers/Connect"
Utils = require "../../helpers/Utils"
{ CLabel } = require '../../cmp/CLabel'
An = require '../../helpers/Analytics'
{ onScrollTooltipPosition } = require '../../helpers/UtilsHelpers'

module.exports = Connect createReactClass(
  displayName: "CSavedSearches"

  setFilterForm: (filter)->
    @props.setForm(filter)
    An.search.savedSearch()
    @props.router.push("/results/#{filter.filterId}")

  componentDidMount: ->
    onScroll = onScrollTooltipPosition('saved-search-tooltip', 'saved-search-tooltip-anchor')
    document.querySelector('.main-content').addEventListener 'scroll', onScroll

  componentWillUnmount: ->
    onScroll = onScrollTooltipPosition('saved-search-tooltip', 'saved-search-tooltip-anchor')
    document.querySelector('.main-content').removeEventListener 'scroll', onScroll

  render: ->
    React.createElement("div", {"className": "block-tile row-half"},
      React.createElement("div", {"className": "box-tile"},
        React.createElement("div", {"className": "tile-title flex-wrap tile-title-saved-searches"},
          React.createElement("div", {"className": "name-block"}, React.createElement("span", {"className": "upper bold"}, React.createElement(CLabel, {"k": "home.saved.searches.title"}))),
          React.createElement("div", {"className": "box-btn box-right info-icon", "onClick": (if Utils.isMobile() then @props.onTooltip else undefined)},
            React.createElement("a", {"id": "saved-search-tooltip-anchor", "className": "btn btn-info tooltip-bottom"},
              React.createElement("span", {"id": "saved-search-tooltip", "className": "tooltiptext"}, React.createElement(CLabel, {"k": "home.saved.searches.info"})),
              React.createElement("i", {"className": "fa fa-info"})
            )
          ),
          React.createElement("div", {"className": "box-btn box-right", "style": (marginRight: '30px')}, React.createElement(Link, {"to": '/search', "className": "btn"}, React.createElement("i", {"className": "fa fa-plus small"}), " ", React.createElement(CLabel, {"k": "home.new.search"})))
        ),
        React.createElement("div", {"className": "tile-table"},
          React.createElement("table", {"className": "table"},
            React.createElement("tbody", null,
              ((@props.savedFilters?.filters || []).map((filter, i)=>
                React.createElement("tr", {"className": "search-item", "onClick": (@setFilterForm.bind @, filter), "key": (i)},
                  React.createElement("td", null, (filter.name)),
                  React.createElement("td", {"className": "cell-arrow"}, React.createElement("i", {"className": "fa fa-angle-right"}))
                )
              ))
            )
          )
        )
      )
    )
),
dispatch: (dispatch)->
  setForm: (form) ->
    dispatch(type: 'SET_FILTER_FORM', form: form)
    dispatch(type: 'NEW_SEARCH')

state: (state)->
  savedFilters: state.savedFilters
  firstName: state.session?.user?.firstName