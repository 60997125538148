Connect = require '../helpers/Connect'
{ CLabel } = require '../cmp/CLabel'
CWpSso = require '../cmp/CWpSso'
HeaderSimple = require '../pages/parts/HeaderSimple'

module.exports = Connect createReactClass(

  getUrl: ->
    if (@props.url && @props.token) then @props.url + @props.token else 'about:blank'

  render: ->
    React.createElement("div", {"className": "wrapper-content"},
      React.createElement(HeaderSimple, {"title": (@props.route.title)}),
      React.createElement("div", {"className": "main home-page"},
        React.createElement("div", {"className": "main-content"},
          React.createElement("div", {"className": "container"},
            React.createElement("div", {"className": "dashboard home"},
              React.createElement(CWpSso, {"url": (@getUrl())})
            )
          )
        )
      )
    )
),{

  state: (state) ->
    session: state.session
    url: state.app?.applicationSettings?.librarySiteSso
    token: state.session?.jwtToken
}


    