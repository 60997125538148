{ extend } = require '../../helpers/UtilsHelpers'
DEFAULT_TAB = 'admin'

initialState = {
  translations: {}
  searchFieldsInfo: {}
  searchCompanyFieldsInfo: {}
  locationHistory: []
}

clone = (o) ->
  extend(true, { version: Math.random() }, o)

module.exports = (getStore, getAPI) ->
  ping = () ->
    getAPI().ping null, () ->
      window.location.reload()

  loadSettings = () ->
    getAPI().settings (settings) ->
      getStore().dispatch(type: "SET_SETTINGS", payload: settings)

  (state = initialState, action) ->
    if action.type == "SET_SETTINGS"
      state = extend initialState, locationHistory: state.locationHistory, action.payload
      state.tab = DEFAULT_TAB
      clone state
    else if action.type == "LOAD_SETTINGS"
      loadSettings()
      state
    else if action.type == "SET_ADMIN_TAB"
      state.tab = action.name
      clone state
    else if action.type == "CHANGE_LOCATION"
      if action.action == "PUSH"
        state.locationHistory?.push([action.location])
      else if action.action == "POP"
        state.locationHistory?.pop()
      clone state
    else if action.type == "CHECK_SERVER_IS_RUNNING"
      ping()
      state
    else if action.type == "SET_WARNING_MESSAGE"
      state.showWarningMessage = action.showWarningMessage
      clone state
    else if action.type == "SET_EXPORT_IN_PROGRESS"
      state.exportInfo = action.payload
      clone state
    else if action.type == "SET_IMPORT_JOB"
      state.importJob = action.payload
      clone state
    else if action.type == "SET_EXPORT_QUEUE_NUMBER"
      exportElement = (state.exportInfo.elements.filter (e) -> e.id == parseInt(action.payload.id))[0]
      if exportElement
        exportElement.numberInQueue = action.payload.numberInQueue
      clone state
    else
      state
