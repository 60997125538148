import React, { createRef, useEffect } from "react";

const VisibilitySensor = ({ offset = 0, onVisible }) => {
  const currentElement = createRef();

  const onScroll = () => {
    if (!currentElement.current) {
      return;
    }
    const top = currentElement.current.getBoundingClientRect().top;
    if (top + offset >= 0 && top - offset <= window.innerHeight) {
      onVisible();
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll, true);
    return () => document.removeEventListener("scroll", onScroll, true);
  });

  return <div ref={currentElement} />;
};

export default VisibilitySensor;
