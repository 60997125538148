{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
{ API } = require '../../redux/Store'
Utils = require '../../helpers/Utils'
{ CLabel } = require '../CLabel'
CMultipleDropdown = require '../filters/CMultipleDropdown'
Moment = require 'moment'
TimeInput = require('rc-time-picker').default

module.exports = Connect createReactClass(
  displayName: 'CAdmin'

  intervalId: 0

  getInitialState: () ->
    info: {}
    time: Moment().startOf('day')
    importHistory: []
    log: ""
    changed: false
    importJob: @props.importJob
    isAborting: false

  componentWillMount: () ->
    if !@props.user?.isAdmin
      @props.router.push('/home')

  componentWillUnmount: () ->
    clearTimeout(@intervalId)

  componentDidMount: () ->
    @loadData(true)

  loadData: (loadSettings) ->
    if loadSettings
      API.getImportSettings (info)=>
        importTime = Moment()
          .set('hour', info.settings?.hours ? 0)
          .set('minute', info?.settings?.minutes ? 0)
        @setState info: info, time: importTime
    API.getImportHistory (importHistory) =>
      Object.keys(importHistory).forEach((key) =>
        list = importHistory[key]
        list.forEach((h, j) =>
          if !h.finishTime
            @loadProgress()
            false
        )
      )
      @setState importHistory: importHistory, isAborting: false
  

  setTime: (e) ->
    info = @state.info
    info?.settings.hours = e.get('hour')
    info?.settings.minutes = e.get('minute')
    @setState info: info, time: Moment().set('hour', info.settings?.hours).set('minute', info.settings?.minutes), changed: true

  setDayOfWeek: (_, v) ->
    info = @state.info
    info?.settings.daysOfWeek = v.join(';')
    @setState info: info, changed: true

  saveChanges: () ->
    @setState changed: false
    API.saveImportSettings @state.info?.settings, (r) ->
      console.log(r)

  toggleRepeatWeekly: () ->
    info = @state.info
    info?.settings.active = !info?.settings.active
    @setState info: info, changed: true

  startImport: () ->
    isSilent = false
    startImport = (isSilent = false) =>
      API.startImport isSilent, () =>
        document.querySelector(".admin-page .item-date.active")?.classList.remove("active")
        console.log("Start importing")
        @loadData(false)

    proceed = () =>
      @props.togglePopupCustom()
      startImport(isSilent)

    content = React.createElement("div", {"key": "scan.popup"},
                React.createElement("p", null, React.createElement(CLabel, {"k": "admin.full.scan.description"})),
                React.createElement("div", {"className": "box-control box-inline form", "style": (textAlign: 'right')},
                  React.createElement("div", {"className": "box-checkbox", "style": (width: '90%', margin: 'auto')},
                    React.createElement("input", {"type": "checkbox", "id": "scanSilent", "onChange": (() => isSilent = true)}), React.createElement("label", {"htmlFor": "scanSilent", "style": (fontSize: '14px')}, React.createElement(CLabel, {"k": "admin.full.scan.silent"}))
                  )
                )
              )
    button = React.createElement("a", {"className": "btn btn-red btn-sm close-modal", "onClick": (proceed)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.full.scan.proceed"})))
    @props.togglePopupCustom content, button, 'cancel'

  startIndexing: () ->

    startIndexing = () =>
      API.startIndexing () =>
        document.querySelector(".admin-page .item-date.active")?.classList.remove("active")
        @loadData(false)
        @props.setImportInProgress(true)
        console.log("Start indexing")

    proceed = () =>
      @props.togglePopupCustom()
      startIndexing()

    content = React.createElement("p", null,
      React.createElement(CLabel, {"k": "admin.run.indexing.description"})
    )
    button = React.createElement("a", {"className": "btn btn-red btn-sm close-modal", "onClick": (proceed)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.full.scan.proceed"})))
    @props.togglePopupCustom content, button, 'cancel'

  abortImport: () ->
    @setState isAborting: true
    API.abortImport null, null, () =>
      console.log("Abort importing")
      @loadData(false)

  openLog: (h, e) ->
    if e.target.classList.contains("active")
      return
    clearTimeout(@intervalId)
    @setState log: "Loading..."
    if (e.target.closest(".box-scroll").querySelector(".item-date.active"))
      e.target.closest(".box-scroll").querySelector(".item-date.active").classList.remove("active")
    e.target.classList.add("active")

    if h.finishTime
      console.log("FINISH TIME")
      API.getHistoryLog h.id, (log) =>
        console.log log.indexOf("↵");
        @setState log: React.createElement("div", {"className": "log"}, (log))
    else
      @loadProgress()

  loadProgress: () ->
    clearTimeout(@intervalId)
    @intervalId = setInterval () =>
      API.getImportJob (job) =>
        @setState importJob: job
      API.getImportProgress (log) =>
        if !log
          clearTimeout(@intervalId)
          @loadData(false)
        @setState log: React.createElement("div", {"className": "log"}, (log))

    , 1000

  render: ->
    React.createElement("div", null,
      React.createElement("div", {"className": "block-tile admin-page"},
        React.createElement("div", {"className": "box-tile"},
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "row-quarter"},
                React.createElement("div", {"className": "info-count"}, React.createElement("span", {"className": "count"}, (@state.info?.tables?.property)), React.createElement(CLabel, {"k": "admin.properties"}))
              ),
              React.createElement("div", {"className": "row-quarter"},
                React.createElement("div", {"className": "info-count"}, React.createElement("span", {"className": "count"}, (@state.info?.tables?.project)), React.createElement(CLabel, {"k": "admin.projects"}))
              ),
              React.createElement("div", {"className": "row-quarter"},
                React.createElement("div", {"className": "info-count"}, React.createElement("span", {"className": "count"}, (@state.info?.tables?.client)), React.createElement(CLabel, {"k": "admin.clients"}))
              ),
              React.createElement("div", {"className": "row-quarter"},
                React.createElement("div", {"className": "info-count"}, React.createElement("span", {"className": "count"}, (@state.info?.tables?.user)), React.createElement(CLabel, {"k": "admin.users"}))
              )
            )
          ),
          React.createElement("div", {"className": "tile-group"},
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "row text-right"},
                React.createElement("div", {"className": "box-left", "style": (paddingRight: '10px')},
                  (if !@state.importJob?.jobInProgress
                    React.createElement("div", {"className": "text-left"},
                      React.createElement("a", {"style": (marginRight: '10px'), "className": "btn btn-border-grey btn-normal", "onClick": (@startImport)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.full.scan.import"}))),
                      React.createElement("a", {"className": "btn btn-border-grey btn-normal", "onClick": (@startIndexing)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.run.indexing.reindex"})))
                    )
                  else if @state.isAborting || @state.importJob?.stage == 'ABORTING'
                    React.createElement("a", {"className": "btn btn-border-grey btn-normal"}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.is.aborting"})))
                  else
                    React.createElement("a", {"className": "btn btn-border-grey btn-normal", "onClick": (@abortImport)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.abort.import"})))
                  )
                ),
                React.createElement("div", {"style": (marginBottom: '10px'), "className": "box-inline"},
                  React.createElement("div", {"className": "box-control box-inline row-quarter", "style": (textAlign: 'left')},
                    React.createElement("div", {"className": "box-checkbox"},
                      React.createElement("input", {"type": "checkbox", "id": "repeatWeekly", "onChange": (@toggleRepeatWeekly), "checked": (@state.info?.settings?.active || false)}), React.createElement("label", {"htmlFor": "repeatWeekly"}, React.createElement(CLabel, {"k": "admin.weekly.auto.update"}))
                    )
                  ),
                  React.createElement("div", {"className": "box-control box-inline text-left row-quarter", "style": (marginBottom: '10px')},
                    React.createElement(CMultipleDropdown, {"placeholder": "--none--", "className": "row-half", "onChange": (@setDayOfWeek), "values": (@state.info?.settings?.daysOfWeek || null), "options": ([{value: "1", label:"Monday"}, {value: "2", label:"Tuesday"}, {value: "3", label:"Wednesday"}, {value: "4", label:"Thursday"}, {value:"5", label: "Friday"}, {value: "6", label: "Saturday"}, {value: "7", label: "Sunday"}])})
                  ),
                  React.createElement("div", {"className": "box-control box-inline text-left row-half"},
                    React.createElement(TimeInput, { \
                      "onChange": (@setTime),  \
                      "value": (@state.time),  \
                      "showSecond": (false),  \
                      "hideDisabledOptions": (true),  \
                      "allowEmpty": (false)
                    }),
                    React.createElement("span", {"style": (marginLeft: '5px')}, React.createElement(CLabel, {"k": "admin.timezone.utc"}))
                  )
                ),
                React.createElement("a", {"style": (marginLeft: '20px'), "className": "btn btn-red btn-normal #{if !@state.changed then 'disabled unselectable'}", "onClick": (@saveChanges)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.save.changes"})))
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": "block-tile"},
        React.createElement("div", {"className": "box-tile"},
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "tile-title"},
              React.createElement("div", {"className": "name-block middle"}, React.createElement(CLabel, {"k": "admin.update.logs"}))
            ),
            React.createElement("div", {"className": "block-logs"},
              React.createElement("div", {"className": "items-list"},
                React.createElement("div", {"className": "list-date"},
                  React.createElement("div", {"className": "box-scroll"},
                    (Object.keys(@state.importHistory).map (key) =>
                      list = @state.importHistory[key]
                      ts = key
                      React.createElement("div", {"className": ("item-date #{if list.length == 1 && !list[0].finishTime then "active" else ""}"), "key": (ts), "onClick": ((e) => if list.length == 1 then @openLog(list[0], e))},
                        (Moment.utc(ts * 1).format("YYYY-MM-DD")),
                        (if list.length > 1
                          list.map (h, j) =>
                            React.createElement("div", {"onClick": (@openLog.bind @, h), "className": ("item-date item-time #{if !h.finishTime then "active" else ""}"), "key": (j)}, (Moment(h.startTime * 1).utc().format("HH:mm z")))
                        )
                      )
                    )
                  )
                ),
                React.createElement("div", {"className": "list"},
                  React.createElement("div", {"className": "box-scroll log-box"},
                    (@state.log)
                  )
                )
              )
            )
          )
        )
      )
    )
),state: (state) ->
    user: state.session?.user
    importJob: state.app.importJob
  ,dispatch: (dispatch) ->
    login: (session) -> dispatch(type: 'LOGIN', payload: session)
    togglePopupCustom: (content, button, buttonText)-> dispatch(type: 'TOGGLE_POPUP_CUSTOM', data: title: 'admin.full.scan.title', content: content, button: button, buttonText: buttonText, buttonTextClass: 'btn-grey row-half', hideCloseOnMobile: true)
