import { useCallback, useEffect, useMemo, useState } from 'react'
import { CLabel } from '../cmp/CLabel'
import CAnimatedNoteBlock from './CAnimatedNoteBlock'
import AnimateHeight from 'react-animate-height';

const ACTION_DUE = "ACTION_DUE"
const DONE_ACTIONS = "DONE_ACTIONS"

export default ({ animatingNoteId, tabName, notes, animation, notesLoading, onMarkingAction, onNoteDeleted, onNoteChanged, onNoteAdded, hasFilters, column, title, isDimmed }) => {
  const label = useMemo(() => {
    if (tabName === ACTION_DUE) {
      if (hasFilters) {
        if (column === DONE_ACTIONS) {
          return "actions.and.notes.tab.action.done.filters";
        }
        return "actions.and.notes.tab.action.all.filters";
      }
      if (column === DONE_ACTIONS) {
        return "actions.and.notes.tab.action.done.empty";
      }
      return "actions.and.notes.tab.action.all.empty";
    }
    if (hasFilters) {
      return "actions.and.notes.tab.note.all.filters"
    }
    return "actions.and.notes.tab.note.all.empty"
  }, [tabName, hasFilters]);

  const [isOpenNoteDropdown, setIsOpenNoteDropdown] = useState(false);

  return <div className={"box-tab" + (isDimmed ? " dimmed" : "")} style={{ display: "block" }}>
    {title}
    <div className={`notes-list ${isDimmed ? "dimmed" : ""}`}>
      {notes.length > 0
        ? notes.map((note, i) => (
          <CAnimatedNoteBlock
            animation={animation}
            column={column}
            isAnimating={animatingNoteId === note.id}
            disableMenu={notesLoading || Boolean(animatingNoteId)}
            onNoteDeleted={onNoteDeleted}
            onNoteChanged={onNoteChanged}
            onNoteAdded={onNoteAdded}
            onMarkingAction={onMarkingAction}
            key={`note-${column}-${note.id}-${i}`}
            note={note}
            isOpenNoteDropdown={isOpenNoteDropdown && i === notes.length - 1}
            setIsOpenNoteDropdown={setIsOpenNoteDropdown}
          />
      ))
        : <div className="no-info empty">
          <CLabel k={label} />
        </div>
      }
    </div>
  </div>;
}

