import Connect from '../../helpers/Connect'
import CFilterDropdown from '../../cmp/filters/CFilterDropdown'
import CMobileDropdownMenu from '../../cmp/CMobileDropdownMenu'
import { CLabel } from '../../cmp/CLabel'
import Const from '../../helpers/Const'
import An from '../../helpers/Analytics'
import { withTranslation } from 'react-i18next'
import { useState } from 'react'
import { translateCategoricalValue } from '../filters/CTranslateUtils'


const getSortables = (searchFieldsInfo, formStateMap, client) => {
  return Object.entries(searchFieldsInfo)
    .filter(([name, field]) => field.sortable)
    .filter(([name, field]) => !field.userAccessField || field.userAccessField.split(',').some((it) => client[it]))
    .map(([name, field]) => {
      const state = useState(formStateMap[name]);
      return ({
        state: state[0],
        setValue: state[1],
        name: field.name,
        priority: searchFieldsInfo[field.name].priority,
        isSortedByDefault: searchFieldsInfo[field.name].isSortedByDefault,
        isGroupedByDefault: searchFieldsInfo[field.name].isGroupedByDefault,
        defaultSorting: searchFieldsInfo[field.name].defaultSorting || undefined,
        groupable: searchFieldsInfo[field.name].groupable,
        customSorting: searchFieldsInfo[field.name].customSorting,
        defaultOrderDirection: searchFieldsInfo[field.name].defaultOrderDirection,
      })
    })
    .sort((a, b) => (typeof (a.state?.sortingNumber) == "number" ? a.state.sortingNumber : -1) - (typeof (b.state?.sortingNumber) == "number" ? b.state.sortingNumber : -1));
  // .sort((a, b) => (typeof (a.state.sortingNumber) == "number" ? a.state.sortingNumber : a.isSortedByDefault) - (typeof (b.state.sortingNumber) == "number" ? b.state.sortingNumber : b.isSortedByDefault));
}

const getDefaultField = (field, searchFieldsInfo) => {
  return ({
    ...field,
    isHeading: typeof (field.isHeading) == "boolean" ? field.isHeading : searchFieldsInfo[field.name].isGroupedByDefault,
    orderType: field.orderType || searchFieldsInfo[field.name].defaultSorting || undefined,
    sortingNumber: typeof (field.sortingNumber) == "number" ? field.sortingNumber : searchFieldsInfo[field.name].isSortedByDefault,
    orderDirection: field.orderType || searchFieldsInfo[field.name].defaultOrderDirection || "ASC",
  })
}

export default withTranslation()(Connect(
  ({ form, client, searchPropertyFieldsInfo, searchCompanyFieldsInfo, t, search, onCancel, updateSorting, clearSorting }) => {
    const searchFieldsInfo = form.searchType == Const.SEARCH_TYPE_PROPERTIES ? searchPropertyFieldsInfo : searchCompanyFieldsInfo

    const fieldsList = getSortables(searchFieldsInfo, form, client)

    const sortingPositions = [...Array(form.searchType == Const.SEARCH_TYPE_PROPERTIES ? 9 : 5)]
      .map((v, i) => fieldsList.find((x) => (x.state?.sortingNumber == (i + 1))))

    const fields = fieldsList
      .sort((a, b) => a.priority - b.priority)
      .map((field) => {
        if (field.name == 'branch') {
          const customPropertySet = searchFieldsInfo.branch?.label ? translateCategoricalValue(t, "customPropertySet", searchFieldsInfo.branch?.label) : t('data.fast.filter.label.branch', { ns: "data" })
          return ({ value: field.name, label: customPropertySet })
        } else {
          return ({ value: field.name, label: <CLabel k={`data.sorting.label.${field.name}`} param={{ ns: "data" }}  /> })
        }
      })


    const toggleSortingDirection = (field) => {
      field.setValue({ ...field.state, orderDirection: field.state.orderDirection != 'DESC' ? "DESC" : "ASC" })
    }

    const setSort = (field, newField, position) => {
      if (field) { 
        const state = fieldsList.find((x) => x.name == field)
        state?.setValue({ ...state.state, sortingNumber: -1 })
      }

      if (newField) { 
        const state = fieldsList.find((x) => x.name == newField)
        state?.setValue({ ...(state.state || getDefaultField({ name: newField }, searchFieldsInfo)), sortingNumber: position + 1 })
      }
    }

    const toggleHeading = (field, checked) => {
      field.setValue({ ...field.state, isHeading: checked })
    }

    const setSortType = (field, sortType) => {
      field.setValue({ ...field.state, orderType: sortType })
    }

    const onResetSorting = () => {
      fieldsList.filter(x => x?.name).forEach((field) => {
        field.setValue({
          ...field.state,
          ...getDefaultField({ name: field.name }, searchFieldsInfo),
        })
      })
    }

    const onClearSorting = () => {
      sortingPositions.filter(x => x?.name && x?.state).forEach((field) => {
        field.setValue({
          ...field.state,
          ...getDefaultField({ name: field.name }, searchFieldsInfo),
          sortingNumber: -1
        })
      })
    }

    const onSave = () => {
      clearSorting() //Out of sync, clear may happen in the middle of new data set
      const form = sortingPositions.filter(x => x?.name && x?.state).forEach((x) => updateSorting(x.name, x.state))
      search(form)
    }

    let fieldCount = 0
    return (
      <div className="modal open">
        <div className="modal-header-mobile show-mobile">
          <a className="btn btn-back close-modal" onClick={onCancel}><span className="upper"><CLabel k="close" /></span></a>
          <div className="title-page"><CLabel k="custom.sorting" /></div>
        </div>
        <CMobileDropdownMenu options={[
          { label: t("custom.sorting.and.headings.clear.all").toUpperCase(), icon: "fa-refresh", onClick: onClearSorting },
          { label: t("custom.sorting.and.headings.default.settings").toUpperCase(), icon: "fa-cog", onClick: onResetSorting }
        ]} />
        <div className="modal-dialog modal-md" style={{ maxWidth: '630px' }}>
          <div className="modal-content">
            <div className="modal-header hide-mobile">
              <div className="modal-title sorting-modal-title">
                <div className="name-block middle"><span className="bold upper"><CLabel k="custom.sorting.and.headings.title" /></span></div>
                <div className="box-btn">
                  <a className="btn" onClick={onClearSorting}>
                    <CLabel k="custom.sorting.and.headings.clear.all" />
                  </a>
                  <a className="btn ml-2" onClick={onResetSorting}>
                    <CLabel k="custom.sorting.and.headings.default.settings" />
                  </a>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <form className="form form-small">
                <table className="table table-sorting">
                  <thead>
                    <tr>
                      <th>
                        <div className="hide-mobile"><CLabel k="custom.sorting.and.headings.priority.label" /></div>
                        <div className="show-mobile"><CLabel k="custom.sorting.and.headings.priority.mobile" /></div>
                      </th>
                      <th><CLabel k="custom.sorting.and.headings.sort.fields" /></th>
                      <th><CLabel className="hide-mobile" k="custom.sorting.and.headings.ascending.descending.label" /><CLabel className="show-mobile" k="custom.sorting.and.headings.ascending.descending.mobile" /></th>
                      <th className='text-center' style={{ width: '25%' }}><CLabel k="custom.sorting.and.headings.show.headings" /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      sortingPositions.map((field, i) => {
                        if (field?.state?.sortingNumber > -1) fieldCount++
                        return (<tr key={i}>
                          <td className="cell-number text-center"> {field ? fieldCount : ""} </td>
                          <td className="cell-control">
                            <div className="box-control">
                              <div className="box-control-dropdown">
                                <CFilterDropdown onChange={(f, newField) => setSort(field?.name, newField, i)} value={field?.name} options={fields} />
                              </div>
                            </div>
                            {(field?.customSorting?.length > 1) &&
                              <div className="box-control">
                                <CFilterDropdown onChange={(f, value) => setSortType(field, value)} nullable={false} value={field?.state?.orderType} name={field?.name} options={field?.customSorting.map((cs) => ({ value: cs, label: <CLabel k={`custom.sorting.type.${cs}`} /> }))} />
                              </div>
                            }
                          </td>
                          <td className="cell-icon">
                            {field?.state &&
                              <a onClick={() => toggleSortingDirection(field)} className="btn link-grey">
                                <i className={"icon icon-middle icon-" + ((field?.state?.orderDirection == 'DESC') ? 'desc' : 'asc')} />
                              </a>
                            }
                          </td>
                          <td className="cell-checkbox text-center">
                            {field?.groupable && (
                              <div className="box-checkbox middle">
                                <input checked={field.state.isHeading || false} onChange={(value) => toggleHeading(field, value.target.checked)} type="checkbox" id={"heading_cb_" + i} /><label htmlFor={"heading_cb_" + i}>&nbsp;</label>
                              </div>)
                            }
                          </td>
                        </tr>)
                      })
                    }
                  </tbody>
                </table>
              </form>
            </div>
            <div className="modal-footer dark hide-mobile">
              <div className="clearfix">
                <div className="box-btn box-right">
                  <a className="btn btn-grey btn-normal close-modal" onClick={onCancel}><span className="upper"><CLabel k="cancel" /></span></a>
                  <a className="btn btn-red btn-normal close-modal" onClick={onSave}><span className="upper"><CLabel k="custom.sorting.and.headings.show.results" /></span></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer-mobile show-mobile">
          <a className="btn btn-red btn-normal close-modal" onClick={onSave}><span className="upper"><CLabel k="custom.sorting.and.headings.apply" /></span></a>
        </div>
      </div>
    )
  },
  {
    dispatch: (dispatch) => ({
      onCancel: () => dispatch({ type: 'TOGGLE_POPUP_SORT' }),
      clearSorting: () => dispatch({ type: 'CLEAR_SORTING' }),
      // addSorting: (oldName, newName, number) => dispatch({ type: 'ADD_SORTING_TO_SEARCH_FORM', oldName: oldName, newName: newName, number: number }),
      updateSorting: (name, info) => dispatch({ type: 'UPDATE_SORTING_TO_SEARCH_FORM', name: name, info: info }),
      search: (form) => {
        An.navigate.sortResults()
        // dispatch({ type: 'SET_FILTER_FORM', form: form })
        dispatch({ type: 'NEW_SEARCH' })
        dispatch({ type: 'TOGGLE_POPUP_SORT' })
      },
    }),

    state: (state) => ({
      searchPropertyFieldsInfo: state.app.searchFieldsInfo,
      searchCompanyFieldsInfo: state.app.searchCompanyFieldsInfo,
      form: state.searchForm.form,
      client: state.session?.user?.client
    })
  }
))