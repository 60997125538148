{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
Const = require '../../helpers/Const'
{ CLabel } = require '../../cmp/CLabel'
{ API } = require '../../redux/Store'
Utils = require '../../helpers/Utils'
CWatchPopupNew = require '../../cmp/popups/CWatchPopupNew'
{ withTranslation } = require 'react-i18next'
{ translateCategoricalValue } = require '../filters/CTranslateUtils'
{ extend } = require '../../helpers/UtilsHelpers'

MAX_MARKERS_FOR_CLUSTERING = 1000000
MAX_MARKERS_WITH_BIG_ICONS = 25
MAX_RESULT_FOR_MARKER_LABEL = 250
MARKER_LABEL_TRUNCATE_AT = 18
DEFAULT_MARKER_COLOR = 'bla'

EARTH_RADIUS_M = 6371000

Img1 = require '../../../assets/img/map/1.png'
Img2 = require '../../../assets/img/map/2.png'
Img3 = require '../../../assets/img/map/3.png'
Img4 = require '../../../assets/img/map/4.png'
Img5 = require '../../../assets/img/map/5.png'
IMAGE_PATH = '../../../img/map/'
CROSS_HAIR_ICON = require '../../../assets/img/icons/cross-hairs.gif'


colors = ['bl', 'br', 'dbl', 'gr', 'gre', 'or', 'pr', 're', 'tu', 'ye']
colorsForProjectStage = ['ye', 'bl', 'br', 'dbl', 'gr', 'gre', 'or', 'pr', 're', 'tu']
colorPinsBy = [
  { label: 'Chain Scale', value: 'chainScale' }
  { label: 'Project Type', value: 'projectType' }
  { label: 'Project Stage', value: 'projectStage' }
  { label: 'Room Count', value: 'rooms' }
  { label: 'Franchise Company', value: 'franchiseCompany' }
]

cachedLabels = {}
cachedCompanyRoles = []
franchiseCompanies = ['Marriott', 'Hilton', 'IHG Hotels & Resorts', 'Hyatt', 'Radisson Hotel Group', 'Best Western', 'Choice', 'Wyndham', 'Other']
projectTypes = ['New Construction', 'Conversion', 'Renovation', 'Open & Operating']

isNeededRecenter = true

module.exports = withTranslation() Connect createReactClass(
  displayName: "CResultMap"

  #shouldComponentUpdate: (nextProps, nextState)->
  #  @state.markers.length != nextState.markers.length

  getInitialState: () ->
    showDropdown: false
    mapResult: {}
    form: @props.form
    searchType: null
    propertyPopupOpen: false
    propertyWatch: null
    watching: false,
    markers: {}
    companyRoles: @getCompanyRoles()
    allowMarkerLabels: @props.mapResult.results?.length <= MAX_RESULT_FOR_MARKER_LABEL
    shapes: [
      { label: @props.t('data.mapLegend.censusProperty', { ns: "data" }), icon: 'census_properties' }
      { label: @props.t('data.mapLegend.unbrandedCensus', { ns: "data" }), icon: 'unbranded' }
      { label: @props.t('data.mapLegend.pipelineProperty', { ns: "data" }), icon: 'pipeline_properties' }
      { label: @props.t('data.mapLegend.unbrandedPipeline', { ns: "data" }), icon: 'unbranded2' }
    ].concat(if @props.client.showSales then { label: @props.t('data.mapLegend.sale', { ns: "data" }), icon: 'sale' } else [])
    .concat(if @props.client.showDistressedAssets then { label: @props.t('data.mapLegend.distressedAsset', { ns: "data" }), icon: 'distressed_asset' } else [])
    options:
      propertiesLegend:
        labelsWithColors: []
        activeColorPin: if @props.form.hasOwnProperty('activeColorPin') then (colorPinsBy.filter (it) => it.value == @props.form.activeColorPin)[0] else colorPinsBy[0]
        useClustering: if @props.form.hasOwnProperty('propertyMapUseClustering') then @props.form.propertyMapUseClustering else true
        showLabels: if @props.form.hasOwnProperty('propertyMapShowLabels') then @props.form.propertyMapShowLabels else false
      companiesLegend:
        useClustering: if @props.form.hasOwnProperty('companyMapUseClustering') then @props.form.companyMapUseClustering else true
        showLabels: if @props.form.hasOwnProperty('companyMapShowLabels') then @props.form.companyMapShowLabels else false


  componentWillMount: () ->
    @props.search({}, {})

  onSlideClick: () ->
    if (document.querySelector('.box-slide-form').classList.contains('open'))
      document.querySelector('.box-slide-form').classList.remove('open')
      document.querySelector('.box-slide-form').querySelector('.slide-form').classList.remove('open')
      document.querySelector('.box-slide-form').querySelector('.slide-form').classList.remove('overflow')
    else
      document.querySelector('.box-slide-form').classList.add('open')
      document.querySelector('.box-slide-form').querySelector('.slide-form').classList.add('open')
      setTimeout(() ->
        document.querySelector('.box-slide-form').querySelector('.slide-form').classList.add('overflow')
      , 1000)

  componentDidMount: () ->
    #Banner shown while map data is being fixed, disabled
    # @props.setShowWarningMessage("There is a mapping upgrade underway and it will cause unexpected map results. We regret the inconvenience.")

    if !document.querySelector('.search-filter').classList.contains('fixed') && Utils.isMobile()
      document.querySelector('.search-results')?.style.paddingTop = document.querySelector('.search-filter').offsetHeight+'px'
      document.querySelector('.search-filter')?.classList.add('fixed')

    document.querySelector('.btn-slide-form.show').addEventListener 'click', @onSlideClick
    document.querySelector('.btn-slide-form.hide').addEventListener 'click', @onSlideClick



    if !@props.form.searchType
      @props.setSearchType(Const.SEARCH_TYPE_PROPERTIES)

    if Utils.isMobile()
      document.querySelector('.main-content').classList.add('visible')

  componentWillUnmount: () ->
    document.querySelector('.search-filter').classList.remove('fixed')
    document.querySelector('.search-results')?.removeAttribute('style')
    @props.setShowWarningMessage(false)
    if Utils.isMobile()
      document.querySelector('.main-content').classList.remove('visible')

  closePopup: ()->
    @setState({ propertyPopupOpen: false, propertyWatch: null })

  toggleWatch: ()->
    @setState({ watching: !@state.watching })
    if (@state.watching)
      @props.deleteWatch(@state.propertyWatch.watch?.propertyId)
      return
    @props.updateWatch(@state.propertyWatch.watch?.propertyId)

  initMap: () ->
    if !window.google
      console.log "Google Maps API not loaded"
      return

    lat = @props.form?.companyProximityFilter?.value?.location?.latitude || @props.form?.proximityFilter?.value?.location?.latitude || 40.806862
    lng = @props.form?.companyProximityFilter?.value?.location?.longitude || @props.form?.proximityFilter?.value?.location?.longitude || -96.681679
    @map = new google.maps.Map @refs.map,
      zoom: if @props.mapResult?.count > 0 then 4 else 2,
      minZoom: 2
      zoomControl: true
      mapTypeControl: false
      zoomControlOptions: position: google.maps.ControlPosition.LEFT_BOTTOM
      streetViewControl: true
      streetViewControlOptions: position: google.maps.ControlPosition.LEFT_BOTTOM
      fullscreenControl: true
      fullscreenControlOptions: position: google.maps.ControlPosition.LEFT_BOTTOM
      center:
        lat: lat
        lng: lng

    legend = document.getElementById('legend')
    legend.style.display = 'block'
    @map.controls[google.maps.ControlPosition.RIGHT_TOP].push(legend)
    onMapDragEvent = () =>
      proj = @map.getProjection()
      bounds = @map.getBounds()
      sLat = @map.getBounds().getSouthWest().lat()
      nLat = @map.getBounds().getNorthEast().lat()
      if sLat < -88 || nLat > 88
        @map.setOptions(
          center: new google.maps.LatLng(lat, lng)
        )

    @map.addListener 'idle', () ->
      onMapDragEvent()

    @map.addListener 'dragend', () ->
      onMapDragEvent()

    @map.addListener 'bounds_changed', () =>
      clearTimeout(@boundChangeTimeoutId)
      @boundChangeTimeoutId = setTimeout () =>
        bound = @map?.getBounds()
        markersCount = 0
        markersNeedUpdate = []
        @state.markers.forEach (marker, idx) ->
          if bound?.contains(marker.getPosition())
            markersCount++
            markersNeedUpdate.push(marker)
        withSmallMarkers = MAX_MARKERS_WITH_BIG_ICONS < markersCount
        markersNeedUpdate.forEach (marker, idx) =>
          
          item = @state.mapResult.results[@markers.indexOf(marker)]
          if !item
            return
          labeledIcon = @buildLabeledIcon(item, @props.form.searchType == Const.SEARCH_TYPE_PROPERTIES, withSmallMarkers)
          marker.setIcon(labeledIcon.icon)

          legend = if @props.form.searchType == Const.SEARCH_TYPE_PROPERTIES then 'propertiesLegend' else 'companiesLegend'
          if @state.options?[legend]?.showLabels
            marker.setLabel(labeledIcon.label)
      , 200

  drawCircle2: (point, radius, dir) ->
    d = radius/EARTH_RADIUS_M; # radians
    lat1 = (Math.PI/180) * point.lat(); # radians
    lng1 = (Math.PI/180) * point.lng(); # radians
    points = 360
    extp = new Array()
    if dir==1
      start=0
      end=points
    else
      start=points
      end=0
    for a in [start...end] by dir
      tc = (Math.PI/180) * a;
      y = Math.asin(Math.sin(lat1) * Math.cos(d) + Math.cos(lat1) * Math.sin(d) * Math.cos(tc));
      dlng = Math.atan2(Math.sin(tc) * Math.sin(d) * Math.cos(lat1), Math.cos(d) - Math.sin(lat1) * Math.sin(y));
      x = ((lng1 - dlng + Math.PI) % (2 * Math.PI)) - Math.PI ; # MOD function
      extp.push(new google.maps.LatLng(y * (180/Math.PI), x * (180/Math.PI)))
    # extp.push(extp[0])
    return extp

  getRadiusPoint: (point, radius, a) ->
    d = radius / EARTH_RADIUS_M; # radians
    lat1 = (Math.PI/180) * point.lat(); # radians
    lng1 = (Math.PI/180) * point.lng(); # radians
    tc = (Math.PI/180) * a;
    y = Math.asin(Math.sin(lat1) * Math.cos(d) + Math.cos(lat1) * Math.sin(d) * Math.cos(tc));
    dlng = Math.atan2(Math.sin(tc) * Math.sin(d) * Math.cos(lat1), Math.cos(d) - Math.sin(lat1) * Math.sin(y));
    x = ((lng1 - dlng + Math.PI) % (2 * Math.PI)) - Math.PI ; # MOD function
    new google.maps.LatLng(y * (180/Math.PI), x * (180/Math.PI))

    
  updateMap: (newProps, legend, fitBounds = false) ->
    if !window.google
      return
    @refs.map.style.display = 'block'

    if !@map
      @initMap()
    if isNeededRecenter
      fitBounds = true
    else
      fitBounds = false
    if @state.markers && @state.markers.length > 0
      @state.markers?.map (marker) ->
        marker.setMap(null)
        marker = null
    @markers = []
    @markerCluster?.clearMarkers(null)
    @markerCluster = null

    withSmallMarkers = MAX_MARKERS_WITH_BIG_ICONS < newProps.mapResult.results?.length
    @markers = []

    # Proximity shape part
    if @proximityShape
      @proximityShape.setMap(null)
      @proximityShape = null
    if @centerMarker
      @centerMarker.setMap(null)
      @centerMarker = null

    if (newProps.mapResult.filterForm.searchType == Const.SEARCH_TYPE_PROPERTIES && legend != "propertiesLegend")
      return

    if (newProps.mapResult.filterForm.searchType == Const.SEARCH_TYPE_COMPANIES && legend != "companiesLegend")
      return

    proximityFilter = if newProps.form.searchType == Const.SEARCH_TYPE_COMPANIES then newProps.form?.companyProximityFilter?.value else newProps.form?.proximityFilter?.value
    if proximityFilter
      lat = proximityFilter.location?.latitude || 40.806862
      lng = proximityFilter.location?.longitude || -96.681679

      if isNeededRecenter
        @map.setOptions(
          center: new google.maps.LatLng(lat, lng)
        )
      else
        isNeededRecenter = true

      radius = proximityFilter.radius || 0
      isKm  = proximityFilter.unit == 'Km'
      radiusMultiplier = if isKm then 1000 else 1609.344
      radius = radius * radiusMultiplier

      @proximityShape = @getProximityShape(lat, lng, radius)
      @centerMarker = @getProximityCenter(lat, lng, radius, proximityFilter.address)

      @proximityShape.setMap(@map)
      @centerMarker.setMap(@map)

    openPropertyPopup = (markerId) =>
      API.getMapProperty markerId, (propertyWatch) =>
        if (Utils.isMobile())
          @props.router.push('/property/' + propertyWatch.watch?.propertyId)
          return
        @setState({ propertyPopupOpen: true, propertyWatch: propertyWatch, watching: propertyWatch?.property?.isWatching })
    
    openCompanyCardPopup = (markerId) =>
      if (Utils.isMobile())
        @props.router.push('/company/' + markerId)
        return
      @props.toggleCompanyCardPopup(markerId)

    createGMapMarker = (marker) =>
      if marker.latitude && marker.longitude
        labeledIcon = @buildLabeledIcon(marker, newProps.form.searchType == Const.SEARCH_TYPE_PROPERTIES, withSmallMarkers)

        label = null
        if @state.options?[legend]?.showLabels
          label = labeledIcon.label

        m = new google.maps.Marker
          icon: labeledIcon.icon
          animation: if newProps.mapResult.results.length < MAX_MARKERS_FOR_CLUSTERING && @state.options?[legend]?.useClustering then google.maps.Animation.lo
          label: label
          position:
            lat: marker.latitude
            lng: marker.longitude
        m.addListener 'click', () =>
          isInFullScreen = (document.fullscreenElement && document.fullscreenElement != null) ||
            (document.webkitFullscreenElement && document.webkitFullscreenElement != null) ||
            (document.mozFullScreenElement && document.mozFullScreenElement != null) ||
            (document.msFullscreenElement && document.msFullscreenElement != null)

          if document.exitFullscreen
            document.exitFullscreen()
          else if document.webkitExitFullscreen
            document.webkitExitFullscreen()
          else if document.mozCancelFullScreen
            document.mozCancelFullScreen()
          else if document.msExitFullscreen
            document.msExitFullscreen()

          if newProps.form.searchType == Const.SEARCH_TYPE_COMPANIES
            openCompanyCardPopup(marker.id)
          else if newProps.form.searchType == Const.SEARCH_TYPE_PROPERTIES
            openPropertyPopup(marker.id)

        infowindow = new google.maps.InfoWindow(content: '<span>' + marker.name + '</span>', disableAutoPan: false)
        m.addListener 'mouseover', () =>
          infowindow.open(@map, m)
        m.addListener 'mouseout', () ->
          infowindow.close()
        @markers.push(m)
    results = newProps.mapResult.results

    yieldingLoop = (count, chunksize, finished) ->
      i = 0
      chunk = () ->
        end = Math.min(i + chunksize, count)
        while i < end
          createGMapMarker(results[i])
          ++i
        if i < count
          chunk()
        else
          finished.call null
      chunk()

    yieldingLoop(results.length, 1000, () =>
      if newProps.mapResult?.options?[legend]?.useClustering || @state.options?[legend]?.useClustering
        if MarkerClusterer
          @markerCluster = new MarkerClusterer(@map, @markers, imagePath: IMAGE_PATH, minimumClusterSize: 5)
      else
        @markers.forEach (marker, id) =>
          marker.setMap(@map)

      if newProps.mapResult?.count > 0
        if fitBounds
          @bounds = new google.maps.LatLngBounds()
          @markers.forEach (marker, idx) =>
            @bounds.extend(marker.getPosition())
          @map.fitBounds(@bounds)
        @setState markers: @markers
    )
    google.maps.event.trigger(@map, 'bounds_changed')

  getProximityShape: (lat, lng, radius, map) ->
    shapeRotationDeg = 89
    shapeRotationFillDeg = 360 - shapeRotationDeg * 4
    proximityShape = new google.maps.Polygon({
      paths: [
        [
          {lat: 90, lng: lng + shapeRotationDeg}, 
          {lat: 90, lng: lng - shapeRotationDeg}, 
          {lat: -90, lng: lng - shapeRotationDeg}, 
          {lat: -90, lng: lng + shapeRotationDeg}, 
        ]
        @drawCircle2(new google.maps.LatLng(lat, lng), radius, -1)
        [
          {lat: 90, lng: lng + shapeRotationDeg}, 
          {lat: 90, lng: lng + shapeRotationDeg * 3}, 
          {lat: -90, lng: lng + shapeRotationDeg * 3}, 
          {lat: -90, lng: lng + shapeRotationDeg}, 
        ]
        [
          {lat: 90, lng: lng + shapeRotationDeg * 3}, 
          {lat: 90, lng: lng + shapeRotationDeg * 3 + shapeRotationFillDeg}, 
          {lat: -90, lng: lng + shapeRotationDeg * 3 + shapeRotationFillDeg}, 
          {lat: -90, lng: lng + shapeRotationDeg * 3}, 
        ]
      ],
      strokeColor: '#999',
      strokeOpacity: 0,
      strokeWeight: 0,
      fillColor: '#999',
      fillOpacity: 0.5,
      # draggable: true,
      # editable: true,
      geodesic: true
    })
    return proximityShape

  getProximityCenter: (lat, lng, radius, address, map) ->
    point = new google.maps.LatLng(lat, lng)
    centerVertical = @getDiameterLine(point, radius, 0)
    centerHorizontal = @getDiameterLine(point, radius, 90)

    centerMarker = new google.maps.Marker({
      position: point,
      map: @map,
      icon: 
        url: CROSS_HAIR_ICON
        anchor: new google.maps.Point(9, 9)
        scaledSize: new google.maps.Size(18, 18)
    })

    infowindow = new google.maps.InfoWindow(content: '<span>' + (address || 'Location center') + '</span>', disableAutoPan: false)
    centerMarker.addListener 'mouseover', () =>
      infowindow.open(map, centerMarker)
    centerMarker.addListener 'mouseout', () =>
      infowindow.close()
    centerMarker.setMap(map)
    return {
      setMap: (map) ->
        centerHorizontal.setMap(map)
        centerVertical.setMap(map)
        centerMarker.setMap(map)
    }

  getDiameterLine: (point, radius, rotation) ->
    path = [
      @getRadiusPoint(point, radius, rotation),
      @getRadiusPoint(point, radius, rotation + 180),
    ]
    line = new google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: '#999',
      strokeOpacity: 0.5,
      strokeWeight: 1
    })
    line
    
  truncateToWord: (text, length) ->
    if text && text.indexOf(' ', length) > 0
      text.substring(0, text.indexOf(' ', length))
    else
      text

  initPropertiesLegend: (legend) ->
    options = @state.options
    options.propertiesLegend = legend
    colorPin = legend?.activeColorPin || colorPinsBy[0]

    if cachedLabels[colorPin.value]
      options.propertiesLegend.labelsWithColors = cachedLabels[colorPin.value]
    else
      if colorPin.value == 'franchiseCompany'
        colors[franchiseCompanies.length - 1] = 'ye'
        options.propertiesLegend.labelsWithColors = cachedLabels[colorPin.value] = franchiseCompanies.map (it, index) -> label: it, color: colors[index]
      else if colorPin.value == 'projectType'
        options.propertiesLegend.labelsWithColors = Utils.filterProjectTypes(projectTypes, @props).map (it, index) -> label: it, color: colors[index]
      else if colorPin.value == 'projectStage'
        API.dictionary colorPin.value, @props.form, (labels) =>
          labels = labels.map (l) -> l.label
          labels.unshift("No Project Stage")
          options.propertiesLegend.labelsWithColors = labels.map (it, index) -> label: it, color: colorsForProjectStage[index]
          @props.setMapOptions(@state.options)
          @setState options: options
      else
        API.dictionary colorPin.value, @props.form, (labels) =>
          if colorPin.value == 'rooms'
            labels = labels.map (it) -> it.label
          else if colorPin.value == 'chainScale'
            labels = labels?.sort((a, b) -> a.order > b.order).map (it) -> it.label

          options.propertiesLegend.labelsWithColors = cachedLabels[colorPin.value] = labels.map (it, index) -> label: it, color: colors[index]
          @props.setMapOptions(@state.options)
          @setState options: options

    @setState options: options


  getPropertyIcon: (marker, halfSize) ->
    isWithSpecialActivity = (marker) ->
      if marker.projectType
        specialProjectTypes = marker.projectType.split(';').filter (it) -> it == 'Sale' || it == 'Distressed Asset'
      isUnbranded = marker.franchiseCompany == 'Unbranded Hotel'

      if specialProjectTypes?.length == 2
        if isUnbranded then '000' else '111'
      else if specialProjectTypes?[0] == 'Sale'
        if isUnbranded then '00' else '11'
      else if specialProjectTypes?[0] == 'Distressed Asset'
        if isUnbranded then '01' else '10'
      else if isUnbranded
        '0'
      else '1'

    createIconPath = (label, labelArray, propertyType, specialActivity) ->
      chooseIconSize = (color) ->
        if halfSize
          require '../../../assets/img/map/properties/smallerPoint/05xpointer_' + propertyType + '_' + specialActivity + '_' + color + '.png'
        else
          require '../../../assets/img/map/properties/point/pointer_' + propertyType + '_' + specialActivity + '_' + color + '.png'

      getDefaultLabelColor = (labelArray) ->
        (labelArray.filter (it) -> it.label == 'Other' || it.label == 'No Project Activity' || it.label == 'No Project Stage' || it.label == 'Open & Operating')?[0]?.color || DEFAULT_MARKER_COLOR

      if label && labelArray.length > 0
        color = (labelArray.filter (it) -> it.label.replace(' ', '') == label.replace(' ', ''))?[0]?.color
        if !color
          color = (labelArray.filter (it) ->
            if it.label == 'Underway / Under Construction'
              it.label.indexOf(label) > -1
            else if it.label == 'Start Next 6 or 12 Months'
              label in ['Start Next 6 Months', 'Start Next 12 Months']
            else
              label.indexOf(it.label) > -1)?[0]?.color
          if !color
            color = getDefaultLabelColor(labelArray)
        chooseIconSize(color)
      else
        color = getDefaultLabelColor(labelArray)
        chooseIconSize(color)

    labels = @state.options?.propertiesLegend?.labelsWithColors

    if labels
      activeColorPin = @state.options?.propertiesLegend?.activeColorPin.value
      label = marker[activeColorPin]
      isWithSpecialActivity = isWithSpecialActivity(marker)
      propertyType = if marker.propertyType == "Census" then 'c' else 'r'
      createIconPath(label, labels, propertyType, isWithSpecialActivity)

  getCompanyIcon: (marker, halfSize) ->
    roles = marker.roles?.split(',')
    iconName = 'more_than_1_role'
    if roles?.length == 1

      otherRoles = @state.companyRoles.slice(0, 6).map (it) -> it.label
      if !otherRoles.includes(roles[0])
        iconName = 'others'
      else
        iconName = (@state.companyRoles.filter (it) -> it.label == roles[0])?[0]?.icon
    if halfSize
      require '../../../assets/img/map/companies/smallerPoint/05xpointer_' + iconName + '_p.png'
    else
      require '../../../assets/img/map/companies/point/pointer_' + iconName + '_p.png'

  buildLabeledIcon: (marker, isProperty, isHalfSize) ->
    iconSize = null
    if isProperty
      iconSize = if isHalfSize then new google.maps.Size(26, 27) else new google.maps.Size(48, 54)
    else
      iconSize = if isHalfSize then new google.maps.Size(21, 24) else new google.maps.Size(41, 47)

    text = @truncateToWord(marker.name, MARKER_LABEL_TRUNCATE_AT)
    fontSize = if isHalfSize then '11px' else '13px'
    fontWeight = 'bold'
    fontFamily = 'Helvetica,sans-serif'
    textSize = @getTextWidth(text, fontSize + ' ' + fontWeight + ' ' + fontFamily)
    textPositionX = textSize.width / 2 + iconSize.width
    textPositionY = iconSize.height / 2 - textSize.height / 4

    label = null
    if @state.allowMarkerLabels
      label = 
        text: text
        fontSize: fontSize
        fontFamily: fontFamily
        fontWeight: fontWeight
    result =
      icon:
        url: if isProperty then @getPropertyIcon(marker, isHalfSize) else @getCompanyIcon(marker, isHalfSize)
        # size: iconSize
        labelOrigin: new google.maps.Point(textPositionX, textPositionY)
      label: label
        

  # /**
  # * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  # * 
  # * @param {String} text The text to be rendered.
  # * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  # * 
  # * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  # */
  getTextWidth: (text, font) ->
    # re-use canvas object for better performance
    canvas = @getTextWidth.canvas || (@getTextWidth.canvas = document.createElement("canvas"));
    context = canvas.getContext("2d");
    context.font = font;
    metrics = context.measureText(text);
    size = 
      width: Math.ceil(metrics.width)
      height: 12;

  componentWillReceiveProps: (newProps) ->
    if newProps.form.version != @state.form.version || newProps.mapResult.options?.version != @state.mapResult.options?.version
      if newProps.form.searchType == Const.SEARCH_TYPE_COMPANIES
        @setState companyRoles: @getCompanyRoles()

      if newProps.mapResult.status
        @setState mapResult: newProps.mapResult
        return

      if newProps.form.activeColorPin && (newProps.form.activeColorPin != @state.options?.propertiesLegend?.activeColorPin?.value)
        @state.options?.propertiesLegend?.activeColorPin = colorPinsBy.find((it) => it.value == newProps.form.activeColorPin)

      labels = @state.options?.propertiesLegend?.labelsWithColors
      colorPin = @state.options?.propertiesLegend?.activeColorPin.value

      if not Utils.arraysEqual(Utils.filterProjectTypes(projectTypes, newProps), @state.filteredProjectTypes) && newProps.form.searchType == Const.SEARCH_TYPE_PROPERTIES
        @setState filteredProjectTypes: Utils.filterProjectTypes(projectTypes, newProps)
        @initPropertiesLegend(@state.options.propertiesLegend)

      else if not Utils.arraysEqual(newProps.form.projectStage?.value, @state.form.projectStage?.value) && newProps.form.searchType == Const.SEARCH_TYPE_PROPERTIES
        @setState form: newProps.form
        @initPropertiesLegend(@state.options.propertiesLegend)

      else if newProps.form.searchType != @state.searchType
        if newProps.form.searchType == Const.SEARCH_TYPE_PROPERTIES
          @initPropertiesLegend(@state.options.propertiesLegend)

        else if newProps.form.searchType == Const.SEARCH_TYPE_COMPANIES
          if cachedCompanyRoles.length == 0
            API.dictionary 'companyRole', newProps.form, (roles) ->
              cachedCompanyRoles = roles
        @setState searchType: newProps.form.searchType

      #if newProps.mapResult?.version != @state.mapResult?.version

      if newProps.mapResult.results
        legend = if newProps.form.searchType == Const.SEARCH_TYPE_PROPERTIES then 'propertiesLegend' else 'companiesLegend'
        @setState allowMarkerLabels: newProps.mapResult.results?.length <= MAX_RESULT_FOR_MARKER_LABEL

        if newProps.mapResult?.count > MAX_MARKERS_FOR_CLUSTERING && !(newProps.mapResult?.options?[legend]?.useClustering || @state.options?[legend]?.useClustering)
          options = @state.options
          options[legend].useClustering = true
          @props.setMapOptions(options)
          @setState mapResult: extend {}, newProps.mapResult
          return

        @setState mapResult: extend({}, newProps.mapResult)
        @updateMap(newProps, legend, true)

  onChange: (e) ->
    legend = if @props.form.searchType == Const.SEARCH_TYPE_PROPERTIES then @state.options.propertiesLegend else @state.options.companiesLegend
    target = e.target
    value = if target.type == 'checkbox' then target.checked else target.value
    legend[target.name] = value
    options = @state.options
    if @props.form.searchType == Const.SEARCH_TYPE_PROPERTIES
      options.propertiesLegend = legend
      @setState options: options
    else if @props.form.searchType == Const.SEARCH_TYPE_COMPANIES
      options.companiesLegend = legend
      @setState options: options
    isNeededRecenter = false
    @props.setMapOptions(@state.options)

  toggleDropdown: () ->
    if @state.showDropdown
      document.querySelector('.block-nav').classList.remove('hide-mobile-menu')
    else
      document.querySelector('.block-nav').classList.add('hide-mobile-menu')

    @setState showDropdown: !@state.showDropdown

  showBlock: (name) ->
    companyRoles = @state.companyRoles
    companyRole = (companyRoles.filter (it) -> it.value == name)[0]
    companyRole.isChecked = !@isChecked(name)
    @setState companyRoles: companyRoles
    if name == 'other'
      otherRoles = cachedCompanyRoles.filter (it) ->  !['isDeveloper', 'isManager', 'isContractor', 'isPurchaser', 'isArchitect', 'isDesigner'].includes(it.value)
      otherRoles.filter (it, idx) =>
        @props.toggleBlockOnSearchForm(it.value, true, companyRole.isChecked)
    else
      @props.toggleBlockOnSearchForm(name)
    @props.search({}, {})

  getCompanyRoles: () ->
    [
      { label: @props.t("data.company.roles.developerOwner", { ns: "data" }), icon: 'owners', value: 'isDeveloper', isChecked: @isChecked('isDeveloper') }
      { label: @props.t("data.company.roles.management", { ns: "data" }), icon: 'managers', value: 'isManager', isChecked: @isChecked('isManager') }
      { label: @props.t("data.company.roles.contractor", { ns: "data" }), icon: 'contractors', value: 'isContractor', isChecked: @isChecked('isContractor') }
      { label: @props.t("data.company.roles.purchaser", { ns: "data" }), icon: 'purchasers', value: 'isPurchaser', isChecked: @isChecked('isPurchaser') }
      { label: @props.t("data.company.roles.architect", { ns: "data" }), icon: 'architects', value: 'isArchitect', isChecked: @isChecked('isArchitect') }
      { label: @props.t("data.company.roles.designer", { ns: "data" }), icon: 'designers', value: 'isDesigner', isChecked: @isChecked('isDesigner') }
      { label: @props.t("data.company.roles.others", { ns: "data" }), icon: 'others', value: 'other', isChecked: @isChecked('other') }
      { label: @props.t("data.company.roles.moreThan1Role", { ns: "data" }), icon: 'more_than_1_role' }
    ].filter (it) => if @props.client.showActivityContacts then true else it.value in ['isDeveloper', 'isManager', 'other', undefined]

  isChecked: (role) ->
    if role == 'other'
      otherRoles = cachedCompanyRoles.filter (it) -> !['isDeveloper', 'isManager', 'isContractor', 'isPurchaser', 'isArchitect', 'isDesigner'].includes(it.value)
      otherRoles.length > 0 && otherRoles.every (it) => @props.form?[it.value]
    else
      @props.form?[role] || false

  selectColorPin: (colorPin) ->
    legend = @state.options?.propertiesLegend
    legend.activeColorPin = colorPin
    @initPropertiesLegend(legend)
    @props.setMapOptions(@state.options)

  render: () ->
    if @props.mapResult.status
      React.createElement("span", {"className": "fa fa-exclamation-circle error-message"}, React.createElement(CLabel, {"k": "error.loading.map"}))
    else
      React.createElement("div", {"className": "search-result"},
        React.createElement("div", {"className": "menu-dropdown show-mobile", "style": (display: if @state.showDropdown then 'block' else 'none'), "onClick": (@toggleDropdown)},
          (colorPinsBy.map (colorPin, index) =>
            React.createElement("a", {"onClick": (@selectColorPin.bind @, colorPin), "className": (if colorPin.label == @state.options?.propertiesLegend?.activeColorPin.label then 'active'), "key": (index)}, React.createElement("div", {"className": "form-control"}, (translateCategoricalValue(@props.t, 'mapLegend', colorPin.label))))
          )
        ),
        (if @state.propertyPopupOpen
          React.createElement(CWatchPopupNew, {"watch": (@state.propertyWatch), "watching": (@state.watching), "toggleWatch": (@toggleWatch), "close": (@closePopup)})
        ),
        React.createElement("div", {"className": "block-map"},
          (if @props.loading && !@map
            React.createElement("div", {"ref": "loader", "className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
          ),
          React.createElement("div", {"ref": "map", "className": "map", "style": (display: if @props.loading && !@map then "none")}
          ),
          React.createElement("div", {"id": "legend", "style": (display: "none", maxWidth: '200px')},
            React.createElement("div", {"className": "box-nav-legend"},
              React.createElement("div", {"className": "box-slide-form open"},
                React.createElement("div", {"className": "box-btn"},
                  React.createElement("a", {"className": "btn btn-slide-form hide"}, React.createElement("i", {"className": "icon icon-min-legend"})),
                  React.createElement("a", {"className": "btn btn-slide-form show"}, React.createElement("i", {"className": "icon icon-max-legend"}))
                ),
                React.createElement("div", {"className": "slide-form open"},
                  (if @props.form.searchType == Const.SEARCH_TYPE_PROPERTIES
                    React.createElement("div", {"className": "box-legend"},
                      React.createElement("form", {"className": "form form-small"},
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("div", {"className": "label-control", "style": (color: 'grey', position: 'relative', top: '-5px')},
                            React.createElement(CLabel, {"k": "search.map.color.pins.by"})
                          ),
                          React.createElement("div", {"className": "box-control-dropdown", "onClick": (@toggleDropdown)},
                            React.createElement("div", {"className": "box-dropdown"},
                              React.createElement("a", {"className": "href-dropdown"},
                                React.createElement("div", {"className": "arrow-dropdown"}, React.createElement("div", {"className": "box-icon"}, React.createElement("i", {"className": "fa fa-angle-down"}))),
                                React.createElement("div", {"className": "change-value"}, React.createElement("div", {"className": "form-control"}, (translateCategoricalValue(@props.t, 'mapLegend', @state.options?.propertiesLegend?.activeColorPin.label))))
                              ),
                              React.createElement("div", {"className": "menu-dropdown hide-mobile", "style": (display: if @state.showDropdown then 'block' else 'none')},
                                (colorPinsBy.map (colorPin, index) =>
                                  React.createElement("a", {"onClick": (@selectColorPin.bind @, colorPin), "className": (if colorPin.label == @state.options?.propertiesLegend?.activeColorPin.label then 'active'), "key": (index)},
                                    React.createElement("div", {"className": "form-control"},
                                      (translateCategoricalValue(@props.t, 'mapLegend', colorPin.label))
                                    )
                                  )
                                )
                              )
                            )
                          )
                        ),
                        (if @props.loading && @props.mapResult.results?.length > MAX_MARKERS_FOR_CLUSTERING
                          React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
                        else
                          React.createElement("div", {"className": "items-legend"},
                            (@state.options?.propertiesLegend?.labelsWithColors.map (it, id) =>
                              React.createElement("div", {"className": "item-legend", "key": (id)},
                                React.createElement("a", {"className": "btn"},
                                  React.createElement("div", {"className": "box-img"},
                                    React.createElement("img", {"width": "18", "src": (require "../../../assets/img/map/properties/legend/points/#{it.color}.svg"), "alt": ("#{it.label}")})
                                  ),
                                  (translateCategoricalValue(@props.t, @state.options?.propertiesLegend?.activeColorPin.label, it.label))
                                )
                              )
                            ),
                          React.createElement("hr", null),
                          (@state.shapes.map (shape, id) ->
                            label = shape.label
                            icon = shape.icon
                            React.createElement("div", {"className": "item-legend", "key": (id)},
                              React.createElement("a", {"className": "btn"},
                                React.createElement("div", {"className": "box-img"}, React.createElement("img", {"src": (require "../../../assets/img/map/properties/legend/#{icon}.png"), "alt": ("#{label}")})),
                                (label)
                              )
                            )
                          )
                          )
                        ),
                        React.createElement("hr", null),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("div", {"className": "box-checkbox middle"},
                            React.createElement("input", {"type": "checkbox", "id": "propertiesLegendClustering", "onChange": (@onChange), "name": "useClustering", "checked": (@state.options?.propertiesLegend.useClustering)}),
                            React.createElement("label", {"className": (if @state.markers?.length > MAX_MARKERS_FOR_CLUSTERING then 'disabled'), "htmlFor": "propertiesLegendClustering"}, React.createElement(CLabel, {"k": "search.map.use.clustering"}))
                          )
                        ),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("div", {"className": "box-checkbox middle"},
                            React.createElement("input", {"type": "checkbox", "id": "propertiesLegendLabels", "onChange": (@onChange), "name": "showLabels", "disabled": (!@state.allowMarkerLabels), "checked": (@state.options?.propertiesLegend.showLabels && @state.allowMarkerLabels)}),
                            React.createElement("label", {"htmlFor": "propertiesLegendLabels", "className": (if !@state.allowMarkerLabels then 'disabled')}, React.createElement(CLabel, {"k": "search.map.show.labels"}))
                          )
                        )
                      )
                    )
                  else if @props.form.searchType == Const.SEARCH_TYPE_COMPANIES
                    React.createElement("div", {"className": "box-legend"},
                      React.createElement("form", {"className": "form form-small"},
                        (if @props.loading
                          React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
                        else
                          React.createElement("div", {"className": "items-legend"},
                            (@state.companyRoles.map (role, idx) =>
                              value = role.value
                              React.createElement("div", {"className": "item-legend", "key": (idx)},
                                (if value
                                  React.createElement("div", {"className": "box-control"},
                                    React.createElement("div", {"className": "box-checkbox middle"},
                                      React.createElement("input", {"type": "checkbox", "id": "value#{idx}", "onChange": (@showBlock.bind @, value), "checked": (role.isChecked || false)}),
                                      React.createElement("label", {"htmlFor": ("value#{idx}")}, React.createElement("div", {"className": "box-img"}, React.createElement("img", {"src": (require "../../../assets/img/map/companies/legend/#{role.icon}.png"), "alt": ""})), (role.label))
                                    )
                                  )
                                else
                                  React.createElement("a", {"className": "btn active"},
                                    React.createElement("div", {"className": "box-img"}, React.createElement("img", {"src": (require "../../../assets/img/map/companies/legend/#{role.icon}.png"), "alt": ""})),
                                    (role.label)
                                  )
                                )
                              )
                            )
                          )
                        ),
                        React.createElement("hr", null),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("div", {"className": "box-checkbox middle"},
                            React.createElement("input", {"type": "checkbox", "id": "companiesLegendClustering", "onChange": (@onChange), "name": "useClustering", "checked": (@state.options?.companiesLegend.useClustering)}),
                            React.createElement("label", {"className": (if @state.markers.length > MAX_MARKERS_FOR_CLUSTERING then 'disabled'), "htmlFor": "companiesLegendClustering"}, React.createElement(CLabel, {"k": "search.map.use.clustering"}))
                          )
                        ),
                        React.createElement("div", {"className": "box-control"},
                          React.createElement("div", {"className": "box-checkbox middle"},
                            React.createElement("input", {"type": "checkbox", "id": "companiesLegendLabels", "onChange": (@onChange), "name": "showLabels", "disabled": (!@state.allowMarkerLabels), "checked": (@state.options?.companiesLegend.showLabels && @state.allowMarkerLabels)}),
                            React.createElement("label", {"htmlFor": "companiesLegendLabels", "className": (if @state.allowMarkerLabels then 'disabled')}, React.createElement(CLabel, {"k": "search.map.show.labels"}))
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
), {
  state: (state) ->
    mapResult: state.searchForm?.mapResult
    form: state.searchForm?.form
    loading: state.loader?.loading
    client: state.session?.user?.client
    count: state.searchForm.info?.count
  dispatch: (dispatch) ->
    setShowWarningMessage: (message) -> dispatch(type: 'SET_WARNING_MESSAGE', showWarningMessage: message)
    setSearchType: (searchType) -> dispatch(type: 'SET_SEARCH_TYPE', searchType: searchType)
    search: () -> dispatch(type: 'SEARCH_MAP')
    toggleBlockOnSearchForm: (name, setExplicitly, value) -> dispatch(type: 'TOGGLE_BLOCK_ON_SEARCH_FORM', name: name, setExplicitly: setExplicitly, value: value)
    setMapOptions: (options) -> dispatch(type: 'SET_MAP_OPTIONS', options: options)
    toggleWatchPopup: (watch) -> dispatch(type: 'TOGGLE_POPUP_WATCH', data: watch)
    deleteWatch: (propertyId) => dispatch(type: 'DELETE_WATCH', propertyId: propertyId, stateNotSync: true)
    updateWatch: (propertyId) => dispatch(type: 'UPDATE_WATCH', propertyId: propertyId, stateNotSync: true)
    toggleCompanyCardPopup: (companyId) -> dispatch(type: 'TOGGLE_POPUP_COMPANY_CARD', data: companyId)
}
