{ getHistoryColor, getColorByChanges } = require '../../helpers/Utils'


module.exports = createReactClass
  displayName: "CResultAddress"

  shouldComponentUpdate: (nextProps, nextState)->
    nextProps.property != @props.property

  getFieldClassName: (object, field)->
    if field.updatedAtField
      return getColorByChanges(object, field.updatedAtField, 'bold')
    return getHistoryColor(object, field.n, 'bold')


  generateAddressInfo: (object, fieldsInfo)->
    fields = []
    fieldsInfo.filter (field, i)=>
      if object?[field.n]?.trim()
        fields.push(field)
    fields.map (field, i)=>
      React.createElement("span", {"key": (i), "className": (@getFieldClassName(object, field))},
        (if field.pre then field.pre else ""),
        (object[field.n]),
        (if field.post then field.post else "")
      )

  getContactName: (contact)->
    if contact.title?.trim()
      return contact.name
    return contact.phone

  generateCompanyAddressInfo: (company, changeKeys)->
    [
      if(company?.contact?.fullName?.trim().length)
        React.createElement("span", {"key": "company.contact.fullName", "className": (getColorByChanges(company, changeKeys.contactKey, 'bold'))},
          (company.contact.fullName),
          (if company.name then ", ")
        )
      if(company?.name?.trim().length)
        React.createElement("span", {"key": "company.name", "className": (getColorByChanges(company, changeKeys.idKey, 'bold'))},
          (company.name)
        )
    ]

  render: ()->
    <>
      {if @props.property
        @generateAddressInfo @props.property, @props.fieldsInfo || [
          {n:"address", post:React.createElement("br", null)}
          {n:"address1", post:React.createElement("br", null)}
          {n:"address2", post: ", "}
          {n:"city",  post: ", "}
          {n:"stateProvince", post: " "}
          {n:"postalCode", post: " "}
          {n:"country", pre:React.createElement("br", null)}
        ]
      else if @props.company
        @generateCompanyAddressInfo @props.company, @props.changeKeys || { contactKey: "contactIdNew", idKey: "idNew" }
      }
    </>

