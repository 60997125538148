import AnimateHeight from "react-animate-height"
import CNoteBlock from '../cmp/note/CNoteBlock'
import { useEffect, useState } from "react";

const duration = 500;

const CAnimatedNoteBlock = ({ isAnimating, column, animation, ...rest}) => {
    const [height, setHeight] = useState(animation.isExpanding && isAnimating ? 0 : 'auto');

    useEffect(() => {
        if (!isAnimating) {
            return;
        }
        if (animation.isExpanding) {
            setHeight('auto');
            return;
        }
        if (animation.isCollapsing) {
            setHeight(0);
            return;
        }
    }, [animation, isAnimating, setHeight]);

    const getOpacity = () => {
        if (isAnimating && !animation.visible) {
            return 0;
        }
        return 1;
    }

    const onAnimationEnd = () => {
        animation.onAnimationEnd(column);
    }

    return <AnimateHeight height={height} duration={duration} delay={animation.delay || 0} onHeightAnimationEnd={onAnimationEnd} className="tile-action">
        <CNoteBlock {...rest} opacity={getOpacity()} isAnimating={isAnimating} />
    </AnimateHeight>
}

export default CAnimatedNoteBlock;