Connect = require '../../helpers/Connect'
{ CLabel } = require '../CLabel'
CFilterElement = require '../filters/CFilterElement'
CProximityFilterElement = require '../filters/CProximityFilterElement'
CHasNotesFilterElement = require '../filters/CHasNotesFilterElement'

{ withTranslation } = require 'react-i18next' 

module.exports = withTranslation() Connect createReactClass(
  displayName: 'CSearchFormColumnCompany'

  putValue: (name, value) ->
    val = if typeof value == 'string' && value.length == 0 then undefined else value
    if name == "companyProximityFilter" && value?.address?.length == 0 && !value?.radius
      val = undefined
    @props.addValueToSearchForm(name, val)

  putValueFromDropDown: (name, values, options)->
    values = if values.length == 0 then undefined else (values.map (e)=> "'#{e}'").join(";")
    @props.addValueToSearchForm(name, values, options)

  showBlock: (name) ->
    @props.toggleBlockOnSearchForm(name)
    @props.setSearchFormColumns(companyCollapsed: false)

  setAllRoles: () ->
    roles = if @props.client?.showActivityContacts then ['isDeveloper', 'isManager', 'isContractor', 'isPurchaser', 'isArchitect', 'isDesigner'] else ['isDeveloper', 'isManager']
    if @props.client?.showDistressedAssets
      roles = roles.concat(['isLender', 'isServicer', 'isReceiver'])
    uncheck = roles.every (role)=> @props.form[role]

    roles.map (role) =>
      if !@props.form[role] || uncheck
        @showBlock(role)
        if uncheck
          @props.setSearchFormColumns(companyCollapsed: false)

  checkShowContact: () ->
    (@props.client?.showNewConstruction || @props.client?.showConversions || @props.client?.showRenovations) &&
    @props.client?.showActivityContacts &&
    (@props.form.showNewConstruction || @props.form.showConversions || @props.form.showRenovations)

  render: () ->
    React.createElement("div", {"className": "row-three"},
      React.createElement("div", {"className": "box-slide-form open"},
        React.createElement("div", {"className": "top-form"},
          (@props.children)
        ),
        React.createElement("div", {"className": "slide-form #{if @props.collapsed then '' else 'open'}"},
          React.createElement("div", {"className": "tile-group"},
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "box-form-group"},
                React.createElement("div", {"className": "box-title"},
                  React.createElement("div", {"className": "box-right"}, React.createElement("a", {"onClick": (@setAllRoles), "className": "btn btn-small"}, React.createElement(CLabel, {"k": "search.company.all.roles"}))),
                  React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "search.company.company.role"})))
                ),
                React.createElement("div", {"className": "box-control"},
                  React.createElement("div", {"className": "clearfix"},
                    React.createElement("div", {"className": "box-checkbox middle aligned row-half"},
                      React.createElement("input", {"type": "checkbox", "id": "check21", "checked": (@props.form.isDeveloper || false), "onChange": (@showBlock.bind @, "isDeveloper")}), React.createElement("label", {"htmlFor": "check21"}, React.createElement(CLabel, {"k": "search.company.isDeveloper"}))
                    ),
                    React.createElement("div", {"className": "box-checkbox middle aligned row-half"},
                      React.createElement("input", {"type": "checkbox", "id": "check22", "checked": (@props.form.isManager || false), "onChange": (@showBlock.bind @, "isManager")}), React.createElement("label", {"htmlFor": "check22"}, React.createElement(CLabel, {"k": "search.company.isManager"}))
                    ),
                    (if @props.client?.showActivityContacts
                      [
                        React.createElement("div", {"key": "isContractor", "className": "box-checkbox middle aligned row-half"},
                          React.createElement("input", {"type": "checkbox", "id": "check23", "checked": (@props.form.isContractor || false), "onChange": (@showBlock.bind @, "isContractor")}), React.createElement("label", {"htmlFor": "check23"}, React.createElement(CLabel, {"k": "search.company.isContractor"}))
                        )
                        React.createElement("div", {"key": "isPurchaser", "className": "box-checkbox middle aligned row-half"},
                          React.createElement("input", {"type": "checkbox", "id": "check24", "checked": (@props.form.isPurchaser || false), "onChange": (@showBlock.bind @, "isPurchaser")}), React.createElement("label", {"htmlFor": "check24"}, React.createElement(CLabel, {"k": "search.company.isPurchaser"}))
                        )
                        React.createElement("div", {"key": "isArchitect", "className": "box-checkbox middle aligned row-half"},
                          React.createElement("input", {"type": "checkbox", "id": "check25", "checked": (@props.form.isArchitect || false), "onChange": (@showBlock.bind @, "isArchitect")}), React.createElement("label", {"htmlFor": "check25"}, React.createElement(CLabel, {"k": "search.company.isArchitect"}))
                        )
                        React.createElement("div", {"key": "isDesigner", "className": "box-checkbox middle aligned row-half"},
                          React.createElement("input", {"type": "checkbox", "id": "check26", "checked": (@props.form.isDesigner || false), "onChange": (@showBlock.bind @, "isDesigner")}), React.createElement("label", {"htmlFor": "check26"}, React.createElement(CLabel, {"k": "search.company.isDesigner"}))
                        )
                      ]
                    ),
                    (if @props.client?.showDistressedAssets
                      [
                        React.createElement("div", {"key": "isLender", "className": "box-checkbox middle aligned row-half"},
                          React.createElement("input", {"type": "checkbox", "id": "check27", "checked": (@props.form.isLender || false), "onChange": (@showBlock.bind @, "isLender")}), React.createElement("label", {"htmlFor": "check27"}, React.createElement(CLabel, {"k": "search.company.isLender"}))
                        )
                        React.createElement("div", {"key": "isServicer", "className": "box-checkbox middle aligned row-half"},
                          React.createElement("input", {"type": "checkbox", "id": "check28", "checked": (@props.form.isServicer || false), "onChange": (@showBlock.bind @, "isServicer")}), React.createElement("label", {"htmlFor": "check28"}, React.createElement(CLabel, {"k": "search.company.isServicer"}))
                        )
                        React.createElement("div", {"key": "isReceiver", "className": "box-checkbox middle aligned row-half"},
                          React.createElement("input", {"type": "checkbox", "id": "check29", "checked": (@props.form.isReceiver || false), "onChange": (@showBlock.bind @, "isReceiver")}), React.createElement("label", {"htmlFor": "check29"}, React.createElement(CLabel, {"k": "search.company.isReceiver"}))
                        )
                      ]
                    )
                  )
                )
              ),
              React.createElement("div", {"className": "box-form-group"},
                React.createElement("div", {"className": "box-control box-control-top"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "portfolioSize", "value": (@props.form?.portfolioSize?.value), "name": "portfolioSize", "value": (@props.form?.portfolioSize?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.company.placeholder.portfolio.size"))}))
              )
            )
          ),
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "tile-title"},
              React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "search.company.location"})))
            ),
            React.createElement("form", {"className": "form form-small"},
              React.createElement(CProximityFilterElement, {"onBlur": (@putValue), "onChange": (@putValue), "name": "companyProximityFilter", "value": (@props.form?.companyProximityFilter?.value)})
            )
          ),
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "tile-title"},
              React.createElement("div", {"className": "name-block small"}, React.createElement("span", {"className": "bold"}, React.createElement(CLabel, {"k": "search.company.letterhead"})))
            ),
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "box-form-group"},
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "companyContactName", "value": (@props.form?.companyContactName?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.company.placeholder.contact.name"))})),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "companyName", "value": (@props.form?.companyName?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.company.placeholder.company.label"))})),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "companyAddress", "value": (@props.form?.companyAddress?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.company.placeholder.street.address"))})),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "companyCity", "value": (@props.form?.companyCity?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.company.placeholder.city"))})),
                React.createElement("div", {"className": "box-multi-control"},
                  React.createElement("div", {"className": "box-table"},
                    React.createElement("div", {"className": "box-control row-half"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "companyStateProvince", "value": (@props.form?.companyStateProvince?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.company.placeholder.state.province"))})),
                    React.createElement("div", {"className": "box-control row-half"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "companyPostalCode", "value": (@props.form?.companyPostalCode?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.company.placeholder.zip.code"))}))
                  )
                ),
                React.createElement("div", {"className": "box-control"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "companyCountry", "value": (@props.form?.companyCountry?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.company.placeholder.country"))}))
              ),
              React.createElement("div", {"className": "box-control mt-2"}, React.createElement(CFilterElement, {"onChange": (@putValue), "name": "companyId", "value": (@props.form?.companyId?.value), "type": "text", "className": "form-control", "placeholder": (@props.t("search.company.placeholder.company.id"))})),
              (if @props.form?.storageKey != "LENAV_PREVIOUS_SEARCH_FORM_AN" 
                React.createElement("div", {"className": "box-form-group"},
                  React.createElement("div", {"className": "box-control"},
                      React.createElement(CHasNotesFilterElement, {"notesSharingAllowed": (@props.client?.noteSharing), "showCheckbox": (true), "onChange": (@putValue), "name": "companyNotesFilter", "value": (@props.form?.companyNotesFilter?.value)})
                  )
                )
              )
            )
          )
        )
      )
    )
), {
  state: (state) ->
    form: state.searchForm.form
    translation: state.translation
    client: state.session?.user?.client
  dispatch: (dispatch) ->
    addValueToSearchForm: (name, value, options) -> dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value, options: options)
    toggleBlockOnSearchForm: (name) -> dispatch(type: 'TOGGLE_BLOCK_ON_SEARCH_FORM', name: name)
    setSearchFormColumns: (searchFormColumns) -> dispatch(type: 'SET_SEARCH_FORM_COLUMNS', searchFormColumns: searchFormColumns)
}
