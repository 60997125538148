const HEADER_PADDING = 110;

const PrintContent = ({ children, className }) => {
    const devicePixelRatio = navigator.vendor?.indexOf("Apple") > -1 ? 2 : 1;
    return <>
        <div className={className || ""} style={{ height: "100%" }}>
            <div className="show-print" style={{ paddingTop: HEADER_PADDING / devicePixelRatio }}></div>
            {children}
        </div>
    </>;
}

export default PrintContent;