import * as React from "react";
import HeaderSimple from "../pages/parts/HeaderSimple";
import Connect from "../helpers/Connect";
import "./shadow_user.scss";
import { useEffect, useState } from "react";
import { CLabel } from "../cmp/CLabel";
import { API } from "../redux/Store";
import { useTranslation } from "react-i18next";
const LENAV_SHADOW_SESSION = "LENAV_SHADOW_SESSION";

const ShadowUser = ({ params, translation, login, logout, session, router }) => {
    const [hasError, setError] = useState(false);
    const { t } = useTranslation();
    const { shadowToken } = params;

    const logoutSession = () => {
        logout(session);
    };

    const sessionAlreadyExistsForToken = () => {
        const shadowSession = JSON.parse(localStorage.getItem(LENAV_SHADOW_SESSION) || null);
        return session.token && shadowSession && shadowSession.shadowToken === shadowToken && shadowSession.token === session.token;
    }

    useEffect(() => {
        if (session && session.token) {
            if (sessionAlreadyExistsForToken()) {
                console.log(router)
                console.log('route:' + "/home")
                router.push('/home');
                return;
            }
            logoutSession();
            return;
        }

        let timeout = null;
        API.shadowLogin(shadowToken, (response) => {
            if (response.token) {
                localStorage.setItem(LENAV_SHADOW_SESSION, JSON.stringify({
                    token: response.token,
                    shadowToken: shadowToken
                }));
                login(response)
                timeout = setTimeout(() => router.push('/home'), 2000)
                return;
            }
            setError(true);
        }, () => {
            setError(true);
        });

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [session])

    const errorMessage = (() => {
        return <>
            <div><CLabel k="shadow.user.token.expired" /></div>
            <div className="mt-3">
                <CLabel k="shadow.user.please.contact1" />
                <a href="tel:+1 603 4318740">+1 603 4318740</a>
                <CLabel k="shadow.user.please.contact2" />
            </div>
        </>;
    })();

    const loadingMessage = (() => {
        return <>
            <div><CLabel k="shadow.user.preparing.session" /></div>
            <div className="mt-3">
                <CLabel k="shadow.user.loading" />
            </div>
        </>;
    })();

    return <div className="wrapper-content">
        <HeaderSimple title={t("header.title")} />
        <div className="main">
            <div className="main-content">
                <div className="card">
                    {hasError ? errorMessage : loadingMessage}
                </div>
            </div>
        </div>
    </div>;
}

export default Connect(ShadowUser, {
    dispatch: (dispatch) => {
        return {
            login: (session) => dispatch({ type: 'LOGIN', payload: session }),
            logout: (session) => dispatch({ type: "LOGOUT", payload: session, terminateSessionByUser: true, keepRoute: true })
        }
    },
    state: (state) => {
        return {
            translation: state.translation,
            session: state.session
        };
    },
});
