{ extend } = require '../../helpers/UtilsHelpers'

clone = (o) ->
  extend(true, { version: Math.random() }, o)

module.exports = (getStore, getAPI) ->
  loadChartDimensions = () ->
    getAPI().getDimensions (dimensions) ->
      getStore().dispatch(type: "SET_CHART_DIMENSIONS", payload: dimensions)

  loadFirstChart = () ->
    getAPI().getFirstChart (chart) ->
      getStore().dispatch(type: "SET_CHARTS", payload: [chart])
      getStore().dispatch(type: "LOAD_CHARTS", slice: true, isLoading: false)

  loadCharts = (slice = false) ->
    getAPI().getCharts (charts) ->
      if slice && charts.length > 4
        charts = charts.splice(0, 4)
      getStore().dispatch(type: "SET_CHARTS", payload: charts, isLoading: false)

  (state = { data: [], isLoading: true }, action) ->
    if action.type == "LOAD_CHART_DIMENSIONS"
      loadChartDimensions()
    else if action.type == "LOAD_FIRST_CHART"
      state.data = []
      state.isLoading = true
      loadFirstChart()
      clone state
    else if action.type == "LOAD_CHARTS"
      state.isLoading = action.isLoading
      loadCharts(action.slice)
      clone state
    else if action.type == "SET_CHARTS"
      state.data = action.payload
      state.isLoading = action.isLoading
      clone state
    else if action.type == "SET_CHART_DIMENSIONS"
      state.dimensions = action.payload
      clone state
    else
      state
