
Connect = require '../../../helpers/Connect'
{ Chart } = require 'react-google-charts'
{ isMobile } = require '../../../helpers/Utils'
{ API } = require '../../../redux/Store'
{ CLabel } = require '../../CLabel'
loadjs = require 'loadjs'
{ withTranslation } = require 'react-i18next' 
{ extend } = require '../../../helpers/UtilsHelpers'

googleChartsPromise = null


module.exports = withTranslation() Connect createReactClass(
  displayName: 'CChart'
  isUnmounted: false

  getInitialState: ->
    recalculate: true
    loading: true

  onResize: ()->
    clearTimeout(@timeoutId)
    @timeoutId = setTimeout ()=>
      @setState recalculate: true
    , 100

  componentDidMount: ->
    @isUnmounted = false

    @googleChartsLoader().then ()=>
      if !@isUnmounted
        @setState loading: false

    window.addEventListener("resize", @onResize)

  componentWillUnmount: ->
    @isUnmounted = true
    clearTimeout(@timeoutId)
    window.removeEventListener("resize", @onResize)

  googleChartsLoader: () ->
    if googleChartsPromise
      return googleChartsPromise

    googleChartsPromise = new Promise((resolve, reject) =>
      loadjs('https://www.gstatic.com/charts/loader.js',
        success: ()=>
          window.google.charts.load('current', packages: ['corechart'], language: @props.i18n.language)
          window.google.charts.setOnLoadCallback ()=> resolve()
        error: ()=>
          reject()
      )
    )

  handleSelectCallback: (Chart)->
    selection = Chart.chart.getSelection()[0]
    setFormAndSearch = (dimension1Value, dimension2Value, cellIndex)=>
      checkIsOther = (value, dimension)->
        if value == 'Other'
          other = Chart.props?.chart.data.filter (it)->
            it?[dimension].value != value

          if Chart.props.chartType == 'Table'
            data = Chart.props?.rows.map((row) -> row[0])
            selected = Chart.props?.rows.map((row)-> row[cellIndex])
            other = other.filter (it)-> it.dimension1.value == dimension1Value
          else
            data = Chart.props?.data[0]
            selected = Chart.props?.data[selection?.row + 1]
          other = other.filter (value, index, self)-> (self.map (it)-> it?[dimension].value).indexOf(value?[dimension].value) == index
          if dimension == 'dimension2'
            if Chart.props.chartType == 'Table'
              other = (other.filter (it)-> (data.filter (d, idx)->
                d == it.dimension1.label && selected[idx])?[0])
            else
              other = (other.filter (it)-> (data.filter (d, idx)->
                d == it[dimension].label && selected[idx])?[0])

          other = other.reduce (acc, next)->
            acc += if next?[dimension].value == '~' then '*' else  "-'" + next[dimension].value + "'"
            acc += ';'
          , ''
          dimensionIdx = if dimension == 'dimension1' then 0 else 1
          if Chart.props.chart.dimensions[dimensionIdx] == 'newbrandedchainscale' || Chart.props.chart.dimensions[dimensionIdx] == 'brandedchainscale'
            other + '*;'
          else
            other
        else
          if value == '~' then value else "'" + value + "'"

      filterForm1 = (Chart.props?.chart?.legend.filter (it)-> it.name == 'dimension1')?[0]?.filterForm
      if dimension2Value
        filterForm2 = (Chart.props?.chart?.legend.filter (it)-> it.name == 'dimension2')?[0]?.filterForm
      @props.initSearchForm()

      setupForm = (key, filterForm, dimensionValue, dimension)=>
        includesActivity = ['showNewConstruction', 'showRenovations', 'showConversions', 'showSales', 'showDistressedAssets'].includes(key)
        brandDimension = 'brand'
        keysToIgnoreWhenUnbranded = ['brand', 'chainScale']
        if ['isDeveloper', 'isManager', 'isContractor', 'isPurchaser', 'isArchitect', 'isDesigner'].includes(key)
          @props.toggleBlockOnSearchForm(key)
        else if includesActivity || key == 'activeOrDone' || key == 'activeStages' || key == 'big3Brands' || key == 'big6Brands'
          @props.toggleBlockOnSearchForm(key, true, filterForm[key])
        else if !includesActivity
          value = if filterForm[key] != '#value' then filterForm[key] else checkIsOther(dimensionValue, dimension)
          index = if dimension == 'dimension1' then 0 else 1

          if dimensionValue == '~' && Chart.props.chart.dimensions[index] == 'brandedchainscale'
            if keysToIgnoreWhenUnbranded.includes(key)
              key = brandDimension
              value = filterForm[brandDimension]?.replace('-', '')
          else if dimensionValue == "Unbranded Hotel" && (Chart.props.chart.dimensions[index] == 'newbrandedchainscale')
            if 'newFranchiseName' in Object.keys(filterForm)
              key = 'newFranchiseName'
              value = filterForm['newFranchiseName'].replace('-', '')
          formatterFn = (input)->
            input = typeof input == 'string' && input?.replace(/(;)/g, '$1 ')
            while input[input.length - 1]?.match(/[\s;]/)
              input = input.slice(0, -1)
            input
          if key == 'territory' || key == 'branch'
            API.dictionary key, {}, (options)=>
              @props.addValueToForm(key, value, options, formatterFn)
              @props.search(0)
              if key == 'territory'
                content = React.createElement("div", null,
                  React.createElement(CLabel, {"k": "chart.territory.info.message.text"})
                )
                @props.toggleInfoPopup(content)
          else
            if Chart.props.chart.dimensions.length == 1
              if key == brandDimension || key == 'newBrand'
                if @props.form[key]
                  return
            @props.addValueToForm(key, value, null, formatterFn)

      Object.keys(filterForm1).forEach (key, i)=>
        setupForm(key, filterForm1, dimension1Value, 'dimension1')
      if filterForm2
        Object.keys(filterForm2).forEach (key, i)=>
          setupForm(key, filterForm2, dimension2Value, 'dimension2')

      #@props.search(0)
      #setTimeout ()->
      @props.router.push('/results')
      # ,1000

    if Chart.props.chartType == 'Table'
      onCellClick = (colIndex) => (e)->
        if selection?.row > 0
          colIndex = colIndex % (selection?.row * Chart.props.columns?.length)
        if colIndex == Chart.props.columns?.length - 1 || selection?.row == Chart.props.rows?.length - 1
          return
        selected = (Chart.props?.chart?.data.filter (it)->
          it.dimension1?.label == Chart.props.rows?[selection?.row]?[0] &&
          it.measure1 == Chart.props.rows?[selection?.row]?[colIndex])?[0]
        if selected
          dimension1Value = selected.dimension1?.value
          dimension2Value = selected.dimension2?.value
          setFormAndSearch(dimension1Value, dimension2Value, colIndex)
        e.target.removeEventListener 'click', onCellClick(colIndex)

      document.querySelectorAll(".Table td").forEach (td, colIndex)->
        td.addEventListener 'click', onCellClick(colIndex)

    else if Chart.props.chartType == 'ColumnChart' || Chart.props.chartType == 'BarChart'
      if selection?.row == null || selection?.column == null
        return

      if Chart.props.options?.isStacked
        selected = (Chart.props?.chart?.data.filter (it)->
          it.dimension1?.label == Chart.props.data[selection?.row + 1]?[0] &&
          it.measure1 == Chart.props.data?[selection?.row + 1][selection?.column])[0]
        if selected
          dimension1Value = selected.dimension1?.value
          dimension2Value = selected.dimension2?.value
          setFormAndSearch(dimension1Value, dimension2Value)
      else
        selected = (Chart.props?.chart?.data.filter (it)->
          it.dimension1?.label == Chart.props.data[selection?.row + 1]?[0] &&
          it.measure1 == Chart.props.data?[selection?.row + 1]?[1])[0]
        if selected
          dimension1Value = selected.dimension1?.value
          setFormAndSearch(dimension1Value)

    else
      selection = Chart.chart.getSelection()?[0]
      dimension1Value = Chart.props.chart.data[selection?.row]?.dimension1?.value
      dimension2Value = Chart.props.chart.data[selection?.column]?.dimension2?.value
      setFormAndSearch(dimension1Value, dimension2Value)

  calculateHeight: ()->
    if isMobile() && (@props.chartType == 'ColumnChart' || @props.chartType == 'BarChart')
      500
    else if window.innerWidth < 400 && (@props.chartType == 'PieChart' && !@props.isDoubleChart)
      500
    else if @props.chartType == 'ColumnChart' || @props.chartType == 'BarChart'
      if @props.data?[0]?.length < 5
        370
      else
        400
    else if @props.chartType == 'PieChart' && !@props.isDoubleChart
      400
    else if @props.chartType == 'PieChart' && @props.isDoubleChart
      if window.innerWidth > 596 && window.innerWidth < 769
        500
      else
        305
    else if @props.chartType == 'PieChart'
      if window.innerWidth > 593 && window.innerWidth < 769
        485
      else
        305
    else if @props.chartType == 'Table'
      410
    else
      305

  renameCustpropset: (title)->
    if title == 'custpropset' && @props.searchFieldsInfo["branch"]?.label
      @props.searchFieldsInfo["branch"]?.label
    else
      @props.t("charts.#{title}")

  render: ->
    if @state.loading then return null
    translatedTitles = {}
    if @props.options?.hAxis?.title
      hAxisTitle = @renameCustpropset(@props.options.hAxis.title)
      translatedTitles.hAxis = title: hAxisTitle
    if @props.options?.vAxis?.title
      vAxisTitle = @renameCustpropset(@props.options.vAxis.title)
      translatedTitles.vAxis = title: vAxisTitle
    if @props.options?.title
      optionsTitle = @renameCustpropset(@props.options.title)
      translatedTitles.options = title: optionsTitle


    options = extend true,
      hAxis: { slantedText: @props.chartType != 'ColumnChart' }
      legend:
        position: 'top'
        maxLines: 5
        textStyle: color: '#3E3E3F'
        pagingTextStyle: color: '#931D3D'
        scrollArrows: activeColor: '#931D3D',inactiveColor:'#cccccc'
      , @props.options
      , translatedTitles

    React.createElement("div", {"className": (@props.chartType + ' ' + if @props.className then @props.className)},
      (if @props.chart?.error
        React.createElement("span", {"className": "chart-error"}, (@props.chart.error))
      else
        React.createElement("div", null,
          (@props.chart?.data?.length > 0 && React.createElement(Chart, { \
            "chartType": (@props.chartType),  \
            "data": (@props.data),  \
            "rows": (@props.rows),  \
            "columns": (@props.columns),  \
            "options": (options),  \
            "graph_id": (@props.graph_id),  \
            "chart": (@props.chart),  \
            "width": '100%',  \
            "height": (@calculateHeight() + 'px' if @state.recalculate),  \
            "chartEvents": ([{eventName: 'select', callback: @handleSelectCallback}]),  \
            "loader": (React.createElement("div", {"className": "search-block-loader"}, React.createElement("div", {"className": "loader"}, "\x3CCLabel k=\"loading\"\x3E"))),  \
            "loadCharts": (false)
          })),
          (@props.chart?.data?.length == 0 && React.createElement("div", {"style": ({
            height: @calculateHeight() + 'px' if @state.recalculate,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          })},
            React.createElement(CLabel, {"k": "charts.no.data"})
          )),
          (if @props.chartType == 'PieChart' && @props.index != undefined
            React.createElement("div", {"className": "pieChartTitle"}, React.createElement(CLabel, {"k": "charts.#{@props.chart.legend[@props.index]?.title}"}))
          else if @props.chartType == 'PieChart'
            React.createElement("div", {"className": "pieChartTitle"}, React.createElement(CLabel, {"k": "charts.#{@props.chart.legend[0]?.title}"}))
          else if @props.chartType == 'ColumnChart'
            React.createElement("div", {"className": "columnChartTitle"}, React.createElement(CLabel, {"k": "charts.#{@props.chart.dimensions[0]}"}))
          else if @props.chartType == 'BarChart'
            React.createElement("div", {"className": "barChartTitle"}, React.createElement(CLabel, {"k": "charts.#{@props.chart.legend[0]?.title}"}))
          )
        )
      )
    )

), {
  state: (state)->
    translation: state.translation
    form: state.searchForm.form
    searchFieldsInfo: state.app.searchFieldsInfo

  dispatch: (dispatch)->
    initSearchForm: () -> dispatch(type: 'INIT_SEARCH_FORM', withoutSearch: true)
    addValueToForm: (name, value, options, formatFn) -> dispatch(type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value, options: options, formatFn: formatFn, noCountChange: true)
    toggleBlockOnSearchForm: (name, setExplicitly, value) -> dispatch(type: 'TOGGLE_BLOCK_ON_SEARCH_FORM', name: name, setExplicitly: setExplicitly, value: value, withoutSearch: true)
    search: (page)-> dispatch(type: 'SEARCH', page: page, target:"CChart")
    toggleInfoPopup: (content)-> dispatch(type: 'TOGGLE_POPUP_CUSTOM', data: title: "chart.territory.info.message.title", content: content, buttonText: "ok", modalSize: 'modal-md')
}
