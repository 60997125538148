Connect = require '../../helpers/Connect'
{ CLabel } = require "../CLabel"
{ CInput } = require '../CInput'
CFilterDropdown = require '../filters/CFilterDropdown'
Utils = require '../../helpers/Utils'
Moment = require 'moment'
TimeInput = require('rc-time-picker').default
CFilterElement = require '../filters/CFilterElement'
{ API } = require '../../redux/Store'
{ debounce } = require "throttle-debounce"
{ cleanupFilter, getSearchForm } = require '../../cmp/results/CFilterUtils'
{ withTranslation } = require 'react-i18next' 
{ extend } = require '../../helpers/UtilsHelpers'
CCustomFieldSet = (require './CCustomFieldSet').default
 

module.exports = withTranslation() Connect createReactClass(
  displayName: "CAdminAutoExportItem"

  stateDirty: []

  getInitialState: () ->
    exportId: null
    clientId: null
    clientName: null
    format: null
    dayOfWeek: null
    dateFormat: @props.dateFormat
    hour: 0
    minute: 0
    time: Moment().startOf('day')
    host: null
    structure: null
    login: null
    isSftp: false
    path: null
    active: false
    lastExport: null
    status: null
    errorInfo: null
    succeed: true
    email: null
    period: Utils.EXPORT_PERIOD.WEEKLY
    weekOfMonth: null
    dayOfWeekOfMonth: null
    isValidDownload: false
    isValidAutoExport: false
    filterForm: @props.filterForm || null
    columns: @props.columns,
    note: null

  componentDidMount: () ->
    @setState
      time: Moment().set('hour', @state.hour).set('minute', @state.minute)
      exportId: @props.id
      clientId: @props.clientId
      clientName: @props.clientName
      format: @props.format
      dateFormat: @props.dateFormat
      dayOfWeek: @props.dayOfWeek
      hour: @props.hour
      minute: @props.minute
      structure: @props.structure
      host: @props.host
      login: @props.login
      isSftp: @props.isSftp || @state.isSftp
      path: @props.path
      active: @props.active
      lastExport: @props.lastExport
      status: @props.status
      errorInfo: @props.errorInfo
      period: @props.period ? 'WEEKLY'
      email: @props.email
      weekOfMonth: @props.weekOfMonth
      dayOfWeekOfMonth: @props.dayOfWeekOfMonth
      isValidDownload: false,
      isValidAutoExport: false,
      succeed: @props.succeed
      filterForm: @props.filterForm || null
      columns: @props.columns
      note: @props.note
      includeAllContacts: @props.includeAllContacts
      @validate


  toggleActive: () ->
    @setState active: !@state.active
    @saveAutoExport(active: !@state.active)

  toggleIncludeAllContacts: () ->
    @setState includeAllContacts: !@state.includeAllContacts
    @saveAutoExport(includeAllContacts: !@state.includeAllContacts)


  getTime: (hour, minute) ->
    if hour
      if minute == null
        minute = 0
      Moment().set('hour', hour).set('minute', minute)
    else
      null

  setTime: (e) ->
    hour = e.get('hour')
    minute = e.get('minute')
    @setState hour: hour, minute: minute, time: Moment().set('hour', hour).set('minute', minute)
    @saveAutoExport('hour': hour, 'minute': minute)

  setDayOfWeek: (_, v) ->
    @setState dayOfWeek: v
    @saveAutoExport(dayOfWeek: v)

  setWeekOfMonth: (_, v) ->
    @setState weekOfMonth: v
    @saveAutoExport(weekOfMonth: v)

  setDayOfWeekOfMonth: (_, v) ->
    @setState dayOfWeekOfMonth: v
    @saveAutoExport(dayOfWeekOfMonth: v)

  setFormat: (_, v) ->
    @setState format: v
    @saveAutoExport(format: v)

  setDateFormat: (value) ->
    @setState dateFormat: value
    @saveAutoExport(dateFormat: value)

  setStructure: (_, v) ->
    values = 'structure': v
    if(v == 'RelationalPackage')
      values.format = 'zip'
    else if(@state.format == 'zip')
      values.format = 'csv'
    @setState values
    @saveAutoExport(values)


  setSftpFlag: (value) ->
    @setState isSftp: value
    @saveAutoExport(isSftp: value)

  setExportPeriod: (value) ->
    @setState period: value
    @saveAutoExport(period: value)


  downloadItem: () ->
    if @state.clientId
      cb = (exportInfo)=>
        @props.setExportInProgress(exportInfo)

      API.startAutoExport { exportId: @state.exportId,  download: true }, cb

  sendItem: () ->
    API.startAutoExport { exportId: @state.exportId }

  deleteItem: () ->
    proceed = () =>
      @props.togglePopupCustom()
      @props.deleteAutoExport(@state.exportId)

    content = React.createElement("p", null,
      React.createElement(CLabel, {"k": "admin.auto.export.delete.description"})
    )
    button = React.createElement("a", {"className": "btn btn-red btn-sm close-modal", "onClick": (proceed)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.full.scan.proceed"})))
    @props.togglePopupCustom content, button, 'cancel'

  getStructureValues: () ->
    [
      {value: 'OneRowPerProperty', label:'One row per property'}
      {value: 'RelationalPackage', label:'Relational package'}
      {value: 'ProjectsHistory', label:'Project Timeline History'}
    ]

  getFormats: (structure) ->
    if(structure == 'RelationalPackage')
      [ {value: 'zip', label:'csv (zip)'}, {value: 'xml', label:'xml (zip)'} ] 
    else
      [
        {value: 'csv', label:'csv'},
        {value: 'csvp', label:'csv delimited with pipe (\'|\')'}
      ]


  saveAutoExport: (field) ->
    form =
      id: @state.exportId
      clientId: @state.clientId
      format: @state.format
      dayOfWeek: @state.dayOfWeek
      dateFormat: @state.dateFormat
      structure: @state.structure
      isSftp: @state.isSftp
      hour: @state.hour
      minute: @state.minute
      host: @state.host
      login: @state.login
      path: @state.path
      active: @state.active
      period: @state.period
      email: @state.email
      weekOfMonth: @state.weekOfMonth
      dayOfWeekOfMonth: @state.dayOfWeekOfMonth
      filterForm: @state.filterForm
      columns: @state.columns
      note: @state.note
      includeAllContacts: @state.includeAllContacts

    for key of field
      form[key] = field[key]
    @props.saveAutoExport(form)

  onBlur: (name, id, e) -> 
    value = e.target.value
    form = {}
    form[name] = value
    dirtyIndex = @stateDirty.indexOf(name);
    if @state[name] != value || dirtyIndex >= 0
      @setState form
      @stateDirty.splice(dirtyIndex, 1)
      @saveAutoExport form

  onInputChange: (event) ->
    value = event.target.value
    name = event.target.name
    form = {}
    form[name] = value
    if @state[name] != value
      @setState form, @validate
      if not (name in @stateDirty)
        @stateDirty.push(name)

  validate: () ->
    @setState
      isValidDownload: @state.clientId?.trim()
      isValidAutoExport: @state.clientId?.trim() &&  @state.host?.trim() && @state.login?.trim()

  setClientForm: (name, e, value, label) ->
    if e?.target?.value
      value = e.target.value
    if value?.lastIndexOf(";")
      value = value.substring(0, value.lastIndexOf(";"))
    @setState clientId: value, clientName: label, filterForm: null, @validate
    @saveAutoExport clientId: value

  autocompleteClientXhr: (field, cb) ->
    if @state[field]
      API.clientAutocomplete(@state[field], cb)

  autocompleteBranchXhr: (field, cb, value) ->
    API.branchAutocomplete(value, @state.clientId, cb)

  onFilterChange: ()->
    @saveAutoExport(filterForm: cleanupFilter(@props.searchForm))
    @setState filterForm: cleanupFilter(@props.searchForm), @validate
    @props.initSearchForm()

  getFieldsData: ()->
    if !@state.filterForm
      return labels: @props.t('admin.tabs.auto.export.filter.none', { ns: "internal_ui" }), size: 0
    fields = getSearchForm({
      searchForm: @state.filterForm,
      client: @props.client,
      fullReload: true
    })
    labels = [];
    searchFieldsInfo = @props.searchFieldsInfo
    fields.forEach (field)->
      labels.push(searchFieldsInfo?[field.name]?.label || Utils.prettifyFieldName(field.name))
    if labels.length == 0
      return labels: @props.t('admin.tabs.auto.export.filter.none', { ns: "internal_ui" }), size: 0
    return labels: labels.join(', '), size: fields.size


  openFilterPopup: ()->
    form = extend(true, {}, @state.filterForm)
    form.clientId = @state.clientId
    form.propertySetLabel = @props.propertySetLabel
    form.searchType = Const.SEARCH_TYPE_PROPERTIES
    @props.setForm(form)
    @props.toggleFilterPopup(form, @onFilterChange, {
      clientId: @state.clientId
    })

  onCustomFieldSetChange: (fields) ->
    @setState columns: fields
    @saveAutoExport(columns: fields)

  render: () ->
    activeCheckboxBold = @state.active
    activeStyleForCheckbox = if @state.active then fontWeight: 'bold' else fontWeight: 'normal'
    includeAllContactsStyleForCheckbox = if @state.includeAllContacts then fontWeight: 'bold' else fontWeight: 'normal'

    fieldsData = @getFieldsData()
    fieldsLabels = fieldsData.labels
    fieldsSize = fieldsData.size

    React.createElement("div", {"className": "box-control clearfix auto-export-item", "key": ("auto-export-" + @props.id), "style": (margin: '10px')},
      React.createElement("div", {"className": "auto-export-item-header"},
        React.createElement("div", {"className": "column-three"},
          React.createElement("div", {"className": "title-group", "style": ( flex: 0.8 )},
            React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.clientId"}))
          ),
          React.createElement("div", {"className": "inputs-group"},
            React.createElement("div", {"key": 'clientId', "className": "box-control auto-export-block"},
              React.createElement("div", {"className": "box-inline text-left auto-export-block", "style": (width: '100%'), "key": "clientIdValue"},
                React.createElement(CFilterElement, { \
                  "type": "text",  \
                  "withLabels": true,  \
                  "autocompleteXhr": (@autocompleteClientXhr.bind @, 'clientName'),  \
                  "onChange": (@setClientForm.bind @, 'clientName'),  \
                  "value": (@props.clientId),  \
                  "label": (@props.clientName),  \
                  "className": "form-control"
                })
              )
            )
          )
        ),
        React.createElement("div", {"className": "column-three", "style": ( flex: 1.7, paddingLeft: 40 )},
          React.createElement("div", {"className": "title-group", "style": ( flex: 0.435 )},
            React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.note"}))
          ),
          React.createElement("div", {"className": "inputs-group"},
            React.createElement("div", {"className": "box-control auto-export-block", "key": 'note'},
              React.createElement(CInput, {"name": 'note', "id": "note", "className": "form-control auto-export-item-input", "type": 'text', "onChange": (@onInputChange), "onBlur": (@onBlur.bind @, 'note', @props.id), "value": (@state.note), "style": (width: '100%')})
            )
          )
        )
      ),
      React.createElement("div", {"className": "box-control clearfix auto-export-item", "style": (margin: '10px 10px 0 10px')},
        React.createElement("div", {"key": "data-item", "style": ( display: 'flex' )},
          React.createElement("div", {"className": "column-three", "key": "left"},
            React.createElement("div", {"className": "title-group", "style": ( flex: 0.8 )},
              React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.format"})),
              React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.structure"})),
              React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.filter.label"})),
              React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.fields"}))
            ),
            React.createElement("div", {"className": "inputs-group"},
              React.createElement("div", {"key": 'format', "className": "box-control auto-export-block"},
                React.createElement("div", {"className": "box-inline text-left auto-export-block", "style": (width: '100%'), "key": "formatValue"},
                  React.createElement(CFilterDropdown, {"name": 'format', "id": "format", "className": "text-left auto-export-dropdown", "onChange": (@setFormat), "value": (@state.format), "options": (@getFormats(@state?.structure))})
                )
              ),
              React.createElement("div", {"className": "box-control auto-export-block", "key": 'structure'},
                React.createElement("div", {"className": "box-inline text-left auto-export-block", "style": (width: '100%'), "key": "structureValue"},
                  React.createElement(CFilterDropdown, {"name": 'structure', "id": "structure", "className": "text-left auto-export-dropdown", "onChange": (@setStructure), "value": (@state.structure), "options": (@getStructureValues())})
                )
              ),
              React.createElement("div", {"className": "box-control auto-export-block", "key": 'filter'},
                React.createElement("div", {"className": "box-inline text-left auto-export-block", "onClick": (@openFilterPopup), "style": (width: '100%'), "key": "structureValue"},
                  React.createElement("div", { \
                    "type": "text",  \
                    "className": "form-control auto-export-filter-input",  \
                    "style": ({ fontStyle: if fieldsSize > 0 then 'normal' else 'italic' })
                  },
                    (fieldsLabels)
                  )
                )
              ),
              React.createElement("div", {"className": "box-control auto-export-block", "key": 'fields'},
                React.createElement("div", {"className": "box-inline text-left auto-export-block", "style": (width: '100%'), "key": "fields"},
                  React.createElement(CCustomFieldSet, {"clientId": (@props.clientId), "clientName": (@state.clientName), "structure": (@state.structure), "structureName": (@getStructureValues().find((structure) => structure.value == @state.structure)?.label || ""), "onChange": (@onCustomFieldSetChange), "selectedColumns": (@state.columns)})
                )
              )
            )
          ),
          React.createElement("div", {"className": "column-three", "key": "center"},
            React.createElement("div", {"className": "title-group"},
              React.createElement("label", {"className": "auto-export-item-label"},
                    React.createElement("div", {"className": "box-control box-checkbox"},
                    React.createElement("input", {"type": "radio", "id": ("data1_" + @props.id), "onChange": (@setSftpFlag.bind @, true), "checked": (@state.isSftp)}),
                    React.createElement("label", {"style": ({paddingLeft: '22px', marginRight: '8px'}), "htmlFor": ("data1_" + @props.id)}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.sftp"})),
                    React.createElement("input", {"type": "radio", "id": ("data2_" + @props.id), "onChange": (@setSftpFlag.bind @, false), "checked": (!@state.isSftp)}),
                    React.createElement("label", {"style": ({paddingLeft: '22px'}), "htmlFor": ("data2_" + @props.id)}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.ftp"}))
                    )
              ),
              React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.login"})),
              React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.path"})),
              React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.email.notify"}))
            ),
            React.createElement("div", {"className": "inputs-group"},
              React.createElement("div", {"key": 'clientId', "className": "box-control auto-export-block"},
                React.createElement(CInput, {"name": 'host', "id": "host", "className": "form-control auto-export-item-input", "type": 'text', "onChange": (@onInputChange), "onBlur": (@onBlur.bind @, 'host', @props.id), "value": (@state.host), "style": (width: '100%')})
              ),
              React.createElement("div", {"key": 'format', "className": "box-control auto-export-block"},
                React.createElement(CInput, {"name": 'login', "id": "login", "className": "form-control auto-export-item-input", "type": 'text', "onChange": (@onInputChange), "onBlur": (@onBlur.bind @, 'login', @props.id), "value": (@state.login), "style": (width: '100%')})
              ),
              React.createElement("div", {"className": "box-control auto-export-block", "key": 'path'},
                React.createElement(CInput, {"name": 'path', "id": "path", "className": "form-control auto-export-item-input", "type": 'text', "onChange": (@onInputChange), "onBlur": (@onBlur.bind @, 'path', @props.id), "value": (@state.path), "style": (width: '100%')})
              ),
              React.createElement("div", {"className": "box-control auto-export-block", "key": 'email'},
                React.createElement(CInput, {"name": 'email', "id": "email", "placeholder": (@props.t('admin.tabs.auto.export.email.description', { ns: "internal_ui" })), "className": "form-control auto-export-item-input", "type": 'text', "onChange": (@onInputChange), "onBlur": (@onBlur.bind @, 'email', @props.id), "value": (@state.email), "style": (width: '100%')})
              )
            )
          ),
          React.createElement("div", {"className": "column-three title-group", "key": "right", "style": ( flex: 0.7, alignItems: "flex-start", paddingLeft: "40px")},
            React.createElement("div", {"className": "auto-export-item-label box-control box-checkbox"},
              React.createElement("input", {"type": "checkbox", "id": ("active-" + @props.id), "onChange": (@toggleActive), "checked": (@props.active || false)}),
              React.createElement("label", {"htmlFor": ("active-" + @props.id), "style": (activeStyleForCheckbox)}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.active"}))
            ),
            React.createElement("div", {"className": "auto-export-item-label box-control",     \
              "style": ( marginLeft: "30px", marginBottom: "5px" )},
              React.createElement("label", {"className": "auto-export-item-label"}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.time"})),
              React.createElement(TimeInput, { \
                  "onChange": (@setTime),  \
                  "value": (@getTime(@props.hour, @props.minute)),  \
                  "showSecond": (false),  \
                  "hideDisabledOptions": (true),  \
                  "allowEmpty": (false)
              }),
              React.createElement("span", {"style": (marginLeft: '5px')}, React.createElement(CLabel, {"k": "admin.timezone.utc"}))
            ),
            React.createElement("div", {"className": "auto-export-item-label box-control box-checkbox", "style": (width: '100%', whiteSpace: 'nowrap')},
              React.createElement("input", {"type": "radio", "id": ("isWeekly" + @props.id), "onChange": (@setExportPeriod.bind @, Utils.EXPORT_PERIOD.WEEKLY), "checked": (@state.period == 'WEEKLY')}),
              React.createElement("label", {"htmlFor": ("isWeekly" + @props.id)},
                React.createElement(CLabel, {"k": 'admin.tabs.auto.export.weekly'})
              ),
              React.createElement(CFilterDropdown, {"style": (marginLeft: "10px", width: "100%"), "placeholder": "--none--", "className": "text-left auto-export-dropdown", "onChange": (@setDayOfWeek), "value": (@state.dayOfWeek), "options": (Utils.getDaysOfWeek())})
            ),
            React.createElement("div", {"className": "auto-export-item-label box-control box-checkbox"},
              React.createElement("input", {"type": "radio", "id": ("isMonthly" + @props.id), "onChange": (@setExportPeriod.bind @, Utils.EXPORT_PERIOD.MONTHLY), "checked": (@state.period == 'MONTHLY')}),
              React.createElement("label", {"htmlFor": ("isMonthly" + @props.id)},
                React.createElement(CLabel, {"k": 'admin.tabs.auto.export.monthly'})
              )
            ),
            React.createElement("div", {"className": "auto-export-item-label box-control", "style": (width: "100%", whiteSpace: 'nowrap')},
              React.createElement(CFilterDropdown, {"style": (marginLeft: "30px"), "placeholder": "--none--", "className": "text-left auto-export-dropdown", "onChange": (@setWeekOfMonth), "value": (@state.weekOfMonth), "options": (Utils.getWeekOfMonth())}),
              React.createElement(CFilterDropdown, {"style": (marginLeft: "10px", width: "100%"), "placeholder": "--none--", "className": "text-left auto-export-dropdown", "onChange": (@setDayOfWeekOfMonth), "value": (@state.dayOfWeekOfMonth), "options": (Utils.getDaysOfWeek())})
            )
          )
        ),
        React.createElement("div", {"className": "row", "style": (display: "flex", width: "80%")},
          React.createElement("div", {"style": (flex: 1)},
            React.createElement("div", {"className": "auto-export-item-label box-control box-checkbox #{if @state.structure != 'RelationalPackage' then 'disabled' else ''}", "style": (paddingLeft: "93px")},
              React.createElement("input", {"type": "checkbox", "id": ("includeAllContacts-" + @props.id), "onChange": (@toggleIncludeAllContacts), "checked": (@props.includeAllContacts || false)}),
              React.createElement("label", {"htmlFor": ("includeAllContacts-" + @props.id), "style": (includeAllContactsStyleForCheckbox)}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.contacts.all"}))
            )
          ),
          React.createElement("div", {"className": "box-checkbox inputs-group inputs-group--row"},
            React.createElement("span", null, React.createElement(CLabel, {"k": 'results.fast.filter.table.dateformat.label'})),
            React.createElement("input", {"type": "radio", "id": ("dateFormat_us-" + @props.id), "onChange": (@setDateFormat.bind @, 'US'), "checked": (@state.dateFormat == 'US')}),
            React.createElement("label", {"htmlFor": ("dateFormat_us-" + @props.id)},
              React.createElement(CLabel, {"k": 'results.fast.filter.table.dateformat.us'})
            ),
            React.createElement("input", {"type": "radio", "id": ("dateFormat_int-" + @props.id), "onChange": (@setDateFormat.bind @, 'International'), "checked": (@state.dateFormat == 'International')}),
            React.createElement("label", {"htmlFor": ("dateFormat_int-" + @props.id)},
              React.createElement(CLabel, {"k": 'results.fast.filter.table.dateformat.international'})
            )
          )
        )
      ),
      (if @props.id
        React.createElement("div", {"className": "box-form-group", "key": "actions"},
          React.createElement("div", {"className": "row-double-three", "key": "history", "style": (fontStyle: 'italic', textAlign: 'left'), "key": "active"},
            React.createElement("span", {"style": (marginLeft: '15px')}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.last.transmission"})),
            (
              if @state.lastExport
                color = 'green'
                if !@state.succeed
                  color = 'red'
                React.createElement("span", null,
                  React.createElement("span", {"key": "lastExport"}, (Moment(@state.lastExport).tz('UTC').format('YYYY-MM-DD hh:mm'))),
                  React.createElement("span", {"className": "bold"}, (' - ')),
                  React.createElement("span", {"key": "status", "className": "bold", "style": (color: color)}, (@state.errorInfo ? @state.status))
                )
              else
                React.createElement("span", {"className": "bold"}, (" - Didn't execute"))
            )
          ),
          React.createElement("div", {"className": "row-three", "key": "buttons"},
            React.createElement("div", {"className": "row-three", "key": "download"}, React.createElement("a", {"className": "btn bold text-center #{if !@state.isValidDownload then 'disabled' else ''}", "onClick": (@downloadItem)}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.actions.download"}))),
            React.createElement("div", {"className": "row-three", "key": "send"}, React.createElement("a", {"className": "btn bold text-center #{if !@state.isValidAutoExport then 'disabled' else ''}", "onClick": (@sendItem)}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.actions.send"}))),
            React.createElement("div", {"className": "row-three", "key": "delete"}, React.createElement("a", {"className": "btn bold text-center", "onClick": (@deleteItem)}, React.createElement(CLabel, {"k": "admin.tabs.auto.export.actions.delete"})))
          )
        )
      )
    )

), {
  state: (state) ->
    {
      searchForm: state.searchForm.form
      translation: state.translation
      searchFieldsInfo: state.app.searchFieldsInfo
      client: state.session && state.session.user && state.session.user.client
    }
  dispatch: (dispatch) ->
    toggleFilterPopup: (form, onSearch, defaultValues) ->
      dispatch(type: 'TOGGLE_POPUP_FILTER', onSuccessCallback: onSearch, data: originalForm: form, excludeTab: Const.SEARCH_TYPE_COMPANIES, defaultValues: defaultValues, successBtnText: @t("search.save.filters"))
    initSearchForm: () -> dispatch(type: 'INIT_SEARCH_FORM')
    setForm: (form) -> dispatch(type: 'SET_FILTER_FORM', form: form, getCount: true)
    setExportInProgress: (exportInfo) -> dispatch(type: 'SET_EXPORT_IN_PROGRESS', payload: exportInfo)
    saveAutoExport: (form)-> dispatch(type: "SAVE_AUTO_EXPORT", data: form)
    deleteAutoExport: (exportId) -> dispatch(type: "DELETE_AUTO_EXPORT", exportId: exportId)
    togglePopupCustom: (content, button, buttonText) -> dispatch(type: 'TOGGLE_POPUP_CUSTOM', data: title: 'admin.auto.export.delete.label', content: content, button: button, buttonText: buttonText, buttonTextClass: 'btn-grey row-half', hideCloseOnMobile: true)
}