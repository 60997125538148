
CRolesList = require('../property/CRolesList.js').default
CRoleLong = require('../property/CRoleLong.js').default
CRoleCompact = require('../property/CRoleCompact.js').default

{ AccessRestriction } = require "../AccessRestriction"
{ Link } = require 'react-router'

Connect = require '../../helpers/Connect'
{ formatNumber, getColorByChanges } = require '../../helpers/Utils'
{ CLabel } = require '../../cmp/CLabel'
Moment = require 'moment'
{ API } = require '../../redux/Store'

CResultAddress = require '../../cmp/results/CResultAddress'
{ translateCategoricalValue } = require '../../cmp/filters/CTranslateUtils'
{ withTranslation, Trans } = require 'react-i18next'
{ getDateLocale } = require "../../helpers/LocaleHelpers";
CMobileDropdownMenu = require '../../cmp/CMobileDropdownMenu';

module.exports = withTranslation() Connect createReactClass(
  displayName: 'CWatchPopupNew'

  getInitialState: ->
    property: @props.watch?.property
    watching: @props.watching

  componentWillReceiveProps: (newProps) ->
    @setState property: newProps.watch?.property

  formatPhoneNumber: (number) ->
    number?.replace(/^(\d{3})\d*/g, "\$1")

  toggleWatch: ->
    @setState watching: !@state.watching
    @props.toggleWatch()

  generateProjectInfo: (project) ->
    result = []
    if project.projectType in ["New Construction", "Renovation", "Conversion"]
      result.push React.createElement("div", {"className": "info-hotel", "key": (project.projectType)},
        React.createElement("div", {"className": ("item-info title item-info-#{getColorByChanges(project, 'createdAt')}")}, (translateCategoricalValue(@props.t, 'projectType', project.projectType))),
        (if project.projectStageNew
          React.createElement("div", {"className": "item-info"}, React.createElement("span", {"className": ("#{getColorByChanges(project, 'projectStageUpdatedAt', 'bold')}")}, (translateCategoricalValue(@props.t, 'projectStage', project.projectStageNew))))
        ),
        (if project.startDateNew
          React.createElement("div", {"className": "item-info"}, React.createElement("span", {"className": "name"}, React.createElement(CLabel, {"k": "watch.list.property.project.start"})), React.createElement("span", {"className": ("#{getColorByChanges(project, 'startDateUpdatedAt', 'bold')}")}, " ", (Moment(project.startDateNew).tz('UTC').locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]"))))
        ),
        (if project.scheduledOpenNew
          React.createElement("div", {"className": "item-info"}, React.createElement("span", {"className": "name"}, React.createElement(CLabel, {"k": "watch.list.property.project.open"})), React.createElement("span", {"className": ("#{getColorByChanges(project, 'scheduledOpenUpdatedAt', 'bold')}")}, " ", (Moment(project.scheduledOpenNew).tz('UTC').locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]"))))
        )
      )
    if project.projectType == "Distressed Asset"
      result.push React.createElement("div", {"className": "info-hotel", "key": (project.projectType)},
        React.createElement("div", {"className": ("item-info title item-info-#{getColorByChanges(project, 'createdAt')}")}, (translateCategoricalValue(@props.t, 'projectType', project.projectType))),
        React.createElement("div", {"className": "item-info"}, React.createElement("span", {"className": "name"}, React.createElement(CLabel, {"k": "watch.list.property.project.asset.status"})), React.createElement("span", {"className": ("#{getColorByChanges(project, 'assetStatusUpdatedAt', 'bold')}")}, " ", (translateCategoricalValue(@props.t, 'assetStatus', project.assetStatusNew))))
      )
    if project.projectType == "Sale"
      result.push React.createElement("div", {"className": "info-hotel", "key": (project.projectType)},
        React.createElement("div", {"className": ("item-info title item-info-#{getColorByChanges(project, 'createdAt')}")}, (translateCategoricalValue(@props.t, 'projectType', project.projectType))),
        (if project.dateOfSaleNew
          React.createElement("div", {"className": "item-info"}, React.createElement("span", {"className": "name"}, React.createElement(CLabel, {"k": "watch.list.project.dateOfSale"})), " ", React.createElement("span", {"className": ("#{getColorByChanges(project, 'dateOfSaleUpdatedAt', 'bold')}")}, (Moment(project.dateOfSaleNew).tz('UTC').locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]"))))
        ),
        (if project.buyerNameNew
          React.createElement("div", {"className": "item-info"}, React.createElement("span", {"className": ("#{getColorByChanges(project, 'buyerNameUpdatedAt', 'bold')}")}, (project.buyerNameNew)))
        ),
        (if project.sellingPriceNew
          React.createElement("div", {"className": "item-info"}, React.createElement("span", {"className": "name"}, React.createElement(CLabel, {"k": "watch.list.property.project.price"})), React.createElement("span", {"className": ("#{getColorByChanges(project, 'sellingPriceUpdatedAt', 'bold')}")}, " $", (formatNumber(project.sellingPriceNew))))
        ),
        (if project.sellingPricePerRoomNew
          React.createElement("div", {"className": "item-info"}, React.createElement("span", {"className": "name"}, React.createElement(CLabel, {"k": "watch.list.property.project.per.room"})), React.createElement("span", {"className": ("##{getColorByChanges(project, 'sellingPricePerRoomUpdatedAt', 'bold')}")}, " $", (formatNumber(project.sellingPricePerRoomNew))))
        )
      )

    result

  seeDetails: ->
#    @props.toggleWatchPopup()
    @props.close()
    @props.router.push("/property/#{@props.watch.watch?.propertyId}")

  addNote: ->
    @props.close()
    @props.toggleEditNotePopup({
      id: @props.watch?.watch?.propertyId,
      name: @state.property?.nameNew || @state.property?.name,
    }, @props.loadData)

  render: ->
    propertyColor = Utils.getColorByDate(@state.property.createdAt)
    options = [
      {label: React.createElement(CLabel, {"k": (if @state.watching then "watch.list.property.watching" else "watch.list.property.add.to.watch.list")}), icon:"fa-check", onClick: @toggleWatch, checkAccess: true },
      {label: React.createElement(CLabel, {"k": "watch.list.property.new.note"}), icon:"fa-plus", onClick: @addNote, checkAccess: true },
    ]
    React.createElement("div", {"className": "modal open", "id": "modal-watch-list"},
      React.createElement("div", {"className": "modal-header-mobile show-mobile"},
        React.createElement("a", {"className": "btn btn-back close-modal", "onClick": (@props.close)}, React.createElement("i", {"className": "fa fa-times"})),
        React.createElement("div", {"className": "title-page"}, (@state.property?.nameNew))
      ),
      React.createElement("div", {"className": "modal-dialog modal-sm"},
        React.createElement("div", {"className": "modal-content", "style": (width: "600px")},
          React.createElement("div", {"className": "modal-header hide-mobile"},
            React.createElement("div", {"className": "modal-title with-nav"},
              React.createElement("a", {"className": "small tooltip black", "style": ({ display: "block", width: "max-content" }), "title": (@state.property?.nameNew)},
                React.createElement("span", {"className": "name-block"}, (@state.property?.nameNew)),
              (if propertyColor
                React.createElement("div", {"style": (if propertyColor == "orange" then background: "orange"), "className": "label-status"}, React.createElement(CLabel, {"k": "results.result.new.label"}))
              )
              ),
              React.createElement("div", {"className": "box-nav"},
                React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn link-red", "onClick": (@toggleWatch)}, React.createElement("i", {"className": "fa fa-check icon-left"}), (if @state.watching then React.createElement(CLabel, {"k": "watch.list.property.watching"}) else React.createElement(CLabel, {"k": "watch.list.property.add.to.watch.list"})))),
                React.createElement("a", {"className": "btn link-red", "onClick": (@addNote), "data-cy-add-note": "true"}, React.createElement("i", {"className": "fa fa-plus icon-left"}), React.createElement(CLabel, {"k": "watch.list.property.new.note"}))
              )
            )
          ),
          React.createElement("div", {"className": "modal-body"},
            React.createElement("form", {"className": "form form-small"},
              React.createElement("div", {"className": "body-group"},
                React.createElement("table", {"className": "table table-border table-normal"},
                  React.createElement("tbody", null,
                    React.createElement("tr", null,
                      React.createElement("td", {"style": (width: "40%")},
                        React.createElement("span", {"style": ({ verticalAlign: 'top' })},
                          React.createElement(CResultAddress, {"property": (@state.property), "fieldsInfo": ([
                            {n:"addressNew", post:React.createElement("br", null), updatedAtField: "addressUpdatedAt"},
                            {n:"address1New", post:React.createElement("br", null), updatedAtField: "address1UpdatedAt"},
                            {n:"address2New", post: ", ", updatedAtField: "address2UpdatedAt"},
                            {n:"cityNew",  post: ", ", updatedAtField: "cityUpdatedAt"},
                            {n:"stateProvinceNew", post: " ", updatedAtField: "stateProvinceUpdatedAt"},
                            {n:"postalCodeNew", post: " ", updatedAtField: "postalCodeUpdatedAt"},
                            {n:"countryNew", pre:React.createElement("br", null), updatedAtField: "countryUpdatedAt"}
                          ])})
                        ),
                        (if @state.property?.phoneNew
                          React.createElement("div", null,
                            React.createElement("span", {"className": ("#{getColorByChanges(@state.property, 'phoneUpdatedAt', 'bold')}")},
                              React.createElement(Trans, {"i18nKey": "property.contact.phoneNumber", "values": ({
                                phoneNumber: @formatPhoneNumber(@state.property?.phoneNew)
                              })},
                                React.createElement("span", null, "P: "),
                                React.createElement("a", {"target": "_top", "href": ("tel:" + @formatPhoneNumber(@state.property?.phoneNew)), "className": "link link-phone"})
                              )
                            )
                          )
                        )
                      ),
                      React.createElement("td", {"className": "cell-details", "style": ({paddingLeft: "10px"})},
                        React.createElement("table", {"className": "table"},
                          React.createElement("tbody", null,
                            React.createElement("tr", null, React.createElement("td", {"className": "cell-label"}, React.createElement(CLabel, {"k": "watch.list.property.rooms"})), React.createElement("td", {"className": ("text-right #{getColorByChanges(@state.property, 'roomsUpdatedAt', 'bold')}")}, (@state.property.roomsNew))),
                            React.createElement("tr", null, React.createElement("td", {"className": "cell-label"}, React.createElement(CLabel, {"k": "watch.list.property.brand"})), React.createElement("td", {"className": ("text-right nowrap #{getColorByChanges(@state.property, 'brandNameUpdatedAt', 'bold')}")}, (@state.property.brandNameNew || "Unbranded"))),
                            React.createElement("tr", null, React.createElement("td", {"className": "cell-label nowrap"}, React.createElement(CLabel, {"k": "watch.list.property.chain.scale"})), React.createElement("td", {"className": ("text-right #{getColorByChanges(@state.property, 'chainScaleUpdatedAt', 'bold')}")}, (translateCategoricalValue(@props.t, 'chainScale', @state.property.chainScaleNew))))
                          )
                        )
                      )
                    )
                  )
                )
              ),
              React.createElement("div", null,
                ((@state.property?.projects || []).map((project, i) =>
                  React.createElement("div", {"className": "body-group project-color-#{project.projectType?.replace(' ', '').toLowerCase()}", "key": (i)},
                    (@generateProjectInfo(project))
                  )
                ))
              ),
              React.createElement("div", {"className": "body-group hide-mobile"},
              React.createElement(CRolesList, {"property": (@state.property), "RenderItemAs": (CRoleLong), "isHistory": (true), "separateRolesContacts": (true), "style": ({
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row"
                })})
              ),
              React.createElement("div", {"className": "body-group show-mobile"},
                React.createElement(CRolesList, {"property": (@state.property), "RenderItemAs": (CRoleCompact), "isHistory": (true), "style": ({flexDirection: "column", justifyContent: "stretch"})})
              )
            ),
            React.createElement("div", {"className": "modal-property-id show-mobile"}, "ID: ", (@props.watch?.watch?.propertyId))
          ),
          React.createElement("div", {"className": "modal-footer hide-mobile"},
            React.createElement("div", {"className": "modal-row"},
              React.createElement("div", {"className": "modal-property-id"}, "ID: ", (@props.watch?.watch?.propertyId)),
              React.createElement("div", {"className": "box-btn text-right"},
                React.createElement("a", {"className": "btn btn-grey btn-normal close-modal", "onClick": (@props.close)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "watch.list.property.close"}), " ")),
                React.createElement("a", {"className": "btn btn-red btn-normal close-modal", "onClick": (@seeDetails)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "watch.list.property.see.details"})))
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": "modal-footer-mobile show-mobile"},
        React.createElement("a", {"className": "btn btn-red btn-normal close-modal", "onClick": (@seeDetails)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "watch.list.property.see.details"})))
      ),
      React.createElement(CMobileDropdownMenu, {"options": (options)})
    )
),
state: (state) ->
  form: state.searchForm.form
dispatch: (dispatch) ->
  deleteWatch: (propertyId, cb) -> dispatch(type: 'DELETE_WATCH', propertyId: propertyId, cb: cb)
  updateWatch: (propertyId, cb) -> dispatch(type: 'UPDATE_WATCH', propertyId: propertyId, cb: cb)
  toggleEditNotePopup: (property, loadData) -> dispatch(type: 'TOGGLE_POPUP_EDIT_NOTE', data: { property: property, onChanged: loadData })
