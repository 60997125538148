const DAYS_7 = "DAYS_7";
const DAYS_14 = "DAYS_14";
const DAYS_30 = "DAYS_30";
const DAYS_60 = "DAYS_60";
const DAYS_EARLIER = "DAYS_EARLIER";
const NOT_CHANGED = "NOT_CHANGED";
import * as Moment from 'moment'

const INTERESTING_FIELDS_PROPERTY = [
  'devOwnCompanyId',
  'managementCompanyId',
  'devOwnContactId',
  'managementContactId',
  'address1',
  'brandName',
];

const INTERESTING_FIELDS_PROJECTS = [
  'projectStage',
  'scheduledOpen',
  'dateOfSale',
  'startDate',
  'sellingPrice',
  'sellingPricePerRoom',
  'assetStatus',
  'purchaserId',
  'sellerId',
  'buyerId',
  'receiverId',
  'servicerId',
  'contractorId',
  'architectId',
  'designerId',
  'lenderId',
  'purchaserContactId',
  'sellerContactId',
  'buyerContactId',
  'receiverContactId',
  'servicerContactId',
  'contractorContactId',
  'architectContactId',
  'designerContactId',
  'lenderContactId',
  'newFranchiseName'
];

const DATE_FIELDS = [
  'startDate',
  'scheduledOpen',
  'dateOfSale'
]

const PRICE_FIELDS = [
  'sellingPrice'
]

const OLD_VALUE_FIELDS = [
  'projectStage'
]

const SALE_FIELDS = [
  'dateOfSale',
  'sellingPrice',
  'sellingPricePerRoom'
]

const FORCED_PROJECT_FIELDS = {
  "Sale": [
    'dateOfSale',
    'sellingPrice',
    'buyerId',
    'sellerId'
  ],

  "New Construction": [
    'projectStage',
    'scheduledOpen',
    'startDate',
    'architectId',
    'contractorId',
    'designerId',
    'purchaserId',
  ],

  "Conversion": [
    'projectStage',
    'scheduledOpen',
    'startDate',
    'newFranchiseName',
    'architectId',
    'contractorId',
    'designerId',
    'purchaserId',
  ],

  "Renovation": [
    'projectStage',
    'scheduledOpen',
    'startDate',
    'architectId',
    'contractorId',
    'designerId',
    'purchaserId',
  ],

  "Distressed Asset": [
    'assetStatus',
    'servicerId',
    'lenderId',
    'receiverId',
  ],
}

const interestingFieldsOrder = [
  'address1',
  'brandName',
  'newFranchiseName',
  'projectStage',
  'startDate',
  'scheduledOpen',
  'sale',
  'devOwnCompanyId',
  'devOwnContactId',
  'managementCompanyId',
  'managementContactId',
  'architectId',
  'architectContactId',
  'contractorId',
  'contractorContactId',
  'designerId',
  'designerContactId',
  'purchaserId',
  'purchaserContactId',
  'buyerId',
  'buyerContactId',
  'sellerId',
  'sellerContactId',
  'lenderId',
  'lenderContactId',
  'receiverId',
  'receiverContactId',
  'servicerId',
  'servicerContactId',
]

const getTimeRange = (lastModified) => {
  if (Moment(lastModified).isBetween(
    Moment().subtract(8, "days"),
    Moment()
  )) {
    return {
      value: 7,
      label: "watch.list.changed.in.last.seven.days"
    }
  }
  if (Moment(lastModified).isBetween(
    Moment().subtract(15, "days"),
    Moment().subtract(8, "days")
  )) {
    return {
      value: 14,
      label: "watch.list.changed.eight.fourteen.days.ago"
    }
  }
  if (Moment(lastModified).isBetween(
    Moment().subtract(31, "days"),
    Moment().subtract(15, "days")
  )) {
    return {
      value: 30,
      label: "watch.list.changed.fifteen.thirty.days.ago"
    }
  }
  if (Moment(lastModified).isBetween(
    Moment().subtract(61, "days"),
    Moment().subtract(31, "days")
  )) {
    return {
      value: 60,
      label: "watch.list.changed.thirty.sixty.days.ago"
    }
  }
  if (Moment(lastModified) < Moment().subtract(61, "days")) {
    return {
      value: Infinity,
      label: "watch.list.unchanged.in.past.sixty.days"
    };
  }
};

const getCompany = (item, field) => {
  const companyFieldNames = {
    'devOwnCompanyId': 'developer',
    'managementCompanyId': 'manager',
    'purchaserId': 'purchaser',
    'sellerId': 'seller',
    'buyerId': 'buyer',
    'receiverId': 'receiver',
    'servicerId': 'servicer',
    'contractorId': 'contractor',
    'architectId': 'architect',
    'designerId': 'designer',
    'lenderId': 'lender'
  }
  const companyFieldName = companyFieldNames[field];
  if (!companyFieldName) {
    return null;
  }
  return item[companyFieldName];
}


const getContact = (item, field) => {
  const companyFieldNames = {
    'devOwnContactId': 'developerContact',
    'managementContactId': 'managementContact',
    'purchaserContactId': 'purchaserContact',
    'sellerContactId': 'sellerContact',
    'buyerContactId': 'buyerContact',
    'receiverContactId': 'receiverContact',
    'servicerContactId': 'servicerContact',
    'contractorContactId': 'contractorContact',
    'architectContactId': 'architectContact',
    'designerContactId': 'designerContact',
    'lenderContactId': 'lenderContact'
  }
  const companyFieldName = companyFieldNames[field];
  if (!companyFieldName) {
    return null;
  }
  return item[companyFieldName];
}


const getProjectTypeTranslation = (project, t) => {
  if (project.projectType === "Conversion" && project.newFranchiseNameNew) {
    return t("watch.list.project.type.conversion.to", { brandName: project.newFranchiseNameNew });
  }
  return t(projectTypeTranslationKeys[project.projectType]);
}

const getNewProjectTypeTranslation = (project, t) => {
  if (project.projectType === "Conversion" && project.newFranchiseNameNew) {
    return t("watch.list.project.type.newConversion.to", { brandName: project.newFranchiseNameNew });
  }
  return t(newProjectTypeTranslationKeys[project.projectType]);
}

const getProjectSubheading = (lastModified, t) => (project) => {
  if (!project.projectType) {
    return null;
  }
  const projectTimeRange = getTimeRange(project.createdAt).value;
  const propertyTimeRange = getTimeRange(lastModified).value;
  const isNewProject = propertyTimeRange !== Infinity && (projectTimeRange <= 30 || projectTimeRange === propertyTimeRange);
  if (isNewProject) {
    return getNewProjectTypeTranslation(project, t)
  }
  return getProjectTypeTranslation(project, t);
};

const projectTypeTranslationKeys = {
  'Sale': 'watch.list.project.type.sale',
  'Distressed Asset': 'watch.list.project.type.distressed',
  'Conversion': 'watch.list.project.type.conversion.label',
  'Renovation': 'watch.list.project.type.renovation',
  'New Construction': 'watch.list.project.type.new.construction',
}

const newProjectTypeTranslationKeys = {
  'Sale': 'watch.list.project.type.newSale',
  'Distressed Asset': 'watch.list.project.type.newDistressed',
  'Conversion': 'watch.list.project.type.newConversion.label',
  'Renovation': 'watch.list.project.type.newRenovation',
  'New Construction': 'watch.list.project.type.new.newConstruction',
}

export {
  DAYS_7,
  DAYS_14,
  DAYS_30,
  DAYS_60,
  DAYS_EARLIER,
  NOT_CHANGED,
  INTERESTING_FIELDS_PROPERTY,
  INTERESTING_FIELDS_PROJECTS,
  DATE_FIELDS,
  PRICE_FIELDS,
  OLD_VALUE_FIELDS,
  SALE_FIELDS,
  FORCED_PROJECT_FIELDS,
  projectTypeTranslationKeys,
  interestingFieldsOrder,
  getProjectSubheading,
  getCompany,
  getContact,
  getTimeRange
};
