import Moment from "moment";
import Connect from "../../helpers/Connect";
import Const from '../../helpers/Const';
import { formatNumber, isMobile } from "../../helpers/Utils";
import { CLabel } from "../CLabel";
import CPeriodFilter, { LAST_LOGIN, LAST_MONTH, LAST_WEEK } from "./CPeriodFilter.js";
import { onScrollTooltipPosition } from "../../helpers/UtilsHelpers";
import { useEffect } from "react";

const searchValues = {
  "pipeline": {
    "propertyType": ["PIPELINE"]
  },

  "showRenovationsAndConversions": {
    project: {
      newProjectType: ["RENOVATION", "CONVERSION"]
    }
  },

  "updated": {
    "updated": true,
    "interestingOnly": true,
    project: {
      "updated": true,
      "interestingOnly": true
    }
  }
}

const days = {
  [LAST_WEEK]: 7,
  [LAST_MONTH]: 30,
}

export default Connect(({ router, showTooltip, onTooltip, properties, period = LAST_WEEK, initSearchForm, addValueToForm, lastLogin }) => {

  const source = {
    [LAST_WEEK]: properties?.lastWeek,
    [LAST_MONTH]: properties?.month,
    [LAST_LOGIN]: properties?.lastLogin,
  }

  const pipeline = source[period]?.pipeline
  const census = source[period]?.census
  const updated = source[period]?.updated

  const gotoResults = (propertyType) => {

    const daysAgo = period != LAST_LOGIN ? days[period] : Math.floor(Moment.duration(Moment().diff(Moment(lastLogin))).asDays());
    const searchForm = {
      isToggleable: true,
      enabled: true,
      mode: 'DAYS',
      daysAgo: daysAgo,
      invalid: false,
      values: searchValues[propertyType]
    };

    initSearchForm();
    addValueToForm("propertyChanges", searchForm);
    router.push('/results')
  };

  useEffect(() => {
    const onScrollFn = onScrollTooltipPosition("properties-tooltip", "properties-tooltip-anchor");
    document.querySelector('.main-content').addEventListener('scroll', onScrollFn);
    return () => {
      document.querySelector('.main-content').removeEventListener('scroll', onScrollFn);
    }
  }, [])

  return (
    <div className="tile-group">
      <div className="tile-title">
        <div className="box-btn box-right" onClick={isMobile() ? onTooltip : undefined}>
          <a id="properties-tooltip-anchor" className="btn btn-info tooltip-bottom">
            {!isMobile() && <span span id="properties-tooltip" className="tooltiptext"><CLabel k="home.properties.info" /></span>}
            {isMobile() && <span id="properties-tooltip" className="tooltiptext"><CLabel k="home.properties.info" /></span>}
            <i className="fa fa-info"></i>
          </a>
        </div>
        <div className="flex-wrap">
          <div className="name-block box-inline"><span className="upper bold"><CLabel k="home.properties.title" /></span></div>{properties.totalCount && (<span style={{ whiteSpace: "nowrap" }}>(<CLabel k="home.properties.total" param={{ count: formatNumber(properties.totalCount) }}/>)</span>)}
        </div>
      </div>
      <CPeriodFilter period={period} />
      <div className="info-count info-count-middle"><span style={{ cursor: "pointer" }} onClick={() => gotoResults("updated")}><span className="count">{formatNumber(updated)}</span><CLabel k="home.properties.updated.records" /></span></div>
      <div className="info-count info-count-middle"><span style={{ cursor: "pointer" }} onClick={() => gotoResults("showRenovationsAndConversions")}><span id="renovationsAndConversionsCount" className="count">{formatNumber(census)}</span><CLabel k="home.properties.new.census.records" /></span></div>
      <div className="info-count info-count-middle"><span style={{ cursor: "pointer" }} onClick={() => gotoResults("pipeline")}><span className="count">{formatNumber(pipeline)}</span><CLabel k="home.properties.new.pipeline.records" /></span></div>
    </div>
  )
}, {
  state: (state) => ({
    properties: (state.app?.properties || {}),
    lastLogin: state.app?.properties?.lastLoginDate,
    period: state.watches?.period,
  }),

  dispatch: (dispatch) => ({
    addValueToForm: (name, value) => dispatch({ type: 'ADD_VALUE_TO_SEARCH_FORM', name: name, value: value }),
    initSearchForm: () => dispatch({ type: 'INIT_SEARCH_FORM', searchType: Const.SEARCH_TYPE_PROPERTIES }),
    changePeriodFilter: (period) => dispatch({ type: 'CHANGE_PERIOD_FILTER', period: period }),
  })
}
);
