CNoteText = require './CNoteText'
CNoteDates = require './CNoteDates'
Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'
{ API } = require '../../redux/Store'
{ AccessRestriction } = require "../AccessRestriction"
{ createRef } = require 'react'
{ isContentAdmin } =  require "../../helpers/UserUtils";
Utils = require '../../helpers/Utils'
{ checkActionConfirmationForAA, ACTION_TYPES } = require '../../helpers/UtilsHelpers'
{ withTranslation } = require 'react-i18next'
{ createPortal } = require 'react-dom';

#props.note
#props.truncated
#props.onDeleted
#props.onChanged
module.exports = withTranslation() Connect createReactClass(
  displayName: 'CItemNote'

  getInitialState: ()->
    showMenuDropdown: false
    note: @props.note

  handleClickOutside: (event)->
    if @state.showMenuDropdown
      @toggleNoteMenu()

  componentWillReceiveProps: (newProps)->
    @setState note: newProps.note

  componentWillUnmount: ()->
    document.removeEventListener('click', @handleClickOutside, true);

  componentDidMount: ()->
    document.addEventListener('click', @handleClickOutside, true);

  componentWillReceiveProps: (nextProps)->
    @setState note: nextProps.note

  toggleNoteMenu: (e)->
    @setState showMenuDropdown: !@state.showMenuDropdown

  makeActive: (note, active)->
    callbackFn = () =>
      API.editNote {id: note.id, active: active}, (response) =>
        if(@props.onChanged) then @props.onChanged(response)
    if (active)
      checkActionConfirmationForAA(@props.t, note, @props.user, ACTION_TYPES.MARK_AS_NOT_DONE, @props.toggleConfirmActionPopup, callbackFn)
      return
    checkActionConfirmationForAA(@props.t, note, @props.user, ACTION_TYPES.MARK_AS_DONE, @props.toggleConfirmActionPopup, callbackFn)


  editNote: (note)->
    @props.toggleEditNotePopup(note.property, note.company, note, @props.onChanged)

  deleteNote: (note)->
    callbackFn = () =>
      API.deleteNote note.id, null, null, (response) =>
        if(@props.onDeleted) then @props.onDeleted(note)
    checkActionConfirmationForAA(@props.t, note, @props.user, ACTION_TYPES.DELETE, @props.toggleConfirmActionPopup, callbackFn)

  assignToMe: (note)->
    callbackFn = () => 
      API.editNote {id: note.id, assignedToId: @props.user.id}, (response) =>
        if(@props.onChanged) then @props.onChanged(response)
        if(@props.onChanged) then @props.onChanged(response)
    checkActionConfirmationForAA(@props.t, note, @props.user, ACTION_TYPES.REASSIGN, @props.toggleConfirmActionPopup, callbackFn)

  showHistory: (note)-> 
    @props.toggleNoteHistoryPopup(note, @props.shouldFetchHistory)

  renderMenu: (children) ->
    if Utils.isMobile()
      return createPortal(children, document.body)
    return children

  render: ->
    assignedToMe = @props.user?.id == @state.note?.assignedTo?.id
    isOwn = @props.user?.id == @state.note?.createdBy?.id
    allowEdit = isOwn || isContentAdmin(@props.user, @props.isAliasEnabled) || @props.hasAccessToAssignableActions
    allowDelete = isOwn || isContentAdmin(@props.user, @props.isAliasEnabled)

    React.createElement("div", {"className": "item-action", "key": (@props.key)},
        React.createElement("div", {"className": "box-btn"},
            React.createElement("div", {"className": "box-dropdown nav-action"},
              ((allowEdit || allowDelete) && React.createElement("a", {"className": "btn href-dropdown", "onClick": (@toggleNoteMenu)},
                  React.createElement("i", {"className": "fa fa-circle"}),
                  React.createElement("i", {"className": "fa fa-circle"}),
                  React.createElement("i", {"className": "fa fa-circle"})
              )),
             (@renderMenu(React.createElement("div", {"className": "menu-dropdown note-menu-dropdown", "style": (display: if (@state.showMenuDropdown) then "block" else "none")},
                (if @state.note.type == "ACTION"
                    React.createElement(React.Fragment, null,
                      (if allowEdit && !assignedToMe && @props.hasAccessToAssignableActions
                        React.createElement(AccessRestriction, null,
                          React.createElement("a", {"className": "btn assign-btn", "onClick": (@assignToMe.bind @, @state.note, false)},
                            React.createElement("i", {"className": "icon-hand-right-black"}),
                            React.createElement(CLabel, {"k": "actions.and.notes.assign.to.me"})
                          )
                        )
                      ),
                      (if allowEdit
                        if @state.note.active
                            React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn", "onClick": (@makeActive.bind @, @state.note, false)}, React.createElement("i", {"className": "fa fa-check icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.mark.as.done"})))
                          else
                            React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn", "onClick": (@makeActive.bind @, @state.note, true)}, React.createElement("i", {"className": "fa fa-check icon-left"}), React.createElement(CLabel, {"k": "actions.and.notes.mark.as.not.done"})))
                      )
                    )
                ),

                (if allowEdit 
                  React.createElement(AccessRestriction, null, React.createElement("a", {"className": "btn", "onClick": (@editNote.bind @, @state.note)}, React.createElement("i", {"className": "fa fa-pencil icon-left"}), React.createElement(CLabel, {"k": "property.edit"})))
                ),
                (if allowDelete
                  React.createElement(AccessRestriction, null,
                    React.createElement("a", {"className": "btn", "onClick": (@deleteNote.bind @, @state.note)},
                      React.createElement("i", {"className": "fa fa-close icon-left"}),
                      React.createElement(CLabel, {"k": "property.delete"})
                    )
                  )
                ),
                (@props.hasAccessToAssignableActions && React.createElement("a", {"className": "btn", "onClick": (@showHistory.bind @, @state.note)},
                  React.createElement("i", {"className": "icon icon-history icon-left"}),
                  React.createElement(CLabel, {"k": "actions.and.notes.showHistory"})
                ))
              ), document.body))
            )
        ),
        React.createElement(CNoteDates, {"note": (@state.note)}),
        React.createElement("div", {"className": "text"},
          React.createElement(CNoteText, {"note": (@state.note), "truncated": (@props.truncated)})
        )
    )
), {
  state: (state)->
    client: state.session?.user?.client
    user: state.session?.user
    isAliasEnabled: state.session?.isAliasEnabled
    translation: state.translation
    hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions")
  dispatch: (dispatch)-> 
    toggleEditNotePopup: (property, company, note, onChanged) -> dispatch(type: 'TOGGLE_POPUP_EDIT_NOTE', data: {property: property, company: company, note: note, onChanged: onChanged }),
    toggleConfirmActionPopup: (data) => dispatch({ type: "TOGGLE_POPUP_CONFIRM_ACTION", data: data }),
    toggleNoteHistoryPopup: (note, shouldFetchHistory) -> dispatch(type: 'TOGGLE_POPUP_NOTE_HISTORY', data: { note: note, shouldFetchHistory })
}


