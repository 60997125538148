{ extend } = require '../../helpers/UtilsHelpers'

clone = (o) ->
  extend(true, { version: Math.random() }, o)

module.exports = (getStore, getAPI) ->
  loadRecentActivity = (form) ->
    getAPI().getRecentActivity form, (data) ->
      getStore().dispatch(type: "SET_USERS_RECENT_ACTIVITY", payload: data)

  loadExportHistory = (form) ->
    getAPI().getExportHistory form, (data) ->
      getStore().dispatch(type: "SET_USERS_EXPORT_HISTORY", payload: data)

  loadAnalytics = (form) ->
    getAPI().getTrackedActivity form, (data) ->
      getStore().dispatch(type: "SET_ANALYTICS", payload: data)

  loadAnalyticsGroupSessions = (form) ->
    getAPI().getTrackedActivityGroupSessions form, (data) ->
      getStore().dispatch(type: "SET_ANALYTICS_GROUP_SESSIONS", payload: data)

  loadAnalyticsSessions = (form) ->
    getAPI().getTrackedSessions form, (data) ->
      getStore().dispatch(type: "SET_ANALYTICS_SESSIONS", payload: data)

  loadAutoExports = () ->
    getAPI().getAutoExports (data) ->
      getStore().dispatch(type: "SET_AUTO_EXPORTS", payload: data)

  saveAutoExport = (form) ->
    getAPI().saveAutoExport form, (data) ->
      getStore().dispatch(type: "SET_AUTO_EXPORTS", payload: data)

  deleteAutoExport = (exportId) ->
    getAPI().deleteAutoExport exportId, (data) ->
      getStore().dispatch(type: "SET_AUTO_EXPORTS", payload: data)

  (state = {}, action) ->
    if action.type == "GET_USERS_RECENT_ACTIVITY"
      loadRecentActivity(action.form)
      state
    else if action.type == "SET_USERS_RECENT_ACTIVITY"
      state.usersRecentActivity = action.payload
      clone state
    else if action.type == "GET_USERS_EXPORT_HISTORY"
      loadExportHistory(action.form)
      state
    else if action.type == "SET_USERS_EXPORT_HISTORY"
      state.usersExportHistory = action.payload
      clone state
    else if action.type == "GET_ANALYTICS"
      loadAnalytics(action.form)
      state
    else if action.type == "SET_ANALYTICS"
      state.analyticsData = action.payload
      clone state
    else if action.type == "GET_ANALYTICS_GROUP_SESSIONS"
      loadAnalyticsGroupSessions(action.form)
      state
    else if action.type == "SET_ANALYTICS_GROUP_SESSIONS"
      state.analyticsGroupSessionsData = action.payload
      clone state
    else if action.type == "GET_ANALYTICS_SESSIONS"
      loadAnalyticsSessions(action.form)
      state
    else if action.type == "SET_ANALYTICS_SESSIONS"
      state.analyticsSessionsData = action.payload
      clone state

    else if action.type == "GET_AUTO_EXPORTS"
      loadAutoExports()
      state
    else if action.type == "SET_AUTO_EXPORTS"
      state.autoExports = action.payload.map (autoExport) ->
        autoExport.includeNotes = autoExport.includeNotes or false
        autoExport.includeExportDate = autoExport.includeExportDate or false
        autoExport.includeCompanyIds = autoExport.includeCompanyIds or false
        autoExport
      clone state
    else if action.type == "SAVE_AUTO_EXPORT"
      saveAutoExport(action.data)
      state
    else if action.type == "DELETE_AUTO_EXPORT"
      deleteAutoExport(action.exportId)
      state
    else
      state
