import DatePicker from 'rc-calendar/lib/Picker';
import React from 'react';
import { Trans } from 'react-i18next';

const DatePickerWrapper = ({ placeholder, ...props }) => {
    return (
        <div className="general-changes-datepicker">
            <DatePicker {...props}>
                {placeholder}
            </DatePicker>
        </div>
    );
}

const ChangeMode = ({ changeMode }) => {
    return (
        <div className="general-changes-arrows-icon">
            <i className="fa fa-sort" onClick={changeMode} />
        </div>
    );
}

export const DatePickerSince = ({ placeholder, changeMode, ...props }) => {
    return (
        <div className="box-control middle general-changes-datepicker-wrapper" style={{ display: "flex"}}>
            <Trans i18nKey="search.general.changes.fast.filter.since">
                <div></div>
                <DatePickerWrapper placeholder={placeholder} {...props} /> 
                {changeMode && <ChangeMode changeMode={changeMode} />}
            </Trans>
        </div>
    );
};