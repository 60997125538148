ADMIN_TAB = 'admin'
ADMIN_TAGS_TAB = 'tags'
ADMIN_USERS_TAB = 'users'
ADMIN_NOTIFICATIONS_TAB = 'notifications'
ADMIN_EXPORTS_TAB = 'exports'
ADMIN_AUTO_EXPORTS_TAB = 'autoexports'
ADMIN_QUERIES_TAB = 'queries'


{ Link } = require 'react-router'
Connect = require '../helpers/Connect'
Header = require '../pages/parts/Header'
Menu = require('../pages/parts/Menu').default
{ API } = require '../redux/Store'
Utils = require '../helpers/Utils'
CFilterDropdown = require '../cmp/filters/CFilterDropdown'
CAdminContentTab = require '../cmp/admin/CAdminContentTab'
{ CLabel } = require '../cmp/CLabel'
CAdmin = require '../cmp/admin/CAdmin'
CAdminTags = require '../cmp/admin/CAdminTags'
CAdminUsers = require '../cmp/admin/CAdminUsers'
CAdminNotifications = require '../cmp/admin/CAdminNotifications'
CAdminExports = require '../cmp/admin/CAdminExports'
CAdminAutoExports = require '../cmp/admin/CAdminAutoExports'
CAdminQueries = require('../cmp/admin/CAdminQueries').default
Moment = require 'moment'


module.exports = Connect createReactClass(
  displayName: "Admin"

  changeTab: (name)->
    @props.setAdminTab(name)

  render: ()->
    React.createElement("div", {"className": "wrapper-content admin-page"},
      React.createElement(Header, {"title": (@props.route.title)}),
      React.createElement("div", {"className": "main"},
        React.createElement("div", {"className": "main-content"},
          React.createElement("div", {"className": "container"},
            React.createElement(Menu, {"className": "hide-mobile"}),
            React.createElement("div", {"className": "dashboard"},
              React.createElement("div", {"className": "nav-page"},
                React.createElement("div", {"className": "name"}, React.createElement(CLabel, {"k": "admin.name"}),
                  React.createElement("a", {"className": "btn btn-info tooltip-bottom", "style": ({marginTop: "4px", marginLeft: "4px", fontSize: "12px"})},
                    React.createElement("span", {"className": "tooltiptext", "style": ({width: "230px"})}, "FE: ", (APP_VERSION), " ", React.createElement("br", null), " BE: ", (@props.app?.appVersion)), React.createElement("i", {"className": "fa fa-info"})
                  )
                )
              ),

              React.createElement("div", {"className": "box-nav-tabs box-nav-tabs-admin"},
                React.createElement("ul", {"className": "nav-tabs tabs-fixed"},
                  React.createElement("li", {"className": (if @props.tab == ADMIN_TAB then "active" else "")}, React.createElement("a", {"onClick": (@changeTab.bind @, ADMIN_TAB)}, React.createElement(CLabel, {"className": "truncated", "k": "admin.tabs.update"}))),
                  React.createElement("li", {"className": (if @props.tab == ADMIN_TAGS_TAB then "active" else "")}, React.createElement("a", {"onClick": (@changeTab.bind @, ADMIN_TAGS_TAB)}, React.createElement(CLabel, {"className": "truncated", "k": "admin.tabs.tags"}))),
                  React.createElement("li", {"className": (if @props.tab == ADMIN_USERS_TAB then "active" else "")}, React.createElement("a", {"onClick": (@changeTab.bind @, ADMIN_USERS_TAB)}, React.createElement(CLabel, {"className": "truncated", "k": "admin.tabs.users.label"}))),
                  React.createElement("li", {"className": (if @props.tab == ADMIN_NOTIFICATIONS_TAB then "active" else "")}, React.createElement("a", {"onClick": (@changeTab.bind @, ADMIN_NOTIFICATIONS_TAB)}, React.createElement(CLabel, {"className": "truncated", "k": "admin.tabs.notifications"}))),
                  React.createElement("li", {"className": (if @props.tab == ADMIN_EXPORTS_TAB then "active" else "")}, React.createElement("a", {"onClick": (@changeTab.bind @, ADMIN_EXPORTS_TAB)}, React.createElement(CLabel, {"className": "truncated", "k": "admin.tabs.exports"}))),
                  React.createElement("li", {"className": (if @props.tab == ADMIN_AUTO_EXPORTS_TAB then "active" else "")}, React.createElement("a", {"onClick": (@changeTab.bind @, ADMIN_AUTO_EXPORTS_TAB)}, React.createElement(CLabel, {"className": "truncated", "k": "admin.tabs.auto.exports"}))),
                  React.createElement("li", {"className": (if @props.tab == ADMIN_QUERIES_TAB then "active" else "")}, React.createElement("a", {"onClick": (@changeTab.bind @, ADMIN_QUERIES_TAB)}, React.createElement(CLabel, {"className": "truncated", "k": "admin.tabs.auto.queries"})))
                )
              ),

              React.createElement("div", {"className": "box-tile"},
                React.createElement("div", {"className": "block-tabs"},
                  React.createElement(CAdminContentTab, {"tabName": (@props.tab)},
                    (if @props.tab == ADMIN_TAB
                      React.createElement(CAdmin, null)
                    else if @props.tab == ADMIN_TAGS_TAB
                      React.createElement(CAdminTags, null)
                    else if @props.tab == ADMIN_USERS_TAB
                      React.createElement(CAdminUsers, null)
                    else if @props.tab == ADMIN_NOTIFICATIONS_TAB
                      React.createElement(CAdminNotifications, null)
                    else if @props.tab == ADMIN_EXPORTS_TAB
                      React.createElement(CAdminExports, null)
                    else if @props.tab == ADMIN_AUTO_EXPORTS_TAB
                      React.createElement(CAdminAutoExports, null)
                    else if @props.tab == ADMIN_QUERIES_TAB
                      React.createElement(CAdminQueries, null)
                    )
                  )
                )
              )
            )
          )
        )
      )
    )

), {
  dispatch: (dispatch) ->
    login: (session)-> dispatch(type: 'LOGIN', payload: session)
    setAdminTab: (name)-> dispatch(type: 'SET_ADMIN_TAB', name: name)

  state: (state) ->
    user: state.session?.user
    tab: state.app.tab
    app: state.app
}


