Connect = require '../../helpers/Connect'
{ CLabel } = require '../../cmp/CLabel'
Utils = require '../../helpers/Utils'
{ API } = require '../../redux/Store'
{ withTranslation, Trans } = require 'react-i18next' 
{ extend } = require '../../helpers/UtilsHelpers'
{ shouldUseMiles } = require '../../helpers/LocaleHelpers'
{ CInputWrapper } = require '../../cmp/CInputWrapper'
TextareaAutosize = (require 'react-textarea-autosize').default

module.exports = withTranslation() Connect createReactClass(
  displayName: "CProximityFilterElement"
  location: {}

  getInitialState: ()->
    value: @props.value || extend(true, {}, {
      location:
        latitude: null
        longitude: null
      address: null
      radius: null
      unit: if shouldUseMiles(@props.i18n.resolvedLanguage) then "M" else "Km"
    })
    recognized: @isRecognized(@props.value),
    loading: true

  isRecognized: (value)->
    if value then value.location?.latitude && value.location?.longitude else true

  componentDidMount: ()->

    input = @refs.locationInput
    addEventListener = if input.addEventListener then input.addEventListener else input.attachEvent

    addEventListenerWrapper = (type, listener) ->
      if type == 'keydown'
        origListener = listener
        listener = (event) ->
          suggestion_selected = document.querySelector(".pac-item-selected") != null
          if event.which == 13 and !suggestion_selected
            simulated_downarrow = new KeyboardEvent("keydown", {
                keyCode: 40,
                which: 40
            })
            origListener.apply(input, [simulated_downarrow])

          origListener.apply(input, [event])

      addEventListener.apply(input, [type, listener])

    input.addEventListener = addEventListenerWrapper
    input.attachEvent = addEventListenerWrapper

    @autocomplete = if window.google?.maps?.places.SearchBox then new google.maps.places.SearchBox(input) else undefined
    @autocomplete?.addListener('places_changed', @onSelected)
    @setState loading: false

  onSelected: ()->
    place = @autocomplete?.getPlaces()?[0]

    if place?.geometry?.location
      value = @state.value
      value.address =  place.formatted_address
      @refs.locationInput.value = value.address
      value.location =
        latitude: place?.geometry?.location?.lat?()
        longitude: place?.geometry?.location?.lng?()
      @setState value: value, recognized: true
      @handleChange()

  selectedDefaultLocation: ()->
    value = @state.value
    value.address = @refs.locationInput.value
    value.location =
      latitude: null
      longitude: null
    @setState value: value, recognized: false

  handleChange: (forceChange = false)->
    value = @state.value
    if value?.location?.latitude && value?.location?.longitude && value?.radius
      @props.onChange?(@props.name, value, false)
    else if forceChange
      @props.onChange?(@props.name, value, false)

  componentWillReceiveProps: (newProps)->
    if newProps.value
      @setState value: newProps.value, recognized: if newProps.value then @isRecognized(newProps.value)
    else
      initialValueState = extend(true, {}, {
        location:
          latitude: null
          longitude: null
        address: null
        radius: null
        unit: if shouldUseMiles(@props.i18n.resolvedLanguage) then "M" else "Km"
      })
      @setState value: initialValueState, recognized: false
    @refs.locationInput.value = newProps.value?.address || ""

  changeUnits: ()->
    unit = if @state.value?.unit == "M" then "Km" else "M"
    value = @state.value
    forceChange = value.unit && unit == ""
    value.unit = unit
    @setState value: value
    @handleChange(forceChange)

  changeRadius: (e)->
    value = @state.value
    value.radius = Number(e.target.value)
    @setState value: value
    @handleChange(true)

  onBlur: ()->
    @props.onBlur()

  onInput: (e)->
    v = e.target
    if v.value.length > v.maxLength
      v.value = v.value.slice(0, v.maxLength)

  changeAddress: (e)->
    isVisible = document.querySelector(".pac-container").style.display != "none"
    if isVisible && !@state.recognized
      value = @state.value
      value.address = e.target.value
      @setState recognized: !!value, value: value
    else if !isVisible && @state.recognized
      @selectedDefaultLocation()
    if value
      @props.onChange?(@props.name, value, false)
    else
      @selectedDefaultLocation()
    @handleChange(true)

  isValidRadius: (radius)->
    n = Math.floor(radius)
    String(n) == String(radius) && n >= 0

  render: ->
    React.createElement("div", {"className": "box-form-group box-form-proximity"},
      React.createElement("div", {"className": "box-control #{if !@autocomplete then 'disabled'}"},
        React.createElement("div", {"className": "box-control-proximity"},
          React.createElement(Trans, {"i18nKey": (@props.description || "search.company.office.located.within"), "values": ({
            context: @state.value?.unit
          })},
            React.createElement(CInputWrapper, {"onBlur": (@onBlur), "onInput": (@onInput), "defaultValue": (@state.value?.radius || ""), "value": (@state.value?.radius || ""), "maxLength": "4", "min": "1", "max": "9999", "onChange": (@changeRadius), "ref": "radius", "type": "number", "className": "form-control small"}),
            React.createElement("a", {"className": "link link-change active", "onClick": (@changeUnits)})
          ),
          (" "),
          React.createElement("div", {"className": "box-control-dropdown google-box-control"},
            React.createElement("div", {"className": "box-dropdown usual"},
              React.createElement(TextareaAutosize, { \
                "ref": "locationInput",  \
                "rows": "1",  \
                "wrap": "soft",  \
                "className": "form-control google-form-control  textarea-control",  \
                "name": (@props.name+"_address"),  \
                "defaultValue": (@state.value?.address || ""),  \
                "value": (@state.value?.address || ""),  \
                "placeholder": (@props.t("search.additional.property.details.proximity.location")),  \
                "onChange": (@changeAddress)
              })
            )
          ),
          (if @props.form?[@props.name]
            if @state.value?.radius && !@isValidRadius(@state.value.radius)
              React.createElement("div", {"className": "unrecognized-address-warning"},
              React.createElement(CLabel, {"k": "search.additional.property.invalid.number"})
              )
            else if @state.value?.radius && !@state.value?.address
              React.createElement("div", {"className": "unrecognized-address-warning"},
                React.createElement(CLabel, {"k": "search.additional.property.specify.location"})
              )
            else if !@state.value?.radius
              React.createElement("div", {"className": "unrecognized-address-warning"},
                React.createElement(CLabel, {"k": "search.additional.property.specify.distance"})
              )
            else if !@state.recognized
              React.createElement("div", {"className": "unrecognized-address-warning"},
                React.createElement(CLabel, {"k": "search.additional.property.details.proximity.location.not.recognized"})
              )
          )
        )
      )

    )
),
  state: (state) ->
    translation: state.translation
    form: state.searchForm?.form
