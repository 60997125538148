import React, { useEffect, useState } from 'react';
import './CDateRange.styles.scss';
import CDateRangeDialog from "./CDateRangeDialog";
import * as Moment from 'moment';
import Connect from "../../../helpers/Connect";
import { CLabel } from '../../CLabel';
import { useTranslation } from 'react-i18next';
import { getDateLocale } from "../../../helpers/LocaleHelpers";

const options = {
    ANYTIME: 'ANYTIME',
    IN_LAST_DAYS: 'IN_LAST_DAYS',
    BETWEEN: 'BETWEEN'
}

const initialState = {
    selectedOption: options.ANYTIME,
    dateFrom: null,
    dateTo: null,
    days: 60
};

const CDateRange = ({ onChange, controlValue, label }) => {
    
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const [value, setValue] = useState(controlValue || initialState);
    

    useEffect(() => {
        if (!controlValue) {
            setValue(initialState);
        }
    }, [controlValue]);

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const onConfirm = (value) => {
        setValue(value);
        if (value.selectedOption === options.ANYTIME) {
            onChange({
                updatedAfter: null,
                updatedBefore: null,
                controlValue: value
            });
        }
        if (value.selectedOption === options.IN_LAST_DAYS) {
            const updatedAfter = Moment().tz('UTC').startOf('day').subtract(value.days, 'd').format()
            const updatedBefore = Moment().tz('UTC').endOf('day').format()
            onChange({
                updatedAfter: updatedAfter,
                updatedBefore: updatedBefore,
                controlValue: value
            });
        }
        if (value.selectedOption === options.BETWEEN) {
            onChange({
                updatedAfter: value.dateFrom,
                updatedBefore: value.dateTo,
                controlValue: value
            });
        }
        closeModal();
    };

    const controlLabel = (() => {
        if (value.selectedOption === options.ANYTIME) {
            return t("controls.date.range.button.anytime");
        }
        if (value.selectedOption === options.IN_LAST_DAYS) {
            return t("controls.date.range.button.in.the.last.days", {
                days: value.days
            });
        }
        if (value.selectedOption === options.BETWEEN) {
            const language = getDateLocale(i18n.resolvedLanguage);
            return t("controls.date.range.button.between.dates", {
                dateFrom: Moment(value.dateFrom).tz('UTC').locale(language).format("ll"),
                dateTo: Moment(value.dateTo).tz('UTC').locale(language).format("ll")
            });
        }
    })();

    const labelClass = value.selectedOption !== options.ANYTIME ? "bold" : null;

    return <>
        {label && <span className={labelClass}>
            <CLabel k={label} />
        </span>}
        <a onClick={openModal}>{controlLabel}</a>
        {open && <CDateRangeDialog
            closeModal={closeModal}
            onConfirm={onConfirm}
            value={value}
        />}
    </>
};

export default Connect(CDateRange, {
    state: (state) => {
        return {
            translation: state.translation,
        }
    }
});

