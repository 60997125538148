import { useState, useEffect } from "react";
import Connect from '../helpers/Connect'
import CSavedSearches from '../cmp/home/CSavedSearches'
import CReports from '../cmp/home/reports/CReports'
import CCharts from "../cmp/home/charts/CCharts";
import CStatsProperties from "../cmp/home/CStatsProperties";
import CStatsWatch from "../cmp/home/CStatsWatch";
import An from "../helpers/Analytics";
import Header from "./parts/Header.cjsx";
import Menu from "./parts/Menu";
import CStatsActions from "../cmp/home/CStatsActions";
import { throttle } from "throttle-debounce";
import { LAST_WEEK } from "../cmp/home/CPeriodFilter";

export default Connect(({ route, changePeriodFilter }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    An.main.home();
  }, []);

  useEffect(() => {
    const onScroll = throttle(500, () => setShowTooltip(false));
    document.querySelector(".main-content").addEventListener("scroll", onScroll);
    return () => {
      document.querySelector(".main-content").removeEventListener("scroll", onScroll);
      changePeriodFilter(LAST_WEEK)
    };
  }, []);

  return (
    <div className="wrapper-content">
      <Header title={route.title} />
      <div className="main home-page">
        <div className="main-content">
          <div className="container">
            <Menu className="hide-mobile" />
            <div className="dashboard home">
              <div className="dashboard-group clearfix">
                <div className="flex-row">
                  <div className="block-tile row-half">
                    <div className="box-tile">
                      <CStatsProperties showTooltip={showTooltip} onTooltip={() => setShowTooltip(true)}/>
                      <CStatsWatch showTooltip={showTooltip} onTooltip={() => setShowTooltip(true)}/>
                      <CStatsActions showTooltip={showTooltip} onTooltip={() => setShowTooltip(true)}/>
                    </div>
                  </div>
                  <CCharts showTooltip={showTooltip} onTooltip={() => setShowTooltip(true)}/>
                </div>
                <CReports showTooltip={showTooltip} onTooltip={() => setShowTooltip(true)}/>
                <CSavedSearches showTooltip={showTooltip} onTooltip={() => setShowTooltip(true)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}, {
  state: (state) => ({
    // client: state && state.session && state.session.user && state.session.user.client
  }),

  dispatch: (dispatch) => ({
    changePeriodFilter: (period) => dispatch({ type: 'CHANGE_PERIOD_FILTER', period: period }),
  })
});
