Connect = require '../../helpers/Connect'
{ CLabel } = require "../CLabel"
{ CInput } = require '../CInput'
Moment = require 'moment'
Calendar = require('rc-calendar').default
LocaleEnUS = require 'rc-calendar/lib/locale/en_US'
DatePicker = require 'rc-calendar/lib/Picker'
{ API } = require '../../redux/Store'
{ createIframeForDownload } = require '../../helpers/Utils'
{ withTranslation } = require 'react-i18next'


module.exports = withTranslation() Connect createReactClass(
  displayName: "CAdminExports"

  getInitialState: ->
    clientName: ''
    firstName: ''
    lastName: ''
    format: ''
    method: ''
    status: ''
    dateFrom: null
    dateTo: null
    usersExportHistory: @props.usersExportHistory

  componentDidMount: ->
    @props.getExportHistory({})

  componentWillReceiveProps: (newProps)->
    if newProps.usersExportHistory != @state.usersExportHistory
      @setState usersExportHistory: newProps.usersExportHistory

  onChangeFilter: (e)->
    @state[e.target.name] = e.target.value
    @updateExportHistory()

  updateExportHistory: ->
    form =
      clientName: @state.clientName
      firstName: @state.firstName
      lastName: @state.lastName
      format: @state.format
      method: @state.method
      status: @state.status
      dateFrom: @state.dateFrom
      dateTo: @state.dateTo
    @props.getExportHistory(form)

  setDateFrom: (moment)->
    @state.dateFrom = moment?.format()
    @setState dateFrom: @state.dateFrom
    @updateExportHistory()

  setDateTo: (moment)->
    @state.dateTo = moment?.format()
    @setState dateTo: @state.dateTo
    @updateExportHistory()

  calendarPlaceHolder: (name, value)->
    React.createElement("span", {"tabIndex": "0", "className": "pointer"},
      React.createElement("i", {"className": "fa fa-fw fa-calendar"}),
      React.createElement("span", null, (if @state[name] then Moment(@state[name]).format("[MMM-DD]") else React.createElement(CLabel, {"k": ("admin.tabs.users.recent.activity.filter.date." + name.substring(4,).toLowerCase())})))
    )

  calendarPlaceHolderFrom: (value)->
    @calendarPlaceHolder('dateFrom', value)

  calendarPlaceHolderTo: (value)->
    @calendarPlaceHolder('dateTo', value)

  createCalendar: (name)->
    React.createElement(Calendar, { \
      "locale": (LocaleEnUS),  \
      "dateInputPlaceholder": (@props.t("admin.tabs.users.recent.activity.filter.please.input", { ns: "internal_ui" })),  \
      "formatter": 'YYYY-MM-DD',  \
      "showDateInput": (true),  \
      "defaultValue": (@state[name] && Moment(@state[name]))
    })

  exportUserExportHistory: (e)->
    params = "&clientName=#{@state.clientName}"
    if @state.firstName
      params += "&firstName=#{@state.firstName}"
    if @state.lastName
      params += "&lastName=#{@state.lastName}"
    if @state.format
      params += "&format=#{@state.format}"
    if @state.status
      params += "&status=#{@state.status}"
    if @state.method
      params += "&method=#{@state.method}"
    if @state.dateFrom
      params += "&dateFrom=#{@state.dateFrom.substring(0, 10)}"
    if @state.dateTo
      params += "&dateTo=#{@state.dateTo.substring(0, 10)}"
    createIframeForDownload(API.exportUserExportHistory(params))

  render: ->
    calendarDateFrom = @createCalendar('dateFrom')
    calendarDateTo = @createCalendar('dateTo')
    React.createElement("div", {"className": "block-tile"},
      React.createElement("div", {"className": "box-tile"},
        React.createElement("div", {"className": "tile-group"},
          React.createElement("form", {"className": "form form-small"},
            React.createElement("div", {"className": "box-control clearfix"},
              React.createElement("div", {"className": "row-half name-block upper", "style": (fontSize: '1em')}, React.createElement(CLabel, {"k": "admin.tabs.users.export.history.label"})),
              React.createElement("div", {"className": "box-right"},
                React.createElement("a", {"className": "btn link-grey", "onClick": (@exportUserExportHistory)}, React.createElement("i", {"className": "fa fa-clipboard icon-left"}), React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.export"}))
              )
            ),
            React.createElement("div", {"className": "box-control clearfix"},
              React.createElement("div", {"className": "row-20"},
                React.createElement(DatePicker, { \
                  "animation": "slide-up",  \
                  "calendar": (calendarDateFrom),  \
                  "name": 'dateFrom',  \
                  "value": (@state.dateFrom && Moment(@state.dateFrom)),  \
                  "onChange": (@setDateFrom)
                },
                  (@calendarPlaceHolderFrom)
                )
              ),
              React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.first.name", { ns: 'internal_ui' })), "name": 'firstName', "className": "form-control", "type": 'text', "value": (@state.firstName)})),
              React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.client.name", { ns: 'internal_ui' })), "name": 'clientName', "className": "form-control", "type": 'text', "value": (@state.clientName)})),
              React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.method", { ns: 'internal_ui' })), "name": 'method', "className": "form-control", "type": 'text', "value": (@state.method)})),
              React.createElement("div", {"className": "clear-left"},
                React.createElement("div", {"className": "row-20"},
                  React.createElement(DatePicker, { \
                    "animation": "slide-up",  \
                    "calendar": (calendarDateTo),  \
                    "name": 'dateTo',  \
                    "value": (@state.dateTo && Moment(@state.dateTo)),  \
                    "onChange": (@setDateTo)
                  },
                    (@calendarPlaceHolderTo)
                  )
                ),
                React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.last.name", { ns: 'internal_ui' })), "name": 'lastName', "className": "form-control", "type": 'text', "value": (@state.lastName)})),
                React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.format", { ns: 'internal_ui' })), "name": 'format', "className": "form-control", "type": 'text', "value": (@state.format)})),
                React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.status", { ns: 'internal_ui' })), "name": 'status', "className": "form-control", "type": 'text', "value": (@state.status)}))
              )
            )
          ),
          React.createElement("div", {"className": "flex-table"},
            React.createElement("div", {"className": "flex-tr flex-th"},
              React.createElement("div", {"className": "flex-td flex-td-15"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.start"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.duration"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.user"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.client"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.records.label"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.records.exported"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.format"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.method"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.size"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.export.history.status"})
              )
            ),
            (@state.usersExportHistory?.map (exportHistory, idx)->
              React.createElement("div", {"className": "flex-tr", "key": (idx)},
                React.createElement("div", {"className": "flex-td flex-td-15"},
                  (exportHistory.startTime && Moment(exportHistory.startTime).format('YYYY-MM-DD hh:mm'))
                ),
                React.createElement("div", {"className": "flex-td"},
                  (exportHistory.duration)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (exportHistory.userName)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (exportHistory.clientName)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (exportHistory.recordsCount)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (exportHistory.recordsExported)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (exportHistory.format)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (exportHistory.method)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (exportHistory.size)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (if (exportHistory.status == 'FINISHED')
                      React.createElement("a", {"href": (API.downloadExport("&exportId=#{exportHistory.id}")), "target": "_blank"}, (exportHistory.status))
                    else
                      exportHistory.status
                  )
                )
              )

            )
          )
        )
      )
    )
), {
  state: (state)->
    usersExportHistory: state.admin.usersExportHistory
    translation: state.translation
  dispatch: (dispatch)->
    getExportHistory: (form)-> dispatch(type: "GET_USERS_EXPORT_HISTORY", form: form)
}