
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router'
import '../../../assets/style/menu.css'
import { CLabel } from '../../cmp/CLabel'
import CMenuFilters from '../../cmp/CMenuFilters'
import An from '../../helpers/Analytics'
import Connect from '../../helpers/Connect'
import Const from '../../helpers/Const.coffee'
import Utils from '../../helpers/Utils'

export default Connect(({
  actionsToDo,
  className,
  mainMenuOpened,
  router,
  client,
  user,
  recentView,
  conciergeInfo,
  applicationSettings,
  sharedFilters,
  sharedSearchesIsOpened,
  savedSearchesIsOpened,
  recentlyViewedIsOpened,
  session,
  savedFilters,
  hideMainMenu,
  showMainMenu,
  toggleMainMenu,
  toggleWpSso,
  initSearchForm,
  toggleSharedSearchesMenu,
  toggleSavedSearchesMenu,
  toggleRecentlyViewedMenu,
  setForm,
  logout }) => {
  const { t } = useTranslation();

  const noteCount = actionsToDo && (actionsToDo.thisWeek || 0) + (actionsToDo.overdue || 0) || 0

  const onResize = () => {
    if (window.innerWidth < 1024 || Utils.isMobile()) {
      hideMainMenu()
      return;
    }
    showMainMenu();
  }

  useEffect(() => {
    if (window.innerWidth < 1024 || Utils.isMobile()) hideMainMenu()

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  useEffect(() => {
    window.hdnOnBackPressed = () => {
      window.react_router = router;
      if (mainMenuOpened) {
        hideMainMenu()
        return true
      }
      if (router.getCurrentLocation().pathname === "/") {
        window.hdn_window.finish()
        return true;
      }
      return true
    }

    return () => {
      window.hdnOnBackPressed = undefined
    }
  }, [mainMenuOpened, router])

  const isActive = (path) => (window.location.href.endsWith(path) ? "active" : "");
  const toggleMainMenu2 = () => (Utils.isMobile() ? toggleMainMenu() : onResize())

  const setFilterForm = (filter) => {
    toggleMainMenu2()
    if (!router.location.pathname.startsWith("/results")) setForm(filter)
    An.search.savedSearch()
    router.push("/results/" + filter.filterId)
  }

  const openRecent = (element) => {
    if (element.type == "filter") {
      const filter = (savedFilters || []).filter(f => (f.filterId == element.id))
      if (filter.length > 0) setFilterForm(filter[0])
    } else {
      toggleMainMenu2()
      router.push("/property/" + element.id)
    }
  }

  const openWpSsoMobile = (e) => {
    An.main.library()
    toggleMainMenu2()
    e.preventDefault()
    e.stopPropagation()
    if (applicationSettings && session && applicationSettings.librarySiteSso && session.jwtToken) {
      toggleWpSso(applicationSettings.librarySiteSso + session.jwtToken);
    }
  }

  const clearSearchAndNavigate = (path, storageKey) => () => {
    sessionStorage.removeItem(Const.ACTIONS_AND_NOTES_FILTER_STATE);
    sessionStorage.removeItem(Const.WATCH_LIST_FILTER_STATE);
    initSearchForm(storageKey)
    router.push(path)
  }

  const generateMailTo = () => {
    const conciergeEmail = client && client.conciergeEmail
    const CONCIERGE_EMAIL = conciergeInfo && conciergeInfo[conciergeEmail] && conciergeInfo[conciergeEmail].CONCIERGE_EMAIL || Const.DEFAULT_CONCIERGE_INFO.EMAIL
    const CONCIERGE_PHONE = conciergeInfo && conciergeInfo[conciergeEmail] && conciergeInfo[conciergeEmail].CONCIERGE_PHONE || Const.DEFAULT_CONCIERGE_INFO.PHONE
    const message = t('menu.concierge.starter', { CONCIERGE_PHONE });
    return session && session.user 
      ? Utils.generateMailTo(CONCIERGE_EMAIL, t("menu.concierge.request.from", {
        from: [session.user.firstName, ' ', session.user.lastName, ', ', client && client.name].join("")
      }), message) 
      : ''
  }

  return (
    <div className={"block-nav " + className + (mainMenuOpened ? " open more-menu" : "")}>
      <div className="main-navigate">
        <div className="box-nav main-mobile">
          <ul className="menu-main menu-main-mobile">
            <li className={isActive("/home")}><Link className="menu-item" to="/home"><i className="icon icon-home icon-middle"></i><CLabel k="menu.home" /></Link></li>
            <li className={isActive("/notes")}>
              <Link className="menu-item" onClick={clearSearchAndNavigate("/notes", "LENAV_PREVIOUS_SEARCH_FORM_AN")}>
                <span className="icon">
                  <i className="icon icon-list icon-middle"></i>
                  {noteCount > 0 && <div className="label-count">{noteCount}</div>}
                </span>
                <CLabel k="menu.actions.and.notes" />
              </Link>
            </li>
            <li className={isActive("/watches")}>
              <Link className="menu-item" onClick={clearSearchAndNavigate("/watches", "LENAV_PREVIOUS_SEARCH_FORM_WL")}>
                <i className="icon icon-watchlist icon-middle"></i>
                <CLabel k="menu.watch.list" />
              </Link>
            </li>
            <li className={isActive("/search") || isActive("/results")}><Link className="menu-item" to="/search"><i className="icon icon-search icon-middle"></i><CLabel k="menu.search" /></Link></li>
            <li className="show-mobile">
              <a className="menu-item" onClick={toggleMainMenu}>
                <span className="icon" style={{ width: "auto" }}>
                  <i className="fa fa-circle"></i>
                  <i className="fa fa-circle"></i>
                  <i className="fa fa-circle"></i>
                </span>
                <CLabel k="menu.more" />
              </a>
            </li>
          </ul>
        </div>
        <div className="transform-mobile-nav">
          <a className="close-menu show-mobile" onClick={hideMainMenu}><i className="fa fa-times"></i></a>
          <div className="title-nav show-mobile"><CLabel k="menu.more" /></div>
          <div className="box-nav">
            <ul className="menu-main">
              <li className={isActive("/charts")}><Link className="menu-item" to="/charts"><i className="icon icon-chartgallery icon-middle"></i><CLabel k="menu.chart.gallery" /></Link></li>
              {session && session.user && session.user.isAdmin &&
                <li className={isActive("/admin")}><Link className="menu-item" to="/admin"><i className="icon icon-admin icon-middle"></i><CLabel k="menu.admin" /></Link></li>
              }
              {(session && session.user && session.user.isTester || config.LE_ENV != "production") &&
                <li className={isActive("/twiddler") + (session && session.isTwiddlerEnabled ? " menu-twiddler-enabled" : "")}>
                  <Link className="menu-item" to="/twiddler">
                    <i className="icon icon-twiddler icon-middle"></i><CLabel k="menu.twiddler" />
                  </Link>
                </li>
              }
              <li>
                <a className="menu-item" onClick={An.main.concierge} href={generateMailTo()}>
                  <i className="icon icon-concierge icon-middle"></i>
                  <CLabel k="menu.contact.concierge" />
                </a>
              </li>
              {client && client.hasLibrarySite && applicationSettings && applicationSettings.librarySite &&
                <li>
                  <a target={Utils.isMobile() && session && session.jwtToken ? "" : "_blank"}
                    className="menu-item"
                    rel="noopener noreferrer"
                    onClick={Utils.isMobile() && session && session.jwtToken ? openWpSsoMobile : An.main.library}
                    href={session && session.jwtToken ? '/sfc' : applicationSettings && applicationSettings.librarySite}>
                    <i className="icon icon-library icon-middle" ></i>
                    <CLabel k="menu.library" />
                  </a>
                </li>
              }
              <li className={"show-mobile " + isActive('/settings')}><Link className="menu-item" to="/settings"><i className="icon fa fa-2x fa-user icon-middle" style={{ height: 'auto' }}></i><CLabel k="header.profile" /></Link></li>
              <li className="show-mobile"><a className="menu-item" onClick={() => logout(session)}>
                <i className="icon fa fa-2x fa-sign-out icon-middle" style={{ fontSize: "1.8em" }}></i>
                {session && session.isAliasEnabled && <CLabel k="menu.back.to.admin.user" /> || <CLabel k="menu.log.out" />}
              </a>
              </li>
            </ul>
          </div>
          {sharedFilters && sharedFilters.length > 0 &&
            <CMenuFilters
              title="menu.searches.shared"
              filters={sharedFilters}
              onFilterSelected={setFilterForm}
              isOpen={sharedSearchesIsOpened}
              onMenuToggle={toggleSharedSearchesMenu}
              user={user}  
            />
          }
          {savedFilters && savedFilters.length > 0 &&
            <CMenuFilters
              title="menu.searches.saved"
              savedSearches={true}
              filters={savedFilters}
              onFilterSelected={setFilterForm}
              isOpen={savedSearchesIsOpened}
              onMenuToggle={toggleSavedSearchesMenu}
              user={user}
            />
          }
          <div className="box-nav">
            <div className="name-nav" onClick={toggleRecentlyViewedMenu}>
              <CLabel k="menu.recently.viewed" /> <i className={"fa " + (recentlyViewedIsOpened ? "fa-angle-up" : "fa-angle-down")}></i>
            </div>
            <ul className="menu-main menu-secondary" style={{ display: (recentlyViewedIsOpened ? "block" : "none") }}>
              {recentView.map((element) => (
                <li key={"filter_" + element.id}>
                  <a className="menu-item" onClick={() => openRecent(element)}>
                    <i className={"icon " + (element.type == "filter" ? "icon-savedsearch" : "icon-property") + " icon-middle"}></i>
                    <span>{Utils.ellipsis(element.name, 32)}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div >
  )
}, {
  dispatch: (dispatch) => ({
    hideMainMenu: () => dispatch({ type: 'HIDE_MAIN_MENU' }),
    showMainMenu: () => dispatch({ type: 'SHOW_MAIN_MENU' }),
    setForm: (form) => {
      dispatch({ type: 'SET_FILTER_FORM', form: form })
      dispatch({ type: 'NEW_SEARCH' })
    },
    toggleWpSso: (url) => dispatch({ type: 'TOGGLE_POPUP_WP_SSO', data: url }),

    logout: (session) => dispatch({ type: 'LOGOUT', payload: session, terminateSessionByUser: true }),
    toggleMainMenu: () => dispatch({ type: 'TOGGLE_MAIN_MENU' }),
    toggleSavedSearchesMenu: () => dispatch({ type: 'TOGGLE_SAVED_SEARCHES_MENU' }),
    toggleSharedSearchesMenu: () => dispatch({ type: 'TOGGLE_SHARED_SEARCHES_MENU' }),
    toggleRecentlyViewedMenu: () => dispatch({ type: 'TOGGLE_RECENTLY_VIEWED_MENU' }),
    initSearchForm: (storageKey) => dispatch({ type: 'INIT_SEARCH_FORM', searchType: Const.SEARCH_TYPE_PROPERTIES, storageKey: storageKey }),
  }),

  state: (state) => ({
    actionsToDo: state.notes && state.notes.actionsToDo,
    session: state.session,
    client: state.session && state.session.user && state.session.user.client,
    user: state.session && state.session.user && state.session.user,
    conciergeInfo: state.app && state.app.conciergeInfo,
    applicationSettings: state.app && state.app.applicationSettings,
    savedFilters: state.savedFilters && state.savedFilters.filters,
    mainMenuOpened: state.menu && state.menu.mainMenuOpened,
    savedSearchesIsOpened: state.menu && state.menu.savedSearchesIsOpened,
    sharedSearchesIsOpened: state.menu && state.menu.sharedSearchesIsOpened,
    recentlyViewedIsOpened: state.menu && state.menu.recentlyViewedIsOpened,
    sharedFilters: state.savedFilters && state.savedFilters.shared,
    recentView: state.recentView && state.recentView.list,
  })
})
