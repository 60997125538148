
Moment = require 'moment'
Utils = require '../../helpers/Utils'
{ CLabel } = require '../../cmp/CLabel'
{ withTranslation, Trans } = require 'react-i18next'
{ getDateLocale } = require "../../helpers/LocaleHelpers";
Connect = require '../../helpers/Connect'


module.exports = withTranslation() Connect createReactClass(
  displayName: "CNoteDates"

  render: ()->
    note = @props.note
    React.createElement(React.Fragment, null,
      React.createElement("div", {"className": "date done"},
        (if note.dateFrom && note.type == 'NOTE'
          React.createElement("span", null,
            React.createElement(CLabel, {"k": "property.notes.noteCreated", "param": ({ 
              name: "#{note.createdBy?.firstName} #{note.createdBy?.lastName}",
              date: Moment(note.dateFrom).locale(getDateLocale(@props.i18n.resolvedLanguage)).format("ll")
            })})
          )
        )
      ),
      React.createElement("div", {"className": "date done"},
        (if !note.active && note.type == 'ACTION'
          React.createElement("span", null,
            React.createElement(CLabel, {"k": "property.notes.action"}),
            React.createElement("span", {"className": "status"}, " ", React.createElement(CLabel, {"k": "property.notes.done"}), """ 
""", (if note.doneAt
                React.createElement("span", null, (Moment(note.doneAt).locale(getDateLocale(@props.i18n.resolvedLanguage)).format("ll")))
              )
            ) 
          )
        ),
        (if note.active && note.dateTo && note.type == 'ACTION'
          due = Utils.getNoteStatusDue(note.dateTo, @props.t, @props.i18n)
          noteColor = Utils.getNoteStatusColor(note.dateTo, "gray-text")
          React.createElement("span", null,
            React.createElement(CLabel, {"k": "property.notes.action"}),
            React.createElement("span", {"className": ("status #{noteColor}")}, " ", React.createElement(CLabel, {"k": "property.notes.due"}), " ", (due)
            )
          )

        ),
        (if note.assignedTo?.id && note.type == 'ACTION' && @props.hasAccessToAssignableActions
          React.createElement("span", null,
            React.createElement("div", {"className": "box-icon"}, React.createElement("i", {"className": "fa fa-circle"})),
            React.createElement("span", null,
              React.createElement(Trans, {"i18nKey": "actions.and.notes.assigned.to", "values": ({
                name: note.assignedTo.firstName + " " + note.assignedTo.lastName,
                icon: if note.assignedTo?.id == @props.user.id then "👉" else ""
              })},
                React.createElement("span", {"style": ( fontSize: 16, top: 1, position: "relative" )}, "👉"),
                React.createElement("span", null)
              )
            )
          )

        )
      )
    )
), {
  state: (state) ->
    user: state.session && state.session.user
    hasAccessToAssignableActions: state.session && state.session.user && state.session.user.client && Utils.hasFeature(state.session.user.client, "AssignableActions")
    user: state.session && state.session.user
}
