import CCount from "../cmp/results/CCount"
import CSearchForm from "../cmp/search/CSearchForm"
import Header from "../pages/parts/Header"
import { CLabel } from "../cmp/CLabel"
import Menu from "../pages/parts/Menu"
import Connect from "../helpers/Connect"
import An from "../helpers/Analytics"
import { useEffect } from "react"
import Utils from "../helpers/Utils"
import { Trans, useTranslation } from "react-i18next"

export default Connect(({ router, route, form, roomsCount, isCountLoading, initSearchForm }) => {
  const { t } = useTranslation()

  useEffect(() => {
    An.main.search()
    initSearchForm()
  }, [])

  const search = () => {
    if (Utils.keyLength(form) > 0) {
      router.push('/results')
    }
  }

  const reset = () => {
    initSearchForm()
  }

  return (
    <div className="wrapper-content">
      <Header title={route.title} />
      <div className="main">
        <div className="main-content">
          <div className="container">
            <Menu className="hide-mobile" />
            <div className="dashboard">
              <CSearchForm />
            </div>
            <div className="search-line-results">
              <div className="container">
                <div className="container-center">
                  <div className="box-btn box-right">
                    <a className="btn btn-grey btn-normal hide-mobile" onClick={reset}><span className="upper"><CLabel k="search.cancel" /></span></a>
                    <a className="btn btn-red btn-normal show-results" onClick={search}>
                      <span className="upper"><CLabel k="search.show.results" /></span>
                      <div className="show-mobile"><CCount /> <span className="count"><CLabel k="search.count.records.found" /></span></div>
                    </a>
                  </div>
                  <div className="box-flex">
                    <div className="box-result box-flex hide-mobile">
                      <div className="count box-left"><CCount /></div>
                      <div className="box-result">
                        {roomsCount ? <Trans i18nKey="search.count.records.found.two.line" values={{ roomsCount: Utils.formatNumber(roomsCount) }}>
                          <span></span>
                          <span id="rooms-count-info" className="hide-mobile" style={(isCountLoading && { opacity: 0.5 } || {})}>
                            {{ roomsCount }}
                          </span>
                        </Trans> :
                        <CLabel k="search.count.records.found" />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}, {
  dispatch: (dispatch) => ({
    initSearchForm: () => dispatch({ type: 'INIT_SEARCH_FORM' })
  }),

  state: (state) => ({
    form: state.searchForm.form,
    isCountLoading: state.searchForm?.info?.isCountLoading,
    count: state.searchForm.info?.count,
    roomsCount: state.searchForm?.info?.roomsCount && Number.parseInt(state.searchForm.info.roomsCount)
  })
})