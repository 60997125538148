Connect = require '../../helpers/Connect'
{ CLabel } = require "../CLabel"
{ CInput } = require '../CInput'
Moment = require 'moment'
Calendar = require('rc-calendar').default
LocaleEnUS = require 'rc-calendar/lib/locale/en_US'
DatePicker = require 'rc-calendar/lib/Picker'
{ API } = require '../../redux/Store'
{ createIframeForDownload } = require '../../helpers/Utils'
CPagination = require './CPagination.cjsx'
{ withTranslation } = require 'react-i18next' 

PAGE_SIZE = 24


module.exports = withTranslation() Connect createReactClass(
  displayName: "CAdminRecentActivity"

  getInitialState: ->
    clientName: ''
    firstName: ''
    lastName: ''
    platform: ''
    dateFrom: null
    dateTo: null
    recentActivities: @props.usersRecentActivity
    currentPage: 1
    totalPages: 1

  componentDidMount: ->
    @props.getRecentActivity({})

  updateActivity: (page) ->
    if page
      offset = (page - 1) * PAGE_SIZE
      @setState {currentPage: page}
     else
      @setState {currentPage: 1}
      offset = (@state.currentPage - 1) * PAGE_SIZE
    form = {
      clientName: @state.clientName
      firstName: @state.firstName
      lastName: @state.lastName
      platform: @state.platform
      dateFrom: @state.dateFrom
      dateTo: @state.dateTo
      limit: PAGE_SIZE
      offset: offset
    }
    @props.getRecentActivity(form)

  componentWillReceiveProps: (newProps)->
    if newProps.usersRecentActivity != @state.recentActivities
      @setState {
        recentActivities: newProps?.usersRecentActivity?.activities,
        totalPages: Math.ceil(newProps.usersRecentActivity.totalElements / PAGE_SIZE)
      }

  onChangeFilter: (e)->
    @state[e.target.name] = e.target.value
    @updateActivity()

  setDateFrom: (moment)->
    @state.dateFrom = moment?.startOf('days').format()
    @setState dateFrom: @state.dateFrom
    @updateActivity()

  setDateTo: (moment)->
    @state.dateTo = moment?.format()
    @setState dateTo: @state.dateTo
    @updateActivity()

  calendarPlaceHolder: (name, value)->
    React.createElement("span", {"tabIndex": "0", "className": "pointer"},
      React.createElement("i", {"className": "fa fa-fw fa-calendar"}),
      React.createElement("span", null, (if @state[name] then Moment(@state[name]).format("[MMM-DD]") else React.createElement(CLabel, {"k": ("admin.tabs.users.recent.activity.filter.date." + name.substring(4,).toLowerCase())})))
    )

  calendarPlaceHolderFrom: (value)->
    @calendarPlaceHolder('dateFrom', value)

  calendarPlaceHolderTo: (value)->
    @calendarPlaceHolder('dateTo', value)

  createCalendar: (name)->
    React.createElement(Calendar, { \
      "locale": (LocaleEnUS),  \
      "dateInputPlaceholder": (@props.t("admin.tabs.users.recent.activity.filter.please.input", { ns: "internal_ui" })),  \
      "formatter": 'YYYY-MM-DD',  \
      "showDateInput": (true),  \
      "defaultValue": (@state[name] && Moment(@state[name]))
    })

  exportActivity: (e)->
    params = "&clientName=#{@state.clientName}"
    if @state.firstName
      params += "&firstName=#{@state.firstName}"
    if @state.lastName
      params += "&lastName=#{@state.lastName}"
    if @state.platform
      params += "&platform=#{@state.platform}"
    if @state.dateFrom
      params += "&dateFrom=#{@state.dateFrom.substring(0, 10)}"
    if @state.dateTo
      params += "&dateTo=#{@state.dateTo.substring(0, 10)}"
    createIframeForDownload(API.exportUserActivity(params))

  render: ->
    calendarDateFrom = @createCalendar('dateFrom')
    calendarDateTo = @createCalendar('dateTo')
    React.createElement("div", {"className": "block-tile"},
      React.createElement("div", {"className": "box-tile"},
        React.createElement("div", {"className": "tile-group"},
          React.createElement("form", {"className": "form form-small"},
            React.createElement("div", {"className": "box-control clearfix"},
              React.createElement("div", {"className": "row-half name-block upper", "style": (fontSize: '1em')}, React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.logins"})),
              React.createElement("div", {"className": "box-right"},
                React.createElement("a", {"className": "btn link-grey", "onClick": (@exportActivity)}, React.createElement("i", {"className": "fa fa-clipboard icon-left"}), React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.export"}))
              )
            ),
            React.createElement("div", {"className": "box-control clearfix"},
              React.createElement("div", {"className": "row-20"},
                React.createElement(DatePicker, { \
                  "animation": "slide-up",  \
                  "calendar": (calendarDateFrom),  \
                  "name": 'dateFrom',  \
                  "value": (@state.dateFrom && Moment(@state.dateFrom)),  \
                  "onChange": (@setDateFrom)
                },
                  (@calendarPlaceHolderFrom)
                )
              ),
              React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.first.name", { ns: 'internal_ui' })), "name": 'firstName', "className": "form-control", "type": 'text', "value": (@state.firstName)})),
              React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.client.name", { ns: 'internal_ui' })), "name": 'clientName', "className": "form-control", "type": 'text', "value": (@state.clientName)})),
              React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.platform", { ns: 'internal_ui' })), "name": 'platform', "className": "form-control", "type": 'text', "value": (@state.platform)})),
              React.createElement("div", {"className": "clear-left"},
                React.createElement("div", {"className": "row-20"},
                  React.createElement(DatePicker, { \
                    "animation": "slide-up",  \
                    "calendar": (calendarDateTo),  \
                    "name": 'dateTo',  \
                    "value": (@state.dateTo && Moment(@state.dateTo)),  \
                    "onChange": (@setDateTo)
                  },
                    (@calendarPlaceHolderTo)
                  )
                ),
                React.createElement("div", {"className": "row-20"}, React.createElement(CInput, {"ref": "filterUserActivity", "onChange": (@onChangeFilter), "placeholder": (@props.t("admin.tabs.users.recent.activity.filter.last.name", { ns: 'internal_ui' })), "name": 'lastName', "className": "form-control", "type": 'text', "value": (@state.lastName)}))
              )
            )
          ),
          React.createElement("div", {"className": "clearfix"},
            React.createElement(CPagination, {"totalPages": (@state?.totalPages), "currentPage": (@state?.currentPage), "paginationFunc": (@updateActivity)})
          ),
          React.createElement("div", {"className": "flex-table"},
            React.createElement("div", {"className": "flex-tr flex-th"},
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.last.activity"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.user"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.client"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.platform"})
              ),
              React.createElement("div", {"className": "flex-td"},
                React.createElement(CLabel, {"k": "admin.tabs.users.recent.activity.login.time"})
              )
            ),
            (@state?.recentActivities && @state?.recentActivities.length > 0 && @state?.recentActivities.map (recentActivity, idx)->
              React.createElement("div", {"className": "flex-tr", "key": (idx)},
                React.createElement("div", {"className": "flex-td"},
                  (recentActivity.lastActivityDateTime && Moment(recentActivity.lastActivityDateTime).tz('UTC').format('YYYY-MM-DD HH:mm'))
                ),
                React.createElement("div", {"className": "flex-td"},
                  (recentActivity.userName)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (recentActivity.clientName)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (recentActivity.platform)
                ),
                React.createElement("div", {"className": "flex-td"},
                  (recentActivity.lastLoginDate && Moment(recentActivity.lastLoginDate).tz('UTC').format('YYYY-MM-DD HH:mm'))
                )
              )
            )
          )
        )
      )
    )
), {
  state: (state)->
    usersRecentActivity: state.admin.usersRecentActivity
    translation: state.translation
  dispatch: (dispatch)->
    getRecentActivity: (form)-> dispatch(type: "GET_USERS_RECENT_ACTIVITY", form: form)
}