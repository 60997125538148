Connect = require '../../../helpers/Connect'
{ CLabel } = require '../../../cmp/CLabel'
Utils = require '../../../helpers/Utils'
CMultipleDropdown = require '../CMultipleDropdown'
{ API } = require '../../../redux/Store'
DatePicker = require 'rc-calendar/lib/Picker'
Moment = require 'moment'
React = require 'react'
{ getError, optionsFields, mapSelectedFieldsToChangeTypes, getSelectedFields, filterOptionFields, validateSelectedChanges } = require './CGeneralChangesHelpers'
{ withTranslation, Trans } = require 'react-i18next' 
{ DatePickerSince } = require '../DatePickerSince'


module.exports = withTranslation() Connect createReactClass(
  displayName: "CGeneralChangesFastFilterElement"

  getInitialState: ()->
    values:
      isToggleable: true
      enabled: @props.value?.enabled || false
      mode: @props.value?.mode || 'DATE'
      sinceDate: @props.value?.sinceDate
      daysAgo: @props.value?.daysAgo || 7,
      values: @props.value?.values || null,
      invalid: false
    fieldsValues: ''

  optionsFields: ()->
    optionsFields.filter(filterOptionFields(@props.client)).map((field) =>
      if field.translationKey
        field.label = @props.t(field.translationKey);
      return field
    )

  getClearState: ()->
    values:
      isToggleable: true
      enabled: true
      mode: @props.value?.mode || 'DATE'
      sinceDate: undefined
      daysAgo: 7
      values: null
      invalid: false
    fieldsValues: ''

  componentWillReceiveProps: (newProps) -> 
    if newProps.value
      fieldsValues = @optionsFields().filter((v) ->
        newProps.value[v.value] 
      ).map((v) -> v.value).join(';')
      @setState(
        values: 
          newProps.value
        fieldsValues: fieldsValues
      )
    else
      @setState(@getClearState())


  handleText: (e)->
    if e.target.name == 'daysAgo'
      @state.values.mode = 'DAYS'
      @state.values.enabled = true
    @state.values[e.target.name] = e.target.value
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  handleRadio: (e)->
    @state.values[e.target.name] = 'true' == e.target.value
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  putTimelineDate: (moment) ->
    @state.values.enabled = true
    @state.values.mode = 'DATE'
    @state.values.sinceDate = moment?.startOf('day').format()
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  handleChange: ()->
    @props.onChange?(@props.name, @state.values, false)

  putValueFromDropDown: (name, value)->
    prevValue = getSelectedFields(@state.values.values)?.split(';');
    @state.values[name] = mapSelectedFieldsToChangeTypes(validateSelectedChanges(prevValue, value))
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  changeMode: ()->
    currentMode = @state.values.mode
    if currentMode == 'DAYS'
      @state.values.sinceDate = Moment().subtract(@state.values.daysAgo, 'days').tz('UTC').startOf('days').format()
    if currentMode == 'DATE'
      daysDiff = Math.floor(Moment.duration(Moment().diff(Moment(@state.values.sinceDate))).asDays());
      @state.values.daysAgo = if daysDiff <= 0 then 1 else daysDiff;
    @state.values.mode = if currentMode == 'DAYS' then 'DATE' else 'DAYS'
    @state.values.invalid = Boolean(getError(@state.values))
    @setState(@state, @handleChange)

  timelineDatePlaceholder: (value)->
    React.createElement("div", {"className": "pointer form-control", "style": (borderTop: "0px")},
      React.createElement("a", {"className": "black box-left"},
        (if value?.value then Moment(value?.value).format("[MMM-DD]") else React.createElement(CLabel, {"k": 'timeline.export.date'}))
      ),
      React.createElement("span", {"className": "fa fa-calendar box-right"})
    )

  render: ()->
    error = getError(@state.values)
    timelinePriorDateCalendar = Utils.createCalendar(@state.values?.sinceDate && Moment(@state.values?.sinceDate), @props.t("timeline.export.date"), Utils.timelineDatesFilter)
    React.createElement(React.Fragment, null,  
      React.createElement("div", {"className": "box-control middle box-checkbox"},
        React.createElement(CMultipleDropdown, { \
          "className": "general-changes-dropdown",  \
          "onChange": (@putValueFromDropDown),  \
          "name": "values",  \
          "values": (getSelectedFields(@state.values?.values)),  \
          "options": (@optionsFields()),  \
          "placeholder": (@props.t("search.general.changes.type"))
        }),
        (if @state.values.mode != 'DAYS'
          React.createElement(DatePickerSince, { \
              "className": "general-changes-datepicker",  \
              "animation": "slide-up",  \
              "calendar": (timelinePriorDateCalendar),  \
              "name": 'sinceDate',  \
              "value": (@state.values?.sinceDate && Moment(@state.values?.sinceDate).tz('UTC')),  \
              "onChange": (@putTimelineDate),  \
              "placeholder": (@timelineDatePlaceholder),  \
              "changeMode": (@changeMode)})
        else
          React.createElement("div", {"className": "box-control middle", "style": (display: "flex")},
            React.createElement(Trans, {"i18nKey": "search.general.changes.fast.filter.days.ago"},
              React.createElement("input", { \
                "onBlur": (@props.onBlur),   \
                "name": "daysAgo",  \
                "value": (@state.values.daysAgo), "maxLength": "2", "min": "1", "max": "700",   \
                "onChange": (@handleText), "type": "number", "className": "form-control micro",  \
                "style": (borderTop: "0px", lineHeight: "34px", paddingRight: "1px", margin: "0px 5px", width: 50 )}),
              React.createElement("div", {"className": "general-changes-arrows-icon"},
                React.createElement("i", {"onClick": (@changeMode), "className": "fa fa-calendar"})
              )
            )
          )
        )
      ),
      (error && React.createElement("div", {"className": "general-changes-error"},
        React.createElement(CLabel, {"k": (error.translationKey), "param": (error.params)})
      ))
    )
), {
  state: (state) ->
    client: state.session?.user?.client
    translation: state.translation
}