require './property.css'
CResultAddress = require '../cmp/results/CResultAddress'
CPropertyNotes = require '../cmp/results/CPropertyNotes'
CRolesList = require('../cmp/property/CRolesList.js').default
CRoleDetails = require('../cmp/property/CRoleDetails.js').default
{ getRolesInfo } = require '../cmp/property/RoleUtils'
{ CLabel } = require '../cmp/CLabel'
CTimeline = require '../cmp/CTimeline'
CMobileDropdownMenu = require '../cmp/CMobileDropdownMenu'
CBackButton = require('../cmp/CBackButton').default
React = require 'react'
{ browserHistory } = require 'react-router'
Connect = require '../helpers/Connect'
An = require '../helpers/Analytics'
Header = require '../pages/parts/Header'
Menu = require('../pages/parts/Menu').default
Utils = require '../helpers/Utils'
{ itemInfoTooltip } = require '../helpers/Blocks'
{ API } = require '../redux/Store'
{ getHistoryColor, getColorByDate, formatPhoneNumber, formatNumber, isMobile, getUserAgent, createIframeForDownload, getProjectHistoryColor, getFormattedLabel } = require '../helpers/Utils'
Moment = require 'moment'
React = require 'react'
{ AccessRestriction } = require '../cmp/AccessRestriction'
{ withTranslation, Trans } = require 'react-i18next' 
{ decode } = require 'html-entities'
{ translateCategoricalValue } = require '../cmp/filters/CTranslateUtils'
{ getDateLocale } = require "../helpers/LocaleHelpers";
{ extend } = require '../helpers/UtilsHelpers'
CItemNotesList = require '../cmp/note/CItemNotesList'
PrintHeader = require('../pages/parts/PrintHeader').default
PrintFooter = require('../pages/parts/PrintFooter').default
PrintContent = require('../pages/parts/PrintContent').default
{ FEATURE_ENUM, FEATURE_TABLE, checkIfFeatureIsAvailable } = require('../helpers/MobileFeature')

MAX_HEIGHT = 1300
NOTE_SIZE = 80
NOTE_LINE_HEIGHT = 16
NOTE_LINE_CHARS = 130
COMMENTARY_LINE_CHARS = 160
COMMENTARY_LINE_HEIGHT = 16
NOTES_BOX_SIZE = 120
PRINT_PROJECT_HEIGHT = 170
PRINT_ADDITIONAL_DETAILS_HEIGHT = 200
PRINT_PROPERTY_INFO_HEIGHT = 250
PRINT_CONTACT_HEIGHT = 100


module.exports = withTranslation() Connect createReactClass(
  displayName: "Property"
  mounted: false
  getInitialState: ()->
    property: id: -1
    location: {}
    loading: false
    showTimeline: false
    notes: []
    commentary: null
    showOriginal: false
    notesChunks: []
    rolesChunks: []

  processPages: (notes)->
    chunks = []
    chunk = []
    rolesChunks = []
    rolesChunk = []
    projectsHeight = (@state.property?.projects || []).length * PRINT_PROJECT_HEIGHT
    commentary = decode(@state.commentary?.translationHuman || @state.commentary?.translation || @state.property?.commentary)
    commentaryHeight = Math.ceil(commentary.length / COMMENTARY_LINE_CHARS) * COMMENTARY_LINE_HEIGHT
    heightsSum = projectsHeight + PRINT_ADDITIONAL_DETAILS_HEIGHT + PRINT_PROPERTY_INFO_HEIGHT + commentaryHeight
    roles = getRolesInfo(@state.property)

    currentChunkSum = heightsSum
    roles.forEach (role, idx)=>
      roleSize = PRINT_CONTACT_HEIGHT
      if idx % 2 == 0 && currentChunkSum + roleSize > MAX_HEIGHT
        rolesChunks.push rolesChunk
        chunks.push([])
        rolesChunk = []
        currentChunkSum = roleSize + PRINT_CONTACT_HEIGHT
        rolesChunk.push role
      else if idx % 2 == 0
        rolesChunk.push role
        currentChunkSum += roleSize
      if (idx % 2 == 1)
        rolesChunk.push role
      if idx == roles.length - 1
        rolesChunks.push rolesChunk

    currentChunkSum += NOTES_BOX_SIZE
    notes.forEach (note, idx)=>
      noteSize = Math.ceil((note.text.length / NOTE_LINE_CHARS)) * NOTE_LINE_HEIGHT + NOTE_SIZE
      if currentChunkSum + noteSize > MAX_HEIGHT
        chunks.push chunk
        chunk = []
        currentChunkSum = noteSize + NOTES_BOX_SIZE
        chunk.push note
      else
        chunk.push note
        currentChunkSum += noteSize
      if idx == notes.length - 1
        chunks.push chunk
    @setState notesChunks: chunks, rolesChunks: rolesChunks

  componentDidMount: ()->
    @mounted = true
    @load(@props.params.propertyId)
    An.navigate.propertyCard()

  componentWillUnmount: ()->
    @mounted = false

  componentWillReceiveProps: (newProps)->
    if newProps.params.propertyId != @props.params.propertyId
      @load(newProps.params.propertyId)
    if newProps.result?.results?.length != @props.result?.results?.length
      @setState property: newProps.result?.results?[@findIndex()]
      @getLocation()

  load: (propertyId)->
    if !propertyId
      @props.router.push("/search")
      return
    @setState loading: true
    API.getProperty propertyId, (property)=>
      @props.addToRecent(type:"property", id: property.id, name: property.name)
      @setState property: property, loading: false, commentary: property?.commentaryTranslation
      @loadNotes(propertyId)
      @getLocation()
    , ()=>
      @setState property: null, loading: false

  loadNotes: (ownerId) ->
    API.notesProperty ownerId, (notes) =>
      @setState({ notes: notes })
      @processPages(notes)

  sortNotes: (a, b)->
    if (a.type != b.type)
      return if a.type == "ACTION" then -1 else 1
    if (a.active != b.active)
      return if a.active then -1 else 1
    if (a.type == "NOTE")
      return a.updatedAt - b.updatedAt
    if (!a.active)
      return a.doneAt - b.doneAt
    return a.dateTo - b.dateTo

  onNoteChanged: (note)->
    isNewNote = !Boolean(@state.notes.find((item) => note.id == item.id))
    if (isNewNote)
      @loadNotes(@state.property.id)
      return

    notes = @state.notes.map((item) => 
      if item.id != note.id
        return item
      return note
    )
    @setState({ notes: notes.sort(@sortNotes) })

  onNoteDeleted: (note)->
    notes = @state.notes.filter((item) => 
      if item.id != note.id
        return true
      return false
    )
    @setState({ notes: notes.sort(@sortNotes) })

  getLocation: ->
    geocoder = new google.maps.Geocoder()
    property = @state.property
    geocode = (address)=>
      geocoder.geocode('address': address, (results, status)=>
        if @mounted
          if status == 'OK'
            lat = results[0].geometry.location.lat()
            lng = results[0].geometry.location.lng()
            @setState location: lat: lat, lng: lng
          else
            console.warn(status, '\nFailed to geocode location: ', address)
            @setState location: {}
      )
    if property.country && property.city && (property.address1 || property.address2)
      geocode([property.name, property.country, property.city, property.address1 || property.address2].filter((i)-> i).join(','))
    else
      @setState location: {}

  timelineToggle:(e)->
    if (!@state.showTimeline)
      e.target.closest('.tile-group').querySelector('.timeline-block').classList.add('expanded')
    else
      e.target.closest('.tile-group').querySelector('.timeline-block').classList.remove('expanded')
    @setState showTimeline: !@state.showTimeline

  mobileBlockToggle:(e)->
    e.target.closest('.box-slide-block').querySelector('.slide-block').classList.toggle('open')

  findIndex: ()->
    index = @props.result?.results.filter((x) => x.id == @state.property?.id)[0]?.index

  prev: ()->
    index = @findIndex() - 1
    if index >= 0
      @setState property: @props.result?.results[index]
      @props.router.replace("/property/#{@props.result?.results[index]?.id}")

  next: ()->
    index = @findIndex() + 1
    if index >= @props.result?.results?.length
      @setState loading: true
      @props.search(@props.page++, (result) =>
        property = result?.results.find((item) => item.index == index)
        @setState loading: false, property: property
      )
    else
      @setState property: @props.result?.results[index]
      @props.router.replace("/property/#{@props.result?.results[index]?.id}")

  generateItem: (object, fieldName, label)->
    if object[fieldName]
      React.createElement("div", {"className": "item-info item-info-#{fieldName} text-overflow"},
        React.createElement("div", {"className": "label"}, (label)),
        React.createElement("span", {"className": "#{getHistoryColor(object, fieldName, 'bold')}"}, ( translateCategoricalValue(@props.t, fieldName, object[fieldName]) ))
      )

  toggleWatch: ->
    fcb = (watching)=>()=>
      @setState property: extend(@state.property, watching: watching)
      if @props.result?.results?.length
        @props.toggleWatchingProperty(@state.property)

    if @state.property?.watching || @state.watching
      @props.deleteWatch(@state.property.id, fcb(false))
    else
      @props.updateWatch(@state.property.id, fcb(true))

  export: () ->
    isFeatureAvailable = checkIfFeatureIsAvailable(FEATURE_ENUM.PRINT)
    if (!isFeatureAvailable)
      featureName = FEATURE_TABLE[FEATURE_ENUM.PRINT].name
      @props.toggleMobileFeaturePopup(featureName)
      return
    window.print()

  copyLink: ()->
    Utils.copyLink () =>
      @showAlertMessage(@props.t('property.link.has.been.copied'), 1000)

  showAlertMessage: (text, duration = 1000)->
    @setState loading: true
    alertMessage = @refs.alertMessage
    alertMessage.innerText = text
    alertMessage.style.opacity = 1
    setTimeout(()=>
      alertMessage.style.opacity = 0
      @setState loading: false
      alertMessage.innerText = ''
    , duration)

  locateProperty: (e)->
    e?.preventDefault()
    property = @state.property
    if property.name && property.country && property.city && (property.address1 || property.address2)
      address = [property.country, property.city, property.address1 || property.address2, property.name].filter((i)-> i).join(',')
      if window.deviceName == "IOS" || (window.deviceName == "INIT" && getUserAgent() == "IOS")
        location.href = 'maps://?q=' + address
      else if window.deviceName == "ANDROID" || (window.deviceName == "INIT" && getUserAgent() == "ANDROID")
        window.open('geo://?q=' + address)
      else if window.deviceName == "BROWSER" || (window.deviceName == "INIT" && getUserAgent() == "BROWSER")
        window.open('https://www.google.com/maps/?q=' + address)
      else
        console.warn('Cannot detect user agent, opening new link with map')
        window.open('https://www.google.com/maps/?q=' + address)

  goBackTo: ->
    previous = @props.locationHistory?.slice(0, -1).pop()
    if previous && previous[0] && previous[0].pathname?.match("property")
      @props.router.push('/results')
    else
      browserHistory.goBack()

  requestHumanTranslation: ()-> 
    API.requestHumanTranslation(
      @state.property?.commentaryTranslation?.hash,
      @props.i18n.resolvedLanguage, () =>
        @setState({
          commentary: {
            ...@state.commentary,
            translationHumanRequestedAt: new Date().toISOString()
          }
        })
    )

  toggleShowOriginal: ()->
    @setState showOriginal: !@state.showOriginal

  sortProjects: (projects)->
    projectTypes = {
      "Conversion": 1,
      "New Construction": 2,
      "Renovation": 3,
      "Sale": 4
      "Distressed Asset": 5,
    }
    return projects.sort((a, b) =>
      return projectTypes[a.projectType] - projectTypes[b.projectType]
    )

  render: ()->
    p = @state.property || {}
    propertyColor = Utils.getColorByDate(@state.property.createdAt)
    customPropertySetLabel = if @props.searchFieldsInfo?.branch?.label then translateCategoricalValue(@props.t, "customPropertySet", @props.searchFieldsInfo.branch.label) else React.createElement(CLabel, {"k": "results.result.branch"})
    projects = p?.projects || []
    pagesCount = Math.max(@state.rolesChunks.length, @state.notesChunks.length)
    pages = if pagesCount > 0 then Array(pagesCount).fill(0).map((i, idx) => idx) else []
    paging = (className)=>
      if @props.result?.totalCount && @findIndex() != undefined
        React.createElement("div", {"className": "item-nav fixed-mobile-paging #{className}"},
          React.createElement("ul", {"className": "paging"},
            React.createElement("li", {"unselectable": "on", "className": "unselectable"},
              (if @findIndex() > 0
                React.createElement("a", {"className": "btn icon-blue", "onClick": (@prev)}, React.createElement("i", {"className": "fa fa-chevron-left small"}))
              )
            ),
            React.createElement("li", null,
              React.createElement(Trans, {"i18nKey": "property.results.count", "values": ({count: @findIndex() + 1, total: @props.result.totalCount})},
                React.createElement("span", {"className": "min"}), React.createElement("span", {"className": "total"})
              )
            ),
            React.createElement("li", {"unselectable": "on", "className": "unselectable"},
              (if @findIndex() < @props.result.totalCount
                React.createElement("a", {"className": "btn icon-blue", "onClick": (@next)}, React.createElement("i", {"className": "fa fa-chevron-right small"}))
              )
            )
          )
        )

    mapThisLocation = (className)=>
      React.createElement("div", {"className": "line-address #{className}"},
        React.createElement("span", {"style": ({ verticalAlign: 'top' })},
          React.createElement(CResultAddress, {"property": (p)})
        ),
        (if @state.location?.lat && @state.location?.lng && @props.mapsAvailable
          React.createElement("div", {"className": "box-btn"}, React.createElement("a", {"className": "btn btn-small", "onClick": (@locateProperty)}, React.createElement(CLabel, {"k": "property.map.this.location"})))
        )
      )

    mapAddress = (className)=>
      React.createElement("div", {"className": "property-line-address #{className}"},
        React.createElement("div", null,
          React.createElement(CResultAddress, {"property": (p), "fieldsInfo": ([
            {n:"address", post: " | "}
            {n:"address1", pre: " ", post: " | "}
            {n:"address2", post: ", "}
            {n:"city",  post: ", "}
            {n:"stateProvince", post: " "}
            {n:"postalCode", post: " | "}
            {n:"country", post: ""}
          ])})
        ),
        (if @state.location?.lat && @state.location?.lng && @props.mapsAvailable
          React.createElement("div", {"className": "box-btn"}, React.createElement("a", {"className": "btn btn-small", "onClick": (@locateProperty)}, React.createElement(CLabel, {"k": "property.map.this.location"})))
        )
      )

    React.createElement(React.Fragment, null, React.createElement("div", {"className": "wrapper-content property #{if isMobile() then 'print-mobile'}"},
      React.createElement(Header, {"title": "", "backLabel": (@props.route.backLabel), "onBackClick": (@goBackTo)}),
      React.createElement(PrintHeader, null),
      (if not @props.activeQuickSearch
        options =  [
            if @state.property?.watching then {label: React.createElement(CLabel, {"k": "property.watching"}), icon:"fa-eye", onClick: @toggleWatch, checkAccess: true } else {label: React.createElement(CLabel, {"k": "watch.list.property.add.to.watch.list"}),  icon:"fa-eye", onClick: @toggleWatch, checkAccess: true}
            {label: React.createElement(CLabel, {"k": "property.print"}), icon:"fa-print", onClick: @export }
            {label: React.createElement(CLabel, {"k": "property.copy.deep.link"}), icon:"fa-copy", onClick: @copyLink}
        ]
        React.createElement("div", null,
          React.createElement(CMobileDropdownMenu, {"options": (options)}),
          (paging('show-mobile'))
        )
      ),
      React.createElement(PrintContent, null,
      React.createElement("div", {"className": "main"},
        React.createElement("div", {"className": "main-content"},
          React.createElement("div", {"className": 'alert-message', "ref": "alertMessage"}),
          React.createElement("div", {"className": "container"},
            React.createElement(Menu, {"className": "hide-mobile"}),
            (if @state.loading
              React.createElement("div", {"className": "block-loader"}, React.createElement("div", {"className": "loader"}, React.createElement(CLabel, {"k": "loading"})))
            else
              React.createElement("div", {"className": "dashboard"},
                React.createElement("div", {"className": "nav-page hide-mobile"},
                  (if p.id
                    React.createElement("div", {"className": "box-nav box-right"},
                      React.createElement("div", {"className": "item-nav"},
                        React.createElement("a", {"className": "btn link-grey icon-blue #{if @state.loading then 'disabled unselectable'}", "onClick": (@export)}, React.createElement("i", {"className": "fa fa-print icon-left", "style": (marginRight:"4px")}), React.createElement(CLabel, {"k": "property.print"}))
                      ),
                      React.createElement("div", {"className": "item-nav"},
                        React.createElement("a", {"className": "btn link-grey icon-blue #{if @state.loading then 'disabled unselectable'}", "onClick": (@copyLink)}, React.createElement("i", {"className": "fa fa-copy icon-left", "style": (marginRight:"4px")}), React.createElement(CLabel, {"k": "property.copy.deep.link"}))
                      ),
                      React.createElement("div", {"className": "item-nav"},
                        React.createElement(AccessRestriction, null,
                          React.createElement("a", {"className": "btn btn-red btn-watch-list", "onClick": (@toggleWatch), "style": (minWidth: "165px")},
                            React.createElement("div", {"className": "no-active-text"}, React.createElement("i", {"className": "fa fa-check icon-left"}), (if @state.property?.watching then React.createElement(CLabel, {"k": "property.watching"}) else React.createElement(CLabel, {"k": "watch.list.property.add.to.watch.list"})))
                          )
                        )
                      ),
                      (paging('hide-mobile'))
                    )
                  ),
                  React.createElement("div", {"className": "box-back"},
                    React.createElement(CBackButton, {"onBackClick": (@goBackTo)})
                  )
                ),
                (if p.id
                  React.createElement("div", {"className": "dashboard-group clearfix"},
                    React.createElement("div", {"className": "row-double-three print-full-width"},
                      React.createElement("div", {"className": "block-tile"},
                        React.createElement("div", {"className": "box-tile"},
                          React.createElement("div", {"className": "tile-group"},
                            React.createElement("div", {"className": "property-details-title"},
                              React.createElement("div", {"className": "property-details-left"},
                                (propertyIsNewRed = @state.property.createdAt && Moment(@state.property.createdAt) > Moment().subtract(7, 'days')),
                                (propertyIsNewOrange = Moment(@state.property.createdAt).isBetween(Moment().subtract(31, 'days'), Moment().subtract(7, 'days'))),
                                React.createElement("div", {"className": "property-details-name"},
                                  React.createElement("div", {"className": "property-details-name-value"}, (p.name)),
                                  (if propertyIsNewRed || propertyIsNewOrange
                                    React.createElement(CLabel, {"style": (if propertyIsNewOrange then background: "orange"), "className": "property-details-status", "k": "results.result.new.label"})
                                  )
                                ),
                                React.createElement("div", {"className": "property-details-name-room show-mobile"}, (p.rooms), " ", React.createElement(CLabel, {"k": "property.rooms"})),
                                (mapThisLocation('show-mobile'))
                              ),
                              React.createElement("div", {"className": "property-details-title-right"},
                                React.createElement("div", {"className": "show-mobile"},
                                  React.createElement(AccessRestriction, null,
                                    (if @state.property?.watching
                                      React.createElement("a", {"onClick": (@toggleWatch), "className": "btn link-grey watch active btn-watch-list"}, React.createElement("i", {"className": "fa fa-eye"}))
                                    else
                                      React.createElement("a", {"onClick": (@toggleWatch), "className": "btn link-grey watch btn-watch-list #{if isMobile() then 'nohover'}"}, React.createElement("i", {"className": "fa fa-eye"}))
                                    )
                                  )
                                ),
                                React.createElement("div", {"className": "property-details-id"},
                                  React.createElement("div", {"className": ""}, "ID: ", (p.id)),
                                  (p.sourceIdLe && React.createElement("div", {"className": "property-details-id"}, "LE.Internal: ", (p.sourceIdLe))),
                                  (@state.property?.fkeys?.map (i, idx)->
                                    React.createElement("div", {"key": (i.id + i.label), "className": "property-details-id"}, (i.label), ": ", (i.id))
                                  )
                                )
                              )
                            ),
                              (mapAddress('hide-mobile'))
                          ),
                          React.createElement("div", {"className": "tile-group hide-mobile"},
                            React.createElement("div", {"className": "clearfix"},
                              React.createElement("div", {"className": "row-half"},
                                React.createElement("div", {"className": "items-info-hotel"},
                                  (if p.phone
                                    React.createElement("div", {"className": "item-info"},
                                      React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.phone"})),
                                      React.createElement(AccessRestriction, null, React.createElement("a", {"target": "_top", "href": "tel:#{formatPhoneNumber(p.phone)}", "className": "link link-phone #{getHistoryColor(p, 'phone', 'bold')}"}, (p.phone)))
                                    )
                                  ),
                                  (@generateItem(p, "fax", React.createElement(CLabel, {"k": "property.fax"}))),
                                  (@generateItem(p, "generalManager", React.createElement(CLabel, {"k": "property.gm"}))),
                                  (if p.rooms
                                   React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "results.result.rooms"})), React.createElement("span", {"className": "#{getHistoryColor(p, 'rooms')}"}, (p.rooms)))
                                  ),
                                  (@generateItem(p, "market", React.createElement(CLabel, {"k": "results.result.market"}))),
                                  (@generateItem(p, "tract", React.createElement(CLabel, {"k": "results.result.tract"}))),
                                  (@props.searchFieldsInfo.branch && itemInfoTooltip(p, "propertySets", customPropertySetLabel, 165))
                                )
                              ),
                              React.createElement("div", {"className": "row-half"},
                                React.createElement("div", {"className": "items-info-hotel"},
                                  (@generateItem(p, "county", React.createElement(CLabel, {"k": "results.result.county"}))),
                                  (if p.brand?.brandName
                                   React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "results.result.brand.label"})), React.createElement("span", {"className": "#{getHistoryColor(p, 'brandId', 'bold')}"}, (p.brand.brandName)))
                                  ),
                                  (if p.brand?.brandCompany
                                   React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "results.result.brand.company"})), React.createElement("span", {"className": "#{getHistoryColor(p, 'brandCompany', 'bold')}"}, (p.brand.brandCompany))) 
                                  ),
                                  (@generateItem(p, "chainScale", React.createElement(CLabel, {"k": "search.hotel.information.chain.scale"}))),

                                  (if p.usersWithAccess
                                    React.createElement("span", {"className": "territory tooltip-bottom #{getHistoryColor(p, 'usersWithAccess', 'bold')}"},
                                      React.createElement("div", {"style": (height: '18px', marginBottom: '-4px'), "className": "item-info text-overflow"},
                                        React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "results.result.territory"})),
                                        (if p.usersWithAccessFull
                                          React.createElement("span", null,
                                            React.createElement("span", {"className": "tooltiptext"},
                                              (p.usersWithAccessFull.map (i, idx)->
                                                React.createElement("div", {"key": (idx)},
                                                  (i)
                                                )
                                              )
                                            ),
                                            (if p.usersWithAccess == "None" then React.createElement(CLabel, {"k": "results.result.none"}) else p.usersWithAccess)
                                          )
                                        else
                                          React.createElement("span", {"className": "#{getHistoryColor(p, 'usersWithAccess', 'bold')}"},
                                            (if p.usersWithAccess == "None" then React.createElement(CLabel, {"k": "results.result.none"}) else p.usersWithAccess)
                                          )
                                        )
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      ),
                      React.createElement("div", {"className": "project-tiles"},  
                        (@sortProjects(projects).map (project, i)=>
                          combine = i >= projects?.length - 2 && projects?.length > 1
                          React.createElement("div", {"className": "block-tile project-tile #{if combine then 'project-tile-combine' else ''} project-tile-#{project.projectType.toLowerCase()}", "key": (i)},
                            React.createElement("div", {"className": "box-tile project-hotel project-type-outline-#{project.projectType?.replace(' ', '')?.toLowerCase()}"},
                              React.createElement("div", {"className": "box-slide-block open"},
                                React.createElement("div", {"className": "tile-group"},
                                  React.createElement("div", {"className": "tile-title"},
                                    React.createElement("div", {"className": "box-btn box-right show-mobile"},
                                      React.createElement("a", {"className": "btn btn-small btn-slide-block", "onClick": (@mobileBlockToggle)}, React.createElement("div", {"className": "box-arrow"}, React.createElement("i", {"className": "fa fa-angle-up"})))
                                    ),
                                    React.createElement("div", {"className": "name-block box-inline"}, React.createElement("span", {"className": ("bold #{getProjectHistoryColor(project)}-on-white-bg")}, (translateCategoricalValue(@props.t, 'projectType', project.projectType))))
                                  )
                                ),
                                React.createElement("div", {"className": "slide-block open"},
                                  React.createElement("div", {"className": "tile-group"},
                                    React.createElement("div", {"className": "clearfix"},
                                      (if project.projectType in ["New Construction", "Renovation", "Conversion"] && (["showNewConstruction", "showRenovations", "showConversions"].some (it)=> @props.client?[it])
                                        React.createElement("div", {"className": "row-half"},
                                          React.createElement("div", {"className": "items-info-hotel"},
                                            React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.project.stage"})), React.createElement("span", {"className": "bold #{getHistoryColor(project, 'projectStage')}"}, (translateCategoricalValue(@props.t, 'projectStage', project.projectStage)))),
                                            (if project.startDate
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.start.date"})), React.createElement("span", {"className": "#{getHistoryColor(project, 'startDate', 'bold')}"}, (Moment(project.startDate).tz('UTC').locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]"))))
                                            ),
                                            (if project.scheduledOpen
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.opening.date"})), React.createElement("span", {"className": "#{getHistoryColor(project, 'scheduledOpen', 'bold')}"}, (Moment(project.scheduledOpen).tz('UTC').locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]"))))
                                            )
                                          )
                                        )
                                      ),
                                      (if project.projectType == "Conversion" && @props.client?.showConversions && project.projectStage == "Recently Completed"
                                        React.createElement("div", {"className": "row-half"},
                                          React.createElement("div", {"className": "items-info-hotel"},
                                            (if project.initialBrand?.brandName
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.initial.brand"})), React.createElement("span", {"className": "#{getHistoryColor(project.initialBrand, 'brandName', 'bold')}"}, (project.initialBrand.brandName)))
                                            ),
                                            (if project.initialBrand?.brandCompany
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.initial.franchise"})), React.createElement("span", {"className": "#{getHistoryColor(project.initialBrand, 'brandCompany', 'bold')}"}, (project.initialBrand.brandCompany)))
                                            ),
                                            (if project.initialChainScale
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.initial.chain"})), React.createElement("span", {"className": "#{getHistoryColor(project, 'initialChainScale', 'bold')}"}, (translateCategoricalValue(@props.t, 'chainScale', project.initialChainScale))))
                                            )
                                          )
                                        )
                                      ),
                                      (if project.projectType == "Conversion" && @props.client?.showConversions && project.projectStage != "Recently Completed"
                                        React.createElement("div", {"className": "row-half"},
                                          React.createElement("div", {"className": "items-info-hotel"},
                                            (if project.newBrand?.brandName
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.new.brand"})), React.createElement("span", {"className": "#{getHistoryColor(project.newBrand, 'brandName', 'bold')}"}, (project.newBrand.brandName)))
                                            ),
                                            (if project.newBrand?.brandCompany
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.new.franchise"})), React.createElement("span", {"className": "#{getHistoryColor(project.newBrand, 'brandCompany', 'bold')}"}, (project.newBrand.brandCompany)))
                                            ),
                                            (if project.newChainScale
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.new.chain"})), React.createElement("span", {"className": "#{getHistoryColor(project, 'newChainScale', 'bold')}"}, (translateCategoricalValue(@props.t, 'chainScale', project.newChainScale))))
                                            )
                                          )
                                        )
                                      ),
                                      (if project.projectType == "Distressed Asset" && @props.client?.showDistressedAssets
                                        React.createElement("div", {"className": "row-half"},
                                          React.createElement("div", {"className": "items-info-hotel"},
                                            (if project.assetStatus
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "results.result.asset.status"})), React.createElement("span", {"className": ("#{getHistoryColor(project, "assetStatus", "bold")}")}, (translateCategoricalValue(@props.t, 'assetStatus', project.assetStatus))))
                                            )
                                          )
                                        )
                                      ),
                                      (if project.projectType == "Sale" && @props.client?.showSales
                                        React.createElement("div", {"className": "row-half"},
                                          React.createElement("div", {"className": "items-info-hotel"},
                                            (if project.dateOfSale
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.date.sold"})), React.createElement("span", {"className": "#{getHistoryColor(project, 'dateOfSale', 'bold')}"}, (Moment(project.dateOfSale).locale(getDateLocale(@props.i18n.resolvedLanguage)).format("[month-year]"))))
                                            ),
                                            (if !(project.sellingPrice in [null, undefined])
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.price"})), React.createElement("span", {"className": "#{getHistoryColor(project, 'sellingPrice', 'bold')}"}, "$", (formatNumber(project.sellingPrice))))
                                            ),
                                            (if !(project.sellingPricePerRoom in [null, undefined])
                                              React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.price.per.room"})), React.createElement("span", {"className": "#{getHistoryColor(project, 'sellingPricePerRoom', 'bold')}"}, "$", (formatNumber(project.sellingPricePerRoom))))
                                            )
                                          )
                                        )
                                      )
                                    )
                                  ),
                                  (if project.timeline?.length > 1
                                    React.createElement("div", {"className": "tile-group hide-print"},
                                      React.createElement("div", {"style": (marginBottom: '10px', marginTop: '10px')},
                                        React.createElement("a", {"className": (getColorByDate(project.timeline[project.timeline.length - 1].asOf, "black") + ' bold'), "onClick": (@timelineToggle)}, React.createElement("i", {"className": (if @state.showTimeline then "fa fa-caret-down" else "fa fa-caret-right")}), " ", React.createElement(CLabel, {"k": "timeline.title"}))
                                      ),
                                      React.createElement("div", {"className": "timeline-block"},
                                        React.createElement(CTimeline, {"timeline": (project.timeline)})
                                      )
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )
                      ),
                      React.createElement("div", {"className": "block-tile additional-info-block-tile"},
                        React.createElement("div", {"className": "box-tile"},
                          React.createElement("div", {"className": "box-slide-block"},
                            React.createElement("div", {"className": "tile-group"},
                              React.createElement("div", {"className": "tile-title"},
                                React.createElement("div", {"className": "box-btn box-right show-mobile"},
                                  React.createElement("a", {"className": "btn btn-small btn-slide-block", "onClick": (@mobileBlockToggle)}, React.createElement("div", {"className": "box-arrow"}, React.createElement("i", {"className": "fa fa-angle-up"})))
                                ),
                                React.createElement("div", {"className": "name-block middle"},
                                  React.createElement("span", {"className": "hide-mobile"}, React.createElement(CLabel, {"k": "property.additional.property"})),
                                  React.createElement("span", {"className": "show-mobile"}, React.createElement(CLabel, {"k": "property.details"}))
                                )
                              )
                            ),
                            React.createElement("div", {"className": "slide-block"},
                              React.createElement("div", {"className": "tile-group"},
                                React.createElement("div", {"className": "show-mobile"},
                                  React.createElement("div", {"className": "items-info-hotel"},
                                    (if p.rooms
                                     React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "property.rooms"})), React.createElement("span", {"className": "#{getHistoryColor(p, 'rooms', 'bold')}"}, (p.rooms)))
                                    ),
                                    (if p.phone
                                      React.createElement("div", {"className": "item-info"},
                                        React.createElement("div", {"className": "label #{getHistoryColor(p, 'phone', 'bold')}"}, React.createElement(CLabel, {"k": "property.phone"})),
                                        React.createElement(AccessRestriction, null, React.createElement("a", {"target": "_top", "href": "tel:#{formatPhoneNumber(p.phone)}", "className": "link link-phone"}, (p.phone)))
                                      )
                                    ),
                                    (@generateItem(p, "fax", React.createElement(CLabel, {"k": "property.fax"}))),
                                    (@generateItem(p, "generalManager", React.createElement(CLabel, {"k": "property.gm"}))),
                                    (@generateItem(p, "market", React.createElement(CLabel, {"k": "results.result.market"}))),
                                    (@generateItem(p, "tract", React.createElement(CLabel, {"k": "results.result.tract"}))),
                                    (if p.brand?.brandName
                                     React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "results.result.brand.label"})), React.createElement("span", {"className": "#{getHistoryColor(p.brand, 'brandName', 'bold')}"}, (p.brand.brandName)))
                                    ),
                                    (if p.brand?.brandCompany
                                     React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "results.result.brand.company"})), React.createElement("span", {"className": "#{getHistoryColor(p.brand, 'brandCompany', 'bold')}"}, (p.brand.brandCompany)))
                                    ),
                                    (@generateItem(p, "chainScale", React.createElement(CLabel, {"k": "search.hotel.information.chain.scale"}))),
                                    (@generateItem(p, "territory", React.createElement(CLabel, {"k": "results.result.territory"}))),
                                    (@generateItem(p, "branch", @props.searchFieldsInfo.branch?.label || React.createElement(CLabel, {"k": "results.result.branch"})))
                                  )
                                ),
                                React.createElement("div", {"className": "clearfix"},
                                  React.createElement("div", {"className": "row-half"},
                                    React.createElement("div", {"className": "items-info-hotel"},
                                      (@generateItem(p, "country", React.createElement(CLabel, {"k": "results.result.country"}))),
                                      (@generateItem(p, "worldRegion1", React.createElement(CLabel, {"k": "results.result.world.region1"}))),
                                      (@generateItem(p, "worldRegion2", React.createElement(CLabel, {"k": "results.result.world.region2"}))),
                                      (@generateItem(p, "yearBuilt", React.createElement(CLabel, {"k": "results.result.built"}))),
                                      (@generateItem(p, "locationType", React.createElement(CLabel, {"k": "results.result.locationType"})))
                                    )
                                  ),
                                  React.createElement("div", {"className": "row-half"},
                                    React.createElement("div", {"className": "items-info-hotel"},
                                      (@generateItem(p, "lastRenovationYear", React.createElement(CLabel, {"k": "results.result.last.renovation"}))),
                                      (if p.meetingSpace
                                        React.createElement("div", {"className": "item-info text-overflow"}, React.createElement("div", {"className": "label"}, React.createElement(CLabel, {"k": "results.result.meeting.space"})), React.createElement("span", {"className": "#{getHistoryColor(p, 'meetingSpace', 'bold')}"}, (translateCategoricalValue(@props.t, 'meetingSpace', p.meetingSpace))), " ", React.createElement("span", {"className": "medium-gray"}, "(", React.createElement(CLabel, {"k": "results.result.meetingSpaceUnit"}), React.createElement("span", {"className": "super"}, "2"), ")"))
                                      ),
                                      (@generateItem(p, "ownerRole", React.createElement(CLabel, {"k": "results.result.owner.role"}))),
                                      (@generateItem(p, "brandRole", React.createElement(CLabel, {"k": "results.result.brand.role"}))),
                                      (@generateItem(p, "brandAffiliationYear", React.createElement(CLabel, {"k": "results.result.brand.brandAffiliationYear"}))),
                                      (@generateItem(p, "keywords", React.createElement(CLabel, {"k": "results.result.keywords"}))),
                                      React.createElement("div", {"className": "show-mobile"}, (@generateItem(p, "id", React.createElement(CLabel, {"k": "results.result.id"}))))
                                    )
                                  )
                                ),
                                React.createElement("div", {"className": "show-print item-comment"},
                                  React.createElement("div", {"className": "text #{getHistoryColor(p, 'commentary')}"},
                                    (decode(@state.commentary?.translationHuman || @state.commentary?.translation || @state.property?.commentary))
                                  )
                                )
                              ),
                              React.createElement("div", {"className": "tile-bottom show-mobile"},
                                React.createElement("div", {"className": "box-btn text-center"}, React.createElement("a", {"onClick": (@mobileBlockToggle), "className": "btn btn-small btn-slide-block"}, React.createElement(CLabel, {"k": "property.show.less"})))
                              )
                            )
                          )
                        )
                      ),
                      (if @state.commentary || @state.property.commentary
                        React.createElement("div", {"className": "block-tile hide-print"},
                          React.createElement("div", {"className": "box-tile outline-commentary-#{getHistoryColor(p, 'commentary')}"},
                            React.createElement("div", {"className": "box-slide-block"},
                              React.createElement("div", {"className": "tile-group"},
                                React.createElement("div", {"className": "box-btn box-right show-mobile"},
                                  React.createElement("a", {"className": "btn btn-small btn-slide-block", "onClick": (@mobileBlockToggle)}, React.createElement("div", {"className": "box-arrow"}, React.createElement("i", {"className": "fa fa-angle-up"})))
                                ),
                                React.createElement("div", {"className": "tile-title commentary-block"},
                                  React.createElement("div", {"className": "name-block middle"},
                                    React.createElement(CLabel, {"k": "property.commentary.and.status"})
                                  )
                                )
                              ),
                              React.createElement("div", {"className": "slide-block"},
                                React.createElement("div", {"className": "tile-group"},
                                  React.createElement("div", {"className": "item-comment"},
                                    React.createElement("div", {"className": "text"},
                                      (decode(@state.commentary?.translationHuman || @state.commentary?.translation || @state.property?.commentary))
                                    ),
                                    React.createElement("div", {"className": "update"}, React.createElement("span", {"className": "bold #{getHistoryColor(p, 'recordStatus', 'bold')}"}, (p.recordStatus)))
                                  )
                                ),
                                (@state.commentary && React.createElement("div", {"className": "commentary-machine-translation"},
                                  React.createElement(CLabel, {"k": "property.machine.translation"}),
                                  React.createElement("a", {"onClick": (@toggleShowOriginal), "className": "btn btn-small btn-show-original"}, React.createElement(CLabel, {"k": (if @state.showOriginal then "property.hide.original" else "property.show.original")}))
                                )),
                                (@state.showOriginal && React.createElement("div", {"className": "tile-group"},
                                  React.createElement("div", {"className": "item-comment"},
                                    React.createElement("div", {"className": "text"},
                                      (@state.property?.commentary)
                                    ),
                                    React.createElement("div", {"className": "update"}, React.createElement("span", {"className": "bold #{getHistoryColor(p, 'recordStatus', 'bold')}"}, (p.recordStatus)))
                                  )
                                )
                                )
                              )
                            )
                          )
                        )
                      )
                    ),
                    React.createElement("div", {"className": "row-three print-full-width"},
                      React.createElement("div", {"className": "block-tile hide-print"},
                        React.createElement("div", {"className": "box-tile selected"},
                          React.createElement("div", {"className": "box-slide-block open"},
                            React.createElement(CPropertyNotes, {"property": (@state.property), "onNoteChanged": (@onNoteChanged), "onNoteDeleted": (@onNoteDeleted), "notes": (@state.notes)})
                          )
                        )
                      ),
                      (getRolesInfo(@state.property).length > 0 && React.createElement("div", {"className": "block-contacts-hotel"},
                        React.createElement("div", {"className": "block-tile"},
                          React.createElement("div", {"className": "box-slide-block open"},
                            React.createElement("div", {"className": "box-tile show-mobile"},
                              React.createElement("div", {"className": "tile-title"},
                                React.createElement("div", {"className": "box-btn box-right"},
                                  React.createElement("a", {"className": "btn btn-small btn-slide-block", "onClick": (@mobileBlockToggle)}, React.createElement("div", {"className": "box-arrow"}, React.createElement("i", {"className": "fa fa-angle-up"})))
                                ),
                                React.createElement("div", {"className": "name-block"}, React.createElement(CLabel, {"k": "property.contacts"}))
                              )
                            ),
                            React.createElement("div", {"className": "slide-block open hide-print"},
                              React.createElement(CRolesList, {"property": (@state.property), "RenderItemAs": (CRoleDetails), "fillEmpty": (true), "style": ({flexDirection: "column", justifyContent: "stretch"})})
                            ),
                            (@state.rolesChunks.length > 0 && React.createElement("div", {"className": "slide-block open show-print"},
                              React.createElement(CRolesList, {"property": (@state.property), "RenderItemAs": (CRoleDetails), "fillEmpty": (true), "style": ({flexDirection: "column", justifyContent: "stretch"}), "rolesData": (@state.rolesChunks[0])})
                            ))
                          )
                        )
                      ))
                    ),
                    (@state.notesChunks.length > 0 && @state.notesChunks[0].length > 0 && React.createElement("div", {"className": "show-print block-tile no-padding-margin-y"},
                      React.createElement("div", {"className": "box-tile"},
                        React.createElement("div", {"className": "tile-group"},
                          React.createElement("div", {"className": "tile-title"},
                            React.createElement("div", {"className": "name-block middle"},
                              React.createElement(CLabel, {"k": "property.actions.and.notes"})
                            )
                          )
                        ),
                        React.createElement("div", {"className": "tile-action notes-list print-notes-list"},
                          React.createElement(CItemNotesList, {"notes": (@state.notesChunks[0]),  \
                                "onNoteChanged": (() => {}),   \
                                "maxNotes": (100)
                          })
                        )
                      )
                    ) 
                    )
                  )
                else
                  React.createElement("div", {"className": "dashboard-group clearfix"},
                    React.createElement(CLabel, {"k": "property.no.access.message"})
                  )
                )
              )
            )
          )
        )
      )
      ),
      React.createElement(PrintFooter, null)
    ),
    ((@state.notesChunks.length > 1 || @state.rolesChunks.length > 1) && React.createElement("div", {"className": "wrapper-content property show-print"},
      (pages.slice(1).map((i) => React.createElement(PrintContent, {"key": ("chunk-#{i}"), "className": "page-break-before"},
          React.createElement("div", {"className": "main"},
            React.createElement("div", {"className": "main-content"},
              React.createElement("div", {"className": "container"},
                React.createElement("div", {"className": "dashboard no-padding-margin-y"},
                  React.createElement("div", {"className": "dashboard-group"},
                    (@state.rolesChunks[i]?.length > 0 && React.createElement("div", {"className": "block-contacts-hotel"},
                        React.createElement("div", {"className": "block-tile"},
                          React.createElement("div", {"className": "box-slide-block open"},
                            React.createElement("div", {"className": "slide-block open"},
                              React.createElement(CRolesList, {"property": (@state.property), "RenderItemAs": (CRoleDetails), "fillEmpty": (true), "style": ({flexDirection: "column", justifyContent: "stretch"}), "rolesData": (@state.rolesChunks[i])})
                            )
                          )
                        )
                    )),
                    (@state.notesChunks[i]?.length > 0 && React.createElement("div", {"className": "block-tile no-padding-margin-y"},
                      React.createElement("div", {"className": "box-tile"},
                        React.createElement("div", {"className": "tile-group"},
                          React.createElement("div", {"className": "tile-title"},
                            React.createElement("div", {"className": "name-block middle"},
                              React.createElement(CLabel, {"k": "property.actions.and.notes"})
                            )
                          )
                        ),
                        React.createElement("div", {"className": "tile-action notes-list print-notes-list"},
                          React.createElement(CItemNotesList, {"notes": (@state.notesChunks[i]),  \
                                "onNoteChanged": (() => {}),   \
                                "maxNotes": (100)
                          })
                        )
                      )
                    ))
                  )
                )
              )
            )
          )
        )))
    ))
    )
)
,state: (state)->
  mapsAvailable: state.session?.user?.client?.maps
  result: state.searchForm?.result
  page: state.searchForm?.info.page
  locationHistory: state.app.locationHistory
  activeQuickSearch: state.quickSearchForm.active
  form: state.searchForm.form
  translation: state.translation
  client: state.session?.user?.client
  searchFieldsInfo: state.app.searchFieldsInfo
  notes: state.notes.list
,dispatch: (dispatch)->
  login: (session)-> dispatch(type: 'LOGIN', payload: session)
  search: (page, cb)-> dispatch(type: 'SEARCH', page: page, target:"Property", callback: cb)
  addToRecent: (property)-> dispatch(type: 'ADD_TO_RECENT', element: property)
  toggleWatchingProperty: (property)-> dispatch(type: 'TOGGLE_WATCHING_PROPERTY', id: property.id, watching: property.watching)
  deleteWatch: (propertyId, cb)-> dispatch(type: 'DELETE_WATCH', propertyId: propertyId, cb: cb)
  updateWatch: (propertyId, cb)-> dispatch(type: 'UPDATE_WATCH', propertyId: propertyId, cb: cb)
  togglePropertyExportPopup: (propertyId, onFinished) -> dispatch(type: 'TOGGLE_POPUP_PROPERTY_EXPORT', data: {onFinished: onFinished, propertyId: propertyId})
  toggleMobileFeaturePopup: (featureName) -> dispatch(type: 'TOGGLE_POPUP_MOBILE_FEATURE', data: { featureName: featureName })
