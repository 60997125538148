{ extend } = require '../../helpers/UtilsHelpers'

clone = (o) ->
  extend(true, { version: Math.random() }, o)

module.exports = (state = { list: [] }, action) ->
  if (action.type == "SET_RECENT")
    state = list: action.recents
  else if (action.type == "ADD_TO_RECENT")
    if (state.list.filter (opt) -> opt.id == action.element.id).length == 0
      state.list.unshift(action.element)
      clone(state)
    else
      state
  else
    state
