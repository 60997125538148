{ Link } = require 'react-router'
Connect = require '../../helpers/Connect'
{ API } = require '../../redux/Store'
Utils = require '../../helpers/Utils'
{ CLabel } = require '../CLabel'
CFilterDropdown = require '../filters/CFilterDropdown'
Moment = require 'moment'
{ CInput } = require '../CInput'
TimeInput = require('rc-time-picker').default

{ withTranslation } = require 'react-i18next' 


module.exports = withTranslation() Connect createReactClass(
  displayName: 'CAdminNotifications'

  intervalId: 0

  getInitialState: ()->
    info: {}
    time: Moment().startOf('day')
    notificationLogs: ""
    changed: false
    bannerMessage: null
    bannerChanged: false

  componentWillMount: ()->
    if !@props.user?.isAdmin
      @props.router.push('/home')

  componentWillUnmount: ()->
    clearTimeout(@intervalId)

  componentDidMount: ()->
    API.getNotificationSettings (info)=>
      @setState info: info, time: if info.setting?.weeklyDigestHour && info.setting?.weeklyDigestMinute then Moment().set('hour', info.setting.weeklyDigestHour).set('minute', info.setting.weeklyDigestMinute) else @state.time
    API.getBannerMessageSettings (message)=>
      @setState bannerMessage: message.original
    API.getNotificationsLogs (notificationLogs)=>
      @setState notificationLogs: notificationLogs

  setTime: (e)->
    info = @state.info
    info?.setting.weeklyDigestHour = e.get('hour')
    info?.setting.weeklyDigestMinute = e.get('minute')
    @setState info: info, time: Moment().set('hour', info.setting?.weeklyDigestHour).set('minute', info.setting?.weeklyDigestMinute), changed: true

  setDayOfWeek: (_, v)->
    info = @state.info
    info?.setting.weeklyDigestDay = v
    @setState info: info, changed: true

  saveChanges: ()->
    @setState changed: false
    API.saveNotificationSettings @state.info?.setting, (r)->
      console.log(r)

  saveBannerMessage: ()->
    @setState bannerChanged: false
    API.saveBannerMessageSettings @state.bannerMessage, (r)->
      console.log(r)

  changeBannerMessage: (event)->
    @setState bannerChanged: true, bannerMessage: event.target.value

  toggleSendWeeklyDigest: ()->
    info = @state.info
    info?.setting.sendWeeklyDigest = !info?.setting.sendWeeklyDigest
    @setState info: info, changed: true

  getDaysOfWeek: () ->
    [
      {value: 1, label:"Monday"}
      {value: 2, label:"Tuesday"}
      {value: 3, label:"Wednesday"}
      {value: 4, label:"Thursday"}
      {value: 5, label: "Friday"}
      {value: 6, label: "Saturday"}
      {value: 7, label: "Sunday"}
    ]

  render: ->
    React.createElement("div", null,
      React.createElement("div", {"className": "block-tile admin-page"},
        React.createElement("div", {"className": "box-tile"},
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "info-count"},
                React.createElement("div", {"className": "row-quarter"},
                  React.createElement("div", {"className": "name-block upper"},
                    React.createElement(CLabel, {"k": "admin.subscribers.weekly.label"})
                  )
                ),
                React.createElement("div", {"className": "row-quarter"},
                  React.createElement("span", {"className": "count", "id": "subscribersWeeklyCount"}, (@state.info?.subscribers?.weekly)), " ", React.createElement(CLabel, {"k": "admin.subscribers.weekly.count"})
                )
              )
            ),
            React.createElement("div", {"className": "tile-group"},
              React.createElement("form", {"className": "form form-small"},
                React.createElement("div", {"className": "row text-right"},
                  React.createElement("div", {"style": (marginBottom: '10px'), "className": "box-inline"},
                    React.createElement("div", {"className": "box-control box-inline row-quarter", "style": (textAlign: 'left')},
                      React.createElement("div", {"className": "box-checkbox"},
                        React.createElement("input", {"type": "checkbox", "id": "sendWeeklyDigest", "onChange": (@toggleSendWeeklyDigest), "checked": (@state.info?.setting?.sendWeeklyDigest || false)}),
                        React.createElement("label", {"htmlFor": "sendWeeklyDigest"},
                          React.createElement(CLabel, {"k": "admin.weekly.digest.active"})
                        )
                      )
                    ),
                    React.createElement("div", {"className": "box-control box-inline text-left row-quarter", "style": (marginBottom: '10px')},
                      React.createElement(CFilterDropdown, {"placeholder": "--none--", "className": "row-half", "onChange": (@setDayOfWeek), "value": (@state.info?.setting?.weeklyDigestDay), "options": (@getDaysOfWeek())})
                    ),
                    React.createElement("div", {"className": "box-control box-inline text-left row-half"},
                      React.createElement(TimeInput, { \
                        "onChange": (@setTime),  \
                        "value": (@state.time),  \
                        "showSecond": (false),  \
                        "hideDisabledOptions": (true),  \
                        "allowEmpty": (false)
                      }),
                      React.createElement("span", {"style": (marginLeft: '5px')}, React.createElement(CLabel, {"k": "admin.timezone.local"}))
                    )
                  ),
                  React.createElement("a", {"style": (marginLeft: '20px'), "className": "btn btn-red btn-normal #{if !@state.changed then 'disabled unselectable'}", "onClick": (@saveChanges)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.save.changes"})))
                )
              )
            )
          ),
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "info-count"},
                React.createElement("div", {"className": "row-quarter"},
                  React.createElement("div", {"className": "name-block upper"},
                    React.createElement(CLabel, {"k": "admin.subscribers.reminders.label"})
                  )
                ),
                React.createElement("div", {"className": "row"},
                  React.createElement("span", {"className": "count", "id": "subscribersTotalCount"}, ('' + @state.info?.subscribers?.email + @state.info?.subscribers?.push)),
                  React.createElement(CLabel, {"k": "admin.subscribers.reminders.count"}), " ", (@state.info?.subscribers?.email), " ", React.createElement(CLabel, {"k": "admin.subscribers.reminders.email.count"}), " ", (@state.info?.subscribers?.push), " ", React.createElement(CLabel, {"k": "admin.subscribers.reminders.push.count"})
                )
              )
            )
          ),
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "clearfix"},
              React.createElement("div", {"className": "info-count"},
                React.createElement("div", {"className": "row-quarter"},
                  React.createElement("div", {"className": "name-block upper"},
                    React.createElement(CLabel, {"k": "admin.banner.message"})
                  )
                ),
                React.createElement("div", {"className": "row"},
                  React.createElement("div", {"className": "box-control box-inline row-half"},
                    React.createElement(CInput, {"style": (width: "90%", lineHeight: "27px"), "placeholder": (@props.t("admin.banner.placeholder", { ns: 'internal_ui' })), "className": "form-control", "type": 'text', "value": (@state.bannerMessage), "onChange": (@changeBannerMessage)})
                  ),
                  React.createElement("a", {"style": (marginLeft: '20px'), "className": "btn btn-red btn-normal #{if !@state.bannerChanged then 'disabled unselectable'}", "onClick": (@saveBannerMessage)}, React.createElement("span", {"className": "upper"}, React.createElement(CLabel, {"k": "admin.save.changes"})))
                )
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": "block-tile"},
        React.createElement("div", {"className": "box-tile"},
          React.createElement("div", {"className": "tile-group"},
            React.createElement("div", {"className": "tile-title"},
              React.createElement("div", {"className": "name-block middle"}, React.createElement(CLabel, {"k": "admin.notifications.logs.title"}), " ", React.createElement(CLabel, {"k": "admin.notifications.logs.uts"}))
            ),
            React.createElement("div", {"className": "block-logs"},
              React.createElement("div", {"className": "list"},
                React.createElement("div", {"className": "box-scroll log-box"},
                  React.createElement("div", {"className": "log", "style": ('lineHeight': '18px')},
                    (@state.notificationLogs)
                  )
                )
              )
            )
          )
        )
      )
    )
),state: (state)->
    user: state.session?.user
    translation: state.translation
  ,dispatch: (dispatch)->
    login: (session)-> dispatch(type: 'LOGIN', payload: session)
